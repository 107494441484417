// external/getCMRoomDetail
import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManagerRuleMapping = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCMRoomDetail: builder.query({
      query: (data) => {
        return {
          url: `/external/getCMRoomDetail`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCMRoomDetail"],
    }),
    getCMRateChargeRule: builder.query({
      query: (data) => {
        return {
          url: `/external/getCMRateChargeRule`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getCMRateChargeRule"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCMRoomDetailQuery,useGetCMRateChargeRuleQuery } = ChannelManagerRuleMapping;
