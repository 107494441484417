import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrow from "../../assets/icons/Vector (4).svg";
import arrowup from "../../assets/icons/Vector up.svg";
import closedLogo from "../../assets/icons/rateXicon.svg";
import ProfileSideBar from "../profile/ProfileSideBar";
import dashboardIcon from "../../assets/icons/dashboardIcon.svg";
import rateSuggestionIcon from "../../assets/icons/rateSuggestion.svg";
import ratesInventoryIcon from "../../assets/icons/ratesInventory.svg";
import Compset from "../../assets/icons/ratesInventory.svg";
import reservationIcon from "../../assets/icons/reservation.svg";
import ratePulseIcon from "../../assets/icons/ratePulseIcon.svg";
import hotel from "../../assets/icons/hotelinteligence.svg";
import reports from "../../assets/icons/report--data 1.svg";
import "../../pages/onboarding/Global.css";
import dashboardHover from "../../assets/icons/dashboardHover.svg";
import { AiOutlineFileText, AiTwotoneThunderbolt } from "react-icons/ai";
import compitetor from "../../assets/icons/competitor.svg";
import proIcon from "../../assets/icons/Pro.png";
// linear-gradient(45deg, white, transparent)
<style jsx>{`
  .hovered-icon:hover {
    filter: brightness(120%) saturate(150%);
  }
`}</style>;

const Sidebar = ({
  logo,
  menuItems = [],
  footerItems = [],
  footerText,
  isSidebarOpen,
  toggleSidebar,
  onMenuItemClick,
  name,
  designation,
  selectedMenu,
  setSidebarOpen,
  isHoverState,
  setIsHoverState,
  setHoveredIndex,
  hoveredIndex
}) => {
  const generateLink = (name) => {
    return name.toLowerCase().split(" ").join("_");
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [hoveredSubIndex, setHoveredSubIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDashboardActive, setIsDashboardActive] = useState(true);
  const [tempIndex, setTempIndex] = useState();
  // Function to handle mouse entering an item
  const handleMouseEnter = (index) => () => {
    setHoveredIndex(index);
  };

  // Function to handle mouse leaving an item
  const handleMouseLeave = () => {
    setSidebarOpen(false)
    setIsHoverState(false)
    setHoveredIndex(null);
  };
  const [isProfileOpen, setIsProfileOpen] = useState(true);
  const toggleProfileBox = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const toggleDropdown = (index) => {
    if (isSidebarOpen || isHoverState) {
      setDropdownVisible(dropdownVisible === index ? null : index);
    }
  };

  useEffect(() => {
    setSelectedItem(selectedMenu);
  }, [selectedMenu]);
  // setSelectedItem(selectedMenu)
  const handleClick = (index, subIndex) => () => {
    setSelectedItem(menuItems[index]);
    setSelectedIndex(subIndex || index);
    if (subIndex !== undefined) {
      onMenuItemClick(menuItems[index], menuItems[index].subItems[subIndex]);
      setSidebarOpen(false);
      setIsHoverState(false)
    } else {
      onMenuItemClick(menuItems[index]);
      setSidebarOpen(false);
      setIsHoverState(false)
    }
  };

  const propertiesList = window.localStorage.getItem("propertiesList")
    ? JSON.parse(window.localStorage.getItem("propertiesList"))
    : [];
  useEffect(() => {
    if (pathname === "/") {
      setSelectedItem("Dashboard");
    }
    if (pathname === "/rates_&_inventory") {
      setSelectedItem("Rates & Inventory");
      setHoveredIndex(null);
    }
    if (pathname === "/compset_overview") {
      setSelectedItem("Compset Overview");
      setHoveredIndex(null);
    }
    if (pathname === "/reports") {
      setSelectedItem("Reports");
      setHoveredIndex(null);
    }

    if (pathname === "/hotel_intelligence") {
      setSelectedItem("Hotel Intelligence");
      setHoveredIndex(null);
    }
    if (pathname === "/reservations" || pathname.includes('/reservation')) {
      setSelectedItem("Reservations");
      setHoveredIndex(null);
    }
    if (pathname?.includes("Report")) {
      setSelectedItem("Reports");
      setHoveredIndex(null);
    }

    // "Dashboard",
    //   "Rate Suggestions",
    //   "Hotel Intelligence",
    //   "Rates & Inventory",
    //   "Compset Overview",
    //   "Reservations",
    //   "Reports",
  }, [pathname]);

  useEffect(() => {
    let body = document.body;
    body.style.overflow = "";
  });

  useEffect(() => {
    if (selectedItem === "Reports" || pathname?.includes("report")) {
      setTempIndex(6);
      // toggleDropdown(6);
    } else if (
      selectedItem === "Rate Pulse" ||
      pathname?.includes("rate-pulse")
    ) {
      setTempIndex(7);
      // toggleDropdown(7);
    } else {
      setTempIndex();
      setDropdownVisible(null);
    }
  }, [selectedItem]);
  return (
    <>
      <div
      onMouseLeave={handleMouseLeave}
        className={`h-[calc(100vh-60px)] bottom-0 ${(isSidebarOpen || isHoverState) ? "min-w-[220px] opacity-100" : "w-[100%] opacity-100"} transition-all duration-300 ease-in-out flex flex-col sticky left-0 top-0 justify-between`}
      >
        <div className="">
          <div className="flex items-center relative mt-1  ">
            <div className=" w-full h-[70px] flex items-center " onMouseLeave={handleMouseLeave}>
              {(isSidebarOpen || isHoverState) && logo && (
                <img
                  src={logo}
                  className="absolute left-3 w-[70%] h-[60px] overflow-hidden"
                  alt="Logo"
                  onClick={() => navigate("/dashboard")}
                />
              )}

              {/* Render close icon */}
              {!(isSidebarOpen || isHoverState) && closedLogo && (
                <img
                  src={closedLogo}
                  className="absolute left-4 w-[50%]  overflow-hidden"
                  alt="Closed Logo"
                  onClick={() => navigate("/dashboard")}
                />
              )}
              {/* <div
                className={`sidebar_toggle absolute right-0 ${!isSidebarOpen ? "rotate180 overflow-hidden " : " overflow-hidden"}`}
                onClick={toggleSidebar}
              >
                {isSidebarOpen ? (
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.70694 1.70755C8.09694 1.31755 8.09694 0.684557 7.70694 0.29306C7.31694 -0.0984365 6.68395 -0.0969365 6.29245 0.29306L7.70694 1.70755ZM0.998992 7.00101L0.292498 6.29451C-0.0974989 6.68451 -0.0974989 7.31751 0.292498 7.709L0.998992 7.00101ZM6.29245 13.7075C6.68245 14.0975 7.31544 14.0975 7.70694 13.7075C8.09844 13.3175 8.09694 12.6845 7.70694 12.293L6.29245 13.7075ZM6.29245 0.29306L0.292498 6.29451L1.70699 7.7075L7.70694 1.70755L6.29245 0.29306ZM0.292498 7.7075L6.29245 13.7075L7.70694 12.293L1.70699 6.29301L0.292498 7.7075Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M7.70694 1.70755C8.09694 1.31755 8.09694 0.684557 7.70694 0.29306C7.31694 -0.0984365 6.68395 -0.0969365 6.29245 0.29306L7.70694 1.70755ZM0.998992 7.00101L0.292498 6.29451C-0.0974989 6.68451 -0.0974989 7.31751 0.292498 7.709L0.998992 7.00101ZM6.29245 13.7075C6.68245 14.0975 7.31544 14.0975 7.70694 13.7075C8.09844 13.3175 8.09694 12.6845 7.70694 12.293L6.29245 13.7075ZM6.29245 0.29306L0.292498 6.29451L1.70699 7.7075L7.70694 1.70755L6.29245 0.29306ZM0.292498 7.7075L6.29245 13.7075L7.70694 12.293L1.70699 6.29301L0.292498 7.7075Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div> */}
            </div>
          </div>
          <div className=" py-4 md:p-0 h-[100%] overflow-y-auto overflow-x-hidden">
            <div>
              <div className={`text-lg  font-[400]`}>
                <ul className=" ">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex flex-col group  items-center   my-1 text-start justify-between font-[500] pl-2"
                      onMouseEnter={handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        setSelectedItem(item.name ? item?.name : name);
                        if (item.subItems) {
                          toggleDropdown(index);
                        } else {
                          handleClick(index)();
                        }
                      }}
                    >
                      <div
                        className={`flex hover-div   items-center  w-full  text-start justify-between font-[500]  hover:bg-[#f0ebd8ad] text-[14px] cursor-pointer hover:shadow-sm group group-hover:text-blue-500 hover:py-0.5 hover:text-[15px] duration-150 ease-in-out transition-all rounded-l-xl 
                            ${selectedItem === menuItems[index] || selectedItem === item?.name ? "border-[#1f1f1f] bg-[#f0ebd8] " : "text-white"} ${(index === hoveredIndex || index === tempIndex) && "bg-[#ffffff]"} `}
                      >
                        {item.subItems ? (
                          <div className="flex items-center justify-between w-full">
                            <div
                              className="flex items-center gap-3 group w-full  group"
                              onClick={
                                item.name === "Reports" ||
                                item.name === "Rate Pulse"
                                  ? () => {
                                      setTempIndex(index);
                                      setDropdownOpen(false)
                                      // setSidebarOpen(true);
                                      setIsHoverState(true)
                                      setDropdownVisible(index);
                                      setSelectedIndex(0);
                                    }
                                  : () => {
                                      setSelectedIndex(0);
                                    }
                              }
                            >
                              <span className={`ml-3 group `}>
                                {hoveredIndex === index ||
                                index === tempIndex ||
                                selectedItem === item?.name ? (
                                  item?.name === "Reports" ? (
                                    <AiOutlineFileText
                                      color="black"
                                      size={24}
                                    />
                                  ) : (
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className={`  group-hover:text-blue-500 ${"hoverEffect"}`}
                                    >
                                      <g clip-path="url(#clip0_463_32629)">
                                        <path
                                          d="M2.25 1.125H1.125V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H16.875V15.75H2.25V1.125Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M16.875 5.0625H12.9375V6.1875H14.9569L10.6875 10.4569L8.27437 8.03813C8.22208 7.9854 8.15987 7.94356 8.09132 7.915C8.02278 7.88644 7.94926 7.87174 7.875 7.87174C7.80074 7.87174 7.72722 7.88644 7.65868 7.915C7.59013 7.94356 7.52792 7.9854 7.47563 8.03813L3.375 12.1444L4.16812 12.9375L7.875 9.23063L10.2881 11.6494C10.3404 11.7021 10.4026 11.7439 10.4712 11.7725C10.5397 11.8011 10.6132 11.8158 10.6875 11.8158C10.7618 11.8158 10.8353 11.8011 10.9038 11.7725C10.9724 11.7439 11.0346 11.7021 11.0869 11.6494L15.75 6.98062V9H16.875V5.0625Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_463_32629">
                                          <rect
                                            width="18"
                                            height="18"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  )
                                ) : item?.name === "Reports" ? (
                                  <AiOutlineFileText
                                    className="my-2"
                                    color="white"
                                    size={24}
                                  />
                                ) : (
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_463_32629)">
                                      <path
                                        d="M2.25 1.125H1.125V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H16.875V15.75H2.25V1.125Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M16.875 5.0625H12.9375V6.1875H14.9569L10.6875 10.4569L8.27437 8.03813C8.22208 7.9854 8.15987 7.94356 8.09132 7.915C8.02278 7.88644 7.94926 7.87174 7.875 7.87174C7.80074 7.87174 7.72722 7.88644 7.65868 7.915C7.59013 7.94356 7.52792 7.9854 7.47563 8.03813L3.375 12.1444L4.16812 12.9375L7.875 9.23063L10.2881 11.6494C10.3404 11.7021 10.4026 11.7439 10.4712 11.7725C10.5397 11.8011 10.6132 11.8158 10.6875 11.8158C10.7618 11.8158 10.8353 11.8011 10.9038 11.7725C10.9724 11.7439 11.0346 11.7021 11.0869 11.6494L15.75 6.98062V9H16.875V5.0625Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_463_32629">
                                        <rect
                                          width="18"
                                          height="18"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                )}
                              </span>
                              {(isSidebarOpen || isHoverState) && (
                                <span
                                  // className={`${
                                  //   (selectedItem?.includes('Report') || pathname.includes('rate-pulse')) ? 'text-black' : 'text-[#e4e0e0]'
                                  // } ${
                                  //   selectedItem === item?.name ? 'text-[#282a3d]' : ''
                                  // } ${
                                  //   hoveredIndex === index ? 'text-[#282a3d]' : ''
                                  // } ${
                                  //   (item?.name === 'Reports' || item?.name === 'Rate Pulse') ? 'text-[#e4e0e0]' : 'group-hover:text-[#282a3d]'
                                  // } hover:text-[#282a3d]`}
                                  className={`text-[#e4e0e0] ${index === hoveredIndex || index === tempIndex ? "text-black" : ""}`}
                                >
                                  {item.name}
                                </span>
                              )}
                            </div>
                            <span className="px-4 py-2">
                              <img
                                className={`w-3 ${(hoveredIndex === index || index === tempIndex) && "hoverEffect"} `}
                                src={
                                  dropdownVisible === index ? arrowup : arrow
                                }
                                alt=""
                              />
                            </span>
                          </div>
                        ) : (
                          <Link
                            to={generateLink(item)}
                            className={`flex  items-center hover:text-[#ffffff]  text-[#e4e0e0]   w-full gap-3 ml-3`}
                            title={(!isSidebarOpen || !isHoverState) && item}
                            style={{
                              position: "relative",
                            }}
                          >
                            <span className={`my-2  group`}>
                              {hoveredIndex === index ? (
                                <>
                                  <img
                                    className={`group-hover:text-blue-500 ${"hoverEffect"}`}
                                    src={
                                      index === 0
                                        ? dashboardIcon
                                        : index === 1
                                          ? rateSuggestionIcon
                                          : index === 2
                                            ? hotel
                                            : index === 3
                                              ? ratesInventoryIcon
                                              : index === 4
                                                ? compitetor
                                                : index === 5
                                                  ? reservationIcon
                                                  : index === 6
                                                    ? reports
                                                    : index === 7
                                    }
                                    alt=""
                                    srcset=""
                                    width={index === 4 ? 30 : 25}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      index === 0
                                        ? dashboardIcon
                                        : index === 1
                                          ? rateSuggestionIcon
                                          : index === 2
                                            ? hotel
                                            : index === 3
                                              ? ratesInventoryIcon
                                              : index === 4
                                                ? compitetor
                                                : index === 5
                                                  ? reservationIcon
                                                  : index === 6
                                                    ? reports
                                                    : index === 7
                                    }
                                    alt=""
                                    srcset=""
                                    width={index === 4 ? 30 : 25}
                                    className={`${selectedItem === item && "hoverEffect"}`}
                                  />
                                </>
                              )}
                            </span>
                            {(isSidebarOpen || isHoverState) && (
                              <span
                                className={`${(selectedItem === menuItems[index] || index === hoveredIndex) && "text-[#282a3d]"}`}
                              >
                                {item}
                              </span>
                            )}
                            {(isSidebarOpen || isHoverState) && item === "Rate Suggestions" && (
                              <img
                                className={`-ml-4 ${selectedItem === item && ""}`}
                                src={proIcon}
                                width={50}
                              />
                            )}
                          </Link>
                        )}
                      </div>
                      {(isSidebarOpen || isHoverState) &&
                        dropdownVisible === index &&
                        item.subItems && (
                          <ul className="flex flex-col relative 2xl:-left-10 -left-1.5 items-start">
                            {item?.subItems?.map((subItem, subIndex) => (
                              <li
                                key={subIndex}
                                className={`text-sm transition-all ease-in-out duration-150 hover:mt-2 hover:text-[#ffffff] hover:bg-white/10 rounded-lg p-1 w-[100%] ${subIndex === hoveredSubIndex ? "text-[#ffee8c]" : "text-white"}`}
                                onMouseEnter={() => {
                                  setHoveredSubIndex(subIndex);
                                }}
                                onClick={() => {
                                  setSidebarOpen(false);
                                  setIsHoverState(false)
                                  setHoveredSubIndex(subIndex);
                                }}
                                onMouseLeave={() => setHoveredSubIndex(null)}
                              >
                                <Link
                                  to={subItem.path}
                                  onClick={handleClick(index, subIndex)}
                                  className={` flex items-center w-[100%]`}
                                >
                                  <div
                                    className={`flex items-center justify-start pl-2 w-[100%] text-[12px] `}
                                  >
                                    <div className="">
                                      <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`hover-div`}
                                      >
                                        <g clip-path="url(#clip0_463_32634)">
                                          <path
                                            d="M8.62496 7.5L8.09468 8.03029L9.06439 9H2.24993V1.5H1.49993V9C1.49993 9.41344 1.8363 9.75 2.24993 9.75H9.06442L8.09471 10.7197L8.625 11.25L10.5 9.375L8.62496 7.5Z"
                                            fill="white"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_463_32634">
                                            <rect
                                              width="12"
                                              height="12"
                                              fill="white"
                                              transform="matrix(-1 0 0 1 12 0)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <span className="ml-2">{subItem.name}</span>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* {isSidebarOpen ? ( */}
        <div className="w-[100%] px-4 absolute bottom-[3%]">
          <ProfileSideBar isDropdownOpen={isDropdownOpen} setDropdownOpen={setDropdownOpen} data={propertiesList} isSidebarOpen={isSidebarOpen || isHoverState} setDropdownVisible={setDropdownVisible} />
        </div>
        {/* ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default Sidebar;
