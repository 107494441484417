import React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import NewTooltip from '../tooltip/NewTooltip';
import img from "../../assets/icons/Icon (3).svg";
import { Tooltip } from 'react-tooltip';
import { streamlineExcel } from './streamlinedExcelDownload';


const ExcelDownloadButton = ({ data, filename, className = '', small, intelligence, tooltip }) => {

  const downloadExcel = () => {
    streamlineExcel(data,filename)
    // const ws = XLSX.utils.json_to_sheet(data);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    // XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <>
      {
        intelligence &&
        <div onClick={downloadExcel} className="justify-center flex gap-2 border items-center rounded-md p-2 px-4 cursor-pointer">
          <img className="w-4 h-4" loading="lazy" src={img} alt="" />
          <p className="text-sm">Export</p>
        </div>
      }
      {!intelligence && !tooltip &&
        <NewTooltip download={true} title="Download Report" className='z-50'>
          <button onClick={downloadExcel} className={`flex  ${className}`}>
            <AiOutlineDownload size={20} color='white' className='mr-2' />
          </button>
        </NewTooltip>
      }

      {
        tooltip &&
        <button onClick={downloadExcel} className={`flex  ${className}`}>
          <AiOutlineDownload size={20} color='white' className='mr-2' />
        </button>
      }

    </>
  );
};

export default ExcelDownloadButton;

