const SelectableButton = ({ title, selectedIndex, onClick, index }) => {
    return (
      <div
        className={`cursor-pointer text-[14px] ${selectedIndex === index ? "text-white bg-[#1D2D44] hover:bg-[#1D3893] transition-all ease-in-out duration-200" : "bg-[#EEF1F6] text-[#59647B] hover:bg-[#CCD6E5] hover:text-black transition-all ease-in-out duration-200"}  rounded-lg w-fit ${title === "All" ? 'py-2 px-3' : 'py-2 px-2'} `}
        onClick={onClick}
      >
        {title}
      </div>
    );
  };

  export default SelectableButton