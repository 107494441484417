import { emptySplitApi } from "../../injectEndpoints";

export const ReportScheduling = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportScheduling: builder.query({
      query: (data) => ({
        url: `/userDashboardSetting/getReportSchedule`,
        method: "GET",
        params:data
      }),
      // Replace with your actual endpoint
      providesTags: ["getReportScheduling"],
    }),
    editReportScheduling: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editReportSchedule?hId=${data?.hId}&userId=${data?.userId}`,
          method: "PATCH",
          body: data?.body,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editReportScheduling"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetReportSchedulingQuery ,useEditReportSchedulingMutation} = ReportScheduling;
