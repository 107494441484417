const GenerateShortCode =(inputString)=> {
    const words = inputString.split(' ');
    if (words.length === 1) {
      return inputString.slice(0, 3).toLowerCase();
    } else if (words.length === 2) {
      const shortCode = words.reduce((code, word) => {
        if (word.length > 1) {
          return code + word.slice(0, 2);
        } else if (word.length === 1) {
          return code + word.charAt(0);
        }
        return code;
      }, '').toLowerCase();
  
      return shortCode;
    }
    return '';
  }

  export default GenerateShortCode