import React, { useEffect, useState, useMemo, useRef } from "react";

import "./CustomCalander.css";
import useIntelligenceDateRange from "../../utils/dateRange";

const CustomCalander = (props) => {
 
  
  const {
    // if calendarType == single --> normal calander
    // if calendarType == single && onlyYear == true --> only year picker
    // if calendarType == single && onlyMonth == true --> only month picker
    // if calendarType == quarter --> year & quarter picker
    calendarType = "single",
    onlyYear = false,
    onlyMonth = true,
    financialToggle = true,
    //need input for restriction
    activeDateArray,
    selectedDateArray,
    setSelectedDateArray,
    selectedComparedDateArray,
    setSelectedComparedDateArray,
    restrictionEnd = "4/11/2024",
    restrictionStart = "1/11/2021",
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        setShowCalendar(false)
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [ref]);

  const numDays = 30;
  const [calendarDateTimeFrame, setCalendarDateTimeFrame] = useState(
    new Date()
  );
  const [originalSelectedDate, setOriginalSelectedDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  // const [calendarType, setCalendarType] = useState("single");
  const [calendarGenre, setCalendarGenre] = useState(
    calendarType === "single"
      ? onlyYear || onlyMonth
        ? "yearPicker"
        : "datePicker"
      : calendarType === "quarter"
      ? "quarterPicker"
      : ""
  );
  const [calendarDates, setCalendarDates] = useState(
    generateMonthDatesWithExtras(
      generateMonthDatesWithExtras(calendarDateTimeFrame)
    )
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const quarter = [
    {
      name: "Q1",
      monthStart: "January",
      monthMid: "February",
      monthEnd: "March",
    },
    {
      name: "Q2",
      monthStart: "April",
      monthMid: "May",
      monthEnd: "June",
    },
    {
      name: "Q3",
      monthStart: "July",
      monthMid: "August",
      monthEnd: "September",
    },
    {
      name: "Q4",
      monthStart: "October",
      monthMid: "November",
      monthEnd: "December",
    },
  ];

  function generateCalendarDates(date, numDays) {
    const dates = [];
    const currentDate = new Date(date);

    for (let i = 0; i < numDays; i++) {
      const formattedDate = currentDate.toDateString();
      dates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }
  function generateMonthDatesWithExtras(calendarDateTimeFrame) {
    // Create a Date object from the provided date string
    const date = new Date(calendarDateTimeFrame);

    // Calculate the first day of the month
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // Find the previous Sunday before the first day of the month
    const startDay = firstDayOfMonth.getDay();
    const daysToAddToStart = startDay > 0 ? startDay : 7;
    const dateWithExtras = new Date(firstDayOfMonth);
    dateWithExtras.setDate(firstDayOfMonth.getDate() - daysToAddToStart);

    // Calculate the last day of the month
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Find the next Saturday after the last day of the month
    const endDay = lastDayOfMonth.getDay();
    const daysToAddToEnd = endDay < 6 ? 6 - endDay : 0;
    const endDateWithExtras = new Date(lastDayOfMonth);
    endDateWithExtras.setDate(lastDayOfMonth.getDate() + daysToAddToEnd);

    // Generate all dates between the calculated start and end dates
    const generatedDates = [];
    let currentDate = new Date(dateWithExtras);

    while (currentDate <= endDateWithExtras) {
      generatedDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return generatedDates;
  }
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
  function getDayFromDateFormattedString(dateString) {
    // Create a Date object from the formatted string
    const dateObject = new Date(dateString);

    // Get the day of the month
    const dayOfMonth = dateObject.getDate();

    return dayOfMonth;
  }
  function getMonthNumberFromDateFormattedString(dateString) {
    // Create a Date object from the formatted string
    const dateObject = new Date(dateString);

    // Get the month (zero-based index, so add 1 to get the actual month number)
    const monthNumber = dateObject.getMonth() + 1;

    return monthNumber;
  }
  function getYearNumberFromDateFormattedString(dateString) {
    // Create a Date object from the formatted string
    const dateObject = new Date(dateString);

    // Get the year
    const yearNumber = dateObject.getFullYear();

    return yearNumber;
  }
  function getShortDayFromDateFormattedString(dateString) {
    // Create a Date object from the formatted string
    const dateObject = new Date(dateString);

    // Get the short day name
    const shortDay = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
    }).format(dateObject);

    return shortDay;
  }
  function getDayInitialFromDateFormattedString(dateString) {
    // Create a Date object from the formatted string
    const dateObject = new Date(dateString);

    // Get the day initial
    const dayInitial = new Intl.DateTimeFormat("en-US", { weekday: "short" })
      .format(dateObject)
      .charAt(0);

    return dayInitial;
  }
  function createDateFromComponents(day, month, year) {
    // Month is zero-based in JavaScript Date objects, so subtract 1 from the provided month
    const dateObject = new Date(year, month - 1, day);

    return dateObject;
  }
  function setMonthByName(inputDate, monthName) {
    // Make a copy of the input date to avoid modifying the original date
    const date = new Date(inputDate);

    // Convert the month name to a numerical month value
    const monthNumber = new Date(`${monthName} 1, 2000`).getMonth();

    // Set the month in the copy of the input date
    date.setMonth(monthNumber);

    // Check for months with 31 days
    if ([0, 2, 4, 6, 7, 9, 11].includes(monthNumber)) {
      date.setDate(Math.min(31, date.getDate()));
    }
    // Check for months with 30 days
    else if ([3, 5, 8, 10].includes(monthNumber)) {
      date.setDate(Math.min(30, date.getDate()));
    }
    // Check for February
    else if (monthNumber === 1) {
      const isLeapYear =
        (date.getFullYear() % 4 === 0 && date.getFullYear() % 100 !== 0) ||
        date.getFullYear() % 400 === 0;

      date.setDate(Math.min(isLeapYear ? 29 : 28, date.getDate()));
    }

    return date;
  }
  function setYear(inputDate, year) {
    // Make a copy of the input date to avoid modifying the original date
    const date = new Date(inputDate);

    // Set the year in the copy of the input date
    date.setFullYear(year);

    // Check for February and handle leap years
    if (date.getMonth() === 1 && date.getDate() === 29 && !isLeapYear(year)) {
      date.setDate(28); // Adjust to the last day of February for non-leap years
    }

    return date;
  }
  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
  function isSelectedDate(date) {
    return (
      date.getDate() === originalSelectedDate.getDate() &&
      date.getMonth() === originalSelectedDate.getMonth() &&
      date.getFullYear() === originalSelectedDate.getFullYear()
    );
  }
  function isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }
  function isSelectedMonth(date) {
    const selectDate = calendarDateTimeFrame;
    return (
      date.getMonth() === selectDate.getMonth() &&
      date.getFullYear() === selectDate.getFullYear()
    );
  }
  function isDateInRange(inputDate, inputStartDate, inputEndDate) {
    return inputDate >= inputStartDate && inputDate <= inputEndDate;
  }
  function isMonthInDateRange(monthName, year, inputStartDate, inputEndDate) {
    // Convert month name to a numerical month value
    const monthNumber = new Date(`${monthName} 1, ${year}`).getMonth() + 1; // Months are zero-indexed in JavaScript

    if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
      // Invalid month name
      return false;
    }

    // Create a date object for the first day of the month
    const firstDayOfMonth = new Date(`${year}-${monthNumber}-01`);
    // Check if the first day of the month is within the specified date range
    return firstDayOfMonth >= inputStartDate && firstDayOfMonth <= inputEndDate;
  }
  function isYearInDateRange(year, inputStartDate, inputEndDate) {
    const firstDayOfYear = new Date(`${year}-01-01`);
    const lastDayOfYear = new Date(`${year}-12-31`);
    return (
      year >= inputStartDate.getFullYear() && year <= inputEndDate.getFullYear()
    );
  }
  function getMonthNameFromDate(date) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[date.getMonth()];
  }
  function generateYearRange(date) {
    const currentYear = date.getFullYear();
    const years = [];

    for (let year = currentYear - 15; year <= currentYear + 14; year++) {
      years.push(year);
    }

    return years;
  }
  function getStartAndEndDate(startMonth, endMonth, startYear) {
    // Validate input months
    if (startMonth < 1 || startMonth > 12 || endMonth < 1 || endMonth > 12) {
      throw new Error(
        "Invalid month input. Months should be between 1 and 12."
      );
    }

    // Validate input years
    if (!Number.isInteger(startYear) || startYear < 1) {
      throw new Error("Invalid year input. Year should be a positive integer.");
    }

    // Calculate start and end dates
    const inputStartDate = new Date(startYear, startMonth - 1, 1); // Months are zero-indexed
    const inputEndDate = new Date(startYear, endMonth, 0); // Set to last day of endMonth
    // Check if the endMonth extends to the next year
    if (endMonth < startMonth) {
      inputEndDate.setFullYear(startYear + 1);
    }

    return { inputStartDate, inputEndDate };
  }
  function getMonthNumberFromName(monthName) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const normalizedMonthName = monthName
      .trim()
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

    const monthNumber = monthNames.indexOf(normalizedMonthName) + 1;

    return monthNumber > 0 ? monthNumber : null; // Return null if the month name is not found
  }

  function addMonthsToDate(date, monthsToAdd) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + monthsToAdd);
    if (restrictionStart !== "" && restrictionEnd !== "") {
      if (newDate <= restrictionEnd && newDate >= restrictionStart) {
        setCalendarDateTimeFrame(newDate);
      }
    } else {
      setCalendarDateTimeFrame(newDate);
    }
    return newDate;
  }

  function addYearsToDate(date, yearsToAdd) {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + yearsToAdd);
    if (restrictionStart !== "" && restrictionEnd !== "") {
      let restrictionStartDate = new Date(restrictionStart);
      let restrictionEndtDate = new Date(restrictionEnd);
      if (
        newDate.getFullYear() <= restrictionEndtDate.getFullYear() &&
        newDate.getFullYear() >= restrictionStartDate.getFullYear()
      ) {
        setCalendarDateTimeFrame(newDate);
      }
    } else {
      setCalendarDateTimeFrame(newDate);
    }
  }
  function getQuarter(inputDate) {
    const month = inputDate.getMonth() + 1; // Months are zero-indexed in JavaScript

    if (month >= 1 && month <= 3) {
      return "Q1";
    } else if (month >= 4 && month <= 6) {
      return "Q2";
    } else if (month >= 7 && month <= 9) {
      return "Q3";
    } else {
      return "Q4";
    }
  }

  //outputs
  //returning this year from 1st Jan to 31st Dec
  function getDateAndNextYear(date) {
    const currentDate = new Date(date);

    // Extract the year from the current date
    const currentYear = currentDate.getFullYear();

    // Create date objects for January 1st and December 31st of the current year
    const januaryFirst = new Date(currentYear, 0, 1);
    const decemberThirtyFirst = new Date(currentYear, 11, 31);

    // Format the dates as day-month-year strings
    const januaryFirstFormatted = formatDate(januaryFirst);
    const decemberThirtyFirstFormatted = formatDate(decemberThirtyFirst);

    return [januaryFirstFormatted, decemberThirtyFirstFormatted];
  }
  //returning this year from 1st Apr to 31st Mar next year
  function getAprilAndMarchDates(inputDate) {
    const currentYear = new Date(inputDate).getFullYear();
    const nextYear = currentYear + 1;
  
    const aprilFirst = new Date(currentYear, 4 - 1, 1); // April is represented as 3 because months are zero-indexed
    const marchThirtyFirst = new Date(nextYear, 3 - 1, 31); // March is represented as 2 for the next year
  
    const formattedAprilDate = formatDate(aprilFirst);
    const formattedMarchDate = formatDate(marchThirtyFirst);
  
    return [formattedAprilDate, formattedMarchDate];
  }
  //to check year is there in selected year or not
  function isFirstDateSameYear(dateArray, targetYear) {
    if (dateArray.length === 0) {
      return false; // Return false if the array is empty
    }
  
    const [firstDateString] = dateArray;
    const [day, month, year] = firstDateString.split('-').map(Number);
  
    return year >= targetYear;
  }
  //to give me quarter range for selected data
  function getMonthRangeDates(startMonthName, endMonthName, inputYear) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const startMonthNumber = monthNames.indexOf(startMonthName);
    const endMonthNumber = monthNames.indexOf(endMonthName);
  
    if (startMonthNumber === -1 || endMonthNumber === -1) {
      throw new Error('Invalid month name');
    }
  
    const startDate = new Date(inputYear, startMonthNumber, 1);
    const lastDayOfMonth = new Date(inputYear, endMonthNumber + 1, 0);
    const endDate = new Date(inputYear, endMonthNumber, lastDayOfMonth.getDate());
  
    const formatDate = date => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
  
    return [formatDate(startDate), formatDate(endDate)];
  }
  //gives quarter range for specific year
  function getquarterRangeForSpecificYear([startDate, endDate],inputYear) {
    const [startDay, startMonth, startYear] = startDate.split("-").map(Number);
    const [endDay, endMonth, endYear] = endDate.split("-").map(Number);

    const previousYearStartDate = new Date(
      inputYear,
      startMonth - 1,
      startDay
    );
    const previousYearEndDate = new Date(inputYear, endMonth - 1, endDay);

    const formatDateString = (date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
    
    return [
      formatDateString(previousYearStartDate),
      formatDateString(previousYearEndDate),
    ];
  }
  //gives month range for specific year
  function getMonthDatesFromName(monthName, inputYear) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const monthNumber = monthNames.indexOf(monthName);
  
    if (monthNumber === -1) {
      throw new Error('Invalid month name');
    }
  
    const startDate = new Date(inputYear, monthNumber, 1);
    const lastDayOfMonth = new Date(inputYear, monthNumber + 1, 0);
    const endDate = new Date(inputYear, monthNumber, lastDayOfMonth.getDate());
  
    const formatDate = date => {
      const day = date.getDate().toString().padStart(2, '0');
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const formattedYear = date.getFullYear();
      return `${formattedYear}-${formattedMonth}-${day}`;
    };
  
    return [formatDate(startDate), formatDate(endDate)];
  }
  //gives compared month range for specific year
  function adjustYearToDateRange(dateRange, inputYear) {
    const [startDate, endDate] = dateRange.map(dateString => {
      const [day, month, year] = dateString.split('-').map(Number);
      return new Date(inputYear, month - 1, day); // Subtracting 1 for the zero-based month
    });
  
    const formatDate = date => {
      const day = date.getDate().toString().padStart(2, '0');
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const formattedYear = date.getFullYear();
      return `${formattedYear}-${formattedMonth}-${day}`;
    };
  
    return [formatDate(startDate), formatDate(endDate)];
  }


  //financial year props and status

  const [isFinancialYearEnabled, setIsFinancialYearEnabled] = useState(false);
  const handleFinancialYearToggle = () => {
    setIsFinancialYearEnabled(!isFinancialYearEnabled);
  };

  //useEffect
  useEffect(() => {
    setCalendarDates(generateMonthDatesWithExtras(calendarDateTimeFrame));
    // if (originalSelectedDate.getMonth() === calendarDateTimeFrame.getMonth() && originalSelectedDate.getFullYear() === calendarDateTimeFrame.getFullYear()){

    // } else {
    // }
  }, [calendarDateTimeFrame]);

  //components
  const DateComponent = ({ status, dateNumber = "0" }) => {
    return (
      <>
        {status === "today" ? (
          <>
            <div class="todayDate">
              <p>{dateNumber}</p>
            </div>
          </>
        ) : status === "selected" ? (
          <>
            <div class="selectedDate">{dateNumber}</div>
          </>
        ) : status === "rangeMiddle" ? (
          <>
            <div class="rangeMiddleDate">{dateNumber}</div>
          </>
        ) : status === "rangeStart" ? (
          <>
            <div class="rangeStartDate">{dateNumber}</div>
          </>
        ) : status === "rangeEnd" ? (
          <>
            <div class="rangeEndDate">{dateNumber}</div>
          </>
        ) : status === "null" ? (
          <>
            <div class="nullDate">{dateNumber}</div>
          </>
        ) : status === "disabled" ? (
          <>
            <div class="disabledDate">{dateNumber}</div>
          </>
        ) : status === "weekDays" ? (
          <>
            <div class="weekDaysDate">{dateNumber}</div>
          </>
        ) : (
          <>
            <div class="defaultDate">{dateNumber}</div>
          </>
        )}
      </>
    );
  };

  const YearComponent = () => {
    return <>Year</>;
  };

  //calendar
  const CalendarGrid = ({ monthDatesWithExtras }) => {
    // Function to group dates into an array of arrays (7 days per array)
    const groupDatesByWeek = (dates) => {
      const weeks = [];
      for (let i = 0; i < dates.length; i += 7) {
        weeks.push(dates.slice(i, i + 7));
      }
      return weeks;
    };

    // Group the dates by week
    const weeks = groupDatesByWeek(monthDatesWithExtras);

    // Days of the week
    const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

    return (
      <div className="calendar-grid">
        <div className="week-row">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="day-cell day-of-week">
              <DateComponent status={"weekDays"} dateNumber={day} />
            </div>
          ))}
        </div>
        {weeks.map((week, index) => (
          <div key={index} className="week-row">
            {week.map((date, dateIndex) => (
              <div key={dateIndex} className="day-cell">
                <button
                  onClick={() => {
                    if (
                      isDateInRange(
                        date,
                        new Date(restrictionStart),
                        new Date(restrictionEnd)
                      )
                    ) {
                      setOriginalSelectedDate(date);
                    }
                  }}
                >
                  <DateComponent
                    status={
                      !isDateInRange(
                        date,
                        new Date(restrictionStart),
                        new Date(restrictionEnd)
                      )
                        ? "disabled"
                        : isSelectedDate(date)
                        ? "selected"
                        : isToday(date)
                        ? "today"
                        : !isSelectedMonth(date)
                        ? "disabled"
                        : ""
                    }
                    dateNumber={date.getDate()}
                  />
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const CalendarMonthGrid = () => {
    return (
      <>
        <div class="monthCalendarGrid">
          <div class="divider"></div>
          {months.map((month, key) => {
            return (
              <>
                <button
                  onClick={() => {
                    if (
                      isMonthInDateRange(
                        month,
                        calendarDateTimeFrame.getFullYear(),
                        new Date(restrictionStart),
                        new Date(restrictionEnd)
                      )
                    ) {
                      setCalendarDateTimeFrame(
                        setMonthByName(calendarDateTimeFrame, month)
                      );
                      if (onlyMonth) {
                        setSelectedDateArray(getMonthDatesFromName(month, calendarDateTimeFrame.getFullYear()));
                        setShowCalendar(false)
                      } else {
                        setCalendarGenre("datePicker");
                      }
                    }
                  }}
                >
                  <div>
                    {!isMonthInDateRange(
                      month,
                      calendarDateTimeFrame.getFullYear(),
                      new Date(restrictionStart),
                      new Date(restrictionEnd)
                    ) ? (
                      <>
                        <div class="disabledMonthCalendarGridButton">
                          <div>{month}</div>
                        </div>
                      </>
                    ) : getMonthNameFromDate(calendarDateTimeFrame) ===
                      month ? (
                      <>
                        <div class="monthCalendarGridButton selectedMonth">
                          <div>{month}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="monthCalendarGridButton">
                          <div>{month}</div>
                        </div>
                      </>
                    )}
                  </div>
                </button>
              </>
            );
          })}
        </div>
      </>
    );
  };
  const CalendarQuarterGrid = () => {
    return (
      <>
        <div class="monthCalendarGrid">
          <div class="divider"></div>
          {quarter.map((quarter, key) => {
            return (
              <>
                <button
                  onClick={() => {
                    const range = getStartAndEndDate(
                      getMonthNumberFromName(quarter.monthStart),
                      getMonthNumberFromName(quarter.monthEnd),
                      calendarDateTimeFrame.getFullYear()
                    );
                    if (activeDateArray === "selected"){
                      setSelectedDateArray(getMonthRangeDates(quarter.monthStart, quarter.monthEnd, calendarDateTimeFrame.getFullYear()))
                      setShowCalendar(false)
                    }
                    setOriginalSelectedDate(range.inputStartDate);
                  }}
                >
                  <div>
                    {(getMonthNameFromDate(originalSelectedDate) ===
                      quarter.monthStart ||
                      getMonthNameFromDate(originalSelectedDate) ===
                        quarter.monthMid ||
                      getMonthNameFromDate(originalSelectedDate) ===
                        quarter.monthEnd) &&
                    originalSelectedDate.getFullYear() ===
                      calendarDateTimeFrame.getFullYear() ? (
                      <>
                        <div class="monthCalendarGridButton selectedMonth">
                          <div>
                            {quarter.monthStart} - {quarter.monthEnd}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="monthCalendarGridButton">
                          <div>
                            {quarter.monthStart} - {quarter.monthEnd}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </button>
              </>
            );
          })}
        </div>
      </>
    );
  };

  const CalendarYearGrid = () => {
    const yearRange = generateYearRange(calendarDateTimeFrame);
    return (
      <>
        <div class="yearCalendarGrid">
          <div class="divider"></div>
          {yearRange.map((year, key) => {
            return (
              <>
                <button
                  onClick={() => {
                    if (
                      isYearInDateRange(
                        year,
                        new Date(restrictionStart),
                        new Date(restrictionEnd)
                      )
                    ) {
                      setCalendarDateTimeFrame(
                        setYear(calendarDateTimeFrame, year)
                      );
                      if (calendarType === "single" && onlyYear) {
                        if (isFinancialYearEnabled){
                          if (activeDateArray === "selected"){
                            setSelectedDateArray(
                              getAprilAndMarchDates(
                                setYear(calendarDateTimeFrame, year)
                              )
                            );
                          } else if (activeDateArray === "compared"){
                            setSelectedComparedDateArray(
                              getAprilAndMarchDates(
                                setYear(calendarDateTimeFrame, year)
                              )
                            );
                          }
                        } else {
                          if (activeDateArray === "selected"){
                            setSelectedDateArray(
                              getDateAndNextYear(
                                setYear(calendarDateTimeFrame, year)
                              )
                            );
                          } else if (activeDateArray === "compared"){
                            setSelectedComparedDateArray(
                              getDateAndNextYear(
                                setYear(calendarDateTimeFrame, year)
                              )
                            );
                          }
                        }
                        setShowCalendar(false)
                      } else if (calendarType === "single" && onlyMonth) {
                        if (activeDateArray === "selected"){
                          setCalendarGenre("monthPicker");
                        } else if (activeDateArray === "compared"){
                          setSelectedComparedDateArray(adjustYearToDateRange(selectedDateArray,year))
                          setShowCalendar(false)
                        }
                      } else if (calendarType === "quarter") {
                        if (activeDateArray === "selected"){
                          setCalendarGenre("quarterPicker");
                        } else if (activeDateArray === "compared"){
                          setSelectedComparedDateArray(getquarterRangeForSpecificYear(selectedDateArray,year))
                          setShowCalendar(false)
                        }
                      }
                    }
                  }}
                >
                  <div>
                    {!isYearInDateRange(
                      year,
                      new Date(restrictionStart),
                      new Date(restrictionEnd)
                    ) || (activeDateArray === "compared" && isFirstDateSameYear(selectedDateArray, year)) ? (
                      <>
                        <div class="disabledYearCalendarGridButton">
                          <div>{year}</div>
                        </div>
                      </>
                    ) : getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      ) === year ? (
                      <>
                        <div class="yearCalendarGridButton selectedYear">
                          <div>{year}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="yearCalendarGridButton">
                          <div>{year}</div>
                        </div>
                      </>
                    )}
                  </div>
                </button>
              </>
            );
          })}
        </div>
      </>
    );
  };

  const DatePicker = () => {
    return (
      <>
        <div class="calendarModal">
          <div class="calendarModalHeader calendar-grid">
            <div className="yearMonthRow">
              {!onlyYear && !onlyMonth ? (
                <>
                  {/* month row */}
                  <div className="yearRow">
                    {/* month arrow */}
                    {calendarGenre === "datePicker" ? (
                      <button
                        onClick={() => {
                          addMonthsToDate(calendarDateTimeFrame, -1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          class="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          />
                        </svg>
                      </button>
                    ) : (
                      <></>
                    )}
                    {/* month name */}
                    {calendarGenre === "datePicker" ? (
                      <button
                        onClick={() => {
                          setCalendarGenre("monthPicker");
                        }}
                        class="monthSelection"
                      >
                        <div>{getMonthNameFromDate(calendarDateTimeFrame)}</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          class="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                          />
                        </svg>
                      </button>
                    ) : calendarGenre === "monthPicker" ? (
                      <button
                        onClick={() => {
                          setCalendarGenre("datePicker");
                        }}
                        class="monthSelection"
                      >
                        <div>{getMonthNameFromDate(calendarDateTimeFrame)}</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          class="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                          />
                        </svg>
                      </button>
                    ) : calendarGenre === "yearPicker" ? (
                      <button
                        onClick={() => {
                          setCalendarGenre("monthPicker");
                        }}
                        class="monthSelection disabled"
                      >
                        <div>{getMonthNameFromDate(calendarDateTimeFrame)}</div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          class="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                          />
                        </svg>
                      </button>
                    ) : (
                      <></>
                    )}
                    {/* month arrow */}
                    {calendarGenre === "datePicker" ? (
                      <button
                        onClick={() => {
                          addMonthsToDate(calendarDateTimeFrame, 1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          data-slot="icon"
                          class="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* year row */}
              <div className="monthRow">
                {/* year arrow */}
                {calendarGenre === "datePicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, -1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "monthPicker" && onlyMonth ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, -1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, -1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
                {/* year name */}
                {calendarGenre === "datePicker" ? (
                  <button
                    onClick={() => {
                      setCalendarGenre("yearPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "monthPicker" ? (
                  <button
                    onClick={() => {
                      setCalendarGenre("yearPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      if (!onlyYear){
                        setCalendarGenre("monthPicker");
                      }
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setCalendarGenre("yearPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                )}
                {/* year arrow */}
                {calendarGenre === "datePicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "monthPicker" && onlyMonth ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {calendarGenre === "datePicker" ? (
              <>
                <CalendarGrid monthDatesWithExtras={calendarDates} />
              </>
            ) : calendarGenre === "monthPicker" ? (
              <>
                <CalendarMonthGrid />
              </>
            ) : calendarGenre === "yearPicker" ? (
              <>
                <CalendarYearGrid />
              </>
            ) : (
              <></>
            )}

            <div class="modalButtonRow">
              {financialToggle && calendarGenre === "yearPicker" ? (
                <>
                  <div className="flex flex-row w-full">
                    <input
                      className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={isFinancialYearEnabled}
                      onChange={handleFinancialYearToggle}
                    />
                    <label
                      className="inline-block pl-[0.15rem] hover:cursor-pointer"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Financial Year
                    </label>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* <div class="cancelOkButtonRow">
                <button
                  class="calendarModalButton"
                  onClick={() => {
                    setShowCalendar(false);
                  }}
                >
                  Cancel
                </button>
                <button class="calendarModalButton">Ok</button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const QuarterPicker = () => {
    return (
      <>
        <div class="calendarModal">
          <div class="calendarModalHeader calendar-grid">
            <div className="yearMonthRow">
              {/* quarter row */}

              {/* <div className="yearRow">
                {calendarGenre === "quarterPicker" ? (
                  <button
                    onClick={() => {
                    }}
                    class="monthSelection"
                  >
                    <div>{getQuarter(calendarDateTimeFrame)}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button onClick={() => {
                    setCalendarGenre("quarterPicker")
                    }}
                    class="monthSelection disabled"
                  >
                    <div>{getQuarter(calendarDateTimeFrame)}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div> */}

              {/* year row */}
              <div className="monthRow">
                {/* year arrow */}
                {calendarGenre === "quarterPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, -1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, -1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
                {/* year name */}
                {calendarGenre === "quarterPicker" ? (
                  <button
                    onClick={() => {
                      setCalendarGenre("yearPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      setCalendarGenre("quarterPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setCalendarGenre("yearPicker");
                    }}
                    class="monthSelection"
                  >
                    <div>
                      {getYearNumberFromDateFormattedString(
                        calendarDateTimeFrame
                      )}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                      />
                    </svg>
                  </button>
                )}
                {/* year arrow */}
                {calendarGenre === "quarterPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                ) : calendarGenre === "yearPicker" ? (
                  <button
                    onClick={() => {
                      addYearsToDate(calendarDateTimeFrame, 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      data-slot="icon"
                      class="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {calendarGenre === "quarterPicker" ? (
              <>
                <CalendarQuarterGrid />
              </>
            ) : calendarGenre === "yearPicker" ? (
              <>
                <CalendarYearGrid />
              </>
            ) : (
              <></>
            )}

            {/* <div class="modalButtonRow">
              <button
                class="calendarModalButton"
                onClick={() => {
                  setShowCalendar(false);
                }}
              >
                Cancel
              </button>
              <button class="calendarModalButton">Ok</button>
            </div> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="pl-2">
        {/* <button
          onClick={() => {
            showCalendar ? setShowCalendar(false) : setShowCalendar(true);
          }}
          class="calendarButton"
        >
          Show Calendar
        </button> */}

        <svg
          onClick={() =>
            {
              showCalendar ? setShowCalendar(false) : setShowCalendar(true)
              setCalendarGenre("yearPicker")
            }
          }
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg>
        {showCalendar ? (
          //calendar block
          <div class="calendarBlock" ref={ref}>
            {/* typeOfCalendar  */}
            {calendarType === "single" ? (
              <DatePicker />
            ) : calendarType === "quarter" ? (
              <QuarterPicker />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CustomCalander;
