import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import OtaCards from "../../components/otaCards";
import AreaChart from "../../components/charts/areaChart";
import DynamicTable from "../../components/dynamicTable";
import Calendar from "../../components/calendar";
import {
  useGetVisibilityCalendarQuery,
  useGetVisibilityLeaderboardQuery,
  useGetVisibilityOverviewQuery,
  useGetVisibilityTrendQuery,
} from "../../redux/slices/visibilityRank";
import CustomSkeleton from "../../components/Skeleton";
import RateVsCompetition from "../../test/RatevsCompetition";
import { format } from "date-fns";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { useGetFiltersQuery } from "../../redux/slices/dashboard";
import BackdropLoader from "../../components/newLoader";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "../../components/modal";
import Table from "../../components/table";
import { BackButton } from "../rateSuggestion/rateAnalysis";
import ExcelDownloadButton from "../../components/excelDownloadButton";
import { Tooltip } from "react-tooltip";
import { ActiveOtaOptions } from "../../utils/otaOptions";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import { useIsIpad } from "../../utils/isIpad";

const VisibilityRank = () => {
  const otaOptions = ActiveOtaOptions();
  const [selectedOtaVisibilityCalendar, setSelectedOtaVisibilityCalendar] =
    useState(otaOptions && otaOptions[0]);
  const [visibilityTrendDate, setVisibilityTrendDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [visibilityDate, setVisibilityDate] = React.useState(new Date());
  const [selectedOption, setSelectedOption] = useState();
  const [visibilityTable, setVisibilityTable] = useState();
  const { data: filterData } = useGetFiltersQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const { data: rateVisibilityRank, isLoading } = useGetVisibilityOverviewQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      type: selectedOption?.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: visibilityTrend,
    isFetching: visibilityTrendIsFetching,
    isLoading: visibilityTrendIsLoading,
  } = useGetVisibilityTrendQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: selectedIndex,
      type: selectedOption?.value,
      startDate: format(new Date(visibilityTrendDate), "yyyy-MM-dd"),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: visibilityCalender,
    isFetching: visibilityCalenderIsFetching,
    isLoading: visibilityCalenderIsLoading,
  } = useGetVisibilityCalendarQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: selectedIndex,
      type: selectedOption?.value,
      startDate: format(new Date(visibilityDate), "yyyy-MM-dd"),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: visibilityLeaderBoard,
    isLoading: visibilityLeaderboardIsLoading,
  } = useGetVisibilityLeaderboardQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: selectedIndex,
      type: selectedOption?.value,
      startDate: format(new Date(visibilityTrendDate), "yyyy-MM-dd"),
    },
    { refetchOnMountOrArgChange: true }
  );

  const data = [
    {
      otas: "",
      title: "MakeMyTrip",
      price: 3432,
    },
    {
      otas: "",
      title: "GoIbibo",
      price: 3432,
    },
    {
      otas: "",
      title: "Agoda",
      price: 3432,
    },
    {
      otas: "",
      title: "Booking.com",
      price: 3432,
    },
    {
      otas: "",
      title: "Expedia",
      price: 3432,
    },
  ];
const isIpad=useIsIpad()
  // Extract unique dates from the data
  const dates = visibilityCalender && [
    ...new Set(visibilityCalender["data"].slice(0, 7).map((item) => item.date)),
  ];

  // Dynamically create columns based on dates
  const columns1 = React.useMemo(() => {
    const dateColumns =
      dates &&
      dates.map((date, index) => {
        return {
          Header: format(new Date(date), "dd MMM yy"),
          accessor: date, // Set accessor to 'rank',
          width: "100%",
          Cell: ({ row, value }) => {
            return (
              <div>
                {row?.original[date] === 9999 ? "N/A" : row?.original[date]}
              </div>
            );
          },
        };
      });
    return [
      {
        Header: "Hotel name",
        accessor: "hotelName",
        width: "100%",
        Cell: ({ row ,index}) => {

          return (
            <div
              className={` ${isIpad?'max-w-[80px]':'max-w-[120px]'  } truncate`}
              data-tooltip-id={`row?.original?.hotelName${row?.id}`}
              data-tooltip-content={row?.original?.hotelName}
            >
              {row?.original?.hotelName}
              <Tooltip id={`row?.original?.hotelName${row?.id}`} place="right" />
            </div>
          );
        },
      },
      ...(dateColumns || []),
    ];
  }, [dates]);

  // Transform the data to fit the column structure

  function groupByHotel(data) {
    const groupedHotels = {};
    data.forEach((entry) => {
      const { hId, hotelName, rank, date } = entry;
      const key = `${hotelName}_${hId}`;
      if (!groupedHotels[key]) {
        groupedHotels[key] = { hotelName, hId, ranks: [] };
      }
      groupedHotels[key].ranks.push({ rank, date });
    });
    return Object.values(groupedHotels);
  }
  function filterUniqueHotels(hotels) {
    // Create an object to store unique hotel names
    const uniqueHotelNames = {};

    // Iterate through the array of hotel objects
    for (const hotel of hotels) {
      // Extract the hotelName property
      const hotelName = hotel.hotelName;

      // If the hotelName is not already in the uniqueHotelNames object, add it
      if (!uniqueHotelNames[hotelName]) {
        uniqueHotelNames[hotelName] = hotel;
      }
    }

    // Extract values (hotel objects) from the uniqueHotelNames object and return them as an array
    return Object.values(uniqueHotelNames);
  }

  const transformedData = React.useMemo(() => {
    let transformed = [];
    visibilityCalender &&
      visibilityCalender["data"].forEach((item) => {
        const rowData = { hotelName: item.hotelName };
        dates.forEach((date) => {
          const matchingItem =
            visibilityCalender &&
            visibilityCalender?.["data"].find(
              (d) => d.date === date && d.hotelName === item.hotelName
            );
          rowData[date] = matchingItem ? matchingItem.rank : "";
        });
        transformed.push(rowData);
      });
    transformed = filterUniqueHotels(transformed);
    // transformed = groupByHotel(visibilityCalender["data"])
    return transformed;
  }, [visibilityCalender && visibilityCalender["data"], dates]);

  const graphLabel =
    visibilityTrend &&
    visibilityTrend?.data?.visibilityTrend?.map((item) => {
      return format(new Date(item.date), "dd-MMM-yy");
    });
  const graphValue =
    visibilityTrend &&
    visibilityTrend?.data?.visibilityTrend.map((item) => {
      return item.rank === 9999 ? 0 : item.rank;
    });

  const column = [
    {
      name: "Rank",
      selector: (row) => row["rank"] === 9999 ? `100+` : row["rank"],
    },
    {
      name: "Date",
      selector: (row) => format(new Date(row["date"]), "dd-MMM-yy"),
    },
  ];

  useEffect(() => {
    if (
      rateVisibilityRank &&
      rateVisibilityRank?.["data"]?.visibilityOverview
    ) {
      setSelectedIndex(
        rateVisibilityRank?.["data"]?.visibilityOverview?.slice()?.sort((a, b) => {
          if(a?.rank !== 9999 || b?.rank !== 9999){
            return a?.rank - b?.rank;
          }
        
        })[0]?.otaId
      );
    }
  }, [rateVisibilityRank]);
  return (
    <div>
      {visibilityTable && (
        <Modal>
          <div className="lg:w-[80%] md:w-[90%] w-[95%] h-fit [80%] relative sm:pt-5 sm:pb-5 sm:pl-5 sm:pr-5 px-5 pt-5 pb-10 rounded-md bg-white">
            <div className="mb-2 text-lg font-medium flex justify-between pr-6">
              <p>{"Visibility Trend"}</p>
              <p>
                <ExcelDownloadButton
                  filename={"Visibiltiy Trend"}
                  data={visibilityTrend?.data?.visibilityTrend}
                />
              </p>
            </div>

            <div className="mt-5" onClick={(e) => e.stopPropagation()}>
              {
                <Table
                  columns={column}
                  data={visibilityTrend?.data?.visibilityTrend}
                  pagination={true}
                />
              }
            </div>

            <div
              className="absolute h-fit w-fit right-2 sm:right-4 sm:top-4 bottom-[6px] sm:bottom-0  font-semibold cursor-pointer hover:text-red-500 flex items-center"
              onClick={() => {
                setVisibilityTable(false);
              }}
            >
              <AiOutlineCloseCircle size={24} />
            </div>
          </div>
        </Modal>
      )}
      <BackdropLoader
        loading={
          isLoading ||
          visibilityTrendIsFetching ||
          visibilityTrendIsLoading ||
          visibilityCalenderIsLoading ||
          visibilityLeaderboardIsLoading ||
          visibilityCalenderIsFetching
        }
      />
      <div className="flex justify-between w-[100%]">
        <div className="text-[17px] text-white dark:text-black p-4 flex items-center">
          {/* <BackButton dashboard={true} /> */}
           Overview{" "}
          {rateVisibilityRank?.data?.asPer && (
            <span className="text-[#8794AD] dark:text-black text-[12px] ml-1 mt-1">
              as per{" "}
              {rateVisibilityRank?.data?.asPer &&
                format(
                  new Date(rateVisibilityRank?.data?.asPer),
                  "dd MMM yyyy"
                )}
            </span>
          )}
        </div>
        <div className="p-4 flex gap-4">
          <Dropdown
            selectedItem={
              selectedOption?.label || filterData?.data[2]?.types[0]?.label
            }
            setSelectedItems={setSelectedOption}
            options={filterData?.data[2]?.types}
            right={'2px'}
          />
          {/* <div className="dark:bg-[#252C3B] bg-[#CCD6E5] cursor-pointer flex items-center h-[28] px-2 rounded-md">
            <img src={filterIcon} />
          </div> */}
        </div>
      </div>
      <div>
        <div className="flex gap-4 p-4 max-w-[100%] scrollHide overflow-x-scroll">
          {!isLoading ? (
            rateVisibilityRank?.["data"]?.visibilityOverview
              ?.slice()
              ?.sort((a, b) => {
                if(a?.rank !== 9999 || b?.rank !== 9999){
                  return a?.rank - b?.rank;
                }
              
              })
              ?.map((ota, index) => {
                return (
                  <OtaCards
                    index={ota?.otaId}
                    onClick={() => setSelectedIndex(ota?.otaId)}
                    rank={ota?.rank === 9999 ? "--" : ota?.rank}
                    title={ota?.otaName}
                    selectedIndex={selectedIndex}
                    data={data}
                    otaLogo={ota?.otaImage}
                  />
                );
              })
          ) : (
            <div className="w-[100%]">
              {" "}
              <CustomSkeleton height={70} width={"100%"} />
            </div>
          )}
        </div>
      </div>
      <div className="w-[100%] px-4 gap-4 flex flex-col md:flex-row justify-between">
        {graphLabel && graphValue ? (
          <div className="w-full md:w-[70%] bg-newGradient  dark:bg-lightGradient  drop-shadow-lg backdrop-blur-md rounded-lg dark:bg-mainDark">
            <div className="flex justify-between w-[100%]">
              <div className="p-4  text-[14px] text-[#ffffff]">
                Visibility Trend{" "}
                {visibilityTrend?.data?.asPer && (
                  <span className="text-[#f2f3f5] text-[12px] ml-1 mt-1">
                    as per{" "}
                    {visibilityTrend?.data?.asPer &&
                      format(
                        new Date(visibilityTrend?.data?.asPer),
                        "dd MMM yyyy"
                      )}
                  </span>
                )}
              </div>
              <div className="p-4 flex gap-4">
                <Calendar
                  selectedDate={visibilityTrendDate}
                  setSelectedDate={setVisibilityTrendDate}
                  onlyPastDaysSelectable={true}
                />
                {/* <Dropdown selectedItem={"MTD"} /> */}
                <div className="mr-2 flex items-center">
                  <InfoToolTip
                    content={
                      "This feature tracks the visibility of the property across different online channels and platforms over a specified period."
                    }
                    index={"ddd"}
                    position={"left"}
                  />
                </div>
              </div>
            </div>
            <AreaChart
              xaxisShow={true}
              yaxisShow={true}
              height={256}
              currentSeriesData={graphValue}
              currentSeriesName={graphLabel}
              showSingleSeries={true}
              downloadButtonData={visibilityTrend?.data?.visibilityTrend}
              fileName={"Visibility Trend Report"}
              setTableState={() => {
                setVisibilityTable(true);
              }}
              currentLabel={"Rank"}
              reversed={true}
              textWhite={true}
            />
          </div>
        ) : (
          <div className="w-full md:w-[70%]">
            {" "}
            <CustomSkeleton height={350} width={"100%"} />
          </div>
        )}
        <div className="w-full md:w-[30%]">
          {visibilityLeaderBoard &&
          visibilityLeaderBoard?.["data"] &&
          !visibilityLeaderboardIsLoading ? (
            <RateVsCompetition
              title={"Visibility Leaderboard"}
              data={visibilityLeaderBoard?.["data"] ?.slice()
              ?.sort((a, b) => {
                if(a.todayRank !== 9999 || b.todayRank !== 9999){
                  return a.todayRank - b.todayRank;
                }
              
              })}
              asPer={visibilityTrend?.data?.asPer}
              toolTipText={
                "This leaderboard provides a comparative analysis of the property's visibility against competitors within the market across various online channels and platforms."
              }
              showPercentage={true}
            />
          ) : (
            <div className="w-[100%]">
              {" "}
              <CustomSkeleton height={350} width={"100%"} />
            </div>
          )}
        </div>
      </div>
      {visibilityCalender && transformedData ? (
        <div className="m-4 p-4  dark:bg-lightGradient  rounded-xl bg-newGradient drop-shadow-lg backdrop-blur-md  dark:bg-mainDark">
          <div className="flex justify-between items-center w-[100%]">
            <div className="text-[17px] text-white p-4 ">
              Visibility Calendar{" "}
              {visibilityCalender?.data?.asPer && (
                <span className="text-[#8794AD] text-[12px] ml-1 mt-1">
                  as per{" "}
                  {visibilityCalender?.data?.asPer &&
                    format(
                      new Date(visibilityCalender?.data?.asPer),
                      "dd MMM yyyy"
                    )}
                </span>
              )}{" "}
            </div>
            <div className="p-4 flex gap-4">
              <Calendar
                selectedDate={visibilityDate}
                setSelectedDate={setVisibilityDate}
                disablePastDays={false}
                onlyPastDaysSelectable={true}
                enableDaysFromNow={0}
              />
            </div>
          </div>
          {visibilityCalender && transformedData && (
            <DynamicTable data={transformedData} columns={columns1} />
          )}
        </div>
      ) : (
        <div className="w-[100%] m-4">
          {" "}
          <CustomSkeleton height={350} width={"98%"} />
        </div>
      )}
    </div>
  );
};

export default VisibilityRank;
