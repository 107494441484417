import { emptySplitApi } from "../../injectEndpoints";

export const SettingsAccount_UserInterface = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInterface: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getAllUserInterface`,
          method: "GET",
        //   body: data.formData,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserInterface"],
    }),
    getUserInterfaceCurrencies: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getCurrenciesListForUserInterface`,
          method: "GET",
        //   body: data.formData,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserInterface"],
    }),
    getUserInterfaceById: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getUserInterface`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getAllUserInterface"],
    }),
    editUserInterfaceById: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editUserInterface?userInterfaceId=${data?.userInterfaceId}`,
          method: "PATCH",
          body:data?.useInterfaceBody
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editUserInterface"],
    }),
    // getUserDetails: builder.query({
    //   query: (data) => {
    //     return {
    //       url: `/userDashboardSetting/getUserProfile`,
    //       method: "GET",
    //       params: {
    //         userId: data.userId,
    //       },
    //     };
    //   },
    //   // Replace with your actual endpoint
    //   providesTags: ["getUsers"],
    // }),
  }),
  overrideExisting: false,
});

export const { useGetUserInterfaceQuery,useGetUserInterfaceByIdQuery, useEditUserInterfaceByIdMutation, useGetUserInterfaceCurrenciesQuery } = SettingsAccount_UserInterface;
