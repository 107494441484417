import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import eyeOpen from "../assets/icons/eyeOpen.svg";
import downloadBtn from "../assets/icons/downloadBtn.svg";
import dangerIcon from "../assets/icons/dangerIcon.svg";
import { toast } from "react-toastify";
import {
  AiFillCaretDown,
  AiFillCloseCircle,
  AiFillInfoCircle,
  AiOutlineArrowRight,
  AiOutlineArrowsAlt,
  AiOutlineCheck,
  AiOutlineDown,
  AiOutlineExclamationCircle,
  AiOutlineFileText,
  AiOutlinePlus,
  AiOutlineWarning,
} from "react-icons/ai";
import AreaChart from "../components/charts/areaChart";
import { TagCloud } from "react-tagcloud";
import ReactStars from "react-stars";
import Backdrop from "../components/backdrop";
import Dropdown from "../components/dropdown";
import PaceReport from "./dashboard/Tables/PaceReport";
import RevenueReportTable from "./dashboard/Tables/RevenueReport";
import channelImg from "../assets/channelPerformance.svg";
import eventsImg from "../assets/eventsImg.svg";
import cancellationImg from "../assets/cancellationImg.svg";
import sentimental from "../assets/sentimentalImg.svg";
import reputationImg from "../assets/reputation.svg";
import {
  useGetForecastedDataQuery,
  useGetPerformanceMatricesQuery,
  useGetSentimentalAnalysisQuery,
  useGetDropDownDataQuery,
  usePostQuickReportMutation,
  useGetQuickReportQuery,
  useGetAdvertisementDataQuery,
  useGetQuickActionQuery,
  useGetRatingsQuery,
  useDashboardUrgentActionQuery,
  useGetChannelManagerFaultsQuery,
  useGetHotelPerformanceDashboardQuery,
  useGetHotelPerformanceDetailQuery,
} from "../redux/slices/dashboard";
import { CurrentProperty, UserId } from "../utils/impVars";
import BackdropLoader from "../components/newLoader";
import { calculatePercentage } from "../utils/calculatePercentage";
import Notification from "../components/notificationCard/Notification";
import { format } from "date-fns";
import {
  useGetActionsToTakeQuery,
  useGetAllWidgetQuery,
  useGetWidgetsQuery,
  usePostWidgetsMutation,
  useGetCancellationQuery,
  useGetUpcomingEventQuery,
  useGetChannelQuery,
  useAcceptRateMutation,
  useVerifyRatesMutation,
} from "../redux/slices/newDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import formatCurrency from "../utils/formatCurrency";
import SolutionTile from "../components/newDashboard/solutionTitle";
import { useGetLoginInfoQuery } from "../redux/slices/settings/userManagement";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData } from "../redux/states/login";
import InfoToolTip from "../components/tooltip/InfoToolTip";
import SourceReport from "./dashboard/Tables/SourceReport";
import SegmentReport from "./dashboard/Tables/SegmentReport";
import QuarterlyReport from "./dashboard/Tables/QuarterlyReport";
import ParityReport from "./dashboard/Tables/ParityReport";
import {
  useGetMonthEndReportQuery,
  useGetPaceReportQuery,
  useGetParityReportQuery,
  useGetPickUpReportQuery,
  useGetQuarterlyReportQuery,
  useGetRateChangeReportQuery,
  useGetRatesReportQuery,
  useGetRevenueReportQuery,
  useGetSegmentReportQuery,
  useGetSourceReportQuery,
  useGetYearEndReportQuery,
} from "../redux/slices/reports";
import { DownloadMonthEndReport } from "./dashboard/DownloadExcel/MonthEndReport";
import { DownloadRevenueReport } from "./dashboard/DownloadExcel/RevenueReport";
import { DownloadSourceReport } from "./dashboard/DownloadExcel/SourceReport";
import { DownloadSegmentReport } from "./dashboard/DownloadExcel/SegmentReport";
import { DownloadQuaterlyReport } from "./dashboard/DownloadExcel/QuaterlyReport";
import { DownloadPaceReport } from "./dashboard/DownloadExcel/PaceReport";
import { DownloadParityReport } from "./dashboard/DownloadExcel/ParityReport";
import PickUpReport from "./dashboard/Tables/PickUpReport";
import YearEndReport from "./dashboard/Tables/YearEndReport";
import MonthEndTable from "./dashboard/Tables/MonthEndReport";
import { YearEndReportdownload } from "./dashboard/DownloadExcel/YearEndReport";
import CustomSkeleton from "../components/Skeleton";
import { useIsIpad } from "../utils/isIpad";
import { Tooltip } from "react-tooltip";
import { ratesReportDownload } from "./dashboard/DownloadExcel/RatesReport";
import RatesReport from "./dashboard/Tables/RatesReport";
import { downloadPickUPReport } from "./dashboard/DownloadExcel/pickupReprot";
import RazorpayPayment from "../components/razorpayPayment";
import { ActiveOtaOptions } from "../utils/otaOptions";
import useIntelligenceDateRange from "../utils/dateRange";
import useRatePulseDateRange from "../utils/ratepulseDateRange";
import OccupencyReport from "./dashboard/Tables/OccupencyReport";
import { useGetInventoryQuery } from "../redux/slices/ratesInventory";
import { DownloadOccupencyReport } from "./dashboard/DownloadExcel/OccupencyReport";
import revens from "../assets/icons/revens.svg";
import { useOverrideRateMutation } from "../redux/slices/rateSuggestions";
import { useChannelManagerListQuery } from "../redux/slices/onBoarding";
import passwordExpire from "../assets/icons/passwordExpire.svg";
import { useAddNewPropertyChannelManagerMutation } from "../redux/slices/settings/myProperties";
import Alert from "../components/alerts";
import PropertyName from "../utils/propertyName";
import CancellationReport from "./dashboard/Tables/CancellationReport";
import { DownloadCancellationReport } from "./dashboard/DownloadExcel/CancellationReport";
import RevensHey from "../assets/icons/revens.svg";
export const calculateDifference = (currentValue, pastValue, isCurrency) => {
  const diff = parseInt(currentValue) - parseInt(pastValue);
  return isCurrency ? formatCurrency(diff, { isShortFormat: true }) : diff;
};

function countCategoriesWithSolutions(data) {
  if (!data.status || data.code !== 200) {
    throw new Error("Invalid data");
  }

  let count = 0;

  data.data.forEach((task) => {
    if (task.solution && task.solution.length > 0) {
      count++;
    }
  });

  return count;
}

function transformApiData(apiData) {
  const transformedData = [
    ...apiData.positiveSentiment?.slice(0, 10).map((item) => ({
      value: (item?.keyword?.positive + " ").split(",")[0],
      count: item?.severityRating * 20,
      props: {
        title: item?.keyword,
        style: {
          color: "green",
        },
      },
    })),
    ...apiData?.negativeSentiment?.slice(0, 10).map((item) => ({
      value: (item?.keyword?.negative + " ").split(",")[0],
      count: item?.severityRating * 20,
      props: {
        title: item?.keyword,
        style: {
          color: "red",
        },
      },
    })),
  ];
  return transformedData;
}

const NewDashboard = () => {
  // const { data: SelectedWidgets, refetch: refetchWidget , isLoading } = useGetWidgetsQuery({
  //   userId: UserId(),
  //   hId: CurrentProperty(),
  // });
  const [showCM_Modal, setShowCM_Modal] = useState(false);
  const [actionCount, setActionCount] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const targetRef = useRef(null);
  const [addWidget, setAddWidget] = useState(false);
  const [propUser, setPropUser] = useState();
  const [selectedForcastedRange, setSelectedForeCastedRange] = useState({
    label: "7D",
    value: "7D",
  });
  const [getCmId, setGetCmId] = useState();
  const [timePeriod, setTimePeriod] = useState("past30Days");
  const [options, setOptions] = useState(null);
  const [selectedItem, setSelectedItem] = useState(
    (options && options[0]?.type) || "Revenue"
  );

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [rating, setRating] = useState(4.7);

  const colorOptions = {
    luminosity: "white",
    hue: "red",
  };

  const wordsData = [
    {
      value: "JavaScript",
      // count: 3,
      props: {
        title: "JavaScript",
        style: {
          color: "red",
        },
      },
    },
    {
      value: "React",
      count: 30,
      props: {
        title: "React",
        style: {
          color: "white",
        },
      },
    },
    { value: "Nodejs", count: 28 },
    { value: "Express.js", count: 25 },
    { value: "HTML5", count: 33 },
    { value: "MongoDB", count: 18 },
    {
      value: "CSS3",
      count: 20,
      props: {
        title: "CSS3",
        style: {
          color: "red",
        },
      },
    },
  ];

  const { data: loginInfo } = useGetLoginInfoQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });

  const {
    data: getPerformanceMatrices,
    isFetching: getPerformanceMatricesIsFetching,
    isLoading: getPerformanceMatricesIsLoading,
  } = useGetPerformanceMatricesQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      timePeriod: timePeriod,
      chartData:
        selectedItem?.toLowerCase() === "revenue"
          ? "revenue"
          : selectedItem?.toLowerCase() === "adr"
            ? "ADR"
            : selectedItem?.toLowerCase() === "room nights"
              ? "roomNights"
              : selectedItem?.toLowerCase() === "los"
                ? "los"
                : selectedItem?.toLowerCase() === "occupancy" && "occupancy",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: getSentimentalAnalysis,
    isLoading: sentimentalAnalysisIsLoading,
    isFetching: sentimentalAnalysisIsFetching,
  } = useGetSentimentalAnalysisQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });
  const {
    data: getForecastedData,
    isLoading: forecastIsloading,
    isFetching: forecastIsFetching,
    error,
  } = useGetForecastedDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      rangeType: selectedForcastedRange?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: (result) => ({
        ...result,
        error: result.error, // Ensure we have access to the error field
      }),
    }
  );
  const [nextPricingDate, setNextPricingDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  useEffect(() => {
    const handle401Error = async () => {
      if (error && error.status === 401) {
        await window.localStorage.clear();

        navigate("/login");

        window.location.reload();
      }
    };

    handle401Error();
  }, [error, navigate]);

  const {
    data: quickAction,
    isFetching: quickActionIsFetching,
    isLoading: quickActionIsLoading,
  } = useGetQuickActionQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });

  const [ShowCMModal, setShowCMModal] = useState(false);
  const isCMVerified = window.localStorage.getItem("isCMVerified");

  useEffect(() => {
    if (isCMVerified) {
      setShowCM_Modal(false);
    }
    if (isCMVerified === false) {
      setShowCM_Modal(true);
    }
  }, [isCMVerified]);

  useEffect(() => {
    if (showCM_Modal) {
      setTimeout(() => {
        setShowCMModal(true);
      }, [8000]);
    }
  }, [showCM_Modal]);

  useEffect(() => {
    if (loginInfo) {
      const cmId = loginInfo.data.propertiesList?.filter((item) => {
        return (
          item?.propertyName === loginInfo.data.currentProperty.propertyName
        );
      });

      const savedCmId = cmId ? cmId[0]?.cmId : "";
      if (savedCmId) {
        setGetCmId(savedCmId);
      }
      window.localStorage.setItem("isCMVerified", loginInfo.data.isCMVerified);
      setShowCM_Modal(loginInfo.data.isCMVerified === true ? false : true);
      window.localStorage.setItem(
        "rateshopperDateRange",
        JSON.stringify(loginInfo.data.dataDateRange.e)
      );
      window.localStorage.setItem(
        "intelligenceDateRange",
        JSON.stringify(loginInfo.data.dataDateRange.i)
      );
      setPropUser(loginInfo.data.name);
      // console.log('dashboard',loginInfo.data.name)
      window.localStorage.setItem("name", loginInfo?.data?.name);
      window.localStorage.setItem("designation", loginInfo?.data?.designation);
      window.localStorage.setItem(
        "profilePic",
        loginInfo?.data?.profilePicture
      );
      window.localStorage.setItem(
        "activeOta",
        JSON.stringify(loginInfo?.data?.activeOtas)
      );
      window.localStorage.setItem(
        "permissions",
        JSON.stringify(loginInfo?.data?.permissions)
      );
      window.localStorage.setItem(
        "propertiesList",
        JSON.stringify(loginInfo?.data?.propertiesList)
      );
      window.localStorage.setItem(
        "extractionDate",
        JSON.stringify(loginInfo?.data?.lastExtraction)
      );
      // dispatch(setLoginData(loginInfo?.data));
    }
  }, [loginInfo?.data]);
  const { data: getChannelManagerFault } = useGetChannelManagerFaultsQuery(
    {
      cmId: getCmId,
    },
    {
      skip: getCmId ? false : true,
    }
  );
  const [faultMsg, setFaultMsg] = useState();
  const [showFaultAlert, setShowFaultAlert] = useState(false);

  useEffect(() => {
    if (getChannelManagerFault?.data) {
      setFaultMsg(getChannelManagerFault?.data);
      setShowFaultAlert(true);
    }
  }, [getChannelManagerFault]);
  useEffect(() => {
    if (getPerformanceMatrices && getPerformanceMatrices?.data) {
      setOptions([
        { type: "Revenue", typeValue: getPerformanceMatrices?.data?.revenue },
        {
          type: "Occupancy",
          typeValue: getPerformanceMatrices?.data?.occupancy,
        },
        { type: "ADR", typeValue: getPerformanceMatrices?.data?.ADR },
        { type: "LOS", typeValue: getPerformanceMatrices?.data?.los },
        {
          type: "Room Nights",
          typeValue: getPerformanceMatrices?.data?.roomNights,
        },
      ]);
    }
  }, [getPerformanceMatrices && getPerformanceMatrices?.data]);

  const { data: quickReportData } = useGetQuickReportQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });
  const [reportData, setReportData] = useState(
    quickReportData?.data ? quickReportData?.data : []
  );
  const {
    data: urgentAction,
    isLoading: urgentActionIsLoading,
    isFetching: urgentActionIsFetching,
    refetch: urgentRefetch,
  } = useDashboardUrgentActionQuery({
    userId: UserId(),
    hId: CurrentProperty(),
    startDate: format(new Date(nextPricingDate), "yyyy-MM-dd"),
  });
  const userName = propUser ? propUser : window.localStorage.getItem("name");
  const [quickActionModal, setQuickActionModal] = useState(false);
  const [showIncomplete, setShowIncomplete] = useState(true);
  const [showComplete, setShowComplete] = useState(false);
  const [hotelPerformanceIsOpen, setHotelPerformanceIsOpen] = useState(false);
  const quickActionStorage = window.localStorage
    .getItem("quickAction")
    ?.toString();

  useEffect(() => {
    if (quickActionStorage === "false") {
      setQuickActionModal(false);
    } else {
      setTimeout(() => {
        setQuickActionModal(true);
        window.localStorage.setItem("quickAction", false);
      }, [5500]);
    }
  }, []);
  useEffect(() => {
    setReportData(quickReportData?.data ? quickReportData?.data : []);
  }, [quickReportData?.data]);

  const {
    data: SelectedWidgets,
    refetch: refetchWidget,
    isLoading: widgetIsLoading,
  } = useGetWidgetsQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });

  useEffect(() => {
    if (
      getPerformanceMatricesIsFetching ||
      getPerformanceMatricesIsLoading ||
      sentimentalAnalysisIsLoading ||
      sentimentalAnalysisIsFetching ||
      forecastIsloading ||
      forecastIsFetching ||
      widgetIsLoading ||
      urgentActionIsLoading ||
      urgentActionIsFetching
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    getPerformanceMatricesIsFetching,
    getPerformanceMatricesIsLoading,
    sentimentalAnalysisIsLoading,
    sentimentalAnalysisIsFetching,
    forecastIsloading,
    forecastIsFetching,
    widgetIsLoading,
    urgentActionIsLoading,
    urgentActionIsFetching,
  ]);
  const isCompleteTask =
    quickAction &&
    quickAction?.data.filter((task) => {
      return task.taskStatus === true;
    });
  const inCompleteTask =
    quickAction &&
    quickAction?.data.filter((task) => {
      return task.taskStatus === false;
    });
  const isIpad = useIsIpad();
  const propertyName = PropertyName();

  const handleSuccess = (response) => {
    console.log("Payment Successful:", response);
    // Handle successful payment here (e.g., update the backend, show a success message)
  };

  const handleError = (error) => {
    console.error("Payment Error:", error);
    // Handle error in payment here (e.g., show an error message)
  };
  return (
    <div className={`p-5  flex flex-col gap-4`}>
      {/* <div className="-mt-5">
        {faultMsg && showFaultAlert && (
          <Alert
            heading={faultMsg?.faultIssue}
            description={faultMsg?.faultDescription}
          />
        )}
      </div> */}

      {ShowCMModal && (
        <ChannelManagerPasswordModal hideModal={() => setShowCMModal(false)} />
      )}

      {/* <RazorpayPayment
        amount={500} // Amount in INR
        currency="INR"
        description="Test Transaction"
        onSuccess={handleSuccess}
        onError={handleError}
      /> */}

      {addWidget && (
        <WidgetModal
          refetchWidget={refetchWidget}
          onClick={() => setAddWidget(false)}
          SelectedWidgets={SelectedWidgets}
          widgetIsLoading={widgetIsLoading}
        />
      )}
      {quickActionModal && (
        <Backdrop>
          <div
            className="w-[80%] mx-auto  h-[80%] rounded-lg relative p-5"
            style={{
              background: "linear-gradient(45deg, #ffffffc4, #ffffff9c)",
            }}
          >
            <div>
              <div className="text-lg font-sans">Quick actions</div>
              <div
                onClick={() => setQuickActionModal(false)}
                className="absolute right-2 top-4 bg-red-50 text-red-600 text-sm py-1 px-2 rounded-lg hover:bg-red-500 hover:text-white cursor-pointer"
              >
                Close
              </div>
              <div className="mt-8">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setShowIncomplete(!showIncomplete)}
                >
                  <div className="text-md font-medium mb-4">
                    Incomplete ({inCompleteTask?.length})
                  </div>
                  <div
                    className={`text-lg font-medium mb-4  transition-all ease-in-out duration-150 ${showIncomplete ? "rotate-180" : ""}`}
                  >
                    <AiFillCaretDown />
                  </div>
                </div>
                <div className="overflow-y-scroll scrollHide max-h-[200px]">
                  {showIncomplete &&
                    Array.isArray(inCompleteTask) &&
                    inCompleteTask.map((task) => {
                      return (
                        <Notification
                          title={task?.taskName}
                          description={task?.taskDescription}
                          status={task?.taskPriority}
                          data={inCompleteTask && inCompleteTask}
                          veiwAll={() => setQuickActionModal(true)}
                          path={task?.taskLocation}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="mt-8">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setShowComplete(!showComplete)}
                >
                  <div className="text-md font-medium mb-4">
                    Completed ({isCompleteTask?.length})
                  </div>
                  <div
                    className={`text-lg font-medium mb-4 transition-all ease-in-out duration-150 ${showComplete ? "rotate-180" : ""}`}
                  >
                    <AiFillCaretDown />
                  </div>
                </div>
                <div className="overflow-y-scroll scrollHide max-h-[200px]">
                  {showComplete &&
                    Array.isArray(isCompleteTask) &&
                    isCompleteTask.map((task) => {
                      return (
                        <Notification
                          title={task?.taskName}
                          description={task?.taskDescription}
                          path={task?.taskLocation}
                          showFixNow={false}
                          isCompleted={true}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Backdrop>
      )}
      <BackdropLoader loading={isLoading} />
      {!isLoading && (
        <div className="md:flex  md:items-center md:flex-row sm:flex-col -mt-4 sm:flex sm:justify-centre">
          <div className="lg:w-[70%] sm:w-[100%]">
            <TitleBarWithButton
              title={`Hi ${propUser ? propUser?.split(" ")[0] : userName?.split(" ")[0]}, Welcome back to ${propertyName}!`}
              subHeading={"Streamlined Decision Making with RateX"}
              buttonTitle={"Export"}
              onClick={() => null}
            />
          </div>
          <div className="lg:w-[30%] sm:w-full flex  items-center lg:justify-end">
            <div
              className="text-sm text-[#ff5a81] -mt-1 gap-2 flex hover:bg-red-100 duration-150 ease-in-out transition-all w-fit border border-[#ff5a81] bg-[#202439] py-2 px-2 rounded-md relative cursor-pointer"
              onClick={scrollToTarget}
            >
              <div>
                <img src={dangerIcon} />
              </div>
              <div className="">Urgent Action Needed</div>
              <div className="w-8 h-8 -left-4 -top-4 rounded-full bg-[#E62E2E] text-white flex justify-center items-center absolute">
                {actionCount}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="w-[100%] flex justify-end">
        <div
          className="bg-[#0062ff] p-2  cursor-pointer rounded-lg w-fit"
          onClick={() => setHotelPerformanceIsOpen(!hotelPerformanceIsOpen)}
        >
          <AiOutlineArrowsAlt color="white" size={18} />
        </div>
      </div> */}
      {
        <div className="w-[100%]">
          <HotelPerformanceTile
            setHotelPerformanceIsOpen={setHotelPerformanceIsOpen}
            Open
            isOpen={hotelPerformanceIsOpen}
          />
        </div>
      }
      {!isLoading ? (
        <div className="flex gap-4 bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg rounded-lg items-start">
          <div className="w-[70%]">
            <Urgentactions
              data={urgentAction?.data}
              refetch={urgentRefetch}
              date={nextPricingDate}
            />
          </div>

          <div className="w-[30%]">
            <Next7DayPricing
              setSelectedDate={setNextPricingDate}
              selectedDate={nextPricingDate}
              data={urgentAction?.data}
            />
          </div>
        </div>
      ) : (
        <div className="w-[100%]">
          <CustomSkeleton height={400} />
        </div>
      )}

      {/* <div className="flex flex-col md:flex-row gap-4">
        <div
          className={`${isIpad ? "w-[69%]" : "w-[70%] overflow-x-hidden  "} `}
        >
          <div
            className={`w-[100%]  ${isIpad ? "h-[150px]" : "h-[210px]"} rounded-md overflow-hidden`}
          >
            {isLoading ? (
              <CustomSkeleton height={isIpad ? 150 : 210} />
            ) : (
              <CarouselComp data={getAds?.data} />
            )}
          </div>
        </div>
        <div className={`${isIpad ? "w-[30%]" : "w-[30%] "} `}>
          {isLoading ? (
            <CustomSkeleton height={isIpad ? 150 : 210} />
          ) : (
            <QuickAction
              height={isIpad ? " auto" : 210}
              title={"Quick Action"}
              subHeading={"These actions needs immediate attention"}
              // data={data}
              data={inCompleteTask && inCompleteTask}
              veiwAll={() => setQuickActionModal(true)}
            />
          )}
        </div>
      </div> */}
      <div className="flex gap-4 flex-col md:flex-row">
        <div className={`${isIpad ? "w-[69%]" : "w-[70%]"} `}>
          {isLoading ? (
            <CustomSkeleton height={440} />
          ) : (
            <ChartWithTabs
              data={getPerformanceMatrices && getPerformanceMatrices?.data}
              chartHeight={220}
              isPermission={true}
              options={options}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              title={"Performance Key Metrics"}
              subHeading={
                "Metrics to which the hotel perform while on the current date range in comparision to selected period"
              }
              setTimePeriod={setTimePeriod}
              timePeriod={timePeriod}
              downloadButtonData={getPerformanceMatrices?.data}
              isIpad={isIpad}
            />
          )}
        </div>
        <div className={`${isIpad ? "w-[30%]" : "w-[30%]"} `}>
          {isLoading ? (
            <CustomSkeleton height={440} />
          ) : (
            <ReportSection
              title={"Quick Report"}
              height={440}
              reportData={reportData}
              setReportData={setReportData}
              isIpad={isIpad}
            />
          )}
        </div>
      </div>
      <div className="w-[100%] flex gap-4 flex-col md:flex-row">
        {/* <div className={`${isIpad ? "w-[70%]" : "w-[70%]"} `}>
          {isLoading ? (
            <CustomSkeleton height={700} />
          ) : (
            <RateSuggestions
              isPermission={true}
              title={"7 Days Pricing"}
              compCol={columns}
              compData={NextSevenDays?.data}
              isTransparent={true}
              tableBgColor={""}
            />
          )}
        </div>
        <div className={`${isIpad ? "w-[30%]" : "w-[30%]"} `}>
          {isLoading ? (
            <CustomSkeleton height={400} />
          ) : (
            <ForecastedComp
              data={getForecastedData && getForecastedData?.data}
              selectedForcastedRange={selectedForcastedRange}
              setSelectedForeCastedRange={setSelectedForeCastedRange}
              isIpad={isIpad}
            />
          )}
        </div> */}
      </div>
      {SelectedWidgets?.data?.length == 3 && (
        <div className="flex w-full justify-end">
          <span
            onClick={() => setAddWidget(true)}
            className="text-blue-500 cursor-pointer text-[14px]"
          >
            Edit Widget{" "}
          </span>
          {/* <AddWidgetCard height={410} onClick={() => setAddWidget(true)} /> */}
        </div>
      )}
      <div className="w-[100%] flex flex-col md:flex-row gap-4">
        {Array.isArray(SelectedWidgets?.data) &&
          SelectedWidgets?.data?.map((widget, index) => {
            const renderWidget =
              widget?.widgetName === "Channel Performance" ? (
                <div className="md:w-[35%] w-full">
                  {" "}
                  <Channel />{" "}
                </div>
              ) : widget?.widgetName === "Cancellation" ? (
                <div className="md:w-[35%] w-full">
                  {" "}
                  <CancellationMonth />
                </div>
              ) : widget?.widgetName === "Sentiment Analysis" ? (
                <div className="md:w-[35%] w-full">
                  {" "}
                  <SimpleCloud
                    data={
                      getSentimentalAnalysis &&
                      transformApiData(getSentimentalAnalysis?.data)
                    }
                    options={colorOptions}
                    height={410}
                  />{" "}
                </div>
              ) : widget?.widgetName === "Reputation" ? (
                <div className="md:w-[35%] w-full">
                  <Ratings rating={rating} />
                </div>
              ) : widget?.widgetName === "Upcoming Event" ? (
                <div className="md:w-[35%] w-full">
                  <UpcomingEvent />
                </div>
              ) : null; // Add a default value if none of the conditions match

            return renderWidget;
          })}

        {!(SelectedWidgets?.data?.length === 3) && (
          <div className="w-[35%]">
            <AddWidgetCard height={410} onClick={() => setAddWidget(true)} />
          </div>
        )}
      </div>
      <div className="w-[100%]" ref={targetRef} id="targetDiv">
        <ActionComponent setActionCount={setActionCount} />
      </div>
      {/* <div className="flex gap-4">
        <div className="w-[300px] bg-white rounded-lg">
          <img src={channelImg} />
        </div>
        <div className="w-[300px] bg-white rounded-lg">
          <img src={eventsImg} />
        </div>
        <div className="w-[300px] bg-white rounded-lg">
          <img src={cancellationImg} />
        </div>
        <div className="w-[300px] bg-white rounded-lg">
          <img src={sentimental} />
        </div>
        <div className="w-[300px] bg-white rounded-lg">
          <img src={reputationImg} />
        </div>
      </div>

      <Channel />
      <UpcomingEvent />
      <CancellationMonth /> */}
    </div>
  );
};

export default NewDashboard;

export const TitleBarWithButton = ({
  title,
  subHeading,
  buttonTitle,
  showCancel,
  onClick,
}) => {
  const isIpad = useIsIpad();
  const [isTypingComplete, setTypingComplete] = useState(false);

  useEffect(() => {
    const typingDuration = 3500; // Duration of the typing animation in milliseconds
    const timer = setTimeout(() => {
      setTypingComplete(true);
    }, typingDuration);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <div className="">
          <div
            className={` text-[#ffffff] dark:text-black ${isIpad ? "text-[15px] " : "text-[22px]"} typewriter ${isTypingComplete ? "no-caret" : ""}`}
          >
            {title}
          </div>
          <div className="text-[#ffffff]  dark:text-black text-sm">
            {subHeading}
          </div>
        </div>
        {/* <div>
          <div
            className="bg-[#3E5c76] w-fit px-4 py-2 text-white rounded-lg cursor-pointer"
            onClick={onClick}
          >
            {buttonTitle}
          </div>
        </div> */}
      </div>
    </div>
  );
};
const QuickAction = ({ height, title, subHeading, data, onClick, veiwAll }) => {
  const navigate = useNavigate();
  const isIpad = useIsIpad();
  return (
    <div
      className={` w-[100%]  border  overflow-hidden ${isIpad ? " h-[150px] " : "h-full"}  border-[#FF0000] rounded-lg p-4`}
      // style={{ height: `${height}px` }}
      style={{ background: "linear-gradient(279deg, #0d1a3a, #254b72)" }}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div>
          <div className="text-[#ECECEC] font-medium flex items-center gap-2">
            <AiFillInfoCircle />
            {title}
          </div>
        </div>
        <div
          className="text-[#ECECEC] font-medium text-xs cursor-pointer"
          onClick={veiwAll}
        >
          View More
        </div>
      </div>
      <div
        className={`mx-4  ${isIpad ? " p-2 pb-8  h-full overflow-y-scroll " : ""}`}
      >
        {Array.isArray(data) &&
          data?.length > 0 &&
          data?.slice(0, 4)?.map((action) => {
            return (
              <div
                className="flex justify-between cursor-pointer items-center py-2 border-b border-b-[#444379]"
                onClick={() =>
                  navigate(`/${action?.taskLocation?.path}`, {
                    state: { address: action?.taskLocation },
                  })
                }
              >
                <div className="flex items-center gap-2">
                  <div>
                    <img src={action?.actionIcon} />
                    <AiOutlineWarning color="red" />
                  </div>
                  <div className="text-[#D9D9D9] font-light text-[12px]">
                    {action?.taskName}
                  </div>
                </div>
                <div
                  className={` text-${action?.taskPriority === "High" ? "red" : action?.taskPriority === "Medium" ? "orange" : action?.taskPriority === "Low" ? "yellow" : "green"}-500 text-sm`}
                >
                  {action?.taskPriority}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
const ChartWithTabs = ({
  setTimePeriod,
  timePeriod,
  options,
  selectedItem,
  setSelectedItem,
  title,
  subHeading,
  chartHeight,
  data,
  isIpad,
}) => {
  const convertDate = (dateArray) => {
    return dateArray?.map((date) => {
      return format(new Date(date), "dd-MMM-yy");
    });
  };

  return (
    <div
      className={`bg-newGradient dark:bg-lightGradient p-4 rounded-lg backdrop-blur-lg drop-shadow-lg shadow-lg w-[100%] overflow-hidden dark:text-white dark:bg-[#060d2e]`}
    >
      {/* <TimeRange /> */}

      <div className="flex items-center justify-between">
        <div className="text-white">{title}</div>
        <div className="flex gap-4 items-center">
          <div className="flex text-sm bg-[#f3f3f300] text-[#8794AD]">
            <div
              className={`${timePeriod === "past30Days" ? "bg-[#F0F0F0] text-[#131926] " : "text-white bg-[#F0F0F020]"} text-xs md:text-sm p-2 rounded-l-lg  border-[#F0F0F0] cursor-pointer`}
              onClick={() => setTimePeriod("past30Days")}
            >
              Past 30 days
            </div>
            <div
              className={`${timePeriod === "next30Days" ? "bg-[#F0F0F0] text-[#131926] " : "text-white bg-[#F0F0F020]"} text-xs md:text-sm p-2 rounded-r-lg  border-[#F0F0F0] cursor-pointer`}
              onClick={() => setTimePeriod("next30Days")}
            >
              Next 30 days
            </div>
          </div>
          <div>
            <InfoToolTip
              content={
                "These metrics provide valuable insights into various aspects of a hotel's performance."
              }
              position={"left"}
              index={"23"}
            />
          </div>
        </div>
      </div>
      <div className="text-[#e2e3e6] text-sm dark:text-white">{subHeading}</div>

      {/* Selectable Tabs */}

      <div className="flex gap-4 mx-10 mt-8 w-[100%] scroll-container">
        {Array?.isArray(options) &&
          options?.map((option) => {
            return (
              <div
                className={`${selectedItem === option.type ? "text-[#e9ffcd] border-t-2 border-t-[#e9ffcd]" : "text-[#ffffff]"} text-sm cursor-pointer`}
                onClick={() => setSelectedItem(option.type)}
              >
                <div
                  className={` pt-2  px-2 w-[120px] text-center ${selectedItem === option.type ? "text-[#e9ffcd]" : "text-[#ffffff]"} cursor-pointer`}
                >
                  {option?.type}
                </div>
                <div
                  className={`${selectedItem === option.type ? " text-[#e9ffcd]" : "text-[#ffffff]"} text-center text-[18px] mt-1`}
                >
                  {option?.type === "Revenue" || option?.type === "ADR"
                    ? formatCurrency(option?.typeValue)
                    : option?.type === "Occupancy"
                      ? `${option?.typeValue === undefined || option?.typeValue === "NaN" ? "--" : `${option?.typeValue}%`}`
                      : !option?.typeValue
                        ? "--"
                        : option?.typeValue}
                </div>
              </div>
            );
          })}
        <div>
          {/* <ExcelDownloadButton data={""} filename={"Performance Key Metrics"}/> */}
        </div>
      </div>
      <div className="w-[100%]">
        <AreaChart
          height={chartHeight}
          xaxisShow={true}
          yaxisShow={true}
          showSingleSeries={true}
          currentSeriesData={data?.value}
          currentSeriesName={convertDate(data?.date)}
          currentLabel={selectedItem}
          isCurrency={
            selectedItem === "Revenue" || selectedItem === "ADR" ? true : false
          }
          showToolTip={false}
          isPercentage={selectedItem === "Occupancy" && true}
          isFormat={true}
          labelDivideValue={3}
          textWhite={true}
        />
      </div>
    </div>
  );
};
export const ReportSection = ({
  title,
  height,
  reportData,
  setReportData,
  isIpad,
}) => {
  // Send to WhatsApp
  const [parityWhatsApp, setParityWhatsApp] = useState(false);
  const [parityWhatsAppAdmin, setParityWhatsAppAdmin] = useState(false);
  const intelligenceDateRange = useIntelligenceDateRange();
  const ratePulseDateRange = useRatePulseDateRange();

  // const [pickUpWhatsApp, setPickUpWhatsApp] = useState(false);
  const [pickUpWhatsAppAdmin, setPickUpWhatsAppAdmin] = useState(false);

  const [quaterlyWhatsApp, setQuaterlyWhatsApp] = useState(false);
  const [QuaterlyWhatsAppAdmin, setQuaterlyWhatsAppAdmin] = useState(false);

  const [revenueWhatsApp, setRevenueWhatsApp] = useState(false);
  const [revenueWhatsAppAdmin, setRevenueWhatsAppAdmin] = useState(false);
  const [monthEndWhatsApp, setMonthEndWhatsApp] = useState(false);
  const [monthEndWhatsAppAdmin, setMonthEndWhatsAppAdmin] = useState(false);
  const [yearEndWhatsApp, setYearEndWhatsApp] = useState(false);
  const [yearEndWhatsAppAdmin, setYearEndWhatsAppAdmin] = useState(false);

  const [sourceWhatsApp, setSourceWhatsApp] = useState(false);
  const [sourceWhatsAppAdmin, setSourceWhatsAppAdmin] = useState(false);
  const [paceWhatsApp, setPaceWhatsApp] = useState(false);
  const [paceWhatsAppAdmin, setPaceWhatsAppAdmin] = useState(false);
  const [pickUpWhatsApp, setPickUpWhatsApp] = useState(false);
  const [ratesWhatsApp, setRatesWhatsApp] = useState(false);
  const [ratesWhatsAppAdmin, setRatesWhatsAppAdmin] = useState(false);
  const [rateChangeWhatsApp, setRateChangeWhatsApp] = useState(false);
  const [segmentWhatsApp, setSegmentWhatsApp] = useState(false);
  // other states
  const [viewQuickReport, setViewQuickReport] = useState(false);
  const [renderComponent, setRenderComponent] = useState("");
  const [quaterlyDate, setQuaterlyDate] = useState(new Date());

  const [monthEndDateRange, setMonthEndDateRange] = useState([
    formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "dd-MM-yyyy"
    ),
    formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      "dd-MM-yyyy"
    ),
  ]);
  const newDate = new Date();
  const [parityDateRange, setParityDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [pickUpDateRange, setPickUpDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [ratesDateRange, setRatesDateRange] = useState([
    {
      startDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() - 10
      ),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() + 10
      ),
      id: "range 1",
    },
  ]);

  const [selectedInventryDateRange, setSelectedInventryDateRange] = useState(
    new Date()
  );
  const [cancellationDateRange, setCancellationDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setRatesDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);

  // Update ratesDateRange when intelligenceDateRange changes

  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setParityDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);

  const [quarterlyDateRange, setQuaterlyDateRange] = useState([
    formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth() - 3, 1),
      "dd-MM-yyyy"
    ),
    formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth(), 0),
      "dd-MM-yyyy"
    ),
  ]);
  const [revenueDateRange, setRevenueDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ),
      id: "range 1",
    },
  ]);
  const [paceDateRange, setPaceDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ),
      id: "range 1",
    },
  ]);
  const [sourceDateRange, setSourceDateRange] = useState([
    {
      startDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate()
      ),
      endDate: newDate,
      id: "range 1",
    },
  ]);
  const [yearDateRange, setYearDateRange] = useState([
    formatDate(new Date(newDate.getFullYear() - 1, 0, 1), "dd-MM-yyyy"),
    formatDate(new Date(newDate.getFullYear() - 1, 11, 31), "dd-MM-yyyy"),
  ]);
  const [segmentDate, setSegmentDate] = useState(newDate);
  const [parityDate, setParityDate] = useState(newDate);
  const [pickUpDate, setPickupDate] = useState(newDate);
  const [paceDate, setPaceDate] = useState(newDate);

  const [skipMonthEnd, setSkipMonthEnd] = useState(true);
  const [skipQuaterly, setSkipQuaterly] = useState(true);
  const [skipParity, setSkipParity] = useState(true);
  const [skipPace, setSkipPace] = useState(true);
  const [skipYearEnd, setSkipYearEnd] = useState(true);
  const [skipSegment, setSkipSegment] = useState(true);
  const [skipRevenue, setSkipRevenue] = useState(true);
  const [skipRates, setSkipRates] = useState(true);
  const [skipPickUp, setSkipPickUp] = useState(true);
  const [skipSource, setSkipSource] = useState(true);
  const [skipInventory, setSkipInventory] = useState(true);
  const [sourceDate, setSourceDate] = useState(newDate);

  const [downloadMonthEnd, setDownloadMonthEnd] = useState(false);
  const [downloadQuarterly, setDownloadQuarterly] = useState(false);
  const [downloadParity, setDownloadParity] = useState(false);
  const [downloadPace, setDownloadPace] = useState(false);
  const [downloadYearEnd, setDownloadYearEnd] = useState(false);
  const [downloadSegment, setDownloadSegment] = useState(false);
  const [downloadRevenue, setDownloadRevenue] = useState(false);
  const [downloadPickUp, setDownloadPickUp] = useState(false);
  const [downloadSource, setDownloadSource] = useState(false);
  const [downloadRates, setDownloadRates] = useState(false);
  const [donwloadInventory, setDonwloadInventory] = useState(false);

  const [downloadQuaterly, setDownloadQuaterly] = useState();

  const [revenueDate, setRevenueDate] = useState(newDate);

  const [yearEndDate, setYearEndDate] = useState(newDate);

  function formatDate(date) {
    // Get year, month, and day
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // Months are zero-based
    let day = date.getDate();

    // Pad month and day with leading zeros if necessary
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Construct the formatted date string
    return `${year}-${month}-${day}`;
  }

  // const [downloadComponent , setDownloadComponent]=useState('')
  const [addReport, setAddReport] = useState();
  const [error, setError] = useState();
  // const [downloadComponent , setDownloadComponent]=useState('')
  const [postQuickReport] = usePostQuickReportMutation();
  const hId = CurrentProperty();
  const userId = UserId();

  const {
    data: dropDownData,
    isLoading: dropDownIsLoading,
    isFetching: dropDownIsFetching,
    refetch: dorpDownRefetch,
  } = useGetDropDownDataQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });

  const { data: quickReportData, refetch } = useGetQuickReportQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });

  const {
    data: revenueData,
    isLoading: revenueLoading,
    isFetching: revenueIsFetching,
    isSuccess: revenueSuccess,
  } = useGetRevenueReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(revenueDateRange[0]?.startDate),
      endDate: formatDate(revenueDateRange[0]?.endDate),
      whatsAppNotify: revenueWhatsApp,
      admin: revenueWhatsAppAdmin,
    },
    { skip: skipRevenue, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (revenueSuccess && (revenueWhatsApp || revenueWhatsAppAdmin)) {
      setRevenueWhatsApp(false);
      setRevenueWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [revenueLoading, revenueIsFetching, revenueSuccess]);
  const {
    data: paceData,
    refetch: paceDataRefetch,
    isLoading: paceLoading,
    isFetching: paceFetching,
    isSuccess: paceSuccess,
  } = useGetPaceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(paceDateRange[0]?.startDate),
      endDate: formatDate(paceDateRange[0]?.endDate),
      whatsAppNotify: paceWhatsApp,
      admin: paceWhatsAppAdmin,
    },
    { skip: skipPace, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (paceSuccess && (paceWhatsApp || paceWhatsAppAdmin)) {
      setPaceWhatsApp(false);
      setPaceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [paceLoading, paceFetching, paceSuccess]);
  const {
    data: monthEndData,
    isLoading: monthLoading,
    isFetching: monthFetching,
    isSuccess: monthSuccess,
  } = useGetMonthEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: monthEndDateRange[0],
      endDate: monthEndDateRange[1],
      whatsAppNotify: monthEndWhatsApp,
      admin: monthEndWhatsAppAdmin,
    },
    { skip: skipMonthEnd, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (monthSuccess && (monthEndWhatsApp || monthEndWhatsAppAdmin)) {
      setMonthEndWhatsApp(false);
      setMonthEndWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [monthLoading, monthFetching, monthSuccess]);
  const {
    data: yearEndData,
    isLoading: yearLoading,
    isFetching: yearFetching,
    isSuccess: yearSuccess,
  } = useGetYearEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: yearDateRange[0],
      endDate: yearDateRange[1],
      whatsAppNotify: yearEndWhatsApp,
      admin: yearEndWhatsAppAdmin,
    },
    { skip: skipYearEnd, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (yearSuccess && (yearEndWhatsApp || yearEndWhatsAppAdmin)) {
      setYearEndWhatsApp(false);
      setYearEndWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [yearLoading, yearFetching, yearSuccess]);
  const {
    data: quarterlyData,
    isLoading: quarterlyLoading,
    isFetching: quaterlyFetching,
    refetch: quaterlyRefetch,
    isSuccess: quaterlySuccess,
  } = useGetQuarterlyReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: quarterlyDateRange[0],
      endDate: quarterlyDateRange[1],
      whatsAppNotify: quaterlyWhatsApp,
      admin: QuaterlyWhatsAppAdmin,
    },
    { skip: skipQuaterly, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (quaterlySuccess && (quaterlyWhatsApp || QuaterlyWhatsAppAdmin)) {
      setQuaterlyWhatsAppAdmin(false);
      setQuaterlyWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [quarterlyLoading, quaterlyFetching, quaterlySuccess]);

  const {
    data: sourceData,
    isLoading: sourceLoading,
    isFetching: sourceFetching,
    isSuccess: sourceSuccess,
  } = useGetSourceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(sourceDateRange[0].startDate),
      endDate: formatDate(sourceDateRange[0].endDate),
      whatsAppNotify: sourceWhatsApp,
      admin: sourceWhatsAppAdmin,
    },
    { skip: skipSource, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (sourceSuccess && (sourceWhatsApp || sourceWhatsAppAdmin)) {
      setSourceWhatsApp(false);
      setSourceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [sourceLoading, sourceFetching, sourceSuccess]);

  const {
    data: segmentData,
    isFetching: segmentFetching,
    isLoading: segmentLoading,
  } = useGetSegmentReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(segmentDate),
      whatsAppNotify: segmentWhatsApp,
    },
    { skip: skipSegment, refetchOnMountOrArgChange: true }
  );
  const {
    data: parityData,
    isFetching: parityFetching,
    isLoading: parityLoading,
    isSuccess: paritySuccess,
  } = useGetParityReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(parityDateRange[0].startDate),
      endDate: formatDate(parityDateRange[0].endDate),
      whatsAppNotify: parityWhatsApp,
      admin: parityWhatsAppAdmin,
    },
    { skip: skipParity, refetchOnMountOrArgChange: true }
  );
  useEffect(() => {
    if (paritySuccess && (parityWhatsApp || parityWhatsAppAdmin)) {
      setParityWhatsAppAdmin(false);
      setParityWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [parityLoading, parityFetching, paritySuccess]);

  const {
    data: pickUpData,
    isFetching: pickupFetching,
    isLoading: pickupLoading,
  } = useGetPickUpReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(pickUpDateRange[0].startDate),
      endDate: formatDate(pickUpDateRange[0].endDate),
      whatsAppNotify: pickUpWhatsApp,
      admin: setPickUpWhatsAppAdmin,
    },
    { skip: skipPickUp, refetchOnMountOrArgChange: true }
  );

  const {
    data: ratesData,
    isFetching: ratesFetching,
    isLoading: ratesLoading,
    isSuccess: ratesSuccess,
  } = useGetRatesReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(ratesDateRange[0].startDate),
      endDate: formatDate(ratesDateRange[0].endDate),
      whatsAppNotify: ratesWhatsApp,
      admin: ratesWhatsAppAdmin,
    },
    { skip: skipRates, refetchOnMountOrArgChange: true }
  );

  const {
    data: getInventory,
    isFetching: inventoryFetching,
    isLoading: inventoryLoading,
  } = useGetInventoryQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      asOn: format(new Date(), "yyy-MM-dd"),
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipInventory,
    }
  );
  const {
    data: cancelllationData,
    isFetching: cancellationIsFetching,
    isLoading: cancellationIsLoading,
    isSuccess: cancellationSuccess,
  } = useGetRateChangeReportQuery({
    userId: UserId(),
    hId: CurrentProperty(),
    startDate: format(
      new Date(cancellationDateRange[0]?.startDate),
      "yyyy-MM-dd"
    ),
    endDate: format(new Date(cancellationDateRange[0]?.endDate), "yyyy-MM-dd"),
    whatsAppNotify: rateChangeWhatsApp,
  });
  useEffect(() => {
    if (ratesSuccess && (ratesWhatsApp || ratesWhatsAppAdmin)) {
      setRatesWhatsAppAdmin(false);
      setRatesWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [ratesLoading, ratesFetching, ratesSuccess]);
  useEffect(() => {
    if (cancellationSuccess && (rateChangeWhatsApp || ratesWhatsAppAdmin)) {
      //   setRateChangeWhatsApp(false);
      setRateChangeWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [cancellationIsLoading, cancellationIsFetching, cancellationSuccess]);
  const [downloadClick, setDownloadClick] = useState(true);
  const [isLoading, setIsLoading] = useState(
    (yearLoading ||
      monthLoading ||
      parityLoading ||
      paceLoading ||
      sourceLoading ||
      ratesLoading ||
      revenueLoading ||
      quarterlyLoading ||
      inventoryLoading) &&
      downloadClick
  );
  const handleClose = () => {
    setAddReport(false);
    // dorpDownRefetch();
    // setReportData(quickReportData?.data);
    // setOpt(optionss);
  };

  useEffect(() => {
    const downloadReport = async () => {
      if (
        quarterlyData?.data &&
        !quaterlyFetching &&
        !quarterlyLoading &&
        downloadQuaterly
      ) {
        await DownloadQuaterlyReport(quarterlyData.data);
        setDownloadQuaterly(false);
      }
    };

    downloadReport();
  }, [quarterlyData, quarterlyLoading, quaterlyFetching]);

  useEffect(() => {
    const downloadReport = async () => {
      if (paceData && !paceFetching && !paceLoading && downloadPace) {
        await DownloadPaceReport(paceData);
        setDownloadPace(false);
      }
    };

    downloadReport();
  }, [paceData, paceLoading, paceFetching]);

  useEffect(() => {
    const downloadReport = async () => {
      if (
        monthEndData?.data &&
        !monthFetching &&
        !monthLoading &&
        downloadMonthEnd
      ) {
        await DownloadMonthEndReport(monthEndData?.data);
        setDownloadMonthEnd(false);
      }
    };

    downloadReport();
  }, [monthEndData?.data, monthLoading, monthFetching]);
  useEffect(() => {
    setIsLoading(
      (yearLoading ||
        monthLoading ||
        parityLoading ||
        paceLoading ||
        sourceLoading ||
        ratesLoading ||
        quarterlyLoading ||
        revenueLoading ||
        pickupLoading ||
        inventoryLoading) &&
        downloadClick
    );
  }, [
    yearLoading ||
      monthLoading ||
      parityLoading ||
      paceLoading ||
      revenueLoading ||
      sourceLoading ||
      ratesLoading ||
      quarterlyLoading ||
      pickupLoading,
    inventoryLoading,
    quarterlyLoading,
  ]);
  useEffect(() => {
    const downloadReport = async () => {
      if (
        revenueData?.revenueReport &&
        !revenueIsFetching &&
        !revenueLoading &&
        downloadRevenue
      ) {
        await DownloadRevenueReport(revenueData?.revenueReport);
        setDownloadRevenue(false);
      }
    };

    downloadReport();
  }, [revenueData?.revenueReport, revenueIsFetching, revenueLoading]);
  useEffect(() => {
    const downloadPickUpReport = async () => {
      if (
        pickUpData?.data &&
        !pickupLoading &&
        !pickupFetching &&
        downloadPickUp
      ) {
        await downloadPickUPReport(pickUpData?.data);
        setDownloadPickUp(false);
      }
    };

    downloadPickUpReport();
  }, [pickUpData?.data, pickupFetching, pickupLoading]);
  console.log(sourceDateRange, "sourceDa");
  useEffect(() => {
    const downloadSourceReport = async () => {
      if (
        sourceData?.data &&
        !sourceLoading &&
        !sourceFetching &&
        downloadSource
      ) {
        await DownloadSourceReport(sourceData?.data, sourceDateRange);
        setDownloadSource(false);
      }
    };

    downloadSourceReport();
  }, [sourceData?.data, sourceLoading, sourceFetching]);
  useEffect(() => {
    const downloadReport = async () => {
      if (
        segmentData?.data &&
        !segmentFetching &&
        !segmentLoading &&
        downloadSegment
      ) {
        DownloadSegmentReport(segmentData?.data);
        setDownloadSegment(false);
      }
    };

    downloadReport();
  }, [segmentData?.data, segmentFetching, segmentLoading]);
  useEffect(() => {
    const downloadReport = async () => {
      if (
        parityData?.data &&
        !parityFetching &&
        !parityLoading &&
        downloadParity
      ) {
        DownloadParityReport(parityData?.data, parityData?.asOn);
        setDownloadParity(false);
      }
    };

    downloadReport();
  }, [parityData?.data, parityFetching, parityLoading]);
  useEffect(() => {
    const downloadReport = async () => {
      if (
        yearEndData?.data &&
        !yearFetching &&
        !yearLoading &&
        downloadYearEnd
      ) {
        YearEndReportdownload(yearEndData?.data);
        setDownloadYearEnd(false);
      }
    };

    downloadReport();
  }, [yearEndData?.data, yearFetching, yearLoading]);
  useEffect(() => {
    const downloadReport = async () => {
      if (
        getInventory?.data &&
        !inventoryFetching &&
        !inventoryLoading &&
        donwloadInventory
      ) {
        DownloadOccupencyReport(getInventory, selectedInventryDateRange);
        setDonwloadInventory(false);
      }
    };

    downloadReport();
  }, [getInventory?.data, inventoryFetching, inventoryLoading]);
  const handleDownload = async (downloadComponent) => {
    setDownloadClick(true);
    if (downloadComponent === "Pace Report") {
      setSkipPace(false);
      setDownloadPace(true);
      paceData && DownloadPaceReport(paceData);
    } else if (downloadComponent === "Month End Report") {
      setSkipMonthEnd(false);
      setDownloadMonthEnd(true);
      monthEndData?.data && DownloadMonthEndReport(monthEndData?.data);
    } else if (downloadComponent === "Revenue Report") {
      setSkipRevenue(false);
      setDownloadRevenue(true);
      revenueData?.revenueReport &&
        DownloadRevenueReport(revenueData?.revenueReport);
    } else if (downloadComponent === "Source Report") {
      setSkipSource(false);
      setDownloadSource(true);
      sourceData?.data &&
        DownloadSourceReport(sourceData?.data, sourceDateRange);
    } else if (downloadComponent === "Segment Report") {
      setSkipSegment(false);
      setDownloadSegment(true);
      segmentData?.data && DownloadSegmentReport(segmentData?.data);
    } else if (downloadComponent === "Quarterly Report") {
      setSkipQuaterly(false);
      setDownloadQuaterly(true);
      quarterlyData?.data && DownloadQuaterlyReport(quarterlyData?.data);
    } else if (downloadComponent === "Parity Report") {
      setSkipParity(false);
      setDownloadParity(true);
      parityData?.data &&
        DownloadParityReport(parityData?.data, parityData?.asOn);
    } else if (downloadComponent === "Year End Report") {
      setSkipYearEnd(false);
      setDownloadYearEnd(true);
      yearEndData?.data && YearEndReportdownload(yearEndData?.data);
    } else if (downloadComponent === "Rates Report") {
      setSkipRates(false);
      setDownloadRates(true);

      ratesData?.data &&
        ratesReportDownload(
          ratesData?.data,
          ratesData?.data[0].hotelName,
          ratesDateRange[0].startDate,
          ratesDateRange[0].endDate
        );
    } else if (downloadComponent === "Pick Up Report") {
      setSkipPickUp(false);
      setDownloadPickUp(true);
      pickUpData?.data && downloadPickUPReport(pickUpData?.data);
    } else if (downloadComponent === "Occupancy Report") {
      setSkipInventory(false);
      setDonwloadInventory(true);
      getInventory?.data && DownloadOccupencyReport(getInventory);
    } else if (downloadComponent === "Cancellation report") {
      setSkipInventory(false);
      setDonwloadInventory(true);
      cancelllationData?.rateChangeReport &&
        DownloadCancellationReport(
          cancelllationData?.rateChangeReport?.cancellationData
        );
    }
  };

  var optionss = dropDownData?.data.map((item, index) => ({
    value: item.reportName,
    label: item.reportName,
    reportId: item.reportId,
    reportName: item.reportName,
    preDefined: item.predefined,
  }));
  const filteredArray = useMemo(() => {
    return optionss?.filter(
      (option) =>
        !reportData?.some((report) => report?.reportId === option?.reportId)
    );
  }, [optionss, reportData]);

  const [intiailRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (filteredArray && intiailRender) {
      setOpt(filteredArray);
      setInitialRender(false);
    }
  }, [filteredArray]);
  const [opt, setOpt] = useState([]);

  // if (filteredArray && opt && optionss) {
  //   setOpt(filteredArray)
  // }
  //  useEffect(()=>{

  //      setOpt(filteredArray)
  //    },[filteredArray])
  const handleDropDown = (e) => {
    if (reportData?.length < 7) {
      setOpt(opt.filter((option) => option.reportId !== e.reportId));
      setReportData([
        ...reportData,
        {
          reportId: e.reportId,
          preDefined: e.preDefined,
          reportName: e.reportName,
        },
      ]);
      setError("");
    } else {
      setError("Maximum Report Limit Reached");
    }
  };

  const handlePostQuickReport = () => {
    postQuickReport({
      hId: hId,
      userId: userId,
      reports: reportData,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right" });
        setAddReport(false);

        refetch();
      })
      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
      });
  };

  const handleDelete = (e) => {
    setReportData(
      reportData.filter((option) => option.reportId !== e.reportId)
    );

    setOpt([
      ...opt,
      {
        reportId: e.reportId,
        preDefined: e.preDefined,
        value: e.reportName,
        label: e.reportName,
        reportName: e.reportName,
      },
    ]);
  };
  const handleIntitalAPICall = (ApiCall) => {
    setDownloadClick(false);
    if (ApiCall == "Pace Report") {
      setSkipPace(false);
    } else if (ApiCall == "Month End Report") {
      setSkipMonthEnd(false);
    } else if (ApiCall == "Revenue Report") {
      setSkipRevenue(false);
    } else if (ApiCall == "Source Report") {
      setSkipSource(false);
    } else if (ApiCall == "Segment Report") {
      setSkipSegment(false);
    } else if (ApiCall == "Quarterly Report") {
      setSkipQuaterly(false);
    } else if (ApiCall == "Parity Report") {
      setSkipParity(false);
    } else if (ApiCall == "Year End Report") {
      setSkipYearEnd(false);
    } else if (ApiCall == "Rates Report") {
      setSkipRates(false);
    } else if (ApiCall == "Pick Up Report") {
      setSkipPickUp(false);
    } else if (ApiCall == "Occupancy Report") {
      setSkipInventory(false);
    }
  };

  return (
    <div>
      {viewQuickReport && (
        <Backdrop>
          <div className="w-[80%] mx-auto mt-5 bg-white/50 h-[80%] rounded-lg relative p-4 pb-0">
            <div
              onClick={() => setViewQuickReport(false)}
              className="absolute z-30 flex items-center gap-2  px-2 rounded-lg bg-red-50 text-red-400 hover:bg-red-400 hover:text-red-50 right-3 top-3 cursor-pointer transition-all ease-in-out duration-100"
            >
              close
            </div>
            <div
              className={`scrollHide  overflow-y-scroll ${renderComponent == "Rates Report" || renderComponent == "Parity Report" ? "h-[550px]" : "h-auto"}   max-h-full`}
            >
              {renderComponent == "Month End Report" && (
                <MonthEndTable
                  data={monthEndData?.data}
                  isLoading={monthFetching || monthLoading}
                  dateRange={monthEndDateRange}
                  setDateRange={setMonthEndDateRange}
                  setIsWhatsApp={() => {
                    setMonthEndWhatsApp(true);
                    // setMonthEndWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setMonthEndWhatsAppAdmin(true);
                  }}
                  monthEndWhatsAppAdmin={monthEndWhatsAppAdmin}
                />
              )}
              {renderComponent == "Pace Report" && (
                <PaceReport
                  data={paceData}
                  setDateRange={setPaceDateRange}
                  dateRange={paceDateRange}
                  isLoading={paceLoading || paceFetching}
                  setIsWhatsApp={() => {
                    setPaceWhatsApp(true);
                    // setPaceWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setPaceWhatsAppAdmin(true);
                  }}
                  paceWhatsAppAdmin={paceWhatsAppAdmin}
                />
              )}
              {renderComponent == "Revenue Report" && (
                <RevenueReportTable
                  dateRange={revenueDateRange}
                  setDateRange={setRevenueDateRange}
                  data={revenueData?.revenueReport}
                  isLoading={revenueIsFetching || revenueLoading}
                  setIsWhatsApp={() => {
                    setRevenueWhatsApp(true);
                    // setRevenueWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setRevenueWhatsAppAdmin(true);
                  }}
                />
              )}
              {renderComponent == "Source Report" && (
                <SourceReport
                  setDateRange={setSourceDateRange}
                  dateRange={sourceDateRange}
                  data={sourceData?.data}
                  isLoading={sourceLoading || sourceFetching}
                  setIsWhatsApp={() => {
                    setSourceWhatsApp(true);
                    // setSourceWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setSourceWhatsAppAdmin(true);
                  }}
                />
              )}
              {renderComponent == "Segment Report" && (
                <SegmentReport
                  setSelected2Date={setSegmentDate}
                  selected2Date={segmentDate}
                  data={segmentData?.data}
                  setIsWhatsApp={() => {
                    setSegmentWhatsApp(true);
                    // setSegmentWhatsApp(false);
                  }}
                />
              )}
              {renderComponent == "Quarterly Report" && (
                <QuarterlyReport
                  isLoading={quarterlyLoading || quaterlyFetching}
                  dateRange={quarterlyDateRange}
                  setDateRange={setQuaterlyDateRange}
                  data={quarterlyData?.data}
                  setIsWhatsApp={() => {
                    setQuaterlyWhatsApp(true);
                    // setQuaterlyWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setQuaterlyWhatsAppAdmin(true);
                  }}
                  QuaterlyWhatsAppAdmin={QuaterlyWhatsAppAdmin}
                />
              )}
              {renderComponent == "Parity Report" && (
                <ParityReport
                  setDateRange={setParityDateRange}
                  dateRange={parityDateRange}
                  data={parityData?.data}
                  isLoading={parityLoading || parityFetching}
                  setIsWhatsApp={() => {
                    setParityWhatsApp(true);
                    // setParityWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setParityWhatsAppAdmin(true);
                  }}
                  parityWhatsAppAdmin={parityWhatsAppAdmin}
                />
              )}
              {renderComponent == "Pick Up Report" && (
                <PickUpReport
                  setSelected2Date={setPickupDate}
                  selected2Date={pickUpDate}
                  data={pickUpData?.data}
                  setDateRange={setPickUpDateRange}
                  dateRange={pickUpDateRange}
                  isLoading={pickupLoading || pickupFetching}
                  setIsWhatsApp={() => {
                    setPickUpWhatsApp(true);
                    // setParityWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setPickUpWhatsAppAdmin(true);
                  }}
                  pickUpWhatsApp={pickUpWhatsApp}
                />
              )}
              {renderComponent == "Year End Report" && (
                <YearEndReport
                  setDateRange={setYearDateRange}
                  dateRange={yearDateRange}
                  data={yearEndData?.data}
                  isLoading={yearLoading || yearFetching}
                  setIsWhatsApp={() => {
                    setYearEndWhatsApp(true);
                    // setYearEndWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setYearEndWhatsAppAdmin(true);
                  }}
                  yearEndWhatsAppAdmin={yearEndWhatsAppAdmin}
                />
              )}
              {renderComponent == "Rates Report" && (
                <RatesReport
                  setDateRange={setRatesDateRange}
                  dateRange={ratesDateRange}
                  data={ratesData?.data}
                  isLoading={ratesLoading || ratesFetching}
                  setIsWhatsApp={() => {
                    setRatesWhatsApp(true);
                    // setParityWhatsApp(false);
                  }}
                  sendToWhatsappAdmin={() => {
                    setRatesWhatsAppAdmin(true);
                  }}
                  parityWhatsAppAdmin={ratesWhatsAppAdmin}
                />
              )}
              {renderComponent == "Occupancy Report" && (
                <OccupencyReport
                  getInventory={getInventory}
                  isLoading={inventoryLoading || inventoryFetching}
                  selectedDate={selectedInventryDateRange}
                  setSelectedDate={setSelectedInventryDateRange}
                />
              )}
              {renderComponent == "Cancellation report" && (
                <CancellationReport
                  data={cancelllationData}
                  isLoading={cancellationIsFetching || cancellationIsLoading}
                  setDateRange={setCancellationDateRange}
                  dateRange={cancellationDateRange}
                  setIsWhatsApp={() => {
                    setRateChangeWhatsApp(true);
                    // setParityWhatsApp(false);
                  }}
                />
              )}
            </div>
            {/* <div>Add</div> */}
          </div>
        </Backdrop>
      )}
      <BackdropLoader loading={isLoading} />
      {addReport && (
        <Backdrop>
          <div
            className={`flex flex-col ${isIpad ? "w-[100%]" : "w-[80%]"}  mx-auto mt-5 bg-white  h-[80%] rounded-lg relative  p-4 pb-0`}
          >
            <div>
              <div>Add Report</div>
              <div>
                <div
                  onClick={handleClose}
                  className="absolute flex items-center gap-2  px-2 rounded-lg bg-red-50 text-red-400 hover:bg-red-400 hover:text-red-50 right-3 top-3 cursor-pointer transition-all ease-in-out duration-100"
                >
                  Close
                </div>
                <div
                  onClick={handlePostQuickReport}
                  className="absolute  right-[15px] outline outline-blue-600 bottom-[16px] bg-[#f9f9f9] text-blue-600 text-sm py-2 px-4 w-32 text-center rounded-md hover:bg-blue-500 hover:text-white cursor-pointer"
                >
                  Save
                </div>
              </div>
            </div>

            <div className="mt-9 ">
              <div className="max-h-auto min-h-auto overflow-y-auto ">
                {Array?.isArray(reportData) &&
                  reportData.map((report) => {
                    return (
                      <div className="p-2 mt-2 flex justify-between rounded-md border border-[#e1e0e089] bg-[#f3f3f388]">
                        <div className="text-sm">{report?.reportName}</div>
                        <div className="flex gap-4 items-center cursor-pointer">
                          <div
                            onClick={() => {
                              handleDelete(report);
                            }}
                          >
                            <AiFillCloseCircle color="red" size={16} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div>
                <div className=" text-xs text-red-600">{error}</div>
                <div className=" mt-2 flex justify-between rounded-md border border-[#e1e0e089] bg-[#f3f3f388]">
                  <Select
                    className="w-[100%]"
                    onChange={handleDropDown}
                    options={opt}
                  />
                </div>
              </div>
            </div>
          </div>
        </Backdrop>
      )}
      <div
        className={`w-[100%] bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg rounded-lg p-4 relative`}
        style={{ height: `${height}px` }}
      >
        <div className="flex items-center justify-between">
          <div className="text-white">{title}</div>
          <div>
            <InfoToolTip
              content={
                "The Quick Report serves as a valuable tool for promptly evaluating the operational and financial health of the hotel."
              }
              position={"left"}
              index={"quickRep"}
            />
          </div>
        </div>
        <div className=" h-[340px] scroll-container">
          {Array?.isArray(quickReportData?.data) &&
            quickReportData?.data?.map((report) => {
              return (
                report?.reportName && (
                  <div
                    className="p-2 mt-2 flex justify-between rounded-md "
                    style={{
                      background:
                        "linear-gradient(45deg, #1a386b, transparent)",
                    }}
                  >
                    <div className="text-sm text-white">
                      {report?.reportName}
                    </div>
                    <div className="flex gap-4 items-center cursor-pointer">
                      <div
                        id={report.reportName}
                        onClick={() => {
                          setViewQuickReport(true);
                          setRenderComponent(report?.reportName);
                        }}
                      >
                        <img
                          src={eyeOpen}
                          onClick={() =>
                            handleIntitalAPICall(report?.reportName)
                          }
                        />
                      </div>
                      <div
                        onClick={() => {
                          handleDownload(report.reportName);
                        }}
                      >
                        <img src={downloadBtn} />
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </div>
        <div
          onClick={() => setAddReport(true)}
          className="p-2 left-[14px] cursor-pointer hover:bg-[#1D2D44] hover:text-white duration-150 transition-all ease-in-out  bottom-4 absolute w-[92%] flex items-center gap-2 justify-center text-[#081439] rounded-md text-sm  border-[#081439] bg-white "
        >
          <AiOutlinePlus /> Add Report
        </div>
      </div>
    </div>
  );
};
export const ForecastedComp = ({
  selectedForcastedRange,
  setSelectedForeCastedRange,
  data,
  isIpad,
}) => {
  const dayFilter = [
    { label: "7 Days", value: "7D" },
    { label: "15 Days", value: "15D" },
    { label: "30 Days", value: "30D" },
    { label: "45 Days", value: "45D" },
    { label: "60 Days", value: "60D" },
  ];
  const revenueOriginalLabel = data && data?.revenue?.originalChartData?.date;
  const revenueOriginalData = data && data?.revenue?.originalChartData?.value;
  const forecastedRevenueLabel =
    data &&
    Array.isArray(data?.revenue?.forecastedChartData) &&
    data?.revenue?.forecastedChartData.map((item) => {
      return format(new Date(item?.date), "dd-MM-yyyy");
    });
  const forecastedRevenueData =
    data &&
    Array.isArray(data?.revenue?.forecastedChartData) &&
    data?.revenue?.forecastedChartData.map((item) => {
      return item?.revenue;
    });
  const roomNightsOriginalLabel =
    data &&
    data?.roomNights?.originalChartData?.date?.map((item, index) => {
      return format(new Date(item), "dd-MM-yyyy");
    });
  const roomNightsOriginalData =
    data && data?.roomNights?.originalChartData?.value;

  const forecastedRoomNightsLabel =
    data &&
    Array.isArray(data?.roomNights?.forecastedChartData) &&
    data?.roomNights?.forecastedChartData.map((item) => {
      return item?.date;
    });
  const forecastedRoomNightsData =
    data &&
    Array.isArray(data?.roomNights?.forecastedChartData) &&
    data?.roomNights?.forecastedChartData.map((item) => {
      return item?.roomNights;
    });

  return (
    <div className="bg-white p-4 min-h-[400px] max-h-max rounded-lg">
      <div className="flex flex-col justify-between items-center">
        <div className="w-[100%] whitespace-nowrap flex items-center gap-2">
          <div>
            Forecast{" "}
            {isIpad ? (
              <div className="text-[12px] text-gray-500">
                as per{" "}
                {data &&
                  data?.asPer &&
                  format(new Date(data?.asPer), "dd MMM yyyy")}
              </div>
            ) : (
              <span className="text-[12px] text-gray-500">
                as per{" "}
                {data &&
                  data?.asPer &&
                  format(new Date(data?.asPer), "dd MMM yyyy")}
              </span>
            )}
          </div>

          <div
            className={`flex gap-2 justify-end items-center w-[100%] ${isIpad && "-ml-[48px]"}`}
          >
            <div className="flex items-center gap-4">
              <Dropdown
                options={dayFilter}
                selectedItem={selectedForcastedRange?.label}
                setSelectedItems={setSelectedForeCastedRange}
                right={"2px"}
              />
              <div>
                <InfoToolTip
                  content={
                    "This forecast provides a comprehensive projection of revenue and room nights for the specified period."
                  }
                  position={"left"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-[#59647bce] font-light w-[100%] text-sm mt-2">
          Here is your property's forecasted data.{" "}
        </div>
        <div
          className={`flex ${isIpad && "flex-col"} justify-between items-center -mt-2 ${!isIpad && "px-3"}  w-[100%]`}
        >
          <div className="w-[100%]">
            <div className={`${isIpad && "mt-[30px]"} flex gap-2 items-center`}>
              <div className="text-xl text-[#4b4c4d]">Revenue</div>
              <div
                className={` ${data?.revenue?.forecasted > data?.revenue?.original ? "text-[#29CC39] bg-[#ECFDF5] " : "bg-[#FEF2F2] text-[#E62E2E]"} py-0 px-2 rounded-md`}
              >
                {calculatePercentage(
                  data?.revenue?.original,
                  data?.revenue?.forecasted
                ).toFixed(2)}
                %
              </div>
            </div>
            <div
              className={`${data?.revenue?.forecasted > data?.revenue?.original ? "text-[#29CC39]" : "text-[#E62E2E]"} text-xl mt-2`}
            >
              {formatCurrency(data?.revenue?.forecasted)}
            </div>
          </div>
          <div className="w-[100%]">
            <AreaChart
              pastSeriesData={revenueOriginalData}
              currentSeriesData={forecastedRevenueData}
              currentSeriesName={revenueOriginalLabel}
              currentLabel={"Forecasted Revenue"}
              perviousLabel={"Original Revenue"}
              isCurrency={true}
            />
          </div>
        </div>
        <div className="text-[#59647B] w-[100%] -mt-4 text-[12px] border-b pb-4 px-3">
          Hover over the chart to see a comparison of our forecasted revenue for
          the current year and the actual revenue from the previous year.
        </div>
        <div
          className={`flex -mt-4 ${isIpad && "flex-col"}  justify-between items-center w-[100%] ${!isIpad && "px-3"}`}
        >
          <div className="w-[100%]">
            <div className={`flex gap-2 ${isIpad && "mt-[30px]"} items-center`}>
              <div className="text-xl text-[#4b4c4d]">Room Nights </div>
              <div
                className={` ${data?.roomNights?.forecasted > data?.roomNights?.original ? "text-[#29CC39] bg-[#ECFDF5] " : "bg-[#FEF2F2] text-[#E62E2E]"} py-0 px-2 rounded-md`}
              >
                {calculatePercentage(
                  data?.roomNights?.original,
                  data?.roomNights?.forecasted
                ).toFixed(2)}
                %
              </div>
            </div>
            <div
              className={`${data?.roomNights?.forecasted > data?.roomNights?.original ? "text-[#29CC39]" : "text-[#E62E2E]"} text-xl mt-2`}
            >
              {data?.roomNights?.forecasted || 0}
            </div>
          </div>
          <div className="w-[100%]">
            <AreaChart
              currentLabel={"Forecasted Room Night"}
              perviousLabel={"Original Room Night"}
              currentSeriesData={forecastedRoomNightsData}
              currentSeriesName={
                forecastedRoomNightsLabel?.length >
                roomNightsOriginalLabel?.length
                  ? forecastedRoomNightsLabel
                  : roomNightsOriginalLabel
              }
              pastSeriesData={roomNightsOriginalData}
            />
          </div>
        </div>
        <div className="text-[#59647B] w-[100%] text-[12px]  pb-4 -mt-1 px-3">
          Hover over the chart to see a comparison of our forecasted room nights
          for the current year and the actual room nights from the previous
          year.
        </div>
      </div>
    </div>
  );
};
const SimpleCloud = ({ data, options, height }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg rounded-lg p-4 h-[436px] flex flex-col justify-between">
      <div>
        <div className="flex justify-between text-white items-center">
          <div>Sentiment Analysis</div>
          <InfoToolTip
            content={
              "This feature provides insights into guest sentiment by analyzing reviews and feedback from various sources."
            }
            index={"sentiments"}
          />
        </div>
        <div className="text-[#ffffff] text-sm mt-2">
          Discover the most positive and negative tags for your property.
        </div>
        <div className="mt-6 text-wrap">
          {Array.isArray(data) && (
            <TagCloud
              minSize={12}
              maxSize={20}
              tags={data?.slice(0, 80)}
              // onClick={(tag) => alert(`'${tag.value}' was selected!`)}
              colorOptions={options}
              randomSeed={42}
              shuffle={true}
            />
          )}
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/rate-pulse/reputation");
        }}
        className="w-full text-center text-white py-1 rounded-lg -mb-2 cursor-pointer"
      >
        View All
      </div>
    </div>
  );
};
export const Ratings = ({ rating, asPer }) => {
  const otaOptions = ActiveOtaOptions();
  const navigate = useNavigate();
  const [selectOta, setSelectOta] = useState();
  const { data } = useGetRatingsQuery({
    hId: CurrentProperty(),
    userId: UserId(),
    otaId: selectOta?.value === "All" ? "" : selectOta?.value,
  });
  const ratingData = data?.data[0];
  useEffect(() => {
    if (otaOptions[0]) {
      setSelectOta(otaOptions[0]);
    }
  }, [otaOptions]);
  const isIpad = useIsIpad();
  return (
    <div className="bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg p-4 rounded-lg h-[436px]">
      <div className="">
        <div className="w-[100%] text-white flex justify-between items-center">
          <div>Reputation</div>
          <div className="flex  items-center  gap-4">
            {!isIpad && <div className="text-sm">Select OTA</div>}
            <div>
              <Dropdown
                options={otaOptions}
                selectedItem={
                  selectOta?.label ? selectOta?.label : "Select OTA"
                }
                setSelectedItems={setSelectOta}
              />
            </div>
            <div>
              <InfoToolTip
                content={
                  "This metric provides an overview of the property's average reputation score as rated by guests across various Online Travel Agencies (OTAs)."
                }
                index={"Reputations"}
                position={"left"}
              />
            </div>
          </div>
        </div>
        <div className="text-[#ffffff] text-sm mt-2">
          {selectOta?.label === "All"
            ? `Your property's average reputation on different OTA`
            : `Your property's reputation on ${selectOta?.label === "Booking" ? "Booking.com" : selectOta?.label}`}
        </div>
        <div className="flex flex-col justify-center items-center mt-8">
          <div className="text-[#eeeff0] text-sm mt-2">Rating scores</div>
          <div className="text-[#e6e7e9] text-[30px] font-light mt-2 ">
            {ratingData?.averageRating}
          </div>
          <div className="-mt-2">
            <ReactStars
              count={5}
              size={30}
              value={ratingData?.averageRating}
              color2={"#FAD13D"}
              edit={false}
            />
          </div>
          <div className="text-[#ffffff] text-[16px] font-light -mt-1 ">
            {ratingData?.totalReviews} Reviews
          </div>
          <div className="w-[90%] h-0.5 my-2 bg-gray-200"></div>
          <div
            className={`flex flex-col justify-center w-[100%] ${isIpad ? "pb-0" : "pb-4"}`}
          >
            <div className="text-[#ffffff] text-sm text-center">
              Guest Engagement
            </div>
            <div className="flex justify-between mx-auto mt-4 w-[90%] items-center">
              <div className="flex justify-center w-[50%] border-r  items-center flex-col">
                <div className="text-[30px] text-[#29CC39] font-light">
                  {(
                    ((ratingData?.totalReviews -
                      ratingData?.emptyResponsesCount) /
                      ratingData?.totalReviews) *
                    100
                  ).toFixed(2) === "NaN" ||
                  (
                    ((ratingData?.totalReviews -
                      ratingData?.emptyResponsesCount) /
                      ratingData?.totalReviews) *
                    100
                  ).toFixed(2) === NaN
                    ? "--"
                    : (
                        ((ratingData?.totalReviews -
                          ratingData?.emptyResponsesCount) /
                          ratingData?.totalReviews) *
                        100
                      ).toFixed(2)}
                  %
                </div>
                <div className="text-[#ffffff] text-[16px] font-light -mt-1 ">
                  Reviews replied
                </div>
              </div>
              {/* <div className="h-[50px] w-0.5 -ml-2 mt-2 border-l"></div>  */}
              <div className="flex justify-center w-[50%] items-center flex-col">
                <div className="text-[30px] text-[#ffffff] font-light">
                  {ratingData?.emptyResponsesCount}
                </div>
                <div className="text-[#ffffff] text-[16px] font-light -mt-1 ">
                  Unreplied
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/rate-pulse/reputation");
            }}
            className={`w-full text-white text-center ${isIpad ? "py-1" : "py-1 "} rounded-lg mt-3 cursor-pointer`}
          >
            View All
          </div>
        </div>
      </div>
    </div>
  );
};
const AddWidgetCard = ({ height, onClick }) => {
  return (
    <div className="bg-white h-[436px] flex justify-center items-center rounded-lg">
      <div
        className="flex justify-normal items-center flex-col cursor-pointer"
        onClick={onClick}
      >
        <div>
          <AiOutlinePlus size={45} color="#081439" />
        </div>
        <div className="text-[#081439] text-sm">Add New Widget</div>
      </div>
    </div>
  );
};

export const ActionComponent = ({ setActionCount }) => {
  const navigate = useNavigate();
  const { data } = useGetActionsToTakeQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (data) {
      setActionCount(countCategoriesWithSolutions(data));
    }
  }, [data]);
  return (
    <div className="w-[100%] bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg rounded-lg p-4">
      <div className="w-[100%] flex flex-col">
        <div className="flex justify-between items-center">
          <div className="text-white">Take Actions </div>
          <div>
            <InfoToolTip
              content={
                "This feature provides a comparative analysis of your hotel's current performance against historical data."
              }
              position={"left"}
            />
          </div>
        </div>
        <div className="text-[#ffffff] flex-col md:flex-row text-sm mt-2">
          The following data display your property’s current performance in
          comparision to the past records.
        </div>
      </div>

      <div className="w-[100%] flex flex-col md:flex-row">
        <div className="w-full md:w-[70%]  mt-8 rounded-l-lg overflow-hidden">
          <div className="">
            <div className="bg-[#f6f7f900] p-4  flex justify-between">
              <div className="w-[35%] text-[#ebebed] text-sm  font-semibold">
                Action Item
              </div>
              <div className="w-[65%] text-[#ebebed] text-sm ml-5   font-semibold">
                Description
              </div>
            </div>
            {Array?.isArray(data?.data) &&
              data?.data.map((action, index) => {
                return (
                  <div
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                    className={`cursor-pointer ${currentIndex === index ? "bg-[#FF33330A] border-l-4 border-l-[#F43F3F]   border-b border-b-[#F43F3F]" : "bg-[#ffffff00]"} hover:bg-white/20 transition-all ease-in-out duration-100 p-4 cursor-pointer flex justify-between hover:border-l-4 hover:border-l-[#007BFF]`}
                  >
                    <div className="w-[25%] flex items-center gap-2 text-sm ">
                      <div className="text-[#ffffff] flex items-center justify-start gap-2">
                        {action?.taskCategory}
                      </div>
                      {/* <div>
                        <AiOutlineExclamationCircle color="#8794AD" />
                      </div> */}
                    </div>
                    <div className="w-[75%] text-[#ffffff] text-sm flex gap-4 items-center ">
                      <div className="flex items-center justify-between w-[12%]">
                        {/* <div>
                          <span className="text-[#252C32] font-semibold">
                            5
                          </span>{" "}
                          /10
                        </div> */}
                        <div>{/* <AiFillCaretDown color="red" /> */}</div>
                      </div>
                      <div className="w-[60%]">{action?.metrics}</div>
                      {/* <div className="flex text-[#081439] cursor-pointer gap-6 items-center">
                        <div
                          onClick={
                            currentIndex + 1 === data?.data.length
                              ? () => setCurrentIndex(0)
                              : () => setCurrentIndex(index)
                          }
                        >
                          Improve
                        </div>
                        <div>
                          <AiOutlineDown className="-rotate-90" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <SolutionTile data={data} dataIndex={currentIndex} />
      </div>
    </div>
  );
};
const Channel = ({ title, price, img }) => {
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  const color = [
    "#1abc9c",
    "#f1c40f",
    "#2ecc71",
    "#2980b9",
    "#fad390",
    "#f8c291",
    "#60a3bc",
    "#4a69bd",
  ];
  const navigate = useNavigate();
  const hId = CurrentProperty();
  const userId = UserId();
  const options = [
    { value: "reservation", label: "Reservation" },
    { value: "roomNights", label: "Room Nights" },
    { value: "revenue", label: "Revenue" },
    { value: "ADR", label: "ADR" },
  ];
  const [selectedItem, setSelectedItems] = useState({
    value: "reservation",
    label: "Reservation",
  });
  const { data, isLoading, isFetching } = useGetChannelQuery({
    userId: userId,
    hId: hId,
    type: selectedItem?.value,
  });

  const channelDetail = data?.data;
  const isIpad = useIsIpad();
  return (
    <>
      {isLoading || isFetching ? (
        <div className="w-[100%] h-[436px]">
          <CustomSkeleton height={436} />
        </div>
      ) : (
        <div className="flex bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg w-[100%] h-[436px] p-4 rounded-lg">
          <div className="flex flex-col w-full px-2">
            <div
              className={`h-10 text-[#2e343e] px-1 w-full flex  ${isIpad && "flex-col"} items-center justify-between  top-0 z-10`}
            >
              <p className={`font-medium text-white ${isIpad && "-ml-4"}`}>
                {title || "Channel Performance this Month"}
              </p>
              <div
                className={`flex items-center gap-4 ${isIpad && "justify-between w-[100%] mt-2"}`}
              >
                <Dropdown
                  options={options}
                  selectedItem={selectedItem?.label}
                  setSelectedItems={setSelectedItems}
                />
                <InfoToolTip
                  content={
                    "This tooltip offers insights into the performance of various booking channels."
                  }
                  index={"channelPerformance"}
                  position={"left"}
                />
              </div>
            </div>
            <div
              className={`w-full h-[300px] scroll-container ${isIpad ? "mt-10 " : "mt-5 "} overflow-y-scroll`}
            >
              {channelDetail &&
                channelDetail.map((item, index) => {
                  return (
                    <div
                      className="  py-2  group  mb-2 cursor-pointer  hover:bg-[#CCD6E5] rounded-xl "
                      onClick={() => navigate("/hotel_intelligence")}
                    >
                      <div
                        className="flex group-hover:p-1 justify-between
                group-hover:scale-[0.99] group-hover:transition-transform  transition-all ease-in-out duration-300
              "
                      >
                        <div className="flex items-center  lg:w-[100%] md:w-[100%] w-[100%] h-full ml-2 ">
                          {/* <div className="w-[100%]"> */}
                          {/* <img className="h-full w-fit " src={icon} /> */}
                          {/* </div> */}
                          <div className="flex -mt-1  items-center">
                            <div className="flex gap-2 items-center">
                              <div
                                className={`w-8 h-8 uppercase flex rounded-full justify-center text-white items-center text-sm`}
                                style={{ background: color.random() }}
                              >
                                {item?.source?.charAt(0)}
                              </div>
                              <p
                                data-tooltip-id={item.source}
                                className={` text-[#ffffff] truncate min-w-[${isIpad ? "100px max-w-[100px]" : ""}]  text-[14px] whitespace-nowrap font-[400]`}
                              >
                                {item.source}
                                <Tooltip
                                  id={item.source}
                                  content={item.source}
                                />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex text-[14px] w-full gap-2 whitespace-nowrap justify-end items-center font-medium group-hover:bg-[#CCD6E5] group-hover:px-2 ">
                          <p className="text-white">
                            {selectedItem?.label === "Revenue" ||
                            selectedItem?.label === "ADR"
                              ? formatCurrency(item?.value)
                              : item.value}
                          </p>

                          {/* <p className='font-semibold text-[#57F3B1] flex text-sm '>{item?.value} <p className=' text-neutral-dark-20'>/5</p></p> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div
              onClick={() => {
                navigate("/hotel_intelligence");
              }}
              className="w-full text-center text-white rounded-lg mt-4 cursor-pointer"
            >
              View All
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const UpcomingEvent = () => {
  const hId = CurrentProperty();
  const userId = UserId();
  const { data } = useGetUpcomingEventQuery({
    userId: userId,
    hId: hId,
  });
  const eventData = data?.data;

  return (
    <>
      <div className="flex w-[100%] h-[436px] p-4 rounded-lg bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg">
        <div className="flex flex-col gap-4 w-full px-2">
          <div className="flex justify-between items-center -mt-2  top-0 bg-white py-2 z-10">
            <div className="">Upcoming Events</div>
            <InfoToolTip
              content={
                "This feature highlights upcoming events and activities relevant to the property's location or target market."
              }
              index={"upcomingEvents"}
            />
            {/* <div>
        <Dropdown />
      </div> */}
          </div>
          <div className="flex flex-col gap-2 bg-white lg:w-[100%] md:w-[100%] w-[100%] h-full overflow-y-scroll scrollHide">
            {eventData?.map((item, index) => (
              <div
                key={index}
                title={item.eventName}
                className="flex flex-col justify-center gap-2 hover:bg-[#F0EBD8] h-14 w-80 rounded-lg"
              >
                <p className="text-[#2e343e] ml-3 text-[14px] font-semibold max-w-[100%] truncate">
                  {item.eventName}
                </p>
                <p className="text-gray-500 ml-3 text-xs flex items-center gap-2 whitespace-nowrap font-[400]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ABABAB"
                    viewBox="0 0 50 50"
                    width="20px"
                    height="20px"
                  >
                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z" />
                  </svg>
                  {format(
                    new Date(item.eventStartDateTime),
                    "dd-MMM-yyyy hh:mm:ss a "
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
const CancellationMonth = () => {
  const hId = CurrentProperty();
  const navigate = useNavigate();
  const userId = UserId();
  const { data } = useGetCancellationQuery({
    userId: userId,
    hId: hId,
  });
  const cancelationData = data?.data;

  const initial = cancelationData?.totalCancellationThisYear;
  const final = cancelationData?.totalCancellationLastYear;

  var percentage = ((initial - final) / final) * 100;

  percentage = parseFloat(percentage.toFixed(2));
  return (
    <>
      <div className=" flex flex-col w-[100%] h-[436px] p-4 bg-newGradient dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg rounded-lg justify-between ">
        <div className="flex flex-col gap-4  w-full px-2 ">
          <div className="flex mt-1 justify-between items-center">
            <p className="text-white">{"Cancellation this Month"}</p>
            <InfoToolTip
              content={
                "This insight highlights the total number of room nights and associated revenue lost due to cancellations within the current calendar month."
              }
              index={"CancellationTooltip"}
              position={"left"}
            />
          </div>
          <div className="flex  items-center">
            <p className="text-[32px] mr-2 text-white font-medium">
              {cancelationData?.totalCancellationThisYear}
            </p>
            <p
              className={`${percentage === Infinity ? "" : percentage > 0 ? "text-red-500" : "text-[#29CC39]"} text-[14px]`}
            >
              {percentage === Infinity
                ? ""
                : percentage > 0
                  ? `+${percentage}`
                  : Math.abs(percentage)}
              {percentage === Infinity ? "" : "%"}
            </p>
            <span></span>
          </div>
          <span className=" flex -mt-2 gap-2">
            <p className="text-white">
              {cancelationData?.totalCancellationLastYear}
            </p>
            <p className="text-white"> Last Year</p>
          </span>
          <span className=" flex h-[200px] scrollHide overflow-y-scroll flex-col justify-start gap-2">
            {cancelationData?.source?.map((item, index) => (
              <div className="flex flex-col mt-1">
                <span className=" flex justify-between">
                  <span className=" flex flex-col">
                    <p className="text-white">{item?.source}</p>
                    <p className="text-white text-xs">
                      {item?.totalCancellations} Booking
                    </p>
                  </span>
                  <p className="text-white">{item?.cancellationPercentage}%</p>
                </span>
              </div>
            ))}
          </span>
        </div>
        <div
          onClick={() => {
            navigate("/reservations", {
              state: {
                status: "Cancelled",
              },
            });
          }}
          className="w-full text-center py-1 text-white rounded-lg mt-2 cursor-pointer"
        >
          View All
        </div>
      </div>
    </>
  );
};
const WidgetModal = ({
  onClick,
  SelectedWidgets,
  refetchWidget,
  widgetIsLoading,
}) => {
  const { data, isLoading: allWidgetIsLoading } = useGetAllWidgetQuery();
  const [border, setBorder] = useState([]);
  const [temp, setTemp] = useState(true);
  const [widgetData, setWidgetData] = useState([]);
  const [postWidget] = usePostWidgetsMutation();

  const hId = CurrentProperty();
  const userId = UserId();
  const handleClick = (widget, index) => {
    if (
      border?.length <= 2 &&
      // border?.length > 0 &&
      !border?.includes(widget?.widgetId)
    ) {
      setBorder([...border, widget?.widgetId]);
      setWidgetData([...widgetData, widget]);
    }

    if (border?.includes(widget?.widgetId) && border?.length > 1) {
      setBorder(border.filter((item) => item !== widget?.widgetId));
      setWidgetData(
        widgetData.filter((item) => item.widgetId !== widget.widgetId)
      );
    }
  };
  const selectedIds = SelectedWidgets?.data.map((widgets) => widgets?.widgetId);
  const alreadySelectedWidget = SelectedWidgets?.data.map((widgets) => widgets);

  useEffect(() => {
    setBorder(selectedIds ? selectedIds : []);
    setWidgetData(alreadySelectedWidget ? alreadySelectedWidget : []);
  }, []);
  const handleAddWidget = () => {
    postWidget({
      hId: hId,
      userId: userId,
      widgets: widgetData,
    });
    onClick();
    refetchWidget();
  };
  return (
    <>
      <Backdrop>
        {allWidgetIsLoading ? (
          <BackdropLoader loading={allWidgetIsLoading} />
        ) : (
          <div className="w-[80%] mx-auto bg-newGradient dark:bg-lightGradient h-auto rounded-lg relative">
            {border?.length <= 3 && border?.length > 0 && (
              <div
                onClick={handleAddWidget}
                className="absolute right-4 bottom-4 bg-blue-400 text-white  py-2 px-6 rounded-lg hover:bg-blue-500 hover:text-white cursor-pointer"
              >
                Add
              </div>
            )}
            <div
              onClick={onClick}
              className="absolute right-2 top-1 text-[#ffc7c7] text-sm py-1 px-2 rounded-md cursor-pointer"
            >
              <AiFillCloseCircle size={28} />
            </div>
            <div className="text-lg font-medium px-5 py-2 text-white">
              Select Widget
            </div>
            <div className="w-[100%] ">
              <div className="grid grid-cols-4 justify-between gap-4 w-[100%] p-4">
                {Array.isArray(data?.data) &&
                  data?.data.map((widget, index) => (
                    <div
                      onClick={() => handleClick(widget, index)}
                      className={`w-[100%] ${border?.includes(widget?.widgetId) ? "border-sky-200 border-4" : ""}  bg-white shadow-md rounded-lg border`}
                    >
                      <img
                        src={
                          widget?.widgetName === "Channel Performance"
                            ? channelImg
                            : widget?.widgetName === "Cancellation"
                              ? cancellationImg
                              : widget?.widgetName === "Sentiment Analysis"
                                ? sentimental
                                : widget?.widgetName === "Reputation"
                                  ? reputationImg
                                  : widget?.widgetName === "Upcoming Event"
                                    ? eventsImg
                                    : ""
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </Backdrop>
    </>
  );
};

const Urgentactions = ({ data, date, refetch }) => {
  const [overrideRate, { isLoading: overideRateLoading }] =
    useOverrideRateMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTransition, setIsTransition] = useState(false);
  const isIpad = useIsIpad();
  const [isActive, setIsActive] = useState(false);
  const hId = CurrentProperty();
  const userId = UserId();
  const [inputValue, setInputValue] = useState("");
  const [acceptRate, { isLoading }] = useAcceptRateMutation();
  const [verifyRates, { isLoading: verifyRatesIsLoading }] =
    useVerifyRatesMutation();
  const [selectedDropdown, setSelectedDropdown] = useState();

  const handleAction = () => {
    const obj = {
      hId: hId,
      userId: userId,
      date: format(new Date(date), "yyyy-MM-dd"),
    };
    const verifyRateObj = {
      hId: hId,
    };
    if (inputValue && inputValue !== 0) {
      const obj = {
        hId: hId,
        userId: userId,
        overRideRates: [
          {
            rate: parseInt(inputValue),
            date: format(new Date(date), "yyyy-MM-dd"),
          },
        ],
      };
      overrideRate(obj)
        .unwrap()
        .then((res) => {
          toast.success("Rates Override Successfully", {
            position: "bottom-right",
          });
          refetch();
        })
        .catch((err) => {
          return toast.error(err?.data?.data?.message, {
            position: "bottom-right",
          });
        });
    } else {
      if (data?.isRateAccepted) {
        verifyRates(verifyRateObj)
          .unwrap()
          .then((resp) => {
            toast.success("Rates Verification Successfully Completed !", {
              position: "bottom-right",
            });
            refetch();
            setIsActive(true);
          })
          .catch((err) => {
            return toast.error(err?.data?.data?.message, {
              position: "bottom-right",
            });
          });
      } else {
        acceptRate(obj)
          .unwrap()
          .then((res) => {
            toast.success("Rate accepted successfully, sent to verification!", {
              position: "bottom-right",
            });
            verifyRates(verifyRateObj)
              .unwrap()
              .then((resp) => {
                toast.success("Rates Verification Successfully Completed !", {
                  position: "bottom-right",
                });
                refetch();
                setIsActive(true);
              })
              .catch((err) => {
                return toast.error(err?.data?.data?.message, {
                  position: "bottom-right",
                });
              });
          })
          .catch((err) => {
            toast.error("Something went wrong! try again after sometime.", {
              position: "bottom-right",
            });
          });
      }
    }
  };

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(false);
      }, [8000]);
    }
  }, [isActive]);

  useEffect(() => {
    setIsTransition(true);
  }, []);

  const Card = ({
    type = "default",
    title,
    isInput,
    value,
    setInputValue,
    inputValue,
    percentageDiff,
  }) => {
    const inputRef = useRef(null);
    useEffect(() => {
      if (isInput && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isInput]);
    const handleInput = (e) => {
      const value = e.target.value;
      if (value === "" || /^-?\d{0,6}(\.\d{0,2})?$/.test(value)) {
        setInputValue(value);
      }
    };
    return (
      <>
        {type === "occupancy" ? (
          <div 
          style={{
            background: "linear-gradient(45deg, #95C82A, transparent)",
          }}
          className="relative w-[160px] h-[76px]   overflow-hidden flex items-end rounded-2xl">
            {/* Water layers */}
            <div className="absolute inset-0">
              <div
                className="relative w-full water-layer layer-1"
                style={{ height: `${100-value}%` }}
              ></div>
              <div
                className="absolute w-full top-0 water-layer layer-2"
                style={{ height: `${100-value}%` }}
              ></div>
              <div
                className="absolute w-full top-0 water-layer layer-3"
                style={{ height: `${100-value}%` }}
              ></div>
            </div>
            {/* Percentage */}
            <div className="absolute inset-0 flex items-end justify-center">
              <div className="flex h-[76px] flex-col items-center justify-center">
              <span className="text-white text-xl font-semibold z-10">{value}%</span>
              <span className="text-xs text-[#ffffff]z-10">{title}</span></div>
            </div>
          </div>
        ) : 
        type === "date" ? (
          <div
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
            className="p-4 w-[120px] rounded-2xl"
          >
          <div
            className={`text-xl ${title === "Suggested Rates" ? " text-[#95C82A]" : " text-[#ffffff]"}`}
          >
            {value}
          </div>
            <div className="text-xs text-[#ffffff]">{title}</div>
          </div>
        ) : 
        (
          <div
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
            className="p-4 w-[160px] rounded-2xl"
          >
            {isInput ? (
              <input
                placeholder={`₹0.00`}
                className={`text-xl mb-2 rounded-md focus:outline-none px-2 placeholder:text-sm bg-white max-w-[120px] ${title === "Enter Override Rates" ? " text-[#d8861a]" : " text-[#272A3D]"}`}
                value={inputValue}
                onChange={handleInput}
                type="text"
                ref={inputRef}
              />
            ) : (
              <div
                className={`text-xl ${title === "Suggested Rates" ? " text-[#95C82A]" : " text-[#ffffff]"}`}
              >
                {value}
              </div>
            )}
            <div className="text-xs text-[#ffffff]">{title}</div>
          </div>
        )}
      </>
    );
  };
  return (
    <div className="min-h-[380px] max-h-[380px] w-[100%] text-white bg-transparent  rounded-l-lg p-4 relative">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-[18px]">
            Urgent Actions{" "}
            <span className="text-sm text-gray-200">
              {" "}
              for{" "}
              {format(new Date(date), "dd-MMM-yy") ===
              format(new Date(), "dd-MMM-yy")
                ? "today"
                : format(new Date(date), "dd-MMM-yy")}
            </span>
          </div>
          {!data?.isRateAccepted && !data?.isRateVerified && (
            <div className="text-[15px]">
              Set your property price to{" "}
              <span className="font-semibold">
                {formatCurrency(data?.suggestedRate)}
              </span>{" "}
              for{" "}
              {format(new Date(date), "dd-MMM-yy") ===
              format(new Date(), "dd-MMM-yy")
                ? "today"
                : format(new Date(date), "dd-MMM-yy")}{" "}
              to boost bookings and maximize revenue!
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          {data?.isRateAccepted && !data?.isRateVerified && (
            <div
              onClick={!isLoading && handleAction}
              className="text-[14px] text-white bg-[#3E5c76] py-1 px-4 rounded-lg cursor-pointer flex gap-2 items-center"
            >
              <AiOutlineCheck size={16} />{" "}
              {verifyRatesIsLoading ? "Verifying..." : "Verify"}
            </div>
          )}
          {/* <div>
            <Dropdown
              options={[
                { label: "Aggressive Mode", value: "Aggressive Mode" },
                { label: "Normal Mode", value: "Normal Mode" },
              ]}
              selectedItem={selectedDropdown?.label}
              setSelectedItems={setSelectedDropdown}
            />
          </div> */}
        </div>
      </div>
      <div className="mt-4 flex gap-4 items-center">
        {!isActive && !data?.isRateAccepted && !data?.isRateVerified && (
          <>
          <Card
          type={"date"}
            value={18}
            title={"September"}
          />
            <Card
              value={formatCurrency(data?.actualRate)}
              title={"Current Rate"}
            />
            <Card
              value={formatCurrency(data?.suggestedRate)}
              title={"Suggested Rates"}
            />
            <Card type={"occupancy"} value={(data?.occupancy || 0).toFixed(0)} title={"Occupancy"} />
            {/* <Card
              title={"Enter Override Rates"}
              isInput={true}
              inputValue={inputValue}
              setInputValue={setInputValue}
              value={formatCurrency(data?.suggestedRate)}
            /> */}
            <div className=" flex flex-col gap-2">
              {data?.isRateAccepted === false && (
                <div
                  onClick={!isLoading && handleAction}
                  className="text-[14px] text-white bg-[#3E5c76] py-1 px-4 rounded-md cursor-pointer justify-center flex gap-2 items-center"
                >
                  {verifyRatesIsLoading ? (
                    "Verifying..."
                  ) : overideRateLoading || isLoading ? (
                    "Please wait..."
                  ) : (
                    <span className="flex gap-2">
                      {" "}
                      <AiOutlineCheck size={16} /> Accept
                    </span>
                  )}
                </div>
              )}
              <div
                onClick={() => {
                  navigate("/rate_suggestions", {
                    state: {
                      date: date,
                    },
                  });
                }}
                className="text-[14px] relative z-20 whitespace-nowrap rounded-md text-white bg-[#272A3D] py-1 px-4 cursor-pointer flex gap-2 items-center"
              >
                <AiOutlineFileText size={16} />
                See Details
              </div>
            </div>
          </>
        )}
        {
          <div
            className={`absolute animationDiv  duration-500 transition-all ease-in-out  ${isTransition ? "w-[100%]" : "w-[0%]"} flex justify-end left-0 z-10 ${isActive || data?.isRateVerified || data?.isRateAccepted ? "top-[70px]" : `${isIpad ? "top-[180px]" : "top-[130px]"}`}  `}
            // style={{
            //   background:
            //     "linear-gradient(45deg, rgb(17 120 255), rgb(183 183 183))",
            // }}
          >
            <img src={revens} width={isIpad ? 150 : 210} />
          </div>
        }
      </div>
      {/* <div className="mt-4">
        <div className="text-[18px] my-2">Today's Analysis</div>
        <div className="bg-[#F0F0F0] flex gap-2 items-center p-4 w-[260px] rounded-2xl">
          <div className="text-center">
            <div className="text-[10px] text-[#272A3D]">Revenue</div>
            <div className="text-xl text-[#272A3D]">233784</div>
          </div>
          <div className="h-[30px] w-0.5 bg-white"></div>
          <div className="text-center">
            <div className="text-[10px] text-[#272A3D]">Occupancy</div>
            <div className="text-xl text-[#272A3D]">34</div>
          </div>
          <div className="h-[30px] w-0.5 bg-white"></div>
          <div className="text-center">
            <div className="text-[10px] text-[#272A3D]">ADR</div>
            <div className="text-xl text-[#272A3D]">233784</div>
          </div>
        </div>
      </div> */}
      <div
        className={` duration-1000 flex items-center  p-4 text-white  text-lg transition-all ease-in-out  ${isTransition ? "w-[100%]" : "w-[0%]"}  h-[150px] relative ${isActive || data?.isRateVerified || data?.isRateAccepted ? "top-[40px] " : "top-0"} transition-all ease-in-out duration-500  mt-4 rounded-3xl`}
        style={{
          background: data?.isRateVerified
            ? "linear-gradient(45deg, rgb(99 190 11), rgb(240, 235, 216))"
            : data?.isRateAccepted
              ? "linear-gradient(45deg, rgb(204 112 18), rgb(240, 235, 216))"
              : "linear-gradient(45deg, rgb(21, 43, 93), rgb(240 235 216 / 8%))",
        }}
      >
        <div className="w-[80%]">
          {data?.message && <div className="w-[100%]">"{data?.message}"</div>}
        </div>
      </div>
    </div>
  );
};
const Next7DayPricing = ({ setSelectedDate, selectedDate, data }) => {
  const isIpad = useIsIpad();

  function getNext7Days() {
    const days = [];
    const today = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    for (let i = 0; i < 8; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);

      const dayOfWeek = dayNames[nextDay.getDay()];
      const month = monthNames[nextDay.getMonth()];
      const date = nextDay.getDate();
      const year = nextDay.getFullYear();
      days.push(`${dayOfWeek}, ${month} ${date}, ${year}`);
    }

    return days;
  }

  return (
    <div className="p-4 pl-0 bg-transparent   rounded-r-lg min-h-[380px] max-h-[380px]">
      <div className="flex flex-col justify-start items-start">
        <div>
          <div className="text-[18px] text-white">Next 7 day pricing</div>
        </div>
        <div className="flex gap-2 my-2 items-center scrollHide  min-w-[100%] max-w-[100%] overflow-x-scroll">
          {getNext7Days().map((item, index) => {
            const date = new Date(item);
            const day = item?.split(" ")[2]?.split(",")[0];
            const month = item?.split(" ")[1];
            return (
              <div
                className={`${selectedDate === item || item === format(new Date(selectedDate), "EEEE, MMMM d, yyyy") ? "bg-[#0062ff] text-white" : "bg-[#1D2D44] text-white"} cursor-pointer  group transition-all ease-in-out duration-200 text-center  rounded-lg  ${isIpad ? "p-2" : "px-3 py-2"}`}
                onClick={() => setSelectedDate(item)}
              >
                {/* ${index === 0 && 'sticky left-0'} */}
                <div className="text-[18px]  group-hover:text-white font-semibold transition-all ease-in-out duration-200">
                  {day}
                </div>
                <div className="text-[10px] group-hover:text-white transition-all ease-in-out duration-200">
                  {month}
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 gap-2 w-[100%]">
          <div
            className="rounded-lg p-4 max-w-[100%] truncate"
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
          >
            <div className="text-[12px] w-[100%] text-white flex justify-between items-center">
              Revenue{" "}
              {!isIpad && (
                <div
                  className={`text-[11px] group ${data?.revenuePerDiff?.toString()?.includes("-") ? "bg-red-200 text-red-500" : "bg-[#ECFDF5] text-[#29CC39]"} w-fit px-2 py-1 rounded-lg text-[#101010]`}
                >
                  <span className="group-hover:hidden">
                    {data?.revenuePerDiff
                      ? Math.abs(data?.revenuePerDiff)
                      : "--"}
                    %
                  </span>
                  <span className="group-hover:block hidden">
                    {calculateDifference(
                      data?.revenueThisYear,
                      data?.revenueLastYear,
                      true
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center gap-2 justify-between">
              <div className="text-[18px] font-semibold text-[#ffffff]">
                {formatCurrency(data?.revenueThisYear)}
              </div>
            </div>

            <div className="text-[14px] text-[#ffffff]">
              {formatCurrency(data?.revenueLastYear)}{" "}
              <span className="text-xs" title="Same day last year">
                Last year
              </span>
            </div>
          </div>
          <div
            className=" rounded-lg p-4 w-[100%]"
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
          >
            <div className="text-[12px] text-white flex justify-between items-center">
              Room Nights{" "}
              {!isIpad && (
                <div
                  className={`text-[11px] group ${data?.roomNightsPerDiff?.toString()?.includes("-") ? "bg-red-200 text-red-500" : "bg-[#ECFDF5] text-[#29CC39]"} w-fit px-2 py-1 rounded-lg text-[#101010]`}
                >
                  <span className="group-hover:hidden block">
                    {data?.roomNightsPerDiff
                      ? Math.abs(data?.roomNightsPerDiff)
                      : "--"}
                    %
                  </span>

                  <span className="group-hover:block hidden">
                    {calculateDifference(
                      data?.roomNightsThisYear,
                      data?.roomNightsLastYear
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center gap-2 justify-between">
              <div className="text-[18px] font-semibold text-[#ffffff]">
                {data?.roomNightsThisYear}
              </div>
            </div>

            <div className="text-[14px] text-[#ffffff]">
              {data?.roomNightsLastYear}{" "}
              <span className="text-xs" title="Same day last year">
                Last year
              </span>
            </div>
          </div>
        </div>
        <div className="w-[100%] mt-2 grid grid-cols-2 gap-2">
          <div
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
            className="rounded-lg p-4"
          >
            <div className="text-[12px] text-white flex justify-between items-center">
              Cancellation{" "}
              {!isIpad && (
                <div
                  className={`text-[11px] group ${data?.cancellationThisYear > data?.cancellationLastYear ? "bg-red-200 text-red-500" : "bg-[#ECFDF5] text-[#29CC39]"} w-fit px-2 py-1 rounded-lg text-[#101010]`}
                >
                  <span className="group-hover:hidden block">
                    {data?.cancellationPerDiff
                      ? Math.abs(data?.cancellationPerDiff)
                      : "--"}
                    %
                  </span>
                  <span className="group-hover:block hidden">
                    {calculateDifference(
                      data?.cancellationThisYear,
                      data?.cancellationLastYear
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="text-[18px] font-semibold text-[#ffffff]">
                {data?.cancellationThisYear}
              </div>
            </div>

            <div className="text-[14px] text-[#ffffff]">
              {data?.cancellationLastYear}{" "}
              <span className="text-xs" title="Same day last year">
                Last year
              </span>
            </div>
          </div>
          <div
            style={{
              background: "linear-gradient(45deg, #1a386b, transparent)",
            }}
            className="rounded-lg p-4"
          >
            <div className="text-[12px] text-white whitespace-nowrap flex justify-between items-center">
              ADR{" "}
              {!isIpad && (
                <div
                  className={`text-[11px] group ${data?.ADRPerDiff?.toString()?.includes("-") ? "bg-red-200 text-red-500" : "bg-[#ECFDF5] text-[#29CC39]"} w-fit px-2 py-1 rounded-lg text-[#101010]`}
                >
                  <span className="group-hover:hidden block">
                    {data?.ADRPerDiff ? Math.abs(data?.ADRPerDiff) : "--"}%
                  </span>
                  <span className="group-hover:block hidden">
                    {calculateDifference(
                      data?.ADRThisYear,
                      data?.ADRLastYear,
                      true
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center">
              <div className="text-[18px] font-semibold text-[#ffffff]">
                {formatCurrency(data?.ADRThisYear)}
              </div>
            </div>

            <div className="text-[14px] text-[#ffffff]">
              {formatCurrency(data?.ADRLastYear)}{" "}
              <span className="text-xs" title="Same day last year">
                Last year
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChannelManagerPasswordModal = ({ hideModal }) => {
  const hid = CurrentProperty();
  const userID = UserId();

  const [addNewPropertyChannelManager] =
    useAddNewPropertyChannelManagerMutation();
  const { data: channelManagerList } = useChannelManagerListQuery();
  const [selectedCM, setSelectedCM] = useState();
  const [selectedCMId, setSelectedCMId] = useState();
  const [userName, setUserName] = useState();
  const [propertyCode, setPropertyCode] = useState();
  const [password, setPassword] = useState();
  const [showCMlist, setShowCMlist] = useState(false);

  const handleSubmit = () => {
    const channelManagerBody = {
      hId: hid,
      // newhId: status ? propertyData?.hId : newHId,
      cmId: selectedCMId,
      userId: userID,
      userCredential: {
        username: userName,
        password: password,
        propertyCode: propertyCode,
      },
    };
    addNewPropertyChannelManager(channelManagerBody)
      .unwrap()
      .then((res) => {
        toast.success("Channel Manager Credential Saved Successfully!", {
          position: "bottom-right",
        });
        hideModal();
      })
      .catch(() => {
        toast.error("Something Went Wrong!", {
          position: "bottom-right",
        });
        hideModal();
      });
  };
  return (
    <Backdrop>
      <div className="w-[70%] relative p-6 h-fit rounded-lg bg-white">
        <div className="text-3xl">Password Expired</div>
        <div className="text-xs my-2">
          Looks like your channel manager password is changed.
        </div>
        <div className="text-xs w-[45%]">
          We’re getting troubled in importing your data. Connect your channel
          manager for stream less integration
        </div>

        <div className="mt-6 flex flex-col gap-2">
          <div>
            <div className="text-[16px] my-2">Select Channel Manager</div>
            <div
              className={`border flex justify-between items-center ${showCMlist && "border-blue-400"} text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
              onClick={() => setShowCMlist(!showCMlist)}
            >
              {selectedCM ? selectedCM : "Select Chanel Manager"}
              {showCMlist && (
                <div className="h-[200px] left-0 top-[30px] z-10 bg-white mt-4 absolute w-[400px] shadow-xl drop-shadow-md rounded-md overflow-y-scroll">
                  {channelManagerList?.data ? (
                    channelManagerList?.data?.map((item) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedCM(item?.cmname);
                            setSelectedCMId(item?.cmid);
                          }}
                          className="text-sm cursor-pointer hover:bg-gray-100 duration-150 ease-in-out p-2"
                        >
                          {item?.cmname}
                        </div>
                      );
                    })
                  ) : (
                    <span className="p-4 mt-2">Loading...</span>
                  )}
                </div>
              )}
              <div>
                <AiOutlineDown
                  className={`${showCMlist ? "rotate-180" : "rotate-0"}  transition-all duration-200 ease-in-out`}
                />
              </div>
            </div>
          </div>

          {selectedCM === "eZee" && (
            <div>
              <div className="text-[16px] my-2">Enter Property Code</div>
              <input
                placeholder="12345"
                value={propertyCode}
                onChange={(e) => setPropertyCode(e.target.value)}
                className={` flex justify-between items-center  text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
              />
            </div>
          )}
          <div>
            <div className="text-[16px] my-2">Enter Username</div>
            <input
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="XYZ12"
              className={` flex justify-between items-center  text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
            />
          </div>
          <div>
            <div className="text-[16px] my-2">Enter Password</div>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="*****"
              type="password"
              className={` flex justify-between items-center  text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
            />
          </div>
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="w-fit px-2 py-1 bg-blue-500 hover:bg-blue-700 duration-150 ease-in-out transition-all text-white text-sm rounded-lg"
            >
              Save Credential
            </button>
          </div>
        </div>
        <div className="absolute top-0 right-4">
          <img src={passwordExpire} />
        </div>
      </div>
    </Backdrop>
  );
};

const HotelPerformanceTile = ({ isOpen, setHotelPerformanceIsOpen }) => {
  const { data, isLoading, isFetching } = useGetHotelPerformanceDetailQuery({
    hId: CurrentProperty(),
  });
  const Chip = ({ label, color = "gray" }) => {
    const getBackgroundColor = () => {
      switch (color) {
        case "red":
          return "#f44336";
        case "green":
          return "#c6f0d0";
        case "yellow":
          return "#fff3c8";
        default:
          return "#e0e0e0"; // Default gray color
      }
    };

    const chipStyle = {
      display: "inline-block",
      padding: "2px 6px",
      borderRadius: "16px",
      backgroundColor: getBackgroundColor(),
      color:
        color === "green" ? "#3ca856" : color === "yellow" ? "#f8c059" : "#fff",
      border:
        color === "green"
          ? "1px solid #55d875"
          : color === "yellow"
            ? "1px solid #f8c059"
            : "1px solid black",
      fontWeight: "bold",
      fontSize: "8px",
      textAlign: "center",
      cursor: "default",
    };

    return <div style={chipStyle}>{label}</div>;
  };
  const RadialProgress = ({
    percentage,
    size = 100,
    strokeWidth = 10,
    percentData,
  }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    const getGradient = () => {
      if (percentage < 50) {
        return "url(#gradient-red-yellow)";
      } else if (percentage < 75) {
        return "url(#gradient-yellow-green)";
      } else {
        return "url(#gradient-green)";
      }
    };

    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <defs>
          <linearGradient
            id="gradient-red-yellow"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="red" />
            <stop offset="100%" stopColor="yellow" />
          </linearGradient>
          <linearGradient
            id="gradient-yellow-green"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="yellow" />
            <stop offset="100%" stopColor="green" />
          </linearGradient>
          <linearGradient
            id="gradient-green"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="green" />
            <stop offset="100%" stopColor="darkgreen" />
          </linearGradient>
        </defs>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={getGradient()}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={size / 4} // Adjust font size as needed
          fill="white"
          className="font-[700]"
        >
          {percentData}%
        </text>
      </svg>
    );
  };
  const RadialInfoComp = ({
    percentage,
    heading,
    description,
    isGreen = false,
    isChip,
    chipLabel,
    chipColor,
    percentData = 0,
  }) => {
    return (
      <div className="flex gap-4 w-[100%]">
        <div>
          {" "}
          <RadialProgress
            percentData={percentData}
            percentage={percentage}
            size={50}
            strokeWidth={5}
          />
        </div>
        <div className="w-[100%]">
          <div className="flex items-center gap-2 w-[100%]">
            <div
              className={`font-medium w-[100%] whitespace-nowrap ${isGreen ? "text-green-300" : "text-white"} `}
            >
              {heading}
            </div>
            {isChip && (
              <div className="-mt-2 w-[100%]">
                <Chip label={chipLabel} color={chipColor} />
              </div>
            )}
          </div>
          <div className="text-sm w-[100%] text-white whitespace-nowrap">{description}</div>
        </div>
      </div>
    );
  };
  const SemiRadialProgress = ({
    percentage,
    size = 165,
    strokeWidth = 10,
    percentData = 0,
    isCurrency = false,
  }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = Math.PI * radius; // Half of a full circle's circumference
    const offset = circumference - (percentage / 100) * circumference;

    const getGradient = () => {
      if (percentage < 50) {
        return "url(#gradient-red-yellow)";
      } else if (percentage < 75) {
        return "url(#gradient-yellow-green)";
      } else {
        return "url(#gradient-green)";
      }
    };

    return (
      <svg
        width={size}
        height={size / 2} // Half the height for semi-circle
        viewBox={`0 0 ${size} ${size / 2}`}
        style={{ display: "block", margin: "auto" }}
      >
        <defs>
          <linearGradient
            id="gradient-red-yellow"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="red" />
            <stop offset="100%" stopColor="yellow" />
          </linearGradient>
          <linearGradient
            id="gradient-yellow-green"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="yellow" />
            <stop offset="100%" stopColor="green" />
          </linearGradient>
          <linearGradient
            id="gradient-green"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="0%" stopColor="green" />
            <stop offset="100%" stopColor="darkgreen" />
          </linearGradient>
        </defs>
        {/* Background circle (grey remaining part) */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset="0"
          strokeLinecap="round"
          transform={`rotate(180 ${size / 2} ${size / 2})`} // Rotate 180 degrees to start at the top middle
        />
        {/* Foreground circle (colored part based on percentage) */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={getGradient()}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform={`rotate(180 ${size / 2} ${size / 2})`} // Rotate 180 degrees to start at the top middle
        />
        <text
          x="50%"
          y="100%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={size / 10}
          fill="#333"
          dy="-10"
        >
          {isCurrency ? formatCurrency(percentData) : percentData}
        </text>
      </svg>
    );
  };
  const DetailsCard = ({
    title,
    progressPercentage = 0,
    previousPercentage,
    mainValue,
    percentData,
    isCurrency,
  }) => {
    return (
      <div className="p-2 relative  my-[10px]  border bg-white min-w-[250px] drop-shadow-sm rounded-2xl">
        <div className="flex items-center gap-2">
          <div>{title}</div>
          <div>
            <InfoToolTip />
          </div>
        </div>
        <div className="flex gap-2  flex-col justify-center items-center">
          <div className="flex gap-2 mt-[15px] flex-col justify-center items-center">
            <Chip label={"Need Attention"} color={"yellow"} />
            <div className="text-xl">
              {isCurrency ? formatCurrency(mainValue) : mainValue}
            </div>
            <div className="text-xs text-gray-500">
              <span className="text-red-500">{previousPercentage}%</span> from
              STLY
            </div>
          </div>
          <div className="absolute bottom-0">
            <SemiRadialProgress
              percentData={percentData}
              percentage={progressPercentage}
              size={165}
              isCurrency={isCurrency}
            />
          </div>
        </div>
      </div>
    );
  };
  const navigate = useNavigate();
  const {
    data: hotelPerformanceDashBoardData,
    isLoading: hotelPerformanceIsLoading,
  } = useGetHotelPerformanceDashboardQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });
  const personalFormatter = (num) => {
    if (num > 0) {
      return num / 2 + 50;
    } else {
      return Math.abs(Math.abs(num / 2) - 50);
    }
  };
  const isIpad = useIsIpad();
  return (
    <div
      className={`${isOpen ? "h-auto" : "h-fit"}  bg-newGradient  dark:bg-lightGradient backdrop-blur-lg drop-shadow-lg shadow-lg  border-white/20 rounded-lg`}
    >
      <div
        className={`p-2 px-4 relative gap-4 flex justify-between items-center py-4  w-[100%]  `}
      >
        <div className={`flex ${isIpad && "flex-col"}`}>
          <div className="font-medium text-lg text-white w-fit whitespace-nowrap mb-2">
            Hotel Performance
          </div>
          {!isOpen && (
            <div
              className={`flex gap-12 w-[100%] ${isIpad ? "w-[90%]" : "w-[100%]"}  ml-[40px]`}
            >
              <RadialInfoComp
                percentage={personalFormatter(
                  hotelPerformanceDashBoardData?.data?.overallOutcome
                )}
                percentData={
                  hotelPerformanceDashBoardData?.data?.overallOutcome
                }
                heading={"Congratulations"}
                isGreen={true}
                description={` ${hotelPerformanceDashBoardData?.data?.overallOutcome >= 75 ? "Excellent Performance" : hotelPerformanceDashBoardData?.data?.overallOutcome >= 50 ? "Good Performance" : "Poor Performance"}`}
              />
              <RadialInfoComp
                percentage={personalFormatter(
                  hotelPerformanceDashBoardData?.data?.revenue?.perChange
                )}
                heading={formatCurrency(
                  hotelPerformanceDashBoardData?.data?.revenue?.period1
                )}
                isChip={true}
                percentData={
                  hotelPerformanceDashBoardData?.data?.revenue?.perChange
                }
                chipLabel={"On Track"}
                chipColor={"green"}
                description={`${hotelPerformanceDashBoardData?.data?.revenue?.perChange || "--"}% increase in Revenue \n from STLY`}
              />
              <RadialInfoComp
                percentage={personalFormatter(
                  hotelPerformanceDashBoardData?.data?.room?.perChange
                )}
                heading={`${hotelPerformanceDashBoardData?.data?.room?.period1 || "--"} Room nights`}
                isChip={true}
                chipColor={"yellow"}
                percentData={
                  hotelPerformanceDashBoardData?.data?.room?.perChange
                }
                chipLabel={"Need Attention"}
                description={`${hotelPerformanceDashBoardData?.data?.room?.perChange || "--"}  increase in Occupancy \n from STLY`}
              />
            </div>
          )}
        </div>
        <div className={`flex ${isIpad && "flex-col"}  gap-2`}>
          <div
            className={`${isIpad ? "flex-col" : ""}  w-[100%] flex justify-end`}
          >
            <div
              className={`bg-[#0062ff] p-2  cursor-pointer rounded-lg  ${isIpad ? "w-full" : "w-fit"}  flex justify-center`}
              onClick={() => setHotelPerformanceIsOpen((prev) => !prev)}
            >
              <AiOutlineArrowsAlt color="white" size={18} />
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/hotel_performance");
            }}
            className={`flex tems-center  ${isIpad ? "px-2 max-w-[80px] i" : "px-4  min-w-[130px] "} whitespace-nowrap items-center bg-[#ffffff] text-black py-2  text-[11px] rounded-lg  gap-2 top-[26%] cursor-pointer`}
          >
            Know More <AiOutlineArrowRight />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="flex overflow-x-auto gap-4">
          <div
            className={`col-span-2 p-2 sticky left-0 z-10 mr-2  m-[10px]  ${isIpad ? " min-w-[60%]" : "min-w-[30%]"} ${hotelPerformanceDashBoardData?.data?.overallOutcome > 50 ? `bg-[#F2F9D9]` : `bg-[#dc7e7e]`} rounded-2xl`}
          >
            {hotelPerformanceDashBoardData?.data?.overallOutcome > 50 ? (
              <div className="flex items-center gap-2 mb-2">
                <div className="bg-[#0DA858] w-fit p-2 rounded-full">
                  <AiOutlineCheck color="white" />
                </div>
                <div className="text-[#0DA858] text-sm font-semibold">
                  Congratulations, your hotel performed excellent!!
                </div>
              </div>
            ) : (
              <h1 className=" text-white w-fit px-3 rounded-lg font-bold">
                We need to work on hotel, immediately
              </h1>
            )}
            <hr />
            <div className="flex items-center gap-2 my-2">
              <div className="rounded-full border h-16 w-16">
                {" "}
                <img src={RevensHey} className="object-cover" alt="" />
              </div>
              <div className="text-[#131313] text-sm">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">Revens</div>
                  <div className="text-xs">25 min ago</div>
                </div>
                <q className="font-semibold">
                  Hotel’s overall performance score is{" "}
                  {hotelPerformanceDashBoardData?.data?.overallOutcome}% due to
                  occupancy
                </q>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div
                className={`text-[12px] ${hotelPerformanceDashBoardData?.data?.overallOutcome > 50 ? `text-[#0DA858]` : `text-white`}`}
              >
                {hotelPerformanceDashBoardData?.data?.outcomes}
              </div>
              <div>
                <RadialProgress
                  percentage={personalFormatter(
                    hotelPerformanceDashBoardData?.data?.overallOutcome
                  )}
                  percentData={
                    hotelPerformanceDashBoardData?.data?.overallOutcome
                  }
                />
              </div>
            </div>
          </div>
          <DetailsCard
            title={"Revenue"}
            isCurrency={true}
            mainValue={hotelPerformanceDashBoardData?.data?.revenue?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.revenue?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.revenue?.perChange
            )}
            percentData={hotelPerformanceDashBoardData?.data?.revenue?.period2}
          />
          <DetailsCard
            title={"Room Nights"}
            isCurrency={false}
            mainValue={hotelPerformanceDashBoardData?.data?.room?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.room?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.room?.perChange
            )}
            percentData={hotelPerformanceDashBoardData?.data?.room?.period2}
          />
          <DetailsCard
            title={"Cancelled Room Nights"}
            isCurrency={false}
            mainValue={
              hotelPerformanceDashBoardData?.data?.cancellations?.period1
            }
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.cancellations?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.cancellations?.perChange
            )}
            percentData={
              hotelPerformanceDashBoardData?.data?.cancellations?.period2
            }
          />
          <DetailsCard
            title={"Lead Time"}
            isCurrency={false}
            mainValue={hotelPerformanceDashBoardData?.data?.lead?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.lead?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.lead?.perChange
            )}
            percentData={hotelPerformanceDashBoardData?.data?.lead?.period2}
          />
          <DetailsCard
            title={"Rank"}
            isCurrency={false}
            mainValue={hotelPerformanceDashBoardData?.data?.ranks?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.ranks?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.ranks?.perChange
            )}
            percentData={hotelPerformanceDashBoardData?.data?.ranks?.period2}
          />
          <DetailsCard
            title={"Competitor"}
            isCurrency={true}
            mainValue={hotelPerformanceDashBoardData?.data?.competitor?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.competitor?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.competitor?.perChange
            )}
            percentData={
              hotelPerformanceDashBoardData?.data?.competitor?.period2
            }
          />
          <DetailsCard
            title={"Reputation"}
            isCurrency={false}
            mainValue={hotelPerformanceDashBoardData?.data?.reputation?.period1}
            previousPercentage={
              hotelPerformanceDashBoardData?.data?.reputation?.perChange
            }
            progressPercentage={personalFormatter(
              hotelPerformanceDashBoardData?.data?.reputation?.perChange
            )}
            percentData={
              hotelPerformanceDashBoardData?.data?.reputation?.period2
            }
          />
        </div>
      )}
    </div>
  );
};
