import React, { useState, useEffect } from "react";
import "./DraggableTable.css"; // Import your CSS file for styling
import dragDark from "../../assets/icons/dragDark.svg";
import threeDotsDark from "../../assets/icons/threeDotsDark.svg";

const DraggableTable = ({ initialHeaders, initialRows }) => {
  const [headers, setHeaders] = useState(initialHeaders || []);
  const [rows, setRows] = useState(initialRows || []);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStartHeader = (e, index) => {
    if (index === 0) {
      e.preventDefault(); // Prevent dragging for the first header column
    } else {
      e.dataTransfer.setData("index", index); // Set the dragged index
      setDraggedIndex(index);
    }
  };

  const handleDragStartRow = (e, rowIndex) => {
    e.dataTransfer.setData("rowIndex", rowIndex);
  };

  const handleDragOverHeader = (e, index) => {
    e.preventDefault(); // Prevent default behavior to enable dropping
    if (draggedIndex === 0 || index === 0) return; // Prevent reordering the first header
    setDraggedIndex(index);
  };

  const handleDragOverRow = (e, rowIndex) => {
    e.preventDefault(); // Prevent default behavior to enable dropping
    const draggedRowIndex = parseInt(e.dataTransfer.getData("rowIndex"));
    if (draggedRowIndex === rowIndex) return; // Prevent dropping at the same position
    const newRows = [...rows];
    const [removed] = newRows.splice(draggedRowIndex, 1);
    newRows.splice(rowIndex, 0, removed);
    setRows(newRows);
  };

  const mergeColumns = (rowIndex, colIndex) => {
    const newRows = [...rows];
    newRows[rowIndex][colIndex] = `${newRows[rowIndex][colIndex]} - Merged`;
    setRows(newRows);
  };

  return (
    <div className="report-builder">
      <div className="report-container" onDragOver={(e) => e.preventDefault()}>
        {headers.length === 0 || rows.length === 0 ? (
          <p>No data found.</p>
        ) : (
          <div className="table-container">
            <table className="report-table">
              <thead className="bg-[#EEF1F6] dark:bg-[#56ebf2] dark:text-[#FFFFFF]  h-[60px] text-[#131926] text-[13px]">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      draggable={index !== 0} // Allow dragging for all headers except the first one
                      onDragStart={(e) => handleDragStartHeader(e, index)}
                      onDragOver={(e) => handleDragOverHeader(e, index)}
                      className={index === 0 ? "fixed-header" : ""}
                      style={{ width: header.width || "auto",padding:'20px',fontWeight:500 }}
                    >
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    draggable
                    onDragStart={(e) => handleDragStartRow(e, rowIndex)}
                    onDragOver={(e) => handleDragOverRow(e, rowIndex)}
                  >
                    {headers.map((header, index) => (
                      <td
                        key={index}
                        style={{ width: header.width || "auto" }}
                        className={index === 0 ? "cursor-grab" : ""}
                      >
                        {index === 0 ? (
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-4">
                              <img src={dragDark} loading="lazy" alt="Drag" />
                              {row[header.label]}
                            </div>
                            <div className="relative right-4">
                              <img  loading="lazy" src={threeDotsDark} alt="Options" onClick={() => mergeColumns(rowIndex, index)} />
                            </div>
                          </div>
                        ) : (
                          row[header.label]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DraggableTable;
