import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import RateFluctuationCard from "../../components/cards/rateFluctuationCard";
import DynamicTable from "../../components/dynamicTable";
import Calendar from "../../components/calendar";
import {
  useGetDashboardFluctuationCalendarQuery,
  useGetDashboardFluctuationOverviewQuery,
  useGetDashboardFluctuationTrendQuery,
} from "../../redux/slices/rateFluctuation";
import OtaCards from "../../components/otaCards";
import CustomSkeleton from "../../components/Skeleton";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { useGetFiltersQuery } from "../../redux/slices/dashboard";
import BackdropLoader from "../../components/newLoader";
import CustomDumbellChart from "../../components/customDumbellChart";
import icon from "../../assets/icons/3.svg";
import formatCurrency from "../../utils/formatCurrency";
import { format } from "date-fns";
import { BackButton } from "../rateSuggestion/rateAnalysis";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useIsIpad } from "../../utils/isIpad";

const RateFluctuation = () => {
  const [roomId, setRoomId] = React.useState(null);
  const [fluctuationDate, setFluctuationDate] = React.useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState();
  const [transformedData, setTransformedData] = useState();
  const [minimumGraphPrice, setMinimumGraphPrice] = useState();
  const navigate = useNavigate();
  const { data: filterData } = useGetFiltersQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const { data: fluctuationOverview, isLoading: fluctuationOverViewIsLoading } =
    useGetDashboardFluctuationOverviewQuery(
      {
        hId: CurrentProperty(),
        userId: UserId(),
        type: selectedFilter?.value,
      },
      { refetchOnMountOrArgChange: true }
    );
  const {
    data: fluctuationTrend,
    isLoading: fluctuationTrendIsLoading,
    isFetching: fluctuationTrendIsFetching,
  } = useGetDashboardFluctuationTrendQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      roomID: roomId,
      type: selectedFilter?.value,
    },
    {
      skip: roomId ? false : true,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: fluctuationCalendar,
    isLoading: fluctuationCalendarIsLoading,
    isFetching: fluctuationCalendarIsFetching,
  } = useGetDashboardFluctuationCalendarQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: 1,
      roomID: roomId,
      type: selectedFilter?.value,
    },
    {
      skip: roomId ? false : true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [selectedIndex, setSelectedIndex] = React.useState(0);
const isIpad=useIsIpad()
  const generateColumns = (data) => {
    const columns = [
      {
        Header: "Hotel Name",
        accessor: "hotelName",
        width: "100%",
        Cell: ({ row }) => {
          return (
            <div
              className={`${isIpad ?'max-w-[100px] ':'max-w-[150px]'} truncate`}
              data-tooltip-content={row?.original?.hotelName}
              data-tooltip-id={row?.original?.hotelName}
            >
              {row?.original?.hotelName}{" "}
              <Tooltip place="right" id={row?.original?.hotelName} />
            </div>
          );
        },
      },
    ];

    // Extracting unique dates
    const uniqueDates = [];
    data.forEach((hotel) => {
      hotel.fluctuationCalendarRates.forEach((rate) => {
        if (!uniqueDates.includes(rate.timestamp)) {
          uniqueDates.push(rate.timestamp);
        }
      });
    });

    // Adding columns for each date
    uniqueDates.forEach((date) => {
      const formattedDate = format(new Date(date), "dd MMM','yy");
      columns.push({
        Header: formattedDate,
        accessor: (row) => {
          const rate = row.fluctuationCalendarRates.find(
            (rate) => rate.timestamp === date
          );
          return rate ? formatCurrency(rate.price) : "-";
        },
        width: "100%",
      });
    });

    return columns;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className="lg:w-52 md:w-40 w-48">{value}</div>
        ),
      },
      {
        Header: "makMyTrip",
        accessor: "makMyTrip",
        Cell: ({ value, row }) => (
          <div
            className={`hover:bg-[#CCD6E5] hover:text-neutral-800 w-[90px] h-8 text-sm font-medium rounded-lg flex items-center justify-center bg-[#EEF1F6] ${row.original.makMyTrip !== row.original.agoda ? "text-red-500" : ""}`}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "goibibo",
        accessor: "goibibo",
        Cell: ({ value, row }) => (
          <div
            className={`hover:bg-[#CCD6E5] hover:text-neutral-800 text-sm font-medium w-[90px] h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6] ${row.original.goibibo !== row.original.agoda ? "text-red-500" : ""}`}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "expedia",
        accessor: "expidea",
        Cell: ({ value, row }) => (
          <div
            className={`hover:bg-[#CCD6E5] hover:text-neutral-800 text-sm font-medium w-[90px] h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6] ${row.original.expidea !== row.original.agoda ? "text-red-500" : ""}`}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "booking",
        accessor: "booking",
        Cell: ({ value, row }) => (
          <div
            className={`hover:bg-[#CCD6E5] hover:text-neutral-800 text-sm font-medium w-[90px] h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6] ${row.original.booking !== row.original.agoda ? "text-red-500" : ""}`}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "agoda",
        accessor: "agoda",
        Cell: ({ value }) => (
          <div className="hover:bg-[#CCD6E5] hover:text-neutral-800 w-[90px] text-sm font-medium h-8 rounded-lg flex items-center justify-center bg-[#EEF1F6]">
            {value}
          </div>
        ),
      },
    ],
    []
  );

  React.useEffect(() => {
    if (
      fluctuationOverview &&
      fluctuationOverview["data"]?.fluctuationOverview.length
    ) {
      setRoomId(
        fluctuationOverview?.["data"]?.fluctuationOverview?.[0]?.roomID
      );
    }
  }, [fluctuationOverview]);

  useEffect(() => {
    if (fluctuationTrend) {
      let findTransformedData = [];
      let findTransformedDataLength =
        fluctuationTrend.data.fluctuationTrend.length;
      let minimumPrice = 1234567890;
      fluctuationTrend?.data?.fluctuationTrend.map((trend, setTrend) => {
        let formattedDate = format(new Date(trend.timestamp), "dd MMM','yy");
        minimumPrice = minimumPrice > trend.price ? trend.price : minimumPrice;
        findTransformedData.push({
          x: formattedDate,
          y: [
            trend.price,
            fluctuationTrend?.data?.fluctuationTrend?.[
              fluctuationTrend.data.fluctuationTrend.length - 1
            ]?.price,
          ],
        });
      });
      minimumPrice = minimumPrice - minimumPrice / 50;
      setMinimumGraphPrice(minimumPrice);
      setTransformedData(findTransformedData);
    }
  }, [fluctuationTrend]);
  const [click, SetClick] = useState();

  const handleClickhere = (value) => {
    navigate("/settings");
  };
  return (
    <>
      <BackdropLoader
        loading={
          fluctuationTrendIsFetching ||
          fluctuationCalendarIsFetching ||
          fluctuationTrendIsLoading ||
          fluctuationCalendarIsLoading ||
          fluctuationOverViewIsLoading
        }
      />
      <div>
        {!fluctuationOverViewIsLoading ? (
          <div className="flex justify-between items-center w-[100%]">
            <div className="text-[17px] p-4 flex items-center ">
              {" "}
              {/* <BackButton dashboard={true} /> */}
              Overview{" "}
              {fluctuationOverview?.data?.asPer && (
                <span className="text-[#8794AD] text-[12px] ml-1 mt-1">
                  as per{" "}
                  {fluctuationOverview?.data?.asPer &&
                    format(
                      new Date(fluctuationOverview?.data?.asPer),
                      "dd MMM','yy"
                    )}
                </span>
              )}
            </div>
            <div className="p-4 flex gap-4">
              <Dropdown
                selectedItem={
                  selectedFilter?.label || filterData?.data[2]?.types[0]?.label
                }
                setSelectedItems={setSelectedFilter}
                options={filterData?.data[2]?.types}
                right={"2px"}
              />

              {/* <div className="dark:bg-[#252C3B] bg-[#CCD6E5] cursor-pointer flex items-center h-[28] px-2 rounded-md">
                <img src={filterIcon} />
              </div> */}
            </div>
          </div>
        ) : (
          <div className="m-4">
            <CustomSkeleton height={105} width={"100%"} />
          </div>
        )}
        <div className={"flex gap-4 scrollHide mx-4  pb-4 overflow-scroll"}>
          {fluctuationOverview &&
            fluctuationOverview?.data?.fluctuationOverview.map(
              (room, index) => {
                return (
                  <OtaCards
                    index={index}
                    onClick={() => {
                      setSelectedIndex(index);
                      setRoomId(room?.roomID);
                    }}
                    isCurrency={true}
                    price={room.price}
                    title={room.roomName}
                    selectedIndex={selectedIndex}
                    // data={data}
                    percentageDifference={room?.percentageDifference}
                    range={false}
                  />
                );
              }
            )}
        </div>
      </div>

      <div className="flex justify-between px-4 gap-4 md:flex-row flex-col">
        {!fluctuationTrendIsLoading &&
        !fluctuationTrendIsFetching &&
        !fluctuationCalendarIsLoading &&
        !fluctuationCalendarIsFetching ? (
          <div className="w-full  md:w-[70%]">
            <CustomDumbellChart
              data={transformedData}
              height={276}
              toolbar={false}
              minimum={minimumGraphPrice}
              isCurrency={true}
              asPer={fluctuationTrend?.data?.asPer}
            />
          </div>
        ) : (
          <div className="w-full md:w-[70%]">
            <CustomSkeleton height={365} width={"100%"} />
          </div>
        )}
        {!fluctuationTrendIsLoading &&
        !fluctuationTrendIsFetching &&
        !fluctuationCalendarIsLoading &&
        !fluctuationCalendarIsFetching ? (
          <div className="w-full md:w-[30%]">
            <RateFluctuationCard
              title={"Fluctuation Leaderboard"}
              showDropDown={false}
              isRating={true}
              isFluctuation={true}
              img={icon}
              data={fluctuationTrend?.data?.fluctuationLeaderBoard}
              asPer={fluctuationTrend?.data?.asPer}
              tooltipText={
                "This feature presents a comparative analysis of rate fluctuations among competitors within the market. "
              }
            />
          </div>
        ) : (
          <div className="w-full md:w-[30%]">
            <CustomSkeleton height={365} width={"100%"} />
          </div>
        )}
      </div>
      {!fluctuationTrendIsLoading &&
      !fluctuationTrendIsFetching &&
      !fluctuationCalendarIsLoading &&
      !fluctuationCalendarIsFetching ? (
        <div className="m-4 p-4 rounded-xl bg-white dark:bg-mainDark">
          <div className="flex justify-between md:flex-row flex-col w-[100%]">
            <div className="text-[17px] p-4 font-[600]">Overview </div>
            <div className="p-4 flex justify-end flex-wrap md:flex-nowrap gap-4 w-full">
              {/* <Dropdown selectedItem={"Select OTA"} />
              <Dropdown selectedItem={"Select Room Type"} /> */}
              <div className="w-fit">
              <Calendar
                selectedDate={fluctuationDate}
                setSelectedDate={setFluctuationDate}
              />
              </div>
              
              <div className="flex items-center">
                <InfoToolTip
                  content={
                    "This feature provides a summary of the fluctuations in room rates over a specified period. By examining rate fluctuations, hoteliers can identify patterns, trends, and changes in pricing dynamics."
                  }
                />
              </div>
            </div>
          </div>
          {fluctuationCalendar ? (
            <DynamicTable
              data={fluctuationCalendar?.data?.fluctuation}
              columns={generateColumns(fluctuationCalendar?.data?.fluctuation)}
            />
          ) : (
            <>
              <p>Complete Room Mapping First</p>
              <button
                className=" border "
                onClick={() => {
                  handleClickhere("Room Mapping");
                }}
              >
                Map Now
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="w-[100%]">
          <div className="my-3 mx-4">
            <CustomSkeleton height={465} />
          </div>
        </div>
      )}
    </>
  );
};

export default RateFluctuation;
