import React, { useEffect, useState } from "react";

import wrongIcon from "../../assets/img/Frame 1321314740.svg";
import rightIcon from "../../assets/img/Frame 1321314741.svg";
import wrongIcon1 from "../../assets/img/Frame 1321314740 (1).svg";
import rightIcon1 from "../../assets/img/Frame 1321314741 (1).svg";
import wrongIcon2 from "../../assets/img/Frame 1321314740 (2).svg";
import rightIcon2 from "../../assets/img/Frame 1321314741 (2).svg";
import { useAddNewCompsetMutation, useGethotelIdMutation } from '../../../src/redux/slices/onBoarding';
import { toast } from "react-toastify";

const PropertyCard = ({ onCheck, onCheck2, name, address, img, link, setFinalData ,finalData ,setRemovedOptions ,id}) => {
  const [istrue, setIsTrue] = useState(false);
  const [viewOptions, setViewOptions] = useState();
  const [viewTextField,setViewTextField]=useState(false)
  const [url, setUrl]=useState();
  const [gethotelId] = useGethotelIdMutation()
  const toggleDarkMode = () => {
    setIsTrue((prevMode) => !prevMode);
  };
  useEffect(()=>{
    if(link){

    }else{ setViewOptions(true);}
  },[])
  const handleRemoveOTA = () => {
    const filteredData = finalData.filter((item) => item.otaName !== name);

    setRemovedOptions(prev => [...prev, name]);

    // Update state with filtered data
    setFinalData(filteredData);
  };

const addOtaLink=()=>{
    
 const otaArray = {
  otaDetails : [{  otaId: id, url: url }]
 }

  gethotelId(otaArray).unwrap()
  .then((res) => {
    toast.success("OTA Link Added Successfully", { position: "bottom-right" });
    setViewTextField(false);
    setViewOptions(false);
    const updatedFinalData = finalData.map(item => {
      if (item.otaId === id) {
          return {
              ...item,
              link: url // Replace 'https://www.updated-url.com' with your desired URL
          };
      }
      return item;
  });

  // Update the state with the updated data
  setFinalData(updatedFinalData);

  })
  .catch((err) => {
      toast.error(err?.data?.message, { position: "bottom-right" });

  });
};
  return (
    <div className=" w-[392px] h-14 bg-white shadow-e-03 flex flex-col mb-1 rounded-lg ">
      <div className="flex justify-between h-full ">
        <div className="flex flex-row gap-2 items-center justify-center px-2 py-1">
          <div className="h-10 w-14 rounded-lg  flex items-center justify-center">
            <img loading="lazy" src={img} className="h-full object-cover" />
          </div>
          <div>
            {" "}
            <p className=" font-semibold text-sm text-neutral-dark-22 pb-1">
              {name}
            </p>
          </div>
        </div>
        <div className="flex flex-row cursor-pointer gap-2 mr-1 items-center">
          <div className="flex flex-row text-xs items-center gap-2">
            <p className="text-neutral-dark-22">{address}</p>
            {link && !istrue && !viewOptions && (
              <div className="text-black px-3 mr-[20px] w-full py-1 font-[400] bg-[#E6ECF5] rounded-md text-sm hidden lg:flex">
                {
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    Visit OTA
                  </a>
                }
              </div>
            )}
  {viewOptions && (
  <div className="flex gap-2">
    {viewTextField ? (
      <div className="flex  w-full justify-end items-center">
        <div className="text-black flex  h-full w-full rounded-md text-xs">
          <input type="text" value={url} onChange={(e)=>setUrl(e.target.value)} className="w-full h-full border-2  " />
          <img className="mx-2" loading="lazy"  onClick={addOtaLink} src={rightIcon2} />
          <img loading="lazy" onClick={()=>{setViewTextField(false)}} src={wrongIcon} />
        </div>
      </div>
    ) : (
  <div className="flex gap-2">

      <div onClick={() => setViewTextField(true)} className="text-black  text-center p-2 bg-[#E6ECF5] rounded-md text-xs">
        Add OTA
      </div>
        <div onClick={handleRemoveOTA} className="text-black  text-center p-2 bg-[#E6ECF5] rounded-md text-xs">
        Remove OTA
      </div>
  </div>

    )}

  </div>
)}
  </div>


          {istrue ? (
            <p className="px-3 mr-[10px] w-full lg:w-fit py-1 font-[400] text-green-500 rounded-md text-sm">
              Hotel Verified
            </p>
          ) : istrue === null ? (
            <div
              onClick={() => {
                setIsTrue(true);
                onCheck();
              }}
            >
             {link&& <img src={rightIcon2} />}
            </div>
          ) : (
            !viewOptions && (
              <div onClick={() =>{ setIsTrue(true);
                onCheck();
                }}>
                 {link&& <img src={rightIcon2} />}
              </div>
            )
          )}
          {istrue ? (
            <div
              onClick={() => {
                setIsTrue(false);
                onCheck2();
              }}
            >
              <img src={wrongIcon} />{" "}
            </div>
          ) : istrue === null ? (
            <div
              onClick={() => {
                setIsTrue(false);
                onCheck2();
              }}
            >
            <img src={wrongIcon1} />
            </div>
          ) : (
            <div
              onClick={() => {
                setIsTrue(false);
                setViewOptions(!viewOptions);
              }}
            >
             { !viewTextField &&<img src={wrongIcon1} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
