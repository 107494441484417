import React, { useEffect, useRef, useState } from "react";
import "./Calendar.css";
import calIcon from "../../assets/img/cal.svg";
import { format, startOfMonth, getDay, subMonths } from "date-fns";
import { toast } from "react-toastify";
import leftArrow from "../../assets/icons/leftarrow.svg";

const Calendar = ({
  setSelectedDate,
  selectedDate,
  backgroundNone = false,
  width,
  right,
  enableDaysFromNow = 365,
  showIconBackground = false,
  height,
  disablePastDays = false,
  maxSelectableYear,
  enableOneYearFromNow = false,
  onlyPastDaysSelectable = false,
  minStart,
  showAll = false,
  UpdateOneDate = false,
  customAlert,
}) => {
  const ref = useRef();
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(selectedDate).getMonth()
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date(selectedDate).getFullYear()
  );
  const [viewMode, setViewMode] = useState("date");
  const [showCal, setShowCal] = useState(false);

  useEffect(() => {
    const today = new Date();
    if (enableOneYearFromNow) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(today.getFullYear() + 1);
      if (selectedDate > oneYearFromNow) {
        setSelectedDate(oneYearFromNow);
      }
    }
  }, [enableOneYearFromNow, selectedDate, setSelectedDate]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return getDay(startOfMonth(new Date(year, month)));
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const firstDayOfMonth = getFirstDayOfMonth(selectedYear, selectedMonth);

    const calendar = [];
    const today = minStart
      ? new Date().setDate(new Date(minStart).getDate() - 1)
      : new Date().setDate(new Date().getDate() - 1);
    const enableDate = new Date();
    enableDate.setDate(enableDate.getDate() + enableDaysFromNow);

    // Add placeholders for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(
        <div key={`empty-${i}`} className="calendar-day empty"></div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i);
      const isPastDate = currentDate < new Date();
      const isFutureDate = currentDate <= enableDate && currentDate >= today;
      const isSelected = i === new Date(selectedDate).getDate();
      const isDisabledYear = currentDate.getFullYear() > maxSelectableYear;
      const isSelectable = onlyPastDaysSelectable ? isPastDate : isFutureDate;

      let className = "calendar-day text-[12px]";
      if (isSelected) {
        className += " selected text-black";
      } else if (isPastDate && disablePastDays) {
        className += " past-day text-[#A8B1C4]";
      } else if (isDisabledYear) {
        className += " disabled text-[#A8B1C4]";
      } else if (isSelectable) {
        className += " selectable text-black";
      } else {
        className += " future-day text-[#A8B1C4]";
      }

      calendar.push(
        <div
          className={className}
          key={i}
          onClick={() => {
            if (!isSelectable && !showAll) {
              toast.error(
                `${
                  onlyPastDaysSelectable && !customAlert
                    ? "Only past dates are selectable."
                    : !customAlert
                      ? "Only future dates are selectable."
                      : customAlert
                } `,
                {
                  position: "bottom-right",
                }
              );
            } else {
              setSelectedDate(new Date(selectedYear, selectedMonth, i));
              setShowCal(false);
            }
          }}
        >
          {i}
        </div>
      );
    }

    return calendar;
  };

  const renderMonths = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);
    return months.map((month) => (
      <div
        key={month}
        className={`month cursor-pointer ${
          selectedMonth === month ? "selected" : ""
        }`}
        onClick={() => handleMonthClick(month)}
      >
        {new Date(2000, month, 1).toLocaleString("default", { month: "short" })}
      </div>
    ));
  };

  const renderYears = () => {
    const years = Array.from({ length: 12 }, (_, i) => selectedYear - 5 + i);
    return years.map((year) => (
      <div
        key={year}
        className={`year cursor-pointer ${
          selectedYear === year ? "selected" : ""
        }`}
        onClick={() => handleYearClick(year)}
      >
        {year}
      </div>
    ));
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    setViewMode("date");
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setViewMode("date");
  };

  const handlePrevMonth = () => {
    const prevMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const prevYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(prevMonth);
    setSelectedYear(prevYear);
  };

  const handleNextMonth = () => {
    const nextMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(nextMonth);
    setSelectedYear(nextYear);
  };

  //   const handlePrevious = () => {
  //     const prevDate = new Date(onlyPastDaysSelectable && selectedDate);
  //     prevDate.setMonth(prevDate.getMonth() - 1);

  //     // Ensure the new date is in the past if onlyPastDaysSelectable is true
  //     const currentDate = new Date();
  //     const disablePast = disablePastDays && prevDate >= currentDate;
  //     const onlyPast = onlyPastDaysSelectable && prevDate < currentDate;

  //     if ((!disablePastDays || disablePast) && !onlyPastDaysSelectable) {
  //       setSelectedDate(prevDate);
  //     } else if (onlyPastDaysSelectable && prevDate < currentDate) {
  //       setSelectedDate(prevDate);
  //     }
  // };

  // working previous and next

  // const handlePrevious = () => {
  //   const prevDate = new Date(selectedDate);
  //   prevDate.setMonth(prevDate.getMonth() - 1);

  //   // Get today's date
  //   const currentDate = new Date();
  //   currentDate.setHours(0, 0, 0, 0); // Reset the time portion

  //   // Check if the new date is in the past or not
  //   const isDateInPast = prevDate < currentDate;

  //   if (!disablePastDays && !onlyPastDaysSelectable) {
  //       setSelectedDate(prevDate);
  //   } else if (disablePastDays && !isDateInPast) {
  //       setSelectedDate(prevDate);
  //   } else if (onlyPastDaysSelectable && isDateInPast) {
  //       setSelectedDate(prevDate);
  //   }
  // };

  // const handleNext = () => {
  //     const nextDate = new Date(selectedDate);
  //     nextDate.setMonth(nextDate.getMonth() + 1);
  //     // Ensure the new date respects the onlyPastDaysSelectable constraint
  //     const currentDate = new Date();
  //     const pastDateCheck = onlyPastDaysSelectable && nextDate < currentDate;

  //     if (!onlyPastDaysSelectable || pastDateCheck) {
  //         setSelectedDate(nextDate);
  //     }
  // };

  // const handlePrevious = () => {
  //   const prevDate = new Date(selectedDate);
  //   prevDate.setMonth(prevDate.getMonth() - 1);

  //   const currentDate = new Date();
  //   const prevMonth = prevDate.getMonth();
  //   const prevYear = prevDate.getFullYear();

  //   if ((!disablePastDays || prevDate >= currentDate) && !onlyPastDaysSelectable) {
  //       setSelectedDate(prevDate);
  //       setSelectedMonth(prevMonth);
  //       setSelectedYear(prevYear);
  //   } else if (onlyPastDaysSelectable && prevDate < currentDate) {
  //       setSelectedDate(prevDate);
  //       setSelectedMonth(prevMonth);
  //       setSelectedYear(prevYear);
  //   }else if(new Date(format(new Date(selectedDate),'yyyy-MM-dd')) >= currentDate){
  //     setSelectedDate(prevDate);
  //       setSelectedMonth(prevMonth);
  //       setSelectedYear(prevYear);
  //   }
  // };

  const handlePrevious = () => {
    if (disablePastDays) {
      toast.error("Past days are disabled!", { position: "bottom-right" });
    }
    const prevDate = new Date(selectedDate);
    prevDate.setMonth(prevDate.getMonth() - 1);

    const currentDate = new Date();
    const prevMonth = prevDate.getMonth();
    const prevYear = prevDate.getFullYear();

    const updateDateObject = new Date(selectedDate);
    updateDateObject.setDate(updateDateObject.getDate() - 1);
    const updatedDateMonth = updateDateObject.getMonth();
    const updatedDateYear = updateDateObject.getFullYear();
    if (UpdateOneDate) {
      setSelectedDate(updateDateObject);
      setSelectedMonth(updatedDateMonth);
      setSelectedYear(updatedDateYear);
    } else if (
      (disablePastDays || prevDate >= currentDate) &&
      onlyPastDaysSelectable
    ) {
      setSelectedDate(prevDate);
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    } else if (onlyPastDaysSelectable && prevDate < currentDate) {
      setSelectedDate(prevDate);
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    } else if (
      new Date(format(new Date(selectedDate), "yyyy-MM-dd")) >= currentDate
    ) {
      setSelectedDate(prevDate);
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    } else if (onlyPastDaysSelectable && prevDate < currentDate) {
      setSelectedDate(prevDate);
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    } else if (
      new Date(format(new Date(selectedDate), "yyyy-MM-dd")) >= currentDate
    ) {
      setSelectedDate(prevDate);
      setSelectedMonth(prevMonth);
      setSelectedYear(prevYear);
    }
  };

  const handleNext = () => {
    if (onlyPastDaysSelectable) {
      toast.error("Only past dates are selectable!", {
        position: "bottom-right",
      });
    }
    const nextDate = new Date(selectedDate);
    nextDate.setMonth(nextDate.getMonth() + 1);

    const currentDate = new Date();
    const nextMonth = nextDate.getMonth();
    const nextYear = nextDate.getFullYear();

    const updateDateObject = new Date(selectedDate);
    updateDateObject.setDate(updateDateObject.getDate() + 1);
    const updatedDateMonth = updateDateObject.getMonth();
    const updatedDateYear = updateDateObject.getFullYear();
    if (UpdateOneDate) {
      setSelectedDate(updateDateObject);
      setSelectedMonth(updatedDateMonth);
      setSelectedYear(updatedDateYear);
    } else if (!onlyPastDaysSelectable || nextDate < currentDate) {
      setSelectedDate(nextDate);
      setSelectedMonth(nextMonth);
      setSelectedYear(nextYear);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setShowCal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={`relative ${width ? `w-[${width}px]` : " w-[100%]"}`}
      ref={ref}
    >
      <div
        className={`flex cursor-pointer justify-between gap-2 items-center ${backgroundNone ? "" : "bg-white/30 dark:bg-subMainDark"}  dark:text-mainDark text-mainLight ${height ? `h-[${height}px]` : "h-[28px]"}  ${width ? `w-[${width}px]` : "w-fit"} px-2 text-[0.8em] whitespace-nowrap rounded-lg`}
      >
        {showIconBackground && (
          <div
            className={`h-[41px] w-[48px] rounded-lg flex items-center justify-center bg-white/30 dark:bg-subMainDark cursor-pointer`}
            onClick={handlePrevious}
          >
            <img
              src={leftArrow}
              onClick={handlePrevious}
              className="rotate-180 h-[14px] "
              loading="lazy"
            />
          </div>
        )}
        {!showIconBackground && (
          <img
            src={leftArrow}
            onClick={handlePrevious}
            className="rotate-180 h-[14px] "
            loading="lazy"
          />
        )}
        <div
          className="flex text-white  dark:bg-subMainDark dark:rounded-md px-2 dark:h-[14px] dark:text-white items-center gap-2"
          onClick={() => setShowCal(!showCal)}
        >
      <img
  src={calIcon}
  alt=""
  srcSet=""
  className="hidden md:block xl:block lg:block dark:filter dark:invert dark:brightness-0 dark:contrast-100 "
  onClick={() => setShowCal(!showCal)}
  loading="lazy"
/>

          {selectedDate && format(new Date(selectedDate), "dd MMM','yy")}
        </div>
        {showIconBackground && (
          <div
            className="h-[41px] w-[48px]  dark:bg-subMainDark rounded-lg flex cursor-pointer items-center justify-center bg-white/30"
            onClick={handleNext}
          >
            {" "}
            <img src={leftArrow} className="md:ml-2 h-[14px]" loading="lazy" />
          </div>
        )}
        {!showIconBackground && (
          <img
            src={leftArrow}
            onClick={handleNext}
            className="ml-2 h-[14px]"
            loading="lazy"
          />
        )}
      </div>
      {showCal && (
        <div
          className={`calendar-container  ${right ? right : "right-0"}`}
          style={{
            boxShadow: "0px 1px 2px 0px #00000069",
            background: "#f9f9f9",
          }}
        >
          <div className="header">
            <div className="arrow" onClick={handlePrevMonth}>
              {"<"}
            </div>
            <div className="flex">
              <div
                className="month-year cursor-pointer mr-2"
                onClick={() => setViewMode("month")}
              >
                {viewMode === "date"
                  ? new Date(selectedYear, selectedMonth, 1).toLocaleString(
                      "default",
                      { month: "short" }
                    )
                  : "Month"}
              </div>
              <div
                className="month-year cursor-pointer"
                onClick={() => setViewMode("year")}
              >
                {viewMode === "date" ? selectedYear : "Year"}
              </div>
            </div>
            <div className="arrow" onClick={handleNextMonth}>
              {">"}
            </div>
          </div>
          <div className="calendar">
            {viewMode === "date" && (
              <div className="weekdays text-[#59647B] text-[12px]">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
            )}
            <div className="days">{viewMode === "date" && renderDays()}</div>
            <div className="monthyearview">
              {viewMode === "month" && renderMonths()}
              {viewMode === "year" && renderYears()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
