import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LogIn from "../pages/onboarding/LogIn";
import SignUp from "../pages/onboarding/SignUp";
import ForgotPassword from "../pages/onboarding/ForgotPassword";
import ConfirmationDone from "../pages/onboarding/confirmation/ConfirmationDone";
import SinglePropertyStep2 from "../pages/onboarding/singleProperty/SinglePropertyStep2";
import VerifyNumber from "../pages/onboarding/VerifyNumber";
import UniversalProgressBar from "../components/progessBar";
import ChannelManagerSetUp from "../pages/onboarding/signupStep3/ChannelMangerSetUp";
import PropertyDetails from "../pages/onboarding/propertyDetails";
import AddOtaLink from "../pages/onboarding/signupStep3/AddOtaLink";
import ConfirmProperty from "../pages/onboarding/ConfirmProperty";
import SelectProperty from "../pages/onboarding/SelectProperty";
import CompetitionSetup from "../pages/onboarding/signupStep4";
import SelectCompetition from "../pages/onboarding/signupStep4/SelectCompetetion";
import ConfirmCompetition from "../pages/onboarding/signupStep4/ConfirmCompetition";
import CreateNewPassword from "../pages/onboarding/CreateNewPassword";
import AddCompetitionOtaLink from "../pages/onboarding/signupStep4/AddCompetitionOtaLink";
import CircularProgressBar from "../components/rankingProgress/CircularRatingProgressBar";
import RateX_Dashboard from "../pages/dashboard";
// import RateSuggestion from "../components/rateSuggestionBox/RateSuggestion";
// import RateSuggestion from "../components/rateSuggestions"
import RateLeaderbiard from "../pages/rateLeaderboard";
import MapChart from "../components/geographyMap";
import RateSuggestion from "../pages/rateSuggestion";
import RateMonitor from "../pages/rateMonitor";
import ReportBuilder from "../pages/reportBuilder/index";
import Reservations from "../pages/reservations";
import ReservationDetails from "../pages/reservationDetails";
import RateFluctuation from "../pages/rateFluctuation";
import Settings from "../pages/settings";
import ChannelConfiguration from "../pages/settings/channelManager/channels/ChannelConfiguration";
import VisibilityRank from "../pages/visibilityRank";
import Reputation from "../pages/reputation";
import RoomType from "../pages/settings/channelManager/roomtype/RoomTypes";
import ChannelManager from "../pages/settings/channelManager";
import MealPlan from "../pages/settings/channelManager/mealplan/MealPlan";
import RatePlan from "../pages/settings/channelManager/rateplan/RatePlan";
import RatesAndInventory from "../pages/ratesAndInventory";
import AccountAndSecurity from "../pages/settings/account&Security";
import UserInterface from "../pages/settings/userInterface";
import DemandOutlook from "../pages/demandOutlook";
import Analysis from "../pages/analysis";
import NotificationComponent from "../components/notificationCard/notification/NotificationComponent";
import TopBar from "../layouts/header";
import NewDashboard from "../pages/newDashboard";
import IntelligenceNewDashboard from "../pages/IntelligenceNewDashboard";
import ReportBuilderTable from "../pages/reportBuilder/table";
import NewRatePulseDashboard from "../pages/newRatePulseDashboard";
import CompsetOverview from "../pages/compsetOverview";
import TwoFactorVerification from "../pages/onboarding/TwoFactorVerification";
import NewTable from "../pages/newTable/NewTable";
import { useGetQuickReportQuery } from "../redux/slices/dashboard";
import { CurrentProperty, UserId } from "../utils/impVars";
import ReportId from "../pages/dashboard/ReportId";
// import Test from "../pages/testing/Test";
import TemplateReport from "../pages/dashboard/TemplateReport";
import RecentReviews from "../pages/reputation/reviews/RecentReviews";
import ReviewsLandingPage from "../pages/reputation/reviews/reviewsLandingPage";
import MultichainDashboard from "../pages/multichainProperty/dashboard";
import NewReportBuilder from "../pages/reportBuilder/newReportBuilder";
import SwitchScreen from "../pages/switchScreen";
import HotelOverview from "../pages/hotelOverview";
import HotelPerformanceThirdPage from "../pages/hotelOverview/hotelPerformanceThirdPage";
const RateX_Routes = () => {
  // const { data: quickReportData } = useGetQuickReportQuery({
  //   userId: UserId(),
  //   hId: CurrentProperty(),
  // });
  // const [reportData, setReportData] = useState(quickReportData?.data?quickReportData?.data:[]);

  const location = useLocation();
  const [token, setToken] = useState(null);
  const tokenn = location?.state?.token;
  const localToken = window.localStorage.getItem("token") || tokenn;
  useEffect(() => {
    if (localToken) {
      setToken(localToken);
      window.localStorage.setItem("token", localToken);
    } else if (tokenn) {
      setToken(tokenn);
    }
  });

  return (
    <>
      {token ? (
        <TopBar>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<NewDashboard />} />
            <Route path="/ratex/:id/:id2" element={<SwitchScreen />} />

            <Route
              path="/chainProperty/dashboard"
              element={<MultichainDashboard />}
            />
            <Route
              path="/rate-pulse/dashboard"
              element={<NewRatePulseDashboard />}
            />
            <Route path="/dashboard" element={<NewDashboard />} />
            <Route path="/newDashboard" element={<NewDashboard />} />
            <Route
              path="/hotel_intelligence"
              element={<IntelligenceNewDashboard />}
            />
            <Route path="/compset_overview" element={<CompsetOverview />} />
            <Route
              path="/rate_suggestions"
              element={<RateSuggestion />}
            ></Route>
            <Route
              path="/Report/Custom"
              element={<ReportBuilderTable />}
            ></Route>
            <Route
              path="/Report/Custom/ReportBuilder"
              element={<NewReportBuilder />}
            ></Route>
            <Route
              path="/Report/TemplateReport"
              element={<TemplateReport />}
            ></Route>
            <Route
              path="/Report/TemplateReport/:reportId"
              element={<ReportId />}
            />
            <Route path="/report_builder" element={<ReportBuilder />}></Route>
            <Route
              path="/report_builder_table"
              element={<ReportBuilderTable />}
            ></Route>
            <Route
              path="/newReportBuilder"
              element={<NewReportBuilder />}
            ></Route>
            <Route path="/new_table" element={<NewTable />}></Route>
            <Route
              path="/rate-pulse/rate_fluctuation"
              element={<RateFluctuation />}
            ></Route>
            <Route path="/parity" element={<MapChart />}></Route>
            <Route path="/add_rating" element={<CircularProgressBar />}></Route>
            <Route path="/leaderboard" element={<RateLeaderbiard />}></Route>
            <Route
              path="rate-pulse/rate_monitor"
              element={<RateMonitor />}
            ></Route>
            <Route
              path="rate-pulse/demand_outlook"
              element={<DemandOutlook />}
            ></Route>
            <Route path="/reservations" element={<Reservations />}></Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route
              path="/channelConfiguration"
              element={<ChannelConfiguration />}
            ></Route>
            <Route
              path="settings/channel_manager"
              element={<ChannelManager />}
            ></Route>
            <Route path="/room_types" element={<RoomType />}></Route>
            <Route path="/meal_plan" element={<MealPlan />}></Route>
            <Route path="/rate_plan" element={<RatePlan />}></Route>
            <Route
              path="/rates_&_inventory"
              element={<RatesAndInventory />}
            ></Route>
            <Route
              path="/account_&_security"
              element={<AccountAndSecurity />}
            ></Route>
            <Route path="/user_interface" element={<UserInterface />}></Route>
            <Route
              path="/reservation/reservation_Details"
              element={<ReservationDetails />}
            ></Route>
            <Route
              path="/rate-pulse/visibility"
              element={<VisibilityRank />}
            ></Route>
            <Route
              path="/rate-pulse/reputation"
              element={<Reputation />}
            ></Route>
            <Route
              path="/rate-pulse/reputation/reviews"
              element={<ReviewsLandingPage />}
            ></Route>
            <Route
              path="/rate-pulse/reputation/review"
              element={<RecentReviews />}
            ></Route>
              <Route
              path="/hotel_performance"
              element={<HotelOverview />}
            ></Route>

            {/* <Route path="/analysis" element={<Analysis/>}></Route> */}
            {/* <Route path="/:id/:id" element={<Analysis />}></Route> */}
            <Route path="/analysis" element={<Analysis />}></Route>
            <Route
              path="/notification"
              element={<NotificationComponent />}
            ></Route>
          <Route path="/hotelPerformance/details/:id" element={<HotelPerformanceThirdPage />} />
          </Routes>
        </TopBar>
      ) : (
        <Routes key={location.pathname} location={location}>
            <Route path="/ratex/:id/:id2" element={<SwitchScreen />} />
          <Route path="/" element={<LogIn />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirmation_done" element={<ConfirmationDone />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/verify_number" element={<VerifyNumber />} />
          <Route path="/verify_login" element={<TwoFactorVerification />} />
          <Route path="/property_details" element={<PropertyDetails />} />
          <Route path="/single_property" element={<SinglePropertyStep2 />} />
          <Route path="/chain_property" element={<SinglePropertyStep2 />} />
          <Route path="/progress" element={<UniversalProgressBar />} />
          <Route path="/channel_manager" element={<ChannelManagerSetUp />} />
          <Route path="/add_ota" element={<AddOtaLink />} />
          <Route path="/confirm_property" element={<ConfirmProperty />} />
          <Route path="/select_property" element={<SelectProperty />} />
          <Route path="/competition" element={<CompetitionSetup />}></Route>

          <Route
            path="/select_competition"
            element={<SelectCompetition />}
          ></Route>
          <Route
            path="/confirm_competition"
            element={<ConfirmCompetition />}
          ></Route>
          <Route
            path="/create_new_password/:otp"
            element={<CreateNewPassword />}
          ></Route>
          <Route
            path="/add_competition_ota"
            element={<AddCompetitionOtaLink />}
          ></Route>
          <Route path="*" element={<LogIn />}></Route>
        </Routes>
      )}
    </>
  );
};

export default RateX_Routes;
