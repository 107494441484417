import { emptySplitApi } from "../../injectEndpoints";

export const RoomMapping = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyRoomDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertyRoomDetails`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertyRoomDetails"],
    }),
    getPropertyMappingDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertyMappingDetails`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertyMappingDetails"],
    }),
    getOtaRoomMappingDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getOTARoomMappingDetails`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getOtaRoomMappingDetails"],
    }),
    getMappedPropertyRooms: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getMappedPropertyRooms`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getMappedPropertyRooms"],
    }),
    getPropertyRoomWRTOta: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertyRoomWRTOta`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertyRoomWRTOta"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertyRoomDetailsQuery,
  useGetPropertyMappingDetailsQuery,
  useGetOtaRoomMappingDetailsQuery,
  useGetMappedPropertyRoomsQuery,
  useGetPropertyRoomWRTOtaQuery,
} = RoomMapping;
