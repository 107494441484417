import React, { useEffect, useRef, useState } from "react";
import PaceTable from "../../newTable/PaceTable";
import Calendar from "../../../components/calendar";
import { DownloadPaceReport } from "../DownloadExcel/PaceReport";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import whatsappBusiness from '../../../assets/img/whatsapp-business-icon.svg'

const PaceReport = ({
  data,
  dateRange,
  setDateRange,
  setIsWhatsApp,
  isLoading,
  sendToWhatsappAdmin,
  paceWhatsAppAdmin
}) => {
  const dateLimit=useIntelligenceDateRange();

  const [expandedIndex, setExpandedIndex] = useState(0);
  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center text-whtie p-2 font-semibold">
          <p>Pace Report </p>
        </div>
       
        <div className="h-full flex items-center justify-center w-[100%]">
          <CustomSkeleton isTransparent={true} height={500} animated={true} title={'Pace Report'} />

          {/* <NoDataFound /> */}
        </div>
      </div>
    );
  }
  if (isLoading && !paceWhatsAppAdmin) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center text-white justify-center p-2 font-semibold">
          <p>Pace Report </p>
        </div>
     
        <div className="w-[100%]">
          <CustomSkeleton height={500} title={'Pace Report'} animated={true} />
          {/* No data found */}
        </div>
      </div>
    );
  }
  const {
    weeklyArrivals = [],
    weeklyReservation = [],
    weeklyRoomNights = [],
  } = data;
  const fromDate = format( new Date(dateLimit?.fromDate), 'MM/dd/yyyy');
  const endDate = format( new Date(dateLimit?.toDate), 'MM/dd/yyyy');
  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <>
      <div className="flex items-center sticky  top-0  justify-center p-2 font-semibold">
        <p className=" text-white dark:text-black">
          Pace Report - {propertyName}{" "}
          {`(  ${format(new Date(dateRange[0].startDate), "dd MMM yyy") || formatDate(data.asOn?.startDate)} - ${format(new Date(dateRange[0].endDate), "dd MMM yyy") || formatDate(data.asOn?.endDate)})`}
        </p>
      </div>
      <div className="w-full flex justify-end  sticky top-[40px]">
        <div className="m-3 w-auto flex justify-end ">
          <div className="w-fit">
            <div className=" flex items-center  text-white dark:text-black  gap-2 justify-center pr-2">
              <div className="w-fit text-sm">Select date range</div>
              {
                <div
                  className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 rounded-md border-solid border border-[#A0A4B0] z-20"
                  ref={ref1}
                >
                  <div
                    className="flex gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={14} />{" "}
                    {format(new Date(dateRange[0].startDate), "dd MMM yyy") ||
                      " "}{" "}
                    -
                    {format(new Date(dateRange[0].endDate), "dd MMM yyy") ||
                      " "}
                  </div>
                  {showDateRange && (
                    <div className="absolute top-[50px] -left-[200px]">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange([
                            {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },
                          ]);
                        }}
                        minDate={new Date(fromDate)}
                        maxDate={new Date(endDate)}
                      />
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
          <h1
            onClick={() => {
              DownloadPaceReport(data);
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>{" "}
          <div
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
    
        </div>
      </div>
     
        <div className="flex flex-col gap-2 overflow-y-auto h-[80%] ">
          <PaceTable
            arrival={true}
            data={weeklyArrivals}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <PaceTable
            reservation={true}
            data={weeklyReservation}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />
          <PaceTable
            night={true}
            data={weeklyRoomNights}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
          />
        </div>
      
    </>
  );
};

export default PaceReport;
