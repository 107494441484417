import React from "react";
import Chart from "react-apexcharts";
import "../charts/style.css";
import formatCurrency from "../../utils/formatCurrency";

const DumbellChart = ({ data, minimum, toolbar, height,isCurrency, }) => {
  function formatDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const parts = dateString.split("-");
    const year = parts[0];
    const monthIndex = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    }

    const monthName = months[monthIndex];

    return `${day}${daySuffix} ${monthName}, ${year}`;
  }

  const sampleData = [
    {
      data: [
        {
          x: "2024-04-22",
          y: [2800, 4500],
        }
      ],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        isDumbbell: true,
        columnWidth: 3,
        dumbbellColors: [["#008FFB", "#00E396"]],
      },
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      customLegendItems: ["Product A", "Product B"],
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        gradientToColors: ["#00E396"],
        inverseColors: true,
        stops: [0, 100],
      },
    },
    yaxis: {
      min: minimum ? minimum : 2000,
      labels: {
        formatter: function (value) {
          return isCurrency ? formatCurrency(value) : value
        }
      },
       // Adjust this value according to your dataset
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      tickPlacement: "on",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        return '<div class="custom-tooltip">' +
          '<div class="custom-text-tooltip-third">' +
          `Rate Difference: ` +
          (formatCurrency((data[dataPointIndex]['y'][0] - data[dataPointIndex]['y'][1]))) +
          "</div>" +
          '<div class="custom-text-tooltip-second">' +
          `For ${w.globals.categoryLabels[data.length - 1]}` +
          "</div>" +
          '<div class="custom-text-tooltip-first">' +
          `On ${w.globals.categoryLabels[dataPointIndex]}` +
          "</div>" +
          "</div>"
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (value) {
          return value; // You can customize the tooltip value formatting as needed
        },
      },
      marker: {
        show: false,
      },
      strokeDashArray: [], // Set to an empty array for a solid line
    },
  };

 
  return (
    <Chart
      options={options}
      series={data && data.length > 0 ? [{ data: data}] : sampleData}
      type="rangeBar"
      height={height}
    />
  );
};

export default DumbellChart;
