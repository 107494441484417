import * as ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";
async function transformData(data, currencySymbol) {
  const OTAs = [
    "Booking.com",
    "Goibibo",
    "Agoda",
    "MakeMyTrip",
    "Trip.com",
    "EaseMyTrip",
    "HappyEasyGo",
    "ClearTrip",
    "HRS",
  ];
  const defaultRate = "Sold out";
  function getUniqueOtaNames(data) {
    const uniqueOtas = new Set();

    for (const item of data) {
      for (const rateData of item.rateData) {
        uniqueOtas.add(rateData.otaName);
      }
    }

    return Array.from(uniqueOtas);
  }
  let otasPresent = getUniqueOtaNames(data.ratesDate)

  let res = data.ratesDate.map((entry) => {
    let transformedEntry = {
      "Hotel Name": data.hotelName,
      Date: new Date(entry.date).toLocaleDateString("en-GB"), // Format date as DD/MM/YYYY
      Day: new Date(entry.date).toLocaleString("en-GB", { weekday: "short" }), // Get short day name
    };

    // Initialize all OTA fields to "Sold out"
    otasPresent.forEach((ota) => {
      transformedEntry[ota] = "Sold Out";
    });

    // Fill in the rates from the rateData
    entry.rateData.forEach((rateEntry) => {
      transformedEntry[rateEntry.otaName] =
        `${currencySymbol}${rateEntry.rate}`;
    });

    return transformedEntry;
  });
  return res;
}

export const ratesReportDownload = async (
  temp,
  hotelName,
  dayStart,
  endDate,
  currencySymbol = "₹"
) => {
  let transformedData = [];
  await temp.map(async (data, id) => {
    let res = await transformData(data, currencySymbol);
    if (id === 2) {
      console.log(res, "asd");
    }
    transformedData.push(res);
  });
  let length=1; // Handle empty item
  transformedData.map((item) => {
    
    length = Math.max(length, item.length > 0 ? Object.keys(item[0]).length : 0);
  });

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl; // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  // worksheet.mergeCells("A1:L3");
  worksheet.mergeCells("A1", worksheet.getColumn(length).letter + "3");

  worksheet.addImage(logoId, "A1:A3");
  const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const columnWidths = [15, 15, 15, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

  // Display today's date at J1
  const dateCell = worksheet.getCell(worksheet.getColumn(length).letter + "3");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";

  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Rates Report ${hotelName} (${formatDate(
    dayStart
  )} - ${formatDate(endDate)})`;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };

  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  // worksheet.mergeCells("A4", "L4");
  worksheet.mergeCells("A4", worksheet.getColumn(length).letter + "4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "L5");

  transformedData.map((DATA3) => {

let currentRow= worksheet.rowCount;
worksheet.addRow(Object.keys(DATA3[0]).filter(item=> item==="Hotel Name")).eachCell((cell)=>{
    cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
})
currentRow++;
worksheet.mergeCells("A"+currentRow, "B"+currentRow)

worksheet.addRow([DATA3[0]['Hotel Name']]).eachCell((cell)=>{
   
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
})
currentRow++;
worksheet.mergeCells("A"+currentRow, "B"+currentRow)
worksheet.addRow([])
    DATA3[0] &&
      worksheet.addRow(Object.keys(DATA3[0]).slice(1)).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

    DATA3.map((source, index) => {
      worksheet.addRow(Object.values(source).slice(1)).eachCell((cell) => {
        if (cell.value === "Sold out") {
          cell.font = { color: { argb: "0000FF" } }; // Set font color to blue (hex: 0000FF)
        }
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
    worksheet.addRow([]);
  });
  console.log(transformedData, "temppp");

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Rates Report ${hotelName} .xlsx`; // Set the desired filename here
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
};
