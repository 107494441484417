// NotificationComponent.js
import React, { useState } from 'react';
import './NotificationComponent.css';

const NotificationComponent = () => {
  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={`notification-container ${showAll ? 'show-all' : ''}`}>
      <div className="notification" onClick={handleClick}>
        fghjklvgbhjkl;
        <button className='text-white'>Show All</button>
      </div>
      <div className="notification hide-behind">dfghj</div>
      <div className="notification hide-behind">fghjk</div>
    </div>
  );
};

export default NotificationComponent;
