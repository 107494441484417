import React from "react";
import * as ExcelJS from "exceljs";
import { formatDate } from "./formatDate";
import { logoUrl } from "./retvensLogo";

async function transformData(data) {
  const OTAs = [
    "Booking.com",
    "Goibibo",
    "Agoda",
    "MakeMyTrip",
    "Trip.com",
    "EaseMyTrip",
    "HappyEasyGo",
    "ClearTrip",
    "HRS",
  ];
  const defaultRate = "Sold out";
  return data.map((entry) => {
    let transformedEntry = {
      // "Hotel Name": entry.hotelName,
      Date: new Date(entry.date).toLocaleDateString("en-GB"), // Format date as DD/MM/YYYY
      Day: new Date(entry.date).toLocaleString("en-GB", { weekday: "short" }), // Get short day name
    };

    // Initialize all OTA fields to "Sold out"
    entry.rateData.forEach((ota) => {
      transformedEntry[ota.otaName] = ota.rate ? ota.rate : defaultRate;
    });

    // Fill in the rates from the rateData
    entry.rateData.forEach((rateEntry) => {
      transformedEntry[rateEntry.otaName] = rateEntry.rate;
    });

    return transformedEntry;
  });
}

export const DownloadParityReport = async (temp, asOn, dayStart) => {
  const DATA3 = await transformData(temp);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl; // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  const totalColumns = Object.keys(DATA3[0]).length;
  // worksheet.mergeCells("A1:L3");
  
  worksheet.mergeCells("A1", worksheet.getColumn(totalColumns)?.letter + "3");
  // worksheet.mergeCells("A1", worksheet.getColumn(totalColumns)?.letter + "3");
  worksheet.addImage(logoId, "A1:A3");
  const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const columnWidths = [35, 15, 15, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

  // Display today's date at J1
  const dateCell = worksheet.getCell(
    worksheet.getColumn(totalColumns)?.letter + "1"
  );
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";

  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");

  mainHeading.value = `Parity Report  ${asOn?.hotelName} (${formatDate(asOn?.startDate)})`;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };

  worksheet.mergeCells(
    "A4",
    worksheet.getColumn(totalColumns ).letter + "4"
  );
  // worksheet.mergeCells("A4", worksheet.getColumn(totalColumns)?.letter + "4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "L5");
  worksheet.addRow(Object.keys(DATA3[0])).eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "ffffff" } },
    };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });

  DATA3.map((source, index) => {
    worksheet.addRow(Object.values(source)).eachCell((cell) => {
      console.log(typeof cell.value);
      if (temp[index].parity == true && typeof cell.value == "number") {
        cell.font = { color: { argb: "FF0000" } };
      }
      if (index % 2 != 0) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ECEFCE" },
        };
      }
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Parity Report ${DATA3[0]?.hotelName ? DATA3[0]?.hotelName : asOn?.hotelName} .xlsx`; // Set the desired filename here
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
};
