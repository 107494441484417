import React, { useState } from 'react'
import ChannelCard from '../channelcard';
import Compset from './compset';
import ReviewsAndReputation from './reviews&Reputation';
import DisparityAlert from './disparityAlert';
import SettingButton from '../../../components/settingButton/SettingButton';
import { useGetAlertsAndNotificationQuery } from '../../../redux/slices/settings/alertsNotifications';
import { UserId } from '../../../utils/impVars';

const AlertAndNotification = () => {
    const {data:alertsAndNotification,refetch} = useGetAlertsAndNotificationQuery({
        userId:UserId()
    },{refetchOnMountOrArgChange:true})
    const [selectedCard, setSelectedCard] = useState(null);
    const handleCardClick = (cardTitle) => {
        setSelectedCard(cardTitle);
    };

    const handleBackButtonClick = () => {
        setSelectedCard(null)
    }
    const renderComponent = () => {
        switch (selectedCard) {
            case "Compset":
                 return <Compset handleBackButtonClick={handleBackButtonClick} refetch={refetch} data={alertsAndNotification?.data?.compset}  />;
            case "Reviews & Reputation":
                 return <ReviewsAndReputation handleBackButtonClick={handleBackButtonClick} refetch={refetch} data={alertsAndNotification?.data?.reviewAndReputation} />;
            case "Disparity Alert":
             return <DisparityAlert data={alertsAndNotification?.data?.disparityParity} refetch={refetch}  handleBackButtonClick={handleBackButtonClick} />;
            case "Rate Suggestions ":
                // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
            case "Demand Outlook":
                    // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
            case "Time Format":
                        // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
            default:
                return null;
        }
    };
  return (
    <>
    {selectedCard ? (
        <>{renderComponent()}</>
    ) :
        (
            <div className='flex flex-col gap-4'>
                <SettingButton Title={"Alerts & Notifications"} table={true} hidebackbutton={true} />
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-1'>
                        <ChannelCard title={"Compset"} onClick={() => handleCardClick("Compset")} />
                        <ChannelCard title={"Reviews & Reputation"} onClick={() => handleCardClick("Reviews & Reputation")} />
                        <ChannelCard title={"Disparity Alert"} onClick={() => handleCardClick("Disparity Alert")} />
                        <ChannelCard title={"Rate Suggestions"} onClick={() => handleCardClick("")} />
                        <ChannelCard title={"Demand Outlook"} onClick={() => handleCardClick("")} />
                        {/* <ChannelCard title={"Events"} onClick={() => handleCardClick("")} /> */}
                    </div>
                </div>
                {/* {renderComponent()} */}
            </div>)}


</>
  )
}

export default AlertAndNotification;