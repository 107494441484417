import React, { useEffect } from 'react';
import './Backdrop.css'; // You can create a CSS file for styling

const Backdrop = ({ children}) => {
  useEffect(()=>{
    let body = document.body
    body.style.overflow = "hidden"
  })
  return (
    <div className="backdrop backdrop-blur-sm">
      {children}
    </div>
  );
};

export default Backdrop;
