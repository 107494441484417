import React from "react";
import icon from "../../assets/img/Frame.svg";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";

const Notification = ({
  title,
  description,
  status,
  path,
  showFixNow = true,
  isCompleted = false,
}) => {
  const navigate = useNavigate();
  const handleFix = () => {
    if (path) {
      navigate(`/${path?.path}`, { state: { address: path } });
    }
  };
  return (
    <div
      className="w-full h-auto rounded-xl mb-4 flex justify-between items-center gap-2 px-2 py-1 shadow  bg-black/20 backdrop-blur-[80px] lg:flex-row md:flex-row flex-col"
      style={{ background: "#ffffff73" }}
    >
      <div className="  flex gap-2 w-full lg:w-auto md:w-auto justify-start items-center">
        {!isCompleted && <img src={icon} alt="" className="" />}
        {isCompleted && (
          <div className="w-[40px] h-[40px] flex justify-center items-center rounded-lg text-lg text-white bg-green-500">
            <AiOutlineCheck size={26} />
          </div>
        )}
        <div className="flex flex-col gap-2">
          <p className=" font-semibold text-start text-sm">{title}</p>
          <p className=" text-start text-xs w-[100%]">{description}</p>
        </div>
      </div>
      <div className=" flex items-center gap-6">
        {!isCompleted && (
          <div
            className={`bg-${status === "High" ? "red" : status === "Medium" ? "orange" : status === "Low" ? "yellow" : "green"}-50 text-${status === "High" ? "red" : status === "Medium" ? "orange" : status === "Low" ? "yellow" : "green"}-600 px-2 py-1 rounded-lg text-sm`}
          >
            {status}
          </div>
        )}
        <div className="flex flex-row gap-2 items-center justify-end lg:w-auto md:w-auto w-full">
          {showFixNow && (
            <button className=" cursor-pointer rounded-lg bg-[#1D2D44] text-white px-2 py-1">
              <p className="text-sm" onClick={handleFix}>
                Fix Now
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
