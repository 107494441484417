import React, { useEffect, useState } from "react";
import ChannelCard from "../../channelcard";
import SettingButton from "../../../../components/settingButton/SettingButton";
import { useEditAlertsAndNotificationMutation } from "../../../../redux/slices/settings/alertsNotifications";
import { toast } from "react-toastify";
import { UserId } from "../../../../utils/impVars";
import BackdropLoader from "../../../../components/newLoader";

const Compset = ({ handleBackButtonClick, data, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editAlertsAndNotification] = useEditAlertsAndNotificationMutation();
  const [selectedCard, setSelectedCard] = useState(null);
  const [rateChangeAlert, setRateChangeAlert] = useState(null);
  const [soldOut, setSoldOut] = useState(null);
  const [parity, setParity] = useState(null);
  const [rateParity, setRateParity] = useState(null);
  const userId = UserId();
  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  // const handleBackButtonClick = () => {
  //     setSelectedCard(null)
  // }
  const renderComponent = () => {
    switch (selectedCard) {
      case "Rate Change Alert":
      // return <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />;
      case "Sold-out":
      // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
      case "Parity":
      // return <RoomType handleBackButtonClick={handleBackButtonClick} />;
      case "Rate parity with compset":
      // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;

      default:
        return null;
    }
  };

  useEffect(()=>{
    if (parity != null) {
      changeCompset();
    }
  },[parity])

  useEffect(()=>{
    if (rateChangeAlert != null) {
      changeCompset();
    }
  },[rateChangeAlert])

  useEffect(()=>{
    if (rateParity != null) {
      changeCompset();
    }
  },[rateParity])

  useEffect(() => {
    if (soldOut != null) {
      changeCompset();
    }
  }, [soldOut]);

  function changeCompset() {
    setIsLoading(true)
    const body = {
      compset: {
        parity: parity != null ? parity : data?.parity,
        rateChangeOut:
          rateChangeAlert != null ? rateChangeAlert : data?.rateChangeOut,
        rateParityWithCompset:
          rateParity != null ? rateParity : data?.rateParityWithCompset,
        soldOut: soldOut != null ? soldOut : data?.soldOut,
      },
    };
    editAlertsAndNotification({ userId, body })
      .unwrap()
      .then((res) => {
        refetch();
        setIsLoading(false)
        toast.success("Updated Successfully!", { position: "bottom-right" });
      })
      .catch((err) => {
        setIsLoading(false)
      });
  }

  return (
    <>
    <BackdropLoader loading={isLoading} />
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col gap-4">
          <SettingButton
            Title={"Compset"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <ChannelCard
                title={"Rate Change Alert"}
                check={true}
                checked={rateChangeAlert || data?.rateChangeOut}
                onChange={(e) => {
                  setRateChangeAlert(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Sold-out"}
                check={true}
                checked={soldOut || data?.soldOut}
                onChange={(e) => {
                  setSoldOut(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Parity"}
                check={true}
                checked={parity || data?.parity}
                onChange={(e) => {
                  setParity(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Rate parity with compset"}
                check={true}
                checked={rateParity || data?.rateParityWithCompset}
                onChange={(e) => {
                  setRateParity(e.target.checked);
                }}
              />
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};

export default Compset;
