import React from "react";
import * as ExcelJS from "exceljs";
import { logoUrl } from "../../pages/dashboard/DownloadExcel/retvensLogo";



export const streamlineExcel = async (DATA3,filename) => {


  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  


  const logoBase64 = logoUrl;
  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:G3");
  worksheet.addImage(logoId, "A1:A3");

  const dateCell = worksheet.getCell("C3");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";

  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");

  const columns = ["A", "B", "C","D", "E", "F", "G", "H", "I"];
  const columnWidths = [25, 25, 25,25,25,25,25,15,15]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

 
    

    worksheet.addRow(Object.keys(DATA3[0])).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });   
    DATA3.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

   

  


   

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    
    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);
    
    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = ` ${filename } .xlsx`; // Set the desired filename here
    a.click();
    
    // Clean up
    window.URL.revokeObjectURL(url);
};




