import React, { useEffect, useState } from 'react';
import '../Global.css';
import Img from '../../../assets/img/Branding.svg';
import SimpleButton from '../../../components/button/SimpleButton';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../components/inputFeild';
import { useNavigate } from 'react-router-dom';
import ConfirmationDone from '../confirmation/ConfirmationDone';
import Property from '../../../assets/icons/onboarding/Property.svg';
import Property2 from '../../../assets/icons/onboarding/Chain.svg';
import Property3 from '../../../assets/icons/onboarding/Property name.svg';

const PropertyDetails = ({ onInputChange }) => {
    // const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [propertyName, setPropertyName] = useState("");
    const handlePropertyNameChange = (event) => {
        const value = event.target.value;
        setPropertyName(value); // Update property name state variable
    };
     
    const methods = useForm({});
    const { control, handleSubmit, watch } = methods;
    const [selectedProperty, setSelectedProperty] = useState();
    const propertyType = methods.watch("property_type");
    //     useEffect(() => {
    //     const delayTransition = setTimeout(() => {
    //       setLoading(false);
    //     }, 2000); // Adjust the delay time as needed

    //     // Cleanup the timeout to avoid memory leaks
    //     return () => clearTimeout(delayTransition);
    //   }, []);
    

    const options = [
        { label: "Single Property", value: "Single Property" },
        { label: "Property Chain", value: "Property Chain" }
    ]

    useEffect(() => {
        setSelectedProperty(propertyType?.value)
    }, [methods.watch("property_type")]);

    const onSubmit = (data) => {
       
        
        if (selectedProperty === "Single Property") {
            // setLoading(true);
           
            navigate('/single_property' , { state:{propertyName :data.property_name ,selectedoption:data.property_type}});
            
            // setLoading(false);
        }
         else if (selectedProperty === "Property Chain") {
            navigate('/chain_property' , { state:{propertyName :data.property_name ,selectedoption:data.property_type,propertySize:data.property_chain_size}});
        }
    };
    useEffect(()=>{
        let body = document.body
        body.style.overflow = ""
      })
    return (
        <>
            {/* {
                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>
            } */}

            {showConfirmation ? (
                <ConfirmationDone title='Thank You for signing up' subtitle='Our sales team will assist you shortly.' />
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='verify-email-container'>
                            <div className='verify-email-main'>
                                <div className='flex w-full justify-center'>
                                    <img className='img-logo' loading="lazy" src={Img} alt='' />
                                </div>
                                <div className='verify-email-content'>
                                    <div className='flex items-center justify-center flex-col gap-2'>
                                        <h1 className='title'>Your Property’s Details</h1>
                                        <p className='text-[0.8rem] text-center text-[#485266]'>Let's kick things off with some key information</p>
                                    </div>
                                    <div className='flex flex-col w-full gap-4'>
                                        <div className='w-full flex flex-row justify-center'>
                                            <div className='flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white '>
                                                <div className="w-full">
                                                    <InputField
                                                        placeholder={"Select your Property Type"}
                                                        className={"w-full text-start"}
                                                        label={"Select Property Type"}
                                                        id={"property_type"}
                                                        type={"select"}
                                                        control={control}
                                                        name={"property_type"}
                                                        options={options}
                                                        rules={{ required: "Property Type is required" }}
                                                        startIcon={Property}
                                                    />
                                                </div>
                                                {(selectedProperty === "Single Property" || selectedProperty === "Property Chain") && (
                                                    <div className={`w-full ${selectedProperty === "Single Property" ? "rounded-b-lg overflow-hidden" : ""}`}>
                                                        <InputField
                                                            placeholder={" Enter property Name"}
                                                            className={`w-full text-start`}
                                                            label={"Property Name"}
                                                            id={"property_name"}
                                                            type={"text"}
                                                            control={methods.control}
                                                            name={"property_name"}
                                                            value={propertyName} // Pass the property name value
                                                            onChange={handlePropertyNameChange}
                                                            startIcon={Property3}
                                                            options={options}
                                                            rules={{ required: "Property Name is required" }}
                                                        />
                                                    </div>
                                                )}
                                                {selectedProperty === "Property Chain" && (
                                                    <div className="w-full rounded-b-lg overflow-hidden">
                                                        <InputField
                                                            placeholder={" Enter property Chain Size"}
                                                            className={"w-full text-start "}
                                                            label={"Property Chain Size"}
                                                            id={"property_chain_size"}
                                                            type={"text"}
                                                            control={control}
                                                            startIcon={Property2}
                                                            name={"property_chain_size"}
                                                            options={options}
                                                            rules={{ required: "Property Chain Size is required" }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <SimpleButton text='Next' type='submit' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            )}
        </>
    );
};

export default PropertyDetails;
