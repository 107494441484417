import { createSlice } from '@reduxjs/toolkit';

// Load initial state from local storage
const initialState = JSON.parse(localStorage.getItem('loginData')) || {};

const loginDataSlice = createSlice({
  name: 'loginData',
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      // Save login data to local storage
      localStorage.setItem('loginData', JSON.stringify(action.payload));
      return action.payload; // Update state
    },
  },
});

export const { setLoginData } = loginDataSlice.actions;

export default loginDataSlice.reducer;