import { emptySplitApi } from "../../injectEndpoints";
import { useUpdateCompsetRateRuleMutation } from "../rateSuggestions";

export const RateSuggestions = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRateThreshold: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRateThreshold`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertyRoomDetails"],
    }),
    addRateThreshold: builder.mutation({
      query: (data) => {

        return {
          url: `/userDashboardSetting/postRateThreshold`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["addRateThreshold"],
    }),
    updateRateThreshold: builder.mutation({
      query: (data) => {

        return {
          url: `/userDashboardSetting/updateRateThreshold`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["updateRateThreshold"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRateThresholdQuery,
  useAddRateThresholdMutation,
  useUpdateRateThresholdMutation
} = RateSuggestions;
