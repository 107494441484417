import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";
import NoDataFound from "../../components/noData";

const MonthReportTable = ({ data, room, filename, defaultSortColumn, reservation, arrival, night, isExpanded,
    onExpand, }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const rowsPerPage = 5; // Change this value as per your requirement
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    let currentRows = [];

    if (Array.isArray(data)) {
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        currentRows = data
        // .slice(indexOfFirstRow, indexOfLastRow);
    } else {
    }

    useEffect(() => {
        if (defaultSortColumn) {
            setSortColumn(defaultSortColumn);
        }
    }, [defaultSortColumn]);

    const sortedData = currentRows.slice().sort((a, b) => {
        if (sortColumn) {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];
            if (valueA < valueB) return sortDirection === "asc" ? 1 : -1;
            if (valueA > valueB) return sortDirection === "asc" ? -1 : 1;
        }
        return 0;
    });

    return (
        <div  className={`flex flex-col  dark:bg-lightGradient bg-newGradient text-white border rounded-lg cursor-pointer`}>
            <div className="w-[100%] flex p-2 justify-center items-center" onClick={onExpand}>
                <div className="w-[50%] flex flex-col justify-around">
                    <div className="pl-4">
                        <p className=" text-[16px]">
                            {reservation && "Reservation Pace"}
                            {arrival && (room ? "By Rooms" : "By Source")}
                            {night && "Room Night"}

                        </p>
                        <p className=" text-sm">
                            {data?.length} results found
                        </p>
                    </div>
                </div>
                <div className="w-[50%] flex justify-end pr-6">
                    <img src={isExpanded ? arrowUp : arrowDown} alt="" />
                </div>
            </div>
            {(isExpanded && data.length>0)? <div className={` dark:bg-lightGradient bg-newGradient `}>
                <div className="w-[100%] border-b-4 flex p-2 justify-center items-center">
                    <div className={`${arrival ? "w-[11%]" : "w-[20%]"} text-center text-base font-medium flex items-center`}>
                        <p className={`${arrival ? "w-[55%]" : ""}`}>{`${room ? "Rooms" : "Source"}`}</p>
                    </div>
                    <div className={`${arrival ? "w-[11%]" : "w-[20%]"} text-center text-base font-medium`}>
                        <p className="w-[80%]">Revenue</p>
                    </div>
                    <div className={`${arrival ? "w-[11%]" : "w-[20%]"} text-center text-base font-medium`}>
                        <p className="w-[80%]">Reservation</p>
                    </div>
                    <div className={`${arrival ? "w-[11%]" : "w-[20%] pl-6"} text-center text-base font-medium`}>
                        <p className="w-[100%]">ADR</p>
                    </div>
                    {arrival && <div className="w-[11%] text-center text-base font-medium">
                        <p className="w-[100%]">Room Nights</p>
                    </div>}
                    {/* {arrival && <div className="w-[11%] text-center text-base font-medium">
                        <p className="w-[100%]">Occupancy</p>
                    </div>}
                    {arrival && <div className="w-[11%] text-center text-base font-medium">
                        <p className="w-[100%]">RevPar</p>
                    </div>} */}
                    {arrival && <div className="w-[11%] text-center text-base font-medium">
                        <p className="w-[100%]">Average Lead (Days)</p>
                    </div>}
                    {arrival && <div className="w-[11%] text-center text-base font-medium">
                        <p className="w-[100%]">Cancellations</p>
                    </div> }
                </div>
                <div className="w-[100%] flex flex-col">
                    <div class="h-[300px] scroll-container">
                        {sortedData.map((item, index) => {
                            return (
                                <div key={index}>
                                    <DataRow
                                        key={index}
                                        item={item}
                                        index={indexOfFirstRow + index}
                                        selectedRows={selectedRows}
                                        room={room}
                                        arrival={arrival}
                                        night={night}
                                        reservation={reservation}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> :isExpanded && <NoDataFound/>}
        </div>
    );
};

export default MonthReportTable;

const DataRow = ({
    data,
    room,
    item,
    index,
    selectedRows,
    setSelectedIndex,
    arrival,
    night,
    reservation
}) => {
    Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
    };
    const color = [
        "#1abc9c",
        "#f1c40f",
        "#2ecc71",
        "#2980b9",
        "#fad390",
        "#f8c291",
        "#60a3bc",
        "#4a69bd",
    ];

    const isSelected = selectedRows.includes(index);

    if (isSelected) {
    }

    return (
        <div className="flex flex-col py-2">
            <div
                className={`w-[100%] flex p-2 gap-2 justify-center items-center cursor-pointer`}

            >
                <div className={`${arrival ? "w-[11%]" : "w-[20%]"} flex gap-2 items-center justify-start`}>
                    <div
                        className="w-[25px] h-[25px] flex items-center justify-center  text-white text-[12px] rounded-md"
                        style={{ background: color.random() }}
                    >
                        {(item?.Source && item?.Source.toString().charAt(0)) ||
                            (item?.RoomType && item?.RoomType.toString().charAt(0))}
                    </div>
                    <p className="text-sm font-semibold truncate w-[150px]" id="title">
                        <div className="truncate w-[100px]" data-tooltip-id="my-tooltip" data-tooltip-content={room ? item?.RoomType : item?.Source}>
                            {" "}
                            {room ? item?.RoomType : item?.Source}
                        </div>
                        <Tooltip id="my-tooltip" style={{ borderRadius: '10px' }} />
                    </p>
                </div>
                <div className={`${arrival ? "w-[11%] gap-0" : "w-[20%] gap-4"}  flex items-end justify-start`}>
                    {!arrival && <div className="flex flex-col gap-2 w-1/2">
                        {" "}
                        <p className="text-xs text-start">For This Month</p>
                        <p className="text-sm"> {reservation && formatCurrency(item.reservationThisMonth)}
                            {night && formatCurrency(item.roomNightsThisMonth)}
                        </p>
                    </div>}
                    {!arrival && <div className="flex flex-col gap-2 ml-3">
                        <p className="text-xs text-start">After This Month</p>
                        <div className="text-sm flex gap-2">
                            <p
                                className={`${item?.reservationAfterThisMonth > item?.reservationThisMonth || item?.roomNightsAfterThisMonth > item.roomNightsThisMonth ? "text-green-500" : "text-red-500"}`}
                            >
                                {" "}
                                {reservation && formatCurrency(item?.reservationAfterThisMonth)}
                                {night && formatCurrency(item?.roomNightsAfterThisMonth)}
                            </p>
                        </div>
                    </div>}
                    {arrival && <p className="text-sm text-center w-full">{formatCurrency(item.Revenue)}</p>}
                </div>
                <div className={`${arrival ? "w-[11%] gap-0" : "w-[20%] gap-4"} flex  items-end justify-start`}>
                    {!arrival && <div className="flex flex-col gap-2 w-1/2">
                        {" "}
                        <p className="text-xs text-start">For This Month</p>
                        <p className="text-sm"> {reservation && formatCurrency(item.lastYearThisMonthReservation)}
                            {night && formatCurrency(item.lastYearThisMonthRoomNights)}</p>
                    </div>}
                    {!arrival && <div className="flex flex-col gap-2">
                        {" "}
                        <p className="text-xs text-start">After This Month</p>
                        <p
                            className={`text-sm flex gap-2 ${item?.lastYearAfterThisMonthReservation > item.lastYearThisMonthReservation || item.lastYearThisMonthRoomNights < item?.lastYearAfterThisMonthRoomNights ? "text-green-500" : "text-red-500"}`}
                        >
                            {reservation && formatCurrency(item?.lastYearAfterThisMonthReservation)}
                            {night && formatCurrency(item?.lastYearAfterThisMonthRoomNights)}
                        </p>
                    </div>}
                    {arrival && <p className="text-sm text-center w-full">{item.Reservations}</p>}
                </div>
                <div className={`${arrival ? "w-[11%] gap-0" : "w-[20%] gap-4"} flex  items-end justify-start`}>
                    {!arrival && <div className="flex flex-col gap-2 w-1/2">
                        {" "}
                        <p className="text-xs text-start">For This Month</p>
                        <p className="text-sm">{reservation && item.reservationLastMonth}
                            {night && item.roomNightsLastMonth}</p>
                    </div>}
                    {!arrival && <div className="flex flex-col gap-2">
                        {" "}
                        <p className="text-xs text-start">After This Month</p>
                        <p
                            className={`text-sm flex gap-2 ${item.reservationAfterThisMonthForLastMonth > item.reservationLastMonth || item.roomNightsLastMonth < item.roomNightsAfterThisMonthForLastMonth ? "text-green-500" : "text-red-500"}`}
                        >
                            {reservation && item.reservationAfterThisMonthForLastMonth}
                            {night && item.roomNightsAfterThisMonthForLastMonth}
                        </p>
                    </div>}
                    {arrival && <p className="text-sm text-center w-full">{formatCurrency(item.ADR)}</p>}
                </div>
                {arrival && <div className="w-[11%] flex gap-0 items-end justify-start">
                    <p className="text-sm text-center w-full">{item['Room Nights']}</p>
                </div>}
                {/* {arrival && <div className="w-[11%] flex gap-0 items-end justify-start">
                    <p className={`text-sm text-center w-full ${item.Occupancy < 0 ? 'text-red-500' : ''}`}>
                        {item.Occupancy?.toFixed(2)}%
                    </p>
                </div>}
                {arrival && <div className="w-[11%] flex gap-0 items-end justify-start">
                    <p className={`text-sm text-center w-full ${item.RevPAR < 0 ? 'text-red-500' : ''}`}>
                    {formatCurrency(item.RevPAR)}
                    </p>
                </div>} */}
                {arrival && <div className="w-[11%] flex gap-0 items-end justify-start">
                    <p className="text-sm text-center w-full">{item['Average Lead']?.toFixed(2)}</p>
                </div>}
                {arrival && <div className="w-[11%] flex gap-0 items-end justify-start">
                    <p className="text-sm text-center w-full">{item.Cancellations}</p>
                </div>}
            </div>
        </div>
    );
};
