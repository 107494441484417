import { useEffect, useState } from "react";
import RoomMapSelectComp from "../roomMappingSelector";

const RenderPropertyRooms = ({
  data,
  index,
  activeRooms,
  setActiveRooms,
}) => {
  const [selectedRooms, setSelectedRooms] = useState();

  const [currentRoomObject, setCurrentRoomObject] = useState();

  const [close, setClose] = useState(false);
  function filterRoomsNotInOta(rooms, otaRooms, otaIndex) {
    const otaRoomIds =
      otaRooms && otaRooms.map((room) => room?.properties[otaIndex]?.roomId);

    return (
      rooms &&
      otaRoomIds &&
      rooms.filter((room) => !otaRoomIds.includes(room.roomID))
    );
  }

  const convertRoomObject = (roomArray, index, roomIds) => {
    const newRoomArray = filterRoomsNotInOta(roomArray, activeRooms, index);

    const newArr =
      newRoomArray &&
      newRoomArray.map((item) => {
        return {
          label: item?.roomName || item?.roomTypeName,
          value: item?.roomID || item?.roomTypeId,
        };
      });
    return newArr;
  };

  useEffect(() => {
    let newRooms;
    newRooms = convertRoomObject(data?.data[index+1]?.rooms, index);
    setCurrentRoomObject(newRooms);
  }, [activeRooms]);

  useEffect(() => {
    let rooms = [];
    activeRooms &&
      activeRooms.map((roomy, roomyId) => {
        rooms.push({
          id: roomyId,
          roomId: "",
          roomName: "",
        });
      });
    setSelectedRooms(rooms);
  }, [activeRooms,]);

  function setOtaRIdByIndexes(roomsArray, roomIndex, otaIndex, otaRIdValue) {
    if (
      roomsArray === null ||
      roomsArray === undefined ||
      // roomIndex >= roomsArray.length ||
      otaIndex >= roomsArray[roomIndex].properties.length
    ) {
      console.error("Invalid indexes provided");
      return;
    }

    // Clone the object before modifying
    const updatedRooms = JSON.parse(JSON.stringify(roomsArray));

    updatedRooms[roomIndex].properties[otaIndex].roomId = otaRIdValue;

    return updatedRooms;
  }
  function findRoomNameById(rooms, roomId) {
    const foundRoom = rooms && rooms.find((room) => room.roomID === roomId);
    return foundRoom ? foundRoom.roomName : "";
  }
  return (
    <div className="flex flex-col gap-4">
      { activeRooms && activeRooms.map((rooms, roomIds) => {
        return (
          <div
            key={`${index}f${roomIds}`}
            id={`${index}f${roomIds}`}
            className="w-full w-max-[100px]"
          >
            <RoomMapSelectComp
              otaId={index}
              roomId={roomIds}
              options={currentRoomObject}
              selectedData={
                activeRooms &&
                findRoomNameById(
                  data?.data[index+1]?.rooms,
                  activeRooms[roomIds]?.properties[index]?.roomId
                )
              }
              setSelectedData={(e) => {
                let testRoom1 = activeRooms;
                testRoom1 = setOtaRIdByIndexes(
                  testRoom1,
                  e.roomId,
                  e.otaId,
                  e.item.value
                );
                setActiveRooms(testRoom1);
                setClose(!close);
              }}
              deleteSelectedRoom={(e) => {
                let testRoom1 = activeRooms;
                testRoom1 = setOtaRIdByIndexes(
                  testRoom1,
                  e.roomId,
                  e.otaId,
                  ""
                );
                setActiveRooms(testRoom1);
                setClose(!close);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RenderPropertyRooms;
