import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import Calendar from "../../components/calendar/index";
import { addDays, format } from "date-fns";
import downArrow from "../../assets/icons/downArrow.svg";
import formatCurrency from "../../utils//formatCurrency";
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";

import {
  useGetInventoryQuery,
  useGetRatesAndInventoryQuery,
} from "../../redux/slices/ratesInventory";
import { CurrentProperty, UserId } from "../../utils/impVars";
import BackdropLoader from "../../components/newLoader";
import { useGetRoomTypesQuery } from "../../redux/slices/settings/channelManager/roomTypes";
import { useGetMealPlanQuery } from "../../redux/slices/settings/channelManager/mealPlan";
import { BackButton } from "../rateSuggestion/rateAnalysis";
import { Dropdown as MultiSelectDropdown } from "../IntelligenceNewDashboard";
import { Tooltip } from "react-tooltip";
import { useIsIpad } from "../../utils/isIpad";
import CheckBox from "../../components/checkbox/CheckBox";
import NoDataFound from "../../components/noData";

export function extractUniqueValues(data) {
  let roomTypes = [];
  let mealPlans = [];
  let otas = [];

  // Extract unique room types, meal plans, and OTAs
  data.forEach((item) => {
    roomTypes.push(item.title);
    item.subItems.forEach((subItem) => {
      subItem.subItems.forEach((subSubItem) => {
        if (!otas.find((ota) => ota.value === subSubItem.otaId)) {
          otas.push({ label: subSubItem.title, value: subSubItem.otaId });
        }
      });
      mealPlans.push(subItem.title);
    });
  });

  // Remove duplicates
  roomTypes = [...new Set(roomTypes)];
  mealPlans = [...new Set(mealPlans)];

  return { roomTypes, mealPlans, otas };
}

export function filterData(
  data,
  selectedRoomType,
  selectedOTA,
  selectedMealPlan
) {
  const getSelectedLabels = (selection) =>
    selection?.map((item) => item?.label) || [];

  const selectedRoomTypeData = getSelectedLabels(selectedRoomType);
  const selectedOTAData = getSelectedLabels(selectedOTA);
  const selectedMealPlanData = getSelectedLabels(selectedMealPlan);

  const isAllSelected = (selection) =>
    !selection.length || selection.includes("All");

  // If all filters are either empty or set to "All", return a shallow copy of the original data
  if (
    isAllSelected(selectedRoomTypeData) &&
    isAllSelected(selectedOTAData) &&
    isAllSelected(selectedMealPlanData)
  ) {
    return [...data];
  }

  let filteredData = [...data];

  // Filter by room type
  if (!isAllSelected(selectedRoomTypeData)) {
    filteredData = filteredData.filter((room) =>
      selectedRoomTypeData.includes(room.title)
    );
  }

  // Filter by meal plan and OTA
  if (!isAllSelected(selectedMealPlanData) || !isAllSelected(selectedOTAData)) {
    filteredData = filteredData
      .map((room) => ({
        ...room,
        subItems: room.subItems
          .map((meal) => ({
            ...meal,
            subItems: meal.subItems.filter(
              (ota) =>
                (isAllSelected(selectedOTAData) ||
                  selectedOTAData.includes(ota.title)) &&
                (isAllSelected(selectedMealPlanData) ||
                  selectedMealPlanData.includes(meal.title))
            ),
          }))
          .filter((meal) => meal.subItems.length > 0),
      }))
      .filter((room) => room.subItems.length > 0);
  }

  return filteredData;
}

const RatesAndInventory = () => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selected2Date, setSelected2Date] = React.useState(new Date());
  const [selectRoomType, setSelectRoomType] = useState([]);
  const [selectMealPlan, setSelectMealPlan] = useState([]);
  const [selectOta, setSelectedOta] = useState([]);
  const [inventoryDropDown, setInventoryDropDown] = useState([]);
  const { data: roomTypes } = useGetRoomTypesQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const { data: mealPlan } = useGetMealPlanQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const { data, isFetching, isLoading } = useGetRatesAndInventoryQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      today: format(new Date(selectedDate), "yyy-MM-dd"),
      asOn: format(new Date(selected2Date), "yyy-MM-dd"),
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: getInventory } = useGetInventoryQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      asPer: format(new Date(selectedDate), "yyy-MM-dd"),
      asOn: format(new Date(selected2Date), "yyy-MM-dd"),
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  function getNext9Days(selectedDate) {
    let result = [];
    let startDate = new Date(selectedDate);
    for (let i = 0; i < 9; i++) {
      let nextDay = addDays(startDate, i);
      let day = format(nextDay, "EEEE"); // 'EEEE' gives the full name of the day
      result.push({ date: format(nextDay, "yyyy-MM-dd"), day: day });
    }

    return result;
  }

  const roomTypesOption = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.roomTypes?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
  ];

  const mealPlansOption = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.mealPlans?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
  ];

  const otaOptions = data && [
    // { label: "All", value: "" },
    ...extractUniqueValues(data?.data?.rateData)?.otas,
  ];
  const isIpad = useIsIpad();
  const [isToggled, setIsToggled] = useState(false);
  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    if (selected2Date) {
      setSelectedDate(selected2Date);
    }
  }, [selected2Date]);

  useEffect(() => {
    let body = document.body;
    body.style.overflow = "";
  });

  // Extract unique room names
  // Organize data by roomName
  const roomsData = {};
  const totals = [];
  const occupancies = [];
  // Organize data by roomName and gather totals and occupancies
  getInventory?.data?.forEach((item) => {
    item.rooms.forEach((room) => {
      if (!roomsData[room.roomName]) {
        roomsData[room.roomName] = [];
      }
      roomsData[room.roomName].push({
        date: item.date,
        availableRooms: room.availableRooms,
        rates: room.rate,
      });
    });
    totals.push({ date: item.date, total: item.totalAvailableRooms });
    occupancies.push({ date: item.date, occupancy: item.occupancy });
  });
  useEffect(() => {
    if (isToggled) {
      setSelected2Date(new Date());
    }
  }, [isToggled]);
  return (
    <div className="w-[100%] bg-transparent h-full">
      <BackdropLoader loading={isFetching || isLoading} />
      <div className="flex justify-between w-[100%]">
        <div className="text-[17px] text-white p-4 dark:text-black font-[500] flex items-center gap-2">
          {/* <BackButton mainDashboard={true} /> */}
          Rates & Inventory
        </div>
        <div className="p-4 justify-end my-2 flex gap-2">
          <div className="flex items-center bg-white/30 p-2 dark:bg-customSubDarkBg rounded-lg gap-2">
            <h1 className="text-sm text-white">Rates</h1>
            <CheckBox
              checked={isToggled}
              onChange={toggleButton}
              name={"toggle"}
            />
            <h1 className="text-sm text-white">Inventory</h1>
          </div>
          <div className="flex justify-center items-center">
            <div className="text-sm flex items-center dark:text-black w-[80px] -mr-6 text-white">
              As On
            </div>
            <Calendar
              selectedDate={selected2Date}
              setSelectedDate={setSelected2Date}
            //   backgroundNone={true}
            //   right={"-right-0"}
            //   showIconBackground={true}
              onlyPastDaysSelectable={true}
            //    height={41}
            />
          </div>
        </div>
      </div>
      {!isToggled && (
        <div className="p-4 justify-end flex gap-2 -mt-4">
          {/* <div className="p-4 justify-end sticky top-0 bg-white flex gap-2 -mt-4"> */}
          <div className="p-4 flex items-center gap-4">
            <MultiSelectDropdown
              selectedItem={selectRoomType}
              setSelectedItem={setSelectRoomType}
              options={roomTypesOption}
              height={40}
              width={"w-[240px]"}
              placeHolder={"Select Room Types"}
            />
            <MultiSelectDropdown
              selectedItem={selectOta}
              setSelectedItem={setSelectedOta}
              options={otaOptions}
              width={"w-[240px]"}
              placeHolder={"Select OTA"}
            />
            <MultiSelectDropdown
              selectedItem={selectMealPlan}
              setSelectedItem={setSelectMealPlan}
              options={mealPlansOption}
              width={"w-[180px]"}
              placeHolder={"Select Meal Plan"}
              left={'-120px'}
            />
          </div>
        </div>
      )}
      <div className="mx-4 -mt-4 py-1 px-4  rounded-lg drop-shadow-lg backdrop-blur-md">
        {!isToggled ? (
          <>
            <div className="cal-header rounded-lg  top-[100px] bg-transparent  mt-4 px-4">
              <div className="flex    px-2 rounded-lg w-full py-2  top-0 justify-between items-center">
                <div className="w-[250px] bg-transparent">
                  <Calendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    // backgroundNone={true}
                    // width={250}
                    // right={"right-2"}
                    // showIconBackground={true}
                    enableDaysFromNow={45}
                    minStart={selected2Date}
                  />
                </div>
                {isIpad
                  ? getNext9Days(selectedDate)
                      ?.slice(0, 6)
                      .map((date, index) => {
                        return (
                          <div className="px-6 " key={index}>
                            <div className="flex  font-[500] justify-center text-[#0D1321] text-[13px]">
                              <div>{date.day.slice(0, 3)},&nbsp;</div>
                              <div>{date.date.split("-")[2]}</div>
                            </div>
                            <div className="text-white dark:text-black text-[11px]">
                              {format(new Date(date.date), "MMM")},&nbsp;
                              {format(new Date(date.date), "yyyy")}
                            </div>
                          </div>
                        );
                      })
                  : getNext9Days(selectedDate).map((date, index) => {
                      return (
                        <div className="px-6 bg-transparent" key={index}>
                          <div className="flex font-[500] justify-center text-[#ffffff] dark:text-black text-[13px]">
                            <div>{date.day.slice(0, 3)},&nbsp;</div>
                            <div>{date.date.split("-")[2]}</div>
                          </div>
                          <div className="text-[#f1f2f3] dark:text-black text-[11px]">
                            {format(new Date(date.date), "MMM")},&nbsp;
                            {format(new Date(date.date), "yyyy")}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="mt-4 scrollHide  min-h-auto max-h-[500px] overflow-y-scroll">
              <Accordion
                data={
                  data?.data?.rateData &&
                  filterData(
                    data?.data?.rateData,
                    selectRoomType,
                    selectOta,
                    selectMealPlan
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="cal-header sticky top-0  mt-4 px-4">
              <div className="flex bg-white  bg-newGradient text-white dark:bg-lightGradient  rounded-lg px-2 py-2  sticky top-0 justify-between items-center">
                <div className="w-[250px]">
                  <Calendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    // backgroundNone={true}
                    // width={50}
                    // right={"right-0"}
                    // showIconBackground={true}
                    enableDaysFromNow={45}
                    minStart={selected2Date}
                  />
                </div>
                {isIpad
                  ? getNext9Days(selectedDate)
                      ?.slice(0, 6)
                      ?.map((date, index) => {
                        return (
                          <div className="px-6 " key={index}>
                            <div className="flex font-[500] text-white justify-center  text-[13px]">
                              <div>{date.day.slice(0, 3)},&nbsp;</div>
                              <div>{date.date.split("-")[2]}</div>
                            </div>
                            <div className=" text-[11px]">
                              {format(new Date(date.date), "MMM")},&nbsp;
                              {format(new Date(date.date), "yyyy")}
                            </div>
                          </div>
                        );
                      })
                  : getNext9Days(selectedDate)?.map((date, index) => {
                      return (
                        <div className="px-6" key={index}>
                          <div className="flex font-[500] justify-center  text-white text-[13px]">
                            <div>{date.day.slice(0, 3)},&nbsp;</div>
                            <div>{date.date.split("-")[2]}</div>
                          </div>
                          <div className="text-[11px]">
                            {format(new Date(date.date), "MMM")},&nbsp;
                            {format(new Date(date.date), "yyyy")}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="px-4  overflow-y-auto h-[60vh]">
              <div className="flex  flex-col gap-2">
                {Object.entries(roomsData).map(
                  ([roomName, availability], index) => (
                    <div className="flex   flex-col gap-2" key={index}>
                      <div className="rounded-xl m-2">
                        <div className="accordion-item cursor-pointer w-[100%] flex items-start flex-col rounded-xl  bg-newGradient text-white dark:bg-lightGradient ">
                          <div className="w-[100%]  flex justify-between items-center">
                            <div className=" w-[250px]">
                              <div className="accordion-title font-medium h-[50px]   text-[15px] w-[300px] flex justify-start items-center">
                                <div
                                  className="min-w-[240px] max-w-[240px] flex items-center justify-between truncate px-6"
                                  onClick={() => {
                                    if (inventoryDropDown.includes(index)) {
                                      const filteredDate =
                                        inventoryDropDown.filter(
                                          (item) => item !== index
                                        );
                                      setInventoryDropDown(filteredDate);
                                    } else {
                                      setInventoryDropDown([
                                        ...inventoryDropDown,
                                        index,
                                      ]);
                                    }
                                  }}
                                >
                                  <div
                                    className="min-w-[100%] max-w-[100%] truncate"
                                    data-tooltip-id={`tooltip-${index}`}
                                    data-tooltip-content={roomName}
                                  >
                                    {" "}
                                    {roomName}
                                  </div>{" "}
                                </div>
                                {inventoryDropDown.includes(index) ? (
                                  <IoMdArrowDropup
                                    onClick={() => {
                                      if (inventoryDropDown.includes(index)) {
                                        const filteredDate =
                                          inventoryDropDown.filter(
                                            (item) => item !== index
                                          );
                                        setInventoryDropDown(filteredDate);
                                      } else {
                                        setInventoryDropDown([
                                          ...inventoryDropDown,
                                          index,
                                        ]);
                                      }
                                    }}
                                  />
                                ) : (
                                  <MdArrowDropDown
                                    onClick={() => {
                                      if (inventoryDropDown.includes(index)) {
                                        const filteredDate =
                                          inventoryDropDown.filter(
                                            (item) => item !== index
                                          );
                                        setInventoryDropDown(filteredDate);
                                      } else {
                                        setInventoryDropDown([
                                          ...inventoryDropDown,
                                          index,
                                        ]);
                                      }
                                    }}
                                  />
                                )}
                                <Tooltip id={`tooltip-${index}`} />
                              </div>
                            </div>

                            {availability
                              .slice(0, 9)
                              .map((availabilityItem, i) => (
                                <div
                                  key={i}
                                  className="text-xs  flex items-center justify-center  text-center ml-2 w-[100%]"
                                >
                                  {availabilityItem.availableRooms}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      {inventoryDropDown.includes(index) && (
                        <div className="flex">
                          <div className="w-[320px] h-[25px]"></div>
                          <div
                            className="accordion-item cursor-pointer w-[100%]  bg-newGradient text-white dark:bg-lightGradient items-center  flex justify-evenly rounded-xl "
                            data-tooltip-id={`tooltip-${index}`}
                            data-tooltip-content={roomName}
                          >
                            {availability
                              .slice(0, 9)
                              .map((availabilityItem, i) => (
                                <div
                                  key={i}
                                  className="text-xs flex items-center justify-center px-6 text-center ml-2 w-[100%]"
                                >
                                  {availabilityItem.rates}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )}

                {/* Totals Row */}
                <div className="flex flex-col gap-2">
                  <div className="bg-[#f7f4f4] rounded-xl m-2">
                    <div className="accordion-item  cursor-pointer w-[100%] flex items-start flex-col rounded-xl  bg-newGradient text-white dark:bg-lightGradient ">
                      <div className="w-[100%] flex justify-start items-center">
                        <div className="accordion-title font-medium h-[50px] text-[#131926] text-[15px] w-[250px] flex justify-between items-center">
                          <div className="w-[320px] font-bold  truncate px-6">
                            Total
                          </div>
                        </div>
                        {totals.slice(0, 9).map((totalItem, i) => (
                          <div
                            key={i}
                            className="text-sm font-bold flex items-center justify-center px-6 text-center ml-2 w-[100%]"
                          >
                            {totalItem.total}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Occupancy Row */}
                {/* <div className="flex flex-col gap-2">
                  <div className="bg-[#f7f4f4] rounded-xl m-2">
                    <div className="accordion-item cursor-pointer w-[100%] flex items-start flex-col rounded-xl bg-[#f7f4f4]">
                      <div className="w-[100%] flex justify-between items-center">
                        <div className="accordion-title font-medium h-[50px] text-[#131926] text-[15px] w-[250px] flex justify-between items-center">
                          <div className="w-[200px] truncate px-6">
                            Occupancy
                          </div>
                        </div>
                        {occupancies?.slice(0, 9).map((occupancyItem, i) => (
                          <div
                            key={i}
                            className="text-xs flex items-center justify-center px-6 text-center ml-2 w-[6%]"
                          >
                            {occupancyItem.occupancy}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RatesAndInventory;

const Accordion = ({ data }) => {
  return (
    <div className="">
      <NestedAccordion data={data} />
    </div>
  );
};

const NestedAccordion = ({ data }) => {
  function getLowestRate(data) {
    const lowestRates = [];

    data.forEach((room) => {
      const roomTitle = room.title;
      const dates = room.subItems[0].subItems[0].date;
      const roomPrices = room.subItems[0].subItems.map((ota) => ota.prices);

      const minPrices = dates.map((date, index) => {
        const pricesOnDate = roomPrices.map((otaPrices) => otaPrices[index]);
        const minPrice = Math.min(...pricesOnDate);
        const minPriceOTAs = room.subItems[0].subItems
          .filter((ota) => ota.prices[index] === minPrice)
          .map((ota) => ({ otaId: ota.otaId, price: minPrice }));

        return { date, minPrice, minPriceOTAs };
      });

      lowestRates.push({ roomTitle, minPrices });
    });

    return lowestRates;
  }

  const [openItems, setOpenItems] = React.useState([0]);

  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter((item) => item !== index)
        : [...prevOpenItems, index]
    );
  };

  const isItemOpen = (index) => {
    return openItems.includes(index);
  };
  const isIpad = useIsIpad();
  return (
    <div>
      {data?.length > 0 ? (
        <>
          {" "}
          {Array.isArray(data) &&
            data?.slice(isIpad ? 0 : 0, isIpad ? 6 : 9).map((item, index) => (
              <div
                key={index}
                className={`accordion-item cursor-pointer w-[100%]   flex items-start flex-col  ${isItemOpen(index) ? "rounded-xl" : "rounded-xl"}   bg-newGradient text-white dark:bg-lightGradient    mb-4`}
              >
                <div
                  className={`w-[100%]   ${isItemOpen(index) ? "border-b border-b-[#D3D8E1]" : ""}  flex justify-between  items-center`}
                >
                  <div>
                    <div
                      className="accordion-title font-medium  py-4 px-2 h-[50px] text-white text-[15px] w-[250px] flex justify-between items-center"
                      onClick={() => toggleItem(index)}
                    >
                      <div
                        className="w-[200px] truncate"
                        data-tooltip-id={"index"}
                        data-tooltip-content={item?.title}
                      >
                        {item.title}
                      </div>
                      <Tooltip id={"index"} />
                      <div className="filter invert">
                        <img
                          src={downArrow}
                          className={`${isItemOpen(index) ? "rotate-180" : ""}`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-xs px-4 text-center ml-2 w-[10%]">
                    {formatCurrency(
                      getLowestRate(data)[index]?.minPrices[0]?.minPrice
                    ) === "₹ 0.00"
                      ? "--"
                      : formatCurrency(
                          getLowestRate(data)[index]?.minPrices[0]?.minPrice
                        )}
                  </div>
                  <div className="text-xs px-4 text-center ml-2 w-[10%]">
                    {formatCurrency(
                      getLowestRate(data)[index]?.minPrices[1]?.minPrice
                    ) === "₹ 0.00"
                      ? "--"
                      : formatCurrency(
                          getLowestRate(data)[index]?.minPrices[1]?.minPrice
                        )}
                  </div>
                  <div className="text-xs px-4 text-center ml-2 w-[10%]">
                    {formatCurrency(
                      getLowestRate(data)[index]?.minPrices[2]?.minPrice
                    ) === "₹ 0.00"
                      ? "--"
                      : formatCurrency(
                          getLowestRate(data)[index]?.minPrices[2]?.minPrice
                        )}
                  </div>
                  <div className="text-xs px-4 text-center ml-2 w-[10%]">
                    {formatCurrency(
                      getLowestRate(data)[index]?.minPrices[3]?.minPrice
                    ) === "₹ 0.00"
                      ? "--"
                      : formatCurrency(
                          getLowestRate(data)[index]?.minPrices[3]?.minPrice
                        )}
                  </div>
                  <div className="text-xs px-4 text-center ml-2 w-[10%]">
                    {formatCurrency(
                      getLowestRate(data)[index]?.minPrices[4]?.minPrice
                    ) === "₹ 0.00"
                      ? "--"
                      : formatCurrency(
                          getLowestRate(data)[index]?.minPrices[4]?.minPrice
                        )}
                  </div>
                  {
                    <div className="text-xs px-4 text-center ml-2 w-[10%]">
                      {formatCurrency(
                        getLowestRate(data)[index]?.minPrices[5]?.minPrice
                      ) === "₹ 0.00"
                        ? "--"
                        : formatCurrency(
                            getLowestRate(data)[index]?.minPrices[5]?.minPrice
                          )}
                    </div>
                  }
                  {!isIpad && (
                    <div className="text-xs px-4 text-center ml-2 w-[10%]">
                      {formatCurrency(
                        getLowestRate(data)[index]?.minPrices[6]?.minPrice
                      ) === "₹ 0.00"
                        ? "--"
                        : formatCurrency(
                            getLowestRate(data)[index]?.minPrices[6]?.minPrice
                          )}
                    </div>
                  )}
                  {!isIpad && (
                    <div className="text-xs px-4 text-center ml-2 w-[10%]">
                      {formatCurrency(
                        getLowestRate(data)[index]?.minPrices[7]?.minPrice
                      ) === "₹ 0.00"
                        ? "--"
                        : formatCurrency(
                            getLowestRate(data)[index]?.minPrices[7]?.minPrice
                          )}
                    </div>
                  )}
                  {!isIpad && (
                    <div className="text-xs px-4 text-center ml-2 w-[10%]">
                      {formatCurrency(
                        getLowestRate(data)[index]?.minPrices[8]?.minPrice
                      ) === "₹ 0.00"
                        ? "--"
                        : formatCurrency(
                            getLowestRate(data)[index]?.minPrices[8]?.minPrice
                          )}
                    </div>
                  )}
                </div>
                {isItemOpen(index) && (
                  <div className="accordion-content bg-[#F6F8FB] w-[100%]">
                    {item.subItems.map((subItem, subIndex) => (
                      <AccordionSubItems
                        key={subIndex}
                        item={subItem}
                        toggleItem={() => toggleItem(`${index}-${subIndex}`)} // Concatenate parent index with sub-item index
                        index={`${index}-${subIndex}`} // Concatenate parent index with sub-item index
                        openItems={openItems}
                        defaultOpen={subIndex === 0 && index === 0} // Conditionally set default open for the first sub-item of the parent at index 0
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
        </>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

const AccordionSubItems = ({
  item,
  toggleItem,
  index,
  openItems,
  defaultOpen,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  React.useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    toggleItem(index);
  };
  const isIpad = useIsIpad();
  return (
    <div className=" bg-newGradient text-white dark:bg-lightGradient ">
      {" "}
      <div className="w-[100%] border-b  border-b-[#D3D8E1]">
        <div
          className="accordion-title  font-medium pr-2 pl-6 py-4  h-[50px] text-white text-[14px] w-[250px] flex justify-between items-center"
          onClick={handleClick}
        >
          <div>{item.title}</div>
          <div className="filter invert">
            <img src={downArrow} className={`${isOpen ? "rotate-180" : ""}`} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div>
          {item.subItems.map((ota, otaIndex) => {
            return (
              <div
                className="border-b border-b-[#D3D8E1] h-[50px] px-1 flex justify-between"
                key={otaIndex}
              >
                <div className="font-medium pr-2 flex items-center justify-start gap-2 pl-9 py-4 w-[250px] text-[14px] ">
                  <div className="flex gap-2 items-center">
                    {" "}
                    <img
                      src={ota?.otaLogo}
                      loading="lazy"
                      width={"12%"}
                      className="rounded-lg"
                    />{" "}
                    {ota?.title}
                  </div>
                </div>
                {ota.prices
                  ?.slice(isIpad ? 0 : 0, isIpad ? 6 : 9)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-[100px] group relative z-10 flex justify-center items-center text-white rounded-lg my-2 flex-wrap"
                      >
                        {" "}
                        <div className="text-[#fffff] font-medium text-[13px] ">
                          {item === 0 ? "--" : formatCurrency(item)}
                        </div>{" "}
                        {/* <div className="bg-[#4267E533] invisible group-hover:visible opacity-0 backdrop-blur-2xl group-hover:opacity-100 duration-200 ease-in-out transition-all  absolute -top-[58px] border border-[#C1CEFF] p-[8px] rounded-xl">
                        <div className="text-[9px] text-[#0D1321]">
                          Last Updated
                        </div>
                        <div className="text-[12px] text-[#0D1321] font-medium ">
                          3 hrs ago
                        </div>
                      </div> */}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
