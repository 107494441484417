import React, { useEffect } from "react";
import backbutton from "../../assets/icons/Marker.svg";
import img1 from "../../assets/icons/Layer_1.svg";
import img3 from "../../assets/icons/Layer_3.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import formatCurrency from "../../utils/formatCurrency";
import { BackButton } from "../rateSuggestion/rateAnalysis";
const ReservationDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resData } = location.state;
  const handleClick = () => {
    navigate("/reservations");
  };

  return (
    <div className=" flex flex-col p-4 gap-4">
      <div className=" dark:bg-lightGradient bg-newGradient p-4  rounded-xl">
        <div className="flex justify-between items-center ">
          <div className="flex items-center gap-2 ">
            {/* <button>
              <BackButton onClick={handleClick} />
            </button> */}
            <h1 className=" text-lg  text-white ">
              Reservation Details
            </h1>
          </div>
          {/* <div className='flex gap-2'>
                        <Dropdown selectedItem={"Room Type Selector"} />
                        <Dropdown selectedItem={"Rate Strategy Selector"} />

                    </div> */}
        </div>
      </div>
      <div className=" flex p-4 rounded-xl dark:bg-lightGradient  text-white bg-newGradient w-full overflow-hidden">
        <div className=" w-full">
          <div className=" text-[20px] text-white mb-2 uppercase">
            {" "}
            <p>{resData?.reservations?.guestsDetails?.guestDetails?.name}</p>
          </div>
          <div className="flex gap-4 mb-2">
            <div className="flex gap-2">
              <img src={img1} className=" filter dark:invert text-white w-5 h-5" />
              <p className="">
                {
                  resData?.reservations?.bookingDetails?.roomDetails?.pax
                    ?.totalAdults
                }
              </p>
            </div>

            {/* <img src={img2} className=' w-5 h-5' /><p className='font-semibold'>1</p>
                        <img src={img4} className=' w-5 h-5' /><p className='font-semibold'>1</p> */}
            <div className="flex gap-2">
              <img src={img3} className=" filter dark:invert  w-5 h-5" />
              <p className="">
                {resData?.reservations?.bookingDetails?.totalNights}
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            {/* <div className='text-white text-sm font-semibold bg-orange-500 w-7 h-7 flex items-center justify-center rounded-full'>go</div> */}
            <div
              className={`w-fit px-2 h-7 rounded-lg flex items-center ${resData?.bookingStatus === "Cancelled" ? "text-red-500 bg-red-50" : "text-[#29CC39] bg-[#D6F4E6]"}  justify-center`}
            >
              {resData?.bookingStatus &&
                resData?.bookingStatus?.toString()?.charAt(0).toUpperCase() +
                  resData?.bookingStatus.slice(1)}
            </div>
          </div>
        </div>
        <div className=" w-full">
          <p className=" text-white font-bold  text-sm mb-2">
            Arrival
          </p>
          <p className=" text-white   mb-2">
            {format(
              new Date(resData?.reservations?.bookingDetails?.arrivalDate),
              "dd MMM yyyy"
            )}
          </p>
          <p className="text-white  font-bold  text-sm mb-2">Room</p>
          <p className=" text-white  font-normal">
            {resData?.reservations?.bookingDetails?.roomDetails?.roomTypeName}
          </p>
        </div>
         <div className=" w-full">
          {/* <p className=" text-neutral-dark-18 font-normal text-sm mb-2">
            Reservation ID
          </p>
          <p className=" text-neutral-dark-24  font-normal  mb-2">
            ABC1234567890
          </p> */}
          <p className=" text-white font-bold  text-sm mb-2">
            Balance
          </p>
          <p className=" text-neutral-dark-24 text-white  font-normal  mb-2">
            ₹ {resData?.reservations.paymentDetails?.outstanding}
          </p>
        </div> 
      </div>
      <div className=" flex p-4 rounded-xl   dark:bg-lightGradient bg-newGradient -mt-2 justify-between overflow-hidden gap-4 w-full">
        <div className="flex flex-col w-full  ">
          <h1 className="text-[#F0F0F0] text-lg mb-4  dark:text-[#D3D8E1]">
            Booking Info
          </h1>
          <div className="grid gap-2 ">
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Guest Name
              </p>
              <p className=" w-1/4 text-neutral-light-20  text-center">-</p>
              <p className="w-1/2 text-white font-normal uppercase">
                {resData.reservations?.guestsDetails?.guestDetails?.name}
              </p>
            </span>
            {/* <span className='flex justify-between'>
            <p className="w-1/3  text-neutral-light-18 font-normal">Phone</p>
            <p className='w-1/4 text-neutral-light-20 text-center'>-</p>
            <p className='w-1/2 text-neutral-dark-24  font-normal'>+9199876549876</p>
        </span>
        <span className='flex justify-between'>
            <p className="w-1/3  text-neutral-light-18 font-normal">Email Address</p>
            <p className='w-1/4 text-neutral-light-20 text-center'>-</p>
            <p className='w-1/2 text-neutral-dark-24  font-normal'>Email@eamil.come</p>
        </span> */}
            <span className="flex justify-between">
              <p className="w-1/3 text-[#F0F0F0]  font-normal">Source</p>
              <p className="w-1/4 text-[#F0F0F0] text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                {resData?.reservations?.source}
              </p>
            </span>
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Booking Time
              </p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                {format(
                  new Date(resData?.reservations?.bookingDetails?.createdOn),
                  "dd MMM yyyy '- 'HH:mm:ss"
                )}
              </p>
            </span>
            {/* <span className="flex justify-between">
              <p className="w-1/3  text-neutral-dark-18 font-normal">
                Occupancy
              </p>
              <p className="w-1/4 text-neutral-light-20 text-center">-</p>
              <p className="w-1/2 text-neutral-dark-24  font-normal">
                2 Adults & 2 Child
              </p>
            </span> */}
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">Arrival</p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                {format(
                  new Date(resData?.reservations?.bookingDetails?.arrivalDate),
                  "dd MMM yyyy"
                )}
              </p>
            </span>
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Departure
              </p>
              <p className="w-1/4 text-[#F0F0F0] text-center">-</p>
              <p className="w-1/2 text-white font-normal">
                {format(
                  new Date(
                    resData?.reservations?.bookingDetails?.departureDate
                  ),
                  "dd MMM yyyy"
                )}
              </p>
            </span>
             <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Occupency
              </p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white   font-normal">
              {
                  resData?.reservations?.bookingDetails?.roomDetails
                    ?.pax?.totalAdults
                } Adults
                  &  {
                  resData?.reservations?.bookingDetails?.roomDetails
                    ?.pax?.totalChildren
                } Children
              </p>
            </span> 
          </div>
        </div>

        <div className="flex flex-col w-full ">
          <h1 className="text-[#F0F0F0]   mb-4 text-lg  dark:text-[#D3D8E1]">
            Room & Charges
          </h1>
          <div className="grid gap-2">
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Room Type
              </p>
              <p className="w-1/4 text-neutral-light-20 text-center">-</p>
              <p className="w-1/2 text-white font-normal">
                {
                  resData?.reservations?.bookingDetails?.roomDetails
                    ?.roomTypeName
                }
              </p>
            </span>
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Rate Plan
              </p>
              <p className="w-1/4 text-neutral-light-20 text-center">-</p>
              <p className="w-1/2 text-white font-normal">
                {resData?.reservations?.bookingDetails?.roomDetails?.roomPlan}
              </p>
            </span>

            <span className="flex justify-between">
              <p className="w-1/3 text-[#F0F0F0]  font-normal">ADR</p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                {formatCurrency(resData?.ADR)}
              </p>
            </span>
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Total Charges
              </p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                {formatCurrency(resData?.reservations?.priceSummary?.totalCost)}
              </p>
            </span>
          </div>
        </div>
        <div className="w-full">
          <h1 className="text-[#F0F0F0]   mb-2 text-lg font-semibold  dark:text-[#D3D8E1]">
            Payment Details
          </h1>
          <div className="grid gap-2">
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">Amount</p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                ₹{resData?.reservations.paymentDetails?.amount?.toFixed(2)}
              </p>
            </span>{" "}
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Payment Status
              </p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white font-normal">
                {resData?.reservations.paymentDetails?.status}
              </p>
            </span>{" "}
            <span className="flex justify-between">
              <p className="w-1/3  text-[#F0F0F0]  font-normal">
                Pending
              </p>
              <p className="w-1/4 text-[#F0F0F0]  text-center">-</p>
              <p className="w-1/2 text-white  font-normal">
                ₹{resData?.reservations.paymentDetails?.outstanding}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetails;
