import { emptySplitApi } from "../../injectEndpoints";

export const RateMonitor = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRateMonitorDetails: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/ratesMonitor`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRateMonitorDetails"],
    }),
    getRateMonitorTodayRateDetails: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/todayRate`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRateMonitorTodayRateDetails"],
    }),
    getRateMonitorRateTrendDetails: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/priceTrend`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRateMonitorRateTrendDetails"],
    }),
    getRateTendencies: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rateTendencies`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRateTendencies"],
    }),
    getParityCalander: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/parityCalander`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getParityCalander"],
    }),
    getRateParity: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rateParityOverview`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRateParity"],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetRateMonitorDetailsQuery,
  useGetRateMonitorTodayRateDetailsQuery,
  useGetRateMonitorRateTrendDetailsQuery,
  useGetRateTendenciesQuery,
  useGetParityCalanderQuery,
  useGetRateParityQuery
} = RateMonitor;
