import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import SettingButton from "../../../../components/settingButton/SettingButton";
import Dropdown from "../../../../components/dropdown";
import Tableactiontype from "../../../../components/table/TableActionTYpe";
import TableButton from "../../../../components/settingButton/TableButton";
import DynamicCounter from "../../../../components/dynamicCounter/DynamicCounter";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../../components/inputFeild";
import {
  useAddMealPlanMutation,
  useDeleteMealPlanMutation,
  useEditMealPlanMutation,
  useGetMealPlanChargeQuery,
  useGetMealPlanQuery,
} from "../../../../redux/slices/settings/channelManager/mealPlan";
import CustomSkeleton from "../../../../components/Skeleton";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import { toast } from "react-toastify";
import GenerateShortCode from "../../../../utils/shortCode";

const MealPlan = ({ handleBackButtonClick }) => {
  const [isAddMeal, setIsAddMeal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [mealPlanData, setMealplan] = useState();
  const {
    data: mealPlan,
    isLoading: mealPlanIsLoading,
    isFetching: mealPlanIsFetching,
    refetch,
  } = useGetMealPlanQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const hId = CurrentProperty();
  const [addRatePlan] = useAddMealPlanMutation();
  const [editMealPlan] = useEditMealPlanMutation();
  const [deleteMealPlan] = useDeleteMealPlanMutation();

  const handleAddMealPlanClick = () => {
    setIsAddMeal(true);
  };
  const handleDelete = (mealPlanId) => {
    deleteMealPlan({ mealPlanId: mealPlanId, hId: hId })
      .then((res) => {
        toast.success("Meal Plan Delete Successfully!", {
          position: "bottom-right",
        });
        refetch();
      })
      .catch((err) => {
      });
  };
  const column = [
    {
      name: "Meal Plan Name",
      selector: (row) => row["mealPlanName"],
    },
    {
      name: "Meal Charges",
      selector: (row) => row["chargeDetails"]["mealPlancharge"],
    },
    {
      name: "Charge Rule",
      selector: (row) => row["chargeDetails"]["chargeRule"],
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onViewClick={() => {
              setMealplan(row);
              setIsView(true);
              setIsEdit(true);
            }}
            onEditClick={() => {
              setMealplan(row);
              setIsEdit(true);
            }}
            showDelete={true}
            handleDelete={() => handleDelete(row?.mealPlanId)}
            refetch={refetch}
          />
        );
      },
    },
  ];


  return (
    <>
      {isAddMeal || isEdit || isView ? (
        <AddMealPlan
          onClose={() => {
            setIsAddMeal(false);
            setIsEdit(false);
            setIsView(false);
          }}
          addRatePlan={addRatePlan}
          data={mealPlanData}
          isView={isView}
          isEdit={isEdit}
          editMealPlan={editMealPlan}
          refetch={refetch}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Meal Plans"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%]  bg-newGradient text-lightFontColor dark:bg-lightGradient  flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Meal Plans</p>
                <div className="flex items-center gap-2">
                  <Dropdown selectedItem={"Meal"} />
                  <TableButton
                    title={"Add New"}
                    onClick={handleAddMealPlanClick}
                  />
                </div>
              </div>
              {mealPlan?.data != null &&
                mealPlan?.data.length > 0 &&
                !mealPlanIsLoading &&
                !mealPlanIsFetching ? (
                <Table
                  columns={column}
                  pagination={true}
                  data={mealPlan?.data}
                />
              ) : (
                <CustomSkeleton height={400} width={"100%"} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MealPlan;

const AddMealPlan = ({
  onClose,
  addRatePlan,
  data: editData,
  isView,
  isEdit,
  editMealPlan,
  refetch,
}) => {
  const methods = useForm({});
  const [numberOfRoom, setNumberOfRoom] = useState(0);
  const [mealCharge, setMealCharge] = useState(0);
  const [selectedCharge, setSelectedCharge] = useState();
  const hId = CurrentProperty();
  const { data: mealPlanCharge } = useGetMealPlanChargeQuery(
    {
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const onSubmit = async (data) => {
    const obj = {
      hId: hId,
      mealPlanDetails: {
        mealPlanName: data?.mealPlanName,
        mealPlanShortCode: data?.shortCode,
        chargeDetails: {
          mealPlancharge: mealCharge,
          chargeRule: selectedCharge?.value,
        },
      },
    };
    const editParams = {
      hId: hId,
      mealPlanId: editData?.mealPlanId,
    };
    if (isEdit) {
      editMealPlan({ obj, editParams })
        .unwrap()
        .then((res) => {
          toast.success("Meal Plan Updated Successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => {
          onClose();
        });
    } else {
      addRatePlan(obj)
        .unwrap()
        .then((res) => {
          toast.success("Meal Plan Added Successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => onClose());
    }
  };
  const mealPlanChargeOption =
    mealPlanCharge &&
    mealPlanCharge?.data.map((item) => {
      return {
        label: item?.chargeRuleName,
        value: item?.chargeId,
      };
    });
  useEffect(() => {
    if (editData) {
      methods.setValue("mealPlanName", editData?.mealPlanName);
      methods.setValue("shortCode", editData?.mealPlanShortCode);
      setMealCharge(editData?.chargeDetails?.mealPlancharge);
      setSelectedCharge({
        label: editData?.chargeDetails?.chargeRule,
        value: editData?.chargeDetails?.chargeRule,
      });
    }
  }, [editData]);


  useEffect(() => {
    if (methods.watch('mealPlanName')) {
      methods.setValue('shortCode', GenerateShortCode(methods.watch('mealPlanName')))
    }

  }, [methods.watch('mealPlanName')])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
          <SettingButton
            text={"Save"}
            Title={"Add Meal Plan"}
            onClose={onClose}
            handleBackButtonClick={onClose}
            table={true}
            isView={isView}
          />

          <div className="flex gap-4 w-[100%] p-2 flex-col">
            <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-[#131926] text-[13px]">Meal Plan Details</p>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-[#8794AD] text-[12px]">Meal Plan Name</p>
                <div className="w-1/2">
                  <InputField
                    type={"text"}
                    className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                    label={false}
                    placeholder={"Meal Plan Name"}
                    id={"mealPlanName"}
                    name={"mealPlanName"}
                    controls={methods.control}
                    rules={{ required: "Meal Plan Name is required" }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-[#8794AD] text-[12px]">Short Code</p>
                <div className="w-1/2 rounded-lg ">
                  <InputField
                    type={"text"}
                    className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                    label={false}
                    placeholder={"Meal Plan Short Code"}
                    id={"shortCode"}
                    name={"shortCode"}
                    controls={methods.control}
                    rules={{ required: "Meal Plan Short Code is required" }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[50%]">
              <p className="text-[#131926] text-[13px]">Charge Details</p>
              <div className="flex gap-4 w-fit">
                <div className="w-1/2  flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Meal Plan Charge</p>
                  <DynamicCounter
                    initialValue={"₹ 3,000.00"}
                    count={mealCharge}
                    isChecked={"false"}
                    setCount={isView ? () => null : setMealCharge}
                  />
                </div>
                <div className="w-[300px] flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Charge Rule</p>
                  <Dropdown
                    selectedItem={selectedCharge?.label}
                    setSelectedItems={setSelectedCharge}
                    options={mealPlanChargeOption}
                    width={120}
                    height={36}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
