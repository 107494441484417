import React, { useState } from 'react'
import Modal from '../modal'
import { format } from 'date-fns';

const DynamicModal = (
    {
        title,
        subtitle,
        subtitle2,
        subtitle3,
        subtitle4,
        modalOpen,asPer
    }
) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    // const data = [
    //     {
    //       title: "OTAs",
    //       otaName: "All",
    //       otaName: " MakeMyTrip",
    //       otaName: "Agoda",
    //       otaName: "Expedia",
    //       otaName: "Goibibo"
    //     },
    //     {
    //       subtitle3: "Room Name",
          
    //       },
    //       {
    //       subtitle3: "Room Type",
        
    //       },
    //       {
    //       subtitle3: "Room Name",
          
    //       },
    //       {
    //       subtitle3: "Room Name",
         
    //       },
    //       {
    //         subtitle3: "Room Name",
            
    //         },
    //         {
    //             subtitle3: "Room Name",
                
    //             },
    //     // Add more items as needed
    //   ];
    return (
        <>
            <Modal>
                <div className='flex flex-col items-start h-[80%] w-[50%] rounded-lg bg-white p-4 relative'>
                    <div className='flex gap-2 mb-2'>
                        <div className='h-12 rounded-md w-5 bg-[#B1E5FC]'></div>
                  <div className='flex flex-col'>
                  <div className=' flex items-start  w-full text-xl font-semibold teaxt-black '>  <p>{title} <span className='text-[12px] text-gray-500'>{asPer && format(new Date(asPer),"dd MMM','yy")}</span></p></div>
                    <div className=' flex items-start  w-full text-base  mb-1 text-neutral-400'> <p>{subtitle}</p>
                    </div>
                  </div>
                    </div>
                   
                    <div className=' w-full mb-2 '>
                        <div className='flex items-start  w-full text-xl font-semibold teaxt-black m-2'><p>{subtitle2 || "Time Period"}</p>
                        </div>
                        <div className=' flex gap-3 ml-2'>
                            <div className={` text-[13px] hover:boder hover:border-[#2A85FF]  hover:text-[#2A85FF] font-medium rounded-md cursor-pointer flex items-center justify-center h-9 w-auto px-2 border border-gray-200 `}
                            >
                                15 days
                            </div>
                            <div className={` text-[13px] hover:boder hover:border-[#2A85FF]  hover:text-[#2A85FF] font-medium rounded-md cursor-pointer flex items-center justify-center h-9 w-auto px-2 border border-gray-200 `}
                            >
                                30 days
                            </div>
                            <div className={` text-[13px] hover:boder hover:border-[#2A85FF]  hover:text-[#2A85FF] font-medium rounded-md cursor-pointer flex items-center justify-center h-9 w-auto px-2 border border-gray-200 `}
                            >
                                60 days
                            </div>
                            <div className={` text-[13px] hover:boder hover:border-[#2A85FF]  hover:text-[#2A85FF] font-medium rounded-md cursor-pointer flex items-center justify-center h-9 w-auto px-2 border border-gray-200 `}
                            >
                                custom
                            </div>

                        </div>
                    </div>
                
                      
                          <div className=' w-full mb-2'>
                        <div className='flex items-start  w-full text-xl font-semibold teaxt-black m-2'><p>{subtitle3 || "OTAs"}</p>
                        </div>
                        <div className='flex gap-2 ml-2'>
                            <div className='text-[13px] gap-1 font-medium rounded-md cursor-pointer flex items-center justify-center h-9 px-2 border border-gray-200'>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />

                                </label>
                                <p>{isChecked ? 'Checked!' : 'Unchecked'}</p>
                            </div>
                            <div className='px-2 text-[13px] gap-1 font-medium rounded-md cursor-pointer flex items-center justify-center h-9 w-auto border border-gray-200'>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                </label>
                                <p>{isChecked ? 'MakeMyTrip' : 'Unchecked'}</p>
                            </div>
                        </div>

                    </div>
                  
                  
                    <div className=' w-full  '>
                        <div className='flex items-start  w-full text-xl font-semibold teaxt-black m-2'><p>{subtitle4 || "Download Format"}</p>
                        </div>
                        <div className=' flex gap-3 ml-2'>
                            <div className={` px-2 text-[13px] font-medium rounded-md cursor-pointer hover:bg-blue-600 hover:text-white flex items-center justify-center h-9 w-auto border border-gray-200 `}
                            >
                            PDF
                            </div>
                            <div className={`px-2 text-[13px] font-medium rounded-md cursor-pointer flex items-center hover:bg-blue-600 hover:text-white justify-center h-9 w-auto border border-gray-200 `}
                            >
                            Excel
                            </div>
                            <div className={` px-2 text-[13px] font-medium rounded-md cursor-pointer flex items-center hover:bg-blue-600 hover:text-white justify-center h-9 w-auto border border-gray-200 `}
                            >
                           Image (JPG)
                            </div>
                           

                        </div>
                    </div>
                    <div className=' absolute bottom-2 right-4 flex gap-3'>
                        <span className=' text-neutral-600 font-semibold h-8 w-16  flex items-center justify-center cursor-pointer border border-gray-200 rounded-lg' onClick={modalOpen}  >Cancel</span>
                        <span className='text-white flex items-center justify-center bg-[#2A85FF]  h-8 w-32 rounded-lg'>Download Now</span>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default DynamicModal