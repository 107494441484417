import React, { useState } from "react";
import SegmentReportTable from "../../newTable/SegmentReportTable";
import Calendar from "../../../components/calendar";
import { AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import { formatDate } from "../DownloadExcel/formatDate";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { format } from "date-fns";

const SegmentReport = ({
  data,
  setSelected2Date,
  selected2Date,
  setIsWhatsApp,
  isLoading
}) => {
  // const { weeklyArrivals, weeklyReservation, weeklyRoomNights } = data;
  const dateLimit=useIntelligenceDateRange();
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const fromDate = format( new Date(dateLimit?.fromDate), 'MM/dd/yyyy');
  const endDate = format( new Date(dateLimit?.toDate), 'MM/dd/yyyy');
  const propertyName = window.localStorage.getItem("propertyName");
  return (
    <>
      <div className="flex items-center justify-center p-2 font-semibold">
        <p className="sticky">Segment Report - {propertyName} - {`(${formatDate(data.asOn?.startDate)} - ${formatDate(data.asOn?.endDate)})`}</p>
      </div>
      <div className="w-full flex justify-end  ">
        <div className="m-3 w-auto flex justify-end ">
          <div className="w-fit">
           
          </div>
          <div
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="w-[100%]">
          <CustomSkeleton height={500} />
        </div>
      ) : (
        <div className="flex flex-col gap-2 overflow-y-scroll h-[90%] scrollHide">
          <SegmentReportTable
            arrival={true}
            data={data}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
        </div>
      )}
    </>
  );
};

export default SegmentReport;
