import React from "react";
import formatCurrency from "../../utils/formatCurrency";
import { useNavigate } from "react-router-dom";

const HorizontalCard = ({
  currentRate,
  suggestedRate,
  onClick,
  isOverride,
  overRideRate
}) => {
  const navigate = useNavigate();
  return (
    <div className="h-[79px] p-4 rounded-2xl flex flex-wrap justify-between items-center w-[100%] dark:bg-lightGradient  bg-newGradient drop-shadow-lg backdrop-blur-md">
     <div>
        <div className="text-[#ffffff] text-[12px] ">Current Rate</div>
        <div className="text-[#ffffff]">
          {currentRate && formatCurrency(currentRate)}
        </div>
      </div>
    <div>
        <div className="text-[14px] text-white">
          {"Rate Suggestion"}
        </div>
        <div className="text-[#29CC39] text-[17px] font-semibold">
          {suggestedRate && formatCurrency(suggestedRate)}
        </div>
      </div>
   {isOverride &&   <div>
        <div className="text-[#626E86] text-[12px] ">Override Rate</div>
        <div className="text-[#626E86]">
          {formatCurrency(overRideRate)}
        </div>
      </div>}
    
     
      <div>
        <div
          className="bg-[#0062ff] px-3 text-[12px] py-2 text-white rounded-xl cursor-pointer"
          onClick={onClick}
        >
          Accept
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;
