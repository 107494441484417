import React, { useState } from "react";
import RateVsCompetition from "../../test/RatevsCompetition";
import icon from "../../assets/icons/3.svg";
import DynamicModal from "../../components/dynamicModalComponent";
const RateLeaderboard = ({data,asPer}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

 
  return (
    <>
      {isModalOpen && (
        <DynamicModal
          title={"Download Report"}
          asPer={asPer}
          subtitle={
            "Download Report Historical Trend of your ranking on all Platforms"
          }
          modalOPen={() => {
            setIsModalOpen(false);
          }}
        />
      )}
      <div className="h-full">
        {/* <button    onClick={() => setIsModalOpen(true) }>on</button> */}
        <RateVsCompetition
          title={"Rate Leaderboard"}
          img={icon}
          data={data}
          isRateVisible={true}
          asPer={asPer}
          toolTipText={"This feature presents a comparative analysis of the hotel's pricing position relative to competitors within the market"}
        />
      </div>
    </>
  );
};

export default RateLeaderboard;
