import React, { useEffect, useRef, useState } from "react";
import ChannelCard from "../channelcard";
import SettingButton from "../../../components/settingButton/SettingButton";
import TableButton from "../../../components/settingButton/TableButton";
import Table from "../../../components/table";
import Tableactiontype from "../../../components/table/TableActionTYpe";
import InputField from "../../../components/inputFeild";
import {
  useAddRateThresholdMutation,
  useGetRateThresholdQuery,
  useUpdateRateThresholdMutation,
} from "../../../redux/slices/settings/rateSuggestion";
import { CurrentProperty } from "../../../utils/impVars";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { AiOutlineCaretDown } from "react-icons/ai";
import BackdropLoader from "../../../components/newLoader";
import {
  useAddCompsetRateRuleMutation,
  useAddDynamicRateRuleMutation,
  useGetCompsetRateRuleQuery,
  useGetDynamicRateRuleQuery,
  useGetPropertySeasonQuery,
  useUpdateCompsetRateRuleMutation,
  useUpdateDynamicRateRuleMutation,
} from "../../../redux/slices/rateSuggestions";
import { toast } from "react-toastify";
import { useGetCompsetDetailsQuery } from "../../../redux/slices/settings/myProperties";
import NoDataFound from "../../../components/noData";
// import InputField from "../../../components/inputFeild";

function parseIfContainsNumber(str) {
  // Use a regular expression to match any digit in the string
  const digitRegex = /\d+/;

  if (digitRegex.test(str) && typeof str === "string") {
    // If the string contains any digit, find the first sequence of digits and parse it
    const match = str?.match(digitRegex);
    return parseInt(match[0], 10);
  } else {
    // If the string does not contain any digits, return the original string
    return str;
  }
}

const SettingsRateSuggestions = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  const handleBackButtonClick = () => {
    setSelectedCard(null);
  };
  const renderComponent = () => {
    switch (selectedCard) {
      case "Rate Thresholds":
        return <RateThresholds handleBackButtonClick={handleBackButtonClick} />;
      case "Dynamic Rate Rules":
        return (
          <DynamicRateRules handleBackButtonClick={handleBackButtonClick} />
        );
      case "Competitive Rate Strategy":
        return (
          <CompetitiveRateStrategy
            handleBackButtonClick={handleBackButtonClick}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="text-[17px] text-[#ffffff] h-[44px] flex items-center dark:text-black mt-1">
            Dynamic Pricing
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <ChannelCard
                title={"Dynamic Pricing"}
                check={true}
                onChange={(e) => console.log(e.target.checked)}
              />
              <ChannelCard
                title={"Rate Thresholds"}
                onClick={() => handleCardClick("Rate Thresholds")}
              />
              <ChannelCard
                title={"Dynamic Rate Rules"}
                onClick={() => handleCardClick("Dynamic Rate Rules")}
              />
              <ChannelCard
                title={"Competitive Rate Strategy"}
                onClick={() => handleCardClick("Competitive Rate Strategy")}
              />
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};

export default SettingsRateSuggestions;

const RateThresholds = ({ onClose, handleBackButtonClick }) => {
  const { data: RateThreshold, refetch } = useGetRateThresholdQuery({
    hId: CurrentProperty(),
  });
  const thresholdData = RateThreshold && RateThreshold?.details;
  const [addRateThreshold, setAddRateThreshold] = useState(false);
  const [editData, setEditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const methods = useForm({});
  const column = [
    {
      name: "RoomId",
      selector: (row) => row["roomId"],
      width: "30%",
    },
    {
      name: "Minimum Rate",
      selector: (row) => row["minRate"],
      cell: (row) => {
        return (
          <div className="bg-[#eef1f6] rounded-lg p-2 hover:bg-[#CCD6E5]">
            {row["minRate"]}
          </div>
        );
      },
    },
    {
      name: "Base Rate",
      selector: (row) => row["baseRate"],
      cell: (row) => {
        return (
          <div className="bg-[#eef1f6] rounded-lg p-2 hover:bg-[#CCD6E5]">
            {row["baseRate"]}
          </div>
        );
      },
    },
    {
      name: "Maximum Rate",
      selector: (row) => row["maxRate"],
      cell: (row) => {
        return (
          <div className="bg-[#eef1f6] rounded-lg p-2 hover:bg-[#CCD6E5]">
            {row["maxRate"]}
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setEditData(row);
              setIsEdit(true);
            }}
            rule={true}
            reset={true}
          />
        );
      },
    },
  ];
  const data = [
    {
      name: "Delux Room",
      minimumRate: "12,780.00",
      baseRate: "12,780.00",
      maximumRate: "12,780.00",
    },
    {
      name: "Delux Room",
      minimumRate: "12,780.00",
      baseRate: "12,780.00",
      maximumRate: "12,780.00",
    },
    {
      name: "Delux Room",
      minimumRate: "12,780.00",
      baseRate: "12,780.00",
      maximumRate: "12,780.00",
    },
  ];

  return (
    <>
      {isEdit && (
        <RateThresholdModal
          hideModal={() => setIsEdit(false)}
          refetch={refetch}
          editData={editData}
          isEdit={isEdit}
        />
      )}
      {addRateThreshold && (
        <RateThresholdModal
          hideModal={() => setAddRateThreshold(false)}
          data={RateThreshold}
          refetch={refetch}
          onClose={onClose}
        />
      )}
      {!addRateThreshold && !isEdit && (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Rate Threshold"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Dynamic Pricing</p>
                <div className="flex items-center gap-2">
                  <TableButton
                    title={"Add New"}
                    onClick={() => setAddRateThreshold(true)}
                  />
                </div>
              </div>
              {thresholdData != null && (
                <Table
                  columns={column}
                  pagination={true}
                  data={thresholdData}
                />
              )}
              {RateThreshold?.code === 206 && (
                <div className="w-[100%]">
                  {" "}
                  <NoDataFound />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const RateThresholdModal = ({
  hideModal,
  onClose,
  refetch,
  editData,
  isEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({});
  const [addRateThreshold] = useAddRateThresholdMutation();
  const [updateRateThreshold] = useUpdateRateThresholdMutation();
  const hotelId = CurrentProperty();
  useEffect(() => {
    if (editData) {
      methods.setValue("rateThresholdName", editData["roomId"]);
      methods.setValue("rateThresholdMinRate", editData["minRate"]);
      methods.setValue("rateThresholdMaxRate", editData["maxRate"]);
      methods.setValue("rateThresholdBasicRate", editData["baseRate"]);
    }
  }, [editData]);
  const onSubmit = async (data) => {
    setIsLoading(true);
    const hId = hotelId;
    const formObj = {
      hId: hotelId,
      rooms: [
        {
          roomId: data?.rateThresholdName,
          minRate: data?.rateThresholdMinRate,
          maxRate: data?.rateThresholdMaxRate,
          baseRate: data?.rateThresholdBasicRate,
        },
      ],
    };

    if (isEdit) {
      setIsLoading(true);
      updateRateThreshold(formObj)
        .unwrap()
        .then((res) => {
          toast.success(res.message, { position: "bottom-right" });
          setIsLoading(false);
          hideModal();
          refetch();
        })
        .catch((error) => {
          toast.error(error?.data?.message, { position: "bottom-right" });
          setIsLoading(false);
          hideModal();
          console.error("Failed to update thresHold:", error);
        });
    } else {
      setIsLoading(true);
      addRateThreshold(formObj)
        .unwrap()
        .then((res) => {
          toast.success(res.message, { position: "bottom-right" });
          setIsLoading(false);
          hideModal();
          refetch();
        })
        .catch((error) => {
          toast.error(error?.data?.message, { position: "bottom-right" });
          setIsLoading(false);
          hideModal();
          console.error("Failed to post thresHold:", error);
        });
    }
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              text={"Save"}
              Title={"Add New Rate Threshold"}
              onClose={onClose}
              handleBackButtonClick={hideModal}
              table={true}
              onClick={methods.handleSubmit(onSubmit)}
            />
            <div className="flex gap-4 w-[100%] p-2 flex-col overflow-y-auto">
              <div className="flex flex-col gap-4 w-[100%]">
                <p className="text-[#131926] text-[13px]">Rate Plan Details</p>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">
                    Rate Threshold Name
                  </p>
                  <div className="w-1/2 ">
                    <InputField
                      type={"text"}
                      className={
                        "text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Enter Rate Threshold"}
                      id={"rateThresholdName"}
                      name={"rateThresholdName"}
                      controls={methods.control}
                      rules={{ required: "Enter Rate Threshold is required" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Minimum Rate</p>
                  <div className="w-1/2">
                    <InputField
                      type={"number"}
                      className={
                        "text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Enter Minimum Rate"}
                      id={"rateThresholdMinRate"}
                      name={"rateThresholdMinRate"}
                      controls={methods.control}
                      rules={{
                        required: "Minimum Rate is required",
                        min: {
                          value: 1,
                          message: "Value must be greater than or equal to 1",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Base Rate</p>
                  <div className="w-1/2 ">
                    <InputField
                      type={"number"}
                      className={
                        "text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Enter Base Rate"}
                      id={"rateThresholdBasicRate"}
                      name={"rateThresholdBasicRate"}
                      controls={methods.control}
                      rules={{
                        required: "Base Rate is required",
                        min: {
                          value: 1,
                          message: "Value must be greater than or equal to 1",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Maximum Rate</p>
                  <div className="w-1/2 ">
                    <InputField
                      type={"number"}
                      className={
                        "text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Enter Maximum Rate"}
                      id={"rateThresholdMaxRate"}
                      name={"rateThresholdMaxRate"}
                      controls={methods.control}
                      rules={{
                        required: "Maximum Rate is required",
                        min: {
                          value: 1,
                          message: "Value must be greater than or equal to 1",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      {/* <RateThresholdModal hideModal={() => setAddRateThreshold(false)} /> */}
    </>
  );
};

const CompetitiveRateStrategy = ({ handleBackButtonClick }) => {
  const [addCompsetRate, setAddCompsetRate] = useState(false);
  const { data: compsetRateRule, refetch } = useGetCompsetRateRuleQuery({
    hId: CurrentProperty(),
  });
  const [addCompsetRateRule] = useAddCompsetRateRuleMutation();
  const compsetData = compsetRateRule && compsetRateRule?.details;
  const [editData, setEditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const column = [
    {
      name: "Compset Name",
      selector: (row) => row["compsetId"],
      width: "30%",
    },
    {
      name: "Adjust",
      selector: (row) => row["adjustmentType"],
      cell: (row) => {
        return (
          <div className="flex">
            <p className="flex items-center h-[32px] px-4 rounded-lg text-white bg-[#2A85FF] font-[700]">
              {row["adjustmentType"]}
            </p>
            <p className="flex items-center h-[32px] px-4 text-[#2A85FF] font-[700]">
              %
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row) => {
        return (
          <div className="bg-[#eef1f6] rounded-lg p-2 px-4 hover:bg-[#CCD6E5]">
            {row["adjustmentValue"]}
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setEditData(row);
              setIsEdit(true);
            }}
            rule={true}
            reset={true}
          />
        );
      },
    },
  ];

  const data = [
    {
      compsetName: "Select Compset",
      adjust: "₹",
      amount: "+",
      adjustmentValue: "780",
    },
    {
      compsetName: "Select Compset",
      adjust: "₹",
      amount: "+",
      adjustmentValue: "780",
    },
  ];
  return (
    <>
      {isEdit && (
        <CompsetRateModal
          hideModal={() => setIsEdit(false)}
          refetch={refetch}
          editData={editData}
          isEdit={isEdit}
        />
      )}

      {addCompsetRate && (
        <CompsetRateModal
          hideModal={() => setAddCompsetRate(false)}
          data={compsetRateRule}
          refetch={refetch}
        />
      )}
      {!addCompsetRate && !isEdit && (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Compset Rate Rule"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Compset Rate Rule</p>
                <div className="flex items-center gap-2">
                  {/* <Dropdown selectedItem={"All Users"} /> */}
                  <TableButton
                    title={"Add Rule"}
                    onClick={() => setAddCompsetRate(true)}
                  />
                </div>
              </div>
              {compsetData != null && (
                <Table columns={column} pagination={true} data={compsetData} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CompsetRateModal = ({ hideModal, data, refetch, editData, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const hId = CurrentProperty();

  const { data: compsetsType } = useGetCompsetDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const [selectedProperty, setSelectedProperty] = useState(null);
  const compOptions =
    compsetsType &&
    compsetsType.data.compSetDetails.map((property) => ({
      value: property.hId,
      label: property.propertyName,
    }));

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
  };
  const [datatoPost, setDatatoPost] = useState([
    {
      compsetName: "",
      compsetId: "",
      adjustmentType: "",
      amountChangeType: "",
      adjustmentValue: "",
    },
  ]);

  const [Compsets, setCompsets] = useState();
  const [updateCompsetRateRule] = useUpdateCompsetRateRuleMutation();
  const [addCompsetRateRule] = useAddCompsetRateRuleMutation();
  const [temporaryComp, setTemporaryComp] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCompsetIds, setSelectedCompsetIds] = useState([]);
  const [removedValues, setRemovedValues] = useState([]);

  const handleEdit = (index, value, type) => {
    const data = [...datatoPost];

    if (type === "Percent") {
      if (value <= 100 && value >= 0) {
        if (data[index]) {
          const updatedObject = { ...data[index] };
          updatedObject.adjustmentValue = value;
          data[index] = updatedObject;
        }
      }
    } else {
      if (data[index]) {
        const updatedObject = { ...data[index] };
        updatedObject.adjustmentValue = value;
        data[index] = updatedObject;
      }
    }

    setDatatoPost(data);
  };

  const handleEditCompset = (index, comId, comName) => {
    let data = [...datatoPost];
    if (data[index]) {
      // Ensure the object is extensible before adding properties
      data[index] = Object.assign({}, data[index]);
      data[index].compsetName = comName;
      data[index].compsetId = comId;
    }
    setDatatoPost(data);
  };

  useEffect(() => {
    if (editData) {
      setDatatoPost(null);
      setDatatoPost([editData]);
    }
  }, [editData]);

  useEffect(() => {
    if (temporaryComp) {
      setCompsets(temporaryComp);
    }
    setTemporaryComp();
  }, [temporaryComp]);

  const handleAdd = (index, value) => {
    const data = [...datatoPost];
    const Add = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Add;
      data[index] = updatedObject;
    }
    setDatatoPost(data);
  };
  const handleSubstract = (index, value) => {
    const data = [...datatoPost];
    const Substract = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Substract;
      data[index] = updatedObject;
    }
    setDatatoPost(data);
  };
  const handlePercentage = (index, value) => {
    const data = [...datatoPost];
    const Percentage = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.adjustmentType = Percentage;
      data[index] = updatedObject;
    }
    setDatatoPost(data);
  };

  const obj = {
    hId: CurrentProperty(),
    compsets: datatoPost,
  };
  const handleNext = () => {
    const isEmptyField = datatoPost.some(
      (compset) => !compset.compsetId || !compset.adjustmentValue
    );

    if (isEmptyField) {
      toast.error("Please fill in all the fields.", {
        position: "bottom-right",
      });
      return; // Exit the function early if any field is empty
    }
    if (data && [data?.details].length > 0) {
      setIsLoading(true);
      updateCompsetRateRule(obj)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Update Compset Rate Rule Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else if (isEdit) {
      setIsLoading(true);
      updateCompsetRateRule(obj)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Update Compset Rate Rule Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else {
      addCompsetRateRule(obj)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Compset Rate Rule Added Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    }
  };

  const handleAddMore = () => {
    setDatatoPost((prevState) => [
      ...prevState,
      {
        compsetId: "",
        adjustmentValue: "",
        adjustmentType: "Percent",
        compsetName: "", // Make sure to include compsetName here when creating the new object
      },
    ]);
  };

  const handleDelete = (index) => {
    const removedValue = datatoPost[index].compsetName;
    setRemovedValues([...removedValues, removedValue]);

    const newData = datatoPost.filter((item, i) => i !== index);
    setDatatoPost(newData);
  };

  let filteredOptions;
  if (compOptions && Array.isArray(compOptions)) {
    filteredOptions = compOptions.filter((option) => {
      return (
        !datatoPost.some((item) => item.compsetId === option.value) ||
        removedValues.includes(option.value)
      );
    });
  } else {
    // Handle the case when compOptions is undefined or not an array
    console.error("compOptions is undefined or not an array");
  }

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4 bg-newGradient text-white dark:bg-lightGradient">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
      handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
    /> */}
            <div>Adjust Compset Rule</div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={hideModal}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              Save
            </div>
          </div>
        </div>
        <div className="w-[30%]">
          {datatoPost.map((compset, compsetIndex) => {
            return (
              <div
                className="flex gap-4 justify-between mt-8 items-center"
                key={compsetIndex}
              >
                <div>
                  {/* <div className="text-md">Created Level By</div> */}
                  <div className="flex justify-between gap-4 items-center">
                    <div
                      onClick={() => {
                        setSelectedIndex(compsetIndex);
                        setIsOpen(!isOpen);
                      }}
                      className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    >
                      <div className="flex items-center justify-between text-gray-600">
                        <div>
                          {compset?.compsetName
                            ? compset?.compsetName
                            : "Select Compsets"}
                        </div>
                        <div>
                          <AiOutlineCaretDown />
                        </div>
                        {isOpen && selectedIndex === compsetIndex && (
                          <div className="absolute -bottom-[60px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] -ml-2 rounded-md">
                            {Array.isArray(filteredOptions) &&
                              filteredOptions.map((option, ind) => {
                                // const isOptionSelected = selectedCompsetIds.includes(option.value);
                                return (
                                  <div
                                    key={ind}
                                    className={`px-4 py-2 hover:bg-gray-100 text-sm cursor-pointer
                                    `}
                                    onClick={() => {
                                      handleEditCompset(
                                        compsetIndex,
                                        option?.value,
                                        option?.label
                                      );
                                      setIsOpen(false);
                                    }}
                                  >
                                    {option?.label}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    value={compset?.adjustmentValue}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (/^\d*\.?\d*$/.test(newValue)) {
                        handleEdit(
                          compsetIndex,
                          newValue,
                          compset.adjustmentType,
                          compset.amountChangeType
                        );
                      }
                    }}
                    className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    placeholder="Adjust Value"
                  />
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    compset.amountChangeType === "Substract"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleSubstract(compsetIndex, "Substract");
                  }}
                >
                  -
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    compset.amountChangeType === "Add"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleAdd(compsetIndex, "Add");
                  }}
                >
                  +
                </div>

                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    compset.adjustmentType === "Percent"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handlePercentage(compsetIndex, "Percent");
                  }}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    compset.adjustmentType === "Amount"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handlePercentage(compsetIndex, "Amount");
                  }}
                >
                  ₹
                </div>
                <div
                  onClick={() => handleDelete(compsetIndex)}
                  className="bg-red-50/50 text-red-500 hover:bg-red-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer border border-red-500 px-4 py-1 rounded-lg"
                >
                  x
                </div>
              </div>
            );
          })}
        </div>
        {!isEdit && (
          <div
            onClick={handleAddMore}
            className="text-sm mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg w-24 cursor-pointer p-2"
          >
            + Add More{" "}
          </div>
        )}
      </div>
    </>
  );
};

const DynamicRateRules = ({ handleBackButtonClick }) => {
  const hId = CurrentProperty();
  const [addDynamicRateRulesOccupency, setAddDynamicRateRulesOccupency] =
    useState(false);
  const [addSeasonalRule, setAddSeasonalRule] = useState(false);
  const [addFPLOS, setAddFPLOS] = useState(false);
  const [addRoomRange, setAddRoomRange] = useState(false);
  const [addBookingWindowRule, setAddBookingWindowRule] = useState(false);
  const [addDayOfWeekRule, setDayOfWeekRule] = useState(false);
  const [addDemandBasedOnRule, setAddDemandBasedOnRule] = useState(false);
  const { data: dynamicRateRule, refetch } = useGetDynamicRateRuleQuery({
    hId: CurrentProperty(),
  });
  const [dynamicRateRuleState, setDynamicRateRuleState] = useState();

  useEffect(() => {
    if (dynamicRateRule) {
      setDynamicRateRuleState(dynamicRateRule);
    }
  }, [dynamicRateRule]);
  const occupancyData =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.occupancyLevel?.levels;
  const dayweekruleData =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.dayOfWeekRule?.levels;
  const demandrateruleData =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.demandBasedRateRule?.levels;
  const seasonalBasedRuleData =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.seasonalBasedRateRule?.levels;
  const fullPatternRoomBase_Data =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.fplosRateRule?.roomsBasedRateRule?.levels;
  const fullPatternLOS_Data =
    dynamicRateRuleState &&
    dynamicRateRuleState?.details?.fplosRateRule?.losBasedRateRule?.levels;
  const [editData, setEditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editData1, setEditData1] = useState();
  const [isEdit1, setIsEdit1] = useState(false);
  const [editData2, setEditData2] = useState();
  const [isEdit2, setIsEdit2] = useState(false);
  const [seasonalEditData, setSeasonalEditData] = useState();
  const [seasonalIsEditData, setSeasonalIsEditData] = useState(false);
  const [isEditFPLOS, setIsEditFPLOS] = useState(false);
  const [editFPLOS_Data, setEditFPLOS_Data] = useState();
  const [selectedId, setSelectedId] = useState();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();

  // For Season Data Starts

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleEditSeasonDays = (index, value, name) => {
    const updatedDaysOfWeek = [...daysOfWeek];
    if (updatedDaysOfWeek[index]) {
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], day: value };
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], dayName: name };
    }

    // Filter out the selected day from the Days array
    // const updatedDays = Days.filter((day) => day.label !== value);

    // setDays(updatedDays);
    // setTemporaryDays(updatedDays);
    // setDaysOfWeek(updatedDaysOfWeek);
  };

  const { data: allSeason } = useGetPropertySeasonQuery({
    hId: hId,
  });
  const [SeasonDays, setSeasonDays] = useState();
  useEffect(() => {
    if (allSeason && allSeason?.data) {
      const newSeason = allSeason?.data?.map((item) => {
        return {
          label: item?.seasonName,
          value: item?.seasonId,
        };
      });
      setSeasonDays(newSeason);
    }
  }, [allSeason]);

  useEffect(() => {
    const newDay =
      dynamicRateRule &&
      SeasonDays?.filter((item) => {
        return !dynamicRateRule?.details?.seasonalBasedRateRule?.levels?.some(
          (item2) => {
            return item2.seasonId === item?.value;
          }
        );
      });
    if (newDay) {
      setSeasonDays(newDay);
    }
  }, [dynamicRateRule]);

  // Season Data Ends

  // Days of week starts
  const [Days, setDays] = useState([
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ]);

  const handleEditDays = (index, value) => {
    const updatedDaysOfWeek = [...daysOfWeek];
    if (updatedDaysOfWeek[index]) {
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], day: value };
    }

    // Filter out the selected day from the Days array
    // const updatedDays = Days.filter((day) => day.label !== value);

    // setDays(updatedDays);
    // setTemporaryDays(updatedDays);
  };

  useEffect(() => {
    const newDay =
      dynamicRateRule &&
      Days?.filter((item) => {
        return !dynamicRateRule?.details?.dayOfWeekRule?.levels?.some(
          (item2) => {
            return item2.day === item?.label;
          }
        );
      });
    if (newDay) {
      setDays(newDay);
    }
  }, [dynamicRateRule]);

  // End of days of week
  const sendDataToUpdate = dynamicRateRuleState && {
    hId: dynamicRateRuleState?.details?.hId,
    bookingWindowRule: dynamicRateRuleState?.details?.bookingWindowRule,
    dayOfWeekRule: dynamicRateRuleState?.details?.dayOfWeekRule,
    demandBasedRateRule: dynamicRateRuleState?.details?.demandBasedRateRule,
    fplosRateRule: dynamicRateRuleState?.details?.fplosRateRule,
    occupancyLevel: dynamicRateRuleState?.details?.occupancyLevel,
    seasonalBasedRateRule: dynamicRateRuleState?.details?.seasonalBasedRateRule,
  };

  const handleSubmit = () => {
    updateDynamicRateRule(sendDataToUpdate)
      .unwrap()
      .then((res) => {
        refetch();
        toast.success("Updated Successfully !", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong !", {
          position: "bottom-right",
        });
      });
  };

  const handChange = (value, ruleType, index, name) => {
    setDynamicRateRuleState((prevState) => {
      // Create a deep copy of the previous state to avoid direct mutations
      const newState = JSON.parse(JSON.stringify(prevState));

      // Determine the path to the levels array
      let levelsPath;
      switch (ruleType) {
        case "occupancyLevel":
          levelsPath = newState.details.occupancyLevel.levels;
          break;
        case "bookingWindowRule":
          levelsPath = newState.details.bookingWindowRule.levels;
          break;
        case "dayOfWeekRule":
          levelsPath = newState.details.dayOfWeekRule.levels;
          break;
        case "demandBasedRateRule":
          levelsPath = newState.details.demandBasedRateRule.levels;
          break;
        case "seasonalBasedRateRule":
          levelsPath = newState.details.seasonalBasedRateRule.levels;
          break;
        case "losBasedRateRule":
          levelsPath = newState.details.fplosRateRule.losBasedRateRule.levels;
          break;
        case "roomsBasedRateRule":
          levelsPath = newState.details.fplosRateRule.roomsBasedRateRule.levels;
          break;
        default:
          throw new Error("Unknown rule type");
      }
      // Update the specific level
      levelsPath[index] = {
        ...levelsPath[index],
        [name]:
          (ruleType === "seasonalBasedRateRule" && name === "seasonId") ||
          (ruleType === "seasonalBasedRateRule" && name === "seasonName")
            ? value
            : parseIfContainsNumber(value),
      };

      return newState;
    });
  };

  var daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const column1 = [
    {
      name: "Occupancy Level",
      width: "50%",
      cell: (row, index) => {
        return (
          <div>
            <p className="flex gap-2 items-center">
              From
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.occupancyLevel?.levels[index]
                      ?.fromLevel
                  }
                  onChange={(e) =>
                    handChange(
                      parseInt(e.target.value),
                      "occupancyLevel",
                      index,
                      "fromLevel"
                    )
                  }
                />
              </span>{" "}
              to
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.occupancyLevel?.levels[index]
                      ?.toLevel
                  }
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const fromLevel = parseInt(
                      dynamicRateRuleState?.details?.occupancyLevel?.levels[
                        index
                      ]?.fromLevel
                    );

                    value >= fromLevel
                      ? handChange(value, "occupancyLevel", index, "toLevel")
                      : console.log("Value is less than fromLevel");
                  }}
                />
              </span>{" "}
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.occupancyLevel?.levels[index]
                  ?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Value",
                    "occupancyLevel",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.occupancyLevel?.levels[index]
                  ?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "occupancyLevel",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.occupancyLevel?.levels[index]
                  ?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "occupancyLevel",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setSelectedId(row?._id);
            }}
            rule={true}
            reset={true}
            selectedId={selectedId === row?._id ? true : false}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];

  const column3 = [
    {
      name: "Days",
      selector: (row) => row["day"],
      width: "50%",
      cell: (row, index) => {
        return (
          <div className="relative z-20">
            {selectedId === row?._id ? (
              <div className="flex justify-between gap-4 items-center">
                <select
                  className="p-0 w-[200px] pl-2 focus:outline-none rounded-md py-2 flex justify-between"
                  onChange={(e) => {
                    handChange(e.target.value, "dayOfWeekRule", index, "day");
                  }}
                >
                  {Days.map((option, ind) => (
                    <option
                      className="px-4 py-2 hover:bg-gray-100 text-sm"
                      value={option?.value}
                      onClick={() => {
                        handleEditDays(index, option?.value, option?.label);
                        setIsOpen(false);
                      }}
                      key={ind}
                    >
                      {option?.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              `${row?.day}`
            )}
          </div>
        );
      },
    },
    {
      name: "Adjustment",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.dayOfWeekRule?.levels[index]
                  ?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange("Value", "dayOfWeekRule", index, "adjustmentType");
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.dayOfWeekRule?.levels[index]
                  ?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "dayOfWeekRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.dayOfWeekRule?.levels[index]
                  ?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "dayOfWeekRule",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              // setSeasonalEditData(row);
              // setSeasonalIsEditData(true);
              setSelectedId(row?._id);
            }}
            rule={true}
            reset={true}
            selectedId={row?._id === selectedId ? true : false}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];
  const column4 = [
    {
      name: "Demand Strength Range",
      width: "50%",
      cell: (row, index) => {
        return (
          <div>
            <p className="flex gap-2 items-center">
              From
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.demandBasedRateRule?.levels[
                      index
                    ]?.fromLevel
                  }
                  onChange={(e) =>
                    handChange(
                      parseInt(e.target.value),
                      "demandBasedRateRule",
                      index,
                      "fromLevel"
                    )
                  }
                />
              </span>{" "}
              to
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.demandBasedRateRule?.levels[
                      index
                    ]?.toLevel
                  }
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const fromLevel = parseInt(
                      dynamicRateRuleState?.details?.demandBasedRateRule
                        ?.levels[index]?.fromLevel
                    );

                    value >= fromLevel
                      ? handChange(
                          value,
                          "demandBasedRateRule",
                          index,
                          "toLevel"
                        )
                      : console.log("Value is less than fromLevel");
                  }}
                />
              </span>{" "}
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.demandBasedRateRule?.levels[
                  index
                ]?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Value",
                    "demandBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.demandBasedRateRule?.levels[
                  index
                ]?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "demandBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.demandBasedRateRule?.levels[
                  index
                ]?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "demandBasedRateRule",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setSelectedId(row?._id);
            }}
            rule={true}
            reset={true}
            selectedId={selectedId === row?._id ? true : false}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];
  const column5 = [
    {
      name: "Season Name",
      width: "50%",
      cell: (row, index) => {
        return (
          <div className="relative z-20">
            {selectedId === row?._id ? (
              <div className="flex justify-between gap-4 items-center">
                {/* <div
              onClick={() => {
                setSelectedIndex(index);
                setIsOpen(!isOpen);
              }}
              className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
            >
              <div className="flex items-center justify-between text-gray-600">
                <div>
                  {row?.seasonName ? row?.seasonName : "Select Season"}
                </div>
                <div>
                  <AiOutlineCaretDown />
                </div>
              </div>
              {isOpen && selectedId === row?._id && (
                <div className="absolute left-0 top-full z-50 bg-gray-50 duration-150 transition-all ease-in-out cursor-pointer w-full max-h-[200px] overflow-y-scroll mt-2 rounded-md shadow-lg">
                  {Days.map((option, ind) => (
                    <div
                      className="px-4 py-2 hover:bg-gray-100 text-sm"
                      onClick={() => {
                        handleEditDays(index, option?.value, option?.label);
                        setIsOpen(false);
                      }}
                      key={ind}
                    >
                      {option?.label}
                    </div>
                  ))}
                </div>
              )}
            </div> */}
                <select
                  className="p-0 w-[200px] pl-2 focus:outline-none rounded-md py-2 flex justify-between"
                  onChange={(e) => {
                    handChange(
                      e.target.value,
                      "seasonalBasedRateRule",
                      index,
                      "seasonId"
                    );
                  }}
                >
                  {SeasonDays.map((option, ind) => (
                    <option
                      className="px-4 py-2 hover:bg-gray-100 text-sm"
                      value={option?.value}
                      onClick={() => {
                        handleEditSeasonDays(
                          index,
                          option?.value,
                          option?.label
                        );
                        setIsOpen(false);
                      }}
                      key={ind}
                    >
                      {option?.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              `${row?.seasonName}`
            )}
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.seasonalBasedRateRule?.levels[
                  index
                ]?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Value",
                    "seasonalBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.seasonalBasedRateRule?.levels[
                  index
                ]?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "seasonalBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.seasonalBasedRateRule?.levels[
                  index
                ]?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "seasonalBasedRateRule",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              // setSeasonalEditData(row);
              // setSeasonalIsEditData(true);
              setSelectedId(row?._id);
            }}
            rule={true}
            reset={true}
            selectedId={row?._id === selectedId ? true : false}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];
  const column6 = [
    {
      name: "LOS Range",
      width: "50%",
      cell: (row) => {
        return (
          <div>
            <p className="flex gap-2 items-center">
              From
              <span className="bg-[#eef1f6] rounded-lg p-2 px-4 font-[500]">
                {`${row?.fromLevel} `}
              </span>{" "}
              to
              <span className="bg-[#eef1f6] rounded-lg p-2 px-4 font-[500]">
                {row?.toLevel}
              </span>{" "}
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      // cell: (row) => {
      //   return (
      //     <div className="flex">
      //       <p className="flex items-center h-[32px] px-4 rounded-lg text-white bg-[#2A85FF] font-[700]">
      //       {row["occupancyLevel"]["levels"]["adjustmentType"]}
      //       </p>
      //       <p className="flex items-center h-[32px] px-4 text-[#2A85FF] font-[700]">
      //         %
      //       </p>
      //     </div>
      //   );
      // },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      // cell: (row) => {
      //   return (
      //     <div className="bg-[#eef1f6] rounded-lg p-2 px-4 hover:bg-[#CCD6E5]">
      //       {row["details"]["occupancyLevel"]["levels"]["adjustmentValue"]}
      //     </div>
      //   );
      // },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              // setEditFPLOS_Data(row);
              setIsEditFPLOS(true);
            }}
            rule={true}
            reset={true}
          />
        );
      },
    },
  ];
  const column7 = [
    {
      name: "Room Range",
      width: "50%",
      cell: (row, index) => {
        return (
          <div>
            <p className="flex gap-2 items-center">
              From
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.fplosRateRule
                      ?.roomsBasedRateRule?.levels[index]?.fromRooms
                  }
                  onChange={(e) =>
                    handChange(
                      e.target.value,
                      "roomsBasedRateRule",
                      index,
                      "fromRooms"
                    )
                  }
                />
              </span>{" "}
              to
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.fplosRateRule
                      ?.roomsBasedRateRule?.levels[index]?.toRooms
                  }
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const fromLos = parseInt(
                      dynamicRateRuleState?.details?.fplosRateRule
                        ?.roomsBasedRateRule?.levels[index]?.fromRooms
                    );

                    value >= fromLos
                      ? handChange(
                          parseInt(e.target.value),
                          "roomsBasedRateRule",
                          index,
                          "toRooms"
                        )
                      : console.log("Value is less than fromRooms");
                  }}
                />
              </span>{" "}
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.fplosRateRule?.roomsBasedRateRule
                  ?.levels[index]?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Value",
                    "roomsBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.fplosRateRule?.roomsBasedRateRule
                  ?.levels[index]?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "roomsBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.fplosRateRule?.roomsBasedRateRule
                  ?.levels[index]?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "roomsBasedRateRule",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row, index) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setSelectedId(row?._id);
              // setIsEditFPLOS(row);
              // setEditFPLOS_Data(row);
            }}
            rule={true}
            reset={true}
            selectedId={row?._id === selectedId}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];

  const column8 = [
    {
      name: "LOS Range",
      width: "50%",
      cell: (row, index) => {
        return (
          <div>
            <p className="flex gap-2 items-center">
              From
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.fplosRateRule
                      ?.losBasedRateRule?.levels[index]?.fromLos
                  }
                  onChange={(e) =>
                    handChange(
                      e.target.value,
                      "losBasedRateRule",
                      index,
                      "fromLos"
                    )
                  }
                />
              </span>{" "}
              to
              <span className="">
                <input
                  type="number"
                  disabled={selectedId === row?._id ? false : true}
                  className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
                  value={
                    dynamicRateRuleState?.details?.fplosRateRule
                      ?.losBasedRateRule?.levels[index]?.toLos
                  }
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const fromLos = parseInt(
                      dynamicRateRuleState?.details?.fplosRateRule
                        ?.losBasedRateRule?.levels[index]?.fromLos
                    );

                    value >= fromLos
                      ? handChange(
                          parseInt(e.target.value),
                          "losBasedRateRule",
                          index,
                          "toLos"
                        )
                      : console.log("Value is less than fromLOS");
                  }}
                />
              </span>{" "}
            </p>
          </div>
        );
      },
    },
    {
      name: "Adjustment Type",
      selector: (row) => row["adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex">
            <p
              className={` ${
                dynamicRateRuleState?.details?.fplosRateRule?.losBasedRateRule
                  ?.levels[index]?.adjustmentType === "Value"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } flex items-center h-[32px] px-4 rounded-lg cursor-pointer   font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Value",
                    "losBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              ₹
            </p>
            <p
              className={` ${
                dynamicRateRuleState?.details?.fplosRateRule?.losBasedRateRule
                  ?.levels[index]?.adjustmentType === "Percent"
                  ? "bg-[#2A85FF] text-white"
                  : "text-[#2A85FF]"
              } rounded-lg  flex items-center h-[32px] px-4 cursor-pointer font-[700]`}
              onClick={() => {
                selectedId === row?._id &&
                  handChange(
                    "Percent",
                    "losBasedRateRule",
                    index,
                    "adjustmentType"
                  );
              }}
            >
              %
            </p>
          </div>
        );
      },
    },

    {
      name: "Adjustment Value",
      selector: (row) => row["adjustmentValue"],
      cell: (row, index) => {
        return (
          <div className="">
            <input
              type="number"
              disabled={selectedId === row?._id ? false : true}
              className={`${selectedId === row?._id ? "bg-white" : "bg-[#eef1f6]"} rounded-lg p-2 px-4 font-[500]`}
              value={
                dynamicRateRuleState?.details?.fplosRateRule?.losBasedRateRule
                  ?.levels[index]?.adjustmentValue
              }
              onChange={(e) => {
                handChange(
                  parseInt(e.target.value),
                  "losBasedRateRule",
                  index,
                  "adjustmentValue"
                );
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              setSelectedId(row?._id);
              // setIsEditFPLOS(row);
              // setEditFPLOS_Data(row);
            }}
            rule={true}
            reset={true}
            selectedId={row?._id === selectedId}
            onClickUpdate={() => {
              handleSubmit();
              setSelectedId(null);
            }}
          />
        );
      },
    },
  ];

  // console.log("dfghjklkjhg",dynamicRateRule && [dynamicRateRule?.details].length)
  return (
    <>
      {isEdit && (
        <DynamicRateRulesModal
          hideModal={() => setIsEdit(false)}
          refetch={refetch}
          editData={editData}
          isEdit={isEdit}
        />
      )}

      {addDynamicRateRulesOccupency && (
        <div>
          {" "}
          <DynamicRateRulesModal
            hideModal={() => setAddDynamicRateRulesOccupency(false)}
            data={dynamicRateRule}
            refetch={refetch}
          />
        </div>
      )}

      {isEdit2 && (
        <DemandBasedRule
          hideModal={() => setIsEdit2(false)}
          refetch={refetch}
          editData={editData2}
          isEdit={isEdit2}
        />
      )}
      {addDemandBasedOnRule && (
        <DemandBasedRule
          hideModal={() => setAddDemandBasedOnRule(false)}
          data={dynamicRateRule}
          refetch={refetch}
        />
      )}

      {addBookingWindowRule && <div> Booking window rule</div>}

      {isEdit1 && (
        <AddDayWeekRule
          hideModal={() => setIsEdit1(false)}
          refetch={refetch}
          editData={editData1}
          isEdit={isEdit1}
        />
      )}
      {addDayOfWeekRule && (
        <AddDayWeekRule
          hideModal={() => setDayOfWeekRule(false)}
          data={dynamicRateRule}
          refetch={refetch}
        />
      )}
      {(addSeasonalRule || seasonalIsEditData) && (
        <AddSeasonRule
          data={dynamicRateRule}
          refetch={refetch}
          hideModal={
            seasonalIsEditData
              ? () => setSeasonalIsEditData(false)
              : () => {
                  setAddSeasonalRule(false);
                }
          }
          editData={seasonalEditData}
          isEdit={seasonalIsEditData}
        />
      )}
      {(addFPLOS || addRoomRange) && (
        <FPLOSBasedRule
          data={dynamicRateRule}
          refetch={refetch}
          roomRange={addRoomRange ? true : false}
          editData={editFPLOS_Data}
          isEdit={isEditFPLOS}
          hideModal={
            addRoomRange
              ? () => {
                  setAddRoomRange(false);
                }
              : () => {
                  setAddFPLOS(false);
                }
          }
        />
      )}

      {!addDynamicRateRulesOccupency &&
        !addDemandBasedOnRule &&
        !addBookingWindowRule &&
        !addDayOfWeekRule &&
        !addSeasonalRule &&
        !addRoomRange &&
        !addFPLOS &&
        !seasonalIsEditData &&
        !isEditFPLOS &&
        !isEdit &&
        !isEdit1 &&
        !isEdit2 && (
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              Title={"Dynamic Rate Rules"}
              table={true}
              handleBackButtonClick={handleBackButtonClick}
            />
            <div className="flex flex-col gap-4 w-[100%] overflow-y-auto rounded-xl">
              <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">Occupancy Level</p>
                    <div className="flex items-center gap-2">
                      {/* <Dropdown selectedItem={"All Users"} /> */}
                      <TableButton
                        title={"Add Occupany Level"}
                        onClick={() => setAddDynamicRateRulesOccupency(true)}
                      />
                    </div>
                  </div>
                  {occupancyData != null && (
                    <Table
                      columns={column1}
                      pagination={true}
                      data={occupancyData}
                    />
                  )}
                </div>
              </div>
              {/* <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">
                      Booking Window Rule
                    </p>
                    <div className="flex items-center gap-2">
                      <Dropdown selectedItem={"All Users"} />
                      <TableButton
                        title={"Add User"}
                        onClick={() => setAddBookingWindowRule(true)}
                      />
                    </div>
                  </div>
                  {data2 != null && (
                    <Table columns={column2} pagination={true} data={data2} />
                  )}
                </div>
              </div> */}
              <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">Day of Week Rule</p>
                    <div className="flex items-center gap-2">
                      {/* <Dropdown selectedItem={"All Users"} /> */}
                      <TableButton
                        title={"Add Day Of Week Rule"}
                        onClick={() => setDayOfWeekRule(true)}
                      />
                    </div>
                  </div>
                  {dayweekruleData != null && (
                    <Table
                      columns={column3}
                      pagination={true}
                      data={dayweekruleData}
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">
                      Demand Based Rate Rule
                    </p>
                    <div className="flex items-center gap-2">
                      {/* <Dropdown selectedItem={"All Users"} /> */}
                      <TableButton
                        title={"Add Demand Rate Rule"}
                        onClick={() => setAddDemandBasedOnRule(true)}
                      />
                    </div>
                  </div>
                  {demandrateruleData != null && (
                    <Table
                      columns={column4}
                      pagination={true}
                      data={demandrateruleData}
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">Seasonal Pricing</p>
                    <div className="flex items-center gap-2">
                      {/* <Dropdown selectedItem={"All Users"} /> */}
                      <TableButton
                        title={"Add Seasonal Pricing"}
                        onClick={() => setAddSeasonalRule(true)}
                      />
                    </div>
                  </div>
                  {seasonalBasedRuleData != null && (
                    <Table
                      columns={column5}
                      pagination={true}
                      data={seasonalBasedRuleData}
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-2 w-[100%]">
                <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[14px] font-[500]">
                      Full Pattern Length Of Stay
                    </p>
                    <div className="flex items-center gap-2">
                      {/* <Dropdown selectedItem={"All Users"} /> */}
                      <TableButton
                        title={"Add LOS"}
                        onClick={() => setAddFPLOS(true)}
                      />
                    </div>
                  </div>
                  {fullPatternLOS_Data != null && (
                    <Table
                      columns={column8}
                      pagination={true}
                      data={fullPatternLOS_Data}
                    />
                  )}
                  <div className="flex my-2 items-center justify-end gap-2">
                    {/* <Dropdown selectedItem={"All Users"} /> */}
                    <TableButton
                      title={"Add Room Range"}
                      onClick={() => setAddRoomRange(true)}
                    />
                  </div>
                  {fullPatternRoomBase_Data != null && (
                    <Table
                      columns={column7}
                      pagination={true}
                      data={fullPatternRoomBase_Data}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

const DynamicRateRulesModal = ({
  hideModal,
  editData,
  isEdit,
  setAddDynamicRateRulesOccupency,
  data,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState({
    rateRange: true,
    bands: false,
    rateAdjustment: false,
  });
  const [prevStep, setPrevStep] = useState(null);
  const [addDynamicRateRule] = useAddDynamicRateRuleMutation();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();
  const [Inventory, setInventory] = useState(20);
  const [minRate, setMinRate] = useState(10);
  const [maxRate, setMaxRate] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [isPercentage, setIsPercentage] = useState(true);
  const [isInventoryPercentage, setIsInventoryPercentage] = useState(true);
  const [isMinRatePercentage, setIsMinRatePercentage] = useState(true);
  const [isMaxRatePercentage, setIsMaxRatePercentage] = useState(true);
  const [adjustRate, setAdjustRate] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [IntervalValue, setIntervalValue] = useState(0);
  const [dynamicData, setDynamicData] = useState([
    {
      adjustmentType: "",
      adjustmentValue: "",
      fromLevel: "",
      toLevel: "",
    },
  ]);

  const handleInventoryChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isInventoryPercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setInventory(value);
  };

  const handleMinRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMinRatePercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setMinRate(value);
  };

  const handleMaxRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMaxRatePercentage) {
      value = Math.min(Math.max(value, minRate), 100); // Ensure max rate is not less than min rate and not greater than 100%
    } else {
      value = Math.max(value, minRate); // Ensure max rate is not less than min rate
    }
    setMaxRate(value);
  };
  const options = [
    {
      label: "Intervals",
      value: "Intervals",
    },
    {
      label: "Room Inventory",
      value: "Room Inventory",
    },
    {
      label: "No. of bands",
      value: "No. of bands",
    },
  ];

  useEffect(() => {
    if (editData) {
      setDynamicData(null);
      setDynamicData([editData]);
    }
  }, [editData]);

  const obj1 = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
        return {
          fromLevel: band?.split("-")[0],
          toLevel: band?.split("-")[1],
          adjustmentType: isPercentage ? "Percent" : "Amount",
          adjustmentValue: band?.adjustmentValue,
        };
      }),
    },
  };
  const obj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
        return {
          fromLevel: band?.split("-")[0],
          toLevel: band?.split("-")[1],
          adjustmentType: isPercentage ? "Percent" : "Amount",
          adjustmentValue: band?.adjustmentValue,
        };
      }),
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: [],
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: [],
    },
  };

  const handleNext = () => {
    if (steps.rateRange) {
      setPrevStep(steps.rateRange);
      setSteps({ bands: true, rateAdjustment: false, rateRange: false });
    }
    if (steps.bands) {
      setPrevStep(steps.bands);
      setSteps({ bands: false, rateAdjustment: true, rateRange: false });
    }
    if (steps.rateAdjustment) {
      setIsLoading(true);
      if (data && [data?.details].length > 0) {
        updateDynamicRateRule(obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Rate Rule Updated Successfully!", {
              position: "bottom-right",
            });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          });
      } else if (isEdit) {
        setIsLoading(true);
        updateDynamicRateRule(obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Rate Rule Updated Successfully!", {
              position: "bottom-right",
            });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          });
      } else
        addDynamicRateRule(obj)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Rate Rule Added Successfully!", {
              position: "bottom-right",
            });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          });
    }
  };

  const handleBackClick = () => {
    if (prevStep === steps.bands) {
      setSteps({ bands: false, rateAdjustment: false, rateRange: true });
    } else if (prevStep === steps.rateAdjustment) {
      setSteps({ bands: true, rateAdjustment: false, rateRange: false });
    }
  };
  function getIntervals(
    totalNumber,
    intervalSizeOrBands,
    isNumberOfBands = false
  ) {
    // Error handling for invalid inputs
    if (
      typeof totalNumber !== "number" ||
      typeof intervalSizeOrBands !== "number" ||
      totalNumber <= 0 ||
      intervalSizeOrBands <= 0
    ) {
      return ["Invalid inputs. Please provide positive numbers."];
    }

    let intervals = [];

    if (isNumberOfBands) {
      // Calculate interval size based on the number of bands
      let numberOfBands = intervalSizeOrBands;
      let intervalSize = Math.ceil(totalNumber / numberOfBands);

      for (let i = 0; i < totalNumber; i += intervalSize) {
        let start = i;
        let end = Math.min(i + intervalSize - 1, totalNumber - 1);
        intervals.push(start + "-" + end);
      }
    } else {
      // Use the interval size directly
      let intervalSize = intervalSizeOrBands;

      for (let i = 0; i < totalNumber; i += intervalSize) {
        let start = i;
        let end = Math.min(i + intervalSize - 1, totalNumber - 1);
        intervals.push(start + "-" + end);
      }
    }

    return intervals;
  }

  const alpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4 ">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
            handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
          /> */}
            <div>Adjust Occupancy Level</div>
          </div>
          <div className="flex items-center justify-between gap-4">
            {(steps.bands || steps.rateAdjustment) && (
              <div
                className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
                onClick={handleBackClick}
              >
                Back
              </div>
            )}
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={hideModal}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              {steps.rateAdjustment ? "Save" : "Next"}
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center -mt-4">
          <div
            className={`${steps.rateRange && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
            // onClick={() =>
            //   setSteps({
            //     rateRange: true,
            //     bands: false,
            //     rateAdjustment: false,
            //   })
            // }
          >
            Rate Range
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: true,
            //     rateAdjustment: false,
            //   })
            // }
            className={`${steps.bands && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Bands
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: false,
            //     rateAdjustment: true,
            //   })
            // }
            className={`${steps.rateAdjustment && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Rate Adjustment
          </div>
        </div>
        {steps?.rateRange && (
          <div className="w-[30%]">
            <div className="flex justify-start mt-8 items-center">
              <div className="text-md w-[130px]">Inventory</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400  rounded-md text-md"
                  onChange={handleInventoryChange}
                  value={Inventory}
                />
                {/* <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(false)}
                >
                  ₹
                </div> */}
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Minimum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMinRateChange}
                  value={minRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Maximum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMaxRateChange}
                  value={maxRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
        {steps?.bands && (
          <div className="w-[30%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Created Level By</div>
              <div className="flex justify-between gap-4 items-center">
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                >
                  <div className="flex items-center justify-between text-gray-600">
                    <div>
                      {selectedItem?.label ? selectedItem?.label : "Select"}
                    </div>
                    <div>
                      <AiOutlineCaretDown />
                    </div>
                    {isOpen && (
                      <div className="absolute -bottom-[40px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] -ml-2 rounded-md">
                        {Array.isArray(options) &&
                          options.map((option) => {
                            return (
                              <div
                                className="px-4 py-2 hover:bg-gray-100 text-sm"
                                onClick={() => {
                                  setSelectedItem(option);
                                  setIsOpen(false);
                                }}
                              >
                                {option?.label}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Interval Value</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="relative border border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                  placeholder="Enter Value"
                  value={IntervalValue}
                  onChange={(e) =>
                    setIntervalValue(
                      parseInt(e.target.value > 0 ? e.target.value : 0)
                    )
                  }
                />
              </div>
            </div>
            <div className="text-sm text-gray-600 mt-4 mb-8 border-b py-4">
              Preview
            </div>
            <div className="grid grid-cols-3 gap-4 w-[100%]">
              {Array.isArray(getIntervals(Inventory, IntervalValue)) &&
                getIntervals(Inventory, IntervalValue).map(
                  (band, bandIndex) => {
                    return (
                      <div className="bg-gray-100 text-center rounded-lg py-1 px-4 ">
                        <div className="text-sm text-gray-700 font-semibold">
                          Band-{alpha[bandIndex]}
                        </div>
                        <div className="text-sm text-gray-600">{band}</div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        )}
        {steps?.rateAdjustment && (
          <div className="w-[60%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Increase Rates for each level By</div>
              <div className="flex justify-end gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={(e) => {
                    let value = parseInt(e.target.value) || 0;
                    // If percentage mode is selected, constrain the value between 0 and 100
                    if (isPercentage) {
                      value = Math.min(Math.max(value, 0), 100);
                    }
                    setAdjustRate(value);
                  }}
                  value={adjustRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${!isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const AddDayWeekRule = ({
  hideModal,
  editData,
  isEdit,
  selectedItem,
  setSelectedItem,
  refetch,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const hId = CurrentProperty();
  const [Days, setDays] = useState([
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ]);

  const [addDynamicRateRule] = useAddDynamicRateRuleMutation();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();
  const [temporaryDays, setTemporaryDays] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [daysOfWeek, setDaysOfWeek] = useState([
    {
      day: "",
      adjustmentValue: "",
      adjustmentType: "Percent",
    },
  ]);

  useEffect(() => {
    if (editData) {
      setDaysOfWeek(null);
      setDaysOfWeek([editData]);
    }
  }, [editData]);

  function removeDuplicates(array) {
    return array.filter((item, index) => array.indexOf(item) === index);
  }

  const handleAddMore = () => {
    setDaysOfWeek((prevState) => [
      ...prevState,
      {
        day: "",
        adjustmentValue: "",
        adjustmentType: "Percent",
      },
    ]);
  };

  const handleEdit = (index, value, type) => {
    const data = [...daysOfWeek];

    if (type === "Percent") {
      if (value <= 100) {
        if (data[index]) {
          const updatedObject = { ...data[index] };
          updatedObject.adjustmentValue = value;
          data[index] = updatedObject;
        }
      }
    } else {
      if (data[index]) {
        const updatedObject = { ...data[index] };
        updatedObject.adjustmentValue = value;
        data[index] = updatedObject;
      }
    }
    setDaysOfWeek(data);
  };

  const handleEditDays = (index, value) => {
    const updatedDaysOfWeek = [...daysOfWeek];
    if (updatedDaysOfWeek[index]) {
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], day: value };
    }

    // Filter out the selected day from the Days array
    const updatedDays = Days.filter((day) => day.label !== value);

    setDays(updatedDays);
    setTemporaryDays(updatedDays);
    setDaysOfWeek(updatedDaysOfWeek);
  };

  useEffect(() => {
    if (temporaryDays) {
      setDays(temporaryDays);
    }
    setTemporaryDays();
  }, [temporaryDays]);
  const handleAdd = (index, value) => {
    const data = [...daysOfWeek];
    const Add = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Add;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const handleSubstract = (index, value) => {
    const data = [...daysOfWeek];
    const Substract = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Substract;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const handleDelete = (index) => {
    const data = [...daysOfWeek];
    const valueToPush = data[index].day;
    setDays((prevState) => [
      ...prevState,
      { label: valueToPush, value: valueToPush },
    ]);
    const filterData = daysOfWeek.filter((item, dayIndex) => {
      return index != dayIndex;
    });
    setDaysOfWeek(filterData);
  };

  const handlePercentage = (index, value) => {
    const data = [...daysOfWeek];
    const Percentage = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.adjustmentType = Percentage;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const obj1 = {
    hId: CurrentProperty(),
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: daysOfWeek,
    },
  };
  const obj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: [],
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: daysOfWeek,
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: [],
    },
  };

  const handleNext = () => {
    if (data && [data?.details].length > 0) {
      setIsLoading(true);
      updateDynamicRateRule(obj1)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Week Rule Updated Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else if (isEdit) {
      setIsLoading(true);
      updateDynamicRateRule(obj1)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Week Rule Updated Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else
      addDynamicRateRule(obj)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Week Rule Added Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
  };
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
      handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
    /> */}
            <div>Adjust Day Week Rule</div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={hideModal}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              Save
            </div>
          </div>
        </div>
        <div className="w-[30%]">
          {daysOfWeek.map((day, dayIndex) => {
            return (
              <div
                className="flex gap-4 justify-between mt-8 items-center"
                key={dayIndex}
              >
                <div>
                  {/* <div className="text-md">Created Level By</div> */}
                  <div className="flex justify-between gap-4 items-center">
                    <div
                      onClick={() => {
                        setSelectedIndex(dayIndex);
                        setIsOpen(!isOpen);
                      }}
                      ref={ref}
                      className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    >
                      <div className="flex items-center justify-between text-gray-600">
                        <div>{day?.day ? day?.day : "Select Day"}</div>
                        <div>
                          <AiOutlineCaretDown />
                        </div>
                        {isOpen && selectedIndex === dayIndex && (
                          <div className="absolute top-[50px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] -ml-2 rounded-md">
                            {Days.map((option, ind) => {
                              return (
                                <div
                                  className="px-4 py-2 hover:bg-gray-100 text-sm"
                                  onClick={() => {
                                    handleEditDays(dayIndex, option?.value);
                                    setIsOpen(false);
                                  }}
                                  key={ind}
                                >
                                  {option?.label}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    value={day?.adjustmentValue}
                    onChange={(e) =>
                      handleEdit(
                        dayIndex,
                        e.target.value,
                        day.adjustmentType,
                        day.amountChangeType
                      )
                    }
                    className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    placeholder="Adjust Value"
                  />
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    day.amountChangeType === "Substract"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleSubstract(dayIndex, "Substract");
                  }}
                >
                  -
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    day.amountChangeType === "Add"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleAdd(dayIndex, "Add");
                  }}
                >
                  +
                </div>

                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${day.adjustmentType === "Percent" && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => {
                    handlePercentage(dayIndex, "Percent");
                  }}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${day.adjustmentType === "Amount" && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => {
                    handlePercentage(dayIndex, "Amount");
                  }}
                >
                  ₹
                </div>
                <div
                  onClick={() => handleDelete(dayIndex)}
                  className="bg-red-50/50 text-red-500 hover:bg-red-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer border border-red-500 px-4 py-1 rounded-lg"
                >
                  x
                </div>
              </div>
            );
          })}

          {daysOfWeek?.length != 7 && (
            <div onClick={handleAddMore} className="text-sm text-blue-600 mt-8">
              + Add More
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const DemandBasedRule = ({ hideModal, data, refetch, editData, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState({
    rateRange: true,
    bands: false,
    rateAdjustment: false,
  });
  const [addDynamicRateRule] = useAddDynamicRateRuleMutation();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();
  const [Inventory, setInventory] = useState(20);
  const [minRate, setMinRate] = useState(10);
  const [maxRate, setMaxRate] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [isPercentage, setIsPercentage] = useState(true);
  const [isInventoryPercentage, setIsInventoryPercentage] = useState(true);
  const [isMinRatePercentage, setIsMinRatePercentage] = useState(true);
  const [isMaxRatePercentage, setIsMaxRatePercentage] = useState(true);
  const [adjustRate, setAdjustRate] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [IntervalValue, setIntervalValue] = useState(0);
  const [ruleData, setRuleData] = useState([{}]);

  const options = [
    {
      label: "Intervals",
      value: "Intervals",
    },
    {
      label: "Room Inventory",
      value: "Room Inventory",
    },
    {
      label: "No. of bands",
      value: "No. of bands",
    },
  ];
  const handleInventoryChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isInventoryPercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setInventory(value);
  };

  const handleMinRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMinRatePercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setMinRate(value);
  };

  const handleMaxRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMaxRatePercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setMaxRate(value);
  };
  const obj1 = {
    hId: CurrentProperty(),
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
        return {
          fromLevel: band?.split("-")[0],
          toLevel: band?.split("-")[1],
          adjustmentType: isPercentage ? "Percent" : "Amount",
          adjustmentValue: band?.adjustmentValue,
        };
      }),
    },
  };
  const obj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: [],
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: [],
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
        return {
          fromLevel: band?.split("-")[0],
          toLevel: band?.split("-")[1],
          adjustmentType: isPercentage ? "Percent" : "Amount",
          adjustmentValue: band?.adjustmentValue,
        };
      }),
    },
  };
  const handleNext = () => {
    if (steps.rateRange) {
      setSteps({ bands: true, rateAdjustment: false, rateRange: false });
    }
    if (steps.bands) {
      setSteps({ bands: false, rateAdjustment: true, rateRange: false });
    }
    if (steps.rateAdjustment) {
      setIsLoading(true);
      if (data && [data?.details].length > 0) {
        updateDynamicRateRule(obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Demand Base Rule Updated Successfully!", {
              position: "bottom-right",
            });
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            hideModal(true);
          });
      } else if (isEdit) {
        setIsLoading(true);
        updateDynamicRateRule(obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Demand Base Rule Updated Successfully!", {
              position: "bottom-right",
            });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          });
      } else
        addDynamicRateRule(obj)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success("Demand Base Rule Added Successfully!", {
              position: "bottom-right",
            });
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            hideModal(true);
          });
    }
  };

  function getIntervals(totalNumber, intervalSize) {
    // Error handling for invalid inputs
    if (
      typeof totalNumber !== "number" ||
      typeof intervalSize !== "number" ||
      totalNumber <= 0 ||
      intervalSize <= 0
    ) {
      return ["Invalid inputs. Please provide positive numbers."];
    }

    let intervals = [];

    for (let i = 0; i < totalNumber; i += intervalSize) {
      let start = i;
      let end = Math.min(i + intervalSize - 1, totalNumber - 1);
      intervals.push(start + "-" + end);
    }

    return intervals;
  }

  const alpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4 ">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
            handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
          /> */}
            <div>Add Demand Rate Rule</div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={() => hideModal(true)}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              {steps.rateAdjustment ? "Save" : "Next"}
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center -mt-4">
          <div
            className={`${steps.rateRange && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
            // onClick={() =>
            //   setSteps({
            //     rateRange: true,
            //     bands: false,
            //     rateAdjustment: false,
            //   })
            // }
          >
            Rate Range
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: true,
            //     rateAdjustment: false,
            //   })
            // }
            className={`${steps.bands && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Bands
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: false,
            //     rateAdjustment: true,
            //   })
            // }
            className={`${steps.rateAdjustment && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Rate Adjustment
          </div>
        </div>
        {steps?.rateRange && (
          <div className="w-[30%]">
            <div className="flex justify-start mt-8 items-center">
              <div className="text-md w-[130px]">Inventory</div>
              <div className="flex justify-start gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleInventoryChange}
                  value={Inventory}
                />
                {/* <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(false)}
                >
                  ₹
                </div> */}
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Minimum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMinRateChange}
                  value={minRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Maximum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMaxRateChange}
                  value={maxRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
        {steps?.bands && (
          <div className="w-[30%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Created Level By</div>
              <div className="flex justify-between gap-4 items-center">
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                >
                  <div className="flex items-center justify-between text-gray-600">
                    <div>
                      {selectedItem?.label ? selectedItem?.label : "Select"}
                    </div>
                    <div>
                      <AiOutlineCaretDown />
                    </div>
                    {isOpen && (
                      <div className="absolute -bottom-[40px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] -ml-2 rounded-md">
                        {Array.isArray(options) &&
                          options.map((option) => {
                            return (
                              <div
                                className="px-4 py-2 hover:bg-gray-100 text-sm"
                                onClick={() => {
                                  setSelectedItem(option);
                                  setIsOpen(false);
                                }}
                              >
                                {option?.label}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Interval Value</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="relative border border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                  placeholder="Enter Value"
                  value={IntervalValue}
                  onChange={(e) =>
                    setIntervalValue(
                      parseInt(e.target.value > 0 ? e.target.value : 0)
                    )
                  }
                />
              </div>
            </div>
            <div className="text-sm text-gray-600 mt-4 mb-8 border-b py-4">
              Preview
            </div>
            <div className="grid grid-cols-3 gap-4 w-[100%]">
              {Array.isArray(getIntervals(Inventory, IntervalValue)) &&
                getIntervals(Inventory, IntervalValue).map(
                  (band, bandIndex) => {
                    return (
                      <div className="bg-gray-100 text-center rounded-lg py-1 px-4 ">
                        <div className="text-sm text-gray-700 font-semibold">
                          Band-{alpha[bandIndex]}
                        </div>
                        <div className="text-sm text-gray-600">{band}</div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        )}
        {steps?.rateAdjustment && (
          <div className="w-[60%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Increase Rates for each level By</div>
              <div className="flex justify-end gap-4 items-center">
                {/* <div
                  className="py-2 bg-gray-100 px-4 border rounded-lg border-gray-400 cursor-pointer"
                  onClick={
                    adjustRate === 1
                      ? () => null
                      : () => setAdjustRate(adjustRate + 1)
                  }
                >
                  -
                </div> */}
                {/* <div
                  className="py-2 bg-gray-100 px-4 border border-gray-400 rounded-lg cursor-pointer"
                  onClick={() => setAdjustRate(adjustRate + 1)}
                >
                  +
                </div> */}
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={(e) => {
                    let value = parseInt(e.target.value) || 0;
                    // If percentage mode is selected, constrain the value between 0 and 100
                    if (isPercentage) {
                      value = Math.min(Math.max(value, 0), 100);
                    }
                    setAdjustRate(value);
                  }}
                  value={adjustRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${!isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const AddSeasonRule = ({
  hideModal,
  editData,
  isEdit,
  selectedItem,
  setSelectedItem,
  refetch,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const hId = CurrentProperty();
  const { data: allSeason } = useGetPropertySeasonQuery({
    hId: hId,
  });
  const [Days, setDays] = useState();
  useEffect(() => {
    if (allSeason && allSeason?.data) {
      const newSeason = allSeason?.data?.map((item) => {
        return {
          label: item?.seasonName,
          value: item?.seasonId,
        };
      });
      setDays(newSeason);
    }
  }, [allSeason]);

  const editDataModified = [editData]?.map((item) => {
    return {
      dayName: item?.seasonName,
      day: item?.seasonId,
      adjustmentValue: item?.adjustmentValue,
      adjustmentType: item?.adjustmentType,
    };
  });

  const [addDynamicRateRule] = useAddDynamicRateRuleMutation();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();
  const [temporaryDays, setTemporaryDays] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [daysOfWeek, setDaysOfWeek] = useState([
    {
      day: "",
      dayName: "",
      adjustmentValue: "",
      adjustmentType: "Percent",
    },
  ]);

  useEffect(() => {
    if (editData) {
      setDaysOfWeek(null);
      setDaysOfWeek(editDataModified);
    }
  }, [editData]);

  function removeDuplicates(array) {
    return array.filter((item, index) => array.indexOf(item) === index);
  }

  const handleAddMore = () => {
    setDaysOfWeek((prevState) => [
      ...prevState,
      {
        day: "",
        adjustmentValue: "",
        adjustmentType: "Percent",
        dayName: "",
      },
    ]);
  };

  const handleEdit = (index, value, type) => {
    const data = [...daysOfWeek];

    if (type === "Percent") {
      if (value <= 100) {
        if (data[index]) {
          const updatedObject = { ...data[index] };
          updatedObject.adjustmentValue = value;
          data[index] = updatedObject;
        }
      }
    } else {
      if (data[index]) {
        const updatedObject = { ...data[index] };
        updatedObject.adjustmentValue = value;
        data[index] = updatedObject;
      }
    }
    setDaysOfWeek(data);
  };

  const handleEditDays = (index, value, name) => {
    const updatedDaysOfWeek = [...daysOfWeek];
    if (updatedDaysOfWeek[index]) {
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], day: value };
      updatedDaysOfWeek[index] = { ...updatedDaysOfWeek[index], dayName: name };
    }

    // Filter out the selected day from the Days array
    // const updatedDays = Days.filter((day) => day.label !== value);

    // setDays(updatedDays);
    // setTemporaryDays(updatedDays);
    setDaysOfWeek(updatedDaysOfWeek);
  };

  useEffect(() => {
    if (temporaryDays) {
      setDays(temporaryDays);
    }
    setTemporaryDays();
  }, [temporaryDays]);

  useEffect(() => {
    const newDay = Days?.filter((item) => {
      return !daysOfWeek?.some((item2) => {
        return item2?.day === item?.value;
      });
    });
    if (newDay) {
      setDays(newDay);
    }
  }, [daysOfWeek]);

  const handleAdd = (index, value) => {
    const data = [...daysOfWeek];
    const Add = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Add;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const handleSubstract = (index, value) => {
    const data = [...daysOfWeek];
    const Substract = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.amountChangeType = Substract;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const handleDelete = (index) => {
    const data = [...daysOfWeek];
    const valueToPush = data[index].day;
    const nameToPush = data[index].dayName;
    setDays((prevState) => [
      ...prevState,
      { label: nameToPush, value: valueToPush },
    ]);
    const filterData = daysOfWeek.filter((item, dayIndex) => {
      return index != dayIndex;
    });
    setDaysOfWeek(filterData);
  };

  const handlePercentage = (index, value) => {
    const data = [...daysOfWeek];
    const Percentage = value;

    if (data[index]) {
      const updatedObject = { ...data[index] };
      updatedObject.adjustmentType = Percentage;
      data[index] = updatedObject;
    }
    setDaysOfWeek(data);
  };
  const obj1 = {
    hId: CurrentProperty(),
    seasonalBasedRateRule: {
      seasonalBasedRateRuleStatus: true,
      levels: daysOfWeek.map((item) => {
        return {
          seasonName: item?.day,
          adjustmentValue: item?.adjustmentValue,
          adjustmentType: item?.adjustmentType,
        };
      }),
    },
  };
  const obj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: [],
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: [],
    },
    seasonalBasedRateRule: {
      seasonalBasedRateRuleStatus: true,
      levels: daysOfWeek.map((item) => {
        return {
          seasonName: item?.day,
          adjustmentValue: item?.adjustmentValue,
          adjustmentType: item?.adjustmentType,
        };
      }),
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: [],
    },
  };

  const handleNext = () => {
    if (data && [data?.details].length > 0) {
      setIsLoading(true);
      updateDynamicRateRule(obj1)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Seasonal Pricing Updated Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else if (isEdit) {
      setIsLoading(true);
      updateDynamicRateRule(obj1)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Seasonal Pricing Updated Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
    } else
      addDynamicRateRule(obj)
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("Seasonal Pricing Added Successfully!", {
            position: "bottom-right",
          });
          hideModal(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          hideModal(true);
        });
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
      handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
    /> */}
            <div>Adjust Seasonal Pricing</div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={hideModal}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              Save
            </div>
          </div>
        </div>
        <div className="w-[30%]">
          {daysOfWeek.map((day, dayIndex) => {
            return (
              <div
                className="flex gap-4 justify-between mt-8 items-center"
                key={dayIndex}
              >
                <div>
                  {/* <div className="text-md">Created Level By</div> */}
                  <div className="flex justify-between gap-4 items-center">
                    <div
                      onClick={() => {
                        setSelectedIndex(dayIndex);
                        setIsOpen(!isOpen);
                      }}
                      className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    >
                      <div className="flex items-center justify-between text-gray-600">
                        <div>
                          {day?.dayName ? day?.dayName : "Select Season"}
                        </div>
                        <div>
                          <AiOutlineCaretDown />
                        </div>
                        {isOpen && selectedIndex === dayIndex && (
                          <div className="absolute -bottom-[60px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] min-h-auto max-h-[200px] overflow-y-scroll -ml-2 rounded-md">
                            {Days.map((option, ind) => {
                              return (
                                <div
                                  className="px-4 py-2 hover:bg-gray-100 text-sm"
                                  onClick={() => {
                                    handleEditDays(
                                      dayIndex,
                                      option?.value,
                                      option?.label
                                    );
                                    setIsOpen(false);
                                  }}
                                  key={ind}
                                >
                                  {option?.label}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    value={day?.adjustmentValue}
                    onChange={(e) =>
                      handleEdit(
                        dayIndex,
                        e.target.value,
                        day.adjustmentType,
                        day.amountChangeType
                      )
                    }
                    className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                    placeholder="Adjust Value"
                  />
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    day.amountChangeType === "Substract"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleSubstract(dayIndex, "Substract");
                  }}
                >
                  -
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    day.amountChangeType === "Add"
                      ? "outline outline-blue-500 text-blue-500"
                      : ""
                  } cursor-pointer`}
                  onClick={() => {
                    handleAdd(dayIndex, "Add");
                  }}
                >
                  +
                </div>

                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${day.adjustmentType === "Percent" && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => {
                    handlePercentage(dayIndex, "Percent");
                  }}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${day.adjustmentType === "Amount" && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => {
                    handlePercentage(dayIndex, "Amount");
                  }}
                >
                  ₹
                </div>
                <div
                  onClick={() => handleDelete(dayIndex)}
                  className="bg-red-50/50 text-red-500 hover:bg-red-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer border border-red-500 px-4 py-1 rounded-lg"
                >
                  x
                </div>
              </div>
            );
          })}

          {daysOfWeek?.length != 7 && (
            <div onClick={handleAddMore} className="text-sm text-blue-600 mt-8">
              + Add More
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const FPLOSBasedRule = ({
  hideModal,
  data,
  refetch,
  editData,
  isEdit,
  roomRange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState({
    rateRange: true,
    bands: false,
    rateAdjustment: false,
  });
  const [addDynamicRateRule] = useAddDynamicRateRuleMutation();
  const [updateDynamicRateRule] = useUpdateDynamicRateRuleMutation();
  const [Inventory, setInventory] = useState(20);
  const [minRate, setMinRate] = useState(10);
  const [maxRate, setMaxRate] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [isPercentage, setIsPercentage] = useState(true);
  const [isInventoryPercentage, setIsInventoryPercentage] = useState(true);
  const [isMinRatePercentage, setIsMinRatePercentage] = useState(true);
  const [isMaxRatePercentage, setIsMaxRatePercentage] = useState(true);
  const [adjustRate, setAdjustRate] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [IntervalValue, setIntervalValue] = useState(0);
  const [ruleData, setRuleData] = useState([{}]);

  const options = [
    {
      label: "Intervals",
      value: "Intervals",
    },
    {
      label: "Room Inventory",
      value: "Room Inventory",
    },
    {
      label: "No. of bands",
      value: "No. of bands",
    },
  ];
  const handleInventoryChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isInventoryPercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setInventory(value);
  };

  const handleMinRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMinRatePercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setMinRate(value);
  };

  const handleMaxRateChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (isMaxRatePercentage) {
      value = Math.min(Math.max(value, 0), 100);
    }
    setMaxRate(value);
  };
  const obj1 = {
    hId: CurrentProperty(),
    fplosRateRule: {
      losBasedRateRule: {
        losBasedRateRuleStatus: true,
        levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
          return {
            fromLos: band?.split("-")[0],
            toLos: band?.split("-")[1],
            adjustmentType: isPercentage ? "Percent" : "Amount",
            adjustmentValue: adjustRate,
          };
        }),
      },
    },
  };
  const RoomRangeObj1 = {
    hId: CurrentProperty(),
    fplosRateRule: {
      roomsBasedRateRule: {
        roomsBasedRateRuleStatus: true,
        levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
          return {
            fromRooms: band?.split("-")[0],
            toRooms: band?.split("-")[1],
            adjustmentType: isPercentage ? "Percent" : "Amount",
            adjustmentValue: adjustRate,
          };
        }),
      },
    },
  };
  const obj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: [],
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: [],
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: [],
    },
    fplosRateRule: {
      losBasedRateRule: {
        losBasedRateRuleStatus: true,
        levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
          return {
            fromLos: band?.split("-")[0],
            toLos: band?.split("-")[1],
            adjustmentType: isPercentage ? "Percent" : "Amount",
            adjustmentValue: adjustRate,
          };
        }),
      },
    },
  };
  const roomRangeObj = {
    hId: CurrentProperty(),
    occupancyLevel: {
      occupancyLevelStatus: true,
      levels: [],
    },
    bookingWindowRule: {
      bookingWindowRuleStatus: true,
      levels: [],
    },
    dayOfWeekRule: {
      dayOfWeekRuleStatus: true,
      levels: [],
    },
    demandBasedRateRule: {
      demandBasedRateRuleStatus: true,
      levels: [],
    },
    fplosRateRule: {
      roomsBasedRateRule: {
        roomsBasedRateRuleStatus: true,
        levels: getIntervals(Inventory, IntervalValue)?.map((band) => {
          return {
            fromRooms: band?.split("-")[0],
            toRooms: band?.split("-")[1],
            adjustmentType: isPercentage ? "Percent" : "Amount",
            adjustmentValue: adjustRate,
          };
        }),
      },
    },
  };
  const handleNext = () => {
    if (steps.rateRange) {
      setSteps({ bands: true, rateAdjustment: false, rateRange: false });
    }
    if (steps.bands) {
      setSteps({ bands: false, rateAdjustment: true, rateRange: false });
    }
    if (steps.rateAdjustment) {
      setIsLoading(true);
      if (data && [data?.details].length > 0) {
        updateDynamicRateRule(roomRange ? RoomRangeObj1 : obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success(
              roomRange
                ? "Room Range Updated Successfully!"
                : "Full Pattern Length Of Stay Updated Successfully!",
              {
                position: "bottom-right",
              }
            );
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            hideModal(true);
          });
      } else if (isEdit) {
        setIsLoading(true);
        updateDynamicRateRule(roomRange ? RoomRangeObj1 : obj1)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success(
              roomRange
                ? "Room Range Updated Successfully!"
                : "Full Pattern Length Of Stay Updated Successfully!",
              {
                position: "bottom-right",
              }
            );
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            // setAddDynamicRateRulesOccupency(false);
            hideModal(true);
          });
      } else
        addDynamicRateRule(roomRange ? roomRangeObj : obj)
          .unwrap()
          .then((res) => {
            refetch();
            setIsLoading(false);
            toast.success(
              roomRange
                ? "Room Range Added Successfully!"
                : "Full Pattern Length Of Stay Added Successfully!",
              {
                position: "bottom-right",
              }
            );
            hideModal(true);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err?.message, { position: "bottom-right" });
            hideModal(true);
          });
    }
  };

  function getIntervals(totalNumber, intervalSize) {
    // Error handling for invalid inputs
    if (
      typeof totalNumber !== "number" ||
      typeof intervalSize !== "number" ||
      totalNumber <= 0 ||
      intervalSize <= 0
    ) {
      return ["Invalid inputs. Please provide positive numbers."];
    }

    let intervals = [];

    for (let i = 0; i < totalNumber; i += intervalSize) {
      let start = i;
      let end = Math.min(i + intervalSize - 1, totalNumber - 1);
      intervals.push(start + "-" + end);
    }

    return intervals;
  }

  const alpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="p-4 ">
        <div className="w-[100%] justify-between mb-10 flex items-center gap-6">
          <div className="flex items-center gap-4">
            {/* <BackButtonSettings
            handleBackButtonClick={() => setAddDynamicRateRulesOccupency(false)}
          /> */}
            <div>
              {" "}
              {roomRange ? "Add Room Range" : "Add Full Pattern Length Of Stay"}
            </div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div
              className="border-[#E62E2E] border px-4 py-1 rounded-lg text-[#E62E2E] hover:bg-[#E62E2E] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={() => hideModal(true)}
            >
              {"Cancel"}
            </div>
            <div
              className="border-[#081439] border px-4 py-1 rounded-lg text-[#081439] hover:bg-[#1D2D44] hover:text-white cursor-pointer duration-150 transition-all ease-in-out"
              onClick={handleNext}
            >
              {steps.rateAdjustment ? "Save" : "Next"}
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center -mt-4">
          <div
            className={`${steps.rateRange && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
            // onClick={() =>
            //   setSteps({
            //     rateRange: true,
            //     bands: false,
            //     rateAdjustment: false,
            //   })
            // }
          >
            Rate Range
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: true,
            //     rateAdjustment: false,
            //   })
            // }
            className={`${steps.bands && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Bands
          </div>
          <div
            // onClick={() =>
            //   setSteps({
            //     rateRange: false,
            //     bands: false,
            //     rateAdjustment: true,
            //   })
            // }
            className={`${steps.rateAdjustment && "border-b-2 border-[#081439] "} py - 3 px - 2 cursor - pointer text - sm text - [#505257]`}
          >
            Rate Adjustment
          </div>
        </div>
        {steps?.rateRange && (
          <div className="w-[30%]">
            <div className="flex justify-start mt-8 items-center">
              <div className="text-md w-[130px]">Inventory</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleInventoryChange}
                  value={Inventory}
                />
                {/* <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isInventoryPercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsInventoryPercentage(false)}
                >
                  ₹
                </div> */}
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Minimum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMinRateChange}
                  value={minRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMinRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMinRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Maximum Rate</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={handleMaxRateChange}
                  value={maxRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${
                    !isMaxRatePercentage &&
                    `outline outline-blue-500 text-blue-500`
                  } cursor-pointer`}
                  onClick={() => setIsMaxRatePercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
        {steps?.bands && (
          <div className="w-[30%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Created Level By</div>
              <div className="flex justify-between gap-4 items-center">
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className="relative border cursor-pointer border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                >
                  <div className="flex items-center justify-between text-gray-600">
                    <div>
                      {selectedItem?.label ? selectedItem?.label : "Select"}
                    </div>
                    <div>
                      <AiOutlineCaretDown />
                    </div>
                    {isOpen && (
                      <div className="absolute -bottom-[40px] z-50 bg-gray-50  duration-150 transition-all ease-in-out cursor-pointer w-[100%] -ml-2 rounded-md">
                        {Array.isArray(options) &&
                          options.map((option) => {
                            return (
                              <div
                                className="px-4 py-2 hover:bg-gray-100 text-sm"
                                onClick={() => {
                                  setSelectedItem(option);
                                  setIsOpen(false);
                                }}
                              >
                                {option?.label}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Interval Value</div>
              <div className="flex justify-between gap-4 items-center">
                <input
                  className="relative border border-gray-400 w-[160px] text-start text-sm py-2 px-2 rounded-md bg-gray-50"
                  placeholder="Enter Value"
                  value={IntervalValue}
                  onChange={(e) =>
                    setIntervalValue(
                      parseInt(e.target.value > 0 ? e.target.value : 0)
                    )
                  }
                />
              </div>
            </div>
            <div className="text-sm text-gray-600 mt-4 mb-8 border-b py-4">
              Preview
            </div>
            <div className="grid grid-cols-3 gap-4 w-[100%]">
              {Array.isArray(getIntervals(Inventory, IntervalValue)) &&
                getIntervals(Inventory, IntervalValue).map(
                  (band, bandIndex) => {
                    return (
                      <div className="bg-gray-100 text-center rounded-lg py-1 px-4 ">
                        <div className="text-sm text-gray-700 font-semibold">
                          Band-{alpha[bandIndex]}
                        </div>
                        <div className="text-sm text-gray-600">{band}</div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        )}
        {steps?.rateAdjustment && (
          <div className="w-[60%]">
            <div className="flex justify-between mt-8 items-center">
              <div className="text-md">Increase Rates for each level By</div>
              <div className="flex justify-end gap-4 items-center">
                {/* <div
                  className="py-2 bg-gray-100 px-4 border rounded-lg border-gray-400 cursor-pointer"
                  onClick={
                    adjustRate === 1
                      ? () => null
                      : () => setAdjustRate(adjustRate + 1)
                  }
                >
                  -
                </div> */}
                {/* <div
                  className="py-2 bg-gray-100 px-4 border border-gray-400 rounded-lg cursor-pointer"
                  onClick={() => setAdjustRate(adjustRate + 1)}
                >
                  +
                </div> */}
                <input
                  className="w-[70px] p-2 text-center outline-none border border-gray-400 rounded-md text-md"
                  onChange={(e) => {
                    let value = parseInt(e.target.value) || 0;
                    // If percentage mode is selected, constrain the value between 0 and 100
                    if (isPercentage) {
                      value = Math.min(Math.max(value, 0), 100);
                    }
                    setAdjustRate(value);
                  }}
                  value={adjustRate}
                />
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(true)}
                >
                  %
                </div>
                <div
                  className={`py-2 bg-gray-100 px-4 border rounded-lg ${!isPercentage && `outline outline-blue-500 text-blue-500`} cursor - pointer`}
                  onClick={() => setIsPercentage(false)}
                >
                  ₹
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
