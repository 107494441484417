import React, { useEffect } from 'react';
import '../Global.css';
import Img from '../../../assets/img/Branding.svg'
import Img2 from '../../../assets/img/Onboarding.svg';
import SimpleButton from '../../../components/button/SimpleButton';
import { useNavigate } from 'react-router-dom';



const ConfirmationDone = ({ title, subtitle, line, buttontext }) => {
    const onComplete = () => {
    }
    const styles = {
        fontSize: '17px',
        padding: "10px 0px",
        width: '100%',
        background: '#081439',
        color: '#FFFFFF',
        borderRadius: '5px',
        fontWeight:600,
    }
    const navigate =useNavigate()
  const  handleClick =()=>{
        navigate("/login")
    }

    useEffect(()=>{
        setTimeout(()=>{
            navigate("/login")
        },[4000])
    })
    return (
        <div className='verify-email-container'>

            <div className='verify-email-main'>
                <div className='flex w-full justify-center py-4'>
                    <img className='img-logo' src={Img} alt='' />
                </div>
                <div className='verify-email-content'>
                    <div className='flex w-full justify-center'>
                        <img className='img-confirm' src={Img2} alt='' />
                    </div>
                    <div className='flex items-center justify-center flex-col gap-2'>
                        <h1 className='text-[1.3rem] text-[#0D1321] text-center font-sans font-semibold '>{title|| "Ready to Go"}</h1>
                        <p className='text-[0.8rem] text-center text-[#485266]'>{subtitle||"Your Account is Ready!"}</p>

                    </div>
                    {buttontext &&
                        <SimpleButton type='submit' onClick={handleClick} text={"Login Now"} />
                    }
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDone;


