import React, { useEffect, useState } from "react";
import "./Global.css";
import Img from "../../assets/img/rateXBlack.svg";
import Img2 from "../../assets/img/Frame 1321314698.svg";
import SimpleButton from "../../components/button/SimpleButton";
import MyUniversalComponent from "../../components/sideComponent/leftSection";
import emailIcon from "../../assets/icons/onboarding/Email.svg";
import Password from "../../assets/icons/onboarding/Password.svg";
import Fb from "../../assets/icons/onboarding/FB.svg";
import Google from "../../assets/icons/onboarding/Google.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import InputField from "../../components/inputFeild";
import { useUserLoginMutation } from "../../redux/slices/onBoarding";
import { toast } from "react-toastify";
import Backdrop from "../../components/backdrop";
import { SyncLoader } from "react-spinners";
import { setLoginData } from "../../redux/states/login";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUserSignupMutation } from "../../redux/slices/onBoarding";
import { setIsAdmin } from "../../redux/states/admin";
import { setToken } from "../../redux/states/tokenState";

const LogIn = ({ text }) => {
  const tokenn = window.localStorage.getItem("token");
  const location = useLocation();
  const dispatch = useDispatch();
  const [userLogin] = useUserLoginMutation();
  const [userSignup] = useUserSignupMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const rememberMeState = JSON.parse(localStorage.getItem("rememberMe")); // Step 3: Check local storage for rememberMe state
    if (rememberMeState !== null) {
      setRememberMe(rememberMeState);
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const loginData = {
      email: data.email,
      password: data.password,
    };
    userLogin(loginData)
      .unwrap()
      .then((res) => {
        if (res?.data?.twoFactorAuthentication) {
          window.localStorage.setItem("loginId", res?.data?.userId);
          window.localStorage.setItem("role", res?.data?.roleType);
          let resData = {
            email: data.email,
            authenticated: "2FA",
          };
          userSignup(resData)
            .unwrap()
            .then((res) => {
              toast.success(
                "OTP for authentication sent to your email address",
                { position: "bottom-right" }
              );
              navigate("/verify_login", { state: { email: data.email } });
            })
            .catch((err) => {
              toast.error(err?.data?.message, { position: "bottom-right" });
              setLoading(false);
            });
        } else {
          proceedToDashboard(res);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
        navigate("/login");
        setLoading(false);
      });
  };

  const proceedToDashboard = (res) => {
    // http://198.168.137.247:3100/api
    // https://rxserver.retvenslabs.com/api
    dispatch(setToken(res.data.token))
    fetch(
      `https://rxserver.retvenslabs.com/api/userDashboardSetting/getLoginInfo?userId=${res?.data?.userId}&hId=${res?.data?.hId}`,
      {
        headers: {
          Authorization: `${res.data.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        window.localStorage.setItem(
          "rateshopperDateRange",
          JSON.stringify(response.data.dataDateRange.e)
        );
        window.localStorage.setItem(
          "intelligenceDateRange",
          JSON.stringify(response.data.dataDateRange.i)
        );
        window.localStorage.setItem("dateFormat", response.data.dateFormat);
        window.localStorage.setItem("name", response?.data?.name);
        window.localStorage.setItem("role", res?.data?.roleType);
        if (res?.data?.roleType === "SUPERADMIN") {
          dispatch(setIsAdmin(true));
          window.localStorage.setItem("adminUserId", res?.data?.userId);
        }
        window.localStorage.setItem("designation", response?.data?.designation);
        window.localStorage.setItem(
          "profilePic",
          response?.data?.profilePicture
        );
        window.localStorage.setItem(
          "activeOta",
          JSON.stringify(response?.data?.activeOtas)
        );
        window.localStorage.setItem("userId", res?.data?.userId);
        window.localStorage.setItem("email", res?.data?.email);
        window.localStorage.setItem("hId", res?.data?.hId);
        window.localStorage.setItem(
          "permissions",
          JSON.stringify(response?.data?.permissions)
        );
        window.localStorage.setItem(
          "propertiesList",
          JSON.stringify(response?.data?.propertiesList)
        );
        window.localStorage.setItem(
          "extractionDate",
          JSON.stringify(response?.data?.lastExtraction)
        );
        window?.localStorage?.setItem(
          "currency",
          response?.data?.currency?.currencySymbol
        );
        dispatch(setLoginData(response?.data));
        toast.success(res.message, { position: "bottom-right h-5 w-5" });
        navigate("/dashboard", {
          state: {
            userId: res?.data?.userId,
            hId: res?.data?.hId,
            permission: response?.data?.permissions,
            token: res?.data?.token,
          },
        });
        setLoading(false);
      });
  };

  const handleLoginClick = () => {
    navigate("/signup");
  };
  const handleFacebookLogin = () => {
    alert("Add Your Facebook Login link first");
  };

  const handleGoogleLogin = () => {
    alert("Add Your Google Login link first");
  };

  // useEffect(()=>{
  //   if(!tokenn){
  //     window.location.reload()
  //   }
  // },[])
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    localStorage.setItem("rememberMe", JSON.stringify(event.target.checked)); // Step 2: Store rememberMe state in local storage
  };

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  return (
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="login-container">
            <MyUniversalComponent
              title="Made For True Hoteliers"
              subtitle="Step into a world where innovation meets intuition, tailored specifically for the discerning hotelier. Unlock the gateway to a new era of hospitality excellence with RMS - where every detail is designed with you in mind."
              imageSrc={Img2}
            />
            <div className="login-main">
              <div className="login-main-content">
                <div className="flex w-full justify-center">
                  <img className="img-logo" loading="lazy" src={Img} alt="" />
                </div>
                <div className="flex items-center justify-center flex-col gap-2">
                  <h1 className="text-[1.2rem] text-[#0D1321] text-center font-sans font-semibold  ">
                    Welcome Back
                  </h1>
                  <p className="text-[0.8rem] text-[#485266]">
                    {" "}
                    Securely log in to your account
                  </p>
                </div>
                <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
                  <div className="w-full">
                    <InputField
                      placeholder={"Enter email"}
                      className={"w-full rounded-t-lg "}
                      label={"Email"}
                      id={"email"}
                      control={methods.control}
                      name={"email"}
                      startIcon={emailIcon}
                      type={"email"}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+\.\S+$/,
                          message: "Entered value does not match email format",
                        },
                      }}
                    />
                  </div>
                  <div className="w-full rounded-b-lg ">
                    <InputField
                      placeholder={"Enter password"}
                      className={"w-full "}
                      label={"Password"}
                      id={"Password"}
                      startIcon={Password}
                      control={methods.control}
                      name={"password"}
                      type={"password"}
                      rules={{ required: "Password is required" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-4">
                  <div className="w-full flex flex-row justify-between">
                    <div class="flex items-center">
                      <input
                        id="rememberMeCheckbox"
                        type="checkbox"
                        value=""
                        onChange={handleRememberMeChange}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="rememberMeCheckbox"
                        className="ms-2 text-sm font-[500] font-sans text-gray-900 dark:text-[#485266]"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="flex flex-row gap-1 justify-end items-center">
                      <div
                        onClick={() => {
                          navigate("/forgot_password");
                        }}
                        className="text-[#3F70ED] text-[13px] cursor-pointer"
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </div>
                  <SimpleButton text="Log-in" type="submit" />
                  <div className="flex flex-col w-full gap-4 items-center">
                    {/* <div className="flex items-center w-full">
                      <div className="flex-grow border-t border-gray-300"></div>
                      <span className="px-4 text-[#0D1321]">or</span>
                      <div className="flex-grow border-t border-gray-300"></div>
                    </div> */}
                    {/* <div className="flex items-center gap-4">
                      <img
                        onClick={handleFacebookLogin}
                        className="img-size"
                        src={Fb}
                        alt=""
                        loading="lazy"
                      />
                      <img
                        onClick={handleGoogleLogin}
                        className="img-size"
                        src={Google}
                        alt=""
                        loading="lazy"
                      />
                    </div> */}
                    <div>
                      <p className="text-sm font-[400] text-[#0D1321]">
                        Don’t have an account?
                        <span
                          onClick={handleLoginClick}
                          className="text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer"
                        >
                          Register Now
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default LogIn;
