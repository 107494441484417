import React, { useState } from "react";
import DynamicTable from "../dynamicTable";
import "../../pages/onboarding/Global.css";
import arrow from "../../assets/icons/leftarrow.svg";
import Dropdown from "../dropdown";
import Calendar from "../calendar";
import { useNavigate } from "react-router-dom";
import InfoToolTip from "../tooltip/InfoToolTip";
// RateSuggestions.js // Import your CSS file

const RateSuggestions = ({
  isPermission,
  bgColor,
  showOptions = false,
  title,
  compData,
  compCol,
  isPagination,
  showDescription = true,
  isTransparent,
  tableBgColor
}) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(50);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    setProgress(value);
  };
  const data = [
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      age: "14",
      lefttosell: " Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      lefttosell: "Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      lefttosell: "Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      lefttosell: "Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      lefttosell: " Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
    {
      action: "Pubish Rate",
      name: "Deluxe Room",
      lefttosell: " Left of 40",
      occupancy: "75%",
      currentCharge: "₹9,999.00",
      ratesuggetion: "₹9,99,999,",
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" flex items-center dark:text-[#D3D8E1]">{value}</div>
        ),
      },
      {
        Header: "Left To sell",
        accessor: "lefttosell",
        width: "100%",
        Cell: ({ value }) => (
          <div className="text-neutral-light-17  flex items-center dark:text-[#D3D8E1] gap-1 ">
            <p className="text-neutral-dark-25">100</p>
            {value}
          </div>
        ),
      },
      {
        Header: "Occupancy ",
        accessor: "occupancy",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" flex items-center gap-2 mr-4">
            <div style={{ color: "orange", borderRadius: "5px" }}>{value}</div>
            <div className="w-full  max-w-xs">
              <input
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={handleChange}
                className="w-full h-2 bg-gray-300 dark:text-[#D3D8E1] hidden rounded-full appearance-none"
              />
              <div className="h-2  relative dark:text-[#D3D8E1] rounded-lg bg-gradient-to-tr from-[#FFC6564D] to-[#F160634D]">
                <div
                  className="h-full bg-gradient-to-l from-[#FFC656] to-[#F16063] rounded-l-lg absolute top-0 left-0"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: "Current Rating  ",
        accessor: "currentCharge",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" flex  items-center justify-start w-full ">
            {value}
          </div>
        ),
      },
      {
        Header: "Rate Suggestion ",
        accessor: "ratesuggetion",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" text-[#5AFBB7]  flex items-center justify-start w-full  ">
            {value}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => (
          <div className="w-full h-full flex justify-around items-center gap-4 ">
            <div className=" w-24 h-8 bg-[#1D2D44] cursor-pointer dark:bg-[#1D2D44] text-white rounded-lg text-center text-sm grid items-center ">
              {value}
            </div>
            <div className=" lg:mr-2 md:mr-0 mr-0">
              <img src={arrow} />
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="relative">
      <div
        className={`bg-transparent  dark:bg-[#252C3B] ${isPermission ? "" : "blur-sm pointer-events-none cursor-none"} w-[100%] p-4 rounded-xl`}
      >
        <div className="flex justify-between">
          <div className="w-[100%]">
            <div className="flex justify-between items-center">
              <h1
                className="text-white mb-4 dark:text-[#D3D8E1]"
                onClick={() => navigate("/rate_suggestions")}
              >
                {title || "Rate Suggestions"}
              </h1>
              {showDescription && (
                <div>
                  <InfoToolTip
                    content={
                      "This feature presents recommended pricing strategies for seven crucial days within the current month."
                    }
                    position={"left"}
                  />
                </div>
              )}
            </div>
            {showDescription && (
              <div className="text-[14px] text-gray-500 -mt-2">
                This is our suggested pricing for the next 7 days within this month
              </div>
            )}
          </div>
          {showOptions && (
            <div className="flex">
              <div>
                <Dropdown />
              </div>
              <div className=" mx-2">
                <Calendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
          )}
        </div>
        
        <DynamicTable
          columns={compCol ? compCol : columns}
          data={compData ? compData : data}
          isPagination={isPagination}
          isTransparent={isTransparent}
          tableBgColor={tableBgColor}
        />
      </div>
    </div>
  );
};

export default RateSuggestions;
