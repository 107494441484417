import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";

const RevenueTable = ({ data,
    room,
    filename,
    defaultSortColumn,
    sourceMonth,
    sourceYear,
    roomMonth,
    roomYear,
    isExpanded,
    onExpand, }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const rowsPerPage = (data.length); // Change this value as per your requirement
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    let currentRows = [];

    if (Array.isArray(data)) {
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        currentRows = data
        // .slice(indexOfFirstRow, indexOfLastRow);
    } else {
    }

    useEffect(() => {
        if (defaultSortColumn) {
            setSortColumn(defaultSortColumn);
        }
    }, [defaultSortColumn]);

    const findNewIndex = (item, sortedData) => {
        return sortedData.findIndex((sortedItem) => sortedItem === item);
    };

    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }

        setSelectedRows((selectedRows) => {
            const newSelectedRows = selectedRows.map((selectedIndex) => {
                const selectedDataItem = data[selectedIndex];
                const newIndex = findNewIndex(selectedDataItem, sortedData);
                return newIndex !== -1 ? newIndex : null;
            });

            const filteredSelectedRows = newSelectedRows.filter((index) => index !== null);

            return filteredSelectedRows;
        });
    };

    const sortedData = currentRows.slice().sort((a, b) => {
        if (sortColumn) {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];
            if (valueA < valueB) return sortDirection === "asc" ? 1 : -1;
            if (valueA > valueB) return sortDirection === "asc" ? -1 : 1;
        }
        return 0;
    });

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed); // Toggle the collapse state
    };

    return (
        <div  className={`flex flex-col dark:bg-lightGradient bg-newGradient text-white  border rounded-lg cursor-pointer`}>
            <div onClick={onExpand} className="w-[100%] flex p-2 justify-center items-center">
                <div className="w-[50%] flex flex-col justify-around">
                    <div className="pl-6 ml-6">
                        <p className=" text-[16px]">
                            {sourceMonth && "Monthly Source Analysis"}
                            {sourceYear && "Yearly Source Analysis"}
                            {roomMonth && "Monthly Room Analysis"}
                            {roomYear && "Yearly Room Analysis"}
                        </p>
                        <p className=" text-sm">
                            {data?.length} results found
                        </p>
                    </div>
                </div>
                <div className="w-[50%] flex justify-end pr-6">
                    <img src={isExpanded ? arrowUp : arrowDown} alt="" />
                </div>
            </div>
            {isExpanded && (
                <div className={` dark:bg-lightGradient bg-newGradient  text-white`}>
                    <div className="w-[100%] border-b-4 flex p-2 justify-center items-center">
                        <div className="w-[20%] text-center text-base font-medium flex items-center" onClick={() => handleSort(room ? "roomName" : "source")}>
                            <p className="w-[55%]">{room ? "Rooms" : "Source"}</p>
                            {sortColumn === (room ? "rooms" : "source") && (
                                <img src={sortDirection === "asc" ? arrowUp : arrowDown} alt="Sort Indicator" className="w-4 h-4" />
                            )}
                        </div>
                        <div className="w-[25%] text-center text-base font-medium">
                            <p className="w-[70%]">Revenue</p>
                        </div>
                        <div className="w-[20%] text-center text-base font-medium">
                            <p className="w-[70%]">ADR</p>
                        </div>
                        <div className="w-[20%] text-center text-base font-medium">
                            <p className="w-[80%]">Room Nights</p>
                        </div>
                        <div className="w-[20%] text-center text-base font-medium">
                            <p className="w-[80%]">Reservation</p>
                        </div>
                    </div>
                    <div className="w-[100%] flex flex-col">
                        <div class="h-[250px] scroll-container">
                            {sortedData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <DataRow
                                            key={index}
                                            item={item}
                                            index={indexOfFirstRow + index}
                                            selectedRows={selectedRows}
                                            room={room}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default RevenueTable;

const DataRow = ({
    data,
    room,
    item,
    index,
}) => {
    Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
    };
    const color = [
        "#1abc9c",
        "#f1c40f",
        "#2ecc71",
        "#2980b9",
        "#fad390",
        "#f8c291",
        "#60a3bc",
        "#4a69bd",
    ];

    return (
        <div className="flex flex-col py-2">
            <div
                className={`w-[100%] flex p-2  gap-8 justify-center items-center cursor-pointer`}
            >
                <div className="w-[20%] flex gap-2 items-center justify-start pl-6 ">
                    <div
                        className="w-[25px] h-[25px] flex items-center justify-center  text-white text-[12px] rounded-md"
                        style={{ background: color.random() }}
                    >
                        {(item?.source && item?.source.toString().charAt(0)) ||
                            (item?.room && item?.room.toString().charAt(0))}
                    </div>
                    <p className="text-sm font-semibold truncate w-[150px]" id="title">
                        <div className="truncate w-[150px]" data-tooltip-id="my-tooltip" data-tooltip-content={room ? item?.room : item?.source}>
                            {" "}
                            {room ? item?.room : item?.source}
                        </div>
                        <Tooltip id="my-tooltip" style={{ borderRadius: '10px' }} />
                    </p>
                </div>
                <div className="w-[20%] gap-4 flex items-end justify-start">
                    <div className="flex flex-col gap-2 w-1/3">
                        {" "}
                        <p className="text-xs text-start">Last</p>
                        <p className="text-sm whitespace-nowrap"> {formatCurrency(item.lastRevenue)}</p>
                    </div>
                    <div className="flex flex-col gap-2 ml-5">
                        <p className="text-xs text-start">Current</p>
                        <div className="text-sm flex gap-2">
                            <p
                                className={`${item?.currentRevenue > item?.lastRevenue ? "text-green-500" : "text-red-500"}`}
                            >
                                {" "}
                                {formatCurrency(item?.currentRevenue)}
                            </p>
                        </div>
                    </div>

                </div>
                <div className="w-[20%]  flex gap-6 items-end justify-start">
                    <div className="flex flex-col gap-2 w-1/3">
                        {" "}
                        <p className="text-xs text-start">Last</p>
                        <p className="text-sm max-w-28 whitespace-nowrap flex flex-nowrap"> {formatCurrency(item?.lastADR)}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {" "}
                        <p className="text-xs text-start">Current</p>
                        <p
                            className={`text-sm flex gap-2 ${item?.currentADR > item?.lastADR ? "text-green-500" : "text-red-500"}`}
                        >
                            {formatCurrency(item?.currentADR)}
                        </p>
                    </div>

                </div>
                <div className="w-[20%] flex gap-6 items-end justify-start">
                    <div className="flex flex-col gap-2 w-1/3">
                        {" "}
                        <p className="text-xs text-start">Last</p>
                        <p className="text-sm">{item?.lastRoomNights}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {" "}
                        <p className="text-xs text-start">Current</p>
                        <p
                            className={`text-sm flex gap-2 ${item?.currentRoomNights > item?.lastRoomNights ? "text-green-500" : "text-red-500"}`}
                        >
                            {item?.currentRoomNights}
                        </p>
                    </div>

                </div>
                <div className="w-[20%] flex gap-6 items-end justify-start">
                    <div className="flex flex-col gap-2 w-1/3">
                        {" "}
                        <p className="text-xs text-start">Last</p>
                        <p className="text-sm">{item?.lastReservations}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {" "}
                        <p className="text-xs text-start">Current</p>
                        <p
                            className={`text-sm flex gap-2 ${item?.currentReservations > item?.lastReservations ? "text-green-500" : "text-red-500"}`}
                        >
                            {item?.currentReservations}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};
