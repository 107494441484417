import React, { useEffect, useState } from 'react';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;

function MapWithMarkers({height,isPermission,permissionText}) {
  const [map, setMap] = useState(null);
  const [locationNames, setLocationNames] = useState([]);

  useEffect(() => {
    // Check if Google Maps API script is already loaded
    if (!window.google) {
      // Load Google Maps API script
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = initMap;
      document.body.appendChild(script);

      return () => {
        // Clean up after component unmounts
        document.body.removeChild(script);
      };
    } else {
      // Google Maps API script is already loaded
      initMap();
    }
  }, []);

  const locations = [
    { lat: 22.7512, lng: 75.8895 },
    { lat: 22.753236796317925, lng: 75.88375891534149 },
    { lat: 22.750257579588112, lng: 75.90284849778384 },
  ];

  useEffect(() => {
    const fetchLocationNames = async () => {
      const names = await Promise.all(locations.map(fetchLocationName));
      setLocationNames(names);
    };

    fetchLocationNames();
  }, []);

  const fetchLocationName = async (location) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${GOOGLE_MAPS_API_KEY}`);
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        return 'Location not found';
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
      return 'Error fetching location';
    }
  };

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 12,
      center: { lat: 22.7512, lng: 75.8895 },
    });

    setMap(mapInstance);

    locations.forEach((location, index) => {
      const marker = new window.google.maps.Marker({
        position: location,
        map: mapInstance,
        title: `Marker ${index + 1}`,
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${getColor(index)}.png`,
        },
      });

      marker.addListener('click', () => {
        alert(locationNames[index]);
      });
    });

    const bounds = new window.google.maps.LatLngBounds();
    locations.forEach(location => {
      bounds.extend(location);
    });

    mapInstance.fitBounds(bounds);
  };

  const getColor = (index) => {
    const colors = ['blue', 'blue', 'blue', 'blue', 'blue'];
    return colors[index % colors.length];
  };

  return (
    <div className='relative'>
 <div className={` ${!isPermission && 'blur-sm'}`}>
      <div style={{ width: '100%', height: height,borderRadius:"20px" }} id="map" />
      <div>
        <ul>
          {locationNames.map((name, index) => (
            <li key={index} className='flex items-center gap-2 my-4 text-[14px]'> <div className='h-2 w-2 rounded-full'  style={{background:getColor(index)}}></div>{name}</li>
          ))}
        </ul>
      </div>
     
    </div>
    {!isPermission && <div className="absolute top-[40%] left-[40%] bg-[#1D2D44] text-white text-xs px-4 py-2 rounded-xl cursor-pointer">
     {permissionText ? permissionText : "Unlock Now"}
      </div>}
    </div>
   
  );
}

export default MapWithMarkers;
