import React from "react";
import ApexPieChart from "../../components/ApexPieChart";
import AreaChart from "../../components/charts/areaChart";
import Dropdown from "../../components/dropdown";
import filter1 from "../../assets/icons/filter1.svg";
import filter2 from "../../assets/icons/filter2.svg";
import RoomContributions from "../../components/roomContribution";
import filterIcon from "../../assets/icons/FilterIcon.svg";
import Table from "../../components/table/index";
import Arrow from "../../assets/icons/whiteArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
const Analysis = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {  state } = location;
  const data = [
    { label: "Category A", value: 30 },
    { label: "Category B", value: 20 },
    { label: "Category C", value: 50 },
  ];

  const column = [
    {
      name: "Sources",
      selector: (row) => row["source"],
    },
    {
      name: "Previous Year",
      selector: (row) => row["prevYear"],
    },
    {
      name: "Current Year",
      selector: (row) => row["currentYear"],
    },
    {
      name: "Change",
      selector: (row) => row["change"],
    },
  ];
  const tableData = [
    {
      source: "MakeMyTZOOM COMMUNICATIONS PVT. LTD.",
      prevYear: "₹34,200",
      currentYear: "0",
      change: "--",
    },
    {
      source: "go-mmt",
      prevYear: "₹34,200",
      currentYear: "0",
      change: "--",
    },
    {
      source: "Booking.com",
      prevYear: "₹34,200",
      currentYear: "0",
      change: "--",
    },
  ];

  return (
    <div className=" w-[100%] p-4 flex flex-col">
      <div className="flex -mt-4 justify-between w-[100%]">
        <div className="flex items-center">
          {" "}
          <div
            className="h-[40px] rounded-lg bg-[#4267E533] flex justify-center items-center w-[40px] cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <img src={Arrow} className="" height={"30px"} />
          </div>
          <div className="text-[17px] p-4 font-[500]">
            {" "}
            {state?.title || "Analysis"}
          </div>
        </div>
        <div className="p-4 flex gap-4">
          <Dropdown selectedItem={"MMT"} />
          <div className="dark:bg-[#252C3B] bg-[#CCD6E5] cursor-pointer flex items-center h-[28] px-2 rounded-md">
            <img src={filterIcon} />
          </div>
        </div>
      </div>
      <div className=" mb-4 bg-neutral-light-3 w-[100%] rounded-xl  ">
        <div className="flex p-4 items-center justify-between">
          <div className="text-[14px] font-[500]">Rate Tendencies</div>
          <div className="flex items-center gap-2 ">
            <div>
              <img src={filter2} />
            </div>
            <div>
              <img src={filter1} />
            </div>
            <Dropdown selectedItem={"Room type"} />
          </div>
        </div>
        <AreaChart
          height={372}
          xaxisShow={true}
          yaxisShow={true}
          currentSeriesData={state?.currentSeriesData}
          currentSeriesName={state?.currentSeriesName}
          pastSeriesData={state?.pastSeriesData}
        />
      </div>
      <div className="flex w-full  h-[412px] gap-4 ">
        <div className="  bg-neutral-light-3 flex justify-between flex-col  h-[412px] rounded-xl p-4  w-[70%]">
          <div>
            <p className="  text-start text-[17px] font-semibold">
              Contributions
            </p>
          </div>

          <div className="w-[100%] h-[269px] flex justify-center items-center">
            <ApexPieChart height={195} containerWidth={"30%"} data={data} />
          </div>
          <div className="w-[100%] flex flex-col justify-center items-center text-center">
            <div className="text-[13px] font-medium text-[#59647B]">
              Total Revenue
            </div>
            <div className="text-[17px] font-semibold text-[#131926]">
              ₹ 6,68,32,150
            </div>
          </div>
        </div>
        <div className=" w-[30%] rounded-xl ">
          <RoomContributions />
        </div>
      </div>
      <Table columns={column} data={tableData} />
    </div>
  );
};

export default Analysis;
