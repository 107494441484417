import React from 'react';
import './Checkbox.css'; // Import your Tailwind CSS file

const CheckBox = ({ id, checked, onChange,name }) => {
    return (
        <div className="checkbox-apple">
            <input className="yep"
                id={`check-apple-${name}`}
                type="checkbox"
                checked={checked}
                onChange={onChange} />
            <label htmlFor={`check-apple-${name}`}></label>
        </div>
    );
};

export default CheckBox;
