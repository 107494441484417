import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { LoginUser } from "./slices/onBoarding";
import dashboardReducer from "./states/dashboard";
import { SettingsAccount_Security } from "./slices/settings/accountSecurity";
import { SettingsAccount_UserInterface } from "./slices/settings/userInterface";
import { AlertAndNotifications } from "./slices/settings/alertsNotifications";
import { UserManangemt } from "./slices/settings/userManagement";
import { MyPropertyManagement } from "./slices/settings/myProperties";
import { RoomAPI } from "./slices/rooms";
import {RateMonitor} from './slices/rateMonitor/index'
import {Reputation} from './slices/reputation/index'
import  {ChannelManagerMealPlan} from './slices/settings/channelManager/mealPlan'
import {ChannelManageRoomTypes} from './slices/settings/channelManager/roomTypes'
import {ChannelManagerRatePlan} from './slices/settings/channelManager/ratePlan'
import { Reservation} from './slices/reservations/index'
import loginDataReducer from './states/login'
import {RateSuggestion} from './slices/rateSuggestions/index'
import isAdminSlice from './states/admin'
import tokenSlice from './states/tokenState'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    isToken:tokenSlice,
    isAdmin : isAdminSlice,
    dashboard: dashboardReducer,
    loginData:loginDataReducer,
    [LoginUser.reducerPath]: LoginUser.reducer,
    [SettingsAccount_Security.reducerPath]: SettingsAccount_Security.reducer,
    [SettingsAccount_UserInterface.reducerPath]:
      SettingsAccount_UserInterface.reducer,
    [AlertAndNotifications.reducerPath]: AlertAndNotifications.reducer,
    [UserManangemt.reducerPath]: UserManangemt.reducer,
    [MyPropertyManagement.reducerPath]: MyPropertyManagement.reducer,
    [RoomAPI.reducerPath] : RoomAPI.reducer,
    [RateMonitor.reducerPath] : RateMonitor.reducer,
    [Reputation.reducerPath] : Reputation.reducer,
    [ChannelManagerMealPlan.reducerPath] : ChannelManagerMealPlan.reducer,
    [ChannelManageRoomTypes.reducerPath] : ChannelManageRoomTypes.reducer,
    [ChannelManagerRatePlan.reducerPath] : ChannelManagerRatePlan.reducer,
    [Reservation.reducerPath] : Reservation.reducer,
    [RateSuggestion.reducerPath] : RateSuggestion.reducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      LoginUser.middleware,
      SettingsAccount_Security.middleware,
      SettingsAccount_UserInterface.middleware,
      AlertAndNotifications.middleware,
      UserManangemt.middleware,
      MyPropertyManagement.middleware,
      RoomAPI.middleware,
      RateMonitor.middleware,
      Reputation.middleware,
      ChannelManagerMealPlan.middleware,
      ChannelManageRoomTypes.middleware,
      ChannelManagerRatePlan.middleware,
      Reservation.middleware,
      RateSuggestion.middleware
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
