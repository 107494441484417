import React, { useEffect, useState } from 'react'
import ChannelCard from '../../channelcard';
import SettingButton from '../../../../components/settingButton/SettingButton';
import { useEditReportSchedulingMutation } from '../../../../redux/slices/settings/reportScheduling';
import { CurrentProperty, UserId } from '../../../../utils/impVars';
import { toast } from 'react-toastify';
import BackdropLoader from '../../../../components/newLoader';

const PaceReport = ({ handleBackButtonClick, data, refetch }) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const userId = UserId();
    const hId = CurrentProperty();
    const [email, setEmail] = useState(null);
    const [whatsapp, setWhatsapp] = useState(null);
    const [editReportSchedule] = useEditReportSchedulingMutation()

    const handleCardClick = (cardTitle) => {
        setSelectedCard(cardTitle);
    };

    // const handleBackButtonClick = () => {
    //     setSelectedCard(null)
    // }
    const renderComponent = () => {
        switch (selectedCard) {
            case "Email":
            // return <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />;
            case "Whatsapp":
            // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
            default:
                return null;
        }
    };

    useEffect(()=>{ if (email != null) {
        changePace();
    }},[email])

    useEffect(() => {
        if (whatsapp != null) {
            changePace();
        }
    }, [whatsapp]);

    function changePace() {
        setIsLoading(true)
        const body = {
            paceReport: {
                email:
                    email != null ? email : data?.email,
                whatsapp:
                    whatsapp != null ? whatsapp : data?.whatsapp,
            },
        };
        editReportSchedule({ userId, hId, body })
            .unwrap()
            .then((res) => {
                refetch();
                setIsLoading(false)
                toast.success("Updated Successfully!", { position: "bottom-right" });
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }


    return (
        <>
          <BackdropLoader loading={isLoading} />
            {selectedCard ? (
                <>{renderComponent()}</>
            ) :
                (
                    <div className='flex flex-col gap-4'>
                        <SettingButton Title={"Pace Report"} table={true} handleBackButtonClick={handleBackButtonClick} />
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-1'>
                                <ChannelCard
                                    title={"Email"}
                                    onClick={() => handleCardClick("")}
                                    check={true}
                                    checked={
                                        data?.email && email === null
                                            ? data?.email
                                            : email
                                    }
                                    onChange={(e) => {
                                        setEmail(e.target.checked);
                                    }}
                                />
                                <ChannelCard
                                    title={"Whatsapp"}
                                    onClick={() => handleCardClick("")}
                                    check={true}
                                    checked={
                                        data?.whatsapp && whatsapp === null
                                            ? data?.whatsapp
                                            : whatsapp
                                    }
                                    onChange={(e) => {
                                        setWhatsapp(e.target.checked);
                                    }}
                                />
                            </div>
                        </div>
                        {/* {renderComponent()} */}
                    </div>)}


        </>
    )
}

export default PaceReport