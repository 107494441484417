import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../redux/slices/onBoarding";
import Password from '../../assets/icons/onboarding/Password.svg'
import InputField from '../../components/inputFeild';
import { toast } from "react-toastify";
import Img from '../../assets/img/Branding.svg'
import Img2 from '../../assets/img/Frame 1321314698.svg'
import { FormProvider, useForm } from 'react-hook-form';
import SimpleButton from "../../components/button/SimpleButton";
import MyUniversalComponent from "../../components/sideComponent/leftSection";
import Backdrop from "../../components/backdrop";
import { SyncLoader } from "react-spinners";
import { useState } from "react";

const CreateNewPassword = ({ text }) => {
    const [loading, setLoading] = useState(false);
    const emailIdString = window.localStorage.getItem('email');

    const [resetPassword] = useResetPasswordMutation({
        email: emailIdString,
    })
    const navigate = useNavigate();
    const methods = useForm({});

    const onSubmit = async (data) => {
        setLoading(true);
        const newPasswordData = {
            newPass: data.newPass,
            confirmPass: data.confirmPass
        };
        resetPassword(newPasswordData)
            .unwrap()
            .then((res) => {
                toast.success(res.message, { position: "bottom-right" });
                setLoading(false);
            })
            .catch((err) => {
                toast.error(err.message, { position: "bottom-right" });
                setLoading(false);
            });
    }

    const newPassword = methods.watch("newPass");
    return (
        <>
            {
                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>
            }
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className='login-container'>
                        <MyUniversalComponent
                            title="Made For True Hoteliers"
                            subtitle="Step into a world where innovation meets intuition, tailored specifically for the discerning hotelier. Unlock the gateway to a new era of hospitality excellence with RMS - where every detail is designed with you in mind."
                            imageSrc={Img2}
                        />
                        <div className='login-main'>
                            <div className='login-main-content'>
                                <div className='flex w-full justify-center'>
                                    <img className='img-logo' src={Img} alt='' />
                                </div>
                                <div className='flex items-center justify-center flex-col gap-2'>
                                    <h1 className='text-[1.2rem] text-[#0D1321] text-center font-sans font-semibold '>Create a New Password</h1>
                                    <p className='text-[0.8rem] text-center text-[#485266]'>Choose a secure and memorable password to enhance your account security.</p>
                                </div>
                                <div className='flex flex-col w-full items-center justify-center gap-0  rounded-lg drop-shadow-3xl bg-white '>
                                    <div className='w-full rounded-t-lg'>
                                        <InputField
                                            placeholder={"New Password"}
                                            className={"w-full text-gray-600"}
                                            label={"New Password"}
                                            id={"newPass"}
                                            control={methods.control}
                                            name={"newPass"}
                                            startIcon={Password}
                                            type={"password"}
                                            rules={{
                                                required: 'Password is required',
                                                min: v => v.length >= 8 || 'Password must be at least 8 characters long',
                                                max: v => v.length <= 20 || 'Password must not exceed 20 characters',
                                                uppercase: v => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
                                                lowercase: v => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
                                                digit: v => /[0-9]/.test(v) || 'Password must contain at least one digit',
                                                specialCharacter: v => /[^A-Za-z0-9]/.test(v) || 'Password must contain at least one special character'
                                            }}
                                        />

                                    </div>
                                    <div className='w-full rounded-b-lg overflow-hidden'>
                                        <InputField
                                            placeholder={"Confirm Password"}
                                            className={"w-full text-gray-600"}
                                            label={"Confirm Password"}
                                            id={"confirmPass"}
                                            control={methods.control}
                                            startIcon={Password}
                                            name={"confirmPass"}
                                            type={"password"}
                                            rules={{
                                                required: 'Confirm Password is required',
                                                validate: value =>
                                                    value === newPassword || 'The passwords do not match',
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className='flex flex-col w-full gap-4'>
                                    <SimpleButton text='Reset Password' type='submit' />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};
export default CreateNewPassword;