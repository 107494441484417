import React, { useEffect, useState } from "react";
import DynamicTable from "../../components/dynamicTable";
import Dropdown from "../../components/dropdown";
import img1 from "../../assets/icons/Layer_1.svg";
import img3 from "../../assets/icons/Layer_3.svg";
import img4 from "../../assets/icons/Layer_5.svg";
import Calendar from "../../components/calendar";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetReservationQuery } from "../../redux/slices/reservations";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { format } from "date-fns";
import BackdropLoader from "../../components/newLoader";
import { useGetFiltersQuery } from "../../redux/slices/dashboard";
import { AiFillEye } from "react-icons/ai";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";
import { useIsIpad } from "../../utils/isIpad";
import NoDataFound from "../../components/noData";
import useIntelligenceDateRange from "../../utils/dateRange";

const Reservations = () => {
  const location = useLocation();
  const isIpad = useIsIpad();
  const [pageNumber,setPageNumber]=useState(1);
  console.log(pageNumber,"pageNumber")
  const dayFilter = [
    { label: "7D", value: "7D" },
    { label: "15D", value: "15D" },
    { label: "30D", value: "30D" },
    { label: "45D", value: "45D" },
    { label: "60D", value: "60D" },
  ];
  const { data: filterData } = useGetFiltersQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState({
    label: "All",
    value: "all",
  });
  const [statusOption, setStatusOption] = useState();
  const {
    data: getReservation,
    isFetching,
    isLoading,
  } = useGetReservationQuery(
    {
      hId: CurrentProperty(),
      forDate: format(new Date(selectedDate), "yyyy-MM-dd"),
      type: selectedPeriod?.value,
      pageNumber:pageNumber,
      // selectedSource: "Booking.com",
    },
    {
      skip: selectedPeriod?.value ? false : true,
    }
  );
  const navigate = useNavigate(); // Initialize useHistory hook

  const handleClick = (userData) => {
    // Define function to handle click event
    // Navigate to a different route when clicked
    navigate("/reservation/reservation_Details", {
      state: { resData: userData?.original },
    });
  };
  useEffect(() => {
    if (location?.state?.status === "Cancelled") {
      setSelectedOption({ label: "Cancelled", value: "Cancelled" });
    }
  }, [location.state]);
  useEffect(() => {
    if (filterData && filterData?.data[3]?.types[0]) {
      setSelectedPeriod(filterData?.data[3]?.types[0]);
    }
  }, [filterData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Guest Details",
        width: "100%",
        Cell: ({ row }) => {
            
          return (
            <div className=" text-sm  min-w-[100%] max-w-[100%]  rounded-s-lg font-normal  text-white ">
              <div
                className="truncate min-w-[100px] max-w-[100px]"
                data-tooltip-id={`${row.index}tooltip`}
                data-tooltip-content={
                  row?.original.reservations?.guestsDetails?.guestDetails?.name
                }
              >
                {row?.original.reservations?.guestsDetails?.guestDetails?.name}
                <Tooltip variant="dark" id={`${row.index}tooltip`} />
              </div>
              <div className="flex gap-1">
              <img src={img1} className="filter dark:invert" />
                <p>
                  {
                    row?.original?.reservations?.bookingDetails?.roomDetails?.pax
                      ?.totalAdults
                  }
                </p>
                <img src={img4}  className="filter dark:invert"/>
                <p>1</p>
                <img src={img3} className="filter dark:invert" />
                <p>
                  {row?.original?.reservations?.bookingDetails?.totalNights}
                </p>
              </div>
            </div>
          );
        },
      },
      // {
      //   // Header: "Reservation ID",
      //   accessor: "lefttosell",
      //   width: "100%",
      //   Cell: ({ value }) => (
      //     <div className="text-neutral-dark-25  text-sm font-normal lg:w-auto w-9 gap-1 flex">
      //       {value}
      //     </div>
      //   ),
      // },
      {
        Header: "Status ",
        width: "100%",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <div className="flex items-center justify-start ">
              <div
                className={`w-fit px-2 h-7 min-w-[110px] max-w-[100px] truncate rounded-lg font-medium ${isIpad ? "text-xs" : "text-sm"} text-sm py-1 ${
                  row.original.bookingStatus === "Cancelled"
                    ? "bg-red-100 text-red-500"
                    : "bg-green-100 text-green-500"
                }  justify-center `}
                title={row.original.bookingStatus}
              >
                {row.original.bookingStatus
                  ?.toString()
                  ?.charAt(0)
                  .toUpperCase() + row.original.bookingStatus?.slice(1)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Booking Date  ",
        accessor: "bookingDate",
        width: "100%",
        Cell: ({ row }) => (
          <div className="  text-sm font-normal lg:flex lg:flex-row md:flex-col  flex flex-col items-center  gap-2 md:mr-2 mr-2 lg:mr-0">
            <div className="text-white">
              {row?.original?.reservations?.bookingDetails?.createdOn &&
                format(
                  new Date(
                    row?.original?.reservations?.bookingDetails?.createdOn
                  ),
                  "dd MMM yyyy"
                )}
            </div>

            <div></div>
          </div>
        ),
      },
      {
        Header: " Arrival   ",
        accessor: "arrivalDate",
        width: "100%",
        Cell: ({ row }) => (
          <div className="  text-sm font-normal   lg:flex lg:flex-row md:flex-col  flex flex-col items-center gap-2 md:mr-2 mr-2 lg:mr-0">
            <div className="text-white ">
              {row?.original?.reservations?.bookingDetails?.arrivalDate &&
                format(
                  new Date(
                    row?.original?.reservations?.bookingDetails?.arrivalDate
                  ),
                  "dd MMM yyyy"
                )}
            </div>
            <div></div>
          </div>
        ),
      },
      {
        Header: "  Departure  ",
        accessor: "deptDate",
        width: "100%",
        Cell: ({ row }) => (
          <div className="   lg:flex lg:flex-row md:flex-col  flex flex-col items-center  gap-2 md:mr-2 mr-2 lg:mr-0">
            <div className="text-white text-sm font-normal">
              {row?.original?.reservations?.bookingDetails?.departureDate &&
                format(
                  new Date(
                    row?.original?.reservations?.bookingDetails?.departureDate
                  ),
                  "dd MMM yyyy"
                )}
            </div>

            <div></div>
          </div>
        ),
      },
      {
        Header: "Total",
        accessor: "totalCharges",
        width: "100%",
        Cell: ({ row }) => (
          <div className="w-full  flex justify-around items-center mr-2 -ml-4 ">
            <div className="lg:flex lg:flex-row md:flex-col  flex flex-col  items-center  gap-2">
              <div className="text-white text-sm font-normal">
                {formatCurrency(
                  Math.round(
                    row?.original?.reservations?.priceSummary?.totalCost
                  )
                )}
              </div>

              <div></div>
            </div>
            <div className="mr-2"></div>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        width: "100%",
        Cell: ({ row }) => (
          <div
            className=" cursor-pointer flex items-center gap-2 text-lg"
            onClick={() => handleClick(row)}
          >
            <AiFillEye /> view
          </div>
        ),
      },
    ],
    []
  );

  const filteredData =
    getReservation?.data &&
    getReservation?.data?.bookingData.filter((item) => {
      return (
        item?.bookingStatus?.toLowerCase() ===
        selectedOption?.value?.toLowerCase()
      );
    });

  useEffect(() => {
    if (getReservation?.data?.allBookingStatus) {
      const newOpt = getReservation?.data?.allBookingStatus?.map((item) => {
        return {
          label: item?.bookingLabel,
          value: item?.bookingLabel,
        };
      });
      if (newOpt) {
        setStatusOption(newOpt);
      }
    }
  }, [getReservation?.data]);
  const intelligenceDateRange = useIntelligenceDateRange()
  return (
    <div className="p-4">
      <BackdropLoader loading={isFetching || isLoading} />
      <div className="  py-2 rounded-lg">
        <div className="flex flex-col gap-2 md:gap-0 md:flex-row  md:justify-between md:items-center">
          <div>
            <h1 className="text-white dark:text-black text-lg font-medium py-2 px-4  flex items-center gap-2">
              {/* <BackButton mainDashboard={true} /> */}
               Reservations
            </h1>
          </div>
          <div className="flex gap-4">
            {/* <div>
              <Dropdown selectedItem={"OTA"} />
            </div> */}
            <div className="mr-3">
              <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                minStart={intelligenceDateRange.fromDate}
                maxStart={intelligenceDateRange.toDate}
                UpdateOneDate={true}
              />
            </div>
            <div className="flex items-center gap-2 ">
              <div className="text-sm dark:text-black text-white">Status </div>
            <Dropdown
              setSelectedItems={setSelectedOption}
              selectedItem={selectedOption?.label}
              options={
                statusOption && [
                  ...[{ label: "All", value: "all" }],
                  ...statusOption,
                ]
              }
            />
            </div>
            
            <div className="mr-3">
              <Dropdown
                selectedItem={selectedPeriod?.label}
                setSelectedItems={setSelectedPeriod}
                options={filterData && filterData?.data[3]?.types}
              />
            </div>
          </div>
        </div>
      </div>
      {getReservation && getReservation?.data?.bookingData ? (
        <div className=" rounded-xl ">
          <DynamicTable
            columns={columns}
            data={
              selectedOption?.label === "All"
                ? getReservation?.data?.bookingData
                : filteredData
            }
            pageOptions={[10,20,30]}
            maxHeight={650}
            isPagination={false}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            customPagination={true}
            totalPages={getReservation?.data?.totalPages}
          />
        </div>
      ):<div className="p-4 rounded-xl bg-[#F6F8FB]  "><NoDataFound/> </div>} 
    </div>
  );
};

export default Reservations;
