import React from "react";
import Chart from "react-apexcharts";

const SemiDoughnutChart = ({ percentage, height, width }) => {
  const options = {
    chart: {
      type: "donut",
      height: height ? height : 350, // Further reduced chart height
      width: width ? width : 350, // Further reduced chart width
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: ["#FFA033", "#FAD5AB", "#9C27B0"],
    },
    labels: ["Occupancy", "Remaining"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        donut: {
          size: "75%", // Further adjusted donut thickness
        },
        stroke: {
          show: true,
          width: 2, // Further adjusted stroke width
          colors: ["transparent"],
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250, // Further adjusted responsive width
            height: 250, // Further adjusted responsive height
          },
        },
      },
    ],
  };

  const series = [percentage, 100 - percentage];

  return (
    <div className="w-[100%] flex justify-center items-center relative top-4">
      <Chart
        options={options}
        series={series}
        type="donut"
        height={height ? height :350}
        width={width ? width :350}
      />{" "}
      {/* Further reduced chart height and width */}
    </div>
  );
};

export default SemiDoughnutChart;
