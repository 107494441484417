import React from "react";

import downloadBtn from "../../../assets/icons/downloadBtn.svg";
import { rateSuggestionExcel } from "./RateSuggestionExcel";

const RateSuggestionExcelDownload = ({ apiData }) => {
  return (
    <>
      <button
        onClick={() => {
          rateSuggestionExcel(apiData);
        }}
        className=" dark:text-mainDark text-white cursor-pointer  relative flex  select-none rounded-md flex-col justify-center  cursor-pointer w-fit  bg-white/30 dark:bg-subMainDark px-2 h-[28px]"
      >
        <div className="flex flex-row gap-2 justify-center items-center">
          <p className="text-[0.8em]  whitespace-nowrap">Download Rates</p>
          <img src={downloadBtn} className="h-[0.8em]" />
        </div>{" "}
      </button>
    </>
  );
};

export default RateSuggestionExcelDownload;
