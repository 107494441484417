import React, { useState } from "react"
import ChannelCard2 from "../../../../components/channelConfiguration/ChannelCard";
import SettingButton from "../../../../components/settingButton/SettingButton";
import InputField from "../../../../components/inputFeild";
import { FormProvider, useForm } from "react-hook-form";
import DynamicCounter from "../../../../components/dynamicCounter/DynamicCounter";
import Table from '../../../../components/table';
import Dropdown from '../../../../components/dropdown';
import Tableactiontype from '../../../../components/table/TableActionTYpe';
import GlobalModalComponent from "../../../../components/modal/newModal";

const ChannelConfiguration = ({ handleBackButtonClick }) => {
    const [isAddChannelOpen, setIsAddChannelOpen] = useState(false);
    const [isAddChannelSetting, setIsAddChannelSetting] = useState(false);
    const [selectedOtaTitle, setSelectedOtaTitle] = useState(null);


    const handleAddChannelClick = () => {
        setIsAddChannelOpen(true);
        setIsAddChannelSetting(false);
        setSelectedOtaTitle(null);
    };

    const handleManageChannel = (title) => {
        setIsAddChannelSetting(true);
        setIsAddChannelOpen(false);
        setSelectedOtaTitle(title);
    };


    return (

        <>
            {isAddChannelOpen ? (
                <AddChannel onClose={() => setIsAddChannelOpen(false)} />
            ) : isAddChannelSetting ? (
                <ChannelSettings onClose={() => setIsAddChannelSetting(false)} otaTitle={selectedOtaTitle} />
            ) : (

                <div className='flex flex-col gap-4 w-[100%]'>
                    <SettingButton text={"Add Channel"} Title={"Channel Configuration"} onClick={handleAddChannelClick} handleBackButtonClick={handleBackButtonClick} table={true} />
                    <div className='flex lg:flex-row md:flex-col gap-2 w-[100%]'>
                        <ChannelCard2 Title={"MMT"} subtitle={"6 Rooms"} onClick={() => handleManageChannel("Make My Trip")} />
                        <ChannelCard2 Title={"Booking.com"} subtitle={"6 Rooms"} onClick={() => handleManageChannel("Booking.com")} />
                        <ChannelCard2 Title={"Agoda"} subtitle={"6 Rooms"} onClick={() => handleManageChannel("Agoda")} />
                        <ChannelCard2 Title={"Goibibo"} subtitle={"6 Rooms"} onClick={() => handleManageChannel("Goibibo")} />
                    </div>
                </div>
            )}

        </>
    )
}
export default ChannelConfiguration;




const AddChannel = ({ onClose }) => {
    const methods = useForm();
    const [numberOfRoom, setNumberOfRoom] = useState(0);
    const[inventory,setInventory] = useState(0)
    const[baseOccupancy,setbaseOccupancy] = useState(0)
    const[maxOccupancy,setMaxOccupancy] = useState(0)
    const[maxAdult,setMaxAdult] = useState(0)
    const[maxChild,setMaxChild] = useState(0)
    const[minRate,setMinRate] = useState(0)
    const[maxRate,setMaxRate] = useState(0)
    const[baseRate,setBaseRate] = useState(0)
    const[additionlAdult,setAdditionlAdult] = useState(0)
    const[additionlChild,setAdditionlChild] = useState(0)
    const onSubmit = async (data) => {
        
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4 w-[100%] h-[85vh]'>
                    <SettingButton text={"Save"} Title={"Add Channel"} onClose={onClose} table={true} />
                    <div className='flex gap-4 w-[100%] p-2 flex-col overflow-y-auto'>
                        <div className="flex flex-col gap-4 w-[100%]">
                            <p className="text-[#131926] text-[13px]">Connection Settings</p>
                            <div className="flex flex-col justify-center gap-2">
                                <p className="text-[#8794AD] text-[12px]">Room Type Name</p>
                                <div className="w-1/2  rounded-lg overflow-hidden">
                                    <InputField
                                        type={'text'}
                                        className={"text-gray-600 w-full bg-[#F6F8FB] h-9"}
                                        label={false}
                                        placeholder={'Delux Room'}
                                        id={'roomTypeName'}
                                        name={'roomTypeName'}
                                        controls={methods.control}

                                    />
                                </div>
                            </div>
                            <div className=" lg:w-1/4 md:w-1/2 flex flex-col justify-center gap-2">
                                <p className="text-[#8794AD] text-[12px]">Inventory</p>
                                <div><DynamicCounter initialValue={8} count={inventory} setCount={setInventory} /></div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 w-[100%]">
                            <p className="text-[#131926] text-[13px]">Occupancy Configuration</p>
                            <div className="flex gap-4 lg:w-1/2">
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Base Occupancy</p>
                                    <DynamicCounter initialValue={8} count={baseOccupancy} setCount={setbaseOccupancy} />
                                </div>
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Max Occupancy</p>
                                    <DynamicCounter initialValue={8} count={maxOccupancy} setCount={setMaxOccupancy}  />
                                </div>
                            </div>
                            <div className="flex gap-4 lg:w-1/2">
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Max Adults</p>
                                    <DynamicCounter initialValue={8} count={maxAdult} setCount={setMaxAdult} />
                                </div>
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Max Child</p>
                                    <DynamicCounter initialValue={8} count={maxChild} setCount={setMaxChild} />
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-2 w-[100%]">
                            <p className="text-[#131926] text-[13px]">Per Night Charge</p>
                            <div className="flex lg:flex-row md:flex-col gap-4 w-3/4">
                                <div className="w-3/4 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Minimum Rate</p>
                                    <DynamicCounter initialValue={"₹ 3,000.00"} count={minRate} setCount={setMinRate} />
                                </div>
                                <div className="w-3/4 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Base Rate</p>
                                    <DynamicCounter initialValue={"₹ 5,000.00"} count={baseRate} setCount={setBaseRate} />
                                </div>
                                <div className="w-3/4 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Maximum Rate</p>
                                    <DynamicCounter initialValue={"₹ 10,000.00"} count={maxRate} setCount={setMaxRate} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-[100%]">
                            <p className="text-[#131926] text-[13px]">Extra Occupancy Charges</p>
                            <div className="flex lg:flex-row md:flex-col gap-4 lg:w-1/2 ">
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Additional Adult</p>
                                    <DynamicCounter initialValue={"₹ 10,000.00"} count={additionlAdult} setCount={setAdditionlAdult} />
                                </div>
                                <div className="w-1/2 flex flex-col justify-center gap-2">
                                    <p className="text-[#8794AD] text-[12px]">Additional Child</p>
                                    <DynamicCounter initialValue={"₹ 10,000.00"} count={additionlChild} setCount={setAdditionlChild} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </FormProvider>
    )
}


const ChannelSettings = ({ onClose, otaTitle }) => {
    const [settingMapModal, setSettingMapModal] = React.useState(false);
    const [settingRoomMapModal, setSettingRoomMapModal] = React.useState(false);
    const handleRateMapButtonClick = (row) => {
        setSettingMapModal(true)
    }
    const handleRoomMapButtonClick = (row) => {
        setSettingRoomMapModal(true)
    }
    const column = [
        {
            name: "Rate Plan Name",
            selector: row => row["ratePlanName"],
            width: "40%"
        },
        {
            name: "Rate Plans",
            selector: row => row["ratePlans"],
        },
        {
            name: "Status",
            selector: row => row["status"],
            cell: (row) => {
                if (row["status"] === "true") {
                    return (
                        <div className='text-[#29CC39] font-[500]'>
                            Mapped
                        </div>
                    );
                } else if (row["status"] === "false") {
                    return (
                        <div className="text-[#081439] font-[500] text-[14px] cursor-pointer" onClick={() => handleRoomMapButtonClick(row)}>
                            Map
                        </div>
                    );
                } else {
                    return null;
                }
            }
        },
        {
            name: 'Actions',
            cell: (row) => {
                return (
                    <Tableactiontype icon={true} />
                )
            }
        },
    ];
    const column2 = [
        {
            name: "Rate Plan Name",
            selector: row => row["ratePlanName2"],
        },
        {
            name: "Base Occupancy",
            selector: row => row["baseOccupancy"],
        },
        {
            name: "Rate Rule",
            selector: row => row["rateRule"],
        },
        {
            name: "Status",
            selector: row => row["status"],
            cell: (row) => {
                if (row["status"] === "true") {
                    return (
                        <div className='text-[#29CC39] font-[500]'>
                            Mapped
                        </div>
                    );
                } else if (row["status"] === "false") {
                    return (
                        <div className="text-[#081439] font-[500] text-[14px] cursor-pointer" onClick={() => handleRateMapButtonClick(row)}>
                            Map
                        </div>
                    );
                } else {
                    return null;
                }
            }
        },

        {
            name: 'Actions',
            width:"25%",
            cell: (row) => {
                return (
                    <Tableactiontype />
                )
            }
        },
    ];

    const data = [
        {
            ratePlanName: "Delux Room",
            ratePlans: "6",
            status: "true",
        },
        {
            ratePlanName: "Delux Room",
            ratePlans: "6",
            status: "false",
        },
        {
            ratePlanName: "Delux Room",
            ratePlans: "6",
            status: "true",
        },

    ]
    const data2 = [
        {
            ratePlanName2: "Delux Room",
            baseOccupancy: "2",
            rateRule: "+ 0 x 1 + 1000",
            status: "false",
        },
        {
            ratePlanName2: "Delux Room",
            baseOccupancy: "2",
            rateRule: "+ 0 x 1 + 1000",
            status: "true",
        },
        {
            ratePlanName2: "Delux Room",
            baseOccupancy: "2",
            rateRule: "+ 0 x 1 + 1000",
            status: "false",
        },

    ]
    return (
        <>
            {settingMapModal && (
                <SettingRateModal
                    setSettingMapModal={setSettingMapModal}
                />
            )}
            {settingRoomMapModal && (
                <SettingRoomModal
                    setSettingRoomMapModal={setSettingRoomMapModal}
                />
            )}
            <div className='flex flex-col gap-4 w-[100%] h-[85vh]'>
                <SettingButton Title={otaTitle} table={true} handleBackButtonClick={onClose} />
                <div className="flex flex-col gap-4 w-[100%] overflow-y-auto rounded-xl">
                    <div className='flex gap-2 w-[100%]'>
                        <div className="relative w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient flex flex-col rounded-xl p-4">
                            <div className='flex items-center justify-between'>
                                <p className='text-[14px] font-[500]'>Room Type Configurations</p>
                                {/* <div className='flex items-center gap-2'>
                                    <Dropdown selectedItem={"Room or Meal"} />
                                    <TableButton title={"Add New"} />
                                </div> */}
                            </div>
                            {data != null && (
                                <Table
                                    columns={column}
                                    pagination={true}
                                    data={data}
                                />
                            )}
                        </div>
                    </div>
                    <div className='flex gap-2 w-[100%]'>
                        <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4 overflow-y-auto">
                            {/* Add the "overflow-y-auto" class here */}
                            <div className='flex items-center justify-between'>
                                <p className='text-[14px] font-[500]'>Rate Plan Configurations</p>
                                {/* <div className='flex items-center gap-2'>
                                    <Dropdown selectedItem={"Room or Meal"} />
                                    <TableButton title={"Add New"} />
                                </div> */}
                            </div>
                            {data2 != null && (
                                <Table
                                    columns={column2}
                                    pagination={true}
                                    data={data2}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}



const SettingRateModal = ({
    setSettingMapModal
}) => {
    return (
        <GlobalModalComponent
            hideModal={() => setSettingMapModal(false)}
            saveBtnTitle={"Apply"}
            title={"Map Rate Plan"}
        >
            <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <p>Rate Plan Name on OTA</p>
                    <Dropdown selectedItem={"hotel@mail.com"} height={36} />
                </div>
                <div className="flex items-center justify-between">
                    <p>Map to</p>
                    <Dropdown selectedItem={"hotel@mail.com"} height={36} />
                </div>
            </div>

        </GlobalModalComponent>
    );
};
const SettingRoomModal = ({
    setSettingRoomMapModal
}) => {
    return (
        <GlobalModalComponent
            hideModal={() => setSettingRoomMapModal(false)}
            saveBtnTitle={"Apply"}
            title={"Map Room Type"}
        >
            <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <p>Rate Plan Name on OTA</p>
                    <Dropdown selectedItem={"hotel@mail.com"} height={36} />
                </div>
                <div className="flex items-center justify-between">
                    <p>Map to</p>
                    <Dropdown selectedItem={"hotel@mail.com"} height={36} />
                </div>
            </div>

        </GlobalModalComponent>
    );
};

