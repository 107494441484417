import React, { useEffect, useRef, useState } from "react";
import formatCurrency from "../../../utils/formatCurrency";
import QuarterlyReportTable from "../../newTable/QuarterlyReportTable";
import Calendar from "../../../components/calendar";
import { DownloadQuaterlyReport } from "../DownloadExcel/QuaterlyReport";
import CustomSkeleton from "../../../components/Skeleton";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import { formatDate } from "../DownloadExcel/formatDate";
import ReviewTable from "../../newTable/ReviewTable";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import CustomCalander from "../../../components/CustomCalender/CustomCalander";
import NoDataFound from "../../../components/noData";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { toast } from "react-toastify";
import whatsappBusiness from '../../../assets/img/whatsapp-business-icon.svg'
import YearReportTable from "../../newTable/YearEndReportTable";
const QuarterlyReport = ({
  data,
  dateRange,
  setDateRange,
  isLoading,
  setIsWhatsApp,
  sendToWhatsappAdmin,
  QuaterlyWhatsAppAdmin,
}) => {
  const dateLimit = useIntelligenceDateRange();

  const [expandedIndex, setExpandedIndex] = useState(0);
  const [showDateRange, setShowDateRange] = useState(false);
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  if (!data && !isLoading) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p>Quarterly Report</p>
        </div>
  
        <div className="h-full flex items-center justify-center w-[100%]">
          {/* <CustomSkeleton height={500} /> */}

          <NoDataFound />
        </div>
      </div>
    );
  }
  if (isLoading && !QuaterlyWhatsAppAdmin) {
    // Render a fallback UI or an error message when data is undefined
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center p-2 font-semibold">
          <p className="text-white dark:text-black">Quarterly Report  </p>
        </div>
        {/* <div className="m-3 w-full flex justify-end items-end">
          <div className="w-fit"></div>
          <h1
            onClick={() => {}}
            className="flex  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={setIsWhatsApp}
            className="flex  w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div> */}
        <div className="w-[100%]">
          <CustomSkeleton  isTransparent={true} animated={true} height={500} title={'Quarterly Report'} />
        </  div>
      </div>
    );
  }
  const { statistics, sourceData, roomData, twoMonthLOS, monthData, monthWiseBreakDown,reviews } =
    data;
  const propertyName = window.localStorage.getItem("propertyName");

  const fromDate = dateLimit.fromDate ? format(new Date(dateLimit.fromDate), "MM/dd/yyyy") : dateLimit?.fromDate ;
  const endDate = dateLimit.toDate ? format(new Date(dateLimit.toDate), "MM/dd/yyyy") :dateLimit?.toDate ;

  return (
    <>
      <div className="flex sticky top-0 items-center mb-2 justify-center p-2 font-semibold">
      <p className="sticky text-white dark:text-black">
        Quarterly Report - {propertyName} (
          {formatDate(new Date(dateRange[0])) || formatDate(data.asOn?.startDate)} -{" "}
          {formatDate(new Date(dateRange[1])) || formatDate(data.asOn?.endDate)} )
        
      </p>
      </div>
      <div className="w-full sticky top-[40px] flex justify-end  ">
        <div className="m-3 w-auto text-white dark:text-black flex items-center justify-end ">
          <div className="w-fit">
            <div className=" flex items-center gap-2 justify-center pr-2">
              <div className="w-fit text-sm">Select date range</div>
              <div className="border dark:border-black p-2 flex items-center rounded-md justify-center">

              <div  > {formatDate(new Date(dateRange[0])) || formatDate(data.asOn?.startDate)} -{" "}
          {formatDate(new Date(dateRange[1])) || formatDate(data.asOn?.endDate)} </div>
              <CustomCalander
                calendarType={"quarter"}
                activeDateArray={"selected"}
                selectedDateArray={dateRange}
                setSelectedDateArray={setDateRange}
                restrictionStart={fromDate}
                restrictionEnd={endDate}
                />
                </div>
            </div>
          </div>
          <h1
            onClick={() => {
              DownloadQuaterlyReport(data);
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>
          <div
            onClick={()=>{setIsWhatsApp();    
           }}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to self
          </div>
          {/* <div
            onClick={() => {
              sendToWhatsappAdmin();
          
            }}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
          <img src={whatsappBusiness} height={20} width={20} alt="" /> Send to Group
          </div> */}
   
        </div>
      </div>

      <div className="overflow-y-auto h-[80%] ">
        <div className="flex flex-col gap-4">
          <QuarterlyReportTable
            data={sourceData}
            arrival={true}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          <QuarterlyReportTable
            data={roomData}
            arrival={true}
            room={true}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />
             <YearReportTable
            data={monthWiseBreakDown}
            reservation={true}
            arrival={true}
            label={'Month-wise Breakdown'}
            // room={true}
            monthWise={true}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
          />
        </div>

        <div className="flex my-4 justify-between gap-4">
          <TableCards
            title={"Revenue"}
            mainData={formatCurrency(statistics[0]?.["Revenue"])}
          />
          <TableCards
            title={"Reservations"}
            mainData={statistics[0]?.Reservations}
          />
          <TableCards
            title={"ADR"}
            mainData={formatCurrency(statistics[0]?.ADR)}
          />
          <TableCards
            title={"Cancellations"}
            mainData={statistics[0]?.Cancellations}
          />
          <TableCards title={"Occupancy"} mainData={statistics[0]?.Occupancy} />
        </div>
        <ReviewTable
          data={reviews}
          arrival={true}
          room={true}
          isExpanded={expandedIndex === 3}
          onExpand={() => handleExpand(3)}
        />
      </div>
    </>
  );
};

export default QuarterlyReport;

const TableCards = ({ mainData, title, height, width }) => {
 
  return (
    <div
      className={`px-4 py-2 w-[100%] dark:bg-lightGradient bg-newGradient rounded-lg border text-white`}
      style={{ height: `${height}px` }}
    >
      <div className=" font-semibold ">{title}</div>
      <div className=" mt-1 flex items-center gap-2 font-medium ">
        <div>{mainData}{title=='Occupancy'&&'%'}</div>
      </div>
    </div>
  );
};
