import React, { useState } from "react";

const HotelPerformanceThirdPage = () => {
  const revenueFactors = [
    "Occupancy Rate", // Percentage of available rooms occupied
    "Average Daily Rate (ADR)", // Average revenue earned per occupied room
    "Revenue per Available Room (RevPAR)", // Revenue per available room, a combination of occupancy rate and ADR
    "Direct Bookings vs. OTA Bookings", // Proportion of direct bookings vs. bookings from Online Travel Agencies
    "Seasonality", // Variations in demand depending on the season
    "Marketing and Promotions", // Effectiveness of marketing campaigns and promotions
    "Guest Reviews and Ratings", // Impact of guest feedback on future bookings
    "Customer Segmentation", // Targeting different customer segments like leisure, corporate, etc.
    "Length of Stay (LOS)", // Average length of guest stays
    "Cancellation Rates", // Rate of cancellations and its impact on revenue
    "Upselling and Cross-Selling", // Additional revenue from upselling rooms or cross-selling services
    "F&B Revenue", // Revenue generated from Food & Beverage services
    "Event and Meeting Space Revenue", // Revenue from renting event and meeting spaces
    "Ancillary Services", // Revenue from additional services like spa, gym, transport, etc.
    "Cost of Acquisition", // Cost associated with acquiring each customer
    "Staff Efficiency and Payroll Costs", // Impact of staffing on operational costs and revenue
    "Dynamic Pricing Strategies", // Revenue management tactics like dynamic pricing
    "Competitor Pricing", // Influence of competitors' pricing strategies
    "Loyalty Programs", // Effectiveness of loyalty programs in retaining customers
    "Local Economic Conditions", // Impact of the local economic environment on travel demand
    "Global Economic Trends", // Broader economic trends affecting travel and tourism
    "Regulations and Compliance", // Impact of regulations such as health and safety, data protection, etc.
    "Technology Adoption", // Use of technology in streamlining operations and improving guest experience
    "Sustainability Practices", // Revenue impact from eco-friendly practices and certifications
    "Guest Personalization", // Impact of personalized experiences on guest satisfaction and revenue
    "Property Condition and Maintenance", // Impact of property condition on guest experience and repeat business
    "Brand Reputation", // Impact of the hotel's brand reputation on revenue
    "Geopolitical Factors", // Influence of geopolitical stability on travel demand
    "Exchange Rates", // Impact of currency exchange rates on international travel
    "Weather Conditions", // Influence of weather patterns on occupancy and bookings
    "Local Events and Attractions", // Impact of nearby events and attractions on hotel demand
  ];

  // Initialize state for checkboxes
  const [checkedState, setCheckedState] = useState(
    new Array(revenueFactors.length).fill(false)
  );
  const [checkedItems, setCheckedItems] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = !updatedCheckedState[index];
    setCheckedState(updatedCheckedState);

    // Update checked items
    const newCheckedItems = revenueFactors.filter(
      (_, idx) => updatedCheckedState[idx]
    );
    setCheckedItems(newCheckedItems);
  };

  console.log(checkedItems);

  return (
    <div className="p-4 flex w-[100%] justify-between gap-4">
      <div className="w-[30%] bg-gradient-to-r rounded-md from-[#060b2873] scrollHide to-[#0a0e2389] p-2 h-[700px] overflow-y-scroll">
        {revenueFactors &&
          revenueFactors.map((item, index) => {
            return (
              <div className="flex items-center gap-4">
                <div className="bg-white/40 w-[100%] select-none flex items-center gap-4 my-2 px-2 text-sm py-2 rounded-md">
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    id={`checkbox-${index}`}
                    checked={checkedState[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />{" "}
                  <label
                    className="cursor-pointer"
                    htmlFor={`checkbox-${index}`}
                  >
                    {item}
                  </label>
                </div>
              </div>
            );
          })}
      </div>
      <div className="w-[70%] h-[700px] overflow-y-scroll">
        {checkedItems?.map((item, idx) => {
          return (
            <div className="mb-4 bg-white/80 p-4 rounded-lg">
              <div className="text-2xl font-semibold">{item}</div>
              <div className="text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Curabitur pretium tincidunt lacus. 
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HotelPerformanceThirdPage;
