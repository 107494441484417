import React, { useEffect, useState } from 'react'
import '../onboarding/Global.css';
import SimpleButton from '../../components/button/SimpleButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropertyCard from '../../components/propertyCard';
import TitleComponent from '../../components/cardComponent';
import Backdrop from '../../components/backdrop';
import { SyncLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const ConfirmProperty = ({ onSubmit,data ,setSteps , selectedOTAs ,onSelectedOTAsChange}) => {
    const methods = useForm({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [checkedCount, setCheckedCount] = useState(0);
    const handleClick = (data) => {
        
        setSteps({
            step1: false, step2: false, step3: false, step4: false,
        })

        onSubmit(data);
    };
  
   const handlePropertyCardCheck = (ota) => {
    const updatedSelectedOTAs = [...selectedOTAs, ota];

    onSelectedOTAsChange(updatedSelectedOTAs);
    setCheckedCount((prevCount) => prevCount + 1);
};


const handlePropertyCardCheck2 = (ota) => {

    onSelectedOTAsChange(selectedOTAs => selectedOTAs.filter(selectedOta => selectedOta !== ota)); // Update selected OTAs in parent component
    setCheckedCount((prevCount) => prevCount - 1);
};

function filterDataWithLink(data) {
    return data.filter(item => item.link !== null);
}
const handleAddOTAsClick = (newOTA) => {
    if (checkedCount === 0) {
        toast.error("Please select atleast one ota", { position: "bottom-right" });
        return;
    }
    handleClick(methods.getValues());
    setSteps({
        step1: false,
        step2: false,
        step3: false,
        step4: true,
    });
    const updatedSelectedOTAs = [...selectedOTAs, newOTA];
    // Perform further actions with selectedOTAs array
    navigate('/competition'); // Navigate to the desired page
};
useEffect(() => {
    const delayTransition = setTimeout(() => {
        setLoading(false);
    }, 2000); // Adjust the delay time as needed

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(delayTransition);
}, []);
    return (
        <>
         {
                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>

            }
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleClick)}>
                <div className='flex items-center justify-center flex-col gap-2'>
                    <TitleComponent title={'Confirm Your Property'}
                        subtitle={'is that your property?'} />
                </div>

                <div className='flex flex-col items-center justify-center w-full gap-4'>
                {data && data.length > 0 && (
                        <div className=' h-[400px] bg-white gap-4 overflow-x-auto'>
                            {filterDataWithLink(data).map((ota) => (
                                <div key={ota.id}>
                                    <PropertyCard
                                        link={ota.otalink}
                                        onCheck2={() => handlePropertyCardCheck2(ota)}
                                        onCheck={() => handlePropertyCardCheck(ota)}
                                        name={ota.otaname}
                                        address={"Bogmalo, Goa"}
                                        img={ota.otaImage}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                        <SimpleButton text={checkedCount > 0 ? `Add ${checkedCount} OTAs` : 'Add Your OTAs'}  type='button' onClick={handleAddOTAsClick} />
                    <div className='flex flex-col w-full gap-4 items-center'>
                        <div className='text-sm font-[600] text-[#3F70ED]'>Edit Links</div>

                    </div>
                </div>
            </form>
        </FormProvider></>
       
    )
}

export default ConfirmProperty