

import { emptySplitApi } from "../../injectEndpoints";

export const RoomAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoomDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRoomDetail`,
          method: "GET",
          params: {
            hId: data.hId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserListUserManagement"],
    }),
    getMasterRoom: builder.query({
      query: (data) => {
        return {
          url: `/utils/getMasterRoomTypes`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserListUserManagement"],
    }),
    addOtaRoomMapping: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/postOTARoomMapping`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addOtaRoomMapping"],
    }),
    addPropertyRoomMapping: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/postPropertyRoomMapping`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addPropertyRoomMapping"],
    }),
    updateOtaRoomMapping: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateOTARoomMapping`,
          method: "PATCH",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["updateOtaRoomMapping"],
    }),
    updatePropertyRoomMapping: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updatePropertyRoomMapping`,
          method: "PATCH",
          body: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["updatePropertyRoomMapping"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetRoomDetailsQuery,
  useAddOtaRoomMappingMutation,
  useAddPropertyRoomMappingMutation,
  useUpdateOtaRoomMappingMutation,
  useUpdatePropertyRoomMappingMutation,useGetMasterRoomQuery } = RoomAPI;
