import React, { useCallback, useEffect, useState } from "react";
import Tableactiontype from "../../../components/table/TableActionTYpe";
import Table from "../../../components/table";
import TableButton from "../../../components/settingButton/TableButton";
import Dropdown from "../../../components/dropdown";
import InputField from "../../../components/inputFeild";
import { FormProvider, useForm } from "react-hook-form";
import GlobalModalComponent from "../../../components/modal/newModal";
import {
  useAddUsersUserManagementMutation,
  useDeactivateUsersUserManagementMutation,
  useDeleteUsersUserManagementMutation,
  useEditUsersUserManagementMutation,
  useGetUserManagementDetailsQuery,
  useGetUserManagementPropertyQuery,
} from "../../../redux/slices/settings/userManagement";
import { useGetAllModulesQuery } from "../../../redux/slices/dashboard";
import CustomSkeleton from "../../../components/Skeleton";
import { toast } from "react-toastify";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import BackdropLoader from "../../../components/newLoader";

const UserManagement = ({ handleBackButtonClick }) => {
  const [isAddUser, setIsAddUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { data: userManagementProperties } = useGetUserManagementPropertyQuery({
    userId: UserId(),
    // hId:CurrentProperty()
  });
  const handleAddMealPlanClick = () => {
    setIsAddUser(true);
  };
  const [deleteUsersUserManagement] = useDeleteUsersUserManagementMutation();
  const [deactivateUsersUserManagement] =
    useDeactivateUsersUserManagementMutation();
  const {
    data: usersList,
    isLoading: userListIsLoading,
    isFetching,
    refetch,
  } = useGetUserManagementDetailsQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (userListIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [userListIsLoading]);

  const handleDelete = (userId) => {
    setIsLoading(true);
    deleteUsersUserManagement({ userId: userId })
      .unwrap()
      .then((res) => {
        refetch();
        toast.success("User Delete Successfully!", {
          position: "bottom-right",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message, { position: "bottom-right" });
        setIsLoading(false);
      });
  };

  const handleDeactivate = (userId) => {
    setIsLoading(true);
    deactivateUsersUserManagement({ userId: userId })
      .unwrap()
      .then((res) => {
        refetch();
        setIsLoading(false);
        toast.success("User Deactivate Successfully!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message, { position: "bottom-right" });
      });
  };

  const column = [
    {
      name: "Name",
      selector: (row) => row["name"],
    },
    // {
    //   name: "Role",
    //   selector: (row) => row["role"]?.["roleName"],
    // },
    {
      name: "Email Address",
      selector: (row) => row["email"],
    },
    // {
    //   name: "Current Rating",
    //   selector: (row) => row["rating"],
    //   cell: (row) => {
    //     return <div className="text-[#60FBBA]">{row["rating"]}</div>;
    //   },
    // },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            showDelete={UserId() === row?.userId ? false : true}
            handleDelete={() => handleDelete(row?.userId)}
            onViewClick={() => {
              setEditData(row);
              setIsEdit(true);
            }}
            onEditClick={() => {
              setEditData(row);
              setIsEdit(true);
            }}
            showDeactivate={UserId() === row?.userId && row?.isVerified == true ? false : true}
            handleDeactivate={() => handleDeactivate(row?.userId)}
          />
        );
      },
    },
  ];


  const ActiveUser =
    usersList &&
    usersList?.data.length > 0 &&
    usersList?.data.filter((item) => {
      return item?.isDeleted === false;
    });

  return (
    <>
      <BackdropLoader loading={isLoading} />
      {isAddUser || isEdit ? (
        <AddNewUser
          onClose={
            isEdit
              ? () => {
                setEditData(null);
                setIsEdit(false);
              }
              : () => setIsAddUser(false)
          }
          properties={
            userManagementProperties && userManagementProperties?.data
          }
          isEdit={isEdit}
          editData={editData}
          refetch={refetch}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%]">
          <div className="text-[17px] text-[#ffffff] h-[44px] flex items-center dark:text-black mt-1">
            User Management
          </div>
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-newGradient dark:bg-lightGradient text-lightFontColor flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Users</p>
                <div className="flex items-center gap-2">
                  <Dropdown selectedItem={"All Users"} />
                  <TableButton
                    title={"Add User"}
                    onClick={handleAddMealPlanClick}
                  />
                </div>
              </div>
              {usersList &&
                usersList?.data != null &&
                usersList?.data.length > 0 &&
                !isLoading &&
                !isFetching ? (
                <Table columns={column} pagination={true} data={ActiveUser} />
              ) : (
                <CustomSkeleton width={"100%"} height={400} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserManagement;

const AddNewUser = ({ onClose, properties, editData, isEdit, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const userId = window.localStorage.getItem("userId");
  const methods = useForm({});
  const [isChecked, setIsChecked] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const { data: getAllModules, isLoading: getAllModulesIsLoading } =
    useGetAllModulesQuery();
  const [addUsersUserManagement] = useAddUsersUserManagementMutation();
  const [editUsersUserManagement] = useEditUsersUserManagementMutation();
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [isStateSaved, setIsStateSaved] = useState(false);
  const [modulesAPIData, setModulesAPIData] = useState();
  const hId = CurrentProperty();

  useEffect(() => {

    if (isEdit) {
      methods.setValue("name", editData?.name);
      methods.setValue("email", editData?.email);
      methods.setValue("role", editData?.role?.roleName);
      setActiveIndex(0)
      setModulesAPIData(editData?.properties[0]?.permission)
    }
  }, [editData]);

  const newProperties =
    properties &&
    properties.map((item, index) => {
      return {
        hId: item?.propertyId,
        isSelected:
          selectedProperty?.length > 0 &&
            selectedProperty.filter((item) => {
              return item?.propertyId === item;
            })
            ? true
            : false,
        permission:  modulesAPIData,
      };
    });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const body = {
      name: data?.name,
      role: data?.role,
      // designation: "Software Engineer",
      email: data?.email,
      password: data?.password,
      // phoneNumber: "9926709996",
      properties: newProperties,
    };
    const editBody = {
      updatedName: data?.name,
      updatedRole: data?.role,
      // designation: "Software Engineer",
      updatedEmail: data?.email,
      updatedisActive: true,
      // phoneNumber: "9926709996",
      updatedProperties: newProperties,
    };
    if (editData) {
      editUsersUserManagement({ userId: editData?.userId, editBody })
        .unwrap()
        .then((res) => {
          refetch();
          toast.success("User Updated Successfully!", {
            position: "bottom-right",
          });
          setIsLoading(false);
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          onClose();
        });
    } else {
      addUsersUserManagement({ userId: userId, body })
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false);
          toast.success("User Added Successfully!", {
            position: "bottom-right",
          });
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message, { position: "bottom-right" });
          onClose();
        });
    }
  };
  const [selectedData, setSelectedData] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = useCallback((data) => {
    setSelectedData((prevData) => {
      // Check if data already exists in selectedData array
      const index = prevData.findIndex((item) => item.title === data.title);
      if (index !== -1) {
        // If exists, update the item
        const updatedData = [...prevData];
        updatedData[index] = data;
        return updatedData;
      } else {
        // If not exists, add the item
        return [...prevData, data];
      }
    });
  }, []);
  // Data for rendering accordion items
  const accordionData = [
    {
      title: "Parent 1",
      children: [
        { label: "Child 1" },
        { label: "Child 2" },
        { label: "Child 3" },
      ],
    },
    {
      title: "Parent 2",
      children: [{ label: "Child A" }, { label: "Child B" }],
    },
  ];

  const addIsSelectedKey = (modules) => {
    return modules.map((module) => {
      // Add isSelected key with false value to module
      const updatedModule = { ...module, isSelected: false };

      // Check if module has submodules
      if (module.subModules) {
        // Add isSelected key with false value to each submodule
        updatedModule.subModules = module.subModules.map((subModule) => ({
          ...subModule,
          isSelected: false,
        }));
      }
      return updatedModule;
    });
  };
  useEffect(() => {
    if (getAllModules?.data) {
      let updatedGetAllModulesData = addIsSelectedKey(getAllModules?.data);
      setModulesAPIData(updatedGetAllModulesData);
    }
  }, [getAllModules?.data]);

  const moduleHandleChange = (index) => {
    setModulesAPIData((prevModules) => {
      const updatedModules = [...prevModules]; // Create a copy of the array
      updatedModules[index] = {
        ...updatedModules[index],
        isSelected: !updatedModules[index].isSelected,
        subModules: updatedModules[index].subModules.map((subModule) => ({
          ...subModule,
          isSelected: !updatedModules[index].isSelected,
        })),
      };
      return updatedModules;
    });
  };
  const saveState = (data) => {
    setIsStateSaved(true);
    setModulesAPIData(data);
  };

  // useEffect(() => {
  //   if(isStateSaved){
  //     setModulesAPIData(modulesAPIData);
  //     alert("State Saved")
  //   }else{
  //     alert("State not saved!")
  //   }
  // }, [isStateSaved]);

  const subModuleHandleChange = (index, childIndex) => {
    setModulesAPIData((prevModules) => {
      const updatedModules = [...prevModules]; // Create a copy of the array
      updatedModules[index] = {
        ...updatedModules[index],
        subModules: [
          ...updatedModules[index].subModules.slice(0, childIndex), // Copy the subModules array up to the childIndex
          {
            ...updatedModules[index].subModules[childIndex], // Copy the target subModule
            isSelected:
              !updatedModules[index].subModules[childIndex].isSelected, // Toggle the isSelected value
          },
          ...updatedModules[index].subModules.slice(childIndex + 1), // Copy the subModules array after the childIndex
        ],
      };
      return updatedModules;
    });
  };

  const abc =
    modulesAPIData &&
    modulesAPIData.map((item) => {
      return {
        moduleName: item.moduleName,
        moduleID: item.moduleID,
        subModules: item?.subModules?.map((subMod) => {
          return {
            subModuleName: subMod?.subModuleName,
            subModuleID: subMod?.subModuleID,
            dependancy: subMod?.dependancy,
            isSelected: true,
          };
        }),
        isSelected: true,
      };
    });
  const handleProperty = (propertyId, index) => {
    if (activeIndex === index) {
      const arr =
        selectedProperty &&
        selectedProperty.filter((item) => {
          return item != propertyId;
        });
      setSelectedProperty(arr);
    } else {
      setSelectedProperty((prev) => [...prev, propertyId]);
    }
  };

  return (
    <>
      <GlobalModalComponent
        hideModal={() => onClose(false)}
        saveBtnTitle={isEdit ? "Update" : "Create"}
        title={isEdit ? "Edit User" : "Add New User"}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {!getAllModulesIsLoading ? (
              <div className="flex text-ligh  gap-2">
                <div className="flex flex-col  mb-2 gap-4  w-[100%]">
                  <div className="flex flex-col justify-center gap-2">
                    <p className="w-[100%] text-[14px]">
                      Full Name
                    </p>
                    <div className="">
                      <InputField
                        type={"text"}
                        label={false}
                        className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                        placeholder={"Full Name"}
                        id={"name"}
                        name={"name"}
                        rules={{ required: "Full Name Is Required", }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    <p className="w-[100%] text-[#59647B] text-[14px]">Email</p>
                    <div className="">
                      <InputField
                        type={"email"}
                        label={false}
                        className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                        placeholder={"Email"}
                        id={"email"}
                        name={"email"}
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    {!isEdit && (
                      <p className="w-[100%] text-[#59647B] text-[14px]">
                        Password
                      </p>
                    )}
                    {!isEdit && (
                      <div className="">
                        <InputField
                          type={"password"}
                          setting={true}
                          label={false}
                          className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                          placeholder={"Password"}
                          id={"password"}
                          name={"password"}
                          rules={{
                            required: "Password is required",
                            minLength: {
                              value: 8,
                              message: "Password must be at least 8 characters",
                            },
                            maxLength: {
                              value: 20,
                              message: "Password must not exceed 20 characters",
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    <p className="w-[100%] text-[#59647B] text-[14px]">Role</p>
                    <div className="">
                      <InputField
                        type={"text"}
                        label={false}
                        className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                        placeholder={"Manager"}
                        id={"role"}
                        name={"role"}
                        rules={{ required: "Role Is Required", }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-[100%]">
                  <div className="w-[100%] text-black font-medium text-[14px]">
                    Properties & Access
                  </div>
                  <div className="h-[280px] overflow-scroll ">
                    {properties &&
                      properties.map((prop, index) => {
                        return (
                          <div className="my-2" key={index}>
                            <div
                              className="font-medium cursor-pointer text-[15px] text-[#343946] flex w-[100%] justify-between"
                            // onClick={() =>
                            //   setActiveIndex(
                            //     activeIndex === index ? null : index
                            //   )
                            // }
                            >
                              <div>{prop.propertyName}</div>
                              <div>
                                <Toggle
                                  isToggled={
                                    activeIndex === index ? true : false
                                  }
                                  activeIndex={activeIndex}
                                  index={index}
                                  onClick={() => {
                                    handleProperty(prop.propertyId, index);
                                    setActiveIndex(
                                      activeIndex === index ? null : index
                                    );
                                    setIsToggled(!isToggled);
                                  }}
                                />
                              </div>
                            </div>
                            {/* {activeIndex === index && (
                              <div className="mx-4">
                                <Accordion>
                                  {modulesAPIData &&
                                    modulesAPIData.map((item, index) => (
                                      <>
                                        <AccordionItem
                                          selectionStatus={item?.isSelected}
                                          key={index}
                                          index={index}
                                          title={item?.moduleName}
                                          onSelect={() => {
                                            moduleHandleChange(index);
                                          }}
                                        >
                                          {item?.subModules?.map(
                                            (child, childIndex) => (
                                              <CheckableItem
                                                selectionStatus={
                                                  child?.isSelected
                                                }
                                                key={childIndex}
                                                index={childIndex}
                                                label={child.subModuleName}
                                                onChange={() => {
                                                  subModuleHandleChange(
                                                    index,
                                                    childIndex
                                                  );
                                                }}
                                              />
                                            )
                                          )}
                                        </AccordionItem>
                                      </>
                                    ))}
                                </Accordion>
                              </div>
                            )} */}
                          </div>
                        );
                      })}
                  </div>

                  {/* <div className=" flex flex-col gap-2">
                  <div className="bg-neutral-light-3 rounded-lg p-2 flex flex-col gap-2 ">
                    <div className="text-[#131926] flex items-center rounded-lg bg-neutral-light-3 justify-between ">
                      <p className="text-black font-[500] text-[14px]">
                        Property Name
                      </p>
                      <span>
                        <CheckBox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center p-2 gap-2">
                        <label>
                          <input type="checkbox" />
                        </label>
                        <p className="text-black font-[500] text-[14px]">
                          {"Admin"}
                        </p>
                      </div>
                      <div className="flex items-center p-2 gap-2">
                        <label>
                          <input type="checkbox" />
                        </label>
                        <p className="text-black font-[500] text-[14px]">
                          {"View Only"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-[#131926] flex items-center text-[14px] font-[500] h-[33px] rounded-lg bg-neutral-light-3 p-2 justify-between ">
                    <p>Property Name</p>
                    <span>
                      <CheckBox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </span>
                  </div>
                </div> */}
                </div>
              </div>
            ) : (
              <div>
                <CustomSkeleton width={"100%"} height={200} />
              </div>
            )}
          </form>
        </FormProvider>
      </GlobalModalComponent>
    </>
  );
};

// Parent Component
const Accordion = ({ children }) => {
  return <div className="accordion">{children}</div>;
};

// Child Component
const CheckableItem = ({
  selectionStatus,
  label,
  isChecked,
  onChange,
  index,
}) => {
  return (
    <div className="checkable-item mt-0.5 flex flex-row pl-4">
      <input
        type="checkbox"
        className="mr-3"
        checked={selectionStatus}
        onChange={onChange}
        id={label}
      />
      <label className="text-gray-500 text-sm" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

// Accordion Item Component
const AccordionItem = ({
  title,
  selectionStatus,
  children,
  onSelect,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);

  // Initialize items state based on children
  // useEffect(() => {
  //   const initialItems = React.Children.map(children, (child, index) => ({
  //     label: child.props.label,
  //     isChecked: false,
  //   }));
  //   setItems(initialItems);
  // }, [children]);

  // // Function to handle individual item selection
  // const handleItemChange = (index) => {
  //   setItems((prevItems) => {
  //     const updatedItems = [...prevItems];
  //     updatedItems[index] = {
  //       ...updatedItems[index],
  //       isChecked: !updatedItems[index].isChecked,
  //     };
  //     onSelect(getSelectedData(updatedItems)); // Notify parent about the change
  //     return updatedItems;
  //   });
  // };

  // Function to retrieve selected data
  // const getSelectedData = (updatedItems) => {
  //   const selectedItems = updatedItems
  //     .filter((item) => item.isChecked)
  //     .map((item) => item.label);
  //   return { title, selectedItems };
  // };

  // Function to handle selecting/deselecting all items
  // const handleSelectAll = () => {
  //   const updatedItems = items.map((item) => ({
  //     ...item,
  //     isChecked: !items.every((item) => item.isChecked),
  //   }));
  //   setItems(updatedItems);
  //   onSelect(getSelectedData(updatedItems)); // Notify parent about the change
  // };

  return (
    <div className="accordion-item">
      <div className="accordion-header mt-2 flex flex-row">
        <input
          type="checkbox"
          className="mr-3"
          checked={selectionStatus}
          onChange={onSelect}
        // id={index}
        />
        <label
          // htmlFor={index}
          onClick={() => setIsOpen(!isOpen)}
          className="text-gray-700 font-medium text-[14px]"
        >
          {title}
        </label>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export const Toggle = ({ isToggled, onClick, index, activeIndex }) => {
  return (
    <div className="flex items-center">
      <div
        onClick={onClick}
        className={`${isToggled && activeIndex === index ? "bg-blue-500" : "bg-gray-300"
          } w-12 h-6 rounded-full cursor-pointer p-1 duration-300 ease-in-out relative`}
      >
        <div
          className={`${isToggled && activeIndex === index
            ? "translate-x-6"
            : "translate-x-0"
            } w-4 h-4 bg-white rounded-full shadow-md transform duration-300 cursor-pointer top-1 ease-in-out absolute inset-y-0 left-1`}
        ></div>
      </div>
    </div>
  );
};
