import React from 'react'
import BackButtonSettings from '../settingBackButton';

const SettingButton = ({ text, Title, onClick, onClose,handleBackButtonClick,table, onBack,hidebackbutton,type,isView}) => {
    return (
        <div>
            <div className="flex w-[100%] pb-1 justify-between h-[44px] items-center">
                <div className='flex items-center'>
                  { !onClose && !hidebackbutton && <div><BackButtonSettings handleBackButtonClick={handleBackButtonClick} table={table} /></div>}
                    <div className='text-[17px] text-[#ffffff] dark:text-black pl-2'>{Title}</div>
                </div>
                <div className="flex gap-4">
                    {onClose && <div onClick={onClose} className='bg-[#EEF1F6]  rounded-lg flex items-center text-[#929EB5] text-[14px] font-[500] px-4 py-2 cursor-pointer'>
                        <p>Cancel</p>
                    </div>}
                    {onBack && <div onClick={onClose} className='bg-[#EEF1F6]  rounded-lg flex items-center text-[#929EB5] text-[14px] font-[500] px-4 py-2 cursor-pointer'>
                        <p>Go To Dashboard</p>
                    </div>}
                    {text && !isView && <button onClick={onClick} className='bg-[#1D2D44] rounded-lg flex items-center text-white text-[14px] font-[500] px-4 py-2 cursor-pointer'>
                        <p>{text}</p>
                    </button>}
                </div>
            </div>

        </div>
    )
}

export default SettingButton;
