export function formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);
  
    // Get the day, month, and year components
    const day = date.getDate();
    const month = date.getMonth(); // Months are zero-indexed (January = 0)
    const year = date.getFullYear();
  
    // Get the month name as a string (e.g., "July")
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[month];
  
    // Add ordinal suffix to the day (e.g., "4th", "22nd")
    const suffixes = ["st", "nd", "rd", "th"];
    const ordinalSuffix = suffixes[(day % 100 > 10 && day % 100 < 20) ? 3 : (day % 10 === 1) ? 0 : (day % 10 === 2) ? 1 : (day % 10 === 3) ? 2 : 3];
  
    // Format the final string
    return `${day}${ordinalSuffix} ${monthName} ${year.toString().slice(-2)}`;
  }