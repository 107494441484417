import React from 'react'
import { useNavigate } from 'react-router-dom'

const RecentReviews = () => {
    const navigate=useNavigate()
  return (
    <>
    <button onClick={()=>{navigate(-1)}} className='mx-60 cursor-pointer' >Go back </button>
    </>

  )
}

export default RecentReviews