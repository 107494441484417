import React, { useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";

const Dropdown = ({
  options,
  setSelectedItems,
  selectedItem,
  width,
  height,
  backgroundColor,
  fontSize,
  right,
}) => {
  const [isVisible, setIsvisible] = React.useState(false);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setIsvisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div ref={ref}>
      <div
        className={` bg-white/30 text-white cursor-pointer  relative flex  select-none rounded-md flex-col ${width || height ? "justify-between items-center" : "justify-center items-center"} items-center  cursor-pointer ${width ? `w-[${width}px]` : "w-fit"}  bg-[#E9EBF0] dark:bg-subMainDark`}
        onClick={() => setIsvisible(!isVisible)}
        style={{ height: height ? `${height}px` : "28px" }}
      >
        <div
          className={`${!width && "w-fit"}  px-3 py-2 flex ${width || height ? `justify-between items-center` : "justify-center items-center"}`}
          style={{
            height: height ? `${height}px` : "28px",
            width: width && `${width}px`,
          }}
        >
          <div
            className={`${fontSize ? `text-[${fontSize}px]` : "text-[0.8em] "} whitespace-nowrap`}
          >
            {selectedItem ? (
              <div
                data-tooltip-id={selectedItem}
                data-tooltip-content={selectedItem}
                className="truncate"
                style={{maxWidth: width && `${width}px`}}
              >
                {selectedItem}
                <Tooltip
                  id={selectedItem}
                  style={{
                    borderRadius: "10px",
                    maxWidth: "350px",
                    fontSize: "12px",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                />
              </div>
            ) : (
              "Select"
            )}
          </div>
          <div className="ml-4">
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.975746 0.881819C0.75289 0.658963 0.391177 0.658963 0.167464 0.881819C-0.0562496 1.10468 -0.0553925 1.46639 0.167464 1.6901L0.975746 0.881819ZM4.00059 4.71494L3.59688 5.11866C3.81973 5.34151 4.18145 5.34151 4.40516 5.11866L4.00059 4.71494ZM7.83286 1.6901C8.05571 1.46725 8.05571 1.10553 7.83286 0.881819C7.61 0.658106 7.24829 0.658963 7.02458 0.881819L7.83286 1.6901ZM0.167464 1.6901L3.59688 5.11866L4.4043 4.31038L0.975746 0.881819L0.167464 1.6901ZM4.4043 5.11866L7.83286 1.6901L7.02458 0.881819L3.59602 4.31038L4.4043 5.11866Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        {isVisible && (
          <div
            className={`absolute w-[200px] cursor-pointer overflow-hidden z-50 overflow-y-scroll scrollHide ${height ? "top-[42px]" : "top-8"}   rounded-lg h-auto max-h-[180px] shadow-xl drop-shadow-sm shadow-gray-500/10 bg-gray-50`}
            style={{ right: right }}
          >
            {options ? (
              options?.map((option) => {
                return (
                  <div
                    onClick={() =>
                      setSelectedItems({
                        value: option?.value,
                        label: option?.label,
                      })
                    }
                    className="text-sm dark:text-mainDark text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b"
                  >
                    {option?.label}
                  </div>
                );
              })
            ) : (
              <div className="text-center py-2">No Data Found !</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
