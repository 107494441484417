import { emptySplitApi } from "../../injectEndpoints";

export const UserManangemt = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserManagementDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getuserMangement`,
          method: "GET",
          params: {
            userId: data.userId,
            hId:data?.hId
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserListUserManagement"],
    }),
    getLoginInfo: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getLoginInfo`,
          method: "GET",
          params: {
            userId: data.userId,
            hId: data.hId,
            accountType: data.accountType,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserListUserManagement"],
    }),
    getLoginInfo2: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getLoginInfo`,
          method: "GET",
          params: {
            userId: data.userId,
            hId: data.hId,
            accountType: data.accountType,
          },
          headers: {
            Authorization: `${data.token}`, // Replace with your token logic
          },
        };
      },
      providesTags: ["getUserListUserManagement"],
    }),

    getUserManagementProperty: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getPropertiesListForUserManagement`,
          method: "GET",
          params: {
            userId: data.userId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserManagement"],
    }),
    getUserManagement: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getuserMangement`,
          method: "GET",
          params: {
            userId: data.userId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserManagement"],
    }),
    addUsersUserManagement: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addNewUser`,
          method: "POST",
          params: {
            userId: data.userId,
            // hId:data?.hId
          },
          body: data.body,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addUserListUserManagement"],
    }),
    editUsersUserManagement: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editUserMangement`,
          method: "PATCH",
          params: {
            userId: data.userId,
            // hId:data?.hId
          },
          body: data.editBody,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editUsersUserManagement"],
    }),
    deleteUsersUserManagement: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteUser`,
          method: "PATCH",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editUsersUserManagement"],
    }),
    deactivateUsersUserManagement: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deactivateUserAccount`,
          method: "PATCH",
          params: data,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["deactivateUsersUserManagement"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserManagementDetailsQuery,
  useAddUsersUserManagementMutation,
  useGetUserManagementQuery,
  useGetLoginInfoQuery,
  useGetUserManagementPropertyQuery,
  useEditUsersUserManagementMutation,
  useDeleteUsersUserManagementMutation,
  useDeactivateUsersUserManagementMutation,
  useGetLoginInfo2Query
} = UserManangemt;
