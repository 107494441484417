import React, { useState } from "react";
import RateRangeCrad from "../components/rateRangeCard";
import formatCurrency from "../utils/formatCurrency";
import { format } from "date-fns";
import InfoToolTip from "../components/tooltip/InfoToolTip";
import { Tooltip } from "react-tooltip";
import { calculatePercentage } from "../utils/calculatePercentage";
import { useIsIpad } from "../utils/isIpad";

const RateVsCompetition = ({
  title,
  data,
  price,
  img,
  isRateVisible,
  isVisible,
  myProp,
  asPer,
  toolTipText,
  showPercentage,
  isSuggested=false
}) => {

  const propName = window.localStorage.getItem('propertyName')
  const isIpad = useIsIpad()
  return (
    <div className="   flex  w-[100%] h-full p-2   bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-xl ">
      <div className=" flex flex-col  w-full px-2">
        <div className="h-5 text-[#2e343e] mt-4 px-1  w-full flex justify-between items-center">
          <p className="text-white">
            {title || "Suggested Rate vs Competition"}{" "}
            <span className="text-[12px] text-white/80 ml-1">
              as per {asPer && format(new Date(asPer), "dd MMM yyyy")}
            </span>
          </p>

          <InfoToolTip
            content={
              toolTipText ||
              "Include popular icons in your React projects easily with react-icons, which utilizes ES6 imports that allows you to include only the icons that your project is using."
            }
          />
        </div>
        {myProp &&
          myProp.map((item, index) => {
            return (
              <div
                className={
                  "py-2 relative top-8 bg-[#ecedef] pr-2 group border-b cursor-pointer  hover:bg-[#CCD6E5] rounded-xl "
                }
              >
                <div
                  className="flex group-hover:p-1 justify-between
                  group-hover:scale-[0.99] group-hover:transition-transform  transition-all ease-in-out duration-300
                "
                >
                  <div className="flex items-center  lg:w-[100%] md:w-[100%] w-[100%] h-full ml-2 ">
                    {/* <div className="w-[100%]"> */}
                    <img className="h-full w-fit " src={img} />
                    {/* </div> */}
                    <div className="flex -mt-1 ml-2 items-center">
                      <div className="flex flex-col ">
                        <p className="text-base  text-[#2e343e]  text-[12px]  font-[400]">
                          Your Hotel 
                        </p>
                        <p className=" text-neutral-dark-13 text-xs md:text-[12px]  whitespace-nowrap font-[400]">
                          Suggested Rate
                        </p>
                        <p className=" text-neutral-dark-13 text-xs md:text-[12px]  whitespace-nowrap font-[400]">
                          Overridden Rate
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex  w-full gap-2 whitespace-nowrap justify-end items-center font-medium group-hover:bg-[#CCD6E5] group-hover:px-2 ">
                    {isVisible && <p>{item.price} </p>}
                    <div className="flex mt-2 text-sm flex-col">
                     <div> {formatCurrency(item?.todayRate)}</div>
                     <div> {formatCurrency(item?.overRideRates)}</div>
                      {item?.todayRank}
                    </div>
                    {isRateVisible && (
                      <RateRangeCrad percentage={item?.percentageDifference} />
                    )}
                    {showPercentage && item?.yesterdayRank != 9999 && (
                      <RateRangeCrad
                        percentage={
                          item?.yesterdayRank != 9999 &&
                          calculatePercentage(
                            item?.todayRank,
                            item?.yesterdayRank
                          )
                        }
                      />
                    )}
                    {/* <p className='font-semibold text-[#57F3B1] flex text-sm '>{item?.value} <p className=' text-neutral-dark-20'>/5</p></p> */}
                  </div>
                </div>
              </div>
            );
          })}
        <div className=" w-full h-[340px] overflow-y-scroll scrollHide mt-[50px]   ">
          {Array.isArray(data) &&
            data.map((item, index) => {
              return (
                <div className={`py-2 pr-2 ${item.hotelName === propName ? 'bg-[#CCD6E5]':'hover:bg-[#ccd6e580]'} group border-b mb-2 cursor-pointer   rounded-xl`}>
                  {item.hotelName && (
                    <div
                      className="flex group-hover:p-1 justify-between
                  group-hover:scale-[0.99] group-hover:transition-transform  items-center transition-all ease-in-out duration-300
                "
                    >
                      <div className="flex items-center  lg:w-[100%] md:w-[100%] w-[100%] h-full ml-2 ">
                        {/* <div className="w-[100%]"> */}
                        <img className="h-full w-fit " src={img} />
                        {/* </div> */}
                        <div className="flex -mt-1 ml-2 items-center">
                          <div className="flex flex-col ">
                            <p
                              className={` ${item.hotelName === propName ? 'text-black' :'text-[#ffffff]'} ${isIpad ? 'min-w-[120px] max-w-[120px] ' : 'w-[200px]'}  text-xs md:text-[12px]  font-[400]  truncate`}
                              data-tooltip-id={item.hotelName}
                              data-tooltip-content={item.hotelName}
                            >
                              {item.hotelName}
                              <Tooltip  id={item.hotelName} place="left" />
                            </p>
                            <p className={` ${item.hotelName === propName ? 'text-black' :'text-[#ffffff]'}text-[12px]  whitespace-nowrap font-[400]`}>
                              {item.subtitle}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className={`flex  w-full gap-2 whitespace-nowrap ${item.hotelName === propName ? 'text-black' :'text-[#ffffff]'} justify-end items-center font-medium group-hover:bg-[#ccd6e500] group-hover:px-2 `}>
                        {isVisible && <p>{item.price}</p>}
                        <p>
                          {item?.todayRate === 0 ? <span className="text-green-400">Sold Out</span> : item?.todayRate && formatCurrency(item?.todayRate)}{" "}
                          {item?.todayRank === 9999 ? "N/A" : item?.todayRank}
                        </p>
                        {
                          isRateVisible && (
                            <div className="text-xs md:text-[1vw] text-green-500  px-4 rounded-md">
                              {item?.percentageDifference}%
                            </div>
                          )
                          // (
                          //   <RateRangeCrad
                          //     percentage={item?.percentageDifference}
                          //   />
                          // )
                        }

                        {/* <p className='font-semibold text-[#57F3B1] flex text-sm '>{item?.value} <p className=' text-neutral-dark-20'>/5</p></p> */}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RateVsCompetition;
