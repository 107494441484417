import { useEffect, useState } from "react";
import { useGetRoomTypesQuery } from "../../../../redux/slices/settings/channelManager/roomTypes";
import {
  useGetMappedPropertyRoomsQuery,
  useGetOtaRoomMappingDetailsQuery,
  useGetPropertyMappingDetailsQuery,
  useGetPropertyRoomDetailsQuery,
} from "../../../../redux/slices/settings/roomMapping";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import { AiOutlineDown } from "react-icons/ai";
import RoomMapSelectComp from "../../../../components/roomMapping/roomMappingSelector";
import RenderRooms from "../../../../components/roomMapping/renderRooms/renderRooms";
import { useGetCompsetDetailsQuery } from "../../../../redux/slices/settings/myProperties";
import RenderPropertyRooms from "../../../../components/roomMapping/renderRooms/renderPropertyRooms";
import { useAddPropertyRoomMappingMutation, useUpdatePropertyRoomMappingMutation } from "../../../../redux/slices/rooms";
import { toast } from "react-toastify";

const Hotel2HotelMapping = ({ handleBackButtonClick, hId }) => {
  const [hotelId, setHotelId] = useState(CurrentProperty());
  const [loading, setLoading] = useState(false);

  const [addPropertyRoomMapping] = useAddPropertyRoomMappingMutation();
  const [updatePropertyRoomMapping] = useUpdatePropertyRoomMappingMutation();

  const [selectedType, setSelectedType] = useState();

  const [activeRooms, setActiveRooms] = useState();

  const [defaultRoom, setDefaultRoom] = useState();
  const { data: mappedPropertyRooms } = useGetMappedPropertyRoomsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: roomTypes,
    isLoading,
    isFetching,
    refetch,
  } = useGetRoomTypesQuery(
    {
      userId: UserId(),
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data } = useGetPropertyRoomDetailsQuery(
    {
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: propertyMappingDetails } = useGetPropertyMappingDetailsQuery({
    hId: hotelId,
  });

  useEffect(() => {
    if (mappedPropertyRooms) {
      let otaArray = [];
      let resultForm = [];

      let setProperties = [];
      let settingDefaultRoom = [];
      mappedPropertyRooms?.data.map((propertyName, id) => {
        if (id !== 0) {
          setProperties.push({
            hId: propertyName.hotelId,
            roomId: "",
          });
        }
      });
      mappedPropertyRooms?.data?.[0]?.rooms.map((propertyRooms, id) => {
        resultForm.push({
          roomId: propertyRooms.roomId,
          roomName: propertyRooms.roomName,
          properties: setProperties,
        });
        settingDefaultRoom.push({
          value: propertyRooms.roomId,
          label: propertyRooms.roomName,
        });
      });
      setDefaultRoom(settingDefaultRoom);

      setActiveRooms(resultForm);
    }
  }, [mappedPropertyRooms]);

  function findHotelNameById(hotels, hId) {
    const foundRoom =
      hotels && hId && hotels.find((hotel) => hotel.value === hId);
    return foundRoom ? foundRoom.label : "";
  }


  function onSubmit() {
    updatePropertyRoomMapping({
      hId: hotelId,
      activeRooms: activeRooms,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right h-5 w-5" });

        // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
        // localStorage.setItem('otaImage', JSON.stringify(otaImage));
        //  localStorage.setItem('otaName', JSON.stringify(otaName));

        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });

    //   addPropertyRoomMapping({
    //   hId: hotelId,
    //   activeRooms: activeRooms,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res.message, { position: "bottom-right h-5 w-5" });

    //     // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
    //     // localStorage.setItem('otaImage', JSON.stringify(otaImage));
    //     //  localStorage.setItem('otaName', JSON.stringify(otaName));

    //     setLoading(false);
    //   })

    //   .catch((err) => {
    //     toast.error(err.message, { position: "bottom-right" });
    //     setLoading(false);
    //   });
}
  return (
    <div>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <div
              onClick={handleBackButtonClick}
              className=" rotate-90 bg-[#1D2D44] p-2 cursor-pointer rounded-lg"
            >
              <AiOutlineDown size={20} color="white" />
            </div>
            <div className=" text-lg flex items-center">
              Your room mapping with compset
            </div>
          </div>
          <div className="flex flex-row">
            {mappedPropertyRooms?.data.length > 0 ? (
              <button onClick={onSubmit}>
                <div className="w-fit px-4 outline outline-blue-500 text-blue-500 rounded-md cursor-pointer hover:text-white hover:bg-blue-600 hover:outline-none duration-150 transition-all ease-in-out">
                {propertyMappingDetails ? "Update" : "Save"} 
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {mappedPropertyRooms?.data.length > 0 ? (
        <div className="flex justify-between gap-6">
          <div className="w-[100%]">
            <div className="text-[16px] mb-2 h-[50px] ">
              {mappedPropertyRooms?.data?.[0]?.hotelname}
            </div>
            <div className="mt-2 flex flex-col gap-4">
              {activeRooms?.map((room, roomIdd) => {
                return (
                  <div
                    key={roomIdd}
                    className="h-10 outline w-[100%]  justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                  >
                    {room?.roomName}
                  </div>
                );
              })}
            </div>
          </div>
          {mappedPropertyRooms?.data &&
            mappedPropertyRooms?.data.map((compset, index) => {
              return (
                <>
                  {index !== 0 && (
                    <div
                      className="w-[100%]"
                      key={`${index}r${compset.label}`}
                      id={`${index}r${compset.label}`}
                    >
                      <div className="text-[16px] mb-2 h-[50px] ">
                        {compset.hotelName}
                      </div>
                      <RenderPropertyRooms
                        data={mappedPropertyRooms}
                        defaultRoom={defaultRoom}
                        index={index - 1}
                        activeRooms={activeRooms}
                        setActiveRooms={setActiveRooms}
                      />
                    </div>
                  )}
                </>
              );
            })}
        </div>
      ) : (
        <>
          <div>
            <p>Map your hotel and compset first</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Hotel2HotelMapping;
