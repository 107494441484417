import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// const BASE_URL = 'http://localhost:3100/api/'
const BASE_URL = "https://rxserver.retvenslabs.com/api/";
const localStorageToken = window.localStorage.getItem("token");

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().isToken || localStorageToken;
      if (token || localStorageToken) {
        headers.set("Authorization", token || localStorageToken);
      }

      return headers;
    },
  }),

  endpoints: () => ({}),
  tagTypes: [
    "userSignup",
    "verifyOtp",
    "propertyDetails",
    "addCmCred",
    "verifyCM",
    "getOtaLinks",
    "addNewCompset",
    "userLogin",
    "forgotPassword",
    "channelManagerList",
    "resetPassword",
    "gethotelId",
    "editUser",
    "getUsers",
    "getAlertsAndNotifications",
    "editAlertsAndNotifications",
    "editUserInterface",
    "getDropDownData",
    "postQuickReport",
    "getQuickReport",
    "addRateThreshold",
    "getTemplateReport",
    "updateRateThreshold",
    "addOtaRoomMapping",
    "addPropertyRoomMapping",
    "updateOtaRoomMapping",
    "updatePropertyRoomMapping",
    "postWidgets",
    "getWidgets",
    "getCancellation",
    "getUpcomingEvent",
    "getChannel",
    "getRatings",
    "getRevenueReport",
    "getPaceReport",
    "getMonthEndReport",
    "getYearEndReport",
    "getRatesReport",
    "earlyAccess",
    "getExportSourceOverview",
    "getExportOTBDataForChain",
    "getHotelPerformance",
    "hotelPerformanceDashBoard"
  ],
});
