import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import { Tooltip } from "react-tooltip";

const RoomMapSelectComp = ({
  setSelectedData,
  selectedData,
  options,
  otaId,
  roomId,
  deleteSelectedRoom
}) => {
  const updateSelectedData = (item) => {
    setSelectedData({ item, otaId, roomId });
    // setSelectedData((prevState) => ({
    //   ...prevState,
    //   [key]: item,
    // }));
  };

  const deleteSelectedData = () => {
    deleteSelectedRoom({ otaId, roomId });
  };

  const [showOption, setShowOption] = useState(false);

  const [isView, setIsView] = useState();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div key={`${roomId}g${otaId}`} id={`${roomId}g${otaId}`} ref={dropdownRef}>
      <div
        onClick={
          !isView
            ? () => {
              setShowOption(!showOption);
            }
            : null
        }
        className="h-10 outline w-[100%] hover:outline hover:outline-blue-600   justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500 ease-in-out duration-150 transition-all"
        placeholder="Enter Property Name"
        key={`${roomId}${otaId}`}
      >
        <div className="truncate w-[100px]" data-tooltip-content={selectedData} data-tooltip-id={selectedData}>{selectedData}</div>{" "}
        <Tooltip id={selectedData} />
        <div className="flex items-center gap-2">
          <IoIosArrowDropdown
            className={`${showOption && "rotate-180"}`}
            size={20}
          />
          {selectedData && (
            <AiOutlineClose onClick={() => deleteSelectedData()} />
          )}
        </div>
        {showOption && (
          <div className="absolute h-[200px] left-0 -bottom-[210px] overflow-scroll w-[100%] rounded-lg z-[50]">
            {options &&
              options.map((item, itemId) => {
                return (
                  <div
                    onClick={() => {
                      updateSelectedData(item);
                      // setSelectedData(item);
                      setIsView(false);
                    }}
                    className={`p-3  hover:bg-gray-200 ${selectedData?.label === item?.label ? "bg-gray-300" : "bg-gray-100"} text-gray-600 cursor-pointer border-b`}
                    key={itemId}
                    id={itemId}
                  >
                    {item?.label}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RoomMapSelectComp;
