import React from "react";
import Dropdown from "../dropdown";
import increase from "../../assets/icons/increase.svg";
import formatCurrency from "../../utils/formatCurrency";
import { format } from "date-fns";
import InfoToolTip from "../tooltip/InfoToolTip";

const RateFluctuationCard = ({
  title,
  data,
  img,
  isRating = false,
  showLogo = true,
  showDropDown = true,
  isFluctuation = false,
  asPer,
  tooltipText,
  height
}) => {
  return (
    <div className="bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md dark:bg-mainDark p-4 rounded-2xl" style={{height:height}}>
      <div className="flex justify-between items-center">
        <div className="text-[#ffffff] text-[16px] font-medium">
          {title || "Rate Fluctuation"}{" "}
          {asPer && (
            <span className="ml-1 text-gray-500 text-[12px]">
              as per {format(new Date(asPer), "dd MMM','yy")}
            </span>
          )}
        </div>
        {showDropDown && (
          <div>
            <Dropdown selectedItem={"Today"} />
          </div>
        )}
        <div>
          <InfoToolTip content={tooltipText} />
        </div>
      </div>
      <div
        className="mt-8 h-[300px] scrollHide"
        style={{ overflowY: "scroll" }}
      >
        {data && data.length > 0
          ? data?.map((item) => {
              return (
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    {showLogo && (
                      <div>
                        <img
                          height={"32px"}
                          width={"32px"}
                          src={img}
                          loading="lazy"
                        />
                        {/* <img src={item.otaLogo} height={"32px"} width={"32px"} /> */}
                      </div>
                    )}
                    <div>
                      <div className="text-[14px] text-white">
                        {item.roomName || item?.hotelName || item?.compsetName}
                      </div>
                      {!isFluctuation && (
                        <div className="text-[12px] text-[#ffffff]">
                          {(item.price && formatCurrency(item.price)) ||
                            `${item?.reviewCount} Reviews`}
                        </div>
                      )}
                    </div>
                  </div>
                  {!isRating && !isFluctuation && (
                    <div className="text-green-500 text-[15px] flex items-center gap-2 from-[#6BFBBE] to-[#2AFCA4] bg-clip-text">
                      {item.percentage} <img src={increase} loading="lazy" />
                    </div>
                  )}
                  {isRating && !isFluctuation && (
                    <div className="text-green-500 text-[15px] flex items-center gap-2 from-[#6BFBBE] to-[#2AFCA4] bg-clip-text">
                      {(item.ratingScore ? item?.ratingScore : 'N/A') || (item?.priceChangeCounter ? item?.priceChangeCounter :'N/A')}
                      <span className="text-white">
                        {item?.totalRatingCount  ? `/${item?.totalRatingCount}` :''}
                      </span>
                      {/* <img src={increase} /> */}
                    </div>
                  )}
                  {isFluctuation && (
                    <div className="text-green-500 text-[15px] flex items-center gap-2 from-[#3b8d6b] to-[#3b8d6b] bg-clip-text">
                      {item?.priceChangeCounter}
                    </div>
                  )}
                </div>
              );
            })
          : "Not enough data !"}
      </div>
    </div>
  );
};

export default RateFluctuationCard;
