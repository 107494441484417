import React, { useEffect, useState } from "react";
import upArrow from "../assets/icons/ArrowRise.svg";
import down from "../assets/icons/decrease.svg";
import Dropdown from "../components/dropdown";
import { Ratings } from "./newDashboard";
import AreaChart from "../components/charts/areaChart";
import likeThumb from "../assets/icons/likeThumb.svg";
import dislikeThumb from "../assets/icons/thumbs-down.svg";
import {
  useGetParityCalanderQuery,
  useGetRateMonitorTodayRateDetailsQuery,
  useGetRateParityQuery,
} from "../redux/slices/rateMonitor";
import { CurrentProperty, UserId } from "../utils/impVars";
import formatCurrency from "../utils/formatCurrency";
import BackdropLoader from "../components/newLoader";
import { useLastSevenDaysRatesQuery } from "../redux/slices/newDashboard";
import { format } from "date-fns";
import { useGetVisibilityOverviewQuery } from "../redux/slices/visibilityRank";
import ParityCalender from "../components/parityCalender";
import { useNavigate } from "react-router-dom";
import { ActiveOtaOptions } from "../utils/otaOptions";
import Modal from "../components/modal";
import Table from "../components/table";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InfoToolTip from "../components/tooltip/InfoToolTip";
import { BackButton } from "./rateSuggestion/rateAnalysis";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import propLogo from "../assets/icons/3.svg";
import { useIsIpad } from "../utils/isIpad";

const NewRatePulseDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedVisibilityDropdown, setSelectedVisibilityDropdown] =
    useState();
  const [selectedPartityOtaDropdown, setSelectedPartityOtaDropdown] =
    useState();
  const [selectedDropdown, setSelectedDropdown] = useState();

  const {
    data: todaysRate,
    isLoading: todaysRateIsLoading,
    isFetching: todaysRateIsFetching,
  } = useGetRateMonitorTodayRateDetailsQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: parityCalander,
    isLoading: parityCalendarIsLoading,
    isFetching: parityCalendarIsFetching,
  } = useGetParityCalanderQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      type: selectedDropdown?.value || "amongCompset",
      startDate: format(new Date(selectedDate), "yyyy-MM-dd"),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: parityCalander2,
    isLoading: parityCalendar2IsLoading,
    isFetching: parityCalendar2IsFetching,
  } = useGetRateParityQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: visibilityData } = useGetVisibilityOverviewQuery({
    hId: CurrentProperty(),
    userId: UserId(),
    startDate: "2024-04-19",
  });

  useEffect(() => {
    if (
      todaysRateIsLoading ||
      todaysRateIsFetching ||
      parityCalendarIsLoading ||
      parityCalendar2IsLoading ||
      parityCalendarIsFetching ||
      parityCalendar2IsFetching
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    todaysRateIsLoading,
    todaysRateIsFetching,
    parityCalendarIsLoading,
    parityCalendar2IsLoading,
    parityCalendarIsFetching,
    parityCalendar2IsFetching,
  ]);

  const otaOptions = ActiveOtaOptions();
  const visibilityOverviewCopy = [
    ...(visibilityData?.data?.visibilityOverview || []),
  ];

  // Sort the copied array
  const sortedVisibilityOverview = visibilityOverviewCopy.sort(
    (a, b) => a.rank - b.rank
  );
  return (
    <div className="p-5 flex flex-col gap-4">
      <BackdropLoader loading={isLoading} />
      <div className="flex items-center gap-2 ">
        {/* <BackButton mainDashboard={true} /> */}
        <div>
          <div className="text-xl font-medium text-[#ffffff] dark:text-black">
            Welcome to Rate Pulse
          </div>
          <div className="text-[13.5px] text-[#ffffff] dark:text-black">
            Your property & compset analysis
          </div>
        </div>
      </div>
      <TodaysRate
        data={todaysRate?.data?.rateMonitorOverview
          .slice()
          .sort((a, b) => b?.todayRate - a?.todayRate)}
        asPer={todaysRate?.data?.asPer}
      />
      <div className="flex gap-4 flex-col md:flex-row">
        <RateParity
          selectedDropdown={selectedDropdown?.label}
          setSelectedDropdown={setSelectedDropdown}
          isRank={false}
          title={"Rate-Parity Overview"}
          array={parityCalander2?.data?.parity}
          showDropDown={false}
          asPer={parityCalander2?.data?.asPer}
          tooltip={
            "This feature offers a snapshot of rate parity status across different distribution channels and booking platforms."
          }
          isVisibility={false}
        />
        <RateParity
          title={"Visibility"}
          selectedDropdown={selectedVisibilityDropdown?.label}
          setSelectedDropdown={setSelectedVisibilityDropdown}
          array={sortedVisibilityOverview}
          isRank={true}
          showDropDown={false}
          asPer={visibilityData?.data?.asPer}
          tooltip={
            "This feature provides insights  into both rate parity and visibility across different distribution channels and booking platforms."
          }
          isVisibility={true}
        />
        <div className="w-[100%]">
          <Ratings rating={3} asPer={""} />
        </div>
      </div>
      <div>
        <LastSevenDaysAnalysis otaOptions={otaOptions} />
      </div>
      <div>
        {/* <ActionComponent /> */}
        {/* <div className=" pt-2 overflow-hidden">
          <ParityCalender
            data={parityCalander?.data}
            setSelectedDropdown={setSelectedDropdown}
            selectedDropdown={selectedDropdown}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            maxHeight={300}
            asPer={parityCalander?.data?.asPer}
            otaOptions={otaOptions}
            selectedPartityOtaDropdown={selectedPartityOtaDropdown}
            setSelectedPartityOtaDropdown={setSelectedPartityOtaDropdown}
          />
        </div> */}
      </div>
    </div>
  );
};

export default NewRatePulseDashboard;

const TodaysRate = ({ data, asPer }) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="flex justify-between items-center w-[100%]">
          <div className="flex gap-2 items-start">
            <div className="text-[#ffffff] dark:text-black font-medium text-[17px]">
              Today's Rates
            </div>
            <div className="text-[#edeef0] dark:text-black text-sm flex mt-1">
              as per {asPer && format(new Date(asPer), "dd MMM yyyy")}
            </div>
          </div>

          {/* <div>
            <Dropdown />
          </div> */}
        </div>
      </div>
      <div className="flex justify-start scrollHide gap-4 max-w-[100%] overflow-x-scroll">
        {Array.isArray(data) &&
          data?.map((hotel, index) => {
            if (hotel?.todayRate == 0 || hotel === null) {
              return;
            }
            return (
              <div className="mt-4  drop-shadow-lg  bg-newGradient dark:bg-lightGradient  backdrop-blur-md min-w-[220px] p-4 rounded-md" key={index}>
                <div className="flex  mb-2 items-center gap-2">
                  <img
                    src={hotel?.otaImage}
                    width={30}
                    className="rounded-lg "
                  />
                  <div className="text-sm text-[#c2c3c6] mb-1">
                    {hotel?.otaName}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="text-[#ffffff] text-[20px]">
                    {formatCurrency(hotel?.todayRate)}
                  </div>
                  <div
                    className={`${hotel?.percentageDifference?.toFixed(2)?.toString().includes("-") ? "text-[#f99898]" : "text-[#29CC39]"} text-xs`}
                  >
                    {hotel?.percentageDifference.toFixed(2)}%
                  </div>
                  <img
                    src={
                      hotel?.percentageDifference
                        .toFixed(2)
                        ?.toString()
                        .includes("-")
                        ? down
                        : upArrow
                    }
                    className="-ml-1"
                  />
                </div>
                <div className={` text-[#e1e2e3] text-[13px]`}>
                  {formatCurrency(hotel?.yesterdayRate)} Yesterday’s Rate
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const RateParity = ({
  array,
  isRank = false,
  selectedDropdown,
  setSelectedDropdown,
  title,
  showDropDown = true,
  icon,
  asPer,
  tooltip,
  multiChain,
  isVisibility,
}) => {
  const navigate = useNavigate();
  const avgPrice =
    Array.isArray(array) && array.length > 0
      ? (
          array.reduce((sum, item) => {
            // Check if item is valid and price is not 9999 or 0
            if (
              item &&
              typeof item.price === "number" &&
              item.price !== 9999 &&
              item.price !== 0
            ) {
              return sum + item.price;
            }
            return sum;
          }, 0) /
          array.filter(
            (item) =>
              item &&
              typeof item.price === "number" &&
              item.price !== 9999 &&
              item.price !== 0
          ).length
        ).toFixed(2)
      : 0;

  const avgRank =
    Array.isArray(array) && array.length > 0
      ? array
          .map((item) => item?.rank ?? item?.ranking)
          .filter((value) => value !== null && !isNaN(value) && value !== 9999)
          .reduce((sum, value) => sum + value, 0) /
        array.filter((item) => {
          const value = item?.rank ?? item?.ranking;
          return value !== null && !isNaN(value) && value !== 9999;
        }).length
      : 0;
  const isIpad = useIsIpad();
  return (
    <div className="p-4 w-[100%] bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md relative rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div className="text-[#ffffff] font-medium">
          {title}{" "}
          <span className="text-[#dee0e2] text-[12px] ml-1 mt-1">
            {asPer && `as per ${format(new Date(asPer), "dd MMM yyyy")}`}
          </span>
        </div>
        {showDropDown && (
          <div>
            <Dropdown
              options={[
                { label: "Among OTA", value: "amongOta" },
                { label: "Among Compsets", value: "amongCompset" },
              ]}
              selectedItem={selectedDropdown}
              setSelectedItems={setSelectedDropdown}
            />
          </div>
        )}
        <InfoToolTip content={tooltip} position={'left'} />
      </div>
      <div className="mb-2">
        <div className="flex flex-col justify-center items-center mb-4">
          <div className="text-xl font-medium text-[#ffffff]">
            {isRank ? parseInt(avgRank).toFixed(0) === "NaN" ? '--' : parseInt(avgRank).toFixed(0) : formatCurrency(avgPrice) === "NaN" ? '--' :formatCurrency(avgPrice)}
          </div>
          <div className="text-[#edeef0] text-xs">
            {isRank ? "Avg. Rank" : `Avg. Rate`}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col ${multiChain && "min-h-[200px] max-h-[200px] overflow-y-scroll"} ${isRank ? "gap-2" : "gap-2"} scrollHide `}
      >
        {Array.isArray(array) &&
          array.slice(0, multiChain ? array?.length : 6).map((item, index) => {
            if (item?.otaName || item?.propertyName || item?.hotelName) {
              return (
                <div className="flex items-center mb-2 justify-between">
                  <div className="flex items-center gap-4 w-1/2">
                    {/* {isRank && <div>#1</div>} */}

                    <img
                      src={multiChain ? propLogo : item?.otaImage}
                      className="rounded-sm"
                      width={isRank ? "14%" : "24%"}
                    />
                    <div
                      data-tooltip-content={item?.hotelName}
                      data-tooltip-id={`${index}item?.hotelName`}
                      className={`text-[#ffffff] text-start ${item?.hotelName && "min-w-[95%] truncate max-w-[95%] text-sm"}`}
                    >
                      {item?.otaName || item?.propertyName || item?.hotelName}
                      {multiChain && (
                        <Tooltip
                          id={`${index}item?.hotelName`}
                          place="bottom"
                        />
                      )}
                    </div>
                  </div>
                  {!isRank && !isIpad && (
                    <div className="w-1/2 ml-4">
                      <ProgressBar2
                        percentage={item?.price?.toFixed(0)}
                        maxValue={avgPrice * 1.5}
                        width={"100"}
                        // reversed={true}
                      />
                    </div>
                  )}
                  <div className="w-1/2 justify-end text-white items-center flex">
                    {multiChain && "#"}{" "}
                    {isRank && item?.rank !== 9999
                      ? item.rank || item?.ranking
                      : item.rank === 9999 || item?.ranking === 9999
                        ? "--"
                        : formatCurrency(item?.price)}
                  </div>
                </div>
              );
            }
          })}
      </div>

      {!multiChain && Array.isArray(array) && array && array?.length > 4 && (
        <div
          className="absolute bottom-4 left-[45%] text-white cursor-pointer hover:text-blue-600 duration-150 ease-in-out transition-all"
          onClick={
            isVisibility
              ? () => navigate("/rate-pulse/visibility")
              : () => navigate("/rate-pulse/rate_monitor")
          }
        >
          View all
        </div>
      )}
    </div>
  );
};

export const ProgressBar = ({ percentage, width }) => {
  let colorClass;

  if (percentage <= 33) {
    colorClass = "bg-red-500";
  } else if (percentage <= 66) {
    colorClass = "bg-yellow-500";
  } else {
    colorClass = "bg-green-500";
  }

  // Calculate pixel width for the colored bar
  const calculatePixelWidth = (maxWidth, percentage) => {
    const pixelWidth =
      percentage &&
      ((parseInt(percentage)?.toFixed(0) > 100 ? 100 : percentage) * maxWidth) /
        100;
    return `${pixelWidth}px`;
  };

  // Maximum width in pixels
  const maxWidth = width || 150; // 200px is equivalent to 100% width

  // Calculate pixel width for the colored bar
  const pixelWidth = calculatePixelWidth(maxWidth, percentage);

  const isIpad = useIsIpad();

  return (
    <div
      className={` ${isIpad && "hidden"} h-3 bg-gray-100 rounded-full`}
      style={{ width: width ? `${width}px` : "150px" }}
    >
      <div
        className={`h-full ${colorClass} rounded-lg`}
        style={{ width: pixelWidth }}
      ></div>
    </div>
  );
};

export const ProgressBar2 = ({ percentage, width, maxValue, reversed }) => {
  const isLessThanPercentageOfMax = (value, percentage, maxValue) => {
    const threshold = (percentage / 100) * maxValue;
    return value < threshold;
  };

  let colorClass;
  if (reversed) {
    if (
      percentage >= 66 ||
      !isLessThanPercentageOfMax(percentage, 66, maxValue)
    ) {
      colorClass = "bg-red-500";
    } else if (
      percentage >= 33 ||
      !isLessThanPercentageOfMax(percentage, 33, maxValue)
    ) {
      colorClass = "bg-yellow-500";
    } else {
      colorClass = "bg-green-500";
    }
  } else {
    if (
      percentage <= 33 ||
      isLessThanPercentageOfMax(percentage, 33, maxValue)
    ) {
      colorClass = "bg-red-500";
    } else if (
      percentage <= 66 ||
      isLessThanPercentageOfMax(percentage, 66, maxValue)
    ) {
      colorClass = "bg-yellow-500";
    } else {
      colorClass = "bg-green-500";
    }
  }

  // Calculate pixel width for the colored bar
  const calculatePixelWidth = (maxWidth, percentage, maxValue) => {
    const actualMax = maxValue || 4653.67; // Default max value
    const adjustedPercentage = (percentage / actualMax) * 100;
    const pixelWidth =
      (adjustedPercentage > 100 ? 100 : adjustedPercentage) * (maxWidth / 100);
    return `${pixelWidth}px`;
  };

  // Maximum width in pixels
  const maxWidth = width || 150; // 150px is equivalent to 100% width

  // Calculate pixel width for the colored bar
  const pixelWidth = calculatePixelWidth(maxWidth, percentage, maxValue);

  return (
    <div className={`w-[${maxWidth}px] ml-6 h-3 bg-gray-100 rounded-full`}>
      <div
        className={`h-full ${colorClass} rounded-lg`}
        style={{ width: pixelWidth }}
      ></div>
    </div>
  );
};

const LastSevenDaysAnalysis = ({ otaOptions }) => {
  const [selectOta, setSelectOta] = useState();
  const [last7DaysTable, setLast7DaysTable] = useState();
  const myPropName = JSON.parse(window.localStorage.getItem("propertiesList"));
  const {
    data: last7DaysRates,
    isLoading,
    isFetching,
  } = useLastSevenDaysRatesQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (otaOptions) {
      setSelectOta(otaOptions[0]);
    }
  }, [otaOptions]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setOptions] = useState();
  useEffect(() => {
    if (last7DaysRates && last7DaysRates?.data) {
      const optData =
        Array.isArray(last7DaysRates?.data) &&
        last7DaysRates?.data.map((item) => {
          return {
            label: item?.hotelName,
            value: item?.hotelName,
          };
        });
      setSelectedItem(optData[0]?.value);
      setOptions(optData);
    }
  }, [last7DaysRates && last7DaysRates?.data]);

  const calculateAvgPrice = (array, selectedHotel) => {
    const selectedHotelRate =
      Array.isArray(array) &&
      array?.filter((hotel) => {
        return hotel?.hotelName === selectedHotel;
      });

    const selectedRate =
      Array.isArray(selectedHotelRate) && selectedHotelRate[0]?.rates;

    const averagePrice =
      Array.isArray(selectedRate) &&
      selectedRate?.reduce((sum, item) => sum + item?.minPrice, 0) /
        selectedRate?.length.toFixed(2);

    return formatCurrency(averagePrice && parseInt(averagePrice.toFixed(2)));
  };
  function calculateRateChange(hotelName, data) {
    // Check for invalid input data
    if (!hotelName || !data || !Array.isArray(data) || data.length === 0) {
      // console.error("Invalid input data.");
      return null;
    }

    let myHotel;
    let maxRateChange = -Infinity;
    let maxRateChangeHotel;

    for (let i = 0; i < data.length; i++) {
      const hotel = data[i];
      // Check for invalid hotel data
      if (
        !hotel.hotelName ||
        !Array.isArray(hotel.rates) ||
        hotel.rates.length === 0
      ) {
        continue;
      }

      if (hotel.hotelName === hotelName) {
        myHotel = hotel;
      }

      const rates = hotel.rates;
      const initialRate = rates[0].minPrice;
      const finalRate = rates[rates.length - 1].minPrice;

      // Check for invalid rate data
      if (isNaN(initialRate) || isNaN(finalRate)) {
        continue;
      }

      const rateChange = ((finalRate - initialRate) / initialRate) * 100;
      if (rateChange > maxRateChange) {
        maxRateChange = rateChange;
        maxRateChangeHotel = hotel.hotelName;
      }
    }

    if (!myHotel) {
      return null;
    }

    const myHotelRates = myHotel.rates;
    const myInitialRate = myHotelRates[0].minPrice;
    const myFinalRate = myHotelRates[myHotelRates.length - 1].minPrice;
    var myRateChange = 0;
    if (myInitialRate) {
      myRateChange = ((myFinalRate - myInitialRate) / myInitialRate) * 100;
    } else {
      myRateChange = 0;
    }

    return {
      hotelName: hotelName,
      rateChangePercentage: myRateChange,
      maxRateChangeHotelName: maxRateChangeHotel,
      maxRateChangePercentage: maxRateChange,
    };
  }
  function calculateAverageRateDifference(hotelName, data) {
    try {
      // Find the hotel object in the data array
      const hotel = data.find((h) => h.hotelName === hotelName);

      // If hotel not found, throw an error
      if (!hotel) {
        throw new Error(`Hotel "${hotelName}" not found in the data.`);
      }

      // Extract rates array from the hotel object
      const rates = hotel.rates;

      // If there are no rates, throw an error
      if (rates.length === 0) {
        throw new Error(`No rates found for hotel "${hotelName}".`);
      }

      // Calculate the total rate difference
      let totalRateDifference = 0;
      for (let i = 1; i < rates.length; i++) {
        totalRateDifference += Math.abs(
          rates[i].minPrice - rates[i - 1].minPrice
        );
      }

      // Calculate the average rate difference
      const averageRateDifference = totalRateDifference / (rates.length - 1);

      return formatCurrency(parseInt(averageRateDifference.toFixed(2)));
    } catch (error) {
      return error.message;
    }
  }

  function getMaxRate(hotelName, data) {
    try {
      // Find the hotel object with the given name
      const hotel = data.find((hotel) => hotel.hotelName === hotelName);

      if (!hotel) {
        throw new Error("Hotel not found in the data.");
      }

      // Extract rates from the hotel object
      const rates = hotel.rates.map((rate) => rate.minPrice);

      if (rates.length === 0) {
        throw new Error("No rates found for this hotel.");
      }

      // Find the maximum rate
      const maxRate = Math.max(...rates);

      return formatCurrency(parseInt(maxRate));
    } catch (error) {
      return null;
    }
  }
  // myPropName[0]?.propertyName
  const rateChange = calculateRateChange(selectedItem, last7DaysRates?.data);

  const ChartLabelAndData = (array, hotelName) => {
    const selectedHotelRate =
      Array.isArray(array) &&
      array?.filter((hotel) => {
        return hotel?.hotelName === hotelName;
      });

    const selectedRate = {
      label:
        Array.isArray(selectedHotelRate) &&
        selectedHotelRate[0]?.rates?.map((item) => {
          return format(new Date(item?.timeStamp), "dd-MMM-yyy");
        }),
      data:
        Array.isArray(selectedHotelRate) &&
        selectedHotelRate[0]?.rates?.map((item) => {
          return item?.minPrice;
        }),
    };
    return selectedRate;
  };
  const column = [
    {
      name: "Hotel Name",
      selector: (row) => row["hotelName"],
    },
    {
      name: "Ota Name",
      selector: (row) => row["rates"].map((rate) => rate["otaName"]).join(", "),
    },
    {
      name: "Minimum Price",
      selector: (row) =>
        row["rates"].map((rate) => rate["minPrice"]).join(", "),
    },
  ];
  return (
    <>
      {last7DaysTable && (
        <Modal>
          <div className="lg:w-[80%] md:w-[90%] w-[95%] h-fit [80%] relative sm:pt-5 sm:pb-5 sm:pl-5 sm:pr-5 px-5 pt-5 pb-10 rounded-md bg-white">
            <div className="mb-2 text-lg font-medium">{"Rate Analysis"}</div>

            <div className="mt-5" onClick={(e) => e.stopPropagation()}>
              {last7DaysRates?.data && (
                <Table
                  columns={column}
                  data={last7DaysRates?.data}
                  pagination={true}
                />
              )}
            </div>

            <div
              className="absolute h-fit w-fit right-2 sm:right-4 sm:top-4 bottom-[6px] sm:bottom-0  font-semibold cursor-pointer hover:text-red-500 flex items-center"
              onClick={() => {
                setLast7DaysTable(false);
              }}
            >
              <AiOutlineCloseCircle size={24} />
            </div>
          </div>
        </Modal>
      )}
      {!isLoading && !isFetching ? (
        <div className="p-5 bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-lg flex flex-col gap-4">
          <div className="flex justify-between items-start">
            <div className="text-sm md:text-base text-white">
              Last 7 Days Rate Analysis{" "}
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-white">Select OTA</div>
              <Dropdown
                options={otaOptions}
                selectedItem={
                  selectOta?.label ? selectOta?.label : "Select OTA"
                }
                setSelectedItems={setSelectOta}
              />
            </div>
          </div>
          <div className="flex justify-between md:justify-start gap-4  flex-row flex-wrap  md:flex-row">
            {Array.isArray(options) &&
              options.map((option) => {
                if (option?.label) {
                  return (
                    <div
                      className={`${selectedItem === option.value ? "bg-[#1D2D44] text-white md:text-sm" : "bg-[#EEF1F6] md:text-sm text-[#59647B]"} py-2 px-4 text-center truncate w-[40%] text-xs md:text-sm md:w-[20%] rounded-lg md:flex md:items-center md:justify-center cursor-pointer transition-all ease-in-out duration-200`}
                      onClick={() => setSelectedItem(option.value)}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={option?.label}
                    >
                      <div
                        className="max-w-[200px] truncate"
                        data-tooltip-content={option?.label}
                        data-tooltip-id={option?.label}
                      >
                        {" "}
                        {option.label === myPropName[0]?.propertyName
                          ? "Your Hotel"
                          : option?.label}{" "}
                        <Tooltip id={option?.label} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="w-[100%] flex gap-4 md:flex-row flex-col">
            <div className="w-full md:w-[60%]">
              <AreaChart
                height={400}
                showSingleSeries={true}
                xaxisShow={true}
                yaxisShow={true}
                currentSeriesData={
                  ChartLabelAndData(last7DaysRates?.data, selectedItem)?.data
                }
                currentSeriesName={
                  ChartLabelAndData(last7DaysRates?.data, selectedItem).label
                }
                downloadButtonData={
                  last7DaysRates &&
                  last7DaysRates.data &&
                  Array.isArray(last7DaysRates.data)
                    ? last7DaysRates.data.flatMap((hotel) =>
                        hotel.rates && Array.isArray(hotel.rates)
                          ? hotel.rates.map((rate) => ({
                              hotelName: hotel.hotelName,
                              otaName: rate.otaName,
                              minPrice: rate.minPrice,
                              timeStamp: rate.timeStamp,
                            }))
                          : []
                      )
                    : []
                }
                // setTableState={() => { setLast7DaysTable(true) }}
                fileName={"Last 7 Days Rate Analysis"}
                // setTableState={() => {
                //   setDaysTable(true);
                // }}
                currentLabel={"Rate"}
                isCurrency={true}
                textWhite={true}
              />
            </div>
            <div className="w-full md:w-[40%] grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Avg Rate */}
              <div className=" bg-white border-[#D1D1D1] p-2 h-[220px] rounded-lg w-[100%]">
                <div className="text-sm text-[#131926]">Avg. Rate</div>
                <div className="font-medium text-xl mt-1">
                  {calculateAvgPrice(last7DaysRates?.data, selectedItem) !=
                  "false"
                    ? calculateAvgPrice(last7DaysRates?.data, selectedItem)
                    : "N/A"}
                </div>
                <div className="h-0.5 w-[100%] my-2 bg-[#D1D1D1]"></div>
                <div className="flex items-center gap-4">
                  <div className="w-[100%] flex justify-center items-center">
                    <img src={likeThumb} />
                  </div>
                  <div className="text-sm text-[#59647B] h-[130px] overflow-scroll scrollHide">
                    Recently,{" "}
                    {selectedItem === myPropName[0]?.propertyName
                      ? "your hotel"
                      : selectedItem}
                    , maintained an average pricing of{" "}
                    {calculateAvgPrice(last7DaysRates?.data, selectedItem) !=
                    "false"
                      ? calculateAvgPrice(last7DaysRates?.data, selectedItem)
                      : "N/A"}{" "}
                    on All throughout the last week.
                  </div>
                </div>
              </div>
              {/* Percentage change */}
              <div className=" bg-white border-[#D1D1D1] p-2 h-[220px] overflow-hidden  rounded-lg w-[100%]">
                <div className="text-sm text-[#131926]">
                  Rate Percentage Change
                </div>
                <div
                  className={`font-medium text-xl mt-1 ${rateChange && rateChange?.rateChangePercentage?.toString().includes("-") ? "text-red-500" : !rateChange?.rateChangePercentage.toFixed(2) ? "text-black" : "text-green-500"}`}
                >
                  {rateChange && rateChange?.rateChangePercentage.toFixed(2)
                    ? rateChange?.rateChangePercentage.toFixed(2)
                    : "N/A"}
                  %
                </div>
                <div className="h-0.5 w-[100%] my-2 bg-[#D1D1D1]"></div>
                <div className="flex items-center gap-4">
                  <div className="w-[100%] flex justify-center items-center">
                    {rateChange &&
                    rateChange?.rateChangePercentage
                      ?.toString()
                      .includes("-") ? (
                      <img src={dislikeThumb} />
                    ) : (
                      <img src={likeThumb} />
                    )}
                  </div>
                  <div className="text-sm text-[#59647B] h-[120px] overflow-scroll scrollHide">
                    Over the past week,{" "}
                    {rateChange?.hotelName === myPropName[0]?.propertyName
                      ? "your hotel"
                      : rateChange?.hotelName}{" "}
                    {rateChange?.hotelName === myPropName[0]?.propertyName
                      ? ""
                      : "a competitor"}{" "}
                    , experienced a significant increase of{" "}
                    {rateChange?.rateChangePercentage.toFixed(2)}% in their
                    rates on All.
                  </div>
                </div>
              </div>
              {/* Avg Rate Diff */}
              <div className=" bg-white border-[#D1D1D1] p-2 h-[220px] rounded-lg w-[100%]">
                <div className="text-sm text-[#131926]">
                  Avg. Rate Difference
                </div>
                <div className="font-medium text-xl mt-1">
                  {calculateAverageRateDifference(
                    selectedItem,
                    last7DaysRates?.data
                  ) != "₹NaN" &&
                  calculateAverageRateDifference(
                    selectedItem,
                    last7DaysRates?.data
                  ) != "Cannot read properties of undefined (reading 'length')"
                    ? calculateAverageRateDifference(
                        selectedItem,
                        last7DaysRates?.data
                      )?.includes("No rates found ")
                      ? "--"
                      : calculateAverageRateDifference(
                          selectedItem,
                          last7DaysRates?.data
                        )
                    : "N/A"}
                </div>
                <div className="h-0.5 w-[100%] my-2 bg-[#D1D1D1]"></div>
                <div className="flex items-center gap-4">
                  <div className="w-[100%] flex justify-center items-center">
                    <img src={likeThumb} />
                  </div>
                  <div className="text-sm text-[#59647B] h-[130px] overflow-scroll scrollHide">
                    There was an average rate variance of{" "}
                    {calculateAverageRateDifference(
                      selectedItem,
                      last7DaysRates?.data
                    ) != "₹NaN" &&
                    calculateAverageRateDifference(
                      selectedItem,
                      last7DaysRates?.data
                    ) !=
                      "Cannot read properties of undefined (reading 'length')"
                      ? calculateAverageRateDifference(
                          selectedItem,
                          last7DaysRates?.data
                        )
                      : "N/A"}{" "}
                    in{" "}
                    {selectedItem === myPropName[0]?.propertyName
                      ? "your hotel"
                      : selectedItem}
                  </div>
                </div>
              </div>
              {/* Max Rate */}
              <div className=" bg-white border-[#D1D1D1] p-2 h-[220px] rounded-lg w-[100%]">
                <div className="text-sm text-[#131926]">Max rate Last Week</div>
                <div className="font-medium text-xl mt-1">
                  {getMaxRate(selectedItem, last7DaysRates?.data)}
                </div>
                <div className="h-0.5 w-[100%] my-2 bg-[#D1D1D1]"></div>
                <div className="flex items-center gap-4">
                  <div className="w-[100%] flex justify-center items-center">
                    <img src={likeThumb} />
                  </div>
                  <div className="text-sm text-[#59647B] h-[130px] overflow-scroll scrollHide">
                    Last week,{" "}
                    {selectedItem === myPropName[0]?.propertyName
                      ? "your hotel"
                      : selectedItem}
                    , hit a peak rate of{" "}
                    {getMaxRate(selectedItem, last7DaysRates?.data)}. This
                    insight sheds light on the upper limit of their pricing
                    strategy during that period.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton width={"100%"} height={450} />
      )}
    </>
  );
};
