import { Player } from "@lottiefiles/react-lottie-player";
import { Controls } from "@storybook/blocks";
import React, { useEffect } from "react";
import animation from "./animation.json";

const BackdropLoader = ({ loading, animated=false }) => {
  useEffect(() => {
    if (loading) {
      let body = document.body;
      body.style.overflow = "hidden";
    } else {
      let body = document.body;
      body.style.overflow = "";
    }
  }, [loading]);
  return (
    <>
      {(loading && !animated )? (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div
            aria-label="Loading..."
            role="status"
            className="flex items-center flex-col space-x-2"
          >
            <svg
              className="h-20 w-20 animate-spin stroke-white"
              viewBox="0 0 256 256"
            >
              <line
                x1="128"
                y1="32"
                x2="128"
                y2="64"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="195.9"
                y1="60.1"
                x2="173.3"
                y2="82.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="224"
                y1="128"
                x2="192"
                y2="128"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="195.9"
                y1="195.9"
                x2="173.3"
                y2="173.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="128"
                y1="224"
                x2="128"
                y2="192"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="60.1"
                y1="195.9"
                x2="82.7"
                y2="173.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="32"
                y1="128"
                x2="64"
                y2="128"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
              <line
                x1="60.1"
                y1="60.1"
                x2="82.7"
                y2="82.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="24"
              ></line>
            </svg>
            <div className="text-md font-medium text-white mt-4">
              Please Wait...
            </div>
          </div>
        </div>
      ) : (loading && animated) && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-white">
          <Player
            autoplay
            loop
            src={animation}
            style={{ height: "300px", width: "300px" }}
          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
        </div>
      )}
    </>
  );
};

export default BackdropLoader;
