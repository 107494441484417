import { emptySplitApi } from "../../injectEndpoints";

export const NewDashboard = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    NextSevenDays: builder.query({
      query: (data) => ({
        url: `/dashboard/next7DaysPricing`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["NextSevenDays"],
    }),
    PerformaceMatrix: builder.query({
      query: (data) => ({
        url: `/dashboard/performaceMatrix`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["PerformaceMatrix"],
    }),
    TodaysOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/todaysOverview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["TodaysOverview"],
    }),
    GetSourceOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/getSourceOverview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["GetSourceOverview"],
    }),
    LastSevenDaysRates: builder.query({
      query: (data) => ({
        url: `/dashboard/last7DaysRates`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["LastSevenDaysRates"],
    }),
    getAllWidget: builder.query({
      query: (data) => ({
        url: `/dashboard/getWidget`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getAllWidget"],
    }),
    getActionsToTake: builder.query({
      query: (data) => ({
        url: `/dashboard/getActionsToTake`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getActionsToTake"],
    }),
    postWidgets: builder.mutation({
      query: (data) => ({
        url: `/dashboard/editWidegtManager`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["postWidgets"],
    }),
    getWidgets: builder.query({
      query: (data) => ({
        url: `/dashboard/getWidgetManagerDetail`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getWidgets"],
    }),
    getUpcomingEvent: builder.query({
      query: (data) => ({
        url: `/dashboard/getUpcomingEvents`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getUpcomingEvent"],
    }),
    getCancellation: builder.query({
      query: (data) => ({
        url: `/dashboard/getCancellationWidgetData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getCancellation"],
    }),
    getChannel: builder.query({
      query: (data) => ({
        url: `/dashboard/channelPerformance`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getChannel"],
    }),
    acceptRate: builder.mutation({
      query: (data) => ({
        url: `dashboard/acceptedRate`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      providesTags: ["acceptRate"],
    }),
    verifyRates: builder.mutation({
      query: (data) => ({
        url: `/dashboard/verifyRates`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      providesTags: ["verifyRates"],
    }),
  }),
    overrideExisting: false,
});

export const {
  useNextSevenDaysQuery,
  usePerformaceMatrixQuery,
  useTodaysOverviewQuery,
  useGetSourceOverviewQuery,
  useLastSevenDaysRatesQuery,
  useGetAllWidgetQuery,
  useGetActionsToTakeQuery,
  usePostWidgetsMutation,
  useGetWidgetsQuery,
  useGetCancellationQuery,
  useGetUpcomingEventQuery,
  useGetChannelQuery,
  useAcceptRateMutation,
  useVerifyRatesMutation
} = NewDashboard;
