import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";

export const DownloadCancellationReport = async (temp, asOn) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");
    
    const {dateCancellationDataFinal,roomCancellationDataFinal,sourceCancellationDataFinal}=temp
    console.log(temp,"temp")

    // Add the logo
    const logoBase64 = logoUrl;
    const logoId = workbook.addImage({
      base64: logoBase64,
      extension: "jpg",
    });
    const totalColumns = 8; // Adjust based on your data structure

    worksheet.mergeCells("A1", worksheet.getColumn(totalColumns)?.letter + "3");
    worksheet.addImage(logoId, "A1:A3");

    // Set column widths
    const columns = ["A", "B", "C", "D", "E", "F", "G", "H"];
    const columnWidths = [50, 30, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
    columns.forEach((column, index) => {
      worksheet.getColumn(column).width = columnWidths[index];
    });

    // Display today's date at J1
    const dateCell = worksheet.getCell(
      worksheet.getColumn(totalColumns)?.letter + "1"
    );
    dateCell.value = new Date();
    dateCell.numFmt = "dd mmmm yyyy";

    // Main heading
    const mainHeading = worksheet.getCell("A4");
    mainHeading.value = `Cancellation Report ${asOn?.hotelName} (${formatDate(asOn?.startDate)})`;
    mainHeading.alignment = { horizontal: "center", vertical: "middle" };
    mainHeading.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" }, // Yellow color
    };
    
    worksheet.mergeCells("A4", worksheet.getColumn(totalColumns).letter + "4");

    worksheet.addRow([])
    // Add headers
    worksheet.addRow(Object.keys(dateCancellationDataFinal[0])).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    dateCancellationDataFinal.map((item,index)=>{
        worksheet.addRow(Object.values(item)).eachCell((cell) => {
    
            
            if (index % 2 != 0) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "ECEFCE" },
              };
            }
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
  
    })
    worksheet.addRow([])
    worksheet.addRow(Object.keys(roomCancellationDataFinal[0])).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      roomCancellationDataFinal.map((item,index)=>{
        worksheet.addRow(Object.values(item)).eachCell((cell) => {
    
            
            if (index % 2 != 0) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "ECEFCE" },
              };
            }
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
  
    })
    worksheet.addRow([])
    worksheet.addRow(Object.keys(sourceCancellationDataFinal[0])).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      sourceCancellationDataFinal.map((item,index)=>{
        worksheet.addRow(Object.values(item)).eachCell((cell) => {
    
            
            if (index % 2 != 0) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "ECEFCE" },
              };
            }
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
  
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Cancellation Report ${asOn?.hotelName || ''}.xlsx`;
    a.click();

    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log(e);
  }
};
