import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaceReport from "./Tables/PaceReport";
import MonthEndTable from "./Tables/MonthEndReport";
import RevenueReportTable from "./Tables/RevenueReport";
import { useNavigate } from "react-router-dom";
import BackButton from "../../assets/icons/VectorSetting.svg";
import SegmentReport from "./Tables/SegmentReport";
import SourceReport from "./Tables/SourceReport";
import QuarterlyReport from "./Tables/QuarterlyReport";
import ParityReport from "./Tables/ParityReport";
import { CurrentProperty, UserId } from "../../utils/impVars";
import {
  useGetMonthEndReportQuery,
  useGetPaceReportQuery,
  useGetParityReportQuery,
  useGetPickUpReportQuery,
  useGetQuarterlyReportQuery,
  useGetRateChangeReportQuery,
  useGetRatesReportQuery,
  useGetRevenueReportQuery,
  useGetSegmentReportQuery,
  useGetSourceReportQuery,
  useGetYearEndReportQuery,
} from "../../redux/slices/reports";
import { DownloadMonthEndReport } from "./DownloadExcel/MonthEndReport";
import { DownloadPaceReport } from "./DownloadExcel/PaceReport";
import { DownloadRevenueReport } from "./DownloadExcel/RevenueReport";
import { DownloadSegmentReport } from "./DownloadExcel/SegmentReport";
import { DownloadSourceReport } from "./DownloadExcel/SourceReport";
import { DownloadQuaterlyReport } from "./DownloadExcel/QuaterlyReport";
import { DownloadParityReport } from "./DownloadExcel/ParityReport";
import YearEndReport from "./Tables/YearEndReport";
import { toast } from "react-toastify";
import RatesReport from "./Tables/RatesReport";
import PickUpReport from "./Tables/PickUpReport";
import useIntelligenceDateRange from "../../utils/dateRange";
import useRatePulseDateRange from "../../utils/ratepulseDateRange";
import { useGetInventoryQuery } from "../../redux/slices/ratesInventory";
import { format } from "date-fns";
import { YearEndReportdownload } from "./DownloadExcel/YearEndReport";
import RateChangeReport from "./Tables/RateChangeReport";
import OccupencyReport from "./Tables/OccupencyReport";
import CancellationReport from "./Tables/CancellationReport";
import { DownloadCancellationReport } from "./DownloadExcel/CancellationReport";

function formatDate(date) {
  // Get year, month, and day
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based
  let day = date.getDate();

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  // Construct the formatted date string
  return `${year}-${month}-${day}`;
}

const ReportId = () => {
  const { reportId } = useParams();
  const [skipQuarterly, setSkipQuarterly] = useState(true);
  const [skipRevenue, setSkipRevenue] = useState(true);
  const [skipMonth, setSkipMonth] = useState(true);
  const [skipYear, setSkipYear] = useState(true);
  const [skipPace, setSkipPace] = useState(true);
  const [skipSource, setSkipSource] = useState(true);
  const [skipParity, setSkipParity] = useState(true);
  const [skipRate, setSkipRate] = useState(true);
  const [skipPickup, setSkipPickup] = useState(true);
  const [skipInventory, setSkipInventory] = useState(true);
  const [skipCancellation, setSkipCancellation] = useState(true);

  useEffect(() => {
    setSkipQuarterly(reportId === "quarep" ? false : true);
    setSkipPace(reportId === "parep" ? false : true);
    setSkipMonth(reportId === "moenrep" ? false : true);
    setSkipYear(reportId === "yeenrep" ? false : true);
    setSkipRevenue(reportId === "revrep" ? false : true);
    setSkipSource(reportId === "sourep" ? false : true);
    setSkipPickup(reportId === "pickrep" ? false : true);
    setSkipParity(reportId === "parrep" ? false : true);
    setSkipRate(reportId === "ratrep" ? false : true);
    setSkipInventory(reportId === "occrep" ? false : true);
    setSkipCancellation(reportId === "rachrep" ? false : true);
  }, []);

  const [segmentDate, setSegmentDate] = useState(new Date());
  const intelligenceDateRange = useIntelligenceDateRange();
  const ratePulseDateRange = useRatePulseDateRange();

  const [parityWhatsApp, setParityWhatsApp] = useState(false);
  const [quaterlyWhatsApp, setQuaterlyWhatsApp] = useState(false);
  const [revenueWhatsApp, setRevenueWhatsApp] = useState(false);
  const [monthEndWhatsApp, setMonthEndWhatsApp] = useState(false);
  const [yearEndWhatsApp, setYearEndWhatsApp] = useState(false);
  const [sourceWhatsApp, setSourceWhatsApp] = useState(false);
  const [paceWhatsApp, setPaceWhatsApp] = useState(false);
  const [paceWhatsAppAdmin, setPaceWhatsAppAdmin] = useState(false);
  const [pickUpWhatsApp, setPickUpWhatsApp] = useState(false);
  const [rateChangeWhatsApp, setRateChangeWhatsApp] = useState(false);
  const [segmentWhatsApp, setSegmentWhatsApp] = useState(false);
  const [ratesWhatsApp, setRatesWhatsApp] = useState(false);
  const [ratesWhatsAppAdmin, setRatesWhatsAppAdmin] = useState(false);
  const [inventoryWhatsApp, setInventoryWhatsApp] = useState(false);
  const [inventoryWhatsAppAdmin, setInventoryWhatsAppAdmin] = useState(false);

  const [QuaterlyWhatsAppAdmin, setQuaterlyWhatsAppAdmin] = useState(false);
  const [revenueWhatsAppAdmin, setRevenueWhatsAppAdmin] = useState(false);
  const [monthEndWhatsAppAdmin, setMonthEndWhatsAppAdmin] = useState(false);
  const [sourceWhatsAppAdmin, setSourceWhatsAppAdmin] = useState(false);
  const [parityWhatsAppAdmin, setParityWhatsAppAdmin] = useState(false);
  const [yearEndWhatsAppAdmin, setYearEndWhatsAppAdmin] = useState(false);
  const newDate = new Date();
  const [monthEndDateRange, setMonthEndDateRange] = useState([
    formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "dd-MM-yyyy"
    ),
    formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      "dd-MM-yyyy"
    ),
  ]);

  const [parityDateRange, setParityDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setParityDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);
  const [selectedInventryDateRange, setSelectedInventryDateRange] = useState(
    new Date()
  );
  const [quarterlyDateRange, setQuaterlyDateRange] = useState([
    formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth() - 3, 1),
      "dd-MM-yyyy"
    ),
    formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth(), 0),
      "dd-MM-yyyy"
    ),
  ]);
  const [revenueDateRange, setRevenueDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ),
      id: "range 1",
    },
  ]);
  const [paceDateRange, setPaceDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ),
      id: "range 1",
    },
  ]);
  const [sourceDateRange, setSourceDateRange] = useState([
    {
      startDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate()
      ),
      endDate: newDate,
      id: "range 1",
    },
  ]);
  const [yearDateRange, setYearDateRange] = useState([
    formatDate(new Date(newDate.getFullYear() - 1, 0, 1), "dd-MM-yyyy"),
    formatDate(new Date(newDate.getFullYear() - 1, 11, 31), "dd-MM-yyyy"),
  ]);
  const [pickUpDateRange, setPickUpDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  const [cancellationDateRange, setCancellationDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  const [pickUpWhatsAppAdmin, setPickUpWhatsAppAdmin] = useState(false);

  const {
    data: pickUpData,
    isFetching: pickupFetching,
    isLoading: pickupLoading,
  } = useGetPickUpReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(pickUpDateRange[0].startDate),
      endDate: formatDate(pickUpDateRange[0].endDate),
      whatsAppNotify: pickUpWhatsApp,
      admin: setPickUpWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipPickup }
  );
  const {
    data: revenueData,
    isLoading: revenueLoading,
    isFetching: revenueIsFetching,
    isSuccess: revenueSuccess,
  } = useGetRevenueReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(revenueDateRange[0]?.startDate),
      endDate: formatDate(revenueDateRange[0]?.endDate),
      whatsAppNotify: revenueWhatsApp,
      admin: revenueWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipRevenue }
  );
  useEffect(() => {
    if (revenueSuccess && (revenueWhatsApp || revenueWhatsAppAdmin)) {
      setRevenueWhatsApp(false);
      setRevenueWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [revenueLoading, revenueIsFetching, revenueSuccess]);
  const {
    data: paceData,
    refetch: paceDataRefetch,
    isLoading: paceLoading,
    isFetching: paceFetching,
    isSuccess: paceSuccess,
  } = useGetPaceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(paceDateRange[0]?.startDate),
      endDate: formatDate(paceDateRange[0]?.endDate),
      whatsAppNotify: paceWhatsApp,
      admin: paceWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipPace }
  );
  useEffect(() => {
    if (paceSuccess && (paceWhatsApp || paceWhatsAppAdmin)) {
      setPaceWhatsApp(false);
      setPaceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [paceLoading, paceFetching, paceSuccess]);
  const {
    data: monthEndData,
    isLoading: monthLoading,
    isFetching: monthFetching,
    isSuccess: monthSuccess,
  } = useGetMonthEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: monthEndDateRange[0],
      endDate: monthEndDateRange[1],
      whatsAppNotify: monthEndWhatsApp,
      admin: monthEndWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipMonth }
  );
  useEffect(() => {
    if (monthSuccess && (monthEndWhatsApp || monthEndWhatsAppAdmin)) {
      setMonthEndWhatsApp(false);
      setMonthEndWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [monthLoading, monthFetching, monthSuccess]);
  const {
    data: yearEndData,
    isLoading: yearLoading,
    isFetching: yearFetching,
  } = useGetYearEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: yearDateRange[0],
      endDate: yearDateRange[1],
      whatsAppNotify: yearEndWhatsApp,
      admin: yearEndWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipYear }
  );
  const {
    data: quarterlyData,
    isLoading: quarterlyLoading,
    isFetching: quaterlyFetching,
    refetch: quaterlyRefetch,
    isSuccess: quaterlySuccess,
  } = useGetQuarterlyReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: quarterlyDateRange[0],
      endDate: quarterlyDateRange[1],
      whatsAppNotify: quaterlyWhatsApp,
      admin: QuaterlyWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipQuarterly }
  );
  useEffect(() => {
    if (quaterlySuccess && (quaterlyWhatsApp || QuaterlyWhatsAppAdmin)) {
      setQuaterlyWhatsAppAdmin(false);
      setQuaterlyWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [quarterlyLoading, quaterlyFetching, quaterlySuccess]);
  const [ratesDateRange, setRatesDateRange] = useState([
    {
      startDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() - 10
      ),
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setParityDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);
  const {
    data: sourceData,
    isLoading: sourceLoading,
    isFetching: sourceFetching,
    isSuccess: sourceSuccess,
  } = useGetSourceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(sourceDateRange[0].startDate),
      endDate: formatDate(sourceDateRange[0].endDate),
      whatsAppNotify: sourceWhatsApp,
      admin: sourceWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipSource }
  );
  useEffect(() => {
    if (sourceSuccess && (sourceWhatsApp || sourceWhatsAppAdmin)) {
      setSourceWhatsApp(false);
      setSourceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [sourceLoading, sourceFetching, sourceSuccess]);

  const {
    data: segmentData,
    isFetching: segmentFetching,
    isLoading: segmentLoading,
  } = useGetSegmentReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(segmentDate),
      whatsAppNotify: segmentWhatsApp,
    },
    { refetchOnMountOrArgChange: true, skip: true }
  );
  const {
    data: parityData,
    isFetching: parityFetching,
    isLoading: parityLoading,
    isSuccess: paritySuccess,
  } = useGetParityReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(parityDateRange[0].startDate),
      endDate: formatDate(parityDateRange[0].endDate),
      whatsAppNotify: parityWhatsApp,
      admin: parityWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipParity }
  );
  const {
    data: ratesData,
    isFetching: ratesFetching,
    isLoading: ratesLoading,
    isSuccess: ratesSuccess,
  } = useGetRatesReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(ratesDateRange[0].startDate),
      endDate: formatDate(ratesDateRange[0].endDate),
      whatsAppNotify: ratesWhatsApp,
      admin: ratesWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipRate }
  );

  const {
    data: cancelllationData,
    isFetching: cancellationIsFetching,
    isLoading: cancellationIsLoading,
    isSuccess:cancellationSuccess
  } = useGetRateChangeReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: format(
        new Date(cancellationDateRange[0]?.startDate),
        "yyyy-MM-dd"
      ),
      endDate: format(
        new Date(cancellationDateRange[0]?.endDate),
        "yyyy-MM-dd"
      ),
      whatsAppNotify:rateChangeWhatsApp
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipCancellation,
    }
  );
  useEffect(() => {
    if (cancellationSuccess && ( rateChangeWhatsApp|| ratesWhatsAppAdmin)) {
    //   setRateChangeWhatsApp(false);
      setRateChangeWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [cancellationIsLoading, cancellationIsFetching, cancellationSuccess]);
  const {
    data: getInventory,
    isFetching: inventoryFetching,
    isLoading: inventoryLoading,
  } = useGetInventoryQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      asOn: format(new Date(), "yyy-MM-dd"),
      whatsAppNotify: inventoryWhatsApp,
      admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipInventory,
    }
  );
  useEffect(() => {
    if (paritySuccess && (parityWhatsApp || parityWhatsAppAdmin)) {
      setParityWhatsAppAdmin(false);
      setParityWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [parityLoading, parityFetching, paritySuccess]);

  const navigate = useNavigate();
  const handleDownload = () => {
    if (reportId == "moenrep") {
      DownloadMonthEndReport(monthEndData?.data);
    } else if (reportId == "parep") {
      DownloadPaceReport(paceData);
    } else if (reportId == "revrep") {
      DownloadRevenueReport(revenueData?.revenueReport);
    } else if (reportId == "segrep") {
      DownloadSegmentReport(segmentData?.data);
    } else if (reportId == "sourep") {
      DownloadSourceReport(sourceData?.data[0]);
    } else if (reportId == "quarep") {
      DownloadQuaterlyReport(quarterlyData?.data);
    } else if (reportId == "parrep") {
      DownloadParityReport(parityData?.data, parityData?.asOn);
    } else if (reportId == "yeenrep") {
      YearEndReportdownload(yearEndData?.data);
    } else if (reportId == "rachrep") {
      DownloadCancellationReport(
        cancelllationData?.rateChangeReport?.cancellationData
      );
    }
  };
  return (
    <div className="p-4">
      <div className=" flex">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className={`bg-[#1D2D44] w-fit  flex items-center justify-center cursor-pointer rounded-lg  h-[36px] py-1 px-3 `}
        >
          <img className="" src={BackButton} alt="." />
        </div>
        {/* <h1
          onClick={handleDownload}
          className=" flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[40px] mr-2 p-4 text-white dark:text-mainDark font-medium text-[17px]"
        >
          Download
        </h1>{" "} */}
      </div>
      <div className="">
        {reportId == "moenrep" && (
          <MonthEndTable
            data={monthEndData?.data}
            isLoading={monthFetching || monthLoading}
            dateRange={monthEndDateRange}
            setDateRange={setMonthEndDateRange}
            setIsWhatsApp={() => {
              setMonthEndWhatsApp(true);
              // setMonthEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setMonthEndWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "parep" && (
          <PaceReport
            data={paceData}
            setDateRange={setPaceDateRange}
            dateRange={paceDateRange}
            isLoading={paceLoading || paceFetching}
            setIsWhatsApp={() => {
              setPaceWhatsApp(true);
              // setPaceWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setPaceWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "revrep" && (
          <RevenueReportTable
            dateRange={revenueDateRange}
            setDateRange={setRevenueDateRange}
            data={revenueData?.revenueReport}
            isLoading={revenueIsFetching || revenueLoading}
            setIsWhatsApp={() => {
              setRevenueWhatsApp(true);
              // setRevenueWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setRevenueWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "segrep" && (
          <SegmentReport
            data={segmentData?.data}
            selected2Date={segmentDate}
            setSelected2Date={setSegmentDate}
            setIsWhatsApp={() => {
              setSegmentWhatsApp(true);
              // setPaceWhatsApp(false);
            }}
            isLoading={segmentFetching || segmentLoading}
          />
        )}
        {reportId == "sourep" && (
          <SourceReport
            setDateRange={setSourceDateRange}
            dateRange={sourceDateRange}
            data={sourceData?.data}
            isLoading={sourceLoading || sourceFetching}
            setIsWhatsApp={() => {
              setSourceWhatsApp(true);
              // setSourceWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setSourceWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "quarep" && (
          <QuarterlyReport
            isLoading={quarterlyLoading || quaterlyFetching}
            dateRange={quarterlyDateRange}
            setDateRange={setQuaterlyDateRange}
            data={quarterlyData?.data}
            setIsWhatsApp={() => {
              setQuaterlyWhatsApp(true);
              // setQuaterlyWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setQuaterlyWhatsAppAdmin(true);
            }}
            QuaterlyWhatsAppAdmin={QuaterlyWhatsAppAdmin}
          />
        )}
        {reportId == "parrep" && (
          <ParityReport
            setDateRange={setParityDateRange}
            dateRange={parityDateRange}
            data={parityData?.data}
            isLoading={parityLoading || parityFetching}
            setIsWhatsApp={() => {
              setParityWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setParityWhatsAppAdmin(true);
            }}
            asOn={parityData?.asOn}
          />
        )}
        {reportId == "ratrep" && (
          <RatesReport
            setDateRange={setRatesDateRange}
            dateRange={ratesDateRange}
            data={ratesData?.data}
            isLoading={ratesLoading || ratesFetching}
            setIsWhatsApp={() => {
              setRatesWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setRatesWhatsAppAdmin(true);
            }}
            parityWhatsAppAdmin={ratesWhatsAppAdmin}
            asOn={ratesData?.asOn}
          />
        )}

        {reportId == "pickrep" && (
          <PickUpReport
            data={pickUpData?.data}
            setDateRange={setPickUpDateRange}
            dateRange={pickUpDateRange}
            isLoading={pickupLoading || pickupFetching}
            setIsWhatsApp={() => {
              setPickUpWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setPickUpWhatsAppAdmin(true);
            }}
            pickUpWhatsApp={pickUpWhatsApp}
          />
        )}

        {reportId == "yeenrep" && (
          <YearEndReport
            setDateRange={setYearDateRange}
            dateRange={yearDateRange}
            data={yearEndData?.data}
            isLoading={yearLoading || yearFetching}
            setIsWhatsApp={() => {
              setYearEndWhatsApp(true);
              // setYearEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setYearEndWhatsAppAdmin(true);
            }}
          />
        )}

        {reportId == "occrep" && (
          <OccupencyReport
            getInventory={getInventory}
            isLoading={inventoryLoading || inventoryFetching}
            selectedDate={selectedInventryDateRange}
            setSelectedDate={setSelectedInventryDateRange}
            setIsWhatsApp={() => {
              setInventoryWhatsApp(true);
              // setYearEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setInventoryWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId === "canrep" && (
          <CancellationReport
            data={cancelllationData}
            isLoading={cancellationIsFetching || cancellationIsLoading}
            setDateRange={setCancellationDateRange}
            dateRange={cancellationDateRange}
            setIsWhatsApp={() => {
              setRateChangeWhatsApp(true);
              // setParityWhatsApp(false);
            }}
          />
        )}
        {reportId === "rachrepra" && (
          <RateChangeReport
            data={cancelllationData}
            isLoading={cancellationIsFetching || cancellationIsLoading}
            setDateRange={setCancellationDateRange}
            dateRange={cancellationDateRange}
            setIsWhatsApp={() => {
              setRateChangeWhatsApp(true);
              // setParityWhatsApp(false);
            }}
          />
        )}
        
      </div>
    </div>
  );
};

export default ReportId;
