import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import Skeleton from "react-loading-skeleton";
import animation from "../newLoader/animation.json";

const CustomSkeleton = ({
  height,
  width,
  className,
  animated = false,
  title,
  isTransparent=false
}) => {
  const propertyName = window.localStorage.getItem("name");
  return (
    <div className={`w-[100%] relative ${className}`}>
      {!animated ? (
        <Skeleton
          height={height}
          width={width}
          baseColor="#3396E2BD"
          borderRadius={10}
          enableAnimation
          highlightColor="#6679D3B5"
          duration={1}
        />
      ) : (
        <div
          style={{ width: width, height: height }}
          className={`flex flex-col p-4  rounded-lg  bg-transparent justify-center items-center`}
        >
          <div className="w-[100%] text-white dark:text-black text-left">Hi {propertyName?.split(' ')[0]}!, Rate-X is building your {title}.</div>
          <div className="w-[100%]  text-left"></div>
          

          <Player
            autoplay
            loop
            src={animation}
            style={{ height: "300px", width: "300px" }}
          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
          <div className="text-white dark:text-black mt-[100px]">
            {" "}
            Keep patience your property's {title} is loading...{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSkeleton;
