import React from "react";
import * as ExcelJS from "exceljs";
import { formatDate } from "./formatDate";
import { logoUrl } from "./retvensLogo";
import pickupreReport from './PickUp.xlsx'

export const downloadPickUPReport = async (temp) => {
  const DATA3 = [];
  DATA3.push(temp);
  const workbook = new ExcelJS.Workbook();
  // Read the existing file
  // await workbook.xlsx.readFile();

  const worksheet = workbook.addWorksheet("Data");

  // if (!worksheet) {
  //   console.error("Worksheet not found!");
  //   return;
  // }

  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoUrl,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:D3");
  worksheet.addImage(logoId, "A1:A3");

  const columns = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
  ];
  const columnWidths = [
    25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
  ]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });


  // Display today's date at J1
  const dateCell = worksheet.getCell("D1");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";
  dateCell.alignment = {  vertical: "middle" }; 
  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Pickup Report - ${DATA3[0]?.hotelName}  (${formatDate(DATA3[0].pickupToday?.startDate)} ) `;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  worksheet.mergeCells("A4", "D4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "I5");

  DATA3.forEach(async (item) => {
    const { pickupToday, pickupLastYear, pickupThisMonth, pickupNextMonth } =
      item;

    worksheet.addRow([]);
    worksheet.addRow(["Pickup"]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    var lastRowNumber1 = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber1}`, `D${lastRowNumber1}`);

    worksheet.addRow(Object.keys(pickupToday?.res[0])).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    pickupToday?.res.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
    var lastRowBold = worksheet.getRow(worksheet.rowCount);
    lastRowBold.eachCell((cell) => {
      cell.font = { bold: true }; // Make the entire last row bold
    });
    worksheet.addRow([]);
    worksheet.addRow(["Last Year pickup"]).eachCell((cell) => {
      lastRowNumber1 = worksheet.rowCount;
      worksheet.mergeCells(`A${lastRowNumber1}`, `D${lastRowNumber1}`);
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    worksheet.addRow(Object.keys(pickupLastYear?.res[0])).eachCell((cell ,index) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      if(index===pickupLastYear.res[0].length){
        cell.font = { bold: true }; // Make the cell bold if value is 'total'

      }
      if(cell.value=='Total'){
        cell.font = { bold: true }; // Make the cell bold if value is 'total'
      }
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    pickupLastYear?.res.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
    

        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
     lastRowBold = worksheet.getRow(worksheet.rowCount);
    lastRowBold.eachCell((cell) => {
      cell.font = { bold: true }; // Make the entire last row bold
    });
    worksheet.addRow([]);

    worksheet.addRow(["Actual -BOB"]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    lastRowNumber1 = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber1}`, `D${lastRowNumber1}`);
    worksheet.addRow(Object.keys(pickupThisMonth?.res[0])).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    pickupThisMonth?.res.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
   lastRowBold = worksheet.getRow(worksheet.rowCount);
    lastRowBold.eachCell((cell) => {
      cell.font = { bold: true }; // Make the entire last row bold
    });
    worksheet.addRow([]);

    worksheet.addRow(["Forecast"]).eachCell((cell) => {
      lastRowNumber1 = worksheet.rowCount;
      worksheet.mergeCells(`A${lastRowNumber1}`, `D${lastRowNumber1}`);
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    worksheet.addRow(Object.keys(pickupNextMonth?.res[0])).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    pickupNextMonth?.res.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });
   lastRowBold = worksheet.getRow(worksheet.rowCount);
    lastRowBold.eachCell((cell) => {
      cell.font = { bold: true }; // Make the entire last row bold
    });
    const lastRowNumber = worksheet.rowCount;
    // const chartData = {
    //   type: "bar",
    //   data: {
    //     labels: ["Pickup", "Last Year pickup", "Actual -BOB", "Forecast"],
    //     datasets: [
    //       {
    //         label: "Data",
    //         data: [pickupToday, pickupLastYear, pickupThisMonth, pickupNextMonth],
    //         backgroundColor: "rgba(75, 192, 192, 0.2)",
    //         borderColor: "rgba(75, 192, 192, 1)",
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //       },
    //     },
    //   },
    // };

    // const chartCanvas = document.createElement("canvas");
    // const chartContext = chartCanvas.getContext("2d");
    // const chart = new Chart(chartContext, chartData);

    // const chartImage = chartCanvas.toDataURL("image/png");
    // const chartImageId = workbook.addImage({
    //   base64: chartImage,
    //   extension: "png",
    // });

    // worksheet.addImage(chartImageId, {
    //   tl: { col: 0, row: lastRowNumber + 1 },
    //   ext: { width: 600, height: 400 },
    // });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  
  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);
  
  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `PickUp Report ${DATA3[0]?.hotelName } .xlsx`; // Set the desired filename here
  a.click();
  
  // Clean up
  window.URL.revokeObjectURL(url);
  // Save the modified workbook
  // await workbook.xlsx.writeFile("./PickUp.xlsx");

};
