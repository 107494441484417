import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../assets/img/rateXBlack.svg";
import { useDispatch } from "react-redux";
import { setLoginData } from "../../redux/states/login";
import {
  useGetLoginInfo2Query,
  useGetLoginInfoQuery,
} from "../../redux/slices/settings/userManagement";

const SwitchScreen = () => {
  const [hidd, setHidd] = useState();
  const dispatch = useDispatch();
  const rateXtoken = window.localStorage.getItem("token");
  const rateXtOnboarding = window.localStorage.getItem("onboardingStatus");
  const location = useLocation();
  const pathName = location.pathname;
  const token = pathName?.split("token:")[1]?.split("/hId:")[0];
  const hId = pathName?.split("hId:")[1];
  const { data: loginInfo } = useGetLoginInfo2Query(
    {
      hId: hidd || hId,
      accountType: "r-world",
      token: token,
    },
    {
      skip: hidd ? false : true,
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (token && hId && !rateXtoken) {
      setHidd(hId);
      if (loginInfo) {
        window.localStorage.setItem('onboardingStatus',loginInfo?.data?.token?.completionStatus)
        window.localStorage.setItem(
          "isCMVerified",
          loginInfo.data.isCMVerified
        );
        window.localStorage.setItem(
          "rateshopperDateRange",
          JSON.stringify(loginInfo.data.dataDateRange.e)
        );
        window.localStorage.setItem(
          "intelligenceDateRange",
          JSON.stringify(loginInfo.data.dataDateRange.i)
        );
        window.localStorage.setItem("name", loginInfo?.data?.name);
        window.localStorage.setItem(
          "designation",
          loginInfo?.data?.designation
        );
        window.localStorage.setItem(
          "profilePic",
          loginInfo?.data?.profilePicture
        );
        window.localStorage.setItem(
          "activeOta",
          JSON.stringify(loginInfo?.data?.activeOtas)
        );
        window.localStorage.setItem(
          "permissions",
          JSON.stringify(loginInfo?.data?.permissions)
        );
        window.localStorage.setItem(
          "propertiesList",
          JSON.stringify(loginInfo?.data?.propertiesList)
        );
        window.localStorage.setItem(
          "extractionDate",
          JSON.stringify(loginInfo?.data?.lastExtraction)
        );
        dispatch(setLoginData(loginInfo?.data));
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("hId", hId);
        window.location.reload();
      }
    } else {
      if(rateXtOnboarding === "completed"){
      navigate("/dashboard");
    }else{
      navigate("/property_details");
    }
    }
  }, [hId, token, loginInfo]);

  return (
    <div className="h-screen w-[80%] mx-auto flex-col flex justify-center items-center">
      <img src={img} />
      <h1 className="text-xl font-medium  my-4">
        Hold on, Rate-X is preparing your data!
      </h1>
      <div className="w-[100%]">
        <ProgressBar />
      </div>
    </div>
  );
};

export default SwitchScreen;

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return oldProgress + 1;
      });
    }, 50); // 5000ms / 100 steps = 50ms per step

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={{ ...styles.progressBar, width: `${progress}%` }}>
        <span style={styles.progressText}>{progress}%</span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "20px",
    backgroundColor: "#e0e0e0",
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#95c82a",
    transition: "width 0.05s linear",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  progressText: {
    color: "#fff",
    fontWeight: "bold",
    zIndex: 1,
  },
};
