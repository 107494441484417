import React from "react";
import formatCurrency from "../../utils/formatCurrency";
import InfoToolTip from "../tooltip/InfoToolTip";

const RateTendencies = ({ title, data }) => {
  const low = 7000;
  const avg = 9000;
  const high = 20000;
  const alltimelow = 3000;
  const alltimehigh = 25000;
  const totalWidth = 100
  return (
    <div className=" h-full w-[100%] bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-xl p-4">
      <div className=" flex justify-between mb-4">
        <div className="text-base  text-[#ffffff] font-semibold ">
        </div>
      </div>
      <div>
        <div className="">
          {[data] != null && [data]?.length > 0 ? (
            [data]?.map((item, index) => {
              const averageRateDifference = data?.averageRate - data?.lowestRate ;
              const highestRateDifference = data?.highestRate - data?.lowestRate ;

              const TotalRange = data && data[0]?.high - data[0]?.low ;
              const lowPercentage = 0
              const highPercentage = totalWidth
              const avgPercentage = (averageRateDifference / highestRateDifference) * totalWidth;
              const Range = highPercentage-lowPercentage;

              // (value/total value)×100%
              return (
                <>
                <div>
                  <div className="flex justify-between items-center">
                  <p className="text-base  text-[#ffffff]  mb-4 -mt-4">
                Your Rate Range
                  </p>
                  <div className="-mt-8">
                  <InfoToolTip content={"This feature provides an overview of the range of rates offered by your property across different room types and booking channels."} />
                </div>
                  </div>
               
                  <div className="flex justify-between w-full text-white mb-4">
                    <div className="w-full text-[11px] text-white">
                      Lowest rate
                      <p className="text-white text-lg">
                        {formatCurrency(data?.lowestRate) || "₹6,666"}
                      </p>
                    </div>
                    <div className="w-full grid  text-[11px] text-white justify-center ">
                      Average Rate
                      <p className="text-white text-lg ">
                        {formatCurrency(data?.averageRate) || "₹6,666"}
                      </p>
                    </div>
                    <div className="w-full grid text-[11px] text-white justify-end">
                      Highest Rate
                      <p className="text-white text-lg d">
                        {formatCurrency(data?.highestRate) || "₹6,666"}
                      </p>
                    </div>
                  </div>
                  <div className="relative  bg-[#E9FBEA] w-[100%] rounded-lg  h-2">
                    {/* <div
                      className=" h-full  bg-[#BAEDBD] absolute   "
                      style={{ left: `${lowPercentage}%`, width: `${Range}%` }}
                    ></div> */}
                    <div
                      className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 "
                      style={{ left: `${lowPercentage}%` }}
                    ></div>
                    <div
                      className="w-2 h-6 bg-neutral-950   rounded-full absolute -top-[8px] -mt-1/2"
                      style={{ left: `${avgPercentage}%` }}
                    ></div>

                    <div
                      className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 transform -translate-x-1/2"
                      style={{ left: `${highPercentage}%` }}
                    ></div>
                  </div>
                </div>
                <div className=" h-[50px] w-[100%] flex justify-center items-center mx-auto mt-4">
                <div className="bg-gray-200 h-0.5  w-[100%] mx-auto"></div>
                </div>
                {/* <div>
                <p className="text-base   text-white mb-4 mt-2">
                    {item.subtitle}
                  </p>
                  <div className="flex justify-between w-full text-white mb-4">
                    <div className="w-full text-[11px] text-white ">
                      Lowest rate
                      <p className="text-white text-lg  font-semibold">
                        {formatCurrency(data[0]?.low) || "₹6,666"}
                      </p>
                    </div>
                    <div className="w-full grid  text-[11px] text-white justify-center">
                      Average Rate
                      <p className="text-white text-lg  font-semibold">
                        {formatCurrency(data[0]?.avg) || "₹6,666"}
                      </p>
                    </div>
                    <div className="w-full grid text-[11px] text-white justify-end ">
                      Highest Rate
                      <p className="text-white text-lg  font-semibold">
                        {formatCurrency(data[0]?.high) || "₹6,666"}
                      </p>
                    </div>
                  </div>
                  <div className="relative  bg-[#E9FBEA] w-[100%] rounded-lg  h-2">
             
                    <div
                      className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 "
                      style={{ left: `${lowPercentage}%` }}
                    ></div>
                    <div
                      className="w-2 h-6 bg-neutral-950   rounded-full absolute -top-[8px] -mt-1/2"
                      style={{ left: `${avgPercentage}%` }}
                    ></div>

                    <div
                      className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 transform -translate-x-1/2"
                      style={{ left: `${highPercentage}%` }}
                    ></div>
                  </div>
                </div> */}
             
                </>
              );
            })
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RateTendencies;
