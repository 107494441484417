import React, { useState } from 'react'
import arrow from '../../assets/icons/ArrowRise.svg'
import down from '../../assets/icons/decrease.svg'
import '../../pages/onboarding/Global.css'
const RateRangeCard = ({className,percentage,price}) => {
  
    // const percentage = 50; // Example percentage
    // const price = '$100'; // Example price

   

    return (
        <div className=" bg-white  group-hover:bg-[#ccd6e5]  hover:bg-neutral-light-5 flex  overflow-hidden  items-center" >
     
       <div className={`h-5 group-hover:bg-transparent flex flex-col overflow-hidden ${percentage?.toString().includes("-") ? 'text-red-500' :'text-green-500'} `}>
         <div className={`  group-hover:bg-transparent leading-5 hover:opacity-0  hover:leading-[0rem]  rounded hover:transition-all transition-all  hover:duration-200  hover:ease-in-out`}>
           { percentage}%
        </div>
        <div className="   leading-5 group bg-transparent   ">
            { price}
        </div></div>
        <img src={percentage?.toString().includes("-")?down:arrow} alt="arrow" className='bg-neutral-light-3  group-hover:bg-transparent ' />
    </div>
    );
};
    
export default RateRangeCard