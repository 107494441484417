import { CurrentProperty, UserId } from "../../../utils/impVars";
import { emptySplitApi } from "../../injectEndpoints";

export const Dashboard = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    dashboardOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/overview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["dashboard"],
    }),
    channelPerformance: builder.query({
      query: (data) => ({
        url: `/dashboard/channelPerformance`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["channelPerformance"],
    }),
    compsetRateRange: builder.query({
      query: (data) => ({
        url: `/dashboard/compsetRateRange`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["compsetRateRange"],
    }),
    getAllModules: builder.query({
      query: (data) => ({
        url: `/userDashboardSetting/getallModules`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getAllModules"],
    }),
    getSourceOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/getSourceOverview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getSourceOverview"],
    }),
    getNewDashboardOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/todaysOverview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getNewDashboardOverview"],
    }),
    getPerformanceMatrices: builder.query({
      query: (data) => ({
        url: `/dashboard/performaceMatrix`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getPerformanceMatrices"],
    }),
    getSentimentalAnalysis: builder.query({
      query: (data) => ({
        url: `/dashboard/getSentimentAnalysisData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getSentimentalAnalysis"],
    }),
    getForecastedData: builder.query({
      query: (data) => ({
        url: `/dashboard/getForecastedData`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getForecastedData"],
    }),
    getAdvertisementData: builder.query({
      query: (data) => ({
        url: `/dashboard/getAdvertisementDetail`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getForecastedData"],
    }),
    getDropDownData: builder.query({
      query: (data) => ({
        url: `/dashboard/getReportDetail`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getAdvertisementData"],
    }),
    getQuickAction: builder.query({
      query: (data) => ({
        url: `/dashboard/getQuickActions`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getQuickAction"],
    }),
    postQuickReport: builder.mutation({
      query: (data) => ({
        url: "dashboard/editQuickReportDetail",
        method: "PATCH",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["postQuickReport"],
    }),
    getQuickReport: builder.query({
      query: (data) => ({
        url: "dashboard/getQuickReportDetail",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getQuickReport"],
    }),
    getFilters: builder.query({
      query: (data) => ({
        url: "/userDashboardSetting/getDropDownList",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getFilters"],
    }),
    getRatings: builder.query({
      query: (data) => ({
        url: "/dashboard/getReputationWidgetData",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getRatings"],
    }),
    getOTBDataForChain: builder.query({
      query: (data) => ({
        url: "/dashboard/getOTBDataForChain",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getOTBDataForChain"],
    }),
    getExportOTBDataForChain: builder.query({ 
      query: (data) => ({
        url: "/dashboard/getExportOTBDataForChain",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getExportOTBDataForChain"],
    }),
    getDashboardChain: builder.query({
      query: (data) => ({
        url: "/dashboard/chainPropertyTodaysRate",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getDashboardChain"],
    }),
    getDashboardChainGroupVisibility: builder.query({
      query: (data) => ({
        url: "/dashboard/groupVisibility",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getDashboardChainGroupVisibility"],
    }),
    getDashboardChainGroupReputation: builder.query({
      query: (data) => ({
        url: "/dashboard/groupReputation",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getDashboardChainGroupReputation"],
    }),
    getDashboardChainGroupOverview: builder.query({
      query: (data) => ({
        url: "/dashboard/getChainOverView",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getDashboardChainGroupReputation"],
    }),
    dashboardUrgentAction: builder.query({
      query: (data) => ({
        url: "/dashboard/getUrgentActions",
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["dashboardUrgentAction"],
    }),
    getExportSourceOverview: builder.query({
      query: (data) => ({
        url: `/dashboard/getExportSourceOverview`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getExportSourceOverview"],
    }),
    getChannelManagerFaults: builder.query({
      query: (data) => ({
        url: `/utils/getChannelManagerFaults`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getChannelManagerFaults"],
    }),
    getKeywordsReputation: builder.query({
      query: (data) => ({
        url: `/dashboard/getReviewkeyword`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getKeywordsReputation"],
    }),
    getAllCompsetRates: builder.query({
      query: (data) => ({
        url: `/dashboard/getAllCompsetRates`,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getAllCompsetRates"],
    }),
    getHotelPerformance: builder.query({
        query:(data)=>({
            url: `/dashboard/getHotelPerformance`,
            method: "GET",
            params: data,
        }),
        invalidatesTags: ["getHotelPerformance"]
    }),
    getHotelPerformanceDashboard: builder.query({
        query:(data)=>({
            url: `/dashboard/hotelPerformanceDashBoard`,
            method: "GET",
            params: data,
        }),
        invalidatesTags: ["hotelPerformanceDashBoard"]
    }),
    getHotelPerformanceDetail: builder.query({
      query: (data) => ({
        url: ``,
        method: "GET",
        params: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["getHotelPerformanceDetail"],
    }),
    
  }),
  overrideExisting: false,
});
export const {
  useDashboardOverviewQuery,
  useChannelPerformanceQuery,
  useCompsetRateRangeQuery,
  useGetAllModulesQuery,
  useGetSourceOverviewQuery,
  useGetNewDashboardOverviewQuery,
  useGetPerformanceMatricesQuery,
  useGetSentimentalAnalysisQuery,
  useGetForecastedDataQuery,
  useGetAdvertisementDataQuery,
  useGetQuickActionQuery,
  useGetDropDownDataQuery,
  usePostQuickReportMutation,
  useGetQuickReportQuery,
  useGetFiltersQuery,
  useGetRatingsQuery,
  useGetOTBDataForChainQuery,
  useGetDashboardChainQuery,
  useGetDashboardChainGroupVisibilityQuery,
  useGetDashboardChainGroupReputationQuery,
  useGetDashboardChainGroupOverviewQuery,
  useDashboardUrgentActionQuery,
 useGetExportSourceOverviewQuery,
 useGetChannelManagerFaultsQuery,
 useGetKeywordsReputationQuery,
 useGetAllCompsetRatesQuery,
 useGetExportOTBDataForChainQuery,
 useGetHotelPerformanceQuery,
 useGetHotelPerformanceDashboardQuery,
 useGetHotelPerformanceDetailQuery
} = Dashboard;
