import React, { useEffect, useRef, useState } from "react";
import { useGetRateChangeReportQuery } from "../../../redux/slices/reports";
import RateChangeReportTable from "../../newTable/rateChangeReportTable";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import { formatDate } from "../DownloadExcel/formatDate";
import { format } from "date-fns";
import { Calendar, DateRangePicker } from "react-date-range";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DownloadCancellationReport } from "../DownloadExcel/CancellationReport";
import NewRateChangeReportTable from "../../newTable/newRateRangeRepotrTable";

const RateChangeReport = ({
  data,
  dateRange,
  setDateRange,
  isLoading,
  setIsWhatsApp,
}) => {
  // const [showDateRange, setShowDateRange] = useState(false);
  const dateLimit = useIntelligenceDateRange();
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);
  //   const [showDateRange, setshowDateRange] = useState(false);
  const newDate = new Date();

  const [parityDateRange, setParityDateRange] = useState([
    {
      startDate: newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  // const {data} = useGetRateChangeReportQuery({})
  // const { dataCalcellationDataFinal ,sourceCancellationDataFinal,roomCancellationDataFinal} = data?.cancellationData
  // console.log(dataCalcellationDataFinal,"dataCalcellationDataFinal")
  //   const startDate = parityDateRange[0].startDate;
  const [expandedIndex, setExpandedIndex] = useState(0);
  const fromDate = format(new Date(dateLimit?.fromDate), "MM/dd/yyyy");
  const endDate = format(new Date(dateLimit?.toDate), "MM/dd/yyyy");
  const ref1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  // // const { pickupToday ,pickupLastYear,pickupThisMonth,pickupNextMonth} = data;
  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  return (
    <div className="h-[80vh]">
      <div className="h-[10%] my-4 flex items-center text-white dark:text-black justify-between">
        <h1> Rate Change Report</h1>
        <div className="flex ">
          <div className=" flex items-center gap-2 justify-center pr-2">
            <div className="w-fit text-sm">Select date range</div>
            {
              <div
                className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-white dark:text-black rounded-md border-solid border border-[#A0A4B0] z-20"
                ref={ref1}
              >
                <div
                  className="flex text-white dark:text-black  gap-2 items-center"
                  onClick={() => setShowDateRange(!showDateRange)}
                >
                  {" "}
                  <AiFillCalendar size={14} />{" "}
                  {format(new Date(dateRange[0].startDate), "dd MMM yyy") ||
                    " "}{" "}
                  -{format(new Date(dateRange[0].endDate), "dd MMM yyy") || " "}
                </div>
                {showDateRange && (
                  <div className="absolute top-[50px] -left-[200px] text-black">
                    <DateRangePicker
                      ranges={dateRange}
                      onChange={(range) => {
                        setDateRange([
                          {
                            startDate: range?.range1?.startDate,
                            endDate: range?.range1?.endDate,
                            range: "range 1",
                          },
                        ]);
                      }}
                      minDate={new Date(fromDate)}
                      maxDate={new Date(endDate)}
                    />
                  </div>
                )}
              </div>
            }
          </div>
          <h1
            onClick={() => {
              DownloadCancellationReport(
                data?.rateChangeReport?.cancellationData
              );
            }}
            className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            Download
          </h1>{" "}
          <div
            onClick={setIsWhatsApp}
            className="flex m-auto w-[170px] gap-2  justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
          >
            <AiOutlineWhatsApp size={18} /> Send to WhatsApp
          </div>
        </div>
      </div>{" "}
      {!isLoading ? (
        <div className="flex flex-col gap-2 mt-2 overflow-y-scroll h-[90%]">
          <h1 className="text-white dark:text-black text-[24px] font-[700]"> Internal Data</h1>
          {/* Today's comparison */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year']}
            label={"Today's Comparison"}
            isExpanded={expandedIndex === 0}
            onExpand={() => handleExpand(0)}
          />
          {/* This Month Comparison */}

          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"This Month Comparison"}
            isExpanded={expandedIndex === 1}
            onExpand={() => handleExpand(1)}
          />

          {/* Pace Report */}
          <h1 className="text-white dark:text-black text-[24px] font-[700]"> Pace Report</h1>

          {/* Arrival This Month */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Arrival This Month"}
            isExpanded={expandedIndex === 2}
            onExpand={() => handleExpand(2)}
          />

          {/* Reservation Pace */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Reservation Pace"}
            isExpanded={expandedIndex === 3}
            onExpand={() => handleExpand(3)}
          />

          {/* Pickup */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Pickup Pave"}
            isExpanded={expandedIndex === 4}
            onExpand={() => handleExpand(4)}
          />

          {/* compset data */}

          <h1 className="text-white dark:text-black text-[24px] font-[700]"> Compset Based Data</h1>



          {/* My Compset Comparison */}

          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Rate Comparison"}
            isExpanded={expandedIndex === 4}
            onExpand={() => handleExpand(4)}
          />

          {/* Average Monthly Change */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Average Monthly Change"}
            isExpanded={expandedIndex === 4}
            onExpand={() => handleExpand(4)}
          />

          {/* Visibility Comparison */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Visibility Comparison"}
            isExpanded={expandedIndex === 4}
            onExpand={() => handleExpand(4)}
          />

          {/* Reputation Comparison */}
          <NewRateChangeReportTable
            handleExpand={handleExpand}
            data={
              data?.rateChangeReport?.cancellationData
                ?.dateCancellationDataFinal
            }
            header={['Metrics', 'Same Time Last Month', 'Same Time Last Year', 'Actual', 'Business on Books', 'Overall']}
            label={"Reputation Comparison"}
            isExpanded={expandedIndex === 4}
            onExpand={() => handleExpand(4)}
          />

        </div>
      ) : (
        <div className="w-[100%] mt-[100px]">
          <CustomSkeleton height={400} />
        </div>
      )}
    </div>
  );
};

export default RateChangeReport;
