import img from "../../assets/img/image 82.svg";
import React, { useRef, useEffect, useState } from 'react';

const getColorBasedOnRating = (rating) => {
    // Define color conditions for each rating
    switch (rating) {
        case 1:
            return { bgColor: '#F7D2CC', borderColor: '#FD1A07' };
        case 2:
            return { bgColor: '#F7D2CC', borderColor: '#F3731C' };
        case 3:
            return { bgColor: '#5A452C', borderColor: '#EF9725' };
        case 4:
            return { bgColor: '#F3F4D4', borderColor: '#E7E238' };
        case 5:
            return { bgColor: '#D3F9EA', borderColor: '#8BCD48' };
        case 6:
            return { bgColor: '#D3F9EA', borderColor: '#45FCA4' };
        default:
            return { bgColor: 'gray-500', borderColor: 'gray-700' };
    }
};

const RatingComponent = ({ rating }) => {
    const { bgColor, borderColor } = getColorBasedOnRating(rating);
    const [borderVisibility, setBorderVisibility] = useState({ top: 'transparent', left: 'transparent', bottom: 'transparent', right: 'transparent' });
    const containerRef = useRef(null);

    useEffect(() => {
        // Update border visibility based on rating
        if (rating >= 0 && rating < 2) {
            setBorderVisibility({ top: borderColor, left: 'transparent', bottom: 'transparent', right: 'transparent' });
        } else if (rating >= 2 && rating < 4) {
            setBorderVisibility({ top: borderColor, left: borderColor, bottom: 'transparent', right: 'transparent' });
        } else if (rating >= 4 && rating < 5) {
            setBorderVisibility({ top: borderColor, left: borderColor, bottom: borderColor, right: 'transparent' });
        } else if (rating >= 5 && rating <= 6) {
            setBorderVisibility({ top: borderColor, left: borderColor, bottom: borderColor, right: borderColor });
        }
    }, [rating, borderColor]);

    const containerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
        border: `4px solid transparent`, // Set initial border to transparent
        borderTop: `4px solid ${borderVisibility.top}`,
        borderLeft: `4px solid ${borderVisibility.left}`,
        borderBottom: `4px solid ${borderVisibility.bottom}`,
        borderRight: `4px solid ${borderVisibility.right}`,
        backgroundColor: bgColor,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <div className='w-[100%] h-[100%] flex items-center justify-center'>
            <div style={containerStyle}>
                <div className='absolute w-[100%] p-1 h-[100%] items-center flex justify-center'>
                    <img className="object-cover" src={img} alt='' />
                </div>
            </div>
        </div>
    );
};

const CircularProgressBar = ({ rating }) => {
    // You can change the rating value to test different color combinations
    // const rating = {rating};

    return (
        <div className='w-[100%] h-[100%] flex items-center justify-center'>
            <RatingComponent rating={rating} />
        </div>
    );
};

export default CircularProgressBar;



// import React from 'react';

// const CircularProgressBar = () => {
//     const containerStyle = {
//         width: '80vw',
//         height: '80vh',
//         position: 'relative',
//         margin: '0 auto',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: '#f0f0f0', // Set your desired background color
//     };

//     const gradientBorderStyle = {
//         content: '',
//         position: 'absolute',
//         width: '100%',
//         height: '100%',
//     };

//     const topRightGradientStyle = {
//         ...gradientBorderStyle,
//         background: 'linear-gradient(to top right, transparent 50%, #000 50%)',
//         zIndex: '1',
//     };

//     const bottomLeftGradientStyle = {
//         ...gradientBorderStyle,
//         background: 'linear-gradient(to bottom left, transparent 50%, #000 50%)',
//         zIndex: '1',
//     };

//     return (
//         <div style={containerStyle}>
//             <div style={topRightGradientStyle}></div>
//             <div style={bottomLeftGradientStyle}></div>
//             {/* Content goes here */}
//         </div>
//     );
// };
// export default CircularProgressBar;


