import React from "react";
import TableButton from "../settingButton/TableButton";

const SettingProfile = ({onClick,profile,data}) => {
    const {name,email,phoneNumber,designation,profileImg} = data && data[0]
    return (
        <div className="w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient  p-4 flex justify-between items-center rounded-2xl h-[106px]">
            <div className="h-[72px] w-[72px] rounded-lg overflow-hidden">
                <img
                    src={profileImg}
                    alt="Profile"
                    className="w-full h-full object-cover"
                />
            </div>
            <div className="flex ml-2 w-[100%] justify-between items-center">
                <div>
                    <div className="flex gap-2 items-center ">
                        <p className=" text-[17px] font-[500] ">{name}</p>
                        <p className="text-[14px] ">{designation}</p>
                    </div>
                    <div className="text-[14px] font-[400] ">
                       {phoneNumber}
                    </div>
                    <div className="text-[14px] font-[400] ">
                       {email}
                    </div>
                </div>
            </div>
            <div className="cursor-pointer">
                <TableButton title={"Edit"} onClick={onClick}/>
            </div>
        </div>
    );
};

export default SettingProfile;
