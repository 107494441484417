import React, { useEffect, useRef, useState } from "react";
import TableButton from "../../../../components/settingButton/TableButton";
import SettingButton from "../../../../components/settingButton/SettingButton";
import Table from "../../../../components/table";
import Tableactiontype from "../../../../components/table/TableActionTYpe";
import CustomSkeleton from "../../../../components/Skeleton";
import {
  useAddNewSeasonMutation,
  useDeleteSeasonMutation,
  useGetSeasonTypeQuery,
} from "../../../../redux/slices/settings/channelManager/season";
import { CurrentProperty } from "../../../../utils/impVars";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../../components/inputFeild";
import Calendar from "../../../../components/calendar";
import { Dropdown as MultiSelectDropdown } from "../../../../pages/IntelligenceNewDashboard";
import { useGetMasterRoomQuery } from "../../../../redux/slices/rooms";

const Seasons = ({ handleBackButtonClick }) => {
  const hId = CurrentProperty();
  const {
    data: getSeason,
    refetch,
    getSeasonIsLoading,
    getSeasonIsFetching,
  } = useGetSeasonTypeQuery({
    hId: hId,
  });
  const [deleteSeason] = useDeleteSeasonMutation();
  const [isAddSeason, setIsAddSeason] = useState(false);
  const [season, setSeason] = useState();
  const [isSeason, setIsSeason] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [addNewSeason] = useAddNewSeasonMutation();

  const handleDelete = (id) => {
    deleteSeason({ seasonTypeId: id, hId: hId })
      .then((res) => {
        toast.success("Season Delete Successfully!", {
          position: "bottom-right",
        });
        refetch();
      })
      .catch((err) => {
      });
  };

  const handleAddSeasonClick = () => {
    setIsAddSeason(true);
  };

  const column = [
    {
      name: "Season Name",
      selector: (row) => row["seasonName"],
    },
    {
      name: "Start Date",
      selector: (row) => format(new Date(row["startDate"]), "dd-MMM-yy"),
    },
    {
      name: "End Date",
      selector: (row) => format(new Date(row["endDate"]), "dd-MMM-yy"),
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onViewClick={() => {
              setSeason(row);
              setIsView(true);
              setIsEdit(true);
            }}
            onEditClick={() => {
              setSeason(row);
              setIsEdit(true);
            }}
            showDelete={true}
            handleDelete={() => handleDelete(row?.seasonId)}
            refetch={refetch}
          />
        );
      },
    },
  ];

  return (
    <>
      {isSeason || isEdit || isView || isAddSeason ? (
        <AddNewSeason
          onClose={() => {
            setIsAddSeason(false);
            setIsSeason(false);
            setIsEdit(false);
            setIsView(false);
          }}
          AddNewSeason={addNewSeason}
          data={season}
          isView={isView}
          isEdit={isEdit}
          //   editMealPlan={editMealPlan}
          refetch={refetch}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Seasons"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px]  font-[500]">Seasons</p>
                <div className="flex items-center gap-2">
                  {/* <Dropdown selectedItem={"Meal"} /> */}
                  <TableButton
                    title={"Add New"}
                    onClick={handleAddSeasonClick}
                  />
                </div>
              </div>
              {getSeason?.data != null &&
              getSeason?.data.length > 0 &&
              !getSeasonIsLoading &&
              !getSeasonIsFetching ? (
                <Table
                  columns={column}
                  pagination={true}
                  data={getSeason?.data}
                />
              ) : (
                <CustomSkeleton height={400} width={"100%"} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Seasons;

const AddNewSeason = ({
  onClose,
  AddNewSeason,
  data: editData,
  isView,
  isEdit,
  editMealPlan,
  refetch,
}) => {
  const methods = useForm({});
  const ref = useRef();
  const [numberOfRoom, setNumberOfRoom] = useState(0);
  const [mealCharge, setMealCharge] = useState(0);
  const [selectedCharge, setSelectedCharge] = useState();
  const [inputValue, setInputValue] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const hId = CurrentProperty();
  const { data: masterRoom } = useGetMasterRoomQuery({
    hId: CurrentProperty(),
  });
  const [selectedBaseRoom, setSelectedBaseRoom] = useState([]);

  const onSubmit = async (data) => {
    const obj = {
      hId: hId,
      seasons: {
        startDate: format(new Date(from), "yyyy-MM-dd"),
        endDate: format(new Date(to), "yyyy-MM-dd"),
        seasonName: data?.seasonName,
        appliedDays: selectedDays,
        baseRooms: selectedBaseRoom.map((item) => {
          return item?.value;
        }),
      },
    };
    const editParams = {
      hId: hId,
      mealPlanId: editData?.mealPlanId,
    };
    if (isEdit) {
      editMealPlan({ obj, editParams })
        .unwrap()
        .then((res) => {
          toast.success("Meal Plan Updated Successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => {
          onClose();
        });
    } else {
      AddNewSeason(obj)
        .unwrap()
        .then((res) => {
          toast.success("Season Added Successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => onClose());
    }
  };

  useEffect(() => {
    if (editData) {
      //   methods.setValue("mealPlanName", editData?.mealPlanName);
      //   methods.setValue("shortCode", editData?.mealPlanShortCode);
      //   setMealCharge(editData?.chargeDetails?.mealPlancharge);
      //   setSelectedCharge({
      //     label: editData?.chargeDetails?.chargeRule,
      //     value: editData?.chargeDetails?.chargeRule,
      //   });
    }
  }, [editData]);

//   useEffect(() => {
//     if (methods.watch("mealPlanName")) {
//       methods.setValue(
//         "shortCode",
//         GenerateShortCode(methods.watch("mealPlanName"))
//       );
//     }
//   }, [methods.watch("mealPlanName")]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const MonthArray = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ].filter((item) => {
    return item.label != from?.label;
  });
  const days = ["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"];
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6 w-[100%] h-[85vh]">
          <SettingButton
            text={"Save"}
            Title={"Add Season"}
            onClose={onClose}
            handleBackButtonClick={onClose}
            table={true}
            isView={isView}
          />

          <div className="flex gap-4 rounded-lg w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient  p-2 flex-col">
            <div className="flex flex-col gap-4 w-[100%]">
              <p className=" text-[13px]">Season Details</p>
              <div className="flex flex-row justify-center gap-4">
                <div className="w-[40%]">
                  <p className="text-[12px]">Season Name</p>
                  <div className="rounded-lg overflow-hidden">
                    <InputField
                      type={"text"}
                      className={
                        "placeholder:text-gray-700 w-full bg-[#e9ebf0] text-sm text-black h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Enter Season Name"}
                      id={"seasonName"}
                      name={"seasonName"}
                      controls={methods.control}
                      rules={{ required: "Season Name is required" }}
                    />
                  </div>
                </div>
                <div className="w-[100%] flex flex-col justify-center items-start">
                  <div className=" text-[12px]">Base Room</div>
                  <div>
                    <MultiSelectDropdown
                      placeHolder={"Select Room"}
                      options={masterRoom?.data}
                      setSelectedItem={setSelectedBaseRoom}
                      selectedItem={selectedBaseRoom}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p className=" text-[12px]">
                  Select Start And End Date For Season
                </p>
                <div className="mt-2 w-[50%] flex justify-start  items-center gap-6">
                  <div className="w-[100%] flex justify-start items-center gap-4">
                    <div className="text-sm">From</div>
                    <Calendar
                      showAll={true}
                      selectedDate={from}
                      setSelectedDate={setFrom}
                    />
                    <div className="text-sm">To</div>
                    <Calendar
                      selectedDate={to}
                      setSelectedDate={setTo}
                      showAll={true}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="text-[12px] mb-2">
                  Apply Days
                </div>
                <div className="flex gap-4">
                  {days?.map((item, index) => {
                    return (
                      <div>
                        <label
                          className="text-sm cursor-pointer text-gray-600"
                          htmlFor={item}
                        >
                          {item}
                          <div className="flex justify-center">
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setSelectedDays((prevState) => [
                                    ...prevState,
                                    item,
                                  ]);
                                } else {
                                  setSelectedDays((prevState) =>
                                    prevState.filter((day) => day !== item)
                                  );
                                }
                              }}
                              id={item}
                            />
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
