import React from 'react';
import { useTable, usePagination } from 'react-table';
import '../../pages/onboarding/Global.css';

const DynamicTable = ({ isPagination=false,   pageNumber,
    setPageNumber,  columns, data,customPagination=false, customColumnRenderers,totalPages, maxHeight, pageSizeOptions = [10, 20, 50] , isTransparent=false,tableBgColor}) => {
  // const [pageSize, setPageSize] = useState(pageSizeOptions[0]); // State to manage page size

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
 
    state: { pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSizeOptions[0], pageIndex: 0 },
    },
    usePagination
  );

  const handleNext = () => {
    if (pageNumber < totalPages) {
        setPageNumber((prev)=> prev + 1);
    }
  };

  const handlePrevious = () => {
    if (pageNumber > 1) {
      setPageNumber(prev=>prev - 1);
    }
  };
  return (
    <div className={`w-[100%] rounded-lg overflow-y-scroll scrollHide p-4 custom-table`}>
      <div className="text-neutral-light-17 text-base text-[12px] rounded-t-xl font-medium mb-2 overflow-x-auto">
        <table className={`w-full bg-newGradient  dark:bg-lightGradient  table-fixed`}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="border-b  bg-newGradient   dark:bg-lightGradient flex"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`text-[14px]  tabcol  py-4  text-white font-medium bg-transparent ${
                      index === 0 ? 'sticky left-0 z-10' : ''
                    }`}
                    style={{ width: column.width, left: index === 0 ? 0 : undefined }}
                    key={column.id}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div
        className={`text-white dark:text-black min-h-auto max-h-[${maxHeight}px]  backdrop-blur-md text-sm -mt-2 font-semibold rounded-b-xl overflow-x-auto`}
        style={{ background: tableBgColor && tableBgColor }}
      >
        <table {...getTableProps()} className="w-full table-fixed">
          <tbody className='bg-newGradient dark:bg-lightGradient' >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`h-[70px] p-2 ${
                    isTransparent ? 'bg-transparent' : ' bg-transparent   drop-shadow-lg backdrop-blur-md'
                  } group  flex text-white w-full text-[1px] font-medium `}
                  key={row.id}
                >
                  {row.cells.map((cell, index) => {
                    const customRenderer = customColumnRenderers && customColumnRenderers[index];
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={` flex w-full  text-white hover:scale-[0.95] hover:transition-transform ${
                          isTransparent ? 'bg-transparent' : 'bg-transparent'
                        } font-medium ${index === 0 ? 'sticky left-0 z-10' : ''}`}
                        style={{ left: index === 0 ? 0 : undefined }}
                        key={cell.column.id}
                        data-tooltip-id={index}
                        data-tooltip-content={cell?.value}
                      >
                        {customRenderer ? customRenderer(cell.value) : cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
     {(isPagination && pageOptions.length>0) &&<div className="pagination mt-4  py-2 w-[100%] text-sm flex justify-end items-center">
        <button className='mx-4 text-gray-700 cursor-pointer' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <span>
          Page{' '}
       
            {pageIndex + 1} of {pageOptions.length}

        </span>
        <button className='mx-4 text-gray-700 cursor-pointer' onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <select
          value={pageSize}
          className='text-sm h-[35px] p-0 px-2 rounded-md bg-gray-100 w-[100px] flex justify-between items-center'
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
       {[10,20,50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
             Show {pageSize}
            </option>
          ))}
        </select>
      </div>}
      {(customPagination && totalPages>0) && <div>
        <div className="flex justify-center items-center space-x-4 mt-6">
      <button
        onClick={handlePrevious}
        className={`px-4 py-2 text-white rounded ${
          pageNumber === 1
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-700'
        }`}
        disabled={pageNumber === 1}
      >
        Previous
      </button>

      <span className="text-lg text-white dark:text-black font-medium">
        Page {pageNumber} of {totalPages}
      </span>

      <button
        onClick={handleNext}
        className={`px-4 py-2 text-white rounded ${
          pageNumber === totalPages
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-700'
        }`}
        disabled={pageNumber === totalPages}
      >
        Next
      </button>
    </div>
        </div>}
    </div>
  );
};

export default DynamicTable;
