import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/icons/Component 15.svg";
import rightArrow from "../../assets/icons/Component 14.svg";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import formatCurrency from "../../utils/formatCurrency";
import { format } from "date-fns";

const ParityReportTable = ({ data, room, defaultSortColumn, reservation, arrival, night, isExpanded, onExpand }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Set the desired number of rows per page

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const getHeaders = (data) => {
        if (data.length > 0) {
            const headers = Object.keys(data[0]);
            // Remove the first header
            headers.shift();
            return headers;
        }
        return [];
    };
    const headers = getHeaders(data);

    let currentRows = [];

    if (Array.isArray(data)) {
        currentRows = data;
    } else {
    }

    useEffect(() => {
        if (defaultSortColumn) {
            setSortColumn(defaultSortColumn);
        }
    }, [defaultSortColumn]);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    const sortedData = currentRows.slice().sort((a, b) => {
        if (sortColumn) {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];
            if (valueA < valueB) return sortDirection === "asc" ? 1 : -1;
            if (valueA > valueB) return sortDirection === "asc" ? -1 : 1;
        }
        return 0;
    });

    const currentData = sortedData.slice(indexOfFirstRow, indexOfLastRow);


    return (
        <>
            <div className={`w-auto flex flex-col text-white dark:bg-lightGradient bg-newGradient border rounded-lg cursor-pointer`}>
                <div onClick={onExpand} className="w-[100%] flex p-2 justify-center items-center">
                    <div className="w-[50%] flex flex-col justify-around">
                        <div className="pl-4 text-white ">
                            <p className=" text-[16px]">
                                Parity Report Table
                            </p>
                            <p className=" text-sm">
                                {data?.length} results found
                            </p>
                        </div>
                    </div>
                    <div className="w-[50%] flex justify-end pr-6">
                        <img src={isExpanded ? arrowUp : arrowDown} alt="" />
                    </div>
                </div>
                {isExpanded && (
                    <div className={` overflow-x-scroll w-full scrollHide`}>
                        <div className="table-container">
                            <div className="table w-full">
                                <div className="table-header ">
                                    <div className="border-b-4 p-2 pl-0 flex justify-center items-center">
                                        <div className="flex">
                                            {headers && headers.map((header, index) => (
                                                <div
                                                    key={index}
                                                    className={`${arrival ? "w-[120px]" : "w-[20%]"} text-center text-base font-medium ${index === 0 || index === 1 ? 'sticky left-0' : ''}`}
                                                    style={{ left: index === 1 ? '100px' : '0' }}
                                                >
                                                    <p className="w-[100%]">{header}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="max-h-[380px] overflow-auto w-full">
                                    {currentData && currentData.map((item, index) => (
                                        <DataRow
                                            key={index}
                                            item={item}
                                            index={indexOfFirstRow + index}
                                            selectedRows={selectedRows}
                                            room={room}
                                            arrival={arrival}
                                            night={night}
                                            reservation={reservation}
                                            headers={headers}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isExpanded && (
                <div className="flex justify-center mt-2 bg-gray-50 rounded-lg">
                    <button
                        className="px-2 py-1 rounded-l"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <img src={leftArrow} alt="Prev" />
                    </button>
                    <span className="px-2 py-1">{currentPage}/{Math.ceil(data.length / rowsPerPage)}</span>
                    <button
                        className="px-2 py-1 rounded-r"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={indexOfLastRow >= data.length}
                    >
                        <img src={rightArrow} alt="Next" />
                    </button>
                </div>
            )}
        </>
    );
};

export default ParityReportTable;

const DataRow = ({
    item,
    index,
    selectedRows,
    room,
    arrival,
    night,
    reservation,
    headers
}) => {
    Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
    };
    const color = [
        "#1abc9c",
        "#f1c40f",
        "#2ecc71",
        "#2980b9",
        "#fad390",
        "#f8c291",
        "#60a3bc",
        "#4a69bd",
    ];

    const isSelected = selectedRows.includes(index);

    if (isSelected) {
    }

    const isDate = (value) => {
        const dateObj = new Date(value);
        return !isNaN(dateObj.getTime());
    };

    const renderCell = (header) => {
        const value = item[header];

        if (arrival && typeof value === 'number' && header !== 'averageRate') {
            return <p className="text-sm text-center w-full">{formatCurrency(value)}</p>;
        }

        if (header === 'averageRate') {
            return <p className="text-sm text-center w-full">{formatCurrency(value)}</p>;
        }

        if (typeof value === 'string' && isDate(value)) {
            return <p className="text-sm text-center w-full">{format(new Date(value), "dd MMM yyyy")}</p>;
        }
        return <p className="text-sm text-center w-full">{value}</p>;
    };

    return (
        <div className={`flex flex-col py-2`}>
            <div className={`w-[100%] flex p-2 pl-0 gap-2 justify-center items-center cursor-pointer`}>
                <div className="">
                    <div className="flex">
                        {headers.map((header, idx) => (
                            <div
                                key={idx}
                                className={`${arrival ? "w-[120px]" : "w-[20%]"} text-center text-base font-medium ${idx === 0 || idx === 1 ? 'sticky left-0 ' : ''}`}
                                style={{ left: idx === 1 ? '100px' : '0' }}
                            >
                                {renderCell(header)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
