import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('token')

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
     return state = action.payload;
    },
    setClearToken: (state, action) => {
     return state = null;
    },
  },
});

export const { setToken ,setClearToken} = tokenSlice.actions;

export default tokenSlice.reducer;
