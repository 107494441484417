import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { setLocation } from "../../store/map/latLong";
import { useDispatch } from "react-redux";

function findPostalCode(addressComponents) {
  for (const component of addressComponents) {
    if (component.types.includes('postal_code')) {
      return component.long_name;
    }
  }
  return null;
}

function MyComponent({
  setAddress,
  address,
  name,
  rules,
  icon,
  className,
  onBlur,
  rest,
  control,
  field,
  setValue,
  placeholder,
  id,
  label,
  setLog,
  setLat,
  type,
  setCity,
  setState,
  state,
  setCountry,
  country
}) {
  const [postalCode, setPostalCode] = useState("");
  const dispatch = useDispatch()

  function removeLastThreeWords(inputString) {
    const words = inputString.split(','); // Split the input string by commas
    const resultString = words.slice(0, -3).join(','); // Remove the last three words and join them back with commas
    return resultString;
  }

  const handleSelect = async (selected) => {
    setAddress(selected);
    const results = await geocodeByAddress(selected);
    const latLng = await getLatLng(results[0]);
    const result = results[0];
    const addressComponents = result.address_components;
    const postalCodeComponents = result.address_components;

    dispatch(setLocation({ latitude: latLng.lat, longitude: latLng.lng }));
    setLat(latLng.lat)
    setLog(latLng.lng)
    // Extract country, city, and state from the address components
    let extractedCity, extractedState, extractedCountry, extractedPostalCode;
    for (const component of addressComponents) {
      if (component.types.includes("locality")) {
        extractedCity = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        extractedState = component.long_name;
      } else if (component.types.includes("country")) {
        extractedCountry = component.long_name;
      } else if (postalCodeComponents) {
        setPostalCode(findPostalCode(postalCodeComponents))
      }
    }
    setCity(extractedCity);
    // setState(extractedState || "");
    // setCountry(extractedCountry || "");
    setPostalCode(extractedPostalCode || "");

    setValue(name, removeLastThreeWords(selected))

    if (selected && selected.split(',').length >= 4 || selected.split(',').length >= 3) {
      const filteredAddress = selected.split(',').slice(-3)
      setValue('state', filteredAddress[1]);
      setValue('country', filteredAddress[2]);
      setValue('city', filteredAddress[0]);
      setValue('pinCode', postalCode)
    }

    // setValue(name, name === "city" ? extractedCity : selected);
  };
  const apiKey = process.env.apiKey
  // AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0
  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      {...field}
      {...rest}
      options={{
        // Here is where you add your API key
        apiKey: apiKey, // add key in env file
        // Other optional options... 
      }}

    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <div className="relative">
          <input
            {...getInputProps({
              // placeholder: {placeholder},
            })}
            name={name}
            className={className}
            onBlur={onBlur}
            rules={rules}
            id={id}
            control={control}
            placeholder={placeholder}
          />
          <label
            htmlFor={id}
            className={`absolute text-sm ${icon ? "px-14" : "px-2"
              } text-black  peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start dark:text-gray-400 
        duration-300 transform -translate-y-4 scale-75 top-3 origin-[0] bg-white dark:bg-white  peer-focus:px-1  
        peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 
        peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-[:not(:placeholder-shown)]:w-fit 
        peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto`}
          >
            {label}
          </label>
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              // Filter suggestions to only show cities
              if (suggestion.types) {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style: {maxWidth: "324px"},
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              }
              return null; // Return null for non-city suggestions
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default MyComponent;
