import React, { useState } from 'react';

const DynamicCounter = ({ initialValue , count, setCount ,isChecked = true}) => {
    // const [count, setCount] = useState(initialValue || 1);

    const decrement = () => {
        setCount(count > 1 ? count - 1 : 1);
    };

    const increment = () => {
        setCount(count + 1);
    };
    const handleInputChange = (event) => {
        const newValue = event.target.value.trim(); // Remove leading and trailing whitespaces
        if (newValue === "") {
            // If the input is empty, set count to 0
            setCount(0);
        } else {
            // Parse the input value
            let parsedValue = parseInt(newValue);
            if (!isNaN(parsedValue)) {
                // Remove leading zeros
                parsedValue = parseInt(newValue);
                // Ensure count doesn't go below 0
                setCount(Math.max(parsedValue, 0));
            }
        }
    };
    
    return (
        <div className="bg-[#f6f8fb] rounded-lg w-[100%] h-[36px] gap-1 flex items-center justify-between p-2">
            <div
                className="px-2 border bg-[#eef1f6] rounded-lg cursor-pointer font-semibold"
                
                onClick={isChecked ? decrement : null}
                style={{
                    opacity: isChecked ? 1 : 0.5,
                    cursor: isChecked ? "pointer" : "not-allowed",
                }}
            >
                -
            </div>
            <input className=" bg-[#F6F8FB] border rounded-md flex items-center justify-center  outline-none px-2 text-center text-[14px]"
            onChange={isChecked ? handleInputChange : null}
            readOnly={!isChecked} // Make input readOnly when isChecked is false
            style={{
                cursor: isChecked ? "auto" : "not-allowed",
            }} value={count || 0}/>
            <div
                className="px-2 border bg-[#eef1f6] rounded-lg  cursor-pointer font-semibold"
                onClick={isChecked ? increment : null}
                style={{
                    opacity: isChecked ? 1 : 0.5,
                    cursor: isChecked ? "pointer" : "not-allowed",
                }}
                
            >
                +
            </div>
        </div>
    );
};

export default DynamicCounter;
