import React, { useEffect, useState } from "react";
import ChannelCard from "../channelcard";
import SettingButton from "../../../components/settingButton/SettingButton";
import {
  useEditUserInterfaceByIdMutation,
  useGetUserInterfaceByIdQuery,
  useGetUserInterfaceCurrenciesQuery,
  useGetUserInterfaceQuery,
} from "../../../redux/slices/settings/userInterface";
import { toast } from "react-toastify";
import CustomSkeleton from "../../../components/Skeleton";
import { UserId } from "../../../utils/impVars";
import BackdropLoader from "../../../components/newLoader";

const filterGetName = (array, arrayName, itemName, id) => {
  const filterValue =
    array &&
    array[arrayName]?.filter((item) => {
      return item[itemName]?.includes(id);
    });
  return filterValue;
};

const UserInterface = () => {
  const { data: userInterface, refetch } = useGetUserInterfaceQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const { data: userInterfaceDetails,isLoading:getUserInterfaceIsLoading,isFetching } = useGetUserInterfaceByIdQuery(
    {
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {data:currencies} = useGetUserInterfaceCurrenciesQuery({})
  const [editUserInterfaceById] = useEditUserInterfaceByIdMutation();
  const [selectedCard, setSelectedCard] = useState(null);
  const [theme, setTheme] = useState(null);
  const [lang, setLang] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [timeFormat, setTimeFormat] = useState(null);
  const[isLoading,setIsLoading] = useState(false)

  useEffect(()=>{
    if(getUserInterfaceIsLoading || isFetching){
      setIsLoading(true)
    }else{
      setIsLoading(false)
    }
  },[getUserInterfaceIsLoading,isFetching])

  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  const handleBackButtonClick = () => {
    setSelectedCard(null);
  };

  const renderComponent = () => {
    switch (selectedCard) {
      case "Interface Theme":
      // return <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />;
      case "Language":
      // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
      case "Currency":
      // return <RoomType handleBackButtonClick={handleBackButtonClick} />;
      case "Date Format":
      // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
      case "Time Format":
      // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (theme || lang || currency || timeFormat || dateFormat) {
      setIsLoading(true)
      editUserInterfaceById({
        userInterfaceId: userInterfaceDetails?.data?.userInterfaceId,
        useInterfaceBody: {
          Interface: !theme?.value
            ? userInterfaceDetails?.data?.Interface
            : theme?.value,
          language: !lang?.value
            ? userInterfaceDetails?.data?.language
            : lang?.value,
          currency: !currency?.value
            ? userInterfaceDetails?.data?.currency
            : currency?.value,
          dateFormat: !dateFormat?.value
            ? userInterfaceDetails?.data?.dateFormat
            : dateFormat?.value,
          timeFormat: !timeFormat?.value
            ? userInterfaceDetails?.data?.timeFormat
            : timeFormat?.value,
        },
      })
        .unwrap()
        .then((res) => {
          refetch();
          setIsLoading(false)
          toast.success("Updated Successfully!", { position: "bottom-right" });
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error(err?.message, { position: "bottom-right" });
        });
    }
  }, [theme, lang, currency, dateFormat, timeFormat]);

  const interfaceThemeOption =
    userInterface &&
    userInterface?.InterfaceTheme?.map((item) => {
      return {
        label: item?.interfaceName,
        value: item.interfaceId,
      };
    });
  const interfaceLanguageOption =
    userInterface &&
    userInterface?.Interfacelanguage?.map((item) => {
      return {
        label: item?.languageName,
        value: item?.languageId,
      };
    });
  const interfaceCurrencyOption =
  currencies &&
  currencies?.data?.map((item) => {
      return {
        label: item?.currencyName,
        value: item?.currencyId,
      };
    });
  const interfaceDateFormatOption =
    userInterface &&
    userInterface?.DateFormatInterface?.map((item) => {
      return {
        label: item?.dateType,
        value: item?.dateId,
      };
    });
  const interfaceTimeFormatOption =
    userInterface &&
    userInterface?.TimeFormatInterface?.map((item) => {
      return {
        label: item?.timeType,
        value: item?.timeId,
      };
    });

  return (
    <>
    <BackdropLoader loading={isLoading} />
      {selectedCard  ? (
        <>{renderComponent()}</>
      ) : (
        userInterface && !isLoading && !isFetching ? (
          <div className="flex flex-col gap-4 ">
            <SettingButton
              Title={"User Interface"}
              table={true}
              hidebackbutton={true}
            />
            <div className="flex flex-col gap-4 ">
              <div className="flex flex-col gap-1 ">
                <ChannelCard
                  title={"Interface Theme"}
                  dropdown={true}
                  selectedItem={
                    !theme?.value
                      ? filterGetName(
                          userInterface,
                          "InterfaceTheme",
                          "interfaceId",
                          userInterfaceDetails?.data?.Interface
                        )[0]?.interfaceName
                      : theme?.label
                  }
                  onClick={() => ""}
                  setSelectedItem={setTheme}
                  options={interfaceThemeOption}
                />
                <ChannelCard
                  title={"Language"}
                  dropdown={true}
                  selectedItem={
                    !lang?.value
                      ? filterGetName(
                          userInterface,
                          "Interfacelanguage",
                          "languageId",
                          userInterfaceDetails?.data?.language
                        )[0]?.languageName
                      : lang?.label
                  }
                  onClick={() => ""}
                  setSelectedItem={setLang}
                  options={interfaceLanguageOption}
                />
                <ChannelCard
                  title={"Currency"}
                  dropdown={true}
                  selectedItem={
                    !currency?.value
                      ? filterGetName(
                          userInterface,
                          "InterfaceCurrency",
                          "currencyId",
                          userInterfaceDetails?.data?.currency
                        )[0]?.currencyName
                      : currency?.label
                  }
                  onClick={() => ""}
                  setSelectedItem={setCurrency}
                  options={interfaceCurrencyOption}
                />
                <ChannelCard
                  title={"Date Format"}
                  dropdown={true}
                  selectedItem={
                    !dateFormat?.value
                      ? filterGetName(
                          userInterface,
                          "DateFormatInterface",
                          "dateId",
                          userInterfaceDetails?.data?.dateFormat
                        )[0]?.dateType
                      : dateFormat?.label
                  }
                  options={interfaceDateFormatOption}
                  setSelectedItem={setDateFormat}
                />
                <ChannelCard
                  title={"Time Format"}
                  dropdown={true}
                  selectedItem={
                    !timeFormat?.value
                      ? filterGetName(
                          userInterface,
                          "TimeFormatInterface",
                          "timeId",
                          userInterfaceDetails?.data?.timeFormat
                        )[0]?.timeType
                      : timeFormat?.label
                  }
                  options={interfaceTimeFormatOption}
                  setSelectedItem={setTimeFormat}
                />
              </div>
            </div>
            {/* {renderComponent()} */}
          </div>
        ):<CustomSkeleton width={"100%"} height={400} />
      )}
    </>
  );
};

export default UserInterface;
