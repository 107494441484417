import React, { useState } from "react";
import GoogleMapsInput from "../../components/googleMapInput/index";
import { Helmet } from "react-helmet";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import NewTooltip from "../tooltip/NewTooltip";

const InputField = ({
  type,
  label,
  value,
  onChange,
  placeholder,
  className,
  options,
  isMulti,
  id,
  icon,
  startIcon,
  name,
  rules,
  defaultValue,
  address,
  setAddress,
  setLog,
  setLat,
  setValue,
  setCity,
  setLocState,
  setCountry,
  setting,
  setState,
  state,
  country,
  ...rest

}) => {
  const focus = "";
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    formState: { errors: error },
  } = useFormContext();
  const [errors, setErrors] = React.useState(null);

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.1/datepicker.min.js"></script>
      </Helmet>
      <div className="relative">
        {startIcon && (
          <span className={`absolute z-1 left-2 transform -translate-y-1/2 ${type === "Address" ? "top-6" : "top-1/2"}`}>
            <img loading="lazy" src={startIcon} alt="Start Icon" className="h-6 w-6" />
          </span>
        )}
        <div className={`${startIcon ? "pl-10" : ""}`}>
          {type === "datepicker" ? (
            <></>
          ) : type === "select" ? (
            <div className="relative">
              {" "}
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => {
                  setErrors(fieldState.error && fieldState.error.message);
                  return (
                    <>
                      <Select
                        isMulti={isMulti}
                        menuPortalTarget={document.body}
                        placeholder={placeholder}
                        menuPosition={"fixed"}
                        className={` ${className} w-auto h-12 ${icon ? "px-14" : "px-0"
                          }  peer ${errors
                            ? "focus-visible:outline-none border-b-red-500 border-b"
                            : "focus-visible:border-primary-blue"
                          } border-[#E6ECF5] border-b  focus-visible:outline-none [&>*:nth-child(even)]: relative`}
                        options={options}
                        name={name}
                        {...field}
                        {...rest}
                        onBlur={field.onBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            //   borderColor: state.isFocused ? 'grey' : 'red',
                            border: "2px",
                            height: "100%",
                            outline: state.isFocused ? 'none' : baseStyles.outline,
                            boxShadow: state.isFocused ? 'none' : baseStyles.boxShadow,
                            borderBottom: "1px"
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        id={id}
                      />{" "}
                    </>
                  );
                }}
              />
              <label
                htmlFor={id}
                className={`absolute text-sm ${icon ? "px-14" : "px-2"
                  }text-gray-600  peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start dark:text-gray-400 
              duration-300 transform -translate-y-4 scale-75 top-3.5 origin-[0] bg-white dark:bg-white peer-focus:bg-transparent  peer-focus:px-1  
              peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
              peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 
              peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-[:not(:placeholder-shown)]:w-fit 
              peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto`}
              >
                {label}
              </label>
            </div>
          ) : type === "password" ? (
            <div className="relative">
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => {
                  setErrors(fieldState.error && fieldState.error.message);
                  return (
                    <>
                      <input
                        className={` ${className} w-auto h-12 text-black  ${icon ? "px-14" : "px-2"
                          } overflow-auto peer ${errors
                            ? "focus-visible:outline-none border-b-red-500 border-b"
                            : "focus-visible:border-blue-700"
                          } border-b-[#E6ECF5] border-b  focus-visible:outline-none`}
                        type={showPassword ? "text" : "password"}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        id={id}
                        name={name}
                        onBlur={field.onBlur}
                        {...field}
                        {...rest}
                      />
                    </>
                  );
                }}
              />
              {errors && (
                <NewTooltip
                  title={errors}
                  color="#1c274c">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 512 512"
                    version="1.1"
                    fill="#ff0000"
                    stroke="#ff0000"
                    className="absolute right-2 top-0 -mt-4 "
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>warning</title>{" "}
                      <g
                        id="Page-1"
                        strokeWidth="0.00512"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="add"
                          fill="#ff0000"
                          transform="translate(32.000000, 42.666667)"
                        >
                          {" "}
                          <path
                            d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M225.144334,42.6739678 L42.6666667,362.009885 L407.622001,362.009885 L225.144334,42.6739678 Z M224,272 C239.238095,272 250.666667,283.264 250.666667,298.624 C250.666667,313.984 239.238095,325.248 224,325.248 C208.415584,325.248 197.333333,313.984 197.333333,298.282667 C197.333333,283.264 208.761905,272 224,272 Z M245.333333,106.666667 L245.333333,234.666667 L202.666667,234.666667 L202.666667,106.666667 L245.333333,106.666667 Z"
                            id="Combined-Shape"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </NewTooltip>
              )}

              <img loading="lazy" src={icon} className="absolute top-3 left-4 z-50" />
              <img
                src={
                  showPassword
                    ? require("../../assets/icons/onboarding/Property 1=Open.png")
                    : require("../../assets/icons/onboarding/Input_icons.png")
                }
                loading="lazy"
                onClick={() => setShowPassword((prev)=>!prev)}
       
                className={`absolute ${setting ? "top-2" : "top-3"} right-4 z-1  ${errors ? "right-8" : ""}`}
              />

              {label && <label
                htmlFor={id}
                className={`absolute text-sm ${icon ? "px-14" : "px-2"
                  } ${errors ? "w-8/12 peer-focus:dark:text-red-700 " : "w-10/12"
                  } text-black peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start dark:text-gray-400 
              duration-300 transform -translate-y-4 scale-75 top-3 origin-[0] bg-white dark:bg-white peer-focus:bg-transparent  peer-focus:px-1  
              peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
              peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 
              peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-[:not(:placeholder-shown)]:w-fit 
              peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto`}
              >
                {label}
              </label>}
            </div>
          ) : type === "textarea" ? (
            <div className="relative">
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => {
                  setErrors(fieldState.error && fieldState.error.message);
                  return (
                    <>
                      <textarea
                        className={` ${className} w-auto  ${icon ? "px-14" : "px-2"
                          } rounded-md  peer ${errors
                            ? "focus-visible:outline-none border-red-500 border"
                            : "focus-visible:outline-primary-blue"
                          } focus-visible:outline mt-2`}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        id={id}
                        defaultValue={defaultValue}
                        name={name}
                        {...field}
                        {...rest}
                        onBlur={field.onBlur}
                        rows="7"
                        cols="50"

                      // onKeyDown={field.onBlur}
                      />
                    </>
                  );
                }}
              />
              {errors && (
                <NewTooltip
                  title={errors}
                  color="#1c274c">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 512 512"
                    version="1.1"
                    fill="#ff0000"
                    stroke="#ff0000"
                    className="absolute right-2 top-0 -mt-4"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>warning</title>{" "}
                      <g
                        id="Page-1"
                        strokeWidth="0.00512"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="add"
                          fill="#ff0000"
                          transform="translate(32.000000, 42.666667)"
                        >
                          {" "}
                          <path
                            d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M225.144334,42.6739678 L42.6666667,362.009885 L407.622001,362.009885 L225.144334,42.6739678 Z M224,272 C239.238095,272 250.666667,283.264 250.666667,298.624 C250.666667,313.984 239.238095,325.248 224,325.248 C208.415584,325.248 197.333333,313.984 197.333333,298.282667 C197.333333,283.264 208.761905,272 224,272 Z M245.333333,106.666667 L245.333333,234.666667 L202.666667,234.666667 L202.666667,106.666667 L245.333333,106.666667 Z"
                            id="Combined-Shape"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </NewTooltip>
              )}

              <img loading="lazy" src={icon} className="absolute top-3 left-4 z-50" />

              <label
                htmlFor={id}
                className={`absolute text-sm ${icon ? "px-14" : "px-2"
                  } text-gray-500  peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start dark:text-gray-400 
        duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-white  peer-focus:px-1  
        peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
        peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-[:not(:placeholder-shown)]:w-fit 
        peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto`}
              >
                {label}
              </label>
            </div>
          ) : type === "Address" ? (
            <div className="relative max-h-28 scroll-container">
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => {
                  setErrors(fieldState.error && fieldState.error.message);
                  return (
                    <>
                      <GoogleMapsInput
                        address={address}
                        name={name}
                        icon={icon}
                        control={control}
                        rules={rules}
                        placeholder={placeholder}l
                        id={id}
                        setValue={setValue}
                        field={field}lkl
                        rest={rest}
                        type={type}
                        setLat={setLat}
                        setCity={setCity}
                       
                        setLog={setLog}
                        label={label}
                        setState={setState}
                        state={state}
                        setCountry={setCountry}
                        country={country}
                        className={` ${className} w-auto h-12  ${icon ? "px-14" : "px-2"
                          }   peer ${errors
                            ? "focus-visible:border-b-red-500  border-b"
                            : "focus-visible:border-blue-700"
                          } border-b-[#E6ECF5] border-b  focus-visible:outline-none`}
                        setAddress={setAddress}
                        onBlur={field.onBlur}

                      />
                    </>
                  );
                }}
              />
              {errors && (
                <NewTooltip
                  title={errors}
                  color="#1c274c">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 512 512"
                    version="1.1"
                    fill="#ff0000"
                    stroke="#ff0000"
                    className="absolute right-2 top-0 -mt-4"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>warning</title>{" "}
                      <g
                        id="Page-1"
                        strokeWidth="0.00512"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="add"
                          fill="#ff0000"
                          transform="translate(32.000000, 42.666667)"
                        >
                          {" "}
                          <path
                            d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M225.144334,42.6739678 L42.6666667,362.009885 L407.622001,362.009885 L225.144334,42.6739678 Z M224,272 C239.238095,272 250.666667,283.264 250.666667,298.624 C250.666667,313.984 239.238095,325.248 224,325.248 C208.415584,325.248 197.333333,313.984 197.333333,298.282667 C197.333333,283.264 208.761905,272 224,272 Z M245.333333,106.666667 L245.333333,234.666667 L202.666667,234.666667 L202.666667,106.666667 L245.333333,106.666667 Z"
                            id="Combined-Shape"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </NewTooltip>
              )}

              <img  loading="lazy" src={icon} className="absolute top-3 left-4 z-50" />

            </div>
          ) : (
            <div className="relative">
              <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => {
                  setErrors(fieldState.error && fieldState.error.message);
                  return (
                    <>
                      <input
                        className={` ${className}text-black  h-12 ${icon ? "px-14" : "px-2"
                          }   peer ${errors 
                            ? "focus-visible:border-b-red-500  border-b  border-b-red-500"
                            : "focus-visible:border-blue-700"
                            
                          } border-b-[#E6ECF5] border-b  focus-visible:outline-none`}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        id={id}
                        name={name}
                        {...field}  
                        {...rest}
                        onBlur={field.onBlur}
                      // onKeyDown={field.onBlur}
                      />
                    </>
                  );
                }}
              />
              {errors && (
                <NewTooltip
                  title={errors}
                  color="#1c274c">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 512 512"
                    version="1.1"
                    fill="#ff0000"
                    stroke="#ff0000"
                    className="absolute right-2 top-0 -mt-4"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>warning</title>{" "}
                      <g
                        id="Page-1"
                        strokeWidth="0.00512"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="add"
                          fill="#ff0000"
                          transform="translate(32.000000, 42.666667)"
                        >
                          {" "}
                          <path
                            d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M225.144334,42.6739678 L42.6666667,362.009885 L407.622001,362.009885 L225.144334,42.6739678 Z M224,272 C239.238095,272 250.666667,283.264 250.666667,298.624 C250.666667,313.984 239.238095,325.248 224,325.248 C208.415584,325.248 197.333333,313.984 197.333333,298.282667 C197.333333,283.264 208.761905,272 224,272 Z M245.333333,106.666667 L245.333333,234.666667 L202.666667,234.666667 L202.666667,106.666667 L245.333333,106.666667 Z"
                            id="Combined-Shape"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </NewTooltip>
              )}

              <img loading="lazy" src={icon} className="absolute top-3 left-4 z-50" />

              {label && <label
                htmlFor={id}
                className={`absolute text-sm  text-black ${icon ? "px-14 peer-focus:px-2" : "pl-2 pr-2 "
                  } text-black  peer-focus:w-fit ${type === "email" && "w-10/12 pr-0 text-black"
                  }  ${errors ? "w-8/12 peer-focus:dark:text-red-700   " : "w-10/12"
                  }   peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start 
            duration-300 transform -translate-y-4 scale-75 top-3.5 origin-[0] bg-white dark:bg-white peer-focus:bg-transparent  peer-focus:px-1  
            peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
            peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3.5 
            peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-[:not(:placeholder-shown)]:w-fit 
            peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto`}
              >
                {label}
              </label>}
            </div>
          )}
          {/* {<p className="text-red-500 text-xs mt-1 text-start">{errors}</p>} */}
        </div>
      </div>
    </>
  );
};

export default InputField;
