import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state, action) => {
     return state = action.payload;
    },
  },
});

export const { setDashboardState } = dashboardSlice.actions;

export default dashboardSlice.reducer;
