import React from 'react'
import BackButton from "../../assets/icons/VectorSetting.svg"

const BackButtonSettings = ({ handleBackButtonClick, table }) => {
    return (
        <div>
            <div onClick={handleBackButtonClick} className={`bg-[#1D2D44] flex items-center justify-center cursor-pointer rounded-lg ${table === true ? "h-[36px] px-3" : "h-[40px] px-4"} `}>
                <img className='' src={BackButton} alt='.' />
            </div>
        </div>
    )
}

export default BackButtonSettings;
