import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import {
  AiFillCalendar,
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineClose,
  AiOutlineDownload,
  AiOutlineHolder,
  AiOutlineLineChart,
  AiOutlineTable,
} from "react-icons/ai";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { gql, useQuery } from "@apollo/client";
import { useGetReportBuilderUtilsQuery } from "../../redux/slices/utils";
import { CurrentProperty, UserId } from "../../utils/impVars";
import CustomSkeleton from "../../components/Skeleton";
import Dropdown from "../../components/dropdown";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import ExcelJS from "exceljs";
import whiteBackIcon from "../../assets/icons/whiteBackIcon.svg";
import { useNavigate } from "react-router-dom";
import GlobalModalComponent from "../../components/modal/newModal";
import editBlueIcon from "../../assets/icons/editBlueIcon.svg";
import { useIsIpad } from "../../utils/isIpad";
import { RiDragDropLine } from "react-icons/ri";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaChevronLeft, FaUnlockAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";

async function exportToExcel(response) {
  if (!response || !response[0]) {
    throw new Error("Response data is missing or invalid.");
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");

  const pivotHeaders = response[0].pivotHeaders.map((header) =>
    header.names?.map((item) => {
      return item;
    })
  );
  const colDimensions = response[0].colDimensions.fieldNames;
  const responseData = response[0].responseRows;
  const dimensions = response[0].dimensions.fieldNames;
  const metrics = response[0].metrics.map((metric) => metric.name);

  // Create headers
  const headerRow1 = [
    ...dimensions,
    ...Array(pivotHeaders.length * metrics.length).fill(""),
  ];
  const headerRow2 = [
    ...Array(dimensions.length).fill(""),
    ...pivotHeaders.flatMap((header) => Array(metrics.length).fill(header)),
  ];
  const headerRow3 = [
    ...dimensions.map(() => ""),
    ...pivotHeaders.flatMap(() => metrics),
  ];

  // Add header rows to worksheet with borders
  [headerRow1, headerRow2, headerRow3].forEach((headerRow) => {
    const row = worksheet.addRow(headerRow);
    row.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
        left: { style: "thin", color: { argb: "ffffff" } },
        bottom: { style: "thin", color: { argb: "ffffff" } },
        right: { style: "thin", color: { argb: "ffffff" } },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
  });

  // Center align headers
  worksheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };
  worksheet.getRow(2).alignment = { vertical: "middle", horizontal: "center" };
  worksheet.getRow(3).alignment = { vertical: "middle", horizontal: "center" };

  // Merge cells for headers
  worksheet.mergeCells(1, 1, 3); // Merge the dimension headers
  pivotHeaders.forEach((header, index) => {
    const startCol = dimensions.length + 1 + index * metrics.length;
    const endCol = startCol + metrics.length - 1;
    worksheet.mergeCells(2, startCol, 2, endCol);
  });

  // Add data rows
  responseData.forEach((row, rowIndex) => {
    const rowData = [];

    // Add colDimensions
    rowData.push(
      ...dimensions.map(
        (_, idx) => row.dimensionCompoundValues[idx]?.value || ""
      )
    );

    // Add pivotHeaders and metrics
    pivotHeaders.forEach((_, headerIndex) => {
      rowData.push(
        ...metrics.map(
          (_, metricIndex) =>
            row.metricCompoundValues[headerIndex * metrics.length + metricIndex]
              ?.value || ""
        )
      );
    });

    const newRow = worksheet.addRow(rowData);

    // Apply alternating row colors
    newRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: rowIndex % 2 === 0 ? "ECEFCE" : "FFFFFFFF" },
    };

    // Center align and add padding
    newRow.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    // Add borders to data rows
    newRow.eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });
  });

  // Auto-fit columns
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : "";
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength;
  });

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link and trigger download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "exported_data.xlsx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function formatArray(arr) {
  return arr.map((item) => {
    // Split camelCase and capitalize each word
    let formatted = item
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
    return formatted;
  });
}

function getAllDatesInRange(startDate, endDate) {
  // Parse the dates to ensure they are Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  // Ensure the end date is included by adding one day to the end date comparison
  const currentDate = new Date(start);

  while (currentDate <= end) {
    // Format the date as yyyy-mm-dd
    const yyyy = currentDate.getFullYear();
    const mm = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(currentDate.getDate()).padStart(2, "0");

    // Add the formatted date to the array
    dates.push(`${yyyy}-${mm}-${dd}`);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

function groupArrayByDateRange(startDate, endDate, array) {
  // Convert input dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error("Invalid date(s) provided.");
  }

  // Check if startDate is before or equal to endDate
  if (start > end) {
    throw new Error("Start date must be before or equal to end date.");
  }

  const groupedData = {};

  // Iterate through each day in the range
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateString = d.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    groupedData[dateString] = [...array]; // Assign the array to each date
  }

  return [groupedData];
}

function formatString(str) {
  // Split camelCase and capitalize each word
  return str
    ?.replace(/([a-z])([A-Z])/g, "$1 $2")
    ?.replace(/^./, (char) => char.toUpperCase());
}

function toCamelCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}

function formatObjects(inputArray) {
  if (!Array.isArray(inputArray)) {
    console.error("Input is not an array.");
    return [];
  }

  let formattedArray = [];
  inputArray.forEach((obj) => {
    if (typeof obj !== "object" || obj === null) {
      console.error("Invalid object in input array:", obj);
      return;
    }

    let formattedObj = {};
    Object.keys(obj).forEach((key) => {
      if (typeof key !== "string" || key.trim() === "") {
        console.error("Invalid key in object:", key);
        return;
      }
      formattedObj[key] = obj[key];
    });
    formattedArray.push(formattedObj);
  });
  return formattedArray;
}

function removeDuplicatesUsingSet(arr) {
  return [...new Set(arr)];
}

const NewReportBuilder = () => {
  const isTablet = useIsIpad();
  const navigate = useNavigate();
  const { data: reportBuilderUtils } = useGetReportBuilderUtilsQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const ref1 = useRef();
  const [selectedDimensions, setSelectedDimensions] = useState();
  const [selectedMetrics, setSelectedMetrics] = useState();
  const [selectedType, setSelectedType] = useState("TABLE");
  const [timeRange, setTimeRange] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    label: "Day",
    value: "day",
  });
  const [dateRange, setDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), id: "range 1" },
  ]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [columnAItems, setColumnAItems] = useState([]);
  const [col, setCol] = useState([]);
  const [selectedCol, setSelectedCol] = useState([]);
  const [columnToMetrics, setColumnAToMetrics] = useState([]);
  const [columnToCompareMetrics, setColumnAToCompareMetrics] = useState([]);
  const [allowedColumn, setAllowedColumn] = useState(["B"]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportName, setReportName] = React.useState("Untitled Report");
  const [reportDescription, setReportDescription] = React.useState("");
  const [saveModal, setSaveModal] = React.useState(false);
  const [isRowDrop, setIsRowDrop] = useState(false);
  const [isColumnDrop, setIsColumnrop] = useState(false);
  const [isMetricsDrop, setIsMetricsDrop] = useState(false);
  const [lockEdit,setLockEdit] = useState(false)

  const handleDropToColumnB = (item) => {
    setIsRowDrop(false);
    setIsColumnrop(false);
    const isRow = row.some((itm) => {
      return itm?.id === item?.id;
    });
    if (isRow) {
      toast?.error("Sorry you cannot drop same data in same box !", {
        position: "bottom-right",
      });
    } else {
      setColumnAItems((prevItems) => prevItems.filter((i) => i.id !== item.id));
      setRow((prevItems) => [...prevItems, item]);
    }
  };

  const handleMoveToColumnA = (item) => {
    setRow((prevItems) => prevItems.filter((i) => i.id !== item.id));
    setColumnAItems((prevItems) => [...prevItems, item]);
  };

  const handleDropToColumnBCompare = (item) => {
    setIsColumnrop(false);
    setIsRowDrop(false);
    if (selectedTimeRange?.value === "entireRange") {
      const isCol = col?.some((itm) => {
        return itm?.id === item?.id;
      });
      if (isCol) {
        toast?.error("Sorry you cannot drop same data in same box !", {
          position: "bottom-right",
        });
      } else {
        setColumnAItems((prevItems) =>
          prevItems.filter((i) => i.id !== item.id)
        );
        setCol((prevItems) => [...prevItems, item]);
      }
    } else {
      toast.error("Select entire range first", {
        position: "bottom-right",
      });
    }
  };

  const handleMoveToColumnACompare = (item) => {
    setCol((prevItems) => prevItems.filter((i) => i.id !== item.id));
    setColumnAItems((prevItems) => [...prevItems, item]);
  };
  // Metrics
  const handleDropToColumnMetricsCompare = (item) => {
    setIsMetricsDrop(false);
    const isData = columnToCompareMetrics?.some((itm) => {
      return itm?.id === item?.id;
    });
    if (isData) {
      toast?.error("Sorry you cannot drop same data in same box !", {
        position: "bottom-right",
      });
    } else {
      setColumnAToMetrics((prevItems) =>
        prevItems.filter((i) => i.id !== item.id)
      );
      setColumnAToCompareMetrics((prevItems) => [...prevItems, item]);
    }
  };

  const handleMoveToColumnMetricCompare = (item) => {
    setColumnAToCompareMetrics((prevItems) =>
      prevItems.filter((i) => i.id !== item.id)
    );
    setColumnAToMetrics((prevItems) => [...prevItems, item]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref1?.current && !ref1?.current.contains(event.target)) {
        setShowDateRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1]);
  const startDate =
    dateRange && dateRange[0]?.startDate
      ? format(new Date(dateRange[0].startDate), "yyyy-MM-dd")
      : null;
  const endDate =
    dateRange && dateRange[0]?.endDate
      ? format(new Date(dateRange[0].endDate), "yyyy-MM-dd")
      : null;
  useEffect(() => {
    if (row) {
      const newData = row?.map((item) => {
        return item?.id;
      });
      const newDimension = row?.map((item) => {
        return { name: item?.id, isSecondary: false };
      });
      setSelectedDimensions(newDimension);
      setSelectedRow(newData);
    }
  }, [row]);
  useEffect(() => {
    if (col) {
      const newData = col?.map((item) => {
        return item?.id;
      });
      // setSelectedDimensions(prev=>[...prev,newDimension])
      setSelectedCol(newData);
    }
  }, [col]);
  useEffect(() => {
    if (row && col) {
      const newRow = row?.map((item) => {
        return { name: item?.id, isSecondary: false };
      });
      const newCol = col?.map((item) => {
        return { name: item?.id, isSecondary: false };
      });
      const mergedDimensions = [...newRow, ...newCol];
      setSelectedDimensions(mergedDimensions);
    }
  }, [col, row]);
  useEffect(() => {
    if (columnToCompareMetrics) {
      const newData = columnToCompareMetrics?.map((item) => {
        return {
          name: item?.id,
          isSecondary: false,
          isInvisible: false,
        };
      });
      setSelectedMetrics(newData);
    }
  }, [columnToCompareMetrics]);
  function customStringifyForGraphQL(data) {
    if (Array.isArray(data)) {
      let result = "[";
      for (let i = 0; i < data.length; i++) {
        result += customStringifyForGraphQL(data[i]);
        if (i < data.length - 1) {
          result += ",";
        }
      }
      result += "]";
      return result;
    } else if (typeof data === "object" && data !== null) {
      let result = "{";
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        result += `${keys[i]}:${customStringifyForGraphQL(data[keys[i]])}`;
        if (i < keys.length - 1) {
          result += ",";
        }
      }
      result += "}";
      return result;
    } else if (typeof data === "string") {
      return `"${data}"`;
    } else {
      return String(data);
    }
  }
  const [response, setResponse] = useState(null);

  const GET_ITEMS = gql`
  query {
    getReportData(
      entity: 754679
      dimensions: ${customStringifyForGraphQL(formatObjects(selectedDimensions))}
      dimensionFilters: []
      metrics: ${customStringifyForGraphQL(formatObjects(selectedMetrics))}
      dateRanges: [{startDate: "${startDate?.toString()}", endDate: "${endDate?.toString()}"}]
      rowAxis: {
        fieldNames: ${selectedRow ? JSON.stringify(selectedRow) : "[]"}
        sorts: []
        limit: 10
        offset: 0
        isNested: true
      }
      columnAxis: {
        fieldNames: ${selectedCol?.length > 0 ? JSON.stringify(selectedCol) : `"${selectedTimeRange?.value?.toString()}"`}
        limit: 5
        offset: 0
        sorts: []
        isNested: false
      }
      format: TABLE
    )
  }
`;
  const { loading, error, data, networkStatus } = useQuery(GET_ITEMS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const isFetching = networkStatus === 4;
  useEffect(() => {
    if (data?.getReportData?.default?.responses) {
      setResponse(data?.getReportData?.default?.responses);
    }
  }, [data]);

  const reportType =
    reportBuilderUtils?.data && reportBuilderUtils?.data?.reportTypes;
  const reportDimensions = reportBuilderUtils?.data?.dimensions;
  const reportMetrics = reportBuilderUtils?.data?.metrics;
  const reportTimeRange = reportBuilderUtils?.data?.timeRange;

  useEffect(() => {
    if (selectedTimeRange && selectedTimeRange?.value === "entireRange") {
      setAllowedColumn(["B", "D"]);
    } else {
      setAllowedColumn(["B"]);
    }
  }, [selectedTimeRange]);

  useEffect(() => {
    if (reportDimensions || reportMetrics) {
      const newDimensions = reportDimensions
        ?.map((item, index) => {
          const existsInRow = row?.some((itm) => itm?.name === item?.label);
          if (existsInRow) {
            return null;
          }
          return {
            id: item?.value,
            name: item?.label,
            // allowedDropColumns: allowedColumn,
            allowedDropColumns: ["B", "D"],
          };
        })
        .filter(Boolean);

      const newMetrics = reportMetrics
        ?.map((item, index) => {
          const existingMetrics = columnToCompareMetrics?.some(
            (itm) => item?.label === itm?.name
          );
          if (existingMetrics) {
            return null;
          }
          return {
            id: item?.value,
            name: item?.label,
            allowedDropColumns: ["E"],
          };
        })
        .filter(Boolean);

      setColumnAItems(newDimensions);
      setColumnAToMetrics(newMetrics);
      setTimeRange(reportTimeRange);
    }
  }, [reportBuilderUtils, allowedColumn]);
  const responseRows = response && response[0]?.responseRows;
  const pivotHeaders = response && response[0]?.pivotHeaders;
  const colDimensions = response && response[0]?.colDimensions?.fieldNames;
  const responseData = response && response[0]?.responseRows;
  const dimensions = response && response[0]?.dimensions?.fieldNames;
  const metrics = response && response[0]?.metrics;

  useEffect(() => {
    if (loading || isFetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading, isFetching]);
  const handleSave = () => {
    setSaveModal(false);
  };
  return (
    <div className="relative px-4 pr-6 overflow-hidden">
      <div className="bg-[#282a3d] group flex pl-2 py-4 items-center absolute -right-0 w-fit cursor-pointer rounded-l-lg top-[44%] z-50 text-white">
        <div className="mr-3 cursor-pointer">
          <FaChevronLeft />
        </div>
        <div className="flex flex-col group-hover:mr-0 -mr-[55px] transition-all ease-linear duration-150 gap-2">
          <div
            title="Lock report"
            className="bg-[#ffffff]  w-fit py-3 px-4 cursor-pointer rounded-l-lg top-[44%] z-50 text-black"
            onClick={()=>setLockEdit(!lockEdit)}
          >
           {lockEdit ? <FaLock /> : <FaUnlockAlt />}
          </div>
          <div
            title="Download Report"
            className="bg-[#ffffff]  w-fit py-3 px-4 cursor-pointer rounded-l-lg top-[44%] z-50 text-black"
            onClick={Array.isArray(response) ? () => exportToExcel(response) : ()=>toast.error("Insufficient data to download!",{position:"bottom-right"})}
          >
            <AiOutlineDownload />
          </div>
          <div
            title="Steps to generate report!"
            className="bg-[#ffffff] w-fit py-3 px-4 cursor-pointer rounded-l-lg top-1/2 z-50 text-black"
          >
            <BsInfoCircleFill />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        {saveModal && (
          <GlobalModalComponent
            saveBtnTitle={"Save"}
            hideModal={() => setSaveModal(false)}
            title={"Save Report"}
            showClearAll={false}
            onSubmit={handleSave}
          >
            <div className="flex flex-col gap-[16px]">
              <div className=" flex">
                <div className="w-[211px] flex items-center text-[#59647B] text-[14px] font-[500] ">
                  Report Name
                </div>
                <input
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  placeholder="Type Somthing..."
                  className="w-[211px] bg-[#F6F8FB] text-[#59647B] text-[14px]  px-2 h-[36px] focus:outline-none rounded-lg"
                />
              </div>
              <div className="flex">
                <div className="w-[211px] h-[36px] flex items-center text-[#59647B] text-[14px] font-[500] ">
                  Report Description
                </div>
                <textarea
                  placeholder="Type Somthing..."
                  rows={5}
                  cols={5}
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                  className="w-[350px] bg-[#F6F8FB] text-[#59647B] text-[14px]  px-2 py-2  focus:outline-none rounded-lg"
                />
              </div>
            </div>
          </GlobalModalComponent>
        )}
        <div className="flex gap-4 items-center">
          {/* <div
            onClick={() => navigate(-1)}
            className="w-[32px] h-[32px] bg-[#1e50ff] flex justify-center items-center cursor-pointer rounded-md"
          >
            <img src={whiteBackIcon} />
          </div> */}
          <div>
            <input
              defaultValue={reportName}
              className="text-[17px] w-[150px] px-2 text-sm h-8 rounded-lg font-medium focus:outline-none"
              onChange={(e) => setReportName(e.target.value)}
              value={reportName}
            />
          </div>
          {reportDescription ? (
            <div
              onClick={() => setSaveModal(true)}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border-none  h-[18px] w-[18px] flex justify-center items-center text-lg ">
                <img src={editBlueIcon} />
              </div>
              <div>Edit Description</div>
            </div>
          ) : (
            <div
              onClick={() => setSaveModal(true)}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border pb-0.5 h-[18px] w-[18px] flex justify-center items-center text-lg rounded-full border-[#1D3893]">
                +
              </div>
              <div>Add Description</div>
            </div>
          )}
        </div>

        <div className="flex gap-4 items-center">
          <div className="bg-[#EEF1F6] group rounded-lg text-[#59647B] text-[12px] font-[400] py-[4px] px-[24px] cursor-pointer transition-all ease-in-out duration-200  hover:bg-[#CCD6E5] hover:text-black">
            <div
              onClick={() => navigate(-1)}
              className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] "
            >
              {" "}
              Discard Changes{" "}
            </div>
          </div>
          <div
            onClick={() => setSaveModal(true)}
            className="bg-[#1D2D44] group rounded-lg text-white text-[12px] font-[400] py-[4px] px-[24px] cursor-pointer hover:bg-[#1D3893] transition-all ease-in-out duration-200"
          >
            <div className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] ">
              {" "}
              Save{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex gap-2 justify-between relative">
        <div className="min-w-[35%] my-2 sticky bottom-0 min-h-[88vh] z-20 rounded-lg max-h-[88vh] bg-white p-2 max-w-[30%] flex flex-col items-center gap-2">
          <div
            className={`w-[100%] flex ${isTablet && "flex-col gap-2"} justify-between items-center`}
          >
            <div className="text-gray-700 flex items-center gap-2 justify-start w-[100%]">
              Report Builder{" "}
              <span className="text-xs text-gray-600">
                (Customize your report.)
              </span>
            </div>
            <div
              className={`flex  gap-2  ${isTablet ? "w-[100%] justify-start" : "w-[40%] items-center"}  cursor-pointer`}
              onClick={() => exportToExcel(response)}
            >
              <div className="flex w-fit bg-[#1D2D44] py-1 px-2 text-white cursor-pointer rounded-lg">
                <AiOutlineDownload />{" "}
                <div className="text-xs">Download report</div>
              </div>
            </div>
          </div>

          <div className="w-[100%] gap-2 flex sticky bg-white z-40">
            <div className={` ${isTablet ? "w-[70%]" : "w-[50%]"} pr-2`}>
              <div className="my-2 mx-2 text-sm">Select date range</div>
              {
                <div
                  className="relative mb-2 w-[100%] justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-gray-600 rounded-md border-solid border border-[#A0A4B0]"
                  ref={ref1}
                >
                  <div
                    className="flex gap-2 items-center"
                    onClick={() => setShowDateRange(!showDateRange)}
                  >
                    {" "}
                    <AiFillCalendar size={16} />{" "}
                    {format(new Date(dateRange[0].startDate), "dd MMM yyy")} -{" "}
                    {format(new Date(dateRange[0].endDate), "dd MMM yyy")}
                  </div>
                  {showDateRange && (
                    <div className="absolute top-[50px] z-50">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(range) => {
                          setDateRange([
                            {
                              startDate: range?.range1?.startDate,
                              endDate: range?.range1?.endDate,
                              range: "range 1",
                            },
                          ]);
                        }}
                      />
                    </div>
                  )}
                </div>
              }
              <div className="flex px-1 pr-2 justify-start items-center gap-4">
                <Dropdown
                  width={220}
                  selectedItem={selectedTimeRange?.label || "Select Time Range"}
                  setSelectedItems={setSelectedTimeRange}
                  options={timeRange}
                  height={40}
                />
              </div>
            </div>
            {reportBuilderUtils ? (
              <div className="w-[50%] mt-2">
                {/* <div className="text-sm mb-2">Select report type</div>
                <div className="grid grid-cols-2 gap-4 pr-2 justify-start items-center">
                  {reportType?.map((item,index) => {
                    return (
                      <div
                        title={item?.label}
                        className={`p-2 border flex justify-center items-center rounded-md ${selectedType === item?.value ? "text-white border-blue-600 bg-blue-600" : "text-gray-600 border-gray-600"} transition-all ease-in-out duration-200  cursor-pointer`}
                        onClick={() => setSelectedType(item?.value)}
                        key={index}
                      >
                        {item?.value === "TABLE" ? (
                          <AiOutlineTable size={20} />
                        ) : item?.value === "ARCHART" ? (
                          <AiOutlineAreaChart size={20} />
                        ) : item?.value === "LICHART" ? (
                          <AiOutlineLineChart size={20} />
                        ) : (
                          item?.value === "BACHART" && (
                            <AiOutlineBarChart size={20} />
                          )
                        )}
                      </div>
                    );
                  })}
                </div> */}
              </div>
            ) : (
              <div className="w-[00%] px-2">
                <CustomSkeleton height={130} width="100%" className={"z-40"} />
              </div>
            )}
          </div>
          <div className=" h-[550px] w-[100%] overflow-y-scroll">
            <DndProvider backend={HTML5Backend}>
              <div className="w-[100%] text-sm flex flex-col items-center"></div>

              <div
                style={{
                  display: "block",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                {reportDimensions ? (
                  <Column
                    title="Dimensions"
                    items={columnAItems}
                    columnId={"A"}
                    borderColor={"#3066BE"}
                    isFlex={true}
                    onPick={() => {
                      setIsRowDrop(true);
                      setIsColumnrop(true);
                    }}
                    onPickOut={() => {
                      setIsRowDrop(false);
                      setIsColumnrop(false);
                    }}
                    disablePick={lockEdit}
                    // onPickDrop={()=>alert("kbjk")}
                  />
                ) : (
                  <div className="w-[97%] mx-2">
                    <CustomSkeleton height={250} width="100%" />
                  </div>
                )}
              </div>
              <div className="w-[100%] mb-4 flex">
                <div className="w-[100%]">
                  {reportDimensions ? (
                    <Column
                      title="Row"
                      items={row}
                      useCancel={true}
                      onDropItem={handleDropToColumnB}
                      onMoveToColumn={handleMoveToColumnA}
                      columnId={"B"}
                      borderColor={"#3066BE"}
                      isDropping={isRowDrop}
                      disablePick={lockEdit}
                    />
                  ) : (
                    <div className="w-[100%] mx-2">
                      <CustomSkeleton height={250} width="100%" />
                    </div>
                  )}
                </div>
                <div className="w-[100%]">
                  {reportBuilderUtils ? (
                    <Column
                      title="Column"
                      items={col}
                      useCancel={true}
                      onDropItem={handleDropToColumnBCompare}
                      onMoveToColumn={handleMoveToColumnACompare}
                      columnId={"D"}
                      borderColor={"#3066BE"}
                      isDropping={isColumnDrop}
                      disablePick={lockEdit}
                    />
                  ) : (
                    <div className="w-[95%] mx-2">
                      <CustomSkeleton height={250} width="100%" />
                    </div>
                  )}
                </div>
              </div>
            </DndProvider>

            <DndProvider backend={HTML5Backend}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                {reportBuilderUtils ? (
                  <Column
                    title="Metrics"
                    items={columnToMetrics}
                    onDropItem={() => {}}
                    columnId={"C"}
                    borderColor={"#89FC00"}
                    onPick={() => {
                      setIsMetricsDrop(true);
                    }}
                    onPickOut={() => {
                      setIsMetricsDrop(false);
                    }}
                    disablePick={lockEdit}
                  />
                ) : (
                  <div className="w-[50%] mx-2">
                    <CustomSkeleton height={250} width="100%" />
                  </div>
                )}
                {reportBuilderUtils ? (
                  <Column
                    title="Metrics"
                    items={columnToCompareMetrics}
                    useCancel={true}
                    onDropItem={handleDropToColumnMetricsCompare}
                    onMoveToColumn={handleMoveToColumnMetricCompare}
                    columnId={"E"}
                    borderColor={"#89FC00"}
                    isDropping={isMetricsDrop}
                    disablePick={lockEdit}
                  />
                ) : (
                  <div className="w-[50%] mx-2">
                    <CustomSkeleton height={250} width="100%" />
                  </div>
                )}
              </div>
            </DndProvider>
            <DndProvider backend={HTML5Backend}>
              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              ></div>
            </DndProvider>
          </div>
        </div>
        <div className="min-w-[65%] max-w-[65%] overflow-scroll py-4 min-h-[88vh] max-h-[88vh] bg-white my-2  rounded-lg">
          {!isLoading ? (
            <div>
              {/* starting of table */}
              <div>
                <div className=" sticky -top-4 z-20">
                  {/* pivot headers and dimensions */}
                  <div className="flex w-max ">
                    <div className="flex gap-2 sticky left-0 border text-white bg-[#3E5c76]">
                      {/* {Array.isArray(dimensions) &&
                        dimensions
                          ?.slice(0, dimensions?.length - 1)
                          ?.map((item, index) => {
                            return <div className={`p-2 w-[150px]`}></div>;
                          })} */}
                      <div>
                        {Array.isArray(colDimensions) &&
                          colDimensions?.map((item, ind) => {
                            return (
                              <div
                                className={`p-2 text-center ${ind == colDimensions?.length - 1 ? "" : "border-b"} h-max  truncate min-w-[150px]`}
                                data-tooltip-id={`tool-${ind}-${item}`}
                                data-tooltip-content={item}
                                style={{
                                  maxWidth: `${150 * dimensions?.length}px`,
                                  minWidth: `${150 * dimensions?.length}px`,
                                }}
                              >
                                {formatString(item)}
                                <Tooltip id={`tool-${ind}-${item}`} />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* pivotHeaders */}
                    <div className="flex">
                      {Array.isArray(pivotHeaders) &&
                        pivotHeaders?.map((item, index) => {
                          const datesCount = metrics?.length;
                          return (
                            <div className="">
                              {item?.names?.map((data, dind) => {
                                return (
                                  <div className="bg-white">
                                    <div
                                      className={`py-2 flex flex-col justify-center border ${dind % 2 === 0 ? "text-black bg-[#96c82a42]" : "bg-white"} h-[41px] `}
                                      style={{ width: `${150 * datesCount}px` }}
                                    >
                                      <div
                                        className="min-w-[100%] max-w-[100%]  truncate text-sm text-center pl-4"
                                        data-tooltip-content={data}
                                        data-tooltip-id={`tool-${dind}-${data}`}
                                      >
                                        {data}
                                        <Tooltip
                                          id={`tool-${dind}-${data}`}
                                          place="bottom"
                                        />
                                      </div>
                                      {/* <div className="flex items-center gap-2">
                                      {Array.isArray(
                                        groupArrayByDateRange(
                                          dateRange[0].startDate,
                                          dateRange[0].endDate,
                                          metrics
                                        )
                                      ) &&
                                        groupArrayByDateRange(
                                          dateRange[0].startDate,
                                          dateRange[0].endDate,
                                          metrics
                                        )?.map((items) => {
                                          return items[
                                            getAllDatesInRange(
                                              dateRange[0].startDate,
                                              dateRange[0].endDate
                                            )[index]
                                          ]?.map((itm) => {
                                            return (
                                              <div className="w-fit">
                                                {itm?.name}
                                              </div>
                                            );
                                          });
                                        })}
                                    </div> */}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="flex w-max text-white bg-[#3E5c76] z-10 sticky top-6">
                  <div className="flex items-center sticky text-white bg-[#3E5c76] left-0  border w-fit">
                    {Array.isArray(dimensions) &&
                      dimensions?.map((item, index) => {
                        return (
                          <div
                            className={`${dimensions?.length - 1 === index ? "" : "border-r"} p-2 w-[150px]`}
                          >
                            {formatString(item?.split(".")[1])}
                          </div>
                        );
                      })}
                  </div>
                  <div className="border flex ">
                    {/* <div className="flex items-center gap-2">
                                      {Array.isArray(
                                        groupArrayByDateRange(
                                          dateRange[0].startDate,
                                          dateRange[0].endDate,
                                          metrics
                                        )
                                      ) &&
                                        groupArrayByDateRange(
                                          dateRange[0].startDate,
                                          dateRange[0].endDate,
                                          metrics
                                        )?.map((items) => {
                                          return items[
                                            getAllDatesInRange(
                                              dateRange[0].startDate,
                                              dateRange[0].endDate
                                            )[index]
                                          ]?.map((itm) => {
                                            return (
                                              <div className="w-fit">
                                                {itm?.name}
                                              </div>
                                            );
                                          });
                                        })}
                                    </div> */}
                    {pivotHeaders &&
                      pivotHeaders.map((it, index) => {
                        return (
                          metrics &&
                          metrics?.map((item, index) => {
                            return (
                              <div
                                className={`${metrics?.length - 1 === index ? "border-r" : ""}  text-center  p-2 w-[150px]`}
                              >
                                {formatString(item?.name)}
                              </div>
                            );
                          })
                        );
                      })}
                    {/* {Array.isArray(metrics) &&
                      metrics?.map((item, index) => {
                        return (
                          <div
                            className={`${metrics?.length - 1 === index ? "" : "border-r"} p-2 w-[150px]`}
                          >
                            {formatString(item?.name)}
                          </div>
                        );
                      })} */}
                  </div>
                </div>
                <div className="flex border max-h-max w-max relative">
                  <div className="flex sticky left-0 bg-white flex-col">
                    {Array.isArray(responseData) &&
                      responseData?.map((item, index) => {
                        return (
                          <div
                            className={`text-sm flex    ${index % 2 === 0 && "text-black bg-[#96c82a42]"}`}
                          >
                            {item?.dimensionCompoundValues?.map(
                              (items, indexx) => {
                                return (
                                  <div
                                    className={`min-w-[150px] max-w-[150px] truncate p-2 ${indexx === 0 && ""}`}
                                    data-tooltip-id={`tool-${indexx}`}
                                    data-tooltip-content={items?.value}
                                  >
                                    {items?.value}
                                    <Tooltip
                                      id={`tool-${indexx}`}
                                      // isOpen={true}
                                      place="bottom"
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className="">
                    {Array.isArray(responseData) &&
                      responseData?.map((item, index) => {
                        return (
                          <div
                            className={`text-sm flex   ${index % 2 === 0 && "text-black bg-[#96c82a42]"} `}
                          >
                            {item?.metricCompoundValues?.map((items, index) => {
                              return (
                                <div className="w-[150px] text-center p-2">
                                  {items?.value === "N/A" ? "-" : items?.value}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-fit mr-3 p-2 pl-4 ">
              <div className="w-[100%]">
                <CustomSkeleton height={60} />
              </div>
              <div className="w-[100%] mt-2 flex items-center gap-4">
                <div className="w-[30%]">
                  <CustomSkeleton height={600} />
                </div>
                <div className="w-[70%]">
                  <CustomSkeleton height={600} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewReportBuilder;

const Column = ({
  title,
  items,
  onDropItem,
  useCancel,
  onMoveToColumn,
  columnId,
  borderColor,
  disableDrag,
  disablePick, // New prop to disable picking items
  isFlex,
  isDropping,
  onPick,
  onPickOut,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.ITEM,
    drop: (item) => {
      if (!disableDrag) {
        onDropItem(item, columnId);
      }
    },
    canDrop: (item) => {
      if (disableDrag) {
        return false;
      }
      // Check if the item can be dropped in this column
      return item.allowedDropColumns.includes(columnId);
    },
    hover: () => {}, // Ensure hover is defined even if not used
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      className="rounded-md"
      ref={drop}
      style={{
        flex: 1,
        margin: "8px",
        minHeight: "250px",
        maxHeight: "250px",
        overflowY: "scroll",
        backgroundColor: "white",
        border: `1px ${isDropping ? "dashed" : "solid"} #ccc`,
        position: "relative",
      }}
    >
      <h2 className="sticky top-0 text-gray-800 bg-white font-medium text-sm border-b p-4 pb-3 mb-3">
        {title}
      </h2>
      <div
        className={`px-4 flex ${isFlex ? "flex-row gap-3" : "flex-col gap-3"}  mb-3 w-[100%] flex-wrap`}
      >
        {items?.map((item) => (
          <DragItem
            key={item.id}
            item={item}
            useCancel={useCancel}
            onMoveToColumn={onMoveToColumn}
            columnId={columnId}
            borderColor={borderColor}
            isFlex={isFlex}
            onPick={onPick}
            onPickOut={onPickOut}
            disablePick={disablePick} // Pass down disablePick to DragItem
          />
        ))}
      </div>
      {isDropping && (
        <div className="w-[100%] absolute top-0 inset-0  h-full flex justify-center items-center bg-gray-50">
          <div className="flex gap-2 text-gray-600 items-center text-sm">
            <RiDragDropLine size={20} /> Drop inside {title}
          </div>
        </div>
      )}
    </div>
  );
};

const DragItem = ({
  item,
  useCancel,
  onMoveToColumn,
  columnId,
  borderColor,
  isFlex,
  onPick,
  onPickOut,
  disablePick, // Receive disablePick prop
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType.ITEM,
    item: () => {
      if (!disablePick && onPick) {
        onPick(item); // Call onPick when dragging begins
      }
      return { ...item, columnId };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (!dropResult && onPickOut) {
        onPickOut(item); // Call onPickOut when drag ends
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disablePick, // Disable dragging if disablePick is true
  });

  const handleMoveBack = () => {
    if (onMoveToColumn) {
      onMoveToColumn(item);
    }
  };

  return (
    <div
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: "8px",
        margin: "4px",
        cursor: disablePick ? "not-allowed" : "move",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border:
          isDragging || useCancel
            ? `1px dashed ${borderColor ? borderColor : " #A0A4B0"}`
            : `1px solid #A0A4B0`,
        borderLeft: useCancel
          ? `4px solid ${borderColor}`
          : `4px solid ${borderColor}`,
      }}
      className="rounded-md text-md text-gray-700"
      ref={disablePick ? null : drag}
    >
      <div className="text-xs flex gap-2 items-center">
        <AiOutlineHolder size={18} />
        {item.name}
      </div>
      {useCancel && (
        <div className="text-red-400" onClick={handleMoveBack}>
          <AiOutlineClose />
        </div>
      )}
    </div>
  );
};
const ItemType = {
  ITEM: "ITEM",
};
