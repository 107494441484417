import ExcelJS from 'exceljs';
import { logoUrl } from './retvensLogo.js';
import { format, addDays } from 'date-fns';
import { formatDate } from './formatDate.js';

function getNext9Days(selectedDate) {
  let result = [];
  let startDate = new Date(selectedDate);
  for (let i = 0; i < 9; i++) {
    let nextDay = addDays(startDate, i);
    let day = format(nextDay, 'EEEE'); // 'EEEE' gives the full name of the day
    result.push({ date: format(nextDay, 'yyyy-MM-dd'), day: day });
  }
  return result;
}
const propertyName = window.localStorage.getItem("propertyName");
export const DownloadOccupencyReport = async (getInventory,selectedDate=new Date()) => {
  try {
    const roomsData = {};
    const totals = [];
    const occupancies = [];

    // Organize data by roomName and gather totals and occupancies
    getInventory?.data?.forEach((item) => {
      item.rooms.forEach((room) => {
        if (!roomsData[room.roomName]) {
          roomsData[room.roomName] = [];
        }
        roomsData[room.roomName].push({
          date: item.date,
          availableRooms: room.availableRooms,
        });
      });
      totals.push({ date: item.date, total: item.totalAvailableRooms });
      occupancies.push({ date: item.date, occupancy: item.occupancy });
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const logoBase64 = logoUrl;
    const logoId = workbook.addImage({
      base64: logoBase64,
      extension: 'jpg',
    });

    worksheet.mergeCells('A1:J3');
    worksheet.addImage(logoId, 'A1:A3');

    const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    const columnWidths = Array(17).fill(25);
    columns.forEach((column, index) => {
      worksheet.getColumn(column).width = columnWidths[index];
    });

    const dateCell = worksheet.getCell('I1');
    dateCell.value = new Date();
    dateCell.numFmt = 'dd mmmm yyyy';

    const mainHeading = worksheet.getCell('A4');
    mainHeading.value = `Occupancy Report - ${propertyName || ''}  (${formatDate(selectedDate)} )`;
    mainHeading.alignment = { horizontal: 'center', vertical: 'middle' };
    mainHeading.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B7D166' },
    };
    worksheet.mergeCells('A4:J4');
    worksheet.getRow(4).height = 40;
    worksheet.mergeCells('A5:J5');

    // Add date headers
    const next9Days = getNext9Days(selectedDate);
    worksheet.getRow(6).values = ['Room Name', ...next9Days.map(date => `${date.day.slice(0, 3)}, ${date.date.split("-")[2]}`)];
    worksheet.getCell('A6').font = { bold: true }; // Make 'Room Name' bold
    worksheet.getRow(7).values = ['', ...next9Days.map(date => `${format(new Date(date.date), 'MMM')}, ${format(new Date(date.date), 'yyyy')}`)];

    // Merge date headers
    for (let i = 2; i <= 10; i++) {
      worksheet.mergeCells(6, i, 7, i);
      worksheet.getCell(6, i).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(6, i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9EAD3' }, // Light green color
      };
      worksheet.getCell(6, i).font = { bold: true };
    }

    let rowNumber = 8;

    Object.entries(roomsData).slice(0, 9).forEach(([roomName, availability]) => {
      worksheet.getRow(rowNumber).values = [roomName, ...availability.slice(0, 9).map(a => a.availableRooms)];
      worksheet.getCell(`A${rowNumber}`).font = { bold: true }; // Style Room Name
      worksheet.getCell(`A${rowNumber}`).alignment = { wrapText: true }; // Style Room Name

      rowNumber++;
    });

    worksheet.getRow(rowNumber).values = ['Total', ...totals.slice(0, 9).map(t => t.total)];
    worksheet.getRow(rowNumber).font = { bold: true }; // Style Total row
    worksheet.getRow(rowNumber).eachCell((cell) => {
        if (cell.value === 0 || cell.value) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF64C0CC' }  // '#64c0cc' in ARGB format is 'FF64C0CC'
            };
            cell.alignment = {wrapText:true}
        }
    });
    rowNumber++;

    worksheet.getRow(rowNumber).values = ['Occupancy', ...occupancies.slice(0, 9).map(o => o.occupancy)];
    worksheet.getRow(rowNumber).font = { bold: true };
    worksheet.getRow(rowNumber).eachCell((cell) => {
        if (cell.value === 0 || cell.value) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF64C0CC' }  // '#64c0cc' in ARGB format is 'FF64C0CC'
            };
            cell.alignment = {wrapText:true}
        }
    });

    // Style Occupancy row
    rowNumber++;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Occupancy report ${propertyName || 'Unknown'}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating report:', error);
    alert('An error occurred while generating the report. Please try again.');
  }
};
