import React, { useEffect, useState } from "react";
import ChannelCard from "../channelcard";
import RoomMappingByOTAS from "./hotel2roomMapping";
import Hotel2RoomMapping from "./hotel2roomMapping";
import { CurrentProperty } from "../../../utils/impVars";
import Hotel2HotelMapping from "./hotel2hotelMapping";
import CompsetToRoomMapping from "./compset2roomMapping";
import Hotel2hotelOTAMapping from "./hotel2hotelOTAMapping";

const RoomMapping = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  const handleBackButtonClick = () => {
    setSelectedCard(null);
  };

  const renderComponent = () => {
    switch (selectedCard) {
      case "Your OTA Room Mapping To Compsets":
        return (
          <Hotel2hotelOTAMapping handleBackButtonClick={handleBackButtonClick} />
        );
      case "Your Room Mapping":
        return (
          <Hotel2RoomMapping handleBackButtonClick={handleBackButtonClick} type={'your'} />
        );
      case "Compset Room Mapping":
        return (
          <CompsetToRoomMapping handleBackButtonClick={handleBackButtonClick} type={'compset'} />
        );
      case "Your Room Mapping To Compsets":
        return (
          <Hotel2HotelMapping handleBackButtonClick={handleBackButtonClick} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="">
      {selectedCard ? (
        renderComponent()
      ) : (
        <div>
          <div className="border-b-2 font-semibold text-[17px] text-[#0D1321] h-[44px] flex items-center mt-1 mb-4">
            Room Mapping
          </div>
          <div className="flex flex-col gap-2">
            <ChannelCard
              title={"Your OTA Room Mapping To Compsets"}
              onClick={() => handleCardClick("Your OTA Room Mapping To Compsets")}
            />
            <ChannelCard
              title={"Your Room Mapping"}
              onClick={() => handleCardClick("Your Room Mapping")}
            />
            {/* <ChannelCard
              title={"Compset Room Mapping"}
              onClick={() => handleCardClick("Compset Room Mapping")}
            />
            <ChannelCard
              title={"Your Room Mapping To Compsets"}
              onClick={() => handleCardClick("Your Room Mapping To Compsets")}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default RoomMapping;


