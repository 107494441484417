import { emptySplitApi } from "../../injectEndpoints";

export const RateVisibilityRank = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisibilityOverview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/visibilityOverView`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getVisibilityOverview"],
    }),
    getVisibilityTrend: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/visibilityTrends`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getVisibilityTrend"],
    }),
    getVisibilityCalendar: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/visibilityCalender`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getVisibilityCalendar"],
    }),
    getVisibilityLeaderboard: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/visibilityLeaderBoard`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getVisibilityCalendar"],
    }),
  }),
  overrideExisting: false,
});

export const {
useGetVisibilityOverviewQuery, useGetVisibilityTrendQuery, useGetVisibilityCalendarQuery, useGetVisibilityLeaderboardQuery
} = RateVisibilityRank;
