import { emptySplitApi } from "../../injectEndpoints";
export const OnDemandRefresh = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    onDemandBookings: builder.mutation({
      query: (data) => {
        return {
          url: `/onDemand/bookings`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["onDemandBookings"],
    }),
    onDemandRanks: builder.mutation({
      query: (data) => {
        return {
          url: `/onDemand/ranks`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["onDemandRanks"],
    }),
    onDemandRates: builder.mutation({
      query: (data) => {
        return {
          url: `/onDemand/rates`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["onDemandRates"],
    }),
    onDemandSuggestedRates: builder.mutation({
      query: (data) => {
        return {
          url: `/onDemand/suggestedRates`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["onDemandSuggestedRates"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useOnDemandBookingsMutation,
  useOnDemandRanksMutation,
  useOnDemandRatesMutation,
  useOnDemandSuggestedRatesMutation,
} = OnDemandRefresh;
