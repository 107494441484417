import { emptySplitApi } from "../../injectEndpoints";

export const AlertAndNotifications = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlertsAndNotification: builder.query({
      query: (data) => ({
        url: `/userDashboardSetting/getalertandNotification`,
        method: "GET",
        params:data
      }),
      // Replace with your actual endpoint
      providesTags: ["getAlertsAndNotification"],
    }),
    editAlertsAndNotification: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editAlertAndNotification?userId=${data?.userId}`,
          method: "PATCH",
          body: data?.body,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editAlertsAndNotification"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAlertsAndNotificationQuery ,useEditAlertsAndNotificationMutation} = AlertAndNotifications;
