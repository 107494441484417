import React, { useEffect, useRef, useState } from "react";
import ChannelCard from "../../channelcard";
import SettingButton from "../../../../components/settingButton/SettingButton";
import { useEditAlertsAndNotificationMutation } from "../../../../redux/slices/settings/alertsNotifications";
import { toast } from "react-toastify";
import { UserId } from "../../../../utils/impVars";
import BackdropLoader from "../../../../components/newLoader";

const DisparityAlert = ({ handleBackButtonClick, data, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const userId = UserId();
  const [selectedCard, setSelectedCard] = useState(null);
  const [minorDisparity, setMinorDisparity] = useState(null);
  const [majorDisparity, setMajorDisparity] = useState(null);
  const [editAlertsAndNotification] = useEditAlertsAndNotificationMutation();
  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  // const handleBackButtonClick = () => {
  //     setSelectedCard(null)
  // }

  const renderComponent = () => {
    switch (selectedCard) {
      case "Minor Disparity":
      // return <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />;
      case "Major Disparity":
      // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (majorDisparity != null) {
      changeDisparity();
    }
  }, [majorDisparity]);

  useEffect(() => {
    if (minorDisparity != null) {
      changeDisparity();
    }
  }, [minorDisparity]);

  function changeDisparity() {
    setIsLoading(true);
    const body = {
      disparityParity: {
        majorDisparity:
          majorDisparity != null ? majorDisparity : data?.majorDisparity,
        minorDisparity:
          minorDisparity != null ? minorDisparity : data?.minorDisparity,
      },
    };

    editAlertsAndNotification({ userId, body })
      .unwrap()
      .then((res) => {
        refetch();
        setIsLoading(false);
        toast.success("Updated Successfully!", { position: "bottom-right" });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <BackdropLoader loading={isLoading} />
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col gap-4">
          <SettingButton
            Title={"Disparity Alert"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <ChannelCard
                title={"Minor Disparity"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={
                  data?.minorDisparity && minorDisparity === null
                    ? data?.minorDisparity
                    : minorDisparity
                }
                onChange={(e) => {
                  setMinorDisparity(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Major Disparity"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={
                  data?.majorDisparity && majorDisparity === null
                    ? data?.majorDisparity
                    : majorDisparity
                }
                onChange={(e) => {
                  setMajorDisparity(e.target.checked);
                }}
              />
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};

export default DisparityAlert;
