import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManagerRatePlan = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRatePlan: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRatePlanDetail`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getMealPlan"],
    }),
    getRatePlanCharge: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRatePlanCharge`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatePlanCharge"],
    }),
    getRoomTypeMealType: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRoomTypeNMealType`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatePlanCharge"],
    }),
    addRatePlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addratePlanDetail`,
          method: "POST",
          body: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["addRatePlan"],
    }),
    editRatePlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editRatePlanDetail`,
          method: "PATCH",
          body: data?.obj,
          params:data?.editParams
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editRatePlan"],
    }),
    deleteRatePlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteRatePlan`,
          method: "PATCH",
          params: data
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["deleteRatePlan"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetRatePlanQuery,
  useAddRatePlanMutation,
  useGetRatePlanChargeQuery,
  useGetRoomTypeMealTypeQuery,
  useEditRatePlanMutation,
  useDeleteRatePlanMutation } = ChannelManagerRatePlan;
