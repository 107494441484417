import React, { useState } from 'react'
import RateRangeCrad from '../rateRangeCard';
import StatsCard from '../cards/statsCard';

const RoomContributions = () => {
    const currentYearPrice = 2265; // Static value for current year price
    const lastYearPrice = 3016; // Static value for last year price

    // Calculate the percentage of last year's price in the progress bar
    const lastYearPercentage = (lastYearPrice / (currentYearPrice + lastYearPrice)) * 100;

    // Calculate the difference in prices and its percentage relative to the current year price
    const currentYearPercentage = (currentYearPrice / (currentYearPrice + lastYearPrice)) * 100;
    const priceDifference = currentYearPrice - lastYearPrice;
    const priceDifferencePercentage = (priceDifference / currentYearPrice) * 100;


    const priceDifferenceLeft = lastYearPercentage;

    const [progress, setProgress] = useState(priceDifferencePercentage);
    const handleChange = (e) => {
        const value = parseInt(e.target.value);
        setProgress(value);
    };

    let progressBarColor;
    if (currentYearPrice > lastYearPrice) {
        progressBarColor = "#BAEDBD"; // Green color
    }
    if (currentYearPrice < lastYearPrice) {
        progressBarColor = "#FFADAD"; // Red color
    }
    return (
        <div className=" bg-neutral-light-3   flex  w-[100%] h-full p-2  rounded-xl">
           
            <div className=" flex flex-col  w-full px-2 py-2 ">
                <div className="h-5 text-[#2e343e] text-[14px] font-medium px-1  w-full ">
                    <p className="mt-4 text-[14px] ">{"Room Contributions"}</p>
                </div>
                <div>
                <div className=" w-full py-4    ">
                    {/* {data.map((item, index) => { */}
                    {/* return ( */}
                    <div className="  py-2 group   hover:bg-neutral-light-5 rounded-xl ">
                        <div className="flex  justify-between
                  hover:scale-[0.99] hover:transition-transform hover:duration-200
                "

                        >
                            <div className="flex items-center  lg:w-[100%] md:w-[100%] w-[100%] h-full ml-2 ">


                                <div className="flex mt-1  items-center">
                                    <div className="flex flex-col">
                                        <p className="text-base  text-[#2e343e]  whitespace-nowrap text-[14px] font-medium">
                                            {"Room Name"}
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="flex  w-full gap-2 whitespace-nowrap justify-end items-center font-medium hover:bg-neutral-light-5  ">
                                <p>{"₹ 6,874"}</p>
                                {<RateRangeCrad />}
               
                            </div>

                        </div>
                    </div>
                    {/* );
          })} */}
                </div>
                <div className={`h-2 relative rounded-lg`}
                    style={{ background: progressBarColor }}>
                    {lastYearPercentage < currentYearPercentage ? (
                        <div
                            className="h-full bg-[#7fE088] absolute top-0 left-0"
                            style={{ width: `${lastYearPercentage}%` }}
                        ></div>
                    ) :
                        (
                            <div
                                className="h-full bg-red-400 absolute top-0 left-0"
                                style={{ width: `${lastYearPercentage}%` }}
                            ></div>
                        )
                    }

                    {priceDifferencePercentage > 0 ? (
                        <div
                            className="h-full bg-green-600 absolute top-0 "
                            style={{ width: `${priceDifferencePercentage}%`, left: `${priceDifferenceLeft}%` }}
                        ></div>
                    ) :
                        (
                            <div
                                className="h-full bg-red-600 absolute top-0"
                                style={{ width: `${lastYearPercentage + priceDifferencePercentage}%`, left: `${priceDifferenceLeft}%` }}
                            ></div>
                        )
                    }
                    {lastYearPercentage < currentYearPercentage ? (
                        <div
                            className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 "
                            style={{ left: `${lastYearPercentage + priceDifferencePercentage}%` }}
                        ></div>
                    ) : (
                        <div
                            className="w-2 h-6 bg-red-600 border-4 border-red-600 rounded-full absolute -top-[8px] -mt-1/2 "
                            style={{ left: `${lastYearPercentage}%` }}
                        ></div>
                    )}

                </div>
            </div>
            <div>
                <div className=" w-full py-4    ">
                    {/* {data.map((item, index) => { */}
                    {/* return ( */}
                    <div className="  py-2 group   hover:bg-neutral-light-5 rounded-xl ">
                        <div className="flex  justify-between
                  hover:scale-[0.99] hover:transition-transform hover:duration-200
                "

                        >
                            <div className="flex items-center  lg:w-[100%] md:w-[100%] w-[100%] h-full ml-2 ">


                                <div className="flex mt-1  items-center">
                                    <div className="flex flex-col">
                                        <p className="text-base  text-[#2e343e]  whitespace-nowrap text-[14px] font-medium">
                                            {"Room Name"}
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div className="flex  w-full gap-2 whitespace-nowrap justify-end items-center font-medium hover:bg-neutral-light-5  ">
                                <p>{"₹ 6,874"}</p>
                                {<RateRangeCrad />}
               
                            </div>

                        </div>
                    </div>
                    {/* );
          })} */}
                </div>
                <div className={`h-2 relative rounded-lg`}
                    style={{ background: progressBarColor }}>
                    {lastYearPercentage < currentYearPercentage ? (
                        <div
                            className="h-full bg-[#7fE088] absolute top-0 left-0"
                            style={{ width: `${lastYearPercentage}%` }}
                        ></div>
                    ) :
                        (
                            <div
                                className="h-full bg-red-400 absolute top-0 left-0"
                                style={{ width: `${lastYearPercentage}%` }}
                            ></div>
                        )
                    }

                    {priceDifferencePercentage > 0 ? (
                        <div
                            className="h-full bg-green-600 absolute top-0 "
                            style={{ width: `${priceDifferencePercentage}%`, left: `${priceDifferenceLeft}%` }}
                        ></div>
                    ) :
                        (
                            <div
                                className="h-full bg-red-600 absolute top-0"
                                style={{ width: `${lastYearPercentage + priceDifferencePercentage}%`, left: `${priceDifferenceLeft}%` }}
                            ></div>
                        )
                    }
                    {lastYearPercentage < currentYearPercentage ? (
                        <div
                            className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 "
                            style={{ left: `${lastYearPercentage + priceDifferencePercentage}%` }}
                        ></div>
                    ) : (
                        <div
                            className="w-2 h-6 bg-red-600 border-4 border-red-600 rounded-full absolute -top-[8px] -mt-1/2 "
                            style={{ left: `${lastYearPercentage}%` }}
                        ></div>
                    )}

                </div>
            </div>
        </div>
        </div>
    )
}

export default RoomContributions