// MapComponent.js
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Loader } from '@googlemaps/js-api-loader';

const MapComponent = ({ version, libraries,log,lat }) => {
  const mapStyles = {
    height: '280px',
    width: '100%',
    
  };
const Lat="";
const Log="";


  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const [defaultCenter, setDefaultCenter] = useState({
    lat:22.7457114, 
    lng:75.89214199999999, 
  });

  const apiKey = process.env.apiKey

  useEffect(() => {
    const loader = new Loader({
      apiKey,
      version,
      libraries,
    });

    loader.load().then(() => {
      const center = lat && log ? { lat, lng: log } : defaultCenter;
      const newMap = new window.google.maps.Map(mapContainer.current, {
        center: defaultCenter,
        zoom: 13,
      });

      const newMarker = new window.google.maps.Marker({
        position: defaultCenter,
        map: newMap,
        draggable: false,
      });

      setMap(newMap);
      setMarker(newMarker);

      // Add event listener for marker drag event
      // window.google.maps.event.addListener(newMarker, 'drag', handleMarkerDrag);

      // Add event listener for map click event
      // window.google.maps.event.addListener(newMap, 'click', handleMapClick);

      // Fetch initial address
      const initialPosition = newMarker.getPosition();
      // getAddressFromCoordinates(initialPosition);
    });
  }, [apiKey, version, libraries,defaultCenter]);

  useEffect(()=>{
    lat && log && setDefaultCenter({lat:lat,lng:log})
  },[lat,log])

  // const handleMarkerDrag = () => {
  //   if (marker) {
  //     const newPosition = marker.getPosition();
  //     getAddressFromCoordinates(newPosition);
  //   }
  // };

  // const handleMapClick = (event) => {
  //     // Update marker's position based on the selected location
  //     const selectedLocation = event.latLng;
  //     console.log(selectedLocation)
  //     // Update marker position
  //     setDefaultCenter({
  //       lat: 37.7749, // Default latitude (e.g., San Francisco)
  //       lng: -122.4194, // Default longitude (e.g., San Francisco)
  //     })


  //     // Fetch the address for the selected location
  //    return getAddressFromCoordinates(selectedLocation);
  // };

  // const getAddressFromCoordinates = (location) => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   geocoder.geocode({ location: location }, (results, status) => {
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         setAddress(results[0].formatted_address);
  //       } else {
  //         setAddress('No address found');
  //       }
  //     } else {
  //       setAddress('Geocoder failed due to: ' + status);
  //     }
  //   });
  // };

  return (
    <div >
      <div ref={mapContainer} style={mapStyles} />
    </div>
  );
};

export default MapComponent;
