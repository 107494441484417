import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManageRoomTypes = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoomTypes: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getRoomDetail`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRoomTypes"],
    }),
    addRoomTypes: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addRoomDetail`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addRoomTypes"],
    }),
    editRoomTypes: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editRoomDetail`,
          method: "PATCH",
          body:data?.roomDetail,
          params:{
            hId:data?.hId,
            roomTypeId:data?.roomTypeId
          }
        };
      },
      // Replace with your actual endpoint
      providesTags: ["editRoomTypes"],
    }),
    deleteRoomTypes: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteRoomType`,
          method: "PATCH",
          params:{
            hId:data?.hId,
            roomTypeId:data?.roomTypeId
          }
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteRoomTypes"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetRoomTypesQuery, useAddRoomTypesMutation,useEditRoomTypesMutation, useDeleteRoomTypesMutation } = ChannelManageRoomTypes;
