import React, { useEffect, useState } from "react";
import ChannelCard from "../../channelcard";
import SettingButton from "../../../../components/settingButton/SettingButton";
import { useEditAlertsAndNotificationMutation } from "../../../../redux/slices/settings/alertsNotifications";
import { toast } from "react-toastify";
import { UserId } from "../../../../utils/impVars";
import BackdropLoader from "../../../../components/newLoader";

const ReviewsAndReputation = ({ handleBackButtonClick ,data,refetch}) => {
  const [isLoading,setIsLoading] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null);
  const [newRating, setNewRating] = useState(null);
  const [newReview, setNewReview] = useState(null);
  const [unAnsweredReview, setUnAnsweredReview] = useState(null);
  const [badReviewAlert, setBadReviewAlert] = useState(null);
  const[editAlertsAndNotification] = useEditAlertsAndNotificationMutation()
  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };
  const userId = UserId()

  // const handleBackButtonClick = () => {
  //     setSelectedCard(null)
  // }
  const renderComponent = () => {
    switch (selectedCard) {
      case "New Rating":
      // return <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />;
      case "New Review":
      // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
      case "Unanswered Reviews Reminders":
      // return <RoomType handleBackButtonClick={handleBackButtonClick} />;
      case "Bad Review Alert":
      // return <MealPlan handleBackButtonClick={handleBackButtonClick} />;

      default:
        return null;
    }
  };
 
  useEffect(()=>{    if (newRating != null) {
    changeReviewsRating();
  }},[newRating])
  useEffect(()=>{    if (unAnsweredReview != null) {
    changeReviewsRating();
  }},[unAnsweredReview])
  useEffect(()=>{
    if (badReviewAlert != null) {
      changeReviewsRating();
    }},[badReviewAlert])
    useEffect(()=>{    if (newReview != null) {
      changeReviewsRating();
    }},[newReview])


  function changeReviewsRating() {
    setIsLoading(true)
    const body = {
      reviewAndReputation: {
        newRating: newRating != null ? newRating : data?.newRating,
        newReview:
          newReview != null ? newReview : data?.newReview,
        unanswerReviewAndReminder:
          unAnsweredReview != null ? unAnsweredReview : data?.unanswerReviewAndReminder,
        badReviewAlert:
          badReviewAlert != null ? badReviewAlert : data?.badReviewAlert,
      },
    };
    editAlertsAndNotification({ userId, body })
      .unwrap()
      .then((res) => {
        refetch();
        setIsLoading(false)
        toast.success("Updated Successfully!", { position: "bottom-right" });
      })
      .catch((err) => {
        setIsLoading(false)
      });
  }
  
  return (
    <>
    <BackdropLoader loading={isLoading} />
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col gap-4">
          <SettingButton
            Title={"Reviews & Reputation"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <ChannelCard
                title={"New Rating"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={newRating ? newRating : data?.newRating}
                onChange={(e) => {
                  setNewRating(e.target.checked);
                }}
              />
              <ChannelCard
                title={"New Review"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={newReview ? newReview : data?.newReview}
                onChange={(e) => {
                  setNewReview(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Unanswered Reviews Reminders"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={unAnsweredReview ? unAnsweredReview : data?.unanswerReviewAndReminder}
                onChange={(e) => {
                  setUnAnsweredReview(e.target.checked);
                }}
              />
              <ChannelCard
                title={"Bad Review Alert"}
                onClick={() => handleCardClick("")}
                check={true}
                checked={badReviewAlert ? badReviewAlert : data?.badReviewAlert}
                onChange={(e) => {
                  setBadReviewAlert(e.target.checked);
                }}
              />
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};

export default ReviewsAndReputation;
