import React from "react";
import arrow from "../../assets/icons/Vector (3).svg";
import profile from '../../assets/img/8.svg'

const Profile = ({profilePic,name,designation}) => {
  return (
    <div className="w-[100%] flex justify-between items-center cursor-pointer h-[56px]">
      <div className="w-14 overflow-hidden rounded-full">
        <img
          src={profilePic}
          alt="Profile"
          className="w-full h-full object-cover"
          loading="lazy"
        />
      </div>
      <div className="flex ml-2 w-[100%] cursor-pointer justify-between items-center">
        <div>
          <div className="text-[14px] font-semibold text-[#0D1321]">
            {name}
          </div>
          <div className="text-[12px] font-[400] text-[#8794AD]">
            {designation}
          </div>
        </div>
      </div>
      {/* <div>
        <img src={arrow} loading="lazy" alt="" />
      </div> */}
    </div>
  );
};

export default Profile;
