import React from 'react';

const HotelPerformanceTable = ({ tableData }) => {
  // Check if tableData is valid and contains at least one item
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return <div className="p-4 text-center text-gray-500">No data available</div>;
  }

  // Convert camelCase or PascalCase to words with spaces
  const formatHeader = (header) => {
    return header.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  };

  // Get the headers dynamically from the keys of the first object in the data array
  const headers = Object.keys(tableData[0]);

  return (
    <div className="w-full bg-newGradient dark:bg-lightGradient  text-white rounded-lg overflow-hidden    h-full ">
      {/* Container with a fixed height and scrollable overflow */}
      <div className="w-full h-full  overflow-auto">
        {/* Render the headers */}
        <div className=" sticky top-0 flex bg-newGradient dark:bg-lightGradient
         text-white font-semibold p-4">
          {headers.map((header, index) => (
            <div key={index} className="flex-1 text-center">
              {formatHeader(header.charAt(0).toUpperCase() + header.slice(1))}
            </div>
          ))}
        </div>

        {/* Render the data rows */}
        {tableData.map((item, index) => (
          <div key={index} className="flex  p-4">
            {Object.values(item).map((value, i) => (
              <div key={i} className="flex-1 text-center">
                {value !== null && value !== undefined ? String(value) : 'N/A'}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HotelPerformanceTable;
