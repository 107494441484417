import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Component.css';
import ArrowLeft from '../../assets/icons/onboarding/backbutton.svg';
import { useNavigate } from 'react-router-dom';

const UniversalProgressBar = ({ totalSteps, title, stepCount,setSteps,steps}) => {
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate()

    const handleBack = () => {
        if (currentStep === 2) {
            setSteps({...steps,step1:true,step2:false});
        }
        if(currentStep===3){
            setSteps({...steps,step2:true,step3:false});
        }
        if(currentStep===4){
            setSteps({...steps,step3:true,step4:false});
        }
        if(currentStep===5){
            setSteps({...steps,step4:false,step5:true});
        }
     
      };

      useEffect(() => {
        if (steps) {
            if (steps.step1) {
                setCurrentStep(1)
            }
            if (steps.step2) {
                setCurrentStep(2)
            }
            if (steps.step3) {
                setCurrentStep(3)
            }
            if (steps.step4) {
                setCurrentStep(4)
            }
            if (steps.step5) {
                setCurrentStep(5)
            }
        }
    }, [steps])
    
    

    return (
        <div className="progress-bar-container">
            <div className="progress-header">
                <button className='cursor-pointer' onClick={steps?.step1 === true ? ()=>{navigate(-1)}:handleBack} >
                    <img src={ArrowLeft} alt="" />
                </button>
                <div className="text-[1rem] text-[#0D1321] text-center font-sans font-semibold ">{title}</div>
                <div className="step-count flex flex-col items-center">
                    <p>Step</p>
                    {currentStep}/{totalSteps}
                </div>
            </div>
            <div className="progress-bar">
                {[...Array(totalSteps)].map((_, index) => (
                    <div
                        key={index}
                        className={`progress-bar-step ${index < currentStep ? 'filled' : ''}`}
                        style={{
                            width: `calc(${100 / totalSteps}% - ${2 * (totalSteps - 1)}px)`, 
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
};

UniversalProgressBar.propTypes = {
    totalSteps: PropTypes.number.isRequired,
};

export default UniversalProgressBar;
