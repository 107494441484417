import { emptySplitApi } from "../../injectEndpoints";

export const LoginUser = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    userSignup: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/signUp`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["userSignup"],
    }),
    userLogin: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/login`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["userLogin"],
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/verifyOtp`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["verifyOtp"],
    }),
    propertyDetails: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/requestProperty`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["propertyDetails"],
    }),
    addCmCred: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/addCmCred`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["addCmCred"],
    }),
    verifyCM: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/verifyCM`,
        method: "POST",
        body: data,
      }),
      // Replace with your actual endpoint
      invalidatesTags: ["verifyCM"],
    }),
    addNewCompset: builder.mutation({
      query: (data) => ({
        url: `/userDashboardSetting/addMoreCompset`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["addNewCompset"],
    }),
    
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/onBoarding/forgotPassword`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["forgotPassword"],
    }),
    channelManagerList: builder.query({
      query: (data) => ({
        url: '/onBoarding/channelManagerList',
        params: data,
      }),
      providesTags: ['channelManagerList']
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/onBoarding/resetPassword',
        method: "PATCH",
        body: data,
      }),
      providesTags: ['resetPassword']
    }),
    getOtaLinks: builder.mutation({
      query: (data) => ({
        url: '/onBoarding/getOtaLinks',
        method: "POST",
        body: data,
      }),
      providesTags: ['getOtaLinks']
    }),
    gethotelId: builder.mutation({
      query: (data) => ({
        url: '/onBoarding/gethotelId',
        method: "POST",
        body: data,
      }), 
      providesTags: ['gethotelId']
    }),
    
  }),
  overrideExisting: false,
});

export const {useUserSignupMutation , useVerifyOtpMutation ,useChannelManagerListQuery,useGetOtaLinksMutation,
  usePropertyDetailsMutation , useUserLoginMutation,useForgotPasswordMutation, useResetPasswordMutation,useGethotelIdMutation,
  useAddCmCredMutation ,useVerifyCMMutation ,useAddNewCompsetMutation} =LoginUser;

  