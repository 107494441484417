import { format } from "date-fns";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Calendar, DateRangePicker } from "react-date-range";
import { AiFillCalendar } from "react-icons/ai";
import { toast } from "react-toastify";

const TimeRange = ({
  inputValue,
  setInputValue,
  period,
  setPeriod,
  left,
  calendarOpen,
  setCalendarOpen,
  isFuture = false, // Default to false for past dates
  width,
  setShowDropDown,
  showDropDown,
  minDate,
  maxDate,
}) => {
  
  const [dropdownOptions, setDropdownOptions] = useState([
    isFuture ? "Next 7 days" : "Last 7 days",
    isFuture ? "Next 14 days" : "Last 14 days",
    isFuture ? "Next 30 days" : "Last 30 days",
    isFuture ? "Next 90 days" : "Last 90 days",
    "Custom Date",
  ]);
  // const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    setShowDropDown(true);
    const value = e.target.value.toLowerCase();
    setInputValue(
      !/^[a-zA-Z]/.test(value) ? value : alert("Please enter a valid input !")
    );

    // Handle custom time range
    let customOptions = [];

    if (!isNaN(value)) {
      customOptions = [
        `${isFuture ? "Next" : "Last"} ${value} days`,
        `${isFuture ? "Next" : "Last"} ${value} weeks`,
        `${isFuture ? "Next" : "Last"} ${value} months`,
        `${isFuture ? "Next" : "Last"} ${value} years`,
      ];
    } else {
      customOptions = [
        `${isFuture ? "Next" : "Last"} ${value} day${value !== "1" ? "s" : ""}`,
        `${isFuture ? "Next" : "Last"} ${value} week${
          value !== "1" ? "s" : ""
        }`,
        `${isFuture ? "Next" : "Last"} ${value} month${
          value !== "1" ? "s" : ""
        }`,
        `${isFuture ? "Next" : "Last"} ${value} year${
          value !== "1" ? "s" : ""
        }`,
      ];
    }

    setDropdownOptions(customOptions);
  };

  const handleSelect = (option) => {
    setInputValue(option);

    // Define currentDate here
    const currentDate = new Date();
    let newEndDate;

    // Define minDate and maxDate
    const minDateSelect = new Date(minDate);
    const maxDateSelect = new Date(maxDate);

    // Function to calculate date with validation against minDate and maxDate
    const calculateDate = (start, value, unit) => {
      let endDate;

      switch (unit) {
        case "days":
          endDate = new Date(start.getTime() + value * 24 * 60 * 60 * 1000);
          break;
        case "weeks":
          endDate = new Date(start.getTime() + value * 7 * 24 * 60 * 60 * 1000);
          break;
        case "months":
          endDate = new Date(
            start.getFullYear(),
            start.getMonth() + value,
            start.getDate()
          );
          break;
        case "years":
          endDate = new Date(
            start.getFullYear() + value,
            start.getMonth(),
            start.getDate()
          );
          break;
        default:
          break;
      }

      // Validate against minDate and maxDate
      if (endDate < minDateSelect || endDate > maxDateSelect) {
        toast.error(
          `Selected period (${option}) is outside the allowed date range.`,
          { position: "bottom-right" }
        );
        setInputValue(null);
        return null;
      }

      return endDate;
    };

    if (option.startsWith("Last")) {
      // Handle Last X days/weeks/months/years
      const value = parseInt(option.split(" ")[1]);
      const timeUnit = option.split(" ")[2];

      newEndDate = calculateDate(currentDate, -value, timeUnit);

      if (newEndDate) {
        setPeriod([{ startDate: newEndDate, endDate: currentDate }]);
      }
    } else if (option.startsWith("Next")) {
      // Handle Next X days/weeks/months/years
      const value = parseInt(option.split(" ")[1]);
      const timeUnit = option.split(" ")[2];

      newEndDate = calculateDate(currentDate, value, timeUnit);

      if (newEndDate) {
        setPeriod([{ startDate: currentDate, endDate: newEndDate }]);
      }
    }

    setCalendarOpen(false);
    setShowDropDown(false);
  };

  const handleFocus = () => {
    if (!showDropDown) {
    setShowDropDown(true);
    setInputValue("");
    setDropdownOptions(
      isFuture
        ? [
            "Next 7 days",
            "Next 14 days",
            "Next 30 days",
            "Next 90 days",
            "Custom Date",
          ]
        : [
            "Last 7 days",
            "Last 14 days",
            "Last 30 days",
            "Last 90 days",
            "Custom Date",
          ]
    );
  }
  };

  const [range, setRange] = useState();

  const handleSelectDate = (ranges) => {
    setRange(ranges);
    setInputValue(null);
    try {
      setInputValue(
        `${format(
          new Date(ranges?.range1?.startDate || ranges?.range2?.startDate),
          "dd-MMM-yy"
        )} - ${format(
          new Date(ranges?.range1?.endDate || ranges?.range2?.endDate),
          "dd-MMM-yy"
        )}`
      );
      if (ranges?.range1 || ranges?.range2) {
        setPeriod([ranges?.range1 || ranges?.range2]);
        // setCalendarOpen(false)
      }
    } catch (error) {
      console.error("Error selecting date range: ", error);
    }
  };

  useEffect(() => {
    if (inputValue === "Custom Date") {
      setCalendarOpen(true);
    }

    if (!inputValue) {
      setInputValue("");
      setDropdownOptions(
        isFuture
          ? [
              "Next 7 days",
              "Next 14 days",
              "Next 30 days",
              "Next 90 days",
              "Custom Date",
            ]
          : [
              "Last 7 days",
              "Last 14 days",
              "Last 30 days",
              "Last 90 days",
              "Custom Date",
            ]
      );
    }
  }, [inputValue]);

  useEffect(() => {
    if (calendarOpen) {
      setDropdownOptions([]);
    }
  }, [calendarOpen]);

  return (
    <div className="relative w-[100%]">
      <div className="flex" ref={dropdownRef}>
        <input
          className={`bg-white/30 text-white rounded px-4 text-sm placeholder:text-sm py-2 mr-2`}
          type="text"
          placeholder="Start typing number or select date"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          style={{ width: `${width}px` }}
        />
      </div>

      {(inputValue || dropdownOptions.length > 0) && showDropDown && (
        <ul
          className="absolute z-10 mt-2 w-full border rounded-b bg-white shadow-lg"
          ref={dropdownRef}
        >
          {dropdownOptions.map((option, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}

      {calendarOpen && (
        <div className={`absolute z-50 flex mt-4`} style={{ left: left }}>
          <DateRangePicker
            onChange={handleSelectDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={period}
            direction="horizontal"
            minDate={isFuture ? new Date() : minDate ? minDate : undefined}
            maxDate={maxDate && maxDate}
          />
        </div>
      )}
    </div>
  );
};

export default TimeRange;
