import React from "react";
import Img from '../../assets/img/empty.png'

const NoDataFound = ({}) => {
  return (
    <div className="grid justify-center mt-10 text-gray-500">
      <div className="w-full text-center text-black dark:text-white">Data not found.</div>
      <div>
        <img className="w-24 m-6" src={Img} alt="" />
      </div>
    </div>
  );
};

export default NoDataFound;
