
import { formatDate } from "./formatDate.js";
import { logoUrl } from "./retvensLogo.js";
import ExcelJS from "exceljs"


export const DownloadPaceReport  = async (temp,) => {
 try{

 
  const DATA3 = [];
  DATA3.push(temp)


  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl
  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:G3");
  worksheet.addImage(logoId, "A1:A3");
  const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
  const columnWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

  // Display today's date at J1   
  const dateCell = worksheet.getCell("G1");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";

  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Pace Report - ${DATA3[0].hotelName}  (${formatDate(DATA3[0].asOn?.startDate)} - ${formatDate(DATA3[0].asOn?.endDate)})`;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  worksheet.mergeCells("A4", "G4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "G5");
  DATA3.forEach((item) => {


    const { bookingOnMonth, weeklyArrivals, weeklyReservation, weeklyRoomNights } = item;
    const { bookingsThisMonth, lastYearThisMonthBookings, bookingLastMonth, lastYearLastMonthBookings, perDayBookingThisMonth, perDayBookingThisMonthLastYear, perDayBookingLastMonth, perDayBookingLastMonthLastYear } = bookingOnMonth;

    worksheet
      .addRow([
        "Bookings This Month",
        "",
        "Last Year \nThis Month Booking",
        "",
        "Bookings \nLast Month",
        "",
        "Last Year \nLast Month Bookings",
      ])
      .eachCell((cell) => {
        if (cell.value != "") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center",wrapText: true, };
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } }, // Black color
            left: { style: "thin", color: { argb: "000000" } }, // Black color
            bottom: { style: "thin", color: { argb: "000000" } }, // Black color
            right: { style: "thin", color: { argb: "000000" } } // Black color
          };
        }
      });
    worksheet
      .addRow([
        bookingsThisMonth,
        "",
        lastYearThisMonthBookings,
        "",
        bookingLastMonth,
        "",
        lastYearLastMonthBookings,
      ])
      .eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    worksheet.addRow([]);


    worksheet
      .addRow([
        "Per Day Bookings \nThis Month",
        "",
        "Per Day Bookings\n This Month Last Year",
        "",
        "Per Day Booking\nLast Month",
        "",
        "Per Day Booking\nLast Month Last Year"
      ])
      .eachCell((cell) => {
        if (cell.value != "") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" ,wrapText: true,};
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } }, // Black color
            left: { style: "thin", color: { argb: "000000" } }, // Black color
            bottom: { style: "thin", color: { argb: "000000" } }, // Black color
            right: { style: "thin", color: { argb: "000000" } } // Black color
          };
        }
      });
    worksheet
      .addRow([
        perDayBookingThisMonth,
        "",
        perDayBookingThisMonthLastYear,
        "",
        perDayBookingLastMonth,
        "",
        perDayBookingLastMonthLastYear
      ])
      .eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    worksheet.addRow([]);
    
    worksheet.addRow(["Arrival This month "]).eachCell((cell => {

      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };

    }))
    var lastRowNumber = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber}`, `E${lastRowNumber}`);

    worksheet.addRow(Object.keys( weeklyArrivals[0]).map((key) =>
      key.replace(/([A-Z])/g, " $1").trim()
    )).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    weeklyArrivals.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });


    worksheet.addRow([])

    worksheet.addRow(["Reservation Pace"]).eachCell((cell => {

      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    }))
    var lastRowNumber1 = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber1}`, `G${lastRowNumber1}`);
    worksheet.addRow([
      "",
      "This Month",
      "",
      "Last Year",
      "",
      "Last Month",
      "",

    ]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
        border: true,
        borderColor: "00000000",// Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    // const newRowValues = [" ", "This Month", "Last Year", "Last Month"];

    // const newRow = worksheet.addRow(newRowValues);


    worksheet.mergeCells(`B${lastRowNumber1 + 1}`, `C${lastRowNumber1 + 1}`);
    worksheet.mergeCells(`D${lastRowNumber1 + 1}`, `E${lastRowNumber1 + 1}`);
    worksheet.mergeCells(`F${lastRowNumber1 + 1}`, `G${lastRowNumber1 + 1}`);


    worksheet.addRow(Object.keys(weeklyReservation[0]).map((key) =>
      key.replace(/([A-Z])/g, " $1").trim()
    )).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center",
      wrapText: true, };
    });

    weeklyReservation.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });


    worksheet.addRow([])

    worksheet.addRow(["Room Nights"]).eachCell((cell => {

      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };

    }))
    var lastRowNumber2 = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber2}`, `G${lastRowNumber2}`);
    worksheet.addRow([
      "",
      "This Month",
      "",
      "Last Year",
      "",
      "Last Month",
      "",

    ]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
        border: true,
        borderColor: "00000000",// Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });



    worksheet.mergeCells(`B${lastRowNumber2 + 1}`, `C${lastRowNumber2 + 1}`);
    worksheet.mergeCells(`D${lastRowNumber2 + 1}`, `E${lastRowNumber2 + 1}`);
    worksheet.mergeCells(`F${lastRowNumber2 + 1}`, `G${lastRowNumber2 + 1}`);
    // worksheet.mergeCells(`A${lastRowNumber}`,`I${lastRowNumber}`);
    worksheet.addRow(Object.keys(weeklyRoomNights[0]).map((key) =>
      key.replace(/([A-Z])/g, " $1").trim()
    )).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } } // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center",
      wrapText: true, };
    });
    weeklyRoomNights.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  
  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);
  
  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Pace Report ${DATA3[0]?.hotelName } .xlsx`; // Set the desired filename here
  a.click();
  
  // Clean up
  window.URL.revokeObjectURL(url);}
  catch(error) {
    alert("no enough data found")
    console.error("Error creating Excel file:", error);
  }
};
