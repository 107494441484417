const TableButton = ({ title, selectedIndex, onClick, index, cancel }) => {
  return (
    <div
      className={`w-auto cursor-pointer justify-center flex items-center font-[500] text-[12px] h-[28px] ${cancel ? "bg-[#EEF1F6] text-[#0D1321]" : "bg-[#1D2D44] text-white"} rounded-lg px-3`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default TableButton;