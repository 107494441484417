import React, { useEffect } from "react";
import otas from "../../assets/icons/5.svg";
import RateRangeCrad from "../rateRangeCard";
import OtaRateRangeCard from "../otaRateRangwCard/OtaRateRangeCard";
import formatCurrency from "../../utils/formatCurrency";

const OtaCards = ({
  selectedIndex,
  onClick,
  index,
  price,
  title,
  percentageDifference,
  rank,
  otaLogo,
  isCurrency,
  range,
  isPercentage,
  totalCount,
  totalCountState = false,
  // setAvgCount
}) => {
  range = range !== null && range !== undefined ? range : true;
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  const color = [
    "#1abc9c",
    "#f1c40f",
    "#2ecc71",
    "#2980b9",
    "#fad390",
    "#f8c291",
    "#60a3bc",
    "#4a69bd",
  ];
  // useEffect(()=>{
  //   if(selectedIndex === index){
  //     setAvgCount(rank)
  //   }
  // },[selectedIndex])
  return (
    <div
      key={index}
      onClick={onClick}
      className={` ${selectedIndex === index ? "bg-[#4268e53d] border-[#C1CEFF] " : "bg-newGradient"}  dark:bg-lightGradient drop-shadow-lg backdrop-blur-md group cursor-pointer flex items-center  min-w-[220px] w-[10%]  rounded-2xl h-16`}
    >
      <div className="flex items-center gap-2">
        <div className="flex ml-2">
          {otaLogo ? <img
            loading="lazy"
            src={otaLogo}
            alt="OTA Icon"
            className="h-10 rounded-lg"
          /> :
            <div
              className={`w-8 h-8 uppercase flex rounded-lg justify-center text-white items-center text-sm`}
              style={{ background: color.random() }}
            >
              {title?.charAt(0)}
            </div>
          }
        </div>
        <div className="flex">
          <div>
            <div className="dark:text-neutral-light-14 text-[#ffffff] text-[12px] w-[150px] truncate">
              {title || "--"}
            </div>
            <div className="flex gap-2">
              <div className="text-[#ffffff] text-xs md:text-[1vw]">
                {isCurrency ? formatCurrency(price) : rank || price}

                {totalCountState && <span className="text-sm text-gray-100">/{totalCount}</span>}
              </div>
              {range && (
                <div className="flex items-center">
                  {isPercentage && <div className={`text-xs md:text-[1vw] ${percentageDifference?.toString().includes('-')?'text-red-500':'text-green-500'} `}>{percentageDifference}%</div>
                  
                  // (
                  //   <OtaRateRangeCard
                  //     selectedIndex={selectedIndex}
                  //     index={index}
                  //     percentageDifference={percentageDifference}
                  //   />
                  // )
                  
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtaCards;
