import { useEffect, useState } from "react";
import { useGetRoomTypesQuery } from "../../../../redux/slices/settings/channelManager/roomTypes";
import {
  useGetMappedPropertyRoomsQuery,
  useGetOtaRoomMappingDetailsQuery,
  useGetPropertyMappingDetailsQuery,
  useGetPropertyRoomDetailsQuery,
} from "../../../../redux/slices/settings/roomMapping";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import { AiOutlineDown } from "react-icons/ai";
import RenderRooms from "../../../../components/roomMapping/renderRooms/renderRooms";
import { useGetCompsetDetailsQuery } from "../../../../redux/slices/settings/myProperties";
import {
  useAddOtaRoomMappingMutation,
  useUpdateOtaRoomMappingMutation,
} from "../../../../redux/slices/rooms";
import Dropdown from "../../../../components/dropdown";
import { toast } from "react-toastify";

const Hotel2RoomMapping = ({ handleBackButtonClick, type, hId }) => {
  const [hotelId, setHotelId] = useState(CurrentProperty());
  const [loading, setLoading] = useState(false);

  const [addOtaRoomMapping] = useAddOtaRoomMappingMutation();
  const [updateOtaRoomMapping] = useUpdateOtaRoomMappingMutation();

  const [selectedType, setSelectedType] = useState();
  const [apiCompsetDetails, setApiCompsetDetails] = useState();

  const { data: compsetDetails } = useGetCompsetDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: roomTypes,
    isLoading,
    isFetching,
    refetch,
  } = useGetRoomTypesQuery(
    {
      userId: UserId(),
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (type === "compset") {
      const compsetNames =
        compsetDetails &&
        compsetDetails?.data &&
        compsetDetails?.data?.compSetDetails.map((compset) => {
          return {
            value: compset.hId,
            label: compset.propertyName,
          };
        });
      setApiCompsetDetails(compsetNames);
      compsetNames &&
        compsetNames.length > 0 &&
        setHotelId(compsetNames[0].value);
    } else if (type === "your") {
    }
  }, [selectedType, type, compsetDetails]);

  const { data } = useGetPropertyRoomDetailsQuery(
    {
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: getOtaRoomMappingDetails } = useGetPropertyMappingDetailsQuery(
    {
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const otaNames =
    data &&
    data?.data.map((ota) => {
      return ota?.otaName;
    });

  const otaRooms =
    data &&
    data?.data.map((ota) => {
      return ota?.rooms;
    });

  const convertRoomObject = (roomArray) => {
    const newArr =
      roomArray &&
      roomArray.map((item) => {
        return {
          label: item?.roomName || item?.roomTypeName,
          value: item?.roomID || item?.roomTypeId,
        };
      });
    return newArr;
  };
  function getCompsetDefaultRooms() {
    let count = 0;
    let highestRoomCountCompset = 0;
    let defaultRoom = [];
    data &&
      data.data.map((compsetRoom, id) => {
        if (compsetRoom.rooms.length > count) {
          count = compsetRoom.rooms.length;
          highestRoomCountCompset = id;
        }
      });
    data &&
      data.data[highestRoomCountCompset].rooms.map((room, roomId) => {
        defaultRoom.push({
          label: room.roomName,
          value: room.roomID,
        });
      });
    return defaultRoom;
  }

  const defaultRoom =
    type === "your"
      ? roomTypes && convertRoomObject(roomTypes?.data)
      : getCompsetDefaultRooms();

  const [activeRooms, setActiveRooms] = useState(
    getOtaRoomMappingDetails?.data?.activeRooms
  );

  useEffect(() => {
    if (!getOtaRoomMappingDetails?.data?.activeRooms) {
      let otaArray = [];
      let resultForm = [];
      data &&
        data.data &&
        data?.data.map((otaData, otaDataId) => {
          otaArray.push({
            otaId: otaData.otaId,
            otaRId: "",
          });
        });
      defaultRoom &&
        defaultRoom.map((room, roomID) => {
          resultForm.push({
            roomId: room.value,
            roomName: room.label,
            otas: otaArray,
          });
        });
      setActiveRooms(resultForm);
    } else {
      setActiveRooms(getOtaRoomMappingDetails?.data?.activeRooms);
    }
  }, [selectedType, roomTypes, hotelId, getOtaRoomMappingDetails]);

  function findHotelNameById(hotels, hId) {
    const foundRoom =
      hotels && hId && hotels.find((hotel) => hotel.value === hId);
    return foundRoom ? foundRoom.label : "";
  }

  function onSubmit() {
    updateOtaRoomMapping({
      hId: hotelId,
      activeRooms: activeRooms,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right h-5 w-5" });

        // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
        // localStorage.setItem('otaImage', JSON.stringify(otaImage));
        //  localStorage.setItem('otaName', JSON.stringify(otaName));

        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });

    // addOtaRoomMapping({
    //   hId: hotelId,
    //   activeRooms: activeRooms,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res.message, { position: "bottom-right h-5 w-5" });

    //     // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
    //     // localStorage.setItem('otaImage', JSON.stringify(otaImage));
    //     //  localStorage.setItem('otaName', JSON.stringify(otaName));

    //     setLoading(false);
    //   })

    //   .catch((err) => {
    //     toast.error(err.message, { position: "bottom-right" });
    //     setLoading(false);
    //   });
  }

  return (
    <div>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <div
              onClick={handleBackButtonClick}
              className=" rotate-90 bg-[#1D2D44] p-2 cursor-pointer rounded-lg"
            >
              <AiOutlineDown size={20} color="white" />
            </div>
            <div className=" text-lg flex items-center">
              {type === "your"
                ? "Your Hotel Room Mapping"
                : "Compset Room Mapping"}{" "}
              By OTA
            </div>
          </div>
          <div className="flex flex-row">
            {activeRooms && activeRooms.length > 0 && (
              <button onClick={onSubmit}>
                <div className="w-fit px-4 outline outline-blue-500 text-blue-500 rounded-md cursor-pointer hover:text-white hover:bg-blue-600 hover:outline-none duration-150 transition-all ease-in-out">
                  {getOtaRoomMappingDetails ? "Update" : "Save"} 
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
      {data && defaultRoom && activeRooms && activeRooms.length > 0 ? (
        <div className="flex justify-between gap-6 max-w-[100%] min-w-[100%] p-2 max-h-[400px] overflow-scroll">
          <div className="w-[100%] max-w-fit min-w-fit">
            <div>Default Room</div>
            <div className="mt-2 flex flex-col gap-4">
              {activeRooms?.map((room, roomIdd) => {
                return (
                  <div
                    key={roomIdd}
                    className={`h-10 outline w-[100%]  justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500`}
                  >
                    {room?.roomName}
                  </div>
                );
              })}
            </div>
          </div>
          {otaNames &&
            otaNames.map((ota, index) => {
              return (
                <div
                  className="w-[100%]"
                  key={`${index}r${ota}`}
                  id={`${index}r${ota}`}
                >
                  <div className="text-[16px] mb-2 ">{ota}</div>
                  <RenderRooms
                    data={data}
                    defaultRoom={defaultRoom}
                    index={index}
                    activeRooms={activeRooms}
                    setActiveRooms={setActiveRooms}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          <p> Add rooms in channel manager to start room mapping</p>
        </div>
      )}
    </div>
  );
};

export default Hotel2RoomMapping;
