// SimpleButton.js
import React from 'react';

const SimpleButton = ({ text, onClick,onSubmit,type ,disabled }) => {
    const styles = {
        fontSize: '17px',
        padding: "10px 0px",
        width: '100%',
        background: '#081439',
        color: '#FFFFFF',
        borderRadius: '5px',
        fontWeight:600,
    }
return (
    <button className={`${disabled?" cursor-not-allowed":" cursor-pointer"}`} style={styles} onClick={onClick} onSubmit={onSubmit} type={type} disabled={disabled}  >
        {text}
    </button>
);
};

export default SimpleButton;
