import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import daysArray from "../../utils/eNums/daysArray";

// const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

const ColumnChart = ({
  height,
  singleSeries,
  currentSeriesData,
  currenSeriesName,
  pastSeriesData,
  perviousLabel,
  currentLabel,
  isCurrency,
  showSingleSeries,
  textWhite
}) => {
  // Sample data for the chart

  //   const labelColors = ['#FF5733', '#33FF57', '#5733FF', '#FF5733', '#33FF57'];
  //   const randomColors = [30, 40, 25, 50, 49].map(() => getRandomColor());
  const distributedColors = [
    "#FF5733",
    "#33FF57",
    "#5733FF",
    "#FF5733",
    "#33FF57",
  ];

  const chartData = {
    options: {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      chart: {
        width: "100%",
        id: "column-bar-chart",
        type: "bar",
        toolbar: {
          show: false, // Hide the toolbar
        },
        background: "transparent",
        sparkline: {
          enabled: false,
        },
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return '<div class="custom-tooltip">' +
            '<span>' + currentLabel + '</span>' +
            '<span>' + w.config.series[seriesIndex].data[dataPointIndex] + '</span>' +
            '</div>';
        }
      },
      colors: distributedColors,
      plotOptions: {
        bar: {
          borderRadius: 5, // Make bars rounded
          distributed: false, // Display bars evenly distributed
          horizontal: false, // Display horizontal bars
          // columnWidth: '50%', // Set the width of the columns
          rangeBarOverlap: false,
          //   background: ,
          // columnWidth: '80%',
          // Adjust the gap between the series by setting a negative value
          // For example, -20 will create a 20px gap between series
          // hover: {
          //   opacity: 1, // set opacity when hovering
          //   background: "#4CAF50", // set background color when hovering
          // },
        },
        // states: {
        //   hover: {
        //     color: "#4CAF50", // Change color on hover
        //     borderColor: "transparent", // Disable hover border
        //     background: "#69CA6F", // Set hover background color
        //   },
        // },
      },
      colors: ["#ADD7EF", "#BAEDBD"],

      xaxis: {
        categories: currenSeriesName ? currenSeriesName : daysArray,
        crosshairs: {
          show: false, // <--- HERE
        },
        axisBorder: {
          show: false, // Hide x-axis ruler line
        },
        labels: {
          show: true, // Hide x-axis labels
          trim:true,
          style: {
            colors: "#7D889E", // Set the color for x-axis labels
          },
          style: {
            colors: textWhite && '#FFFFFF', // Sets the text color to white
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false, // Hide y-axis ruler line
        },
        labels: {
          show: true, // Hide y-axis labels
          style: {
            colors: "#7D889E",
          },
          style: {
            colors: textWhite && '#FFFFFF', // Sets the text color to white
          },
        },
      },
      grid: {
        show: false, // Hide grid lines
      },
      fill: {
        colors: ["#FFFFFF", "#FFFFFF"], // Set random colors for bars
      },
      stroke: {
        colors: ["transparent"],
        width: 5,
      },
    },
    series: singleSeries
      ? [
          {
            name: "Series 1",
            data: currentSeriesData
              ? currentSeriesData
              : [30, 40, 25, 50, 49, 23, 63],
          },
        ]
      : [
          {
            name: "Series 1",
            data: currentSeriesData
              ? currentSeriesData
              : [30, 40, 25, 50, 49, 23, 63],
          },
          {
            name: "Series 2",
            data: pastSeriesData
              ? pastSeriesData
              : [20, 10, 55, 70, 29, 43, 63],
          },
        ],
  };

  useEffect(() => {
    const div = document.getElementsByClassName(
      "apexcharts-canvas apexchartscolumn-bar-chart"
    );
    if (div) {
      for (let i = 0; i < div.length; i++) {
        div[i].parentElement.style["min-height"] = `${height}px`;
      }
    }
  }, []);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      width={"100%"}
      height={height}
    />
  );
};

export default ColumnChart;
