import React from "react";
import ColumnChart from "../charts/columnChart";
import Dropdown from "../dropdown";

const ColumnChartComponent = ({
  title,
  height,
  singleSeries,
  showFilters,
  showLegends,
  onClick,
  currentSeriesData,
  currenSeriesName,
  pastSeriesData,
  isPermission = true
}) => {
  const [selectedItem, setSelectedItems] = React.useState();
  const option = [
    {
      label: "Today",
      value: "Today",
    },
    {
      label: "Yesterday",
      value: "Yesterday",
    },
    {
      label: "This Week",
      value: "This Week",
    },
  ];
const color = ["#3c40c6", "#808e9b"]
  return (
    <div className="relative">
    <div
      className={`bg-[#F6F8FB] dark:bg-[#252C3B] w-[100%] p-4 rounded-2xl cursor-pointer ${!isPermission ?'blur-sm':''}`}
      onClick={isPermission ? onClick : ()=>null}
    >
      <div>
        <div className="flex justify-between items-center">
          <div className="text-[#131926]">{title || "Demand Outlook"}</div>

          <div className=" flex gap-2">
            {showLegends && (
              <div className="flex gap-6">
                <div className="flex items-center gap-2">
                  {" "}
                  <div className="w-2 rounded-full bg-[#3c40c6] h-2"></div>
                  <div>Actual</div>
                </div>
                <div className="flex items-center gap-2">
                  {" "}
                  <div className="w-2 rounded-full bg-[#808e9b] h-2"></div>
                  <div>Predicted</div>
                </div>
              </div>
            )}
            {showFilters && <Dropdown />}
          </div>
        </div>
        <div></div>
      </div>
      <ColumnChart
        height={height}
        singleSeries={singleSeries}
        currentSeriesData={currentSeriesData}
        currenSeriesName={currenSeriesName}
        pastSeriesData={pastSeriesData}
      />
    </div>
    </div>
  );
};

export default ColumnChartComponent;
