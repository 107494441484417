import { format } from "date-fns";
import React, { useState } from "react";

const ReviewCard = ({
  name,
  time,
  description,
  rating,
  otaLogo,
  otaColor,
  totalRating,
  responses,
  showRepliesIndex,
  index,
  onClick,
  keywords,
}) => {
  const [viewAll, setViewAll] = useState();
  return (
    <div
      className={`relative z-10 p-4 border-l-4  bg-[#F6F8FB] w-[100%] rounded-2xl lg:h-auto mb-4 h-auto`}
      style={{ borderColor: otaColor }}
    >
      <div className="flex flex-wrap w-[100%] items-center justify-between">
        <div className="flex flex-wrap items-center">
          <div className="rounded-full overflow-hidden border w-[30px] h-[30px]">
            <img src={otaLogo} width={"100%"} loading="lazy" />
          </div>
          <div className="text-[14px] ml-2">{name}</div>
        </div>
        <div className="text-[12px] text-[#7C8AA6]">{time}</div>
      </div>
      <div>
        <p className="text-[12px] text-justify mt-2 text-[#131926]">
          {description}
        </p>
        <div className="w-[100%] flex items-center justify-between">
          <div className="bg-[#CABDFF] px-4 py-1 w-[50px] text-[12px] flex justify-center rounded-md mt-1">
            {rating}/{totalRating}
          </div>
          <div
            onClick={onClick}
            className="text-sm text-[#383E49] cursor-pointer"
          >
            {showRepliesIndex === index ? "Hide Reply" : "Show Reply"}
          </div>
          {/* <div className='text-[#772DE0] text-[12px] cursor-pointer'>Read More</div> */}
        </div>
        {showRepliesIndex === index && (
          <div className={`bg-[#E8F3FF]  text-sm my-2 w-[100%] p-4 rounded-lg`}>
            <div className="text-xs text-[#8C8E90]">
              {format(
                new Date(responses?.responseDate),
                "dd-MMM-yyyy HH:mm:ss"
              )}
            </div>
            <div className="text-justify">{responses?.responseText}</div>
          </div>
        )}
        {keywords?.positive?.length > 0 && (
          <div className="flex gap-4 items-center overflow-x-scroll mt-4">
            <span className="text-sm text-gray-600 bg-[#F6F8FB] sticky left-0 py-1 pr-2 scrollHide whitespace-nowrap">
              {" "}
              Positive keywords{" "}
            </span>
            {keywords?.positive &&
              keywords?.positive?.map((itm) => {
                return (
                  <div
                    className={`px-2  transition-all ease-in-out duration-200 py-1 w-fit text-xs rounded-lg bg-[#FFFFFF] border border-[#2D354670] text-[#2D354678]`}
                  >
                    {itm}
                  </div>
                );
              })}
          </div>
        )}
        {keywords?.neutral.length > 0 && (
          <div className="flex gap-4 items-center overflow-x-scroll mt-4">
            <span className="text-sm text-gray-600 bg-[#F6F8FB] sticky left-0 py-1 pr-2 scrollHide whitespace-nowrap">
              {" "}
              Neutral keywords{" "}
            </span>
            {keywords?.neutral &&
              keywords?.neutral?.map((itm) => {
                return (
                  <div
                    className={`px-2  transition-all ease-in-out duration-200 py-1 w-fit text-xs rounded-lg bg-[#FFFFFF] border border-[#2D354670] text-[#2D354678]`}
                  >
                    {itm}
                  </div>
                );
              })}
          </div>
        )}
        {keywords?.negative?.length > 0 && (
          <div className="flex gap-4 items-center overflow-x-scroll mt-4">
            <span className="text-sm text-gray-600 bg-[#F6F8FB] sticky left-0 py-1 pr-2 scrollHide whitespace-nowrap">
              {" "}
              Negative keywords{" "}
            </span>
            {keywords?.negative &&
              keywords?.negative?.map((itm) => {
                return (
                  <div
                    className={`px-2 transition-all ease-in-out duration-200 py-1 w-fit text-xs rounded-lg bg-[#FFFFFF] border border-[#2D354670] text-[#2D354678]`}
                  >
                    {itm}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <hr className="mt-2" />
      <div className="mt-2 flex items-center gap-2"></div>
    </div>
  );
};

export default ReviewCard;

{
  /* <div
          className={`px-2 cursor-pointer transition-all ease-in-out duration-200 py-1 w-fit text-xs rounded-lg bg-[#FFFFFF] border border-[#2D354670] text-[#2D354678]`}
        >
          Shortage
        </div> */
}
