
import { format } from "date-fns";
import { formatDate } from "./formatDate.js";
import { logoUrl } from "./retvensLogo.js";
import ExcelJS from "exceljs"


export const DownloadSourceReport  = async (DATA3,sourceDateRange) => {

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl
  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:I3");
  worksheet.addImage(logoId, "A1:A3");
  const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const columnWidths = [15, 15, 15, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

  // Display today's date at J1
  const dateCell = worksheet.getCell("I1");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";
  dateCell.alignment = { vertical: "middle"};
  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Source Report - ${DATA3.hotelName} (${formatDate(sourceDateRange[0]?.startDate || new Date()) } -${formatDate(sourceDateRange[0]?.endDate || new Date()) } )  `;


  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  worksheet.mergeCells("A4", "I4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "I5");
  worksheet.addRow(Object.keys(DATA3?.sourceData[0])).eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "B7D166" },
    };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } }, // Black color
      left: { style: "thin", color: { argb: "000000" } }, // Black color
      bottom: { style: "thin", color: { argb: "000000" } }, // Black color
      right: { style: "thin", color: { argb: "000000" } } // Black color
    };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });
  DATA3?.sourceData.forEach((item,index) => {
      worksheet.addRow(Object.values(item)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });


  });
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Source Report ${DATA3?.hotelName } .xlsx`; // Set the desired filename here
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
}
