import React from 'react'
import ColumnChartComponent from '../../components/columnChartComponent'
import EventCalendar from '../../components/eventCalendar'
import AreaChart from '../../components/charts/areaChart'

const DemandOutlook = () => {
     const[showBar,setShowBar] = React.useState(false)
    const showFilters = true;
    const showTitle = true;
    const data=[
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },
        {
            eventName:"Event Name",
            eventCategory:"Event Category",
            date:"18 Feb",
            time:"10:00 AM"
        },

    ]
  return (
    <div className=" w-[100%]">
     <div className='flex gap-4 p-4 overflow-hidden'>
    <div className='w-[70%] flex-col '>
        <div className='mb-4 bg-[#F6F8FB] rounded-2xl'>
      {<AreaChart height={"380px"} title={"Demand Trend"} showTitle={showTitle} showFilters={showFilters} onClick1={()=>setShowBar(false)} onClick2={()=>setShowBar(true)} filterState={showBar} />}
        </div>
         <div> <ColumnChartComponent height={"380px"} title={"Demand Accuracy"}/></div>
    </div>
    <div className=' w-[30%]'><EventCalendar data={data} /> </div>
     </div>
        </div>
  )
}

export default DemandOutlook