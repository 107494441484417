export const calculatePercentage = (previous, current) => {
  // Helper function to remove '₹' and ',' and convert to a number
  const cleanValue = (value) => {
    // Ensure the value is a string before using replace
    if (typeof value === 'string') {
      return parseFloat(value.replace(/₹|,/g, ''));
    }
    // If it's already a number, return it directly
    if (typeof value === 'number') {
      return value;
    }
    return NaN; // Return NaN for invalid inputs
  };

  // Clean the input values
  const previousValue = cleanValue(previous);
  const currentValue = cleanValue(current);

  // If either value is NaN, return -1 (invalid percentage calculation)
  if (isNaN(previousValue) || isNaN(currentValue) || previousValue === 0) {
    return -1;
  }

  // Calculate percentage
  const percentage = ((currentValue - previousValue) / previousValue) * 100;



  return percentage; // Return without formatting if it's a whole number
};
