import React from "react";


const Modal = ({ children,className, position='center' ,}) => {
  return (
    <div className={` ${className}  fixed h-screen  flex justify-${position} items-center top-0 left-0 right-0 z-50 bg-black/25 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0  max-h-full`}>
     {children}
     
    </div>
  );
};

export default Modal;
