import React, { useState } from "react";
import { MdArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";

const HotelAnalysis = ({ imageFirst, image, value, table, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleButtonClick = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div
      className="relative w-full flex flex-col overflow-hidden gap-4 p-4 bg-transparent rounded-lg shadow-sm"
    >
      {/* Toggle button */}
      <div onClick={handleButtonClick} className=" w-fit absolute right-2 flex justify-end cursor-pointer">
        {isCollapsed ? <MdArrowDropUp color="white" size={25} /> : <MdOutlineArrowDropDown color="white" size={25} />}
      </div>

      {/* Collapsible content */}
      {isCollapsed && (
        <>
          <div className="flex h-[450px]  overflow-hidden justify-between ">
            <div className="w-[40%] flex justify-start">
              {image}
            </div>
            <div className="w-[60%] flex justify-center overflow-auto">
              <div className="flex w-[90%]">
                {table}
              </div>
            </div>
          </div>

          <div   className={`w-[98%] p-2 rounded-lg pl-6 flex  bg-newGradient dark:bg-lightGradient text-white  flex-col justify-center`}>
            {children}
          </div>
        </>
      )}

      {!isCollapsed && (
        <div onClick={() => setIsCollapsed(true)} className={`w-[98%] ${!isCollapsed&& 'cursor-pointer'} p-2 rounded-lg pl-6 flex bg-newGradient dark:bg-lightGradient text-white flex-col justify-center`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default HotelAnalysis;
