import { useEffect, useState } from "react";
import {
  useGetOtaRoomMappingDetailsQuery,
  useGetPropertyMappingDetailsQuery,
  useGetPropertyRoomDetailsQuery,
  useGetPropertyRoomWRTOtaQuery,
} from "../../../../redux/slices/settings/roomMapping";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import { AiOutlineDown } from "react-icons/ai";
import RenderRooms from "../../../../components/roomMapping/renderRooms/renderRooms";
import { useGetCompsetDetailsQuery } from "../../../../redux/slices/settings/myProperties";
import {
  useAddOtaRoomMappingMutation,
  useUpdateOtaRoomMappingMutation,
  useUpdatePropertyRoomMappingMutation,
} from "../../../../redux/slices/rooms";
import Dropdown from "../../../../components/dropdown";
import { toast } from "react-toastify";
import RenderPropertyRooms from "../../../../components/roomMapping/renderRooms/renderPropertyRooms";
import { Tooltip } from "react-tooltip";

const Hotel2hotelOTAMapping = ({ handleBackButtonClick, hId }) => {
  let otas = JSON.parse(window.localStorage.getItem("activeOta"));

  let OTADropdown = [];
  otas.map((activeOTas, otaId) => {
    OTADropdown.push({
      value: activeOTas.otaId,
      label: activeOTas.otaName,
    });
  });

  const [hotelId, setHotelId] = useState();
  const [loading, setLoading] = useState(false);

  const [addOtaRoomMapping] = useAddOtaRoomMappingMutation();
  const [updatePropertyRoomMapping] = useUpdatePropertyRoomMappingMutation();

  const [selectedType, setSelectedType] = useState();
  const [apiCompsetDetails, setApiCompsetDetails] = useState();
  const [apiGetOtaRoomMappingDetails, setApiGetOtaRoomMappingDetails] =
    useState();
  const [selectedOTA, setSelectedOTA] = useState(1);

  const { data: compsetDetails } = useGetCompsetDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const compsetNames =
      compsetDetails &&
      compsetDetails?.data &&
      compsetDetails?.data?.compSetDetails.map((compset) => {
        if (compset != null) {
          return {
            value: compset.hId,
            label: compset.propertyName,
          };
        }
      });
    setApiCompsetDetails(compsetNames);
    compsetNames &&
      compsetNames.length > 0 &&
      setHotelId(compsetNames[0].value);
  }, [selectedType, compsetDetails?.data?.compSetDetails]);

  const { data } = useGetPropertyRoomDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: hotelId ? false : true,
    }
  );

  const { data: propertyRooms, } = useGetPropertyRoomWRTOtaQuery(
    {
      hId: CurrentProperty(),
      otaId: selectedOTA,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedOTA ? false : true,
    }
  );
  useEffect(() => {
    console.log(propertyRooms?.data, "property Roomns");
  }, [propertyRooms]);

  let { data: getOtaRoomMappingDetails ,error} = useGetOtaRoomMappingDetailsQuery(
    {
      hId: hotelId,
      otaId: selectedOTA,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: hotelId ? false : true,
    }
  );

  const otaNames =
    data &&
    data?.data.map((ota) => {
      return ota?.otaName;
    });

  const otaRooms =
    data &&
    data?.data.map((ota) => {
      return ota?.rooms;
    });

  function getCompsetDefaultRooms() {
    let count = 0;
    let highestRoomCountCompset = 0;
    let defaultRoom = [];
    data &&
      data.data.map((compsetRoom, id) => {
        if (compsetRoom.rooms.length > count) {
          count = compsetRoom.rooms.length;
          highestRoomCountCompset = id;
        }
      });
    data &&
      data.data[highestRoomCountCompset]?.rooms.map((room, roomId) => {
        defaultRoom.push({
          label: room.roomName,
          value: room.roomID,
        });
      });
    return defaultRoom;
  }

  const [defaultRoom, setDefaultRoom] = useState();

  const [activeRooms, setActiveRooms] = useState();

  useEffect(() => {
      let otaArray = [];
      let resultForm = [];

      let setProperties = [];
      let settingDefaultRoom = [];
      propertyRooms?.data.map((propertyName, id) => {
        if (id !== 0) {
          setProperties.push({
            hId: propertyName.hId,
            roomId: "",
          });
        }
      });
      propertyRooms?.data?.[0]?.rooms.map((propertyRooms, id) => {
        resultForm.push({
          roomId: propertyRooms.roomID,
          roomName: propertyRooms.roomName,
          properties: setProperties,
        });
        settingDefaultRoom.push({
          value: propertyRooms.roomID,
          label: propertyRooms.roomName,
        });
      });
      setDefaultRoom(settingDefaultRoom);

      if (
        getOtaRoomMappingDetails &&
        getOtaRoomMappingDetails?.data &&
        getOtaRoomMappingDetails?.data.activeRooms.length > 0
      ) {
        setActiveRooms(getOtaRoomMappingDetails?.data?.activeRooms)
    } else {
      setActiveRooms(resultForm);
    }
  }, [selectedOTA, propertyRooms?.data, getOtaRoomMappingDetails]);

  function findHotelNameById(hotels, hId) {
    const foundRoom =
      hotels && hId && hotels.find((hotel) => hotel.value === hId);
    return foundRoom ? foundRoom.label : "";
  }

  function onSubmit() {
    updatePropertyRoomMapping({
      hId: hotelId,
      otaId: selectedOTA,
      activeRooms: activeRooms,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right h-5 w-5" });

        // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
        // localStorage.setItem('otaImage', JSON.stringify(otaImage));
        //  localStorage.setItem('otaName', JSON.stringify(otaName));

        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });
    // addOtaRoomMapping({
    //   hId: hotelId,
    //   activeRooms: activeRooms,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res.message, { position: "bottom-right h-5 w-5" });

    //     // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
    //     // localStorage.setItem('otaImage', JSON.stringify(otaImage));
    //     //  localStorage.setItem('otaName', JSON.stringify(otaName));

    //     setLoading(false);
    //   })

    //   .catch((err) => {
    //     toast.error(err.message, { position: "bottom-right" });
    //     setLoading(false);
    //   });
  }
  return (
    <div  className="h-screen ">
      <div className="mb-8 mr-2">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <div
              onClick={handleBackButtonClick}
              className=" rotate-90 bg-[#1D2D44] p-2 cursor-pointer rounded-lg"
            >
              <AiOutlineDown size={20} color="white" />
            </div>
            <div className=" text-lg flex items-center">
              Your OTA Room Mapping with compsets
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Dropdown
              selectedItem={findHotelNameById(OTADropdown, selectedOTA)}
              options={OTADropdown}
              setSelectedItems={(e) => {
                setSelectedOTA(e.value);
              }}
            />
            <button onClick={onSubmit}>
              <div className="w-fit px-4 outline outline-blue-500 text-blue-500 rounded-md cursor-pointer hover:text-white hover:bg-blue-600 hover:outline-none duration-150 transition-all ease-in-out">
                {getOtaRoomMappingDetails ? "Update" : "Save"}
              </div>
            </button>
          </div>
        </div>
      </div>
      {defaultRoom && activeRooms && defaultRoom.length !== 0 ? (
        <div className="flex  max-h-full overflow-y-scroll m-2 justify-between gap-6">
          <div className="w-[220px] pb-4 sticky  px-2 left-0 bg-white z-50">
            <div>Default Room</div>
            <div className="mt-2 flex  flex-col gap-4">
              {defaultRoom?.map((room, roomIdd) => {
                return (
                  <div
                    key={roomIdd}
                    className="h-10 outline min-w-[200px] max-w-[200px]  justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                  >
                   <span className="min-w-[90%] max-w-[90%] truncate" data-tooltip-id={room?.label} data-tooltip-content={room?.label}>{room?.label}</span> 
                   <Tooltip id={room?.label} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex pr-4 gap-4">
          {propertyRooms &&
            propertyRooms?.data.map((property, index) => {
              return (
                <>
                  {index !== 0 ? (
                    <div
                      className="w-[100%]"
                      key={`${index}r${property?.propertyName}`}
                      id={`${index}r${property?.propertyNamee}`}
                    >
                      <div
                        className="text-[16px] mb-2 max-w-[300px] truncate"
                        data-tooltip-id={property.propertyName}
                        data-tooltip-content={property.propertyName}
                      >
                        {property.propertyName}
                        <Tooltip id={property.propertyName} place="top" />
                      </div>
                      <RenderPropertyRooms
                        data={propertyRooms}
                        defaultRoom={defaultRoom}
                        index={index - 1}
                        activeRooms={activeRooms}
                        setActiveRooms={setActiveRooms}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>

      
        </div>
      ) : error?.data?.message ? <div> {error?.data?.message} </div> : (
        <>Loading...</>
      )}
    </div>
  );
};

export default Hotel2hotelOTAMapping;
