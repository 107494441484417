import React, { useEffect, useState } from 'react'
import ChannelCard from '../../channelcard';
import SettingButton from '../../../../components/settingButton/SettingButton';
import Reports from './reports';
import { CurrentProperty, UserId } from '../../../../utils/impVars';
import { useEditReportSchedulingMutation, useGetReportSchedulingQuery } from '../../../../redux/slices/settings/reportScheduling';
import BackdropLoader from '../../../../components/newLoader';
import { toast } from 'react-toastify';

const CustomReport = ({ handleBackButtonClick,data }) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [editReportSchedule] = useEditReportSchedulingMutation()
    const [isLoading, setIsLoading] = useState(false)
    const [customReport, setCustomReport] = useState(null);
    const userId = UserId();
    const hId = CurrentProperty();
    const { data: reportScheduling, refetch } = useGetReportSchedulingQuery({
        userId: UserId(),
        hId: CurrentProperty()
    }, { refetchOnMountOrArgChange: true })

    const handleCardClick = (cardTitle) => {
        setSelectedCard(cardTitle);
    };

    // const handleBackButtonClick = () => {
    //     setSelectedCard(null)
    // }
    const renderComponent = () => {
        switch (selectedCard) {
            case "Reports":
                return <Reports handleBackButtonClick={handleBackButtonClick} refetch={refetch} data={reportScheduling?.data?.customReportBuilderReport?.reports?.reportNotification} />;
            // case "Whatsapp":
            // // return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
            default:
                return null;
        }
    };
    useEffect(() => {
        if (customReport != null) {
            changeCustom();
        }

    }, [customReport]);

    function changeCustom() {
        setIsLoading(true)
        const body = {
            customReportBuilderReport: {
                customReport:
                    customReport != null ? customReport : data?.customReport
            },
        };
        editReportSchedule({ userId, hId, body })
            .unwrap()
            .then((res) => {
                refetch();
                setIsLoading(false)
                toast.success("Updated Successfully!", { position: "bottom-right" });
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    return (
        <>
            <BackdropLoader loading={isLoading} />
            {selectedCard ? (
                <>{renderComponent()}</>
            ) :
                (
                    <div className='flex flex-col gap-4'>
                        <SettingButton Title={"Custom Report"} table={true} handleBackButtonClick={handleBackButtonClick} />
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-1'>
                                <ChannelCard
                                    title={"Custom Report"}
                                    onClick={() => handleCardClick("")}
                                    check={true}
                                    checked={
                                        data?.customReport && customReport === null
                                            ? data?.customReport
                                            : customReport
                                    }
                                    onChange={(e) => {
                                        setCustomReport(e.target.checked);
                                    }}
                                />
                                <ChannelCard title={"Reports"} onClick={() => handleCardClick("Reports")} />
                            </div>
                        </div>
                        {/* {renderComponent()} */}
                    </div>)}


        </>
    )
}

export default CustomReport