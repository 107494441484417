import { useEffect, useState } from "react";
import {
  useGetOtaRoomMappingDetailsQuery,
  useGetPropertyMappingDetailsQuery,
  useGetPropertyRoomDetailsQuery,
} from "../../../../redux/slices/settings/roomMapping";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import { AiOutlineDown } from "react-icons/ai";
import RenderRooms from "../../../../components/roomMapping/renderRooms/renderRooms";
import { useGetCompsetDetailsQuery } from "../../../../redux/slices/settings/myProperties";
import {
  useAddOtaRoomMappingMutation,
  useUpdateOtaRoomMappingMutation,
} from "../../../../redux/slices/rooms";
import Dropdown from "../../../../components/dropdown";
import { toast } from "react-toastify";

const CompsetToRoomMapping = ({ handleBackButtonClick, hId }) => {
  const [hotelId, setHotelId] = useState();
  const [loading, setLoading] = useState(false);

  const [addOtaRoomMapping] = useAddOtaRoomMappingMutation();
  const [updateOtaRoomMapping] = useUpdateOtaRoomMappingMutation();

  const [selectedType, setSelectedType] = useState();
  const [apiCompsetDetails, setApiCompsetDetails] = useState();
  const [apiGetOtaRoomMappingDetails, setApiGetOtaRoomMappingDetails] = useState();

  const { data: compsetDetails } = useGetCompsetDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const compsetNames =
      compsetDetails &&
      compsetDetails?.data &&
      compsetDetails?.data?.compSetDetails.map((compset) => {
        if(compset != null){
          return {
            value: compset.hId,
            label: compset.propertyName,
          };
        }
       
      });
    setApiCompsetDetails(compsetNames);
    compsetNames &&
      compsetNames.length > 0 &&
      setHotelId(compsetNames[0].value);
  }, [selectedType, compsetDetails?.data?.compSetDetails]);

  const { data } = useGetPropertyRoomDetailsQuery(
    {
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:hotelId ?false :true
    }
  );

  let { data: getOtaRoomMappingDetails } = useGetOtaRoomMappingDetailsQuery(
    {
      hId: hotelId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:hotelId ?false :true
    }
  );

  const otaNames =
    data &&
    data?.data.map((ota) => {
      return ota?.otaName;
    });

  const otaRooms =
    data &&
    data?.data.map((ota) => {
      return ota?.rooms;
    });

  function getCompsetDefaultRooms() {
    let count = 0;
    let highestRoomCountCompset = 0;
    let defaultRoom = [];
    data &&
      data.data.map((compsetRoom, id) => {
        if (compsetRoom.rooms.length > count) {
          count = compsetRoom.rooms.length;
          highestRoomCountCompset = id;
        }
      });
    data &&
      data.data[highestRoomCountCompset]?.rooms.map((room, roomId) => {
        defaultRoom.push({
          label: room.roomName,
          value: room.roomID,
        });
      });
    return defaultRoom;
  }

  const [defaultRoom, setDefaultRoom] = useState();

  const [activeRooms, setActiveRooms] = useState(
    getOtaRoomMappingDetails?.data?.activeRooms
  );
  useEffect(()=>{
    let compsetDef = getCompsetDefaultRooms()
    setDefaultRoom(compsetDef)
  },[hotelId,getOtaRoomMappingDetails])

  useEffect(() => {
    if (!getOtaRoomMappingDetails?.data?.activeRooms || getOtaRoomMappingDetails?.data?.activeRooms === apiGetOtaRoomMappingDetails) {
      let otaArray = [];
      let resultForm = [];
      data &&
        data.data &&
        data?.data.map((otaData, otaDataId) => {
          otaArray.push({
            otaId: otaData.otaId,
            otaRId: "",
          });
        });
      defaultRoom &&
        defaultRoom.map((room, roomID) => {
          resultForm.push({
            roomId: room.value,
            roomName: room.label,
            otas: otaArray,
          });
        });
      setActiveRooms(resultForm);
    } else {
      setApiGetOtaRoomMappingDetails(getOtaRoomMappingDetails?.data?.activeRooms)
      setActiveRooms(getOtaRoomMappingDetails?.data?.activeRooms);
    }
  }, [ hotelId, defaultRoom,getOtaRoomMappingDetails]);

  function findHotelNameById(hotels, hId) {
    const foundRoom =
      hotels && hId && hotels.find((hotel) => hotel.value === hId);
    return foundRoom ? foundRoom.label : "";
  }

  function onSubmit() {
    updateOtaRoomMapping({
      hId: hotelId,
      activeRooms: activeRooms,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right h-5 w-5" });

        // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
        // localStorage.setItem('otaImage', JSON.stringify(otaImage));
        //  localStorage.setItem('otaName', JSON.stringify(otaName));

        setLoading(false);
      })

      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });
    // addOtaRoomMapping({
    //   hId: hotelId,
    //   activeRooms: activeRooms,
    // })
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res.message, { position: "bottom-right h-5 w-5" });

    //     // localStorage.setItem('globalApiResponse', JSON.stringify(globalApiResponse));
    //     // localStorage.setItem('otaImage', JSON.stringify(otaImage));
    //     //  localStorage.setItem('otaName', JSON.stringify(otaName));

    //     setLoading(false);
    //   })

    //   .catch((err) => {
    //     toast.error(err.message, { position: "bottom-right" });
    //     setLoading(false);
    //   });
  }

  return (
    <div>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <div
              onClick={handleBackButtonClick}
              className=" rotate-90 bg-[#1D2D44] p-2 cursor-pointer rounded-lg"
            >
              <AiOutlineDown size={20} color="white" />
            </div>
            <div className=" text-lg flex items-center">
              Compset Room Mapping By OTA
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Dropdown
              selectedItem={findHotelNameById(apiCompsetDetails, hotelId)}
              options={apiCompsetDetails}
              setSelectedItems={(e) => {
                getOtaRoomMappingDetails = null
                setDefaultRoom();
                setActiveRooms();
                setHotelId(e.value);
              }}
            />
            <button onClick={onSubmit}>
              <div className="w-fit px-4 outline outline-blue-500 text-blue-500 rounded-md cursor-pointer hover:text-white hover:bg-blue-600 hover:outline-none duration-150 transition-all ease-in-out">
              {getOtaRoomMappingDetails ? "Update" : "Save"} 
              </div>
            </button>
          </div>
        </div>
      </div>
      {defaultRoom && activeRooms && defaultRoom.length !== 0 ? (
        <div className="flex justify-between gap-4 scroll-container h-[85vh] w-[100%] p-1">
          <div className="w-[100%]">
            <div>Default Room</div>
            <div className="mt-2 flex flex-col gap-4">
              {defaultRoom?.map((room, roomIdd) => {
                return (
                  <div
                    key={roomIdd}
                    className="h-10 outline w-[100%]  justify-between flex items-center select-none outline-gray-400 relative rounded-md px-2 focus:outline focus:outline-blue-500 bg-white text-sm text-gray-500"
                  >
                    {room?.label}
                  </div>
                );
              })}
            </div>
          </div>
          {otaNames &&
            otaNames.map((ota, index) => {
              return (
                <div
                  className="w-[100%]"
                  key={`${index}r${ota}`}
                  id={`${index}r${ota}`}
                >
                  <div className="text-[16px] mb-2 ">{ota}</div>
                  <RenderRooms
                    data={data}
                    defaultRoom={defaultRoom}
                    index={index}
                    activeRooms={activeRooms}
                    setActiveRooms={setActiveRooms}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default CompsetToRoomMapping;
