import React from "react";
import { useLocation } from "react-router-dom";
import threeDot from "../../assets/icons/three-dots-vertical-svgrepo-com.svg";
import { IoMdEye } from "react-icons/io";
import { AiOutlineCheck, AiOutlineDelete, AiTwotoneEdit } from "react-icons/ai";

const Tableactiontype = ({
  handleEdit,
  handleEditText,
  openModal,
  icon,
  setting,
  reset,
  onViewClick,
  onEditClick,
  showEdit,
  showView,
  handleDelete,
  showDelete = false,
  showDeactivate = false,
  handleDeactivate,
  rule,
  selectedId,
  onClickUpdate
}) => {
  return (
    <>
      <div className="flex items-center gap-[12px]">
        {/* <div>
          <svg
            className='cursor-pointer'
            onClick={openModal2}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
              fill="#5E687F"
            />
            <path
              d="M9 13.3125C9.31066 13.3125 9.5625 13.0607 9.5625 12.75V8.25C9.5625 7.93934 9.31066 7.6875 9 7.6875C8.68934 7.6875 8.4375 7.93934 8.4375 8.25V12.75C8.4375 13.0607 8.68934 13.3125 9 13.3125Z"
              fill="#5E687F"
            />
            <path
              d="M9 5.25C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6C8.25 5.58579 8.58579 5.25 9 5.25Z"
              fill="#5E687F"
            />
          </svg>
        </div> */}

        {icon && (
          <div className="filter invert" onClick={handleEdit}>
            <svg
              className="cursor-pointer"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.637 6.53479C16.7888 5.38301 16.7888 3.51561 15.637 2.36383C14.4852 1.21206 12.6178 1.21206 11.4661 2.36383L10.8008 3.02911C10.8099 3.05662 10.8193 3.08451 10.8291 3.11277C11.073 3.81562 11.5331 4.737 12.3986 5.60252C13.2641 6.46803 14.1855 6.92811 14.8883 7.17196C14.9165 7.18172 14.9442 7.19113 14.9716 7.2002L15.637 6.53479Z"
                fill="#5E687F"
              />
              <path
                opacity="0.5"
                d="M10.8289 3L10.8003 3.02864C10.8094 3.05615 10.8189 3.08405 10.8287 3.1123C11.0725 3.81515 11.5326 4.73654 12.3981 5.60205C13.2636 6.46756 14.185 6.92765 14.8879 7.17149C14.9157 7.18117 14.9433 7.1905 14.9704 7.19949L8.55008 13.6198C8.11723 14.0527 7.90076 14.2691 7.66212 14.4553C7.38061 14.6748 7.07602 14.8631 6.75373 15.0167C6.48052 15.1469 6.19014 15.2437 5.60938 15.4373L2.54688 16.4581C2.26108 16.5534 1.94599 16.479 1.73297 16.266C1.51995 16.053 1.44557 15.7379 1.54084 15.4521L2.56167 12.3896C2.75526 11.8088 2.85205 11.5184 2.98226 11.2452C3.13585 10.9229 3.3241 10.6183 3.54367 10.3368C3.7298 10.0982 3.94623 9.88176 4.37906 9.44894L10.8289 3Z"
                fill="#5E687F"
              />
            </svg>
          </div>
        )}

        {icon && (
          <div className="filter invert">
            <svg
              className="cursor-pointer"
              onClick={openModal}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M8.6967 16.4995H9.3033C11.3903 16.4995 12.4339 16.4995 13.1123 15.835C13.7908 15.1706 13.8602 14.0807 13.9991 11.9009L14.1991 8.75994C14.2744 7.57719 14.3121 6.98582 13.9717 6.61107C13.6313 6.23633 13.0565 6.23633 11.907 6.23633H6.09303C4.94345 6.23633 4.36866 6.23633 4.02829 6.61107C3.68792 6.98582 3.72558 7.57719 3.80091 8.75994L4.00094 11.9009C4.13977 14.0807 4.20919 15.1706 4.88767 15.835C5.56615 16.4995 6.60967 16.4995 8.6967 16.4995Z"
                fill="#5E687F"
              />
              <path
                d="M2.25 4.78948C2.25 4.42614 2.50904 4.13159 2.82857 4.13159L4.82675 4.13124C5.22377 4.1198 5.57401 3.83275 5.7091 3.40809C5.71266 3.39692 5.71674 3.38315 5.73139 3.33318L5.81749 3.03942C5.87018 2.8593 5.91608 2.70239 5.98031 2.56213C6.23407 2.00802 6.70356 1.62324 7.2461 1.52473C7.38343 1.49979 7.52886 1.4999 7.69579 1.50002H10.3043C10.4713 1.4999 10.6167 1.49979 10.754 1.52473C11.2966 1.62324 11.7661 2.00802 12.0198 2.56213C12.0841 2.70239 12.13 2.8593 12.1826 3.03942L12.2687 3.33318C12.2834 3.38315 12.2875 3.39692 12.291 3.40809C12.4261 3.83275 12.8458 4.12015 13.2429 4.13159H15.1714C15.491 4.13159 15.75 4.42614 15.75 4.78948C15.75 5.15282 15.491 5.44737 15.1714 5.44737H2.82857C2.50904 5.44737 2.25 5.15282 2.25 4.78948Z"
                fill="#5E687F"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.06907 8.6104C7.37819 8.57787 7.65384 8.81527 7.68475 9.14065L8.05975 13.088C8.09066 13.4134 7.86513 13.7036 7.55601 13.7361C7.24689 13.7686 6.97125 13.5312 6.94033 13.2059L6.56533 9.25849C6.53442 8.9331 6.75995 8.64294 7.06907 8.6104Z"
                fill="#5E687F"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.931 8.6104C11.2401 8.64294 11.4657 8.9331 11.4348 9.25849L11.0598 13.2059C11.0288 13.5312 10.7532 13.7686 10.4441 13.7361C10.135 13.7036 9.90942 13.4134 9.94033 13.088L10.3153 9.14065C10.3462 8.81527 10.6219 8.57787 10.931 8.6104Z"
                fill="#5E687F"
              />
            </svg>
          </div>
        )}
        {setting && (
          <div className="w-fit">
            <p className="text-[14px] font-[500] text-[#1D3893] cursor-pointer">
              Go To Dashboard
            </p>
          </div>
        )}
        {!icon && !setting && !reset && (
          <div className="w-fit">
            <p
              className="text-[16px] font-[500] text-[#1D3893] cursor-pointer"
              onClick={onViewClick}
            >
              <IoMdEye  color={'white'} />
            </p>
          </div>
        )}
        {!icon && (
          <div className="w-fit" onClick={handleEditText}>
            <p
              className={`${selectedId ? 'text-sm' : 'text-[16px] '} font-[500] text-[#1D3893] cursor-pointer`}
              onClick={selectedId ? onClickUpdate : onEditClick}
            >
             {selectedId ? 'Update' : <AiTwotoneEdit  color={'white'}/>}
            </p>
          </div>
        )}
        {reset && !rule && (
          <div className="w-fit" onClick={handleEditText}>
            <p className="text-[14px] font-[500] text-[#1D3893] cursor-pointer">
              Reset
            </p>
          </div>
        )}
        {showDelete && (
          <div className="w-fit " onClick={handleDelete}>
            <p className="text-[16px] font-[500] text-[#1D3893] hover:text-red-500 duration-200 ease-in-out transition-all cursor-pointer ">
              <AiOutlineDelete color={'white'}/>
            </p>
          </div>
        )}
        {showDeactivate && (
          <div className="w-fit" onClick={handleDeactivate}>
            <p className="text-[12px] font-[500] text-[#f15353] hover:text-red-700 duration-200 ease-in-out transition-all cursor-pointer">
              Deactivate
            </p>
          </div>
        )}

        {/* {!icon && <div className="w-fit">
          <img className="w-5 cursor-pointer" src={threeDot} alt="" />
        </div>} */}

        {/* {isSettingsRoute2 && (
          <div>
            <svg
              className='cursor-pointer'
              onClick={openModal2}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                fill="#5E687F"
              />
              <path
                d="M9 13.3125C9.31066 13.3125 9.5625 13.0607 9.5625 12.75V8.25C9.5625 7.93934 9.31066 7.6875 9 7.6875C8.68934 7.6875 8.4375 7.93934 8.4375 8.25V12.75C8.4375 13.0607 8.68934 13.3125 9 13.3125Z"
                fill="#5E687F"
              />
              <path
                d="M9 5.25C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6C8.25 5.58579 8.58579 5.25 9 5.25Z"
                fill="#5E687F"
              />
            </svg>
          </div>
        )}
        {isSettingsRoute4 && (
          <div>
            <svg
              className='cursor-pointer'
              onClick={openModal2}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                fill="#5E687F"
              />
              <path
                d="M9 13.3125C9.31066 13.3125 9.5625 13.0607 9.5625 12.75V8.25C9.5625 7.93934 9.31066 7.6875 9 7.6875C8.68934 7.6875 8.4375 7.93934 8.4375 8.25V12.75C8.4375 13.0607 8.68934 13.3125 9 13.3125Z"
                fill="#5E687F"
              />
              <path
                d="M9 5.25C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6C8.25 5.58579 8.58579 5.25 9 5.25Z"
                fill="#5E687F"
              />
            </svg>
          </div>
        )} */}
      </div>
    </>
  );
};
export default Tableactiontype;
