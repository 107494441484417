import React, { useState } from "react";
import ChannelCard from "../channelcard";
import RevenueReport from "./revenueReport";
import PickUpReport from "./pickupReport";
import PaceReport from "./paceReport";
import CustomReport from "./customReport";
import { useGetReportSchedulingQuery } from "../../../redux/slices/settings/reportScheduling";
import { CurrentProperty, UserId } from "../../../utils/impVars";

const ReportScheduling = () => {
  const { data: reportScheduling, refetch } = useGetReportSchedulingQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  const handleBackButtonClick = () => {
    setSelectedCard(null);
  };
  const renderComponent = () => {
    switch (selectedCard) {
      case "Revenue Report":
        return (
          <RevenueReport
            handleBackButtonClick={handleBackButtonClick}
            refetch={refetch}
            data={reportScheduling?.data?.revenueReport}
          />
        );
      case "Pick-Up Report":
        return (
          <PickUpReport
            handleBackButtonClick={handleBackButtonClick}
            refetch={refetch}
            data={reportScheduling?.data?.pickUpReport}
          />
        );
      case "Pace Reports":
        return (
          <PaceReport
            handleBackButtonClick={handleBackButtonClick}
            refetch={refetch}
            data={reportScheduling?.data?.paceReport}
          />
        );
      case "Custom Report Builder Reports":
        return (
          <CustomReport
            handleBackButtonClick={handleBackButtonClick}
            refetch={refetch}
            data={reportScheduling?.data?.customReportBuilderReport}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col gap-4">
          <div className=" text-[17px] text-[#ffffff] darK:text-black h-[44px] flex items-center mt-1">
            Report Scheduling
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <ChannelCard
                title={"Revenue Report"}
                onClick={() => handleCardClick("Revenue Report")}
              />
              <ChannelCard
                title={"Pick-Up Report"}
                onClick={() => handleCardClick("Pick-Up Report")}
              />
              <ChannelCard
                title={"Pace Reports"}
                onClick={() => handleCardClick("Pace Reports")}
              />
              {/* <ChannelCard title={"Custom Report Builder Reports"} onClick={() => handleCardClick("Custom Report Builder Reports")} /> */}
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};

export default ReportScheduling;
