import React from 'react';
import PropTypes from 'prop-types';
import { useIsIpad } from '../../utils/isIpad';

const MyUniversalComponent = ({ title, subtitle, imageSrc }) => {
    const isIphone = useIsIpad()
    return (
        <div className={`flex flex-col login-side ${isIphone && 'hidden'} background-image justify-center items-center`}>
            <div className='flex flex-col items-center justify-center gap-4 h-fit'>
                <div className='text-center w-7/12'>
                    <h1 className='text-[1.5rem] text-white text-center font-sans font-semibold'>
                        {title}
                    </h1>
                    <p className='text-[0.8rem] text-white'>{subtitle}</p>
                </div>
                <div className='w-full mx-auto'>
                    <img className='w-8/12 mx-auto my-4' src={imageSrc} alt='' />
                </div>
            </div>
        </div>
    );
};

MyUniversalComponent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    imageSrc: PropTypes.string
};

export default MyUniversalComponent;
