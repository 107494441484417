import { emptySplitApi } from "../../../injectEndpoints";

export const ChannelManagerMealPlan = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMealPlan: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getMealPlanDetail`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getMealPlan"],
    }),
    getMealPlanCharge: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getMealPlanCharge`,
          method: "GET",
          params: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getMealPlanCharge"],
    }),
    addMealPlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addMealPlanDetail`,
          method: "POST",
          body: data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addMealPlan"],
    }),
    editMealPlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editMealPlanDetail`,
          method: "PATCH",
          body: data?.obj,
          params:data?.editParams
        };
      },
      // Replace with your actual endpoint
      providesTags: ["editMealPlan"],
    }),
    deleteMealPlan: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteMealPlan`,
          method: "PATCH",
          params:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteMealPlan"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetMealPlanQuery,useAddMealPlanMutation,useGetMealPlanChargeQuery,useEditMealPlanMutation, useDeleteMealPlanMutation } = ChannelManagerMealPlan;
