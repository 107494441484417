import React, { useEffect, useState } from 'react'
import '../../onboarding/Global.css';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Backdrop from '../../../components/backdrop';
import { SyncLoader } from 'react-spinners';
import PropertyCard from '../../../components/propertyCard';
import SimpleButton from '../../../components/button/SimpleButton';
import TitleComponent from '../../../components/cardComponent';
import { toast } from "react-toastify";
import Select from 'react-select';

const SelectCompetition = ({ onSubmit , setSteps,  data ,steps  ,selectedOTAs, onSelectedOTAsChange}) => {
    const [finalData,setFinalData]=useState();
    const [removedOptions,setRemovedOptions]=useState([])
    const handleClick = (data) => {
        setSteps({
            step1: false, step2: false, step3: true, step4: false, step5:false
        })

        onSubmit(data);
    };
    const methods = useForm({});
    const handleManuallyClick =(data)=>{
        setSteps({
            step1: false, step2: false, step3: false, step4: true,step5:false
        })
   
    }
    useEffect(()=>{
        let body = document.body
        body.style.overflow = ""
      })
    const navigate = useNavigate();

   
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const delayTransition = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the delay time as needed

        // Cleanup the timeout to avoid memory leaks
        return () => clearTimeout(delayTransition);
    }, []);
    const [checkedCount, setCheckedCount] = useState(0);

    const handlePropertyCardCheck = (ota) => {
        const updatedSelectedOTAs = [...selectedOTAs, ota];

        onSelectedOTAsChange(updatedSelectedOTAs);
        setCheckedCount((prevCount) => prevCount + 1);
    };


    const handlePropertyCardCheck2 = (ota) => {

        onSelectedOTAsChange(selectedOTAs => selectedOTAs.filter(selectedOta => selectedOta !== ota)); // Update selected OTAs in parent component
        setCheckedCount((prevCount) => prevCount - 1);
    };

    function filterDataWithLink(data) {
        setFinalData(data.filter(item => item.link !== null && !item.hotelCity ));
    }
    const [options,setOptions]=  useState();
    
    useEffect(()=>{

        setOptions(removedOptions.map((item)=>({"value":item, "label":item})))
    },[removedOptions])
    useEffect(()=>{

        setOptions(removedOptions.map((item)=>({"value":item, "label":item})))
    },[removedOptions])
    const handleAddOTAsClick = (newOTA) => {
        if (checkedCount === 0) {
            toast.error("Please select atleast one ota", { position: "bottom-right" });
            return;
        }
        handleClick(methods.getValues());
        setSteps({
            step1: false, step2: false, step3: true, step4: false, step5:false
        })

        const updatedSelectedOTAs = [...selectedOTAs, newOTA];
        // Perform further actions with selectedOTAs array
        // Navigate to the desired page
    };
    const handleChange=(val)=>{
        // console.log(item,'selected options')
       const filteredData= data.filter((item)=>item.otaName === val.value)
       setFinalData([...finalData,...filteredData])
       setRemovedOptions(prev=> prev.filter((item)=>item != val.value));
    }
    useEffect(()=>{
        filterDataWithLink(data)
    },[])
    return (
        <> {
            loading && <Backdrop>
                <SyncLoader color="white" />
            </Backdrop>

        }
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleClick)}>
                   
                     
                       
                            <div className='verify-email-content2'>
                            
                                <div className='flex items-center justify-center flex-col gap-2'>
                                    <TitleComponent title={'Pick Your Competition '}
                                        subtitle={'Please sign-in to your account and start the adventure'} />
 { options?.length>0 && <Select options={options}  className={`  border rounded-md focus:outline-none focus:border-blue-500`} onChange={handleChange} />}
                                </div>
                                <div className='flex flex-col items-center justify-center w-full gap-4'>
                                    <div className='bg-white gap-4'>
                                    {Array.isArray(data)  && finalData?.map((ota) => {
                               return  <div key={ota.id}>
                                    <PropertyCard  setFinalData={setFinalData} removedOptions={removedOptions} id={ota.otaId} setRemovedOptions={setRemovedOptions} finalData={finalData} link={ota.link} onCheck2={() => handlePropertyCardCheck2(ota)} onCheck={() => handlePropertyCardCheck(ota)} name={ota.otaName} address={ota?.hotelCity}
                                        img={ota.otaImage} />
                                </div>
                            })}
                                     
                                    </div>

                                    <SimpleButton text={checkedCount > 0 ? `Add ${checkedCount} OTAs` : 'Add Your OTAs'} type='button'  onClick={handleAddOTAsClick} />
                                    {/* <div className='flex flex-col w-full gap-4 items-center'>
                                        <button className='text-sm font-[600] text-[#3F70ED] cursor-pointer'  onClick={handleManuallyClick}>Add OTAs Manually</button>

                                    </div> */}
                                </div>
                            </div>
                        
                </form>
            </FormProvider>
        </>

    )
}

export default SelectCompetition