import React, { useEffect, useRef, useState } from "react";
import backButton from "../../assets/img/backIcon.svg";
import RateSuggestionCard from "../../components/rateSuggestionBox/RateSuggestion";
import HorizontalCard from "../../components/cards/horizontalCard";
import RateVsCompetition from "../../test/RatevsCompetition";
import icon from "../../assets/icons/3.svg";
import {
  useGetActualAndSuggestedRatesQuery,
  useGetRateSuggestionCalendarByDateQuery,
  useGetSuggestedRateVsCompetitionQuery,
  useOverrideRateMutation,
} from "../../redux/slices/rateSuggestions";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { format } from "date-fns";
import BackdropLoader from "../../components/newLoader";
import formatCurrency from "../../utils/formatCurrency";
import CapitalizeFirstLetter from "../../utils/firstLetterCapital";
import Calendar from "../../components/calendar";
import { useLocation, useNavigate } from "react-router-dom";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import TimeRange from "../../components/timeRange";
import {
  AiFillCalendar,
  AiFillCloseCircle,
  AiFillCopy,
  AiFillDelete,
  AiFillWarning,
  AiOutlineClose,
} from "react-icons/ai";
import { IoMdClose, IoMdReturnRight } from "react-icons/io";

import { useAddExternalRateMutation } from "../../redux/slices/superAdmin";
import { toast } from "react-toastify";
import Backdrop from "../../components/backdrop";
import { DateRangePicker } from "react-date-range";
import CustomSkeleton from "../../components/Skeleton";
import { useIsIpad } from "../../utils/isIpad";
import { FaCircle } from "react-icons/fa";

function increaseByPercentage(value, percentage) {
  if (typeof value !== "number" || typeof percentage !== "number") {
    throw new Error("Both value and percentage must be numbers.");
  }
  return (100 * value) / (100 - percentage);
}

function getDatesInRange(startDate, endDate) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

function copyRate(dataArray, index, setState, discountPercentage) {
  if (index < 0 || index >= dataArray.length) {
    console.error("Invalid index");
    return;
  }

  const rateToCopy = dataArray[index].overRideRate;

  const updatedData = dataArray.map((item, idx) => {
    if (idx > index) {
      return {
        ...item,
        overRideRate: rateToCopy,
        overRiddenInCM: increaseByPercentage(
          parseInt(rateToCopy),
          discountPercentage
        ),
      };
    }
    return item;
  });

  setState(updatedData);
}

const RateAnalysis = ({
  apiData,
  hideToggle,
  totalDays,
  selectedDate,
  setSelectedDate,
  selectedType,
  setSelectedType,
  options,
  showModal,
  setShowModal,
  refetch,
  isToggled,
  toggleButton,
}) => {
  const userId = UserId();
  const [pushRatePeriod, setPushRatePeriod] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      // startDate: new Date(),
      // endDate: new Date(),

      key: "range1",
    },
  ]);
  useEffect(() => {
    setPushRatePeriod([
      {
        startDate: new Date(selectedDate),
        endDate: new Date(selectedDate),
        // startDate: new Date(),
        // endDate: new Date(),

        key: "range1",
      },
    ]);
  }, [selectedDate]);

  const {
    data: getActualAndSuggestedRates,
    isLoading: getActualAndSuggestedRatesIsLoading,
    isFetching: getActualAndSuggestedRatesIsFetching,
  } = useGetActualAndSuggestedRatesQuery({
    userId: UserId(),
    startDate: format(new Date(pushRatePeriod[0].startDate), "yyyy-MM-dd"),
    endDate: format(new Date(pushRatePeriod[0].endDate), "yyyy-MM-dd"),
    hId: CurrentProperty(),
  });
  const ref2 = useRef();
  const adminUserId = window.localStorage.getItem("adminUserId");
  const role = window.localStorage.getItem("role");
  const [reason, setReason] = useState();
  const [rate, setRate] = useState();
  const [factors, setFactors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPushRate, setIsPushRate] = useState(false);
  const [showTansition, setShowTansition] = useState(false);
  const [showCal, setShowCal] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [overRideRate, setOverRideRate] = useState();
  const [differentPriceData, setDifferentPriceDate] = useState();

  const [pushRateSelectedDate, setPushRateSelectedDate] = useState();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      // startDate: new Date(),
      // endDate: new Date(),

      key: "range1",
    },
  ]);
  const hId = CurrentProperty();
  const [inputValue, setInputValue] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [overrideRate] = useOverrideRateMutation();
  const {
    data: rateSuggestionByDate,
    isLoading: rateSuggestionByDateIsLoading,
    isFetching: rateSuggestionByDateIsFetching,
    refetch: onDateRefetch,
  } = useGetRateSuggestionCalendarByDateQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      date: format(new Date(selectedDate), "yyyy-MM-dd"),
      type: selectedType?.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: rateLeaderborad,
    isLoading: rateLeaderboardIsLoading,
    isFetching,
  } = useGetSuggestedRateVsCompetitionQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      date: format(new Date(selectedDate), "yyyy-MM-dd"),
    },
    { refetchOnMountOrArgChange: true }
  );
  const [addExternalRate] = useAddExternalRateMutation();
  const divRefs = React.useRef([]);

  useEffect(() => {
    if (rateSuggestionByDateIsFetching || rateSuggestionByDateIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [rateSuggestionByDateIsFetching, rateSuggestionByDateIsLoading]);

  function renderDays() {
    let calendar = [];
    for (let i = 1; i <= totalDays; i++) {
      const today = new Date();
      const currentDate = new Date(
        new Date(selectedDate && selectedDate)?.getFullYear(),
        new Date(selectedDate && selectedDate)?.getMonth(),
        i
      );
      const isPastDate =
        currentDate < new Date().setDate(new Date().getDate() - 1);
      calendar.push(
        <div
          className="flex py-2 justify-center min-w-[160px]"
          onClick={
            isPastDate
              ? () => null
              : () =>
                  setSelectedDate(
                    new Date(
                      new Date(selectedDate)?.getFullYear(),
                      new Date(selectedDate)?.getMonth(),
                      i
                    )
                  )
          }
          ref={(el) => (divRefs.current[i] = el)}
        >
          <RateSuggestionCard
            isSelected={
              selectedDate && new Date(selectedDate)?.getDate() === i
                ? true
                : false
            }
            isTrue={true}
            number={i}
            rate={apiData?.data[i - 1]?.rate}
            suggestedRate={
              apiData?.data[i - 1]?.suggestedRate
                ? apiData?.data[i - 1]?.suggestedRate
                : "--" || apiData?.data[i - 1]?.dynamicRate
                  ? apiData?.data[i - 1]?.dynamicRate
                  : "--"
            }
            showSuggestedLabel={false}
            isPastDate={isPastDate}
            selectedType={selectedType?.label}
            showOccupancy={true}
            occupancyPercentage={apiData?.data[i - 1]?.occupancy}
            overRideRate={apiData?.data[i - 1]?.overRideRate}
            isDynamic={selectedType.label === "Dynamic"}
          />
        </div>
      );
    }
    return calendar;
  }

  React.useEffect(() => {
    divRefs.current[
      new Date(selectedDate && selectedDate)?.getDate()
    ].scrollIntoView({
      behavior: "smooth",
    });
  }, [selectedDate]);

  //   const scrollToDiv = (index) => {
  //     divRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  //   };
  const myProp =
    rateLeaderborad?.data &&
    rateLeaderborad?.data?.rateLeaderboad.filter((prop) => {
      const myProperty = JSON.parse(
        window?.localStorage?.getItem("propertiesList")
      );
      return prop?.hotelName === myProperty[0]?.propertyName;
    });
  const otherPropDetails =
    rateLeaderborad?.data &&
    rateLeaderborad?.data?.rateLeaderboad.filter((prop) => {
      const myProperty = JSON.parse(
        window?.localStorage?.getItem("propertiesList")
      );
      return prop?.hotelName != myProperty[0]?.propertyName;
    });

  const handlePush = () => {
    if (hId && adminUserId && factors && reason && rate) {
      setIsLoading(true);
      const obj = {
        hId: hId,
        userId: adminUserId,
        factors: factors.join(", "),
        reason: reason,
        rate: rate,
        startDate: format(new Date(period[0]?.startDate), "yyyy-MM-dd"),
        endDate: format(new Date(period[0]?.endDate), "yyyy-MM-dd"),
      };
      addExternalRate(obj)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          toast.success("Rate Pushed Successfully !", {
            position: "bottom-right",
          });
          setFactors([]);
          setReason();
          setRate();
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.data?.message, {
            position: "bottom-right",
          });
        });
    } else {
      toast.error("All fields are required!", {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (isPushRate) {
      setTimeout(() => {
        setShowTansition(true);
      }, [50]);
    } else {
      setShowTansition(false);
    }
  }, [isPushRate]);

  useEffect(() => {
    if (!showTansition && !showModal) {
      setTimeout(() => {
        setIsPushRate(false);
      }, [300]);
    }
  }, [showTansition]);

  const currentRate = rateSuggestionByDate?.data?.rate;
  const suggestedRate = rateSuggestionByDate?.data?.suggestedRate?.toFixed(2);

  useEffect(() => {
    if (suggestedRate && isPushRate) {
      setOverRideRate(suggestedRate);
    }
  }, [suggestedRate, isPushRate]);

  useEffect(() => {
    if (pushRatePeriod) {
      setPushRateSelectedDate(
        getDatesInRange(
          new Date(pushRatePeriod[0].startDate),
          new Date(pushRatePeriod[0].endDate)
        )
      );
    }
  }, [pushRatePeriod]);

  const handleDeleteDate = (date) => {
    const newDate = pushRateSelectedDate?.filter((item) => {
      return (
        format(new Date(item), "yyyy-MM-dd") !=
        format(new Date(date), "yyyy-MM-dd")
      );
    });
    const newShowData = differentPriceData?.filter((item) => {
      return item?.date != format(new Date(date), "yyyy-MM-dd");
    });
    setDifferentPriceDate(newShowData);
    setPushRateSelectedDate(newDate);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setShowCal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);

  useEffect(() => {
    if (getActualAndSuggestedRates) {
      const newDate =
        getActualAndSuggestedRates &&
        getActualAndSuggestedRates["data"].map((item, index) => {
          return {
            date: format(new Date(item?.date), "yyyy-MM-dd"),
            rate: item?.rate && parseInt(item?.rate)?.toFixed(2),
            suggestedRate:
              item?.suggestedRate && parseInt(item?.suggestedRate)?.toFixed(2),
            overRideRate: "",
            suggestedRateWithDiscount: increaseByPercentage(
              parseInt(item?.suggestedRate),
              item?.discount
            ),
            occupancy: item?.occupancy,
            CMRate: item?.CMRate,
            discount: item?.discount,
            overRiddenInCM: increaseByPercentage(
              parseInt(item?.overRideRate),
              item?.discount
            ),
          };
        });
      setDifferentPriceDate(newDate);
    }
  }, [getActualAndSuggestedRates]);

  const handleDifferentPrice = (index, value, discountPercentage) => {
    if (value === "" || /^-?\d{0,6}(\.\d{0,2})?$/.test(value)) {
      // Create a copy of differentPriceData
      const data = [...differentPriceData];

      // Update the rate of the item at the specified index
      data[index] = {
        ...data[index],
        overRideRate: !value ? "" : value,
        overRiddenInCM: increaseByPercentage(
          parseInt(value),
          discountPercentage
        ),
      };

      // Set the state with the updated array
      setDifferentPriceDate(data);
    }
  };
  const handlePushRate = () => {
    const obj = {
      hId: hId,
      userId: userId,
      overRideRates: differentPriceData?.map((item) => {
        return {
          rate: parseInt(item?.overRideRate),
          date: format(new Date(item?.date), "yyyy-MM-dd"),
        };
      }),
    };
    overrideRate(obj)
      .unwrap()
      .then((res) => {
        toast.success("Rates Override Successfully", {
          position: "bottom-right",
        });
        refetch();
        onDateRefetch();
        setShowTansition(false);
        setDifferentPriceDate();
      })
      .catch((err) => {
        return toast.error(err?.data?.data?.message, {
          position: "bottom-right",
        });
      });
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    // Allow empty string, numbers, and a single dot for floats
    if (newValue === "" || /^\d*\.?\d*$/.test(newValue)) {
      setOverRideRate(newValue);
    }
  };

  useEffect(() => {
    if (showModal) {
      setShowTansition(true);
      setIsPushRate(true);
    }
  }, [showModal]);
  useEffect(() => {
    pushRatePeriod[0].startDate = selectedDate;
    pushRatePeriod[0].endDate = selectedDate;
  }, [selectedDate]);
  const isIpad = useIsIpad();

  return (
    <div className={`m-2 ml-4`}>
      {isPushRate && (
        <Backdrop>
          <div className="w-[100%] rounded-tl-lg rounded-bl-lg flex flex-col items-end min-h-full max-h-full  ">
            <div
              className={`w-[95%] rounded-tl-lg rounded-bl-lg relative ${showTansition ? "right-0" : "-right-[870px]"} transition-all ease-in-out duration-300  bg-[#20243980] h-[100%]`}
            >
              <div className="border-b border-[#747eb1] rounded-tl-lg">
                <div className="flex p-4 bg-[#181a2990] text-white  rounded-tl-lg  justify-between items-center">
                  <div className="">Override Rate</div>
                  <div>
                    <IoMdClose
                      className="cursor-pointer"
                      onClick={() => {
                        setPushRatePeriod([
                          {
                            startDate: new Date(),
                            endDate: new Date(),
                            // startDate: new Date(),
                            // endDate: new Date(),

                            key: "range1",
                          },
                        ]);
                        // setDifferentPriceDate();
                        // setOverRideRate();
                        setShowModal(false);
                        setShowTansition(false);
                      }}
                      size={22}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[400px] absolute -right-[250px] flex justify-start mt-4">
                <div
                  onClick={handlePushRate}
                  className="bg-[#0062ff] z-40 relative cursor-pointer w-[30%] rounded-md  text-sm flex justify-center items-center text-white px-4 py-1"
                >
                  Accept Rate
                </div>
              </div>
              <div className="p-4 h-[93vh]  overflow-y-auto">
                <div className="flex flex-col gap-4">
                  <div className="relative" ref={ref2}>
                    <div className="text-sm text-[#ffffff]">Add Dates</div>
                    <div className="my-2 w-[100%]">
                      <div
                        onClick={() => setShowCal(!showCal)}
                        className={`cursor-pointer flex text-sm items-center gap-4 bg-[#383c4f] text-white ${isIpad ? "w-[35%]" : "w-[25%]"} px-2 py-2 rounded-lg`}
                      >
                        <AiFillCalendar color="white" />
                        <div>
                          {format(
                            new Date(pushRatePeriod[0].startDate),
                            "dd MMM yy"
                          )}{" "}
                          -{" "}
                          {format(
                            new Date(pushRatePeriod[0].endDate),
                            "dd MMM yy"
                          )}
                        </div>
                      </div>
                    </div>
                    {showCal && (
                      <div className="absolute z-50 left-0 top-[80px]">
                        <DateRangePicker
                          minDate={new Date()}
                          ranges={pushRatePeriod}
                          onChange={(range) => {
                            {
                              setPushRatePeriod([
                                {
                                  startDate: range.range1?.startDate,
                                  endDate: range.range1?.endDate,
                                  id: "range1",
                                },
                              ]);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {/* Selected Dates Start */}
                  <div className="my-2">
                    <div className="text-sm text-white">Selected Dates: </div>
                    <div className="max-w-[100%] flex-wrap min-w-[100%] gap-2 max-h-[200px] overflow-y-auto grid grid-cols-4">
                      {pushRateSelectedDate &&
                        pushRateSelectedDate.map((date, index) => {
                          return (
                            <div className="min-w-[25%] my-2 text-sm flex gap-2 rounded-md items-center justify-between bg-[#383c4f] text-[#ffffff] py-1 px-2">
                              <div>
                                {format(date, "EEE, dd MMM yy") ||
                                  format(new Date(date), "EEE, dd MMM yy")}
                              </div>
                              <div>
                                <AiOutlineClose
                                  color="#F24333"
                                  onClick={() => handleDeleteDate(date)}
                                  className="cursor-pointer"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* Selected Dates End */}
                  {/* Same / Different Price Starts  */}
                  {/* <div>
                    <div className="flex gap-4 items-center text-sm">
                      <div
                        onClick={() => setIsSame(true)}
                        className={`px-4 py-1 rounded-lg ${isSame ? "bg-[#2F6690] border border-[#2F6690] text-[#f1f3f0]" : "border border-[#2F6690]"} cursor-pointer transition-all ease-in-out duration-150`}
                      >
                        Same Price
                      </div>
                      <div
                        onClick={() => setIsSame(false)}
                        className={`px-4 py-1 rounded-lg ${!isSame ? "bg-[#2F6690] border border-[#2F6690] text-[#f1f3f0]" : "border border-[#2F6690]"} cursor-pointer transition-all ease-in-out duration-150`}
                      >
                        Different Prices
                      </div>
                    </div>
                  </div> */}
                  {/* Same / Different Price Ends  */}
                  {/* Different Price Block Starts */}
                  {!isSame && (
                    <div className="w-[100%] relative max-h-[300px] pb-4 overflow-y-auto rounded-md bg-transparent">
                      <div className="flex w-[100%] border-b border-[#E1E1E1] px-4 py-6 bg-[#383c4f] text-white h-[45px] sticky top-0  gap-4  items-center">
                        <div className="text-xs w-[100%] ">
                          Dates
                        </div>
                        <div className="text-xs w-[100%] ">
                          Occupancy
                        </div>
                        <div className="text-xs w-[100%] ">
                          Current Rate
                        </div>
                        <div className="text-xs w-[100%] ">
                          CM Rates
                        </div>
                        <div className="text-xs w-[100%] ">
                          Discount
                        </div>
                        <div className="text-xs w-[100%] ">
                          Suggested Rate
                        </div>

                        <div className="text-xs w-[100%] ">
                          Suggested Rates in CM
                        </div>

                        <div className="text-xs w-[100%] ">
                          Overridden Rate
                        </div>
                        <div className="text-xs w-[100%] ">
                          Overridden Rate in CM
                        </div>
                      </div>
                      {differentPriceData &&
                      !getActualAndSuggestedRatesIsLoading &&
                      !getActualAndSuggestedRatesIsFetching ? (
                        differentPriceData.map((item, index) => {
                          return (
                            <div
                              className={`flex w-[100%] ${index % 2 === 0 ? "bg-[#f9fafc3a]" : "bg-[#f9fafc58]"} text-white border-[#E1E1E1] group px-4 gap-4 items-center`}
                            >
                              <div className="w-[100%] text-sm">
                                {format(new Date(item?.date), "EEE, dd MMM yy")}
                              </div>
                              <div className="w-[100%] text-sm">
                                <ProgressBar
                                  width={120}
                                  maxValue={100}
                                  value={item?.occupancy}
                                />
                              </div>
                              <div className="w-[100%] text-sm">
                                {formatCurrency(item?.rate)}
                              </div>
                              <div className="w-[100%] text-sm">
                                {formatCurrency(item?.CMRate)}
                              </div>
                              <div className="w-[100%] text-sm">
                                <ProgressBar
                                  width={120}
                                  maxValue={100}
                                  value={item?.discount}
                                />
                              </div>
                              <div className="w-[100%] text-sm">
                                {formatCurrency(item?.suggestedRate)}
                              </div>

                              <div className="w-[100%] text-sm">
                                {formatCurrency(
                                  item?.suggestedRateWithDiscount
                                )}
                              </div>

                              <div className="w-[100%] flex items-center gap-2">
                                {" "}
                                <input
                                  type="text"
                                  value={item?.overRideRate}
                                  onChange={(e) =>
                                    handleDifferentPrice(
                                      index,
                                      e.target.value,
                                      item?.discount
                                    )
                                  }
                                  className="w-[100%] placeholder:text-xs border my-1 px-2 border-gray-400 rounded-lg h-10 focus:outline focus:outline-orange-600 focus:border-none"
                                  placeholder={`Enter Rate`}
                                  step={"0.01"}
                                />
                                <AiFillCopy
                                  color="white"
                                  onClick={() =>
                                    copyRate(
                                      differentPriceData,
                                      index,
                                      setDifferentPriceDate,
                                      item?.discount
                                    )
                                  }
                                  className="cursor-pointer group-hover:opacity-100 opacity-0 duration-100 transition-all ease-in-out"
                                  size={20}
                                />
                                <AiFillDelete
                                  color="red"
                                  onClick={() => handleDeleteDate(item?.date)}
                                  className="cursor-pointer group-hover:opacity-100 opacity-0 duration-100 transition-all ease-in-out"
                                  size={20}
                                />
                              </div>
                              <div className="w-[100%] text-sm">
                                {formatCurrency(item?.overRiddenInCM)}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="w-[100%]">
                          <CustomSkeleton height={300} />
                        </div>
                      )}
                    </div>
                  )}
                  {/* Different Price Block Ends */}
                  {isSame && (
                    <div>
                      <div className="text-sm">Current Rate</div>
                      <div className="text-gray-800 font-medium text-lg mt-1">
                        {formatCurrency(
                          getActualAndSuggestedRates &&
                            getActualAndSuggestedRates?.data[0]?.rate
                        )}
                      </div>
                    </div>
                  )}
                  <div className=" flex gap-4 mb-4 justify-between">
                    {isSame && (
                      <div>
                        <div className="text-sm mb-2">
                          Suggested Rate{" "}
                          <span className="text-xs text-gray-500">
                            {/* (Recommended) */}
                          </span>
                        </div>
                        <div className="text-green-600 text-[20px] font-medium mt-1">
                          {formatCurrency(
                            getActualAndSuggestedRates &&
                              getActualAndSuggestedRates?.data[0]?.suggestedRate
                          )}
                        </div>
                      </div>
                    )}
                    {isSame && (
                      <div>
                        <div className="text-sm mb-2">Override Rate</div>
                        <div className="text-orange-600 text-lg mt-1">
                          <input
                            type="text"
                            value={overRideRate}
                            onChange={handleChange}
                            className="w-[100%] border px-2 border-gray-400 rounded-lg h-10 focus:outline focus:outline-orange-600 focus:border-none"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {overRideRate !== suggestedRate && (
                  <div className="text-red-600 text-sm mt-2 flex items-center gap-2">
                    <AiFillWarning /> You might be losing revenue by 25%
                  </div>
                )}
                {/* <div className="mt-4">
                  <div className="text-sm">Rate to Push</div>
                  <div className="text-gray-600 text-[20px] font-medium mt-1">
                    {formatCurrency(overRideRate)}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Backdrop>
      )}
      <BackdropLoader loading={isLoading} />
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {/* <div
            onClick={hideToggle}
            className="text-mainLight flex justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] w-[40px] h-[40px] mr-2 dark:text-mainDark font-medium text-[17px]"
          >
            <img src={backButton} />
          </div> */}
          <div className="text-white dark:text-mainDark font-medium text-[17px]">
            Rate Analysis
          </div>
        </div>
        {/* <div className="flex gap-2">
          <div>
            <Dropdown
              options={[{ label: "Room Type Selectors" }]}
              selectedItem={"Room Type Selectors"}
            />
          </div>
          <div>
            <Dropdown
              options={[{ label: "Room Type Selectors" }]}
              selectedItem={"Rate Strategy Selector"}
            />
          </div>
        </div> */}
        <div className="flex items-center gap-4">
          <div
            onClick={() => setIsPushRate(true)}
            className="px-3 py-1 rounded-lg text-white bg-[#1D2D44] text-[12px] cursor-pointer w-[100%] text-center"
          >
            Override Rates
          </div>
          {/* <div className="flex gap-2 items-center">
            <div className="text-sm">Suggestion</div>
            <CheckBox
              checked={isToggled}
              onChange={toggleButton}
              name={"toggle"}
            />
            <div className="text-sm">Dynamic</div>
          </div> */}
          {/* <Dropdown
            options={options}
            selectedItem={selectedType?.label}
            setSelectedItems={setSelectedType}
            // height={36}
          /> */}
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            enableOneYearFromNow={true}
            disablePastDays={true}
            width={170}
            customAlert={
              "Only 1 year future date are enable from current date."
            }
          />
        </div>
      </div>

      <div className="flex gap-2 cursor-pointer scrollHide overflow-x-scroll w-[100%]">
        {renderDays()}
      </div>
      <div className="flex gap-4   w-[100%] mt-2 justify-between">
        <div className="w-[100%] xl:w-[100%] sm:w-[100%]">
          <HorizontalCard
            currentRate={rateSuggestionByDate?.data?.rate}
            suggestedRate={rateSuggestionByDate?.data?.suggestedRate}
            isOverride={
              rateSuggestionByDate?.data?.overRideRate &&
              rateSuggestionByDate?.data?.overRideRate > 0
                ? true
                : false
            }
            onClick={() => setIsPushRate(true)}
            overRideRate={rateSuggestionByDate?.data?.overRideRate}
          />
          <div className="mt-4 p-4 rounded-xl text-[14px]  bg-newGradient dark:bg-lightGradient max-h-[450px] min-h-[450px] w-[100%] dark:bg-[#252C3B]">
            <div className="flex justify-between text-white items-center">
              <div>Rate Suggesstion Breakdown</div>
              <div>
                {" "}
                <InfoToolTip
                  position={"left"}
                  content={
                    "Rate Suggestion Breakdown: Help us improve by providing feedback on clarity, relevance, helpfulness, visibility, and design."
                  }
                  index={"avs"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4 max-h-[340px] overflow-y-auto scrollHide">
              {Array.isArray(rateSuggestionByDate?.data?.breakdown) &&
                rateSuggestionByDate?.data?.breakdown.map((rate, index) => {
                  return (
                    <BreakdownCard
                      price={rate?.value}
                      index={index}
                      title={rate?.label}
                      subBreak={rate?.subBreak}
                    />
                  );
                })}
            </div>
          </div>
          {/* {role === "SUPERADMIN" && (
            <div>
              <SuperAdminRateSuggestion
                period={period}
                setPeriod={setPeriod}
                inputValue={inputValue}
                setInputValue={setInputValue}
                calendarOpen={calendarOpen}
                setCalendarOpen={setCalendarOpen}
                reason={reason}
                setReason={setReason}
                rate={rate}
                setRate={setRate}
                setFactors={setFactors}
                factors={factors}
                handlePush={handlePush}
              />
            </div>
          )} */}
        </div>
        <div className="w-[100%]">
          <RateVsCompetition
            data={rateLeaderborad && otherPropDetails}
            asPer={rateLeaderborad?.data?.asPer}
            myProp={myProp}
            title={"Suggested Rate vs Competition"}
            img={icon}
            toolTipText={
              "Suggested Rate vs Competition: Compare our rates with competitors to make informed decisions."
            }
            isSuggested={true}
          />
        </div>
      </div>
    </div>
  );
};

export default RateAnalysis;

const BreakdownCard = ({ title, price, subBreak, index }) => {
  // ${index %2 ===0 ? 'bg-gray-100' : ''}
  return (
    <div
      className={`w-[100%] text-[#ffffff] p-2 rounded-lg group text-[12px] font-[500]  flex justify-between`}
    >
      <div className="flex items-start gap-2">
        <div className="w-fit h-[10px] rounded-full mt-1">
          <FaCircle color="#33BFFF" size={8} />
        </div>
        <div className="group-hover:pl-2 font-semibold text-white transition-all ease-in-out duration-200">
          {CapitalizeFirstLetter(title?.split("_").join(" "))}
          <div className="flex flex-col gap-2 mt-4">
            {Array.isArray(subBreak) &&
              subBreak?.map((item) => {
                return (
                  <div className="mx-4 flex  w-[80%] font-medium items-start gap-2 text-white">
                    <div>
                      <IoMdReturnRight className="mt-1" />
                    </div>
                    {item}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="px-4 w-[200px] text-white text-md text-end">
        {formatCurrency(price)}
      </div>
    </div>
  );
};

export const BackButton = ({ onClick, dashboard, mainDashboard }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      onClick={() =>
        navigate(
          location?.pathname.includes("rate-pulse") &
            !location?.pathname.includes("/rate-pulse/dashboard")
            ? "/rate-pulse/dashboard"
            : location?.pathname.includes("/reservation/reservation_Details")
              ? "/reservations"
              : location?.pathname?.includes("/reservation/reservation_Details")
                ? "/reservations"
                : mainDashboard
                  ? "/"
                  : -1
        )
      }
      className="text-mainLight flex justify-center gap-2 items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#111112] w-fit px-2 text-white text-xs h-[35px] mr-2 dark:text-mainDark font-medium text-[17px]"
    >
      <img src={backButton} /> Back
    </div>
  );
};

const SuperAdminRateSuggestion = ({
  calendarOpen,
  setCalendarOpen,
  period,
  setPeriod,
  inputValue,
  setInputValue,
  reason = [],
  setReason,
  setRate,
  rate,
  setFactors,
  factors,
  handlePush,
}) => {
  const [chipInput, setChipInput] = useState();
  const [period1ShowDropDown, setPeriod1ShowDropDown] = useState(false);
  const ref2 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setCalendarOpen(false);
        setPeriod1ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);

  const handleInputChange = (e) => {
    setChipInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && chipInput.trim() !== "") {
      setFactors([...factors, chipInput.trim()]);
      setChipInput("");
    }
  };

  const handleChipDelete = (chipIndex) => {
    const newFactors = factors.filter((_, index) => index !== chipIndex);
    setFactors(newFactors);
  };
  return (
    <div className="bg-white p-4 mt-4 rounded-lg">
      <div>Actual Rate</div>
      <div className="flex items-center w-fit mt-[30px] gap-2" ref={ref2}>
        <TimeRange
          calendarOpen={calendarOpen}
          setCalendarOpen={setCalendarOpen}
          period={period}
          setPeriod={setPeriod}
          inputValue={inputValue}
          setInputValue={setInputValue}
          isFuture={true}
          showDropDown={period1ShowDropDown}
          setShowDropDown={setPeriod1ShowDropDown}
        />
        <div className="flex gap-4 items-center">
          <div>
            <input
              type="text"
              className="border rounded-md px-4 h-10 w-[250px] focus:outline-none placeholder:text-sm"
              value={chipInput}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Factors: Type and press Enter..."
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex gap-2">
        {Array.isArray(factors) &&
          factors.map((factor, index) => (
            <div
              key={index}
              className="chip w-fit flex gap-4 items-center justify-between px-3 rounded-lg bg-[#1abc9c] text-white py-1"
            >
              {factor}
              <button className="" onClick={() => handleChipDelete(index)}>
                <AiFillCloseCircle color="white" />
              </button>
            </div>
          ))}
      </div>
      <div className="mt-4 flex items-center gap-4">
        <div>
          <input
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            className="border rounded-md px-4 h-10 w-[250px] focus:outline-none placeholder:text-sm"
            placeholder="Enter your reason"
          />
        </div>
        <div className="flex gap-4 items-center">
          <div>
            <input
              className="border rounded-md px-4 h-10 w-[250px] focus:outline-none placeholder:text-sm"
              placeholder="Enter your rate"
              value={rate}
              type="number"
              onChange={(e) => setRate(e.target.value)}
            />
          </div>
          <div>
            <div
              onClick={handlePush}
              className="px-4 py-1 bg-blue-600 cursor-pointer text-white rounded-lg"
            >
              Push
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ value, maxValue, width }) => {
  // Ensure value is between 0 and maxValue
  const boundedValue = Math.max(0, Math.min(value, maxValue));
  const progressPercentage = (boundedValue / maxValue) * 100;

  // Determine the color based on the progress
  let color;
  if (progressPercentage <= 33) {
    color = "#FCC3C3";
  } else if (progressPercentage <= 66) {
    color = "#FCEDC5";
  } else {
    color = "#C3FCE1";
  }

  let textColor;

  if (progressPercentage <= 33) {
    textColor = "#BE0000";
  } else if (progressPercentage <= 66) {
    textColor = "#BE8800";
  } else {
    textColor = "#00BE64";
  }

  // Apply the max-width and width styles
  const progressStyle = {
    width: `${progressPercentage}%`,
    backgroundColor: color,
    height: "30px",
    transition: "width 0.3s ease-in-out",
    color: textColor,
  };

  const containerStyle = {
    width: width || "100%",
    backgroundColor: "#F9FAFC",
    borderRadius: "5px",
    overflow: "hidden",
  };

  const negativeProgressStyle = {
    width: `${-progressPercentage}%`,
    backgroundColor: "blue",
    height: "30px",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle}>
      {value >= 0 ? (
        <div style={progressStyle} className="flex items-center">
          <span className="px-2">{value}%</span>
        </div>
      ) : (
        <div className="flex items-center" style={negativeProgressStyle}>
          <span className={`px-2 ${value?.toString().includes('-') ? 'text-black':''} `}>{value}%</span>
        </div>
      )}
    </div>
  );
};
