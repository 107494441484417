import { emptySplitApi } from "../../injectEndpoints";

export const Reputation = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReputationOverview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/reputationOverview`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getReputationOverview"],
    }),
    getRatingOverview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rattingOverview`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatingOverview"],
    }),
    getRatingLeaderboard: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rattingLeaderboard`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatingLeaderboard"],
    }),
    getRatingRecentReview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/recentReview`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatingRecentReview"],
    }),
    getRatingTrend: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/rattingTrend`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getRatingTrend"],
    }),
    getAllReview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/getAllReview`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getAllReview"],
    }),
    getReviewByKeyword: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/getSentimentViaKeyword`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getReviewByKeyword"],
    }),
    getReviewkeyword: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/getReviewkeyword`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getReviewkeyword"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReputationOverviewQuery,
  useGetRatingOverviewQuery,
  useGetRatingLeaderboardQuery,
  useGetRatingRecentReviewQuery,
  useGetRatingTrendQuery,
  useGetAllReviewQuery,
  useGetReviewByKeywordQuery,
  useGetReviewkeywordQuery
} = Reputation;
