import React, { useState } from 'react';
import './Global.css';
import Img from '../../assets/img/Branding.svg'
import Img2 from '../../assets/img/Frame 1321314698.svg'
import SimpleButton from '../../components/button/SimpleButton';
import MyUniversalComponent from '../../components/sideComponent/leftSection';
import Email from '../../assets/icons/onboarding/Email.svg'
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../components/inputFeild';
import { useForgotPasswordMutation, useUserSignupMutation } from '../../redux/slices/onBoarding';
import { toast } from "react-toastify";
import Backdrop from '../../components/backdrop'
import { SyncLoader } from 'react-spinners'
import leftArrow from "../../assets/img/leftarrow.png"

const ForgotPassword = ({ text }) => {
    const [showResendOtp, setShowResendOtp] = useState(false);
    const [forgotPassword] = useForgotPasswordMutation()
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const methods = useForm({});

    if (showResendOtp) {
        return <ResendOtp />;
    }
    const onSubmit = async (data) => {
        setLoading(true);
        const forgotPasswordData = {
            email: data.email,
        };
        forgotPassword(forgotPasswordData)
            .unwrap()
            .then((res) => {
                toast.success(res.message, { position: "bottom-right" });
                setShowResendOtp(true)
                setLoading(false);

            })
            .catch((err) => {
                toast.error(err.message, { position: "bottom-right" });
                setLoading(false);
            });
    }
    const handleContactClick = () => {
        alert('Please Add Contact');
    };
    const handleLoginClick = () => {
        navigate('/login')
    }
    return (
        <>
            {
                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>
            }
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className='login-container'>
                        <MyUniversalComponent
                            title="Made For True Hoteliers"
                            subtitle="Step into a world where innovation meets intuition, tailored specifically for the discerning hotelier. Unlock the gateway to a new era of hospitality excellence with RMS - where every detail is designed with you in mind."
                            imageSrc={Img2}
                        />
                        <div className='login-main'>
                            <div className='login-main-content'>
                                <div className='flex w-full justify-center'>
                                    <img className='img-logo' src={Img} alt='' />
                                </div>
                                <div className='flex items-center justify-center flex-col gap-2'>
                                    <h1 className='text-[1.2rem] text-[#0D1321] text-center font-sans font-semibold'>Forgot Password</h1>
                                    <p className='text-[0.8rem] text-center text-[#485266]'> Enter the email associated with your account. We'll send you a link to reset your password.</p>
                                </div>
                                <div className='flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white '>
                                    <div className='w-full'>
                                        <InputField
                                            placeholder={"Email"}
                                            className={"w-full text-gray-600 rounded-t-lg"}
                                            label={"Email"}
                                            id={"email"}
                                            control={methods.control}
                                            name={"email"}
                                            type={"email"}
                                            startIcon={Email}
                                            rules={{
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^\S+@\S+\.\S+$/,
                                                    message: 'Entered value does not match email format'
                                                }
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col w-full gap-4'>
                                    <SimpleButton text='Reset Password' type='submit' />
                                    <div className='flex p-2  justify-between w-full gap-4 items-center'>
                                    <div>
                                       
                                                <span onClick={handleLoginClick} className='text-sm font-[400] flex items-center justify-center   h-10 w-20 rounded-md text-[#3F70ED] border  cursor-pointer gap-2'><img className='w-5' src={leftArrow}/>Back</span>
                                            
                                        </div>
                                        {/* <div>
                                            <p className='text-sm font-[400] text-[#0D1321]'>Need Help?
                                                <span onClick={handleContactClick} className='text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer'>Contact Us</span>
                                            </p>
                                        </div> */}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>

    );
};
export default ForgotPassword;

const ResendOtp = ({ text }) => {
    const emailIdString = window.localStorage.getItem('email');
    const emailId = JSON.parse(emailIdString);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const methods = useForm({});
    const [userSignup] = useUserSignupMutation();

    const resendOtp = async () => {
        setLoading(true);
        if (emailIdString) {
            let signUpData = {
                email: emailId,
                // other data if needed
            };

            userSignup(signUpData)
                .unwrap()
                .then((res) => {
                    toast.success(res.message, { position: "bottom-right" });
                    setLoading(false);
                })
                .catch((err) => {
                    toast.error(err.message, { position: "bottom-right" });
                    setLoading(false);
                });
        } else {
            console.error('Email is undefined');
        }
    };

    return (
        <>
            {
                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>
            }
            <div className='login-container'>
                <MyUniversalComponent
               title="Made For True Hoteliers"
               subtitle="Step into a world where innovation meets intuition, tailored specifically for the discerning hotelier. Unlock the gateway to a new era of hospitality excellence with RMS - where every detail is designed with you in mind."
                    imageSrc={Img2}
                />
                <div className='login-main'>
                    <div className='login-main-content'>
                        <div className='flex w-full justify-center'>
                            <img className='img-logo' src={Img} alt='' />
                        </div>
                        <div className='flex items-center justify-center flex-col gap-2'>
                            <h1 className='text-[1.2rem] text-[#0D1321] text-center font-sans font-semibold '>Check Your Inbox</h1>
                            <p className='text-[0.8rem] text-center text-[#485266]'>We have sent a link on your registered email address, you can make a new password after</p>
                        </div>
                        <div className='flex flex-col w-full gap-4'>
                            <div className='flex flex-col w-full gap-4 items-center'>
                                <div>
                                    <p className='text-sm font-[400] text-[#0D1321]'>Not Received?<span className='text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer' onClick={resendOtp}>Resend</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>

    );
};



