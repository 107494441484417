const SelectableTab = ({ typesArray, selectedIndex, setSelectedIndex }) => {
    return (
      <div className="w-[100%] mt-6 flex justify-start gap-4">
        {typesArray &&
          typesArray.map((typeName, index) => {
            return (
              <div
                className={`h-[32px] cursor-pointer ${selectedIndex === index ? "border-b-2 border-b-[#081439] text-[#0D1321]" : "text-[#0D1321]"} `}
                onClick={() => setSelectedIndex(index)}
              >
                {typeName}
              </div>
            );
          })}
      </div>
    );
  };

  export default SelectableTab