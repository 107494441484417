import React, { useState } from 'react'
import Button from "../../../assets/icons/backButton.svg"
import User from "../../../assets/img/User.svg"
import CheckBox from '../../../components/checkbox/CheckBox';
import Dropdown from '../../../components/dropdown';
const ChannelCard = ({ title, onClick, checked, onChange, check, selectedItem, dropdown,setSelectedItem,options}) => {

    const [isHovered, setIsHovered] = useState(false);
    const hoverStyle = {
        height: isHovered ? '54px' : '52px',
        borderLeft: isHovered ? '4px solid #007BFF' : 'none',
        transition: 'height 0.2s ease',
    };
    const innerContentStyle = {
    
    };
    return (
        <>
            <div className='  bg-newGradient text-white dark:bg-lightGradient   flex items-center w-[100%] h-[52px]  rounded-[16px] p-2 cursor-pointer'
                style={{ ...hoverStyle }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={onClick}
            >

                <div className='flex items-center justify-between w-[100%] p-2' style={innerContentStyle}>
                    <div className='flex items-center gap-4'>
                        <div>
                            <img src={User} alt='' className='filter invert' />
                        </div>
                        <div className='text-[14px] '>{title}</div>
                    </div>
                    <div className='cursor-pointer'>
                       {!check && !dropdown && <img src={Button} alt='' className='filter invert' />}
                        {check && <CheckBox checked={checked}  onChange={onChange} name={title} />}
                        {dropdown && <Dropdown selectedItem={selectedItem} options={options} setSelectedItems={setSelectedItem} />}
                    </div>

                </div>
            </div>
        </>
    )
}
export default ChannelCard
