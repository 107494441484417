import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CustomSkeleton from "../Skeleton";
import formatCurrency from "../../utils/formatCurrency";

function removeSymbolsAndReturnNumbers(arr) {
    return arr.map(item => {
      // Check if the item is an object with a `data` property
      if (typeof item === 'object' && item !== null && 'data' in item) {
        // Process the `data` array within the object
        return {
          ...item,
          data: item.data.map(dataItem => {
            if (typeof dataItem === 'string') {
              const numberString = dataItem.replace(/[^0-9]/g, '');
              return Number(numberString);
            }
            return dataItem;
          })
        };
      }
      
      // Normal processing for strings or numbers
      if (typeof item === 'string') {
        const numberString = item.replace(/[^0-9]/g, '');
        return Number(numberString);
      }
      return item;
    });
  }
  

const BarChartComponent = ({
  graphData,
  period1,
  period2,
  hotelPerformanceIsLoading,
  name,
  singleValue,
  singleSecondValue,
  
}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!hotelPerformanceIsLoading && graphData) {
      const transformedData = transformData(graphData);
      setChartData(transformedData);
      setChartOptions(getChartOptions(transformedData, name));
      setIsLoading(false);
    }
  }, [graphData, hotelPerformanceIsLoading, name]);

  const transformData = (graphData) => {
    try {
      if (!graphData || graphData.length === 0) {
        return [
          {
            name: "No Data",
            data: [0, 0],
          },
        ];
      }
      if (name === "Competitor") {
        return [
          {
            name: "Period 1",
            data: graphData.map((item) => item?.rate || 0),
          },
        ];
      } else if (name === "Reputation") {
        return [
          {
            name: "Period 1",
            data: graphData.map((item) => item?.ratingCount || 0),
          },
        ];
      } else if (name === "Ranks") {
        return [
          {
            name: "Period 1",
            data: graphData.map((item) => item?.rank || 0),
          },
        ];
      } else {
        return [
          {
            name: "Period 1",
            data: graphData.map((item) => item?.period1 || 0),
          },
          {
            name: "Period 2",
            data: graphData.map((item) => item?.period2 || 0),
          },
        ];
      }
    } catch (error) {
      console.error("Error transforming data:", error);
      return [
        {
          name: "Error",
          data: [0, 0],
        },
      ];
    }
  };

  const getChartOptions = (transformedData, name) => {
    // Assuming you have a way to determine if the dark theme is active
    const isDarkTheme = true; // This should be based on your theme logic
  
    const categories = graphData.map((item) => item?.name || item?.otaName);
    
    return {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#00E396", "#0090FF", "#FEB019"], // Adjusted colors for Net Profit, Revenue, Free Cash Flow
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories || [], // Month names
        labels: {
          rotate: 0, // Set rotation to 0 degrees to avoid rotating the labels
          style: {
            fontSize: '8px', // Adjust font size if needed
            fontFamily: 'Helvetica, Arial, sans-serif',
            colors: isDarkTheme ? '#FFFFFF' : '#000000', // Text color based on dark theme
          },
          formatter: function (val) {
            const maxLength = 5; // Set the maximum length for the category names
            return val.length > maxLength ? val.slice(0, maxLength) + '...' : val;
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: isDarkTheme ? '#FFFFFF' : '#000000', // Text color based on dark theme
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const fullCategoryName = categories[dataPointIndex];
          const value = series[seriesIndex][dataPointIndex];
          
          // Create custom tooltip HTML
          return `
            <div style="
              background: #e9eef5;
              padding: 10px;
              border-radius: 5px;
              box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
              font-family: Arial, sans-serif;
              font-size: 12px;
              color: #333;
              text-align: left;
            ">
              <div style="
                font-weight: bold;
                margin-bottom: 5px;
                color: #333;
              ">${fullCategoryName}</div>
              <div style="display: flex; align-items: center;">
                <span style="
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background-color: ${w.config.colors[seriesIndex]};
                  margin-right: 8px;
                "></span>
                <span style="font-weight: bold; margin-right: 4px;">Period 1:</span>
                <span>${value}</span>
              </div>
            </div>`;
        },
      },
      
      legend: {
        position: "top", // Adjust the legend position
        horizontalAlign: "center",
        labels: {
          colors: isDarkTheme ? '#FFFFFF' : '#000000', // Text color based on dark theme
        },
      },
    };
  };

  useEffect(() => {
    if (name === "Reputation" && graphData && graphData.length > 0) {
      console.log(name);
    }
  });

  return (
  <div className="p-6 w-full max-w-full  h-full max-h-[500px] rounded-2xl shadow-lg bg-newGradient dark:bg-lightGradient dark:text-white overflow-hidden">
      <div className="flex justify-between items-center w-full mb-6">
        <div className="w-full flex justify-between">
          <div className="text-xl font-semibold items-center">
            <div className="mr-2 text-white  ">{name}</div>
           
          <p className="text-sm text-white   ">{(name==
        'Competitor'|| name=='Ranks')?'Comparison among competitor':(name=='Lead'|| name=='Source')?'Comparison of Sources':'Comparison of Periods'}</p>
          </div>
          <div className="" >

        
     {( name=='Revenue' || name=='Cancellations' || name=='Room' || name=='Lead') &&   <div className="text-4xl flex w-full     flex-col items-start font-bold">
            <div className="flex items-center  ">
              <h1 className="text-base text-white  ">Period 1: </h1>
              <p className="text-base text-white  ">{name=='Lead'?` ${period1} days`:  name=='Room' ?` ${period1} Room Nights` :period1}</p>
            </div>
            <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Period 2: </h1>
             <p className="text-base text-white  ">
  {name === 'Lead'
    ? `${period2} days`
    : name === 'Room'
    ? `${period2} Room Nights`
    : period2}
</p>
            </div>
          </div>}
          {/* {name=='Competitor' &&<div className="flex items-center gap02">
              <h1 className="text-base">Our Hotel Base Rate :</h1>
              <p className="text-base">{formatCurrency(singleValue)}</p>
            </div>} */}
            {name=='Competitor' && <div className="font-bold flex flex-col items-start gap-2">
            <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Our Hotel Base Rate :</h1>
              <p className="text-base text-white  ">{singleValue}</p>
              </div>
              <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Compset Base Rate: </h1>
              <p className="text-base text-white  ">{singleSecondValue}</p>
            </div>
            </div>
            }
              {name=='Reputation' && <div className="font-semibold items-center gap-2">
            <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Our Hotel Avg Rating :</h1>
              <p className="text-base text-white  ">{singleValue}</p>
              </div>
              <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Compset Avg Rating: </h1>
              <p className="text-base text-white  ">{singleSecondValue}</p>
            </div>
            </div>
            }
 {name=='Ranks' && <div className="font-bold items-center gap-2">
            <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Our Hotel Avg Rating :</h1>
              <p className="text-base text-white">{singleValue}</p>
              </div>
              <div className="flex items-center gap02">
              <h1 className="text-base text-white  ">Compset Avg Rating: </h1>
              <p className="text-base text-white  ">{Math.floor( singleSecondValue)}</p>
            </div>
            </div>
            }
              </div>
        </div>
      </div>
      <div className="w-full">
        <Chart
          options={chartOptions}
          series={removeSymbolsAndReturnNumbers(chartData)}
          type="bar"
          height={350}
        />
      </div>
    </div> 
  );
};

export default BarChartComponent;
