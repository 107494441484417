import React from "react";
import Chart from "react-apexcharts";

const ApexPieChart = ({ height,showLegend,value,label }) => {
  const chartData = {
    series: value ? value : [44, 55, 41, 17, 15],
    dataLabels: {
      show: false,
    },
    options: {
      chart: {
        type: "donut",
        hover: {
          fill: {
            colors: ["#24FFB0", "#FFDA56", "#A761FF", "#FF9533", "#FF5733"],
            opacity: 0.6,
          },
        },
      },
      labels: label ? label : [
        "Room Type 1",
        "Room Type 2",
        "Room Type 3",
        "Room Type 4",
        "Room Type 5",
      ],
      colors: ["#24FFB0", "#FFDA56", "#A761FF", "#FF9533", "#FF5733"],
      fill: {
        colors: ["#24FFB0", "#FFDA56", "#A761FF", "#FF9533", "#FF5733"],
      },
      dataLabels: {
        enabled: false, // Hide the labels
      },
      stroke: {
        width: 0,
      },
      legend:{
        show:showLegend
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
  
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="donut"
      width="400"
      height={height}
    />
  );
};

export default ApexPieChart;
