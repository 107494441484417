import React, { useEffect, useState } from "react";
import formatCurrency from "../../utils/formatCurrency";
import InfoToolTip from "../tooltip/InfoToolTip";
import { useIsIpad } from "../../utils/isIpad";

function isNumberOrFloat(value) {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
}

const StatsCard = ({
  progressPercentage,
  title,
  onClick,
  current,
  previous,
  showCurrency,
  percentage,
  isPercentage,
  additionalText,
  isPermission,
  permissionText,
  tooltipText
}) => {
  const currentYearPrice = current; // Static value for current year price
  const lastYearPrice = previous; // Static value for last year price

  // Calculate the percentage of last year's price in the progress bar
  const lastYearPercentage =
    (lastYearPrice / (currentYearPrice + lastYearPrice)) * 100;

  // Calculate the difference in prices and its percentage relative to the current year price
  const currentYearPercentage =
    (currentYearPrice / (currentYearPrice + lastYearPrice)) * 100;
  const priceDifference = currentYearPrice - lastYearPrice;
  const priceDifferencePercentage = (priceDifference / currentYearPrice) * 100;

  const priceDifferenceLeft = lastYearPercentage;

  const [progress, setProgress] = useState(priceDifferencePercentage);
  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    setProgress(value);
  };

  let progressBarColor;
  if (currentYearPrice > lastYearPrice) {
    progressBarColor = "#BAEDBD"; // Green color
  }
  if (currentYearPrice < lastYearPrice) {
    progressBarColor = "#FFADAD"; // Red color
  }

  const isIpad = useIsIpad()
 
  return (
    <div
      className={` ${isIpad ? 'h-auto':'h-[124px] '}cursor-pointer w-[100%] bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md dark:bg-[#252C3B] rounded-xl p-4 ${isPermission ? "" : "relative"}`}
      onClick={isPermission ? onClick : ()=>null}
    >
      <div className={`${isPermission ? "" : "blur-sm"}`}>
        <div className="flex items-center justify-between">
        <div className="dark:text-[#D3D8E1] text-white">{title || "Title"}</div>
        <div>
                  <InfoToolTip content={tooltipText} />
                </div>
        </div>
        <div className="text-[21px] text-white flex items-center gap-2 dark:text-[#D3D8E1]">
          <div>
            {showCurrency && formatCurrency(currentYearPrice || 0)}{" "}
            {!showCurrency && parseInt(currentYearPrice || 0).toFixed(0)}
            {additionalText && ` ${additionalText}`}
          </div>
          <div
            className={`text-sm ${parseInt(percentage).toFixed(2).toString().includes("-") ? "text-red-500" : "text-green-500"} `}
          >
            {!isPercentage &&
              current > previous != 0 &&
              current > previous &&
              "+"}

            {isNumberOrFloat(parseInt(percentage)) && isPercentage
              ? parseInt(percentage).toFixed(2).toString().includes("-")
                ? parseInt(percentage).toFixed(2).toString().split("-")[1]
                : parseInt(percentage).toFixed(2)
              : (isNumberOrFloat(parseInt(percentage)) && percentage) || 0}
            {isPercentage === true && "%"}
          </div>
        </div>
        {/* <div className="w-full mt-2 max-w-xs relative">
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleChange}
          className="w-full h-2 bg-gray-300 hidden rounded-full appearance-none"
        />

        <div
          className={`h-2 mt-2 relative rounded-lg`}
          style={{ background: progressBarColor }}
        >
          {lastYearPercentage < currentYearPercentage ? (
            <div
              className="h-full bg-[#7fE088] absolute top-0 left-0"
              style={{ width: `${lastYearPercentage}%` }}
            ></div>
          ) : (
            <div
              className="h-full bg-red-400 absolute top-0 left-0"
              style={{ width: `${lastYearPercentage}%` }}
            ></div>
          )}

          {priceDifferencePercentage > 0 ? (
            <div
              className="h-full bg-green-600 absolute top-0 "
              style={{
                width: `${priceDifferencePercentage}%`,
                left: `${priceDifferenceLeft}%`,
              }}
            ></div>
          ) : (
            <div
              className="h-full bg-red-600 absolute top-0"
              style={{
                width: `${lastYearPercentage + priceDifferencePercentage}%`,
                left: `${priceDifferenceLeft}%`,
              }}
            ></div>
          )}
          {lastYearPercentage < currentYearPercentage ? (
            <div
              className="w-2 h-6 bg-[#29CC39] border-4 border-[#29CC39] rounded-full absolute -top-[8px] -mt-1/2 "
              style={{
                left: `${lastYearPercentage + priceDifferencePercentage}%`,
              }}
            ></div>
          ) : (
            <div
              className="w-2 h-6 bg-red-600 border-4 border-red-600 rounded-full absolute -top-[8px] -mt-1/2 "
              style={{ left: `${lastYearPercentage}%` }}
            ></div>
          )}
        </div>
      </div> */}
        <Slider currentValue={current} previousYearValue={previous} />
        <div className="text-white dark:text-[#929EB5] mt-2 text-[14px]">
          {showCurrency && formatCurrency(lastYearPrice || 0)}{" "}
          {!showCurrency && parseInt(lastYearPrice || 0).toFixed(0)}{" "}
          {additionalText && <span>{additionalText}</span>} Last Year
        </div>
      </div>
     {!isPermission && <div className="absolute top-[40%] left-[30%] bg-[#1D2D44] text-white text-xs px-4 py-2 rounded-xl cursor-pointer">
     {permissionText ? permissionText : "Unlock Now"}
      </div>}
    </div>
  );
};

export default StatsCard;

const Slider = ({ currentValue, previousYearValue }) => {
  const maxValue = Math.ceil(Math.max(currentValue, previousYearValue)); // Calculate the maximum value and round it up

  const [fillColor, setFillColor] = useState("#ccc");

  useEffect(() => {
    if (currentValue < previousYearValue) {
      setFillColor("#ff3f34");
    } else if (currentValue > previousYearValue) {
      setFillColor("#05c46b");
    } else {
      setFillColor("#ccc");
    }
  }, [currentValue, previousYearValue]);

  return (
    <div
      style={{
        width: "100%",
        height: "10px",
        backgroundColor: "#eee",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${Math.min((currentValue / maxValue) * 100, 100)}%`, // Adjusted width calculation for range marker
          height: "100%",
          backgroundColor: `${currentValue < previousYearValue ? "#ff3838" : "#3ae374"}`, // Transparent color for range marker
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      {/* <div
        style={{
          width: `${Math.min((maxValue - currentValue) * 100, 100)}%`, // Adjusted width calculation for range marker
          height: "100%",
          backgroundColor: `red`, // Transparent color for range marker
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          position: "absolute",
          top: 0,
          left:0,
          // left: `${Math.min((currentValue - previousYearValue) * 100, 100)}%`,
        }}
      /> */}
      <div
        style={{
          width: `2%`, // Adjusted width calculation for range marker
          height: "20px",
          backgroundColor: `${currentValue < previousYearValue ? "#ff3838" : "#3ae374"}`, // Transparent color for range marker
          borderRadius: "5px",
          position: "absolute",
          top: '-4.5px',
          left: `${Math.min((currentValue / maxValue) * 100, 100)}%`,
        }}
      />
      <div
        style={{
          width: `${Math.min((currentValue / maxValue) * 100, 100)}%`,
          height: "100%",
          backgroundColor: fillColor,
          borderRadius: "5px",
        }}
      />
    </div>
  );
};
