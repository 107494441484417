import React from 'react';
import PropTypes from 'prop-types';
import '../../pages/onboarding/Global.css'

const TitleComponent = ({ title, subtitle, subtitle2, terms }) => {
    return (
        <>
            <div className='flex items-center justify-center flex-col gap-2'>
                <h1 className='title'>{title}</h1>
                <p className='subtitile'>{subtitle}</p>
                <p className='subtitile w-[80%]'>{subtitle2} <span className='font-semibold'>{terms}</span></p>
            </div>
        </>
    );
};


export default TitleComponent;
