import React, { useEffect, useState } from "react";
import { BackButton } from "../../rateSuggestion/rateAnalysis";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewCard from "../../../components/cards/reviewCard";
import { format } from "date-fns";
import OtaCards from "../../../components/otaCards";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import {
  useGetAllReviewQuery,
  useGetRatingOverviewQuery,
  useGetRatingRecentReviewQuery,
  useGetReviewByKeywordQuery,
} from "../../../redux/slices/reputation";
import BackdropLoader from "../../../components/newLoader";
                                                          
const ReviewsLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [allReviewsData,setAllReviewsData] = useState()
  const [OtaId, setOtaId] = React.useState();
  const {
    reviews,
    recentReviews,
    totalRating,
    otaId,
    selectedIndex: incomingIndex,
  } = location.state;
  const { data: getRatingOverview } = useGetRatingOverviewQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });

  const {
    data: recentsReviews,
    isLoading: recentReviewsIsLoading,
    isFetching: recentReviewsIsFetching,
  } = useGetAllReviewQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: OtaId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: allReviewByKeywords } = useGetReviewByKeywordQuery({
    hId: CurrentProperty(),
    userId: UserId(),
    keywords: keyword,
  },{refetchOnMountOrArgChange:true});

  useEffect(() => {
    if (otaId) {
      setOtaId(otaId);
      setSelectedIndex(incomingIndex);
    }
  }, []);

  useEffect(() => {
    if (keyword?.length > 0 && allReviewByKeywords) {
      setAllReviewsData(allReviewByKeywords?.data)
    } else {
      setAllReviewsData(recentsReviews && recentsReviews["data"]?.reviews)
    }

    if(keyword.length > 0 && allReviewByKeywords?.data.length === 0){
      setAllReviewsData(recentsReviews && recentsReviews["data"]?.reviews)
    }
  },[keyword,allReviewByKeywords]);

  useEffect(()=>{
    if(recentsReviews &&
      recentsReviews["data"] && recentsReviews["data"]?.reviews){
setAllReviewsData(recentsReviews?.data?.reviews)
    }
    
  },[recentsReviews])


  return (
    <div>
      <BackdropLoader
        loading={recentReviewsIsLoading || recentReviewsIsFetching}
      />
      <div className="flex justify-between items-center w-[100%]">
        <div className="text-[17px] flex items-center p-4 ">
          {/* <BackButton onClick={() => navigate(-1)} /> */}
             Reviews{" "}
        </div>
      </div>
      <div className="w-[100%] flex items-center justify-between">
        <div className="flex overflow-scroll scrollHide gap-4 p-4">
          {getRatingOverview &&
            getRatingOverview["data"].map((ota, index) => {
              return (
                <OtaCards
                  index={index}
                  onClick={() => {
                    setSelectedIndex(index);
                    setOtaId(ota?.otaId);
                  }}
                  rank={ota.ratingScore}
                  title={ota.otaName}
                  selectedIndex={selectedIndex}
                  // data={data}
                  otaLogo={ota?.otaImage}
                  isCurrency={false}
                  totalCount={ota.totalRatingCount}
                  totalCountState={true}
                />
              );
            })}
        </div>
        <div className="text-sm  mx-4 flex  justify-center items-center gap-3">
          <div className="mb-0.5">Search Keyword</div>
          <input
            type="text"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="h-10 w-[200px] rounded-md border-none outline-gray-300 outline  text-sm placeholder:text-sm px-2 focus:outline focus:outline-gray-500"
            placeholder="Enter keyword here"
          />
        </div>
      </div>

      <div className="p-4 grid grid-cols-2 gap-4 min-h-[550px] max-h-[550px] overflow-y-scroll">
        {recentsReviews &&
          recentsReviews["data"] &&
          allReviewsData?.map((review) => {
            
            return (
              <ReviewCard
              otaLogo={
                recentsReviews?.data?.otaLogo
                }
                otaColor={
                  allReviewsData && allReviewsData?.otaColor
                }
                name={review?.guestName}
                description={review?.reviewText}
                rating={review?.rating}
                time={format(new Date(review?.publishDate), "dd-MMM-yy")}
                totalRating={recentsReviews && recentsReviews?.data?.otaTotalRating}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ReviewsLandingPage;
