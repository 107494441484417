import React, { useEffect, useState } from "react";
import "../Global.css";
import SimpleButton from "../../../components/button/SimpleButton";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import { useNavigate } from "react-router-dom";
import TitleComponent from "../../../components/cardComponent";
import icon1 from "../../../assets/img/1.svg";
import icon2 from "../../../assets/img/5.svg";
import icon3 from "../../../assets/img/3.svg";
import icon4 from "../../../assets/img/4.svg";
import { toast } from "react-toastify";
import { useGethotelIdMutation } from "../../../redux/slices/onBoarding";
import Backdrop from "../../../components/backdrop";
import { SyncLoader } from "react-spinners";

const AddCompetitionOtaLink = ({ setSteps, setData, steps, data: otaData }) => {
  const [gethotelId] = useGethotelIdMutation();
  const methods = useForm({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let body = document.body;
    body.style.overflow = "";
  });
  const onSubmit = (data) => {
    setLoading(true);

    const atLeastOneLinkFilled = [
      "makeMyTrip",
      "goibibo",
      "expedia",
      "agoda",
    ].some((fieldName) => !!data[fieldName]);

    if (!atLeastOneLinkFilled) {
      setError("makeMyTrip", {
        type: "manual",
        message: "Minimum 1 OTA is required",
      });
    } else {
      alert("Form submitted successfully");
    }
    const updatedHId = localStorage.getItem("hId");

    const addNewCompsetData = {
      otaDetails: [
        { otaId: 1, url: data.makeMyTrip },
        { otaId: 2, url: data.goibibo },
        { otaId: 3, url: data.booking },
        { otaId: 4, url: data.agoda },
        { otaId: 5, url: data.clearTrip },
        { otaId: 6, url: data.easeMyTrip },
        { otaId: 7, url: data.happyEasyGo },
      ],
    };

    gethotelId(addNewCompsetData)
      .unwrap()
      .then((res) => {
        setData(res.data);
        setSteps({
          step1: false,
          step2: false,
          step3: false,
          step4: false,
          step5: true,
        });

        toast.success(res.message, { position: "bottom-right" });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message, { position: "bottom-right" });
        setLoading(false);
      });
  };
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = methods;
  const options = [
    { label: "Channel Manager 1", value: "Channel Manager 1" },
    { label: "Channel Manager 2", value: "Channel Manager 2" },
  ];

  return (
    <>
      {loading && (
        <Backdrop>
          <SyncLoader color="white" />
        </Backdrop>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="verify-email-content2">
            <div className="flex flex-col">
              <TitleComponent
                title={"Set Your Online Travel Agents"}
                subtitle={"Set your OTA’s for easy monitoring"}
              />
              <p className="text-[#0D1321]">Your Competition OTA Links</p>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="w-full flex flex-row justify-center">
                <div className="flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white ">
                  <div className="w-full">
                    <InputField
                      placeholder={"MakeMyTrip"}
                      className={"w-full text-gray-600 rounded-t-lg "}
                      label={"MakeMyTrip"}
                      id={"makeMyTrip"}
                      type={"text"}
                      control={control}
                      name={"makeMyTrip"}
                      options={options}
                      startIcon={icon1}
                      rules={{ required: "MakeMyTrip link is required" }}
                      // onFocus={() => watch('focusFieldName', 'makeMyTrip')}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      placeholder={"Goibibo"}
                      className={"w-full text-gray-600"}
                      label={"Goibibo"}
                      id={"goibibo"}
                      name={"goibibo"}
                      type={"text"}
                      startIcon={icon2}

                      // onFocus={() => watch('focusFieldName', 'goibibo')}
                    />
                  </div>
                  <div className="w-full">
                    <InputField
                      placeholder={"Booking"}
                      className={"w-full text-gray-600 "}
                      label={"Booking"}
                      id={"booking"}
                      name={"booking"}
                      type={"text"}
                      startIcon={icon3}

                      // onFocus={() => watch('focusFieldName', 'expedia')}
                    />
                  </div>
                  <div className="w-full rounded-b-lg overflow-hidden">
                    <InputField
                      placeholder={"Agoda"}
                      className={"w-full text-gray-600 "}
                      label={"Agoda"}
                      id={"agoda"}
                      name={"agoda"}
                      type={"text"}
                      startIcon={icon4}

                      // onFocus={() => watch('focusFieldName', 'agoda')}
                    />
                  </div>
                  <div className="w-full rounded-b-lg overflow-hidden">
                    <InputField
                      placeholder={"ClearTrip"}
                      className={"w-full text-gray-600 "}
                      label={"ClearTrip"}
                      id={"clearTrip"}
                      name={"clearTrip"}
                      type={"text"}
                      startIcon={icon4}

                      // onFocus={() => watch('focusFieldName', 'agoda')}
                    />
                  </div>
                  <div className="w-full rounded-b-lg overflow-hidden">
                    <InputField
                      placeholder={"EaseMyTrip"}
                      className={"w-full text-gray-600 "}
                      label={"EaseMyTrip"}
                      id={"easeMyTrip"}
                      name={"easeMyTrip"}
                      type={"text"}
                      startIcon={icon4}

                      // onFocus={() => watch('focusFieldName', 'agoda')}
                    />
                  </div>
                  <div className="w-full rounded-b-lg overflow-hidden">
                    <InputField
                      placeholder={"HappyEasyGo"}
                      className={"w-full text-gray-600 "}
                      label={"HappyEasyGo"}
                      id={"happyEasyGo"}
                      name={"happyEasyGo"}
                      type={"text"}
                      startIcon={icon4}

                      // onFocus={() => watch('focusFieldName', 'agoda')}
                    />
                  </div>
                </div>
              </div>
              {errors.makeMyTrip && (
                <span className="error-message text-center text-[#E83B3B]">
                  {errors.makeMyTrip.message}
                </span>
              )}
              <SimpleButton text="Next" type="submit" disabled={!isValid} />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AddCompetitionOtaLink;
