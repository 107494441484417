import { Provider, useSelector } from "react-redux";
import "./App.css";
import RateX_Routes from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import AdminNavbar from "./components/adminNavbar";
import { useEffect, useState } from "react";
import noInternet from "../src/assets/noInternet.svg";
import { ApolloProvider } from "@apollo/client";
import client from "./ApolloClient";
import { isMobile } from "./utils/isMobile";
import IsMobile from "./components/isMobile/isMobile";
import './i18n'

function App() {
  const [isShow, setIsShow] = useState(false);
  const roleAdmin = window.localStorage.getItem("role") === "SUPERADMIN";
  const isAdmin = useSelector((state) => state?.isAdmin);
  useEffect(() => {
    if (isAdmin?.value || roleAdmin) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [isAdmin, roleAdmin]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      window.location.reload();
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(isMobile());
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="dark themeToggle ">
     {isMobileDevice ? <IsMobile /> : <>
      {isOnline ? (
        <>
          {/* {isShow && (
            <div className="p-2">
              {" "}
              <AdminNavbar />
            </div>
          )} */}
          <Provider store={store}>
            <Router>
              {/* <TopBar> */}
              <ApolloProvider client={client}>
              <RateX_Routes />
              </ApolloProvider>
              {/* </TopBar> */}
            </Router>

            {/* <AreaChart /> */}
            {/* <Testing5 /> */}
          </Provider>
        </>
      ) : (
        <div className="h-[100vh] w-[100%] dark:bg-lightGradient flex-col flex justify-center items-center bg-[#ffffff]">
          <img src={noInternet} width={"20%"} className="-mt-[100px]" />
          <div className="text-2xl text-white -mt-[48px]">
            Internet Connection Disconnected
          </div>
          <div className="text-white">
            Check your internet and try again later
          </div>
        </div>
      )}
      </>}
    </div>
  );
}

export default App;
