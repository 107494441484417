const GlobalModalComponent = ({ hideModal, onSubmit, children ,title,saveBtnTitle,showClearAll,
  height,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/20 bg-opacity-50 flex items-center justify-center z-50">
      <div className={ `${height === "true" ? 'h-[550px]' : 'h-[495px]'} relative bg-white w-8/12 rounded-lg`}>
        <div className="p-4 flex justify-between  items-center border-b border-b-[#D3D8E1]">
          <div className="text-[21px] font-[500] text-[#131926]">{title}</div>
         { showClearAll && <div className="text-[15px] font-[500] text-[#131926]">Clear All</div>}
        </div>
        <div className="px-6 py-4">{children}</div>

        <div className="p-4 bottom-0 absolute w-[100%] gap-4 flex justify-end  items-center border-t border-b-[#D3D8E1]">
          <div
            className="text-[14px] font-[400] px-[8px] rounded-lg py-[4px] cursor-pointer bg-[#EEF1F6] text-[#59647B] transition-all ease-in-out duration-200  hover:bg-[#CCD6E5] hover:text-black"
            onClick={hideModal}
          >
            Cancel
          </div>
          <div
            className="text-[14px] font-[400] px-[8px] py-[4px] rounded-lg cursor-pointer bg-[#1D2D44] hover:bg-[#1D3893] transition-all ease-in-out duration-200  text-white"
            onClick={onSubmit}
          >
            {saveBtnTitle || 'Save'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalModalComponent
