// Tooltip.js
import "./Tooltip.css";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const InfoToolTip = ({content,position,index}) => {
  return (
    <div
      className="max-w-[120px] truncate rounded-full"
      data-tooltip-id={index || "toolTip-id"}
      data-tooltip-content={content}
    >
      <AiOutlineInfoCircle color="white" />
      <Tooltip className="flex max-w-[200px] text-wrap text-left " variant="dark" id={index || "toolTip-id"}   place={position ? position :"top"} style={{borderRadius:'6px',fontSize:'12px'}}  />
    </div>
  );
};

export default InfoToolTip;
