import React, { useEffect, useState } from "react";
import img2 from "../../assets/icons/_Checkbox base.svg";
import leftArrow from "../../assets/icons/Component 15.svg";
import rightArrow from "../../assets/icons/Component 14.svg";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import sort from "../../assets/icons/Component 16.svg";
import RateRangeCard from "../../components/rateRangeCard";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";
import ExcelDownloadButton from "../../components/excelDownloadButton";
import { format } from "date-fns";
import { useIsIpad } from "../../utils/isIpad";

const NewTable = ({
  data,
  room,
  filename,
  startDate,
  endDate,
  title,
  isProperty,
  downloadData,
  currentPage ,
  setCurrentPage,
  totalpages,
  totalCount,
  totalData,
  havePagination=true,
  chainPropertyDate=false,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [totalRevenueLY, setTotalRevenueLY] = useState(0);
  const [totalRevenueTY, setTotalRevenueTY] = useState(0);
  const [totalADRLY, setTotalADRLY] = useState(0);
  const [totalADRTY, setTotalADRTY] = useState(0);
  const [totalRoomNightsLY, setTotalRoomNightsLY] = useState(0);
  const [totalRoomNightsTY, setTotalRoomNightsTY] = useState(0);
  const rowsPerPage = 5; // Change this value as per your requirement
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const [currentRows, setCurrentRows] = useState();
  // let currentRows = [];
const dataLength=data?.length 
const totalPage=Math.ceil(dataLength / rowsPerPage)
console.log(startDate,"startDate",endDate,"endDate")
  useEffect(() => {
    if (Array.isArray(data)) {
      const indexOfLastRow = currentPage * rowsPerPage;
      const indexOfFirstRow = indexOfLastRow - rowsPerPage;
      // currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
      setCurrentRows(data.slice(indexOfFirstRow, indexOfLastRow));
    } else {
    }
  }, [data]);


  const handlePreviousPage = () => {
    setCurrentPage((prev)=>prev - 1);
  };


  const handleNextPage = () => {
    setCurrentPage((prev)=>prev + 1);
  };


  useEffect(() => {
    calculateTotals();

   
  }, [data]);
  const calculateTotals = () => {
    const safeValue = (value) =>
      isNaN(value) || value === undefined ? 0 : value;

    if (Array.isArray(data) && data.length > 0) {
      let revenueLY = 0;
      let revenueTY = 0;
      let adrLY = 0;
      let adrTY = 0;
      let roomNightsLY = 0;
      let roomNightsTY = 0;

      const rowsToCalculate =
        selectedRows.length === 0 || selectedRows.length === data.length
          ? data
          : selectedRows.map((index) => data[index]);

      rowsToCalculate.forEach((item) => {
        revenueLY += safeValue(
          item?.revenueLastYear || item?.revenue?.previous
        );
        revenueTY += safeValue(item?.revenueThisYear || item?.revenue?.current);
        adrLY += safeValue(item?.ADRLastYear || item?.adr?.previous);
        adrTY += safeValue(item?.ADRThisYear || item?.adr?.current);
        roomNightsLY += safeValue(
          item?.roomNightsLastYear || item?.roomNights?.previous
        );
        roomNightsTY += safeValue(
          item?.roomNightsThisYear || item?.roomNights?.current
        );
      });

      setTotalRevenueLY(revenueLY);
      setTotalRevenueTY(revenueTY);
      setTotalADRLY(adrLY);
      setTotalADRTY(adrTY);
      setTotalRoomNightsLY(roomNightsLY);
      setTotalRoomNightsTY(roomNightsTY);
    } else {
      // If data is not an array or is empty, reset the totals
      setTotalRevenueLY(0);
      setTotalRevenueTY(0);
      setTotalADRLY(0);
      setTotalADRTY(0);
      setTotalRoomNightsLY(0);
      setTotalRoomNightsTY(0);
    }
  };

  useEffect(() => {
    calculateTotals();
  }, [selectedRows]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((_, index) => index));
  };
  const handleCheckboxChange = (index) => {
    if (selectAll) {
      // If selectAll is true, uncheck the clicked checkbox
      setSelectedRows(selectedRows.filter((item) => item !== index));
      setSelectAll(false); // Set selectAll to false
    } else {
      const isSelected = selectedRows.includes(index);
      if (isSelected) {
        setSelectedRows(selectedRows.filter((item) => item !== index));
      } else {
        setSelectedRows([...selectedRows, index]);
      }
    }
  };
  const handleToggleExpand = (index) => {
    setExpandedRow((prevRow) => (prevRow === index ? null : index));
  };
  const isTab = useIsIpad();
 
  return (
    <div className="flex flex-col bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md  rounded-lg">
      <div className="w-[100%] flex p-2 justify-center items-center mt-4">
        <div className="w-[50%] flex flex-col justify-around">
          <div className="pl-6 ml-6">
            <p className="text-[#ffffff] text-[16px]">
              {room && !title
                ? "Room Wise Comparison Breakdown"
                : title
                  ? title
                  : "Streamlined Comparison Analysis"}
            </p>
            <p className="text-[#ffffff] text-xs">
              {totalCount || dataLength} results found
            </p>
          </div>
        </div>
        <div className="w-[50%] flex justify-end pr-6">
          {/* <div className="border rounded-lg px-4 py-2 flex items-center mr-6 cursor-pointer">
                        <img src={sort} alt="" />
                        <p className="text-sm text-[#757D8A]">
                            Sort: <span>Chronological</span>
                        </p>
                    </div> */}
        </div>
      </div>
      <div className="w-[100%] border-b-4 text-white flex p-2 justify-center items-center">
        <div className="w-[20%] text-center text-base font-medium">
          <p className="w-[55%]">
            {room ? "Rooms" : isProperty ? "Property Name" : "Source"}
          </p>
        </div>
        <div className="w-[70px] md:w-[20%] text-center text-base font-medium">
          <p className="w-full md:w-[55%]">Revenue</p>
        </div>
        <div className="w-[70px] md:w-[20%] text-center text-base font-medium">
          <p className="w-full md:w-[70%]">ADR</p>
        </div>
        <div className="w-[70px] md:w-[20%] text-center text-base font-medium">
          <p className="w-full md:w-[80%]">Room Nights</p>
        </div>
        <div className="w-[20px] md:w-[20%] flex justify-between  md:justify-evenly ">
          <ExcelDownloadButton
            data={downloadData ? downloadData : data}
            filename={filename}
            intelligence={true}
          />
          {/* <div
            className="flex items-center cursor-pointer"
            onClick={handleSelectAll}
          >
            <input type={"checkbox"} className="w-[18px] h-[18px] ml-2" />
          </div> */}
        </div>
      </div>
      <div className="w-[100%] flex flex-col">
        <div class="h-auto scroll-container">
          {Array.isArray(data) &&
            data.map((item, index) => {
             
              return (
                <div key={index}>
                  <DataRow
                    key={index}
                    item={item}
                    index={indexOfFirstRow + index}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    onCheckboxChange={handleCheckboxChange}
                    selectedRows={selectedRows}
                    isExpanded={expandedRow === indexOfFirstRow + index}
                    toggleExpand={() =>
                      handleToggleExpand(indexOfFirstRow + index)
                    }
                    title={"Agoda"}
                    room={room}
                    startDate={startDate}
                    endDate={endDate}
                    chainPropertyDate={chainPropertyDate}
                  />
                </div>
              );
            })}
        </div>
        <div className="w-[100%] flex bg-newGradient dark:bg-lightGradient text-white p-4 rounded-lg">
        <div className="w-full md:w-[20%]  flex gap-2 items-center justify-start md:pl-6">  <p
            className={`${isTab ? "w-full" : "w-full"} text-start ml-4 text-sm font-semibold`}
          >
            Total
          </p>
          </div>
          <div className="w-[30%] text-center text-sm font-semibold flex gap-4 ">
            <p
            className={'w-1/3 flex justify-start'}
              data-tooltip-id="totalRevenueLY"
              data-tooltip-content={formatCurrency(totalData?.totalRevenueLastYear)}
            >
              {formatCurrency(totalData?.totalRevenueLastYear, isTab && { isShortFormat: true })}{" "}
              {isTab && <Tooltip id="totalRevenueLY" />}
            </p>
            <p
            className={'text-left'}
              data-tooltip-id="totalRevenueTY"
              data-tooltip-content={formatCurrency(totalData?.totalRevenueThisYear)}
            >
              {formatCurrency((totalData?.totalRevenueThisYear || totalData?.totalRevenue), isTab && { isShortFormat: true })}
              
              {isTab && <Tooltip id="totalRevenueTY" />}
            </p>
          </div>
          <div className="w-[25%]  text-center text-sm font-semibold flex gap-4">
            <p
            className="w-1/3"
              data-tooltip-id="totalADRLY"
              data-tooltip-content={formatCurrency(totalData?.totalADRLastYear)}
            >
              {formatCurrency(totalData?.totalADRLastYear, isTab && { isShortFormat: true })}
              {isTab && <Tooltip id="totalADRLY" />}
            </p>
            <p
              data-tooltip-id="totalADRTY"
              data-tooltip-content={formatCurrency(totalData?.totalADRThisYear)}
            >
              {formatCurrency((totalData?.totalADRThisYear || totalData?.totalADR), isTab && { isShortFormat: true })}
              {isTab && <Tooltip id="totalADRTY" />}
            </p>
          </div>
          <div className="w-[20%] text-center text-sm font-semibold flex gap-4">
            <p className="w-1/3">{totalData?.totalRoomNightsLastYear}</p>
            <p>{totalData?.totalRoomNightsThisYear || totalData?.totalRoom}</p>
          </div>
          <div className="w-[20%] text-center text-sm text-white  flex items-center justify-end">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="px-2 py-1 rounded-md bg-gray-200 hover:bg-gray-300 mr-2 cursor-pointer"
            >
              <img className="w-4" src={leftArrow} alt="Prev" />
            </button>
            <span>
              {currentPage}/ {totalpages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalpages}
              className="hover:bg-gray-300 px-2 py-1 rounded-md bg-gray-200 ml-2"
            >
              <img className="w-4" src={rightArrow} alt="Next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTable;

const DataRow = ({
  data,
  room,
  item,
  index,
  onCheckboxChange,
  selectedRows,
  isExpanded,
  toggleExpand,
  handleSelectAll,
  selectedIndex,
  setSelectedIndex,
  title,
  selectAll,
  startDate,
  endDate,
  chainPropertyDate
}) => {
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  const color = [
    "#1abc9c",
    "#f1c40f",
    "#2ecc71",
    "#2980b9",
    "#fad390",
    "#f8c291",
    "#60a3bc",
    "#4a69bd",
  ];

  const isSelected = selectedRows.includes(index);

  if (isSelected) {
  }
  function calculatePercentage(value, total) {
    if (total !== 0) {
      return ((value / total) * 100).toFixed(2);
    } else {
      return 0;
    }
  }
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpand = (index) => {
    if (selectedIndex === index) {
      setExpanded(!expanded);
    } else {
      setSelectedIndex(index);
      setExpanded(true);
    }
  };

  return (
    <div className="flex flex-col py-2 text-white">
      <div
        className={`w-[100%] flex p-2  gap-0 md:gap-8 justify-center items-center cursor-pointer ${selectedIndex === index && expanded ? "bg-[#f5f7fa84]" : ""}`}
        onClick={() => {
          handleToggleExpand(index);
        }}
      >
        <div className="w-full md:w-[20%]  flex gap-2 items-center justify-start md:pl-6 ">
          <div
            className="hidden md:flex md:visible md:w-[25px] h-[25px]  items-center justify-center  text-white text-[12px] rounded-md"
            style={{ background: color.random() }}
          >
            {(item?.source && item?.source.toString().charAt(0)) ||
              (item?.roomName && item?.roomName.toString().charAt(0)) ||
              (item?.propertyName && item?.propertyName.toString().charAt(0))}
          </div>
          <p
            className="text-sm font-semibold truncate w-full md:w-[150px]"
            id="title"
          >
            <div
              className="truncate w-full md:w-[150px]"
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                room ? item?.roomName : item?.source || item?.propertyName
              }
            >
              {" "}
              {room ? item?.roomName : item?.source || item?.propertyName}
            </div>
            <Tooltip id="my-tooltip" style={{ borderRadius: "5px" }} />
          </p>
        </div>
        <div className="w-[70px] md:w-[30%] gap-4 flex items-end justify-start">
          <div className="flex flex-col gap-2 w-1/3">
            {" "}
            <p className="text-xs text-start">
            {chainPropertyDate ? `${format(new Date(startDate), "MMM")} - ${format(new Date(endDate), "MMM")} - ${format(new Date(startDate), "yyyy")}`:
              (startDate && format(new Date(startDate), "MMM-yyyy"))}
            </p>
            <p className="text-sm">
              {" "}
              {formatCurrency(item?.revenueLastYear || item?.revenue?.previous)}
            </p>
          </div>
          <div className="flex flex-col gap-2 ml-2">
            <p className="text-xs text-start">
            {chainPropertyDate ? `${format(new Date(startDate), "MMM")} - ${format(new Date(endDate), "MMM")} - ${format(new Date(endDate), "yyyy")}`:
             ( endDate && format(new Date(endDate), "MMM-yyyy"))}
            
            </p>
            <div className="text-sm flex gap-2">
              <p
                className={`${item?.revenueThisYear > item?.revenueLastYear || item?.revenue?.current > item?.revenue?.previous ? "text-green-500" : "text-red-500"}`}
              >
                {" "}
                {formatCurrency(
                  item?.revenueThisYear || item?.revenue?.current
                )}
              </p>
            </div>
          </div>
          {/* <div
                        className={`flex text-sm pl-2 text-center justify-center ${item?.revenueThisYear > item?.revenueLastYear ? "text-green-500" : "text-red-500"}`}
                    >
                        {calculatePercentage(
                            item?.revenueThisYear,
                            item?.revenueLastYear
                        )}{" "}
                        %
                    </div> */}
        </div>
        <div className="w-[70px] md:w-[25%]  flex gap-6 items-end justify-start">
          <div className="flex flex-col gap-2 w-1/3">
            {" "}
            <p className="text-xs text-start">
            {chainPropertyDate ? `${format(new Date(startDate), "MMM")} - ${format(new Date(endDate), "MMM")} - ${format(new Date(startDate), "yyyy")}`:
              (startDate && format(new Date(startDate), "MMM-yyyy"))}
            </p>
            <p className="text-sm">
              {" "}
              {formatCurrency(item?.ADRLastYear || item?.adr?.previous)}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            {" "}
            <p className="text-xs text-start">
            {chainPropertyDate ? `${format(new Date(startDate), "MMM")} - ${format(new Date(endDate), "MMM")} - ${format(new Date(endDate), "yyyy")}`:
             ( endDate && format(new Date(endDate), "MMM-yyyy"))}
            </p>
            <p
              className={`text-sm flex gap-2 ${item?.ADRThisYear > item?.ADRLastYear || item?.adr?.current > item?.adr?.previous ? "text-green-500" : "text-red-500"}`}
            >
              {formatCurrency(item?.ADRThisYear || item?.adr?.current)}
            </p>
          </div>
          {/* <div
                        className={`flex text-sm text-center justify-center -ml-4 ${item?.ADRThisYear > item?.ADRLastYear ? "text-green-500" : "text-red-500"}`}
                    >
                        {calculatePercentage(
                            item?.ADRThisYear,
                            item?.ADRLastYear
                        )}{" "}
                        %
                    </div> */}
        </div>
        <div className="w-[50px] md:w-[20%] flex gap-6 items-end justify-start">
          <div className="flex flex-col gap-2 w-1/3">
            {" "}
            <p className="text-xs text-start">
              {startDate && format(new Date(startDate), "MMM-yyyy")}
            </p>
            <p className="text-sm">
              {item?.roomNightsLastYear || item?.roomNights?.previous || "--"}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            {" "}
            <p className="text-xs text-start">
              {endDate && format(new Date(endDate), "MMM-yyyy")}
            </p>
            <p
              className={`text-sm flex gap-2 ${item?.roomNightsThisYear > item?.roomNightsLastYear || item?.roomNights?.current > item?.roomNights?.previous ? "text-green-500" : "text-red-500"}`}
            >
              {item?.roomNightsThisYear || item?.roomNights?.current || "--"}
            </p>
          </div>
          {/* <div
                        className={`flex text-sm text-center justify-center -ml-4 ${item?.roomNightsThisYear > item?.roomNightsLastYear ? "text-green-500" : "text-red-500"}`}
                    >
                        {calculatePercentage(
                            item?.roomNightsThisYear,
                            item?.roomNightsLastYear
                        )}{" "}
                        %
                    </div> */}
        </div>
        <div className="w-[50px] md:w-[20%] flex items-center justify-between md:justify-evenly">
          <div className="flex items-center justify-center cursor-pointer p-4">
            <img
              src={selectedIndex === index && expanded ? arrowUp : arrowDown}
              alt=""
            />
          </div>
          {/* <div className="flex items-center justify-end md:justify-center rounded-md">
            <input
              id={`checkbox-${index}`}
              type="checkbox"
              value={data}
              checked={isSelected || selectAll}
              onChange={() => {
                handleToggleExpand(null);
                onCheckboxChange(index);
              }}
              class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100"
            />
          </div> */}
        </div>
      </div>
      {selectedIndex === index && expanded && (
        <div
          className={`flex w-[100%] gap-4 py-4 justify-center overflow-hidden transition-height duration-300 ${selectedIndex === index ? "bg-white/40 drop-shadow-lg backdrop-blur-md" : ""}`}
        >
          <div className="w-[30%]">
            <DataRowCard
              heading={"revenue"}
              value={formatCurrency(
                item?.revenueThisYear - item?.revenueLastYear ||
                  item?.revenue?.current - item?.revenue?.previous
              )}
            />
          </div>
          <div className="w-[30%]">
            <DataRowCard
              heading={"adr"}
              value={formatCurrency(
                item?.ADRThisYear - item?.ADRLastYear ||
                  item?.adr?.current - item?.adr?.previous
              )}
            />
          </div>
          <div className="w-[30%]">
            <DataRowCard
              heading={"room nights"}
              value={
                item?.roomNightsThisYear - item?.roomNightsLastYear ||
                item?.roomNights?.current - item?.roomNights?.previous
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

const DataRowCard = ({ heading, value }) => {
  return (
    <div className="w-[100%] flex flex-col gap-4]">
      <p className="text-sm mb-2">Overall {heading} difference</p>
      <div className={`p-4 text-black bg-[#eceff5] ${ value?.toString() === "--" || value?.toString() === "NaN" ? "h-fit" : 'h-[80px]'}  rounded-lg`}>
        <p
          className={`${(value?.toString().includes("-") &&  !value?.toString().includes("--")) ? "text-red-500" : value?.toString() === "NaN" || value?.toString() === "--" ?'text-gray-500 hidden ' : "text-green-500"} mb-1 text-2xl font-medium`}
        >
          {value?.toString().includes("-")
            ? `${value?.toString() === "NaN" || value?.toString() === "--" ? "N/A" : value}`
            : `${value?.toString() === "NaN" || value?.toString() === "--" ? "N/A" : value}`}
        </p>
        <p className="text-xs">
         
         {value?.toString() === "NaN" || value?.toString() === "--" ? 'Data not found for selected period.':`  Your ${heading === "room nights"?`${heading} are`:`${heading} is`}  ${value?.toString().includes("-") ? "decreased" : "increased"} by
          ${value?.toString() === "NaN" || value?.toString() === "--" ? "--" : value} from past compatision`}
        </p>
      </div>
    </div>
  );
};
