const formatCurrency = (amount, options = { isDecimal: true, isShortFormat: false }) => {
    // Use options to format the currency
    const currency = window?.localStorage.getItem("currency") || "₹";

    // Convert amount to a number if it's not already
    const finalAmount = isNaN(amount) ? parseFloat(amount) : parseFloat(amount);

    // If finalAmount is NaN (Not a Number) or not a valid number, return "--"
    if (isNaN(finalAmount) || !isFinite(finalAmount)) {
        return "--";
    }

    // Determine if the amount is negative
    const isNegative = finalAmount < 0;
    const absoluteAmount = Math.abs(finalAmount);

    let formattedAmount;
    if (options.isShortFormat) {
        if (absoluteAmount >= 1e9) {
            formattedAmount = (absoluteAmount / 1e9).toFixed(1) + 'B';
        } else if (absoluteAmount >= 1e6) {
            formattedAmount = (absoluteAmount / 1e6).toFixed(1) + 'M';
        } else if (absoluteAmount >= 1e3) {
            formattedAmount = (absoluteAmount / 1e3).toFixed(1) + 'K';
        } else {
            formattedAmount = absoluteAmount.toString();
        }
    } else {
        // Format the number as currency with two decimal places
        formattedAmount = options.isDecimal ? absoluteAmount.toFixed(2) : absoluteAmount.toFixed(0);
    }

    if (options.isShortFormat) {
        return `${isNegative ? '-' : ''}${currency} ${formattedAmount}`;
    }

    // Split the number into integer and decimal parts
    const parts = formattedAmount.split(".");
    let integerPart = parts[0];
    const decimalPart = options.isDecimal && parts[1] ? `.${parts[1]}` : "";

    // Add commas to the integer part as per the Indian numbering system
    let lastThree = integerPart.slice(-3);
    const otherNumbers = integerPart.slice(0, integerPart.length - 3);
    if (otherNumbers !== "") {
        lastThree = "," + lastThree;
    }
    const formattedIntegerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    // Combine the parts to get the final formatted currency
    const formattedCurrency = formattedIntegerPart + decimalPart;

    return `${isNegative ? '-' : ''}${currency} ${formattedCurrency}`;
};

export default formatCurrency;
