import React, { useEffect, useState } from 'react';
import '../Global.css';
import SimpleButton from '../../../components/button/SimpleButton';
import Business from '../../../assets/icons/onboarding/Business.svg';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from '../../../components/inputFeild';
import { useNavigate } from 'react-router-dom';
import TitleComponent from '../../../components/cardComponent';
import { useAddCmCredMutation, useChannelManagerListQuery } from '../../../redux/slices/onBoarding';
import { toast } from "react-toastify";
import Select from 'react-select';

import Password from '../../../assets/icons/onboarding/Password.svg'
import Email from '../../../assets/icons/onboarding/Email.svg';
import Property from '../../../assets/icons/onboarding/Property.svg';
import Backdrop from '../../../components/backdrop';
import { SyncLoader } from 'react-spinners';
import { UserId } from '../../../utils/impVars';

const ChannelManagerSetUp = ({ text, setSteps }) => {
    const [addCmCred] = useAddCmCredMutation()
    const { data: channelManagerList } = useChannelManagerListQuery()
    const [selectedCmId, setSelectedCmId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState(null);
    const methods = useForm({});
    const navigate = useNavigate()
const userId = UserId()
    const { control, handleSubmit, watch, formState:
        { errors, isDirty, isSubmitting, isValidating }, setError, clearErrors } = methods;
    const [location, onLocationSelected] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (channelManagerList && channelManagerList.data) {
            const mappedOptions = channelManagerList.data.map((item) => ({
                value: item.cmid,
                label: item.cmname,
            }));

            setOptions(mappedOptions);


        }
    }, [channelManagerList, options]);
    const handleSelectChange = selectedOption => {
        setSelectedCmId(selectedOption.value);
        setSelectedChannelManager(selectedOption)// Set the selected cmid
    };
    const [selectedChannelManager, setSelectedChannelManager] = useState(null);
    const compareCmName = (selectedCmName) => {
        return channelManagerList.data.some(
            (channelManager) => channelManager.cmname === selectedCmName
        );
    };
    const channelManagerType = methods.watch("channelManager_type");

    useEffect(() => {
        setSelectedChannelManager(channelManagerType?.value);
    }, [channelManagerType]);

    const onSubmit = async (data) => {
        const updatedHId = localStorage.getItem('hId');

        setLoading(true);
        const addCmCredData = {
            hId: updatedHId,
            cmId: selectedCmId,
            userId: JSON.parse(userId),
            userCredential: {
                username: data.username,
                email: data.email,
                address: data.address,
                password: data.password,
                propertyCode: data.propertyCode,
                accountType: data.accountType,

            }

        };
        addCmCred(addCmCredData)
            .unwrap()
            .then((res) => {

                toast.success(res?.message, { position: "bottom-right" });
                setSteps({
                    step1: false, step2: true, step3: false, step4: false, step5: false
                })
                setLoading(false);

            })
            .catch((err) => {
                
                toast.error(err?.data?.message, { position: "bottom-right" });
                setLoading(false);
            });
    }

    const focusedFieldName = watch('focusFieldName');

    useEffect(() => {
        if (focusedFieldName) {
            const fieldError = errors[focusedFieldName];
            if (fieldError) {
                setError(focusedFieldName, { type: 'manual', message: fieldError.message });
            } else {
                clearErrors(focusedFieldName);
            }
        }
    }, [focusedFieldName, errors, setError, clearErrors]);

    useEffect(()=>{
        let body = document.body
        body.style.overflow = ""
      })

    return (
        <>
            {

                loading && <Backdrop>
                    <SyncLoader color="white" />
                </Backdrop>
            }
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>

                    <div className='verify-email-content2'>

                        <div className='verify-email-content2'>
                            <TitleComponent title={'Seamlessly import your reservation data'}
                                subtitle={'Connect your channel manager for seamless integration.'}
                                subtitle2={'Don’t worry your data is safe with us. Read our '} terms={'Terms and Conditions'} />
                            <div className='flex flex-col w-full gap-4'>
                                <div className='w-full flex flex-col justify-center gap-2'>
                                    <div className="w-full">
                                        <Select
                                            placeholder={"Select Channel Manager"}
                                            label={"channelManagerList"}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    height: "48px",
                                                    border: "none",
                                                    borderBottom: state.isFocused ? "1px solid rgba(51, 97, 255, 1)" : "none",
                                                    boxShadow: "0 3px 5px rgb(189 180 180 / 32%)",
                                                }),
                                            }}

                                            id={"channelManagerList"}
                                            type={"select"}
                                            control={control}
                                            name={"channelManagerList"}
                                            options={options}
                                            value={selectedChannelManager}
                                            onChange={handleSelectChange}
                                            rules={{ required: "Channel Manager is required" }}
                                        />
                                    </div>
                                    <div className='flex flex-col w-full items-center justify-center gap-0 rounded-lg drop-shadow-3xl bg-white '>
                                        {(selectedChannelManager?.label === 'StayFlexi' ||
                                            selectedChannelManager?.label === 'RateGain' ||
                                            selectedChannelManager?.label === 'AxisRooms'||
                                            selectedChannelManager?.label === 'Staah') ? (
                                            "") : (
                                            <div className='w-full'>
                                                <InputField
                                                    placeholder={"username"}
                                                    className={"w-full  rounded-t-lg "}
                                                    label={"Username"}
                                                    id={"username"}
                                                    control={methods.control}
                                                    name={"username"}
                                                    type={"text"}
                                                    startIcon={Email}

                                                />
                                            </div>
                                        )}

                                        {selectedChannelManager?.label === 'eZee' ? (
                                            <div className='w-full'>
                                                <InputField
                                                    placeholder={"Property Code"}
                                                    className={"w-full  rounded-t-lg "}
                                                    label={"Property Code"}
                                                    id={"propertyCode"}
                                                    control={methods.control}
                                                    startIcon={Property}
                                                    name={"propertyCode"}
                                                    type={"text"}


                                                />
                                            </div>
                                        ) : ""

                                        }
                                        {(selectedChannelManager?.label === 'StayFlexi' ||
                                            selectedChannelManager?.label === 'RateGain' ||
                                            selectedChannelManager?.label === 'AxisRooms'||
                                            selectedChannelManager?.label === 'Staah') && (
                                                <div className='w-full'>
                                                    <InputField
                                                        placeholder={"Email"}
                                                        className={"w-full rounded-t-lg "}
                                                        label={"Email"}
                                                        id={"email"}
                                                        control={methods.control}
                                                        name={"email"}
                                                        startIcon={Email}
                                                        type={"email"}
                                                        rules={{
                                                            required: 'Email is required',
                                                            pattern: {
                                                                value: /^\S+@\S+\.\S+$/,
                                                                message: 'Entered value does not match email format'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}


                                        {selectedChannelManager?.label === 'AsiaTech' &&
                                            <div className='w-full'>
                                                <InputField
                                                    placeholder={"Ac/type"}
                                                    className={"w-full rounded-t-lg "}
                                                    label={"AccountType"}
                                                    id={"accountType"}
                                                    control={methods.control}
                                                    startIcon={Business}
                                                    name={"accountType"}
                                                    type={"text"}
                                                    rules={{ required: "AccountType is required" }}
                                                />
                                            </div>
                                        }
                                             {selectedChannelManager?.label === 'Staah' &&
                                            <div className='w-full'>
                                                <InputField
                                                    placeholder={"PropertyId"}
                                                    className={"w-full rounded-t-lg "}
                                                    label={"PropertyId"}
                                                    id={"propertyId"}
                                                    control={methods.control}
                                                    startIcon={Business}
                                                    name={"propertyId"}
                                                    type={"text"}
                                                    rules={{ required: "AccountType is required" }}
                                                />
                                            </div>
                                        }

                                        <div className='w-full rounded-b-lg overflow-hidden'>
                                            <InputField
                                                placeholder={"Password"}
                                                className={"w-full "}
                                                label={"Password"}
                                                id={"password"}
                                                control={methods.control}
                                                name={"password"}
                                                type={"password"}
                                                startIcon={Password}

                                            />

                                        </div>
                                    </div>
                                </div>
                                {focusedFieldName && errors[focusedFieldName] && (
                                    <span className="error-message">{errors[focusedFieldName].message}</span>
                                )}
                                <SimpleButton text='Next' type='submit' />
                                <div className='flex flex-col w-full gap-4 items-center'>
                                    <div>
                                        <p className='text-sm font-[400] text-[#0D1321]'>Your Channel Manager Not Listed?<span className='text-sm font-[400] text-[#3F70ED] pl-2 cursor-pointer'>Let Us Know</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </FormProvider>
        </>

    );
};

export default ChannelManagerSetUp;
