import React, { useState } from "react";

const MultiInputPasscode = ({ length, onComplete, className ,id ,name }) => {
    const [passcode, setPasscode] = useState(Array(length).fill(""));
    const inputRefs = [];

    const handleInputChange = (e, index) => {
        const value = e.target.value;

        // Check if the input is numeric
        if (/^\d*$/.test(value) && value.length <= 1) {
            const updatedPasscode = [...passcode];
            updatedPasscode[index] = value;
            setPasscode(updatedPasscode);

            // Move focus to the next input or trigger onComplete if all inputs are filled
            if (index < length - 1 && value !== "") {
                inputRefs[index + 1].focus();
            } else if (index === length - 1 && value !== "") {
                const completedPasscode = updatedPasscode.join("");
                onComplete(completedPasscode);
            }
        }
    };

    const handleInputKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && passcode[index] === "") {
            // Move focus to the previous input when backspace is pressed on an empty input
            inputRefs[index - 1].focus();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text/plain').trim().substring(0, length);
        const newPasscode = [...passcode];

        for (let i = 0; i < pastedData.length; i++) {
            if (i < length) {
                newPasscode[i] = pastedData[i];
            }
        }

        setPasscode(newPasscode);
        onComplete(newPasscode.join(''));
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < length; i++) {
            inputs.push(
                <input
                    key={i}
                    id={id}
                    name={name}
                    type="text"
                    value={passcode[i]}
                    onChange={(e) => handleInputChange(e, i)}
                    onKeyDown={(e) => handleInputKeyDown(e, i)}
                    ref={(input) => (inputRefs[i] = input)}
                    onPaste={handlePaste}
                    placeholder="-"
                    className={`border  border-indigo-200/40 ${className} focus:border-none focus:outline focus:outline-primary-500 text-center drop-shadow-3xl w-12 h-12 mx-2 rounded-lg `}
                />
            );
        }
        return inputs;
    };

    return <div className="passcode-input">{renderInputs()}</div>;
};

export default MultiInputPasscode;
