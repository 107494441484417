import { useState, useEffect } from 'react';

const useIntelligenceDateRange = () => {
    const [date, setDate] = useState({
        fromDate: new Date(),
        toDate: new Date()
    });

    useEffect(() => {
        const dateRange = JSON.parse(window.localStorage.getItem('intelligenceDateRange'));
        if (dateRange) {
            setDate({
                fromDate: new Date(dateRange.fromDate),
                toDate: new Date(dateRange.toDate)
            });
        }
    }, []);

    return date;
};

export default useIntelligenceDateRange;
