import React from 'react'
import "../../pages/onboarding/Global.css"
import { useNavigate } from 'react-router-dom'
const EventCalendar = (
    {
       
        data

    }
) => {
    const navigate =useNavigate()
  return (
    <div className='bg-[#F6F8FB] w-full h-full rounded-2xl px-2 py-4'>
     <p className=' font-medium mb-2'>Event Calendar</p>
     {
     data.map((item,index)=>{
        return(
            <div className=' bg-[#EEF1F6] h-24 p-2 rounded-xl mb-2 '>
            <div className='flex w-full justify-between items-center mb-2'>
            <div>
                 <p className=' text-[14px] font-medium'> { item.eventName || "Event Name"}</p>
                 <p className='text-[11px] font-normal'> {item.eventCategory || "Event Category"}</p>
             </div>
             <div className=' text-[12px] h-7 w-20 border rounded-lg flex items-center justify-center scale-left    hover:bg-white'>know more</div>
            </div>
            <div className='flex w-full justify-between items-center'>
             <div className='bg-[#CBD6F3] rounded-lg w-32 h-7 flex items-center justify-center gap-1'><p className=' font-medium'>{item.date || "18 Feb"} </p><p className=' font-normal text-[11px] mt-1'>{ item.time || "10:00 AM"}</p></div>
             <div className=' text-[12px] flex items-center gap-1'> <div className=' text-[20px] move-left'>+</div><div className='mt-1'> Add To callendar</div></div>
            </div>
         </div>
        )
     })
     }
       
     
    </div>
  )
}

export default EventCalendar
