import React from "react";
import DraggableTable from "../../components/draggableTable";
import SelectableButton from "../../components/button/selectableButton";
import DownArrow from "../../assets/icons/downArrow.svg";
import crossIcon from "../../assets/icons/crossIcon.svg";
import GlobalModalComponent from "../../components/modal/newModal";
import whiteBackIcon from "../../assets/icons/whiteBackIcon.svg";
import Dropdownn from "../../components/dropdown/index";
import AreaChart from "../../components/charts/areaChart";
import ColumnChart from "../../components/charts/columnChart";
import editBlueIcon from "../../assets/icons/editBlueIcon.svg";
import { useNavigate } from "react-router-dom";

const ReportBuilder = () => {
  const navigate = useNavigate()
  const [viewResult, setViewResult] = React.useState(false);
  const [saveModal, setSaveModal] = React.useState(false);
  const [reportName, setReportName] = React.useState("Untitled Report");
  const [reportDescription, setReportDescription] = React.useState("");
  const [reportTypeButtonIndex, setReportTypeButtonIndex] = React.useState(0);
  const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(null);
  const [showFilterModal, setShowFilterModal] = React.useState(false);
  const reportTypes = ["Room Types", "Sources", "Segments", "Time Periods"];
  const [reportColumnSelectedTypes, setReportColumnSelectedType] =
    React.useState([]);
  const [reportRowSelectedTypes, setReportRowSelectedType] = React.useState([]);
  const [reportTypesColumnCount, setReportTypesColumnCount] = React.useState([
    1, 2,
  ]);
  const [reportTypesRowCount, setReportTypesRowCount] = React.useState([1, 2]);

  const handleAddColumn = () => {
    setReportTypesColumnCount((prevState) => [
      ...prevState,
      reportTypesColumnCount.length + 1,
    ]);
  };
  const handleRemoveColumn = (index) => {
    const updatedItems = [...reportTypesColumnCount];
    updatedItems.splice(index, 1);
    // Update the state with the modified array
    setReportTypesColumnCount(updatedItems);
  };
  const handleAddRow = () => {
    setReportTypesRowCount((prevState) => [
      ...prevState,
      reportTypesRowCount.length + 1,
    ]);
  };
  const handleRemoveRow = (index) => {
    const updatedItems = [...reportTypesRowCount];
    updatedItems.splice(index, 1);
    // Update the state with the modified array
    setReportTypesRowCount(updatedItems);
  };

  const filterArray = [
    {
      name: "Room Type",
      values: [
        {
          name: "Room 1",
          id: "345523224365",
        },
        {
          name: "Room 2",
          id: "345523224365",
        },
        {
          name: "Room 3",
          id: "345523224365",
        },
        {
          name: "Room 4",
          id: "345523224365",
        },
      ],
    },
    {
      name: "Source",
      values: [
        {
          name: "Source 1",
          id: "345523224365",
        },
        {
          name: "Source 2",
          id: "345523224365",
        },
        {
          name: "Source 3",
          id: "345523224365",
        },
        {
          name: "Source 4",
          id: "345523224365",
        },
        {
          name: "Source 2",
          id: "345523224365",
        },
        {
          name: "Source 3",
          id: "345523224365",
        },
        {
          name: "Source 4",
          id: "345523224365",
        },
      ],
    },
  ];

  const handleFilterButtonClick = (index) => {
    setSelectedFilterIndex(index);
  };
  const handleSave = () => {
    setSaveModal(false);
  };
  return (
    <div className="w-[100%] gap-4 flex flex-col p-4">
      {saveModal && (
        <GlobalModalComponent
          saveBtnTitle={"Save"}
          hideModal={() => setSaveModal(false)}
          title={"Save Report"}
          showClearAll={false}
          onSubmit={handleSave}
        >
          <div className="flex flex-col gap-[16px]">
            <div className=" flex">
              <div className="w-[211px] flex items-center text-[#59647B] text-[14px] font-[500] ">
                Report Name
              </div>
              <input
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                placeholder="Type Somthing..."
                className="w-[211px] bg-[#F6F8FB] text-[#59647B] text-[14px]  px-2 h-[36px] focus:outline-none rounded-lg"
              />
            </div>
            <div className="flex">
              <div className="w-[211px] h-[36px] flex items-center text-[#59647B] text-[14px] font-[500] ">
                Report Description
              </div>
              <textarea
                placeholder="Type Somthing..."
                rows={5}
                cols={5}
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                className="w-[350px] bg-[#F6F8FB] text-[#59647B] text-[14px]  px-2 py-2  focus:outline-none rounded-lg"
              />
            </div>
          </div>
        </GlobalModalComponent>
      )}
      {showFilterModal && (
        <ModalComponent
          filterArray={filterArray}
          handleFilterButtonClick={handleFilterButtonClick}
          selectedIndex={selectedFilterIndex}
          hideModal={() => setShowFilterModal(false)}
          handleApplyFilter={() => {
            alert("Filter Applied");
            setShowFilterModal(false);
          }}
        />
      )}
      <div className="flex items-center justify-between">
        <div className="flex gap-4 items-center">
          <div onClick={()=>navigate(-1)} className="w-[32px] h-[32px] bg-[#1e50ff] flex justify-center items-center cursor-pointer rounded-md">
            <img src={whiteBackIcon} />
          </div>
          <div>
            <input
              defaultValue={reportName}
              className="text-[17px] w-[150px] px-2 text-sm h-8 rounded-lg font-medium focus:outline-none"
              onChange={(e) => setReportName(e.target.value)}
              value={reportName}
            />
          </div>
          {reportDescription ? (
            <div
              onClick={() => setSaveModal(true)}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border-none  h-[18px] w-[18px] flex justify-center items-center text-lg ">
                <img src={editBlueIcon} />
              </div>
              <div>Edit Description</div>
            </div>
          ) : (
            <div
              onClick={() => setSaveModal(true)}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border pb-0.5 h-[18px] w-[18px] flex justify-center items-center text-lg rounded-full border-[#1D3893]">
                +
              </div>
              <div>Add Description</div>
            </div>
          )}
        </div>

        <div className="flex gap-4 items-center">
          <div className="bg-[#EEF1F6] group rounded-lg text-[#59647B] text-[12px] font-[400] py-[4px] px-[24px] cursor-pointer transition-all ease-in-out duration-200  hover:bg-[#CCD6E5] hover:text-black">
            <div onClick={()=>navigate(-1)} className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] ">
              {" "}
              Discard Changes{" "}
            </div>
          </div>
          <div
            onClick={() => setSaveModal(true)}
            className="bg-[#1D2D44] group rounded-lg text-white text-[12px] font-[400] py-[4px] px-[24px] cursor-pointer hover:bg-[#1D3893] transition-all ease-in-out duration-200"
          >
            <div className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] ">
              {" "}
              Save{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F6F8FB] p-4 dark:bg-[#252C3B] min-h-auto  rounded-xl">
        <div>
          <div className="font-medium text-[14px] text-[#0D1321]">
            Report Type
          </div>
          <div className="flex gap-4 mt-2">
            {reportTypes &&
              reportTypes.map((item, index) => {
                return (
                  <SelectableButton
                    title={item}
                    index={index}
                    selectedIndex={reportTypeButtonIndex}
                    onClick={() => setReportTypeButtonIndex(index)}
                    key={index}
                  />
                );
              })}
          </div>
          <div className="mt-4">
            <ReportSelectableComponent
              columnTitle={`Select ${reportTypes[reportTypeButtonIndex]} to compare (Columns)`}
              rowTitle={"Select Parameters for comparison (Rows)"}
              columnOptions={[
                "Premium Room",
                "Delux Room",
                "Heritage Room",
              ].filter((item, index) => {
                return item != reportColumnSelectedTypes[index];
              })}
              ColumnCount={reportTypesColumnCount}
              setColumnSelectedOptionArray={setReportColumnSelectedType}
              RowCount={reportTypesRowCount}
              setRowSelectedOptionArray={setReportRowSelectedType}
              rowOptions={[
                "Premium Room",
                "Delux Room",
                "Heritage Room",
              ].filter((item, index) => {
                return item != reportRowSelectedTypes[index];
              })}
              isTime={
                reportTypes[reportTypeButtonIndex] === "Time Periods"
                  ? true
                  : false
              }
              reportTypes={reportTypes}
              selectedReportType={reportTypes[reportTypeButtonIndex]}
              handleAddColumn={handleAddColumn}
              handleRemoveColumn={handleRemoveColumn}
              handleAddRow={handleAddRow}
              handleRemoveRow={handleRemoveRow}
              openFilterModal={() => setShowFilterModal(true)}
            />
          </div>
        </div>
        <div
          className={`cursor-pointer group mt-2 bottom-4 text-[14px] text-white bg-[#1D2D44]  rounded-lg w-fit py-2 px-4  hover:bg-[#1D3893] transition-all ease-in-out duration-200`}
          onClick={() => setViewResult(true)}
        >
          <div className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] ">
            {" "}
            View Results{" "}
          </div>
        </div>
      </div>
      <div>{viewResult && <ReportResults />}</div>
    </div>
  );
};

export default ReportBuilder;

const ReportSelectableComponent = ({
  columnTitle,
  rowTitle,
  columnOptions,
  rowOptions,
  columnIndex,
  rowIndex,
  ColumnCount,
  RowCount,
  setColumnSelectedOptionArray,
  setRowSelectedOptionArray,
  selectedOption,
  isTime,
  reportTypes,
  selectedReportType,
  handleAddColumn,
  handleRemoveColumn,
  handleRemoveRow,
  handleAddRow,
  openFilterModal,
}) => {
  const [isVisible, setIsvisible] = React.useState(false);

  const Dropdown = ({
    index,
    options,
    setSelectedOptionArray,
    selectedOption,
    handleRemove,
  }) => {
    const [isVisible, setIsvisible] = React.useState(false);

    // const divRef = React.useRef(null);

    // React.useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (divRef.current && !divRef.current.contains(event.target)) {
    //       setIsvisible(true);
    //     } else {
    //       setIsvisible(false);
    //     }
    //   };

    //   document.addEventListener("click", handleClickOutside);

    //   return () => {
    //     document.removeEventListener("click", handleClickOutside);
    //   };
    // }, []);

    const handleSelect = (option) => {
      setSelectedOptionArray((prevState) => [...prevState, option]);
      setIsvisible(!isVisible);
    };
    return (
      <div className="w-[100%] relative my-2">
        <div className="flex gap-2 w-[100%]">
          <div className="h-[36px] w-[36px] cursor-pointer text-[#131926] text-[14px] font-medium flex justify-center items-center border-[#CCD6E5] border rounded-lg">
            {index}
          </div>
          <div
            className="h-[36px] w-[100%] cursor-pointer flex justify-between items-center rounded-lg bg-[#EEF1F6]"
            onClick={() => setIsvisible(!isVisible)}
          >
            <div className="text-[#131926] cursor-pointer text-[14px] mx-3 ">
              Select Option
            </div>
            <div className="mx-3">
              <img
                src={DownArrow}
                className={`${isVisible && "rotate-180"} transition-all ease-in-out duration-200`}
              />
            </div>
          </div>
          <div
            className="flex justify-center cursor-pointer items-center"
            onClick={() => handleRemove(index - 1)}
          >
            <img src={crossIcon} />
          </div>
        </div>
        {isVisible && (
          <div className="absolute w-[88%] cursor-pointer overflow-hidden overflow-y-scroll top-10 right-4 z-50 rounded-lg h-auto max-h-[200px] shadow-md shadow-gray-500/10 bg-white">
            {options ? (
              options.map((option) => {
                return (
                  <div
                    className="text-sm dark:text-mainDark text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b"
                    onClick={() => handleSelect(option)}
                  >
                    {option}
                  </div>
                );
              })
            ) : (
              <div className="text-sm dark:text-mainDark text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b">
                No More Options Available !
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const [SelectedTimeIndex, setSelectedTimeIndex] = React.useState(0);
  const [selectedTimeRange, setSelectedTimeRange] = React.useState();
  const timeOptions = ["Monthly", "Quarterly", "Half-Yearly", "Yearly"];
  const [reportTypeButtonIndex, setReportTypeButtonIndex] = React.useState(0);
  const options = ["Year to Month", "Month to Year"];
  const handleSelect = (option) => {
    setSelectedTimeRange(option);
    setIsvisible(!isVisible);
  };
  return (
    <div>
      {isTime && (
        <div className="text-[14px] mb-2 text-[#59647B] ">Period Interval</div>
      )}
      <div className="flex w-[100%] gap-4">
        {isTime &&
          timeOptions.map((item, index) => {
            return (
              <SelectableButton
                title={item}
                index={index}
                selectedIndex={SelectedTimeIndex}
                onClick={() => setSelectedTimeIndex(index)}
                key={index}
              />
            );
          })}
      </div>
      <div className={`flex w-[100%] ${isTime && "mt-4"} justify-between`}>
        <div className="w-[100%] flex flex-col gap-4">
          <div className="font-medium text-[#0D1321] text-[14px]">
            {columnTitle}
          </div>

          <div className="w-[70%] ">
            {ColumnCount &&
              ColumnCount.map((item, index) => {
                return (
                  <Dropdown
                    index={index + 1}
                    setSelectedOptionArray={setColumnSelectedOptionArray}
                    options={columnOptions}
                    handleRemove={handleRemoveColumn}
                    key={index}
                  />
                );
              })}
            {/* ${columnOptions.length === 0 ? 'opacity-20' :'opacity-100'} */}
            <div
              className={`p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]`}
              onClick={columnOptions.length === 0 ? null : handleAddColumn}
            >
              <div className="border pb-0.5 h-[18px] w-[18px] flex justify-center items-center text-lg rounded-full border-[#1D3893]">
                +
              </div>
              <div>Add Another</div>
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-col gap-4">
          <div className="font-medium text-[#0D1321] text-[14px]">
            {rowTitle}
          </div>
          <div className="w-[70%] ">
            {RowCount &&
              RowCount.map((item, index) => {
                return (
                  <Dropdown
                    index={index + 1}
                    setSelectedOptionArray={setRowSelectedOptionArray}
                    options={rowOptions}
                    handleRemove={handleRemoveRow}
                    key={index}
                  />
                );
              })}
            <div
              onClick={handleAddRow}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border pb-0.5 h-[18px] w-[18px] flex justify-center items-center text-lg rounded-full border-[#1D3893]">
                +
              </div>
              <div>Add Another</div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom divs */}
      <div>
        <div className="flex items-center gap-4 mt-2">
          <div className="text-[14px] mb-2 text-[#59647B] ">Time Range</div>
          <div className="relative">
            <div
              className="h-[36px] w-[193px] cursor-pointer flex justify-between items-center rounded-lg bg-[#EEF1F6]"
              onClick={() => setIsvisible(!isVisible)}
            >
              <div className="text-[#131926] cursor-pointer text-[14px] mx-3 ">
                {selectedTimeRange ? selectedTimeRange : "Select Option"}
              </div>
              <div className="mx-3">
                <img src={DownArrow} />
              </div>
            </div>{" "}
            {isVisible && (
              <div className="absolute w-[100%] cursor-pointer overflow-hidden overflow-y-scroll top-10 z-50 rounded-lg h-auto max-h-[200px] shadow-md shadow-gray-500/10 bg-white">
                {options &&
                  options.map((option) => {
                    return (
                      <div
                        className="text-sm dark:text-mainDark text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b"
                        onClick={() => handleSelect(option)}
                      >
                        {option}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div>
            <div className="text-[14px] mb-2 text-[#59647B] mt-4">Group By</div>
            <div className="flex gap-4 mt-2">
              {reportTypes &&
                reportTypes
                  .filter((item) => item !== selectedReportType)
                  .map((item, index) => {
                    return (
                      <SelectableButton
                        title={item}
                        index={index}
                        selectedIndex={reportTypeButtonIndex}
                        onClick={() => setReportTypeButtonIndex(index)}
                        key={index}
                      />
                    );
                  })}
            </div>
          </div>

          <div className="flex">
            <div className="text-[14px] mb-2 text-[#59647B] mt-4">
              Filter By
            </div>
            <div
              onClick={openFilterModal}
              className="p-4 w-fit flex gap-2 justify-start items-center cursor-pointer text-[#1D3893] font-medium text-[14px]"
            >
              <div className="border pb-0.5 h-[18px] w-[18px] flex justify-center items-center text-lg rounded-full border-[#1D3893]">
                +
              </div>
              <div>Add Another</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = ({
  hideModal,
  handleApplyFilter,
  filterArray,
  handleFilterButtonClick,
  selectedIndex,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/20 bg-opacity-50 flex items-center justify-center z-50">
      <div className="h-[495px] relative bg-white w-8/12 rounded-lg">
        <div className="p-4 flex justify-between  items-center border-b border-b-[#D3D8E1]">
          <div className="text-[21px] font-[500] text-[#131926]">Filters</div>
          <div className="text-[15px] font-[500] text-[#131926]">Clear All</div>
        </div>
        <div className="px-6 py-4">
          {filterArray &&
            filterArray.map((filter) => {
              return (
                <div className="flex items-start mb-4 gap-[100px]">
                  <div className="w-[148px] flex items-center h-[36px]">
                    {filter.name}
                  </div>
                  <div className="flex flex-wrap gap-4">
                    <SelectableButton
                      title={"All"}
                      index={selectedIndex === null && null}
                      selectedIndex={null}
                      onClick={() => handleFilterButtonClick(null)}
                    />{" "}
                    {filter.values.map((value, index) => {
                      return (
                        <SelectableButton
                          title={value.name}
                          selectedIndex={
                            selectedIndex != null && selectedIndex + 1
                          }
                          index={index + 1}
                          onClick={() => handleFilterButtonClick(index)}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="p-4 bottom-0 absolute w-[100%] gap-4 flex justify-end  items-center border-t border-b-[#D3D8E1]">
          <div
            className="text-[14px] font-[400] px-[8px] rounded-lg py-[4px] cursor-pointer bg-[#EEF1F6] text-[#59647B] transition-all ease-in-out duration-200  hover:bg-[#CCD6E5] hover:text-black"
            onClick={hideModal}
          >
            Cancel
          </div>
          <div
            className="text-[14px] font-[400] px-[8px] py-[4px] rounded-lg cursor-pointer bg-[#1D2D44] hover:bg-[#1D3893] transition-all ease-in-out duration-200  text-white"
            onClick={handleApplyFilter}
          >
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportResults = () => {
  const [selectedItems, setSelectedItems] = React.useState();
  const dropdownOption = [
    {
      label: "Bar Chart",
      value: "Bar Chart",
    },
    {
      label: "Pie Chart",
      value: "Pie Chart",
    },
    {
      label: "Area Chart",
      value: "Area Chart",
    },
  ];
  const initialHeaders = [
    { label: "Room Name", width: "20%" }, // Setting width for the "Name" column
    { label: "MakeMyTrip" }, // Setting width for the "Age" column
    { label: "Agoda" }, // Setting width for the "Country" column
    { label: "Agoda" }, // Setting width for the "Country" column
    { label: "Agoda" }, // Setting width for the "Country" column
  ];
  const initialRows = [
    { id: 1, "Room Name": "Delux", MakeMyTrip: "30 %", Agoda: "10 %" }, // Setting height for the first row
    { id: 2, "Room Name": "Premium", MakeMyTrip: "25 %", Agoda: "23 %" },
    { id: 2, "Room Name": "Premium", MakeMyTrip: "25 %", Agoda: "23 %" },
    { id: 2, "Room Name": "Premium", MakeMyTrip: "25 %", Agoda: "23 %" },
    { id: 2, "Room Name": "Premium", MakeMyTrip: "25 %", Agoda: "23 %" },
    {
      id: { value: 3, colspan: 4 },
      "Room Name": "Premium suite",
      MakeMyTrip: "40 %",
      Agoda: "12 %",
    }, // Setting height for the third row
  ];
  return (
    <div>
      <div className="flex items-center w-[100%] justify-between">
        <div className="text-[17px] text-[#0D1321]">Results</div>
        <div className="flex gap-2">
          <div>
            <Dropdownn
              options={dropdownOption}
              setSelectedItems={setSelectedItems}
              selectedItem={selectedItems}
            />
          </div>
          <div className="bg-[#1D2D44] group rounded-lg text-white text-[12px] font-[400] py-[4px] px-[24px] cursor-pointer hover:bg-[#1D3893] transition-all ease-in-out duration-200">
            <div className="group-hover:translate-x-1 transition-all ease-in-out duration-[150ms] ">
              {" "}
              Save{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] rounded-xl mt-4 bg-[#F6F8FB] h-[333px]">
        {selectedItems === "Bar Chart" ? (
          <ColumnChart height={333} />
        ) : selectedItems === "Area Chart" ? (
          <AreaChart
            dashed={true}
            height={333}
            xaxisShow={true}
            yaxisShow={true}
          />
        ) : selectedItems === "Pie Chart" ? (
          <div className="w-[100%] h-[333px] flex justify-center items-center">
            Pie chart for this data is not available !
          </div>
        ) : (
          <div className="w-[100%] h-[333px] flex justify-center items-center">
            At least select one from options
          </div>
        )}
      </div>
      <div className="w-[100%] mt-4">
        <DraggableTable
          initialHeaders={initialHeaders}
          initialRows={initialRows}
        />
      </div>
    </div>
  );
};
