import React from "react";
import Dropdown from "../dropdown";
import DumbellChart from "../charts/dumbellChart";
import { format } from "date-fns";
import InfoToolTip from "../tooltip/InfoToolTip";
import formatCurrency from "../../utils/formatCurrency";

const CustomDumbellChart = ({
  data,
  title,
  minimum,
  toolbar,
  height,
  asPer,
  isCurrency,
}) => {


  return (
    <div className="bg-white h-[100%] dark:bg-mainDark p-4 rounded-2xl">
      <div className="flex w-[100%] justify-between items-center mb-10">
        <div className="text-[17px] ">
          Rate Fluctuations{" "}
          {asPer && (
            <span className="text-[#8794AD] text-[12px] ml-1 mt-1">
              as per {asPer && format(new Date(asPer), "dd MMM','yy")}
            </span>
          )}
        </div>
        <div className="flex gap-4 items-center">
          <div>
            {/* <Dropdown selectedItem={"Your Property"} /> */}
          </div>
          <div>
            <InfoToolTip content={"This feature tracks changes in room rates over time, highlighting fluctuations in pricing within a specified period."} />
          </div>
        </div>
      </div>
      <DumbellChart
        toolbar={toolbar}
        height={height}
        isCurrency={isCurrency}
        data={data}
        minimum={minimum}
      />
    </div>
  );
};
export default CustomDumbellChart;
