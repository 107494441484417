import { emptySplitApi } from "../../injectEndpoints";

export const SettingsAccount_Security = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    editUser: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/editUserProfile`,
          method: "PATCH",
          params: {
            userId: data.userId,
          },
          body: data.formData,
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["editUser"],
    }),
    getUserDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getUserProfile`,
          method: "GET",
          params: {
            userId: data.userId,
          },
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUsers"],
    }),
  }),
  overrideExisting: false,
});

export const { useEditUserMutation,useGetUserDetailsQuery } = SettingsAccount_Security;
