// Tooltip.js
import "./Tooltip.css"
import React from 'react';

const NewTooltip = ({ title, children, download,}) => {
    return (
        <div className="relative inline-block ">
            <div className="group test">
                {children}
                <div className={`opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-transform duration-300 transform group-hover:translate-y-1 absolute bottom-8 ${download ? "left-4" : "-left-3"} -ml-20`}>
                    <div className={`bg-black text-white text-center  rounded-md w-[150px] ${download ? "text-sm py-1" : "py-2 px-2"}`}>
                        {title}
                    </div>
                    <div className="arrow-up"></div>
                </div>
            </div>
        </div>
    );
};

export default NewTooltip;
