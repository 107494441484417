import React, { useState } from "react";
import SettingButton from "../../../../components/settingButton/SettingButton";
import TableButton from "../../../../components/settingButton/TableButton";
import {
  useGetCMRateChargeRuleQuery,
  useGetCMRoomDetailQuery,
} from "../../../../redux/slices/settings/channelManager/cmRuleMapping";
import { CurrentProperty } from "../../../../utils/impVars";
import Dropdown from "../../../../components/dropdown";
import Table from "../../../../components/table";

const CM_RuleMapping = ({ handleBackButtonClick, handleAddRatePlanClick }) => {
  const [selectedCMOption, setSelectedCMOption] = useState();
  const [selectedRoomOption, setSelectedRoomOption] = useState();
  const [selectedRoomPrice, setSelectedRoomPrice] = useState();
  const [ruleMappingData, setRuleMappingData] = useState([]);

  const {
    data: getCM_RoomDetails,
    isLoading,
    isFetching,
  } = useGetCMRoomDetailQuery({
    hId: CurrentProperty(),
  });

  const { data: getCM_ChargeRules } = useGetCMRateChargeRuleQuery({
    hId: CurrentProperty(),
  });

  const channelManagerOption = getCM_RoomDetails?.data?.info?.map(
    (item, index) => {
      return {
        label: item?.channelName,
        value: item?.channelId,
      };
    }
  );

  const roomOptions =
    getCM_RoomDetails?.data?.info
      ?.filter((item) => item?.channelId === selectedCMOption?.value)
      ?.flatMap((item) =>
        item?.roomInfo?.map((itm) => ({
          label: itm?.roomName,
          value: itm?.cmRoomId,
        }))
      ) || [];

      const handleChangeValue = (index,key,value) => {
        const newData = [...ruleMappingData]
        newData[index][key] = value
    setRuleMappingData(newData)
      }

  const columns = [
    {
      name: "Derived Room",
      selector: (row) => row["derivedRoom"],
      cell: (row) => {
        return (
          <div>
            <select className="focus:outline-none border-none outline-none py-0 px-2 w-[100%] bg-gray-200 my-2 rounded-md h-8  text-xs ">
              {roomOptions &&
                roomOptions
                  ?.filter((itm) => {
                    return itm.label !== selectedRoomOption?.label;
                  })
                  .map((item) => {
                    return <option key={item?.label}>{item?.label}</option>;
                  })}
            </select>
          </div>
        );
      },
    },
    {
      name: "Master Room",
      selector: (row) => row["masterRoom"],
    },
    {
      name: "Adjust",
      selector: (row) => row["adjust"],
      cell:(row,index)=>{
        console.log(index)
        return <div className="flex gap-2">
            <div className={`${row?.adjust === "percentage" ? 'bg-blue-500 text-white' : 'text-blue-500'}    cursor-pointer rounded-lg px-4 py-2`} onClick={()=>handleChangeValue(index,'adjust','percentage')}>%</div>
            <div className={`${row?.adjust === "amount" ? 'bg-blue-500 text-white' : 'text-blue-500'}   cursor-pointer rounded-lg px-4 py-2`} onClick={()=>handleChangeValue(index,'adjust','amount')}>₹</div>
        </div>
      }
    },
    {
      name: "Amount",
      selector: (row) => row["amount"],
      cell:(row,index)=>{
        return <div className="flex gap-2">
            <div className={`${row?.amount === "decrement" ? 'bg-blue-500 text-white ' : 'text-blue-500'}  cursor-pointer rounded-lg px-4 py-2`} onClick={()=>handleChangeValue(index,'amount','decrement')}>-</div>
            <div className={`${row?.amount === "increment" ? 'bg-blue-500 text-white ' : 'text-blue-500'} cursor-pointer rounded-lg px-4 py-2`} onClick={()=>handleChangeValue(index,'amount','increment')}>+</div>
        </div>
      }
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjustValue"],
      cell:(row,index)=>{
        return <input className="py-2 rounded-lg px-2 text-sm focus:outline-none" onChange={(e)=>handleChangeValue(index,'adjustValue',e.target.value)} value={ruleMappingData[index]?.adjustValue||0} />
      }
    },
    {
      name: "Actions",
    },
  ];

  const handleAddData = () => {
    setRuleMappingData([
      ...ruleMappingData,
      {
        derivedRoom: "",
        masterRoom: selectedRoomOption?.label,
        adjust: "percentage",
        amount: "increment",
        adjustValue: selectedRoomPrice ? selectedRoomPrice : 35465,
      },
    ]);
  };

console.log(ruleMappingData)

  const data = [
    {
      title: "edkbvkjebv",
    },
  ];

  return (
    <div className="flex flex-col gap-4 w-[100%]">
      <SettingButton
        Title={"Rate Plan"}
        table={true}
        handleBackButtonClick={handleBackButtonClick}
      />
      <div className="flex gap-2 w-[100%]">
        <div className="relative w-[100%]   bg-newGradient text-lightFontColor dark:bg-lightGradient flex flex-col rounded-xl p-4">
          <div className="flex items-center justify-between">
            <p className="text-[14px] font-[500]">
              Channel Manager Rule Mapping
            </p>
            <div className="flex items-center gap-2">
              <div className="bg-black/40 rounded-lg w-fit">
                <Dropdown
                  selectedItem={selectedCMOption?.label}
                  setSelectedItems={setSelectedCMOption}
                  options={channelManagerOption}
                />
              </div>

              {/* <TableButton
              title={"Add New"}
              onClick={handleAddRatePlanClick}
            /> */}
            </div>
          </div>
          <div className="mt-8">
            <div className="text-xs mb-2 text-lightFontColor ">
              Select Master Room & its price:
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="bg-black/40 rounded-lg w-fit">
                  <Dropdown
                    options={roomOptions && roomOptions}
                    right={-100}
                    selectedItem={selectedRoomOption?.label}
                    setSelectedItems={setSelectedRoomOption}
                  />
                </div>
                <div className="bg-black/25 rounded-lg w-fit py-0.5">
                  <input
                    placeholder="Enter rate"
                    type="number"
                    value={selectedRoomPrice}
                    min={0}
                    onChange={(e) => setSelectedRoomPrice(e.target.value)}
                    className="text-sm focus:outline-none  bg-transparent text-white placeholder:text-white px-2"
                  />
                </div>
              </div>
              <div className="text-lightFontColor flex items-center gap-4">
                <div
                  className="] cursor-pointer text-sm"
                  onClick={handleAddData}
                >
                  Add More
                </div>
                <div className=" cursor-pointer text-sm">
                  Adjust Rate Plan
                </div>
              </div>
            </div>
          </div>
          <div className="h-[400px] scroll-container">
            <Table columns={columns} data={ruleMappingData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CM_RuleMapping;
