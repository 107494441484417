import React, { useEffect, useRef, useState } from "react";
import { AiFillCaretUp, AiOutlineDown, AiOutlineSearch } from "react-icons/ai";
import {
  useGetSearchForSuperQuery,
  useGetUserForSuperQuery,
} from "../redux/slices/superAdmin";
import { useGetLoginInfoQuery } from "../redux/slices/settings/userManagement";
import { setLoginData } from "../redux/states/login";
import { useDispatch } from "react-redux";
import BackdropLoader from "./newLoader";
import { setToken } from "../redux/states/tokenState";
import lit from "../assets/icons/lit.png";

const AdminNavbar = () => {
  const localProperty = window.localStorage.getItem("adminProp");
  const localUser = window.localStorage.getItem("adminUser");
  const userHid = window.localStorage.getItem("adminHid");
  const [selectedKeyword, setSelectedKeyword] = useState();
  const [showSearchOption, setShowSearchOption] = useState(false);
  const [showUsersOption, setShowUsersOption] = useState(false);
  const [searchData, setSearchData] = useState();
  const [SelectHid, setSelectHid] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectPropData, setSelectPropData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const userId = window.localStorage.getItem("adminUserId");
  const dispatch = useDispatch();
  const { data: getSearchForSuper } = useGetSearchForSuperQuery(
    {
      userId: userId,
      searchQuery: selectedKeyword,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: getUsers } = useGetUserForSuperQuery(
    {
      userId: userId,
      hId: SelectHid || userHid,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: loginInfo,
    isLoading,
    isFetching,
  } = useGetLoginInfoQuery(
    {
      userId: selectedUserId?.id,
      hId: SelectHid || userHid,
    },
    {
      skip: !selectedUserId ? true : false,
      refetchOnMountOrArgChange: selectedUserId?.id ? true : false,
    }
  );

  useEffect(() => {
    if (getSearchForSuper && getSearchForSuper?.data) {
      setSearchData(getSearchForSuper?.data);
    }
  }, [getSearchForSuper]);
  useEffect(() => {
    if (selectedKeyword?.length > 0) {
      setShowSearchOption(true);
    }
  }, [selectedKeyword]);

  // useEffect(()=>{
  //   if(selectedUserId){

  //   }
  // },[selectedUserId])

  useEffect(() => {
    let body = document.body;
    body.style.overflow = "";
  });

  useEffect(() => {
    if (loginInfo && selectedUserId) {
      window.localStorage.setItem("userId", loginInfo.data.userId);
      window.localStorage.setItem("isCMVerified", loginInfo.data.isCMVerified);
      window.localStorage.setItem("token", loginInfo?.data?.token?.token);
      dispatch(setToken(loginInfo?.data?.token?.token));
      window.localStorage.setItem("name", loginInfo?.data?.name);
      window.localStorage.setItem("designation", loginInfo?.data?.designation);
      window?.localStorage?.setItem(
        "currency",
        loginInfo?.data?.currency?.currencySymbol
      );
      window.localStorage.setItem(
        "profilePic",
        loginInfo?.data?.profilePicture
      );
      window.localStorage.setItem(
        "activeOta",
        JSON.stringify(loginInfo?.data?.activeOtas)
      );
      window.localStorage.setItem(
        "permissions",
        JSON.stringify(loginInfo?.data?.permissions)
      );
      window.localStorage.setItem(
        "propertiesList",
        JSON.stringify(loginInfo?.data?.propertiesList)
      );
      window.localStorage.setItem(
        "extractionDate",
        JSON.stringify(loginInfo?.data?.lastExtraction)
      );
      dispatch(setLoginData(loginInfo?.data));
      // window.location.reload()
    }
    setShowSearchOption(false);
    if (localProperty || localUser) {
      setSelectedKeyword(localProperty);
      setSelectedUser(localUser);
    }
  }, [selectedUser, loginInfo]);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setShowSearchOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const streakMessages = [
    "Kudos! You're on fire with your streaks! 🔥 Keep the momentum going!",
    "High five! You've just added another day to your streak! ✋",
    "Streak-tastic! 🌟 You've kept it up for another day!",
    "Awesome! Your streak is growing stronger every day! 💪",
    "Way to go! Another day, another streak! 🌈 Keep shining!",
    "Boom! Streak count just went up by one! 🚀",
    "Streak alert! 🌟 You’re on a roll, keep it up!",
    "You're unstoppable! Another day, another streak added! 🏆",
    "Wow! Another day added to your impressive streak! 🎉",
    "You did it again! Your streak just got longer! 🌟 Keep up the great work!",
    "Amazing! Another day, another streak! 🌟",
    "You're a streak superstar! Keep it up! 🌟",
    "Fantastic! Your streak is going strong! 💪",
    "Hooray! Another streak day in the books! 📚",
    "Bravo! You’ve extended your streak once again! 👏",
    "Keep it going! Your streak is impressive! 🌟",
    "Awesome work! Another day added to your streak! 🎉",
    "Keep shining! Your streak is growing every day! 🌟",
    "Well done! Another day, another streak! 🎉",
    "You're on a roll! Keep up the great work! 🏆",
    "Impressive! Your streak just got longer! 🌟",
    "Streak-tacular! Keep it going! 🌟",
    "Another day, another streak! Way to go! 💪",
    "You're streaking ahead! Keep it up! 🚀",
    "Fantastic job! Your streak is unstoppable! 🌟",
    "You're a streak champion! Keep shining! 🏆",
    "Streak status: Strong! Keep it going! 🌟",
    "Amazing job! Your streak is growing! 🎉",
    "Well done! Another streak day added! 📚",
    "Keep the momentum! Your streak is on fire! 🔥",
    "Way to shine! Your streak is impressive! 🌟",
    "Streak success! Keep up the great work! 🎉",
    "Bravo! Your streak is unstoppable! 💪",
    "Awesome! Another day, another streak! 🌟",
    "You're on fire! Keep that streak going! 🔥",
    "Keep it up! Your streak is impressive! 💪",
    "Well done! Another day added to your streak! 🌟",
    "Hooray! Your streak is growing stronger! 🎉",
    "You're unstoppable! Keep that streak alive! 🏆",
    "Fantastic! Another streak day in the books! 📚",
    "You're streaking ahead! Keep it going! 🚀",
    "Amazing! Your streak just got longer! 🌟",
    "Streak alert! Keep up the great work! 🌟",
    "Impressive! Another day, another streak! 🎉",
    "Way to go! Your streak is shining! 🌟",
    "Bravo! Your streak is growing stronger! 💪",
    "Awesome job! Another streak day added! 🌟",
    "Keep it going! Your streak is on fire! 🔥",
    "You're a streak superstar! Keep it up! 🌟",
    "Fantastic! Your streak is growing every day! 💪",
  ];

  function getRandomStreakMessage() {
    const randomIndex = Math.floor(Math.random() * streakMessages.length);
    return streakMessages[randomIndex];
  }

  const [randomMessage, setRandomMessage] = useState("");

  const handleMouseEnter = () => {
    setRandomMessage(getRandomStreakMessage());
  };

  return (
    <div
    style={{background:'linear-gradient(358deg, #001111, #1b3167)'}}
      ref={ref}
      className="w-[100%] flex justify-between items-center  flex-col md:flex-row  text-white px-4 py-2  md:h-[60px]"
    >
      <BackdropLoader loading={isLoading || isFetching} />
      <div className="flex gap-4 flex-col md:flex-row items-center">
        <div className=" relative text-sm w-[250px] cursor-pointer flex justify-between items-center rounded-lg bg-white text-gray-700 py-2 px-4">
          <div>
            {" "}
            <input
              placeholder="Select Property"
              value={selectedKeyword}
              onChange={(e) => setSelectedKeyword(e.target.value)}
              className="focus:outline-none w-[170px]"
            />
          </div>
          <div>
            <AiOutlineSearch />
          </div>
          {showSearchOption && (
            <div
              className={`max-h-[200px] scrollHide left-0 overflow-y-scroll min-h-auto ${isLoading || isFetching ? "" : "z-50"}  top-10 absolute bg-white rounded-md w-[250px]`}
            >
              {Array.isArray(searchData) &&
                searchData?.map((prop) => {
                  return (
                    <div
                      className="px-4 py-2 truncate text-gray-600 border-b"
                      onClick={() => {
                        setSelectHid(prop?.hId);
                        window.localStorage.setItem("adminHid", prop?.hId);
                        setSelectPropData(prop);
                        window.localStorage.setItem(
                          "adminProp",
                          prop?.propertyName
                        );
                        window.localStorage.setItem("hId", prop?.hId);

                        setSelectedUserId({
                          name: "",
                          id: prop?.userId?.[0]?.userId,
                        });
                        setSelectedKeyword(prop?.propertyName);
                        setShowSearchOption(false);
                      }}
                    >
                      {prop?.propertyName}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="relative text-sm w-[250px] cursor-pointer flex justify-between items-center rounded-lg bg-white text-gray-700 py-2 px-4">
          <div onClick={() => setShowUsersOption(!showUsersOption)}>
            {" "}
            {selectedUser ? selectedUser : "Select User"}
          </div>
          <div>
            <AiOutlineDown
              onClick={() => setShowUsersOption(!showUsersOption)}
            />
          </div>
          {showUsersOption && (
            <div className="max-h-[200px] scrollHide left-0 overflow-y-scroll min-h-auto z-50 top-10 absolute bg-white rounded-md w-[250px]">
              {Array.isArray(getUsers?.data) &&
                getUsers?.data?.map((user) => {
                  return (
                    <div
                      className="px-4 py-2 truncate text-gray-600 border-b"
                      onClick={() => {
                        setSelectedUserId({
                          name: user?.name,
                          id: user?.userId,
                        });
                        window.localStorage.setItem("adminUser", user?.name);
                        setSelectedUser(user?.name);
                        setShowUsersOption(false);
                      }}
                    >
                      {user?.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <div>
        <div
          onMouseEnter={handleMouseEnter}
          className="flex relative group items-center bg-orange-100/70 px-2 gap-1 rounded-lg text-sm"
        >
          <img src={lit} width={35} alt="streak icon" />
          {loginInfo?.data?.streak}
          <div className="absolute z-30 -mr-2 w-[200px] hidden group-hover:block text-orange-100/70 top-10">
            <AiFillCaretUp size={24} />
          </div>
          {loginInfo?.data?.streak != 0 && (
            <div className="absolute w-[200px] max-h-[100px] min-h-fit hidden group-hover:flex top-14 justify-center items-center text-sm text-gray-700 opacity-0 group-hover:opacity-100 transition-all ease-in-out rounded-lg -right-4 z-30 bg-orange-100 p-2">
              {randomMessage}
            </div>
          )}
        </div>
        {/* <div className="bg-blue-600 text-white text-sm rounded-lg px-4 py-2 cursor-pointer">
          Settings
        </div> */}
      </div>
    </div>
  );
};

export default AdminNavbar;
