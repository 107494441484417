import { emptySplitApi } from "../../injectEndpoints";
export const SuperAdmin = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchForSuper: builder.query({
      query: (data) => {
        return {
          url: `/super/getSearchForSuper`,
          method: "GET",
          params:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getSearchForSuper"],
    }),
    getUserForSuper: builder.query({
      query: (data) => {
        return {
          url: `/super/getUsersForSuper`,
          method: "GET",
          params:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getUserForSuper"],
    }),
    addExternalRate: builder.mutation({
      query: (data) => {
        return {
          url: `/super/addExternalRates`,
          method: "POST",
          body:data
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addExternalRate"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSearchForSuperQuery, useGetUserForSuperQuery, useAddExternalRateMutation } = SuperAdmin;
