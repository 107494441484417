import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";
import { format } from "date-fns";

export const YearEndReportdownload = async (temp) => {
  const DATA3 = [];
  DATA3.push(temp);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  const logoBase64 = logoUrl;
  // Add image at B1
  const logoId = workbook.addImage({
    base64: logoBase64,
    extension: "jpg",
  });
  worksheet.mergeCells("A1:I3");
  worksheet.addImage(logoId, "A1:A3");
  const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const columnWidths = [20, 15, 20, 15, 15, 15, 15, 15, 15]; // Adjust widths as needed
  columns.forEach((column, index) => {
    worksheet.getColumn(column).width = columnWidths[index];
  });

  // Display today's date at J1
  const dateCell = worksheet.getCell("I1");
  dateCell.value = new Date();
  dateCell.numFmt = "dd mmmm yyyy";

  // Move main heading to A2 to G2 and increase row height
  const mainHeading = worksheet.getCell("A4");
  mainHeading.value = `Year End Report - ${DATA3[0].hotelName} (${formatDate(new Date(DATA3[0].asOn?.startDate))} - ${formatDate(new Date(DATA3[0].asOn?.endDate))})`;
  mainHeading.alignment = { horizontal: "center", vertical: "middle" };
  mainHeading.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "B7D166" }, // Yellow color
  };
  worksheet.mergeCells("A4", "I4");
  worksheet.getRow(4).height = 40; // Increase row height
  worksheet.mergeCells("A5", "I5");
  DATA3.forEach((item) => {
    const {
      statistics,
      sourceData,
      roomData,
      monthWiseBreakDown,
      allVisibility,
      reviews,
    } = item;
    const { totalRevenue, cancellations, reservationCount, ADR, occupancy } =
      statistics[0];

    worksheet
      .addRow([
        "Total Revenue",
        "",
        "Cancellations",
        "",
        "Reservations",
        "",
        "Average Daily Rate",
        "",
        "Occupancy",
      ])
      .eachCell((cell) => {
        if (cell.value != "") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin", color: { argb: "ffffff" } },
          };
        }
      });
    worksheet
      .addRow([
        totalRevenue,
        "",
        cancellations,
        "",
        reservationCount,
        "",
        ADR,
        "",
        occupancy,
      ])
      .eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    worksheet.addRow([]);
    worksheet.addRow(["Source-wise Breakdown"]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
    });
    var lastRowNumber = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber}`, `I${lastRowNumber}`);

    worksheet
      .addRow(
        Object.keys(sourceData[0]).map((key) =>
          key.replace(/([A-Z])/g, " $1").trim()
        )
      )
      .eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
    sourceData.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    worksheet.addRow([]);

    worksheet.addRow(["Room-wise Breakdown"]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
    });

    var lastRowNumber1 = worksheet.rowCount;

    worksheet.mergeCells(`A${lastRowNumber1}`, `I${lastRowNumber1}`);

    worksheet
      .addRow(
        Object.keys(roomData[0]).map((key) =>
          key.replace(/([A-Z])/g, " $1").trim()
        )
      )
      .eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" }, // Yellow color
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    roomData.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    worksheet.addRow([]);
    worksheet.addRow(["Month-wise Breakdown"]).eachCell((cell) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" }, // Yellow color
      };
    });

    lastRowNumber1 = worksheet.rowCount;
    worksheet.mergeCells(`A${lastRowNumber1}`, `I${lastRowNumber1}`);

    worksheet
      .addRow(
        Object.keys(monthWiseBreakDown[0]).map((key) =>
          key.replace(/([A-Z])/g, " $1").trim()
        )
      )
      .eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "B7D166" }, // Yellow color
        };
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    monthWiseBreakDown.map((room, index) => {
      worksheet.addRow(Object.values(room)).eachCell((cell) => {
        if (index % 2 != 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ECEFCE" },
          };
        }
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    });

    worksheet.addRow([]);

    function generateExcelHeaders(data) {
      // Get unique OTAs with their first and last dates
      const uniqueOTAs = transformData(data);

      // Construct the headers
      const topLevelHeaders = [];
      const secondLevelHeaders = [""];

      uniqueOTAs.forEach((ota) => {
        const firstDate = new Date(ota.firstDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
        const lastDate = new Date(ota.lastDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });

        topLevelHeaders.push(ota.otaName, "", ""); // OTA name spans three columns
        secondLevelHeaders.push(firstDate, lastDate, "Percentage Growth");
      });

      // Combine into a two-dimensional array
      const headers = [topLevelHeaders, secondLevelHeaders];

      return headers;
    }

    function calculateRankChanges(data) {
      return data?.map((hotel) => {
        const row = [hotel.hotelName];

        hotel.visibility.forEach((record) => {
          let firstRank, lastRank, percentChange;
          if (record.firstRecord.rank !== 9999) {
            firstRank = record.firstRecord.rank;
          } else {
            firstRank = "-";
          }
          if (record.lastRecord.rank !== 9999) {
            lastRank = record.lastRecord.rank;
          } else {
            lastRank = "-";
          }
          if (lastRank === "-" || firstRank === "-") {
            percentChange = "-";
          } else {
            percentChange = (
              ((lastRank - firstRank) / firstRank) *
              100
            ).toFixed(2);
          }

          row.push(
            firstRank,
            lastRank,
            percentChange + (percentChange !== "-" ? "%" : "")
          );
        });

        return row;
      });
    }
    function transformData(data) {
      const otaMap = new Map();

      data?.forEach((hotel) => {
        hotel.visibility.forEach((record) => {
          const { otaName, firstRecord, lastRecord } = record;

          if (otaMap.has(otaName)) {
            const existing = otaMap.get(otaName);
            if (new Date(firstRecord.date) < new Date(existing.firstDate)) {
              existing.firstDate = firstRecord.date;
            }
            if (new Date(lastRecord.date) > new Date(existing.lastDate)) {
              existing.lastDate = lastRecord.date;
            }
          } else {
            otaMap.set(otaName, {
              otaName,
              firstDate: firstRecord.date,
              lastDate: lastRecord.date,
            });
          }
        });
      });

      return Array.from(otaMap.values());
    }

    const finalData = generateExcelHeaders(allVisibility);

    worksheet.addRow(finalData[0]).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    const lastRow = worksheet.rowCount;

    // Iterate over each cell in the last row

    // for (let column = 'B'; column <= String.fromCharCode("B".charCodeAt(0) + 16); column = String.fromCharCode(column.charCodeAt(0) + 3)) {
    //   worksheet.mergeCells(`${column}${lastRow}: ${String.fromCharCode(column.charCodeAt(0) + 2)}${lastRow}`);

    // }

    // worksheet.addRow(finalData[1]).eachCell((cell) => {
    //   cell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "B7D166" },
    //   };
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "000000" } }, // Black color
    //     left: { style: "thin", color: { argb: "000000" } }, // Black color
    //     bottom: { style: "thin", color: { argb: "000000" } }, // Black color
    //     right: { style: "thin", color: { argb: "000000" } } // Black color
    //   };
    //   cell.alignment = { vertical: "middle", horizontal: "center" };
    // });

    // Adding the header to the worksheet

    let rowBodyVisbility = calculateRankChanges(allVisibility);
    rowBodyVisbility?.map((row, index) => {
      worksheet.addRow(row).eachCell((cell) => {
        let cellValue = String(cell.value);

        // Check if cell value contains '-'
        if (cellValue.includes("-")) {
          cell.font = { color: { argb: "FF0000" } }; // Red color for values containing '-'
        } else if (cellValue.includes("%")) {
          cell.font = { color: { argb: "023020" } }; // Green color for other values
        }
      });
    });
    // Calculate percentage growth
    worksheet.addRow([]);

    const topLevelHeaders = [""];
    const secondLevelHeaders = ["Hotel Name"];
    // Iterate through the data to get unique OTA names and add them to the header
    reviews.forEach((obj) => {
      if (!topLevelHeaders.includes(obj.otaName)) {
        topLevelHeaders.push(obj.otaName, "", "");
        secondLevelHeaders.push(
          "Review Score",
          "No. of reviews",
          "Unreplied Reviews"
        );
      }
    });

    worksheet.addRow(topLevelHeaders).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const lastRow1 = worksheet.rowCount;

    // Iterate over each cell in the last row

    for (
      let column = "B";
      column <= String.fromCharCode("B".charCodeAt(0) + 16);
      column = String.fromCharCode(column.charCodeAt(0) + 3)
    ) {
      worksheet.mergeCells(
        `${column}${lastRow1}: ${String.fromCharCode(column.charCodeAt(0) + 2)}${lastRow1}`
      );
    }
    worksheet.addRow(secondLevelHeaders).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B7D166" },
      };
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } }, // Black color
        left: { style: "thin", color: { argb: "000000" } }, // Black color
        bottom: { style: "thin", color: { argb: "000000" } }, // Black color
        right: { style: "thin", color: { argb: "000000" } }, // Black color
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Loop through the data and add rows with hotel name and reviews data
    const rowData = [];
    temp?.reviews.map((review, id) => {
      id === 0 && rowData.push(temp?.hotelName);
      rowData.push(review?.reviewScore);
      rowData.push(review?.noOfReviews);
      rowData.push(review.unrepliedReviews);
    });

    worksheet.addRow(rowData).eachCell((cell,colNumber) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      const column = String.fromCharCode(65 + colNumber); // Convert index to column letter
      const currentWidth = worksheet.getColumn(column).width || 10; // Default width if not set
      const newWidth = Math.max(currentWidth, cell.value.toString().length + 2); // Add padding
      worksheet.getColumn(column).width = newWidth; // Update column width
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger a download
  const a = document.createElement("a");
  a.href = url;
  a.download = `Year End Report ${DATA3[0]?.hotelName} .xlsx`; // Set the desired filename here
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
};
