import React, { useState } from "react";
import logo from "../../assets/img/rateXBlack.svg";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { useEarlyAccessMutation } from "../../redux/slices/utils";
import { toast } from "react-toastify";

const IsMobile = () => {
  const [email, setEmail] = useState();
  const [earlyAccess] = useEarlyAccessMutation();
  const handleNotify = () => {
    earlyAccess({ email: email })
    .unwrap()
    .then((res) => {
      toast.success(res.message, { position: "bottom-right" });
    })
    .catch((err) => {
      toast.error("Eamil already exist", { position: "bottom-right" });
    });
  };
  return (
    <div
      className="p-4 flex-col flex h-screen items-start justify-between"
      style={{ background: "linear-gradient(360deg, #95c82a, white)" }}
    >
      <div className="w-[100%] sticky top-3 flex justify-center">
        {" "}
        <img src={logo} width={100} />
      </div>
      <div className="w-[100%] flex-col flex items-center justify-center">
        <span className="text-xl font-semibold mb-2"> Available Soon</span>
        <span className="text-xs">
          {" "}
          Rate-X isn't quite ready for your device yet, but don’t fret! You can
          experience all the magic on your tablet or desktop.
        </span>
        <span className="text-xs">
          {" "}
          Mobile users, we haven't forgotten about you! We're working hard and
          will be launching on mobile platforms soon. Your patience will pay
          off!
        </span>
        <span className="text-md text-center w-[100%]  mt-4">
          Get early access!
        </span>
        <div className="w-[100%]">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email"
            className="my-2 px-2 focus:outline-none text-sm text-gray-700 placeholder:text-gray-400 h-8 rounded-md w-[100%]"
          />
          <button
            onClick={handleNotify}
            className="my-2 h-8 text-white text-sm rounded-md w-[100%] bg-[#04121c]"
          >
            Get Notified
          </button>
        </div>
      </div>
      <div className="w-[100%]">
        <div className="w-[100%] text-center my-2"> Connect with us:</div>
        <div className="w-[100%] gap-4 flex justify-center">
          <AiFillInstagram size={20} /> <AiFillLinkedin size={20} />{" "}
          <AiFillFacebook size={20} />
        </div>
      </div>
    </div>
  );
};

export default IsMobile;
