import React, { useState } from "react";
import Table from "../../components/table";
import { useGetAllCustomReportQuery } from "../../redux/slices/reports";
import { CurrentProperty, UserId } from "../../utils/impVars";
import CustomSkeleton from "../../components/Skeleton";
import BackdropLoader from "../../components/newLoader";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../rateSuggestion/rateAnalysis";


const ReportBuilderTable = ({ handleBackButtonClick }) => {
    const navigate = useNavigate()
    const {
        data: AllCustomReport,
        isLoading: AllCustomReportIsLoading,
        isFetching: AllCustomReportIsFetching,
    } = useGetAllCustomReportQuery(
        {
            userId: UserId(),
            hId: CurrentProperty(),
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const hId = CurrentProperty();
    const column = [
        {
            name: "Report Name",
            cell: (row) => {
                return (
                    <div className="flex gap-2">
                        <div className="flex flex-col items-start">
                            <p className="font-[600]">{`${row?.reportName}`}</p>
                            {/* <p className="font-[500] text-xs">{`${row?.reportTypeId}`}</p> */}
                        </div>
                    </div>
                );
            },
        },
        {
            name: "Description",
            selector: (row) => row["reportDescription"],
        },
        {
            name: "Created By",
            selector: (row) => row["createdBy"],
        },
        {
            name: "Created On",
            selector: (row) => row["createdAt"],
        },
        {
            name: "Last Modified By",
            selector: (row) => row["modifiedBy"],
        },
        {
            name: "Last Modified On",
            selector: (row) => row["updatedAt"],
        },
        
        // {
        //     name: "Actions",
        //     cell: (row) => {
        //         return (
        //             <Tableactiontype
        //                 onEditClick={() => {
        //                     setEditData(row);
        //                     setIsEdit(true);
        //                 }}
        //                 rule={true}
        //                 reset={true}
        //             // showDelete={true}
        //             // handleDelete={() => handleDelete(row?.mealPlanId)}
        //             // refetch={refetch}
        //             />
        //         );
        //     },
        // },
    ];

    return (
        <>
            <BackdropLoader loading={AllCustomReportIsLoading} />
            <div className="flex flex-col gap-4 w-[100%] ">
                <div className="flex gap-2 w-[100%]">
                    <div className="relative w-[100%] bg-[#F6F8FB] flex flex-col rounded-xl p-4">
                        <div className="flex items-center justify-between">
                            <p className="text-lg font-[500] flex items-center gap-2">
                                {/* <BackButton mainDashboard={true} /> */}
                                Reports</p>
                            <div className="flex items-center gap-2">
                           <div onClick={()=>navigate('/Report/Custom/ReportBuilder')} className="cursor-pointer text-sm bg-[#1D2D44] py-2 px-4 rounded-lg text-white mb-2">Add Report</div> 
                            </div>
                        </div>

                        {AllCustomReport?.data != null ?
                            // AllCustomReport?.data.length > 0 &&
                            // !AllCustomReportIsLoading &&
                            // !AllCustomReportIsFetching? 
                            (
                                <Table
                                    columns={column}
                                    pagination={true}
                                    data={AllCustomReport?.data}
                                    builder={true}
                                />
                            ) : (
                                <CustomSkeleton height={400} width={"100%"} />
                            )}

                    </div>
                </div>
            </div>

        </>
    );
};

export default ReportBuilderTable;

// const AddMealPlan = ({
//   onClose,
//   addRatePlan,
//   data: editData,
//   isView,
//   isEdit,
//   editMealPlan,
//   refetch,
// }) => {
//   const methods = useForm({});
//   const [numberOfRoom, setNumberOfRoom] = useState(0);
//   const [mealCharge, setMealCharge] = useState(0);
//   const [selectedCharge, setSelectedCharge] = useState();
//   const hId = CurrentProperty();
//   const { data: mealPlanCharge } = useGetMealPlanChargeQuery(
//     {
//       hId: CurrentProperty(),
//     },
//     {
//       refetchOnMountOrArgChange: true,
//     }
//   );

//   const onSubmit = async (data) => {
//     const obj = {
//       hId: hId,
//       mealPlanDetails: {
//         mealPlanName: data?.mealPlanName,
//         chargeDetails: {
//           mealPlancharge: mealCharge,
//           chargeRule: selectedCharge?.value,
//         },
//       },
//     };
//     const editParams = {
//       hId: hId,
//       mealPlanId: editData?.mealPlanId,
//     };
//     if (isEdit) {
//       editMealPlan({ obj, editParams })
//         .unwrap()
//         .then((res) => {
//           toast.success("Meal Plan Updated Successfully!", {
//             position: "bottom-right",
//           });
//           refetch();
//           onClose();
//         })
//         .catch((err) => {
//           onClose();
//         });
//     } else {
//       addRatePlan(obj)
//         .unwrap()
//         .then((res) => {
//           toast.success("Meal Plan Added Successfully!", {
//             position: "bottom-right",
//           });
//           refetch();
//           onClose();
//         })
//         .catch((err) => onClose());
//     }
//   };
//   const mealPlanChargeOption =
//     mealPlanCharge &&
//     mealPlanCharge?.data.map((item) => {
//       return {
//         label: item?.chargeRuleName,
//         value: item?.chargeId,
//       };
//     });
//   useEffect(() => {
//     if (editData) {
//       methods.setValue("mealPlanName", editData?.mealPlanName);
//       setMealCharge(editData?.chargeDetails?.mealPlancharge);
//       setSelectedCharge({
//         label: editData?.chargeDetails?.chargeRule,
//         value: editData?.chargeDetails?.chargeRule,
//       });
//     }
//   }, [editData]);

//   return (
//     <FormProvider {...methods}>
//       <form onSubmit={methods.handleSubmit(onSubmit)}>
//         <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
//           <SettingButton
//             text={"Save"}
//             Title={"Add Meal Plan"}
//             onClose={onClose}
//             handleBackButtonClick={onClose}
//             table={true}
//             isView={isView}
//           />

//           <div className="flex gap-4 w-[100%] p-2 flex-col">
//             <div className="flex flex-col gap-4 w-[100%]">
//               <p className="text-[#131926] text-[13px]">Meal Plan Details</p>
//               <div className="flex flex-col justify-center gap-2">
//                 <p className="text-[#8794AD] text-[12px]">Meal Plan Name</p>
//                 <div className="w-1/2  rounded-lg overflow-hidden">
//                   <InputField
//                     type={"text"}
//                     className={"text-gray-600 w-full bg-[#F6F8FB] h-9"}
//                     label={false}
//                     placeholder={"Enter Meal Plan Name"}
//                     id={"mealPlanName"}
//                     name={"mealPlanName"}
//                     controls={methods.control}
//                     rules={{ required: "Meal Plan Name is required" }}
//                     disabled={isView && true}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="flex flex-col gap-4 w-[100%]">
//               <p className="text-[#131926] text-[13px]">Charge Details</p>
//               <div className="flex gap-4 w-1/2">
//                 <div className="w-1/2 flex flex-col justify-center gap-2">
//                   <p className="text-[#8794AD] text-[12px]">Meal Plan Charge</p>
//                   <DynamicCounter
//                     initialValue={"₹ 3,000.00"}
//                     count={mealCharge}
//                     setCount={isView ? () => null : setMealCharge}
//                   />
//                 </div>
//                 <div className="w-1/2 flex flex-col justify-center gap-2">
//                   <p className="text-[#8794AD] text-[12px]">Charge Rule</p>
//                   <Dropdown
//                     selectedItem={selectedCharge?.label}
//                     setSelectedItems={setSelectedCharge}
//                     options={mealPlanChargeOption}
//                     width={534}
//                     height={36}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </FormProvider>
//   );
// };
