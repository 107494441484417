import { emptySplitApi } from "../../injectEndpoints";

export const RateMonitor = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardFluctuationOverview: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/fluctuationOverView`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getDashboardFluctuationOverview"],
    }),
    getDashboardFluctuationTrend: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/fluctuationTrend`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getDashboardFluctuationTrend"],
    }),
    getDashboardFluctuationCalendar: builder.query({
      query: (data) => {
        return {
          url: `/dashboard/fluctuationCalendar`,
          method: "GET",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["getDashboardFluctuationCalendar"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDashboardFluctuationOverviewQuery,useGetDashboardFluctuationTrendQuery, useGetDashboardFluctuationCalendarQuery
} = RateMonitor;
